import { Component, OnInit, ViewChild } from "@angular/core";
import { ReportsService } from "../../shared/services/reports.service";
import { NotificationService } from "../../core/services/notification.service";
import { saveAs } from 'file-saver';
import { addDays } from '@progress/kendo-date-math';
import { GeneralEquipmentReportQuery } from "../../shared/models/reports/general-equipment-report-query";
import { WarehousesService } from "../../shared/services/warehouses.service";
import { KeyValueObject } from "../../shared/models/core/KeyValueObject";
import { NgForm } from "@angular/forms";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { LookupService } from "../../shared/services/lookup.service";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { ContragentType } from "../../shared/enums";
import { GeneralEquipmentReportDto } from "../../shared/models/general-equipment-report/general-equipment-report-dto";
import { GridSettings } from "../../shared/models/grid/grid-settings";
import { ListPageBaseLocal } from "../../shared/components/list-page-base/list-page-base-local";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ExcelExportComponent, ExcelExportData } from "@progress/kendo-angular-excel-export";
import { ColumnSettings } from "../../shared/models/grid/column-settings";
import { SecurityService } from "../../core/services/security.service";
import { UsersService } from "../../admin/users/users.service";
import { GeneralEquipmentExcelReportQuery } from "../../shared/models/reports/GeneralEquipmentExcelReportQuery";
import { NotificationType } from "../../core/services/notification-type";
import { finalize } from "rxjs/operators";
import { AppService } from "../../app.service";

@Component({
	selector: 'general-equipment-report-page',
	templateUrl: './general-equipment-report.page.html',
	styleUrls: [
		'./general-equipment-report.page.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/ngx-contextmenu/ngx-contextmenu.scss'
	],
	animations: [
		trigger('smoothCollapse', [
			state('initial', style({
				height: '0',
				opacity: '0'
			})),
			state('final', style({
				opacity: '1'
			})),
			transition('initial=>final', animate('400ms')),
			transition('final=>initial', animate('400ms'))
		]),
	]
})
export class GeneralEquipmentReport extends ListPageBaseLocal<GeneralEquipmentReportDto> {

	@ViewChild('entityForm') public entityForm: NgForm;

	public range = { start: new Date(), end: new Date() };
	public dataGenerating = false;

	warehousesCities: KeyValueObject[] = [];
	deviceTypes: KeyValueObject[] = [];
	qualities: KeyValueObject[] = [];
	nameOfProducts: KeyValueObject[] = [];
	bankOwners: KeyValueObject[] = [];
	compartments: KeyValueObject[] = [];
	statuses: KeyValueObject[] = [];
	warehouses: KeyValueObject[] = [];

	excelFileName: string;

	isCollapsed: boolean = false;

	generalEquipmentReportQuery: GeneralEquipmentReportQuery = new GeneralEquipmentReportQuery();

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	userContragentId: number;

	constructor(
		private reportsService: ReportsService,
		private notificationService: NotificationService,
		private lookupService: LookupService,
		private securityService: SecurityService,
		private warehousesService: WarehousesService,
		private appService: AppService
	) {
		super();
		appService.pageTitle = 'Общий отчёт по оборудованию';
	}

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'tid',
				title: 'TID',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'clientRequest',
				title: 'Заявка клиента',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'requestSd',
				title: 'Заявка в системе SD',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'nomenclature',
				title: 'Номенклатура',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'series',
				title: 'Серия',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'equipmentType',
				title: 'Тип оборудования',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'bankOwner',
				title: 'Банк владелец',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'quality',
				title: 'Качество',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'keying',
				title: 'Ключевание',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 9,
				field: 'completeness',
				title: 'Комплектность',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 10,
				field: 'deviceServiceTypeName',
				title: 'Сегмент',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: this.isOnlyClient
			},
			{
				orderIndex: 11,
				field: 'warehouse',
				title: 'Склад',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 12,
				field: 'warehouseCity',
				title: 'Город склада',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 13,
				field: 'warehouseArea',
				title: 'Помещение',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 14,
				field: 'dateOfLastTransaction',
				title: 'Дата последней операции',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 15,
				field: 'status',
				title: 'Статус',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 16,
				field: 'subscriberNumber',
				title: 'Абонентский номер',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 17,
				field: 'lastComment',
				title: 'Последний комментарий',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: this.isOnlyClient
			},
			{
				orderIndex: 18,
				field: 'deviceReceiveDateString',
				title: 'Дата оприходования',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 19,
				field: 'keyRegion',
				title: 'Филиал',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 20,
				field: 'warehouseServiceCenters',
				title: 'Исполнители СЦ',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: this.isOnlyClient
			}
		]
	}

	get reportColumnSettings(): ColumnSettings[] {
		return this.gridSettings.columnsConfig.map((v) => {
			if (v.field === 'dateOfLastTransaction') {
				return <ColumnSettings>{
					field: 'dateOfLastTransactionString',
					filter: 'string',
					filterable: v.filterable,
					hidden: v.hidden,
					orderIndex: v.orderIndex,
					title: v.title,
					width: v.width
				}
			} else {
				return v;
			}
		});
	}

	processListItems(items: GeneralEquipmentReportDto[]): void {

	}

	ngOnInit(): void {
		this.userContragentId = this.securityService.currentUser.contragentId;

		this.range.start.setHours(0, 0, 0, 0);
		this.range.end.setHours(0, 0, 0, 0);

		this.warehousesService.warehousesCitiesLookup().subscribe(data => this.warehousesCities = data);
		this.lookupService.getData("device-types", null).subscribe(data => this.deviceTypes = data);

		var clientsState: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: ContragentType.bankEquer }] }
		};

		if (this.isOnlyClient) {
			clientsState = {
				skip: 0,
				take: null,
				filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: ContragentType.bankEquer },
				{ field: "ContragentId", operator: "eq", value: this.securityService.currentUser.contragentId }] }
			};
		}

		this.lookupService.getData("contragents", clientsState).subscribe(data =>{
			this.bankOwners = data;
			
			if (this.isOnlyClient) {
				this.bankOwners = this.bankOwners.filter(flt => flt.id == this.securityService.currentUser.contragentId);
				if (this.bankOwners.length > 0) {
					this.generalEquipmentReportQuery.bankOwner = [this.bankOwners[0].integraCode];
				}
			}
		});

		
		if (!this.isOnlyClient) {
			this.lookupService.getData("warehouse-areas", null).subscribe(data => this.compartments = data);
			this.lookupService.getData("warehouses", null).subscribe(data => this.warehouses = data);
		}

		this.qualities.push(new KeyValueObject(1, "Новый"));
		this.qualities.push(new KeyValueObject(2, "Б/У"));
		this.qualities.push(new KeyValueObject(3, "Б/у отличное"));
		this.qualities.push(new KeyValueObject(4, "Б/у с потёртостями"));
		this.qualities.push(new KeyValueObject(5, "Б/у сильно повреждённое"));
		this.qualities.push(new KeyValueObject(6, "Неисправно"));

		this.statuses.push(new KeyValueObject(1, "В движении"));
		this.statuses.push(new KeyValueObject(2, "Зарезервировано"));
		this.statuses.push(new KeyValueObject(3, "На доставке"));
		this.statuses.push(new KeyValueObject(4, "На складе"));
		this.statuses.push(new KeyValueObject(6, "Списано"));
		this.statuses.push(new KeyValueObject(7, "Установлено"));

		if (this.isOnlyClient) {
			this.generalEquipmentReportQuery.status = ["На складе"];
		}

		super.ngOnInit();
		this.excelFileName = this.getExcelFileName();
	}

	get isExportDisabled(): boolean {
		return !!!this.generalEquipmentReportQuery.modelId
			&& this.generalEquipmentReportQuery.quality.length === 0
			&& this.generalEquipmentReportQuery.status.length === 0
			&& this.generalEquipmentReportQuery.typeOfEquipment.length === 0
			&& this.generalEquipmentReportQuery.warehouse.length === 0
			&& this.generalEquipmentReportQuery.warehouseCity.length === 0
			&& this.generalEquipmentReportQuery.bankOwner.length === 0
			&& this.generalEquipmentReportQuery.compartment.length === 0
	}

	generateReportTable() {
		this.updateData([]);
		this.dataGenerating = true;
		this.reportsService.generalEquipmentReport(this.generalEquipmentReportQuery)
			.subscribe(res => {
				if (res.isSuccessful && !!res.data && res.data.length > 0) {
					this.updateData(res.data);
				} else {
					this.updateData([]);
				}

				this.dataGenerating = false;
				this.isCollapsed = true;
			});
	}

	private getExcelFileName(): string {
		let dateTime = new Date();
		let date = dateTime.toLocaleDateString();
		let time = dateTime.toLocaleTimeString();
		let re = new RegExp(':', 'g');
		time = time.replace(re, "_");
		return `Общий_отчёт_по_оборудованию_создан_${date} ${time}.xlsx`;
	}

	generateReport() {
		this.dataGenerating = true;

		let dateEnd = new Date(this.range.end);
		dateEnd = addDays(dateEnd, 1);

		const startDate = this.getDate(this.range.start);
		const endDate = this.getDate(dateEnd);

		const startDateName = this.range.start.toLocaleDateString().replace(',', '');
		const endDateName = this.range.end.toLocaleDateString().replace(',', '');

		// this.reportsService.generalEquipmentReport(this.generalEquipmentReportQuery)
		// 	.subscribe(blob => {
		// 		if (blob.type === 'application/octet-stream') {
		// 			const localDate = new Date().toLocaleString().replace(',', '');
		// 			saveAs(blob, `Общий_отчёт_по_оборудованию_от_${startDateName}_по_${endDateName}_создан_${localDate}.xlsx`);
		// 			this.dataGenerating = false;

		// 		} else if (blob.type === 'application/json') {
		// 			blob
		// 				.text()
		// 				.then(result => {
		// 					const response = JSON.parse(result);
		// 					if (!response.isSuccessful) {
		// 						this.notificationService.error({
		// 							title: 'Ошибка',
		// 							message: response.errorDescription as string,
		// 							notificationType: NotificationType.SweetAlert
		// 						});
		// 					}

		// 					this.dataGenerating = false;
		// 				});
		// 		}
		// 	});
	}

	getDate(date) {
		return new Date(date).toJSON();
	}

	public exportToExcel(): void {
		this.exportInProgress = true;

		this.reportsService.generalEquipmentReportExcel(this.generalEquipmentReportQuery)
		.subscribe(blob => {
			if (blob.type === 'application/octet-stream') {
				const localDate = new Date().toLocaleString().replace(',', '');
				saveAs(blob, `Общий_отчет_по_оборудованию_создан_${localDate}.xlsx`);
				this.exportInProgress = false;
			} else if (blob.type === 'application/json') {
				blob
					.text()
					.then(result => {
						const response = JSON.parse(result);
						if (!response.isSuccessful) {
							this.notificationService.error({
								title: 'Ошибка',
								message: response.errorDescription as string,
								notificationType: NotificationType.SweetAlert
							});
						}
						this.exportInProgress = false;
					});
			}
		});
	}

	get isOnlyClient(): boolean {
		return this.securityService.isOnlyClient();
	}

	get isDisabledForClient(): boolean {
		return this.isOnlyClient && !this.entityForm.valid;
	}
}
