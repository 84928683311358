import { Coordinates } from "./engubator.coordinates";
import { EngubatorEngineerRegion } from "./engubator.engineer.region";
import { EngubatorMovementType } from "./engubator.movementType";
import { EngubatorSkill } from "./engubator.skill";

export class EngubatorEngineer {
	integraCode: string;
	name: string;
	address: string;
	isAssigningBlocked: boolean;
	serviceZones: number[] = [];
	startCoordinates: Coordinates = new Coordinates();
	finishCoordinates: Coordinates = new Coordinates();
	skills: EngubatorSkill[] = [];
	movementType: EngubatorMovementType = new EngubatorMovementType();
	serviceCenters: string[] = [];
	regions: EngubatorEngineerRegion[] = [];
}
