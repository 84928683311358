export class AbsentArticles {
    articlesMask: string;
    requestQuantity: number;
    responseQuantity: number;

    constructor(articlesMask: string, requestQuantity: number, responseQuantity: number){
        this.articlesMask = articlesMask;
        this.requestQuantity = requestQuantity;
        this.responseQuantity = responseQuantity;
    }
}