import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { filter, tap } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';

@Injectable()
export class RequestConfigurationService {

	constructor(public http: HttpClient,
		public notificationService: NotificationService) {
	}

	private controllerName: string = 'request-configuration';
	private controllerVersion = 1;

	public getRequestConfiguration(requestId: number): Observable<any> {
		return this.http.get<ActionDataResult<any>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}?requestId=${requestId}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public getRequestConfigurationByTid(tidNumber: string): Observable<any> {
		return this.http.get<ActionDataResult<any>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/by-tid?tidNumber=${tidNumber}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}
}
