import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "../../core/services/data.service";
import { NotificationService } from "../../core/services/notification.service";
import { AttachmentTypeDto } from "../models/attachment/attachment-type-dto";
import { AttachmentTypeListItemDto } from "../models/attachment/attachment-type-list-item-dto";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { CompressedAttachment } from "../models/attachment/compressed-attachment";
import { ActionDataResult } from "../models/core/ActionDataResult";

@Injectable()
export class AttachmentTypesService extends DataService<AttachmentTypeListItemDto, AttachmentTypeDto> {

	constructor(
		protected http: HttpClient,
		protected notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'attachment-types';
	}
	
	public checkAttachmentType(requestId: number
		, attachmentTypeConfigurationTypeId: number
		, newActivityStatusId: number
		, newRequestStatusId: number
		, OverloadAttachmentTypeIds: number[]): Observable<ActionDataResult<CompressedAttachment>> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/check-attachment-type`, {
			requestId: requestId,
			attachmentTypeConfigurationTypeId: attachmentTypeConfigurationTypeId,
			newActivityStatusId: newActivityStatusId,
			newRequestStatusId: newRequestStatusId,
			OverloadAttachmentTypeIds: OverloadAttachmentTypeIds,
		});
	}
}
