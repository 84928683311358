import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DialogService } from "@progress/kendo-angular-dialog";
import { DataSourceRequestState, process } from "@progress/kendo-data-query";
import { AppService } from "../../../app.service";
import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { EmailSourceType } from "../../../shared/enums/email-source-type";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { GridSettings } from "../../../shared/models/grid/grid-settings";
import { RequestEmail } from "../../../shared/models/request/request-email";
import { RequestEmailsService } from "../../../shared/services/request-emails.service";
import { StatePersistingService } from "../../../shared/services/state-persisting.service";
import { RequestEmailComponent } from "../request-email/request-email.component";
import { getDate } from '@progress/kendo-date-math';
import { RowClassArgs } from "@progress/kendo-angular-grid";
import { ReportsService } from "../../../shared/services/reports.service";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";

@Component({
	selector: 'request-email-list',
	templateUrl: './request-email-list.component.html',
	styleUrls: [
		'request-email-list.component.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class RequestEmailListComponent extends ListPageBase<RequestEmail> implements OnInit {

	@Input()
	requestId: number;

	enableSaveGridSettings: boolean = true;
	gridSettingsStorageKey: string = 'request-emails-grid-settings';

	emailTypes: KeyValueObject[] = [
		new KeyValueObject(<number>EmailSourceType.EmailGate, 'Входящее'),
		new KeyValueObject(<number>EmailSourceType.Rune, 'Исходящее'),
	];

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 10,
			sort: [{ field: 'date', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'emailType',
				title: 'Тип',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'subject',
				title: 'Название (тема) email',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'body',
				title: 'Текст уведомления',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'senderName',
				title: 'Имя аккаунта Email',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'sender',
				title: 'Email отправителя',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'recipient',
				title: 'Email получателя',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'date',
				title: 'Дата отправления/получения',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: false
			},
		]
	};

	private emails: RequestEmail[] = [];

	constructor(
		router: Router,
		protected appService: AppService,
		protected persistingService: StatePersistingService,
		private requestEmailsService: RequestEmailsService,
		private dialogService: DialogService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, requestEmailsService, persistingService, exportExcelFromGridService);
	}

	ngOnInit(): void {
		this.gridHeight = window.innerHeight - 175;

		const gridSettings: GridSettings = this.persistingService.get(this.gridSettingsStorageKey);
		this.setGridSettings(gridSettings);

		this.onListPageInit();

		this.gridSettings.state.sort = this.gridSettings.state.sort.filter(x => x.field !== 'createdDateMoscow')
	}

	public rowClass(context: RowClassArgs) {
		return {
			'cursor-pointer': true
		};
	}

	onListPageInit(): void {
		this.loading = true;
		this.requestEmailsService.getAll(this.requestId)
			.subscribe(result => {
				if (result.isSuccessful) {
					this.emails = result.data;
					this.emails.map(email => {
						email.dateTime = email.date;
						email.date = getDate(this.appService.parseDate(email.date));
					});
					this.loadData();
				}

				this.loading = false;
			});
	}

	processListItems(items: RequestEmail[]): void {}

	public loadData(state: DataSourceRequestState = this.gridSettings.state): void {
		this.loading = true;
		this.listViewModel = process(this.emails, state);
		this.loading = false;
	}

	public cellClick({ type, dataItem, column }: any): void {
		const emailDialog = this.dialogService.open({
			title: `Email`,
			content: RequestEmailComponent,
			height: 600,
			width: 800,
		});

		const component = <RequestEmailComponent>emailDialog.content.instance;

		component.requestId = this.requestId;
		component.email = dataItem;
	}

	public getEmailTypeName(dataItem: RequestEmail): string {
		return this.emailTypes.filter(x => x.id === <number>dataItem.emailType)[0].name;
	}

	public cutBody(dataItem: any): string {
		var span = document.createElement('span');
		span.innerHTML = dataItem['body'];
		return span.innerText.substring(0, 100) + '...';
	}
}
