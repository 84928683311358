import { Input, Output, EventEmitter, Component } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { NotificationService } from "../../core/services/notification.service";
import { NotificationType } from "../../core/services/notification-type";

@Component({
	selector: "equipment-search-mode",
	templateUrl: "./equipment-search-mode.html",
	styleUrls: ["./equipment-search-mode.scss"],
})
export class EquipmentSearchMode {
    
    serialNumbers: string[] = [];

    @Output() 
    onContinueEvent = new EventEmitter<string[]>();
    onCancelEvent = new EventEmitter();

    constructor(
        public dialog: DialogRef,
        public notificationService: NotificationService
    ) { }

    send() {
        if (this.serialNumbers == null || this.serialNumbers.length == 0) {
            this.notificationService.info({
                title: "",
				message: "Укажите хотя бы один серийный номер",
				notificationType: NotificationType.Toast
            });
        }
        else {
            this.onContinueEvent.emit(this.serialNumbers);
            this.dialog.close();
        }
    }

    cancel() {
		this.onCancelEvent.emit();
		this.dialog.close();
	}
}