import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {KeyValueObject} from '../models/core/KeyValueObject';

@Directive({
  selector: '[appLineList]'
})
export class LineListDirective implements AfterViewInit {

	@Input('appLineList') allItems: KeyValueObject[] = [];
	@Input() selectedIds: number[] = [];

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {}

	public ngAfterViewInit(): void {
  	if (!this.allItems || !this.selectedIds) {
			return;
		}

		const text = this.allItems.filter(x => this.selectedIds.includes(x.id)).map(x => x.name).join(', ');
		this._renderer.setProperty(this._elementRef.nativeElement, 'innerHTML', text);
	}
}
