export enum SetSnModes {
	standart = 1,
	notEnough = 2,
	selected = 3,
	cancelTask = 4,
	stopSendTask = 5,
	awaitingReceive = 6,
	received = 7,
	stopReceiveTask = 8
}
