import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { ArticleDto } from '../models/article/article.dto';
import { CheckArticleBy1cAndCreateDto } from '../models/article/check-article-by-1c-and-create.dto';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { PartnumberDto } from '../models/nomenclature/partnumberDto';

@Injectable()
export class ArticlesService extends DataService<ArticleDto, ArticleDto> {
    constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'articles';
	}

    public getArticle(articleId: number): Observable<ArticleDto> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-article/${articleId}`);
	}

	public checkArticleBy1cAndCreate(requestId: number, articleName: string): Observable<ActionDataResult<CheckArticleBy1cAndCreateDto>> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/check-article-by1c-and-create`, {
			requestId: requestId,
			articleName: articleName
		});
	}

	public getComponentsArticles(): Observable<ArticleDto[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-components-articles`);
	}

	public getPartnumberByArticle(queryString: string): Observable<PartnumberDto[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/by-article/${queryString}`);
	}
}