import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { ActivitiesService } from "../../../services/activities.service";
import { ActivityResult } from "../../../models/activity/activity-result";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { LookupService } from "../../../services/lookup.service";
import { AddingDeviceModel } from "../adding-device/adding-device.model";
import { DeviceInfo } from "../../../models/device/device-info";
import { AddingDevice } from "../adding-device/adding-device";
import { UploadAttachmentsModel, UploadFile } from "../../../models/attachment";
import { DevicesService } from "../../../services/devices.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { ConfirmDeviceDifferent } from "../confirm-device-different/confirm-device-different";
import { RequestsService } from "../../../services/requests.service";
import { NewCommentModel } from "../../../models/request/comments/new-comment-model";
import { CommentTypeEnum } from "../../../enums/comment-type.enum";
import { CommentKindEnum } from "../../../enums/comment-kind.enum";
import { AttachmentsService } from "../../../services/attachments.service";
import { AppService } from "../../../../app.service";
import { AttachmentTypesService } from "../../../services/attachment-types.service";
import { AttachmentTypeConfigurationTypeEnum } from "../../../enums/attachment-type-configuration-type.enum";
import { NotificationType } from "../../../../core/services/notification-type";

@Component({
	selector: 'work-ended-success-install',
	templateUrl: './work-ended-success-install.html',
	styleUrls: ['./work-ended-success-install.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class WorkEndedSuccessInstall implements OnInit {

	@Input() requestId: number;
	@Input() activityId: number;
	@Input() nextActivityStatusId: number;
	@Input() activityStatusToId: number;
	@Input() activityWorkflowTransitionId: number;

	@Output() onContinueEvent = new EventEmitter();
	@Output() onCancelEvent = new EventEmitter();

	activityResults: KeyValueObject[] = [];

	activityResultId: number;
	comment: string = null;
	completedDateTime: Date = null;
	attachments: UploadFile[] = [];
	devices: AddingDeviceModel[] = [];

	isValidLastEquipment: Boolean = false;

	loading: boolean = false;
	dataSaving: boolean = false;

	constructor(
		public requestsService: RequestsService,
		public activitiesService: ActivitiesService,
		public attachmentsService: AttachmentsService,
		public lookupService: LookupService,
		public devicesService: DevicesService,
		public notificationService: NotificationService,
		public dialogService: DialogService,
		public dialog: DialogRef,
		public appService: AppService,
		public attachmentTypesService: AttachmentTypesService
	) { }

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	ngOnInit(): void {
		this.completedDateTime = new Date();
		this.loading = true;
		this.devices.push(new AddingDeviceModel());
		this.activitiesService.getActivityResults(this.activityId, this.nextActivityStatusId).subscribe(resp => {
			if (!!resp) {
				let respKv = resp.map(m => new KeyValueObject(m.activityResultId, m.activityResult));
				this.activityResults = respKv;
				if (resp.length === 1) this.activityResultId = respKv[0].id;
				this.loading = false;
			}
		});
	}

	setDevice(addingDevice: AddingDeviceModel) {
		this.devices[addingDevice.index].nomenclature = addingDevice.nomenclature;
		this.devices[addingDevice.index].serialNumber = addingDevice.serialNumber;
		this.devices[addingDevice.index].uploadAttachments = addingDevice.uploadAttachments;
		this.devices[addingDevice.index].isValid = addingDevice.isValid;

		this.isValidLastEquipment = this.devices.every(d => d.isValid);
	}

	addDevice() {
		this.devices.push(new AddingDeviceModel());
		this.isValidLastEquipment = false;
	}

	saveChanges() {
		this.devicesService.reservedEqualUsed(this.requestId, this.devicesInfo).subscribe((resp) => {
			if (!resp.data) {
				// notification.confirmation перекрывается текущим диалоговым окном
				let width = `500px`;
				if (this.isMobileDevice) {
					width = `80%`
				}
				const confirmDeviceDifferentDialogRef = this.dialogService.open({
					content: ConfirmDeviceDifferent,
					width: width,
				});

				const confirmDeviceDifferent = <ConfirmDeviceDifferent>confirmDeviceDifferentDialogRef.content.instance;
				confirmDeviceDifferent.confirm.subscribe(() => this.completeDialog());
			}
			else
				this.completeDialog();
		});
	}

	validateCompleteDialogData() {
		if (this.completedDateTime) {
			this.requestsService.checkNewFinishFactDate(this.requestId, this.completedDateTime)
				.subscribe(result => {

					if (result.isSuccessful) {
						this.processComleteDialog();
					} else {
						this.notificationService
							.confirmation({
								message: result.errorDescription,
								type: 'question',
								cancelButtonText: 'Отмена',
								showCloseButton: true,
								showConfirmButton: false
							}, () => { }, () => this.dataSaving = false);
					}
				});
		} else {
			this.processComleteDialog();
		}
	}

	completeDialog() {
		this.dataSaving = true;
		this.validateCompleteDialogData();
	}

	processComleteDialog() {
		this.activitiesService.setActivityResult(this.activityId, this.activityResultId).subscribe(() => {
			this.requestsService.changeFinishDateFact(this.requestId, this.completedDateTime, true).subscribe(() => { });

			if (this.comment !== null && this.comment !== "" && !!this.comment) {
				var commentModel = new NewCommentModel;
				commentModel.text = this.comment;
				commentModel.requestId = this.requestId;
				commentModel.commentKind = CommentKindEnum.internal;
				commentModel.activityId = this.activityId;
				this.requestsService.addComment(commentModel).subscribe(() => { });
			}

			let res = this.activityResults.find(f => f.id === this.activityResultId);

			if (res !== null && res.name !== null && res.name !== "" && !!res.name) {
				var commentModel = new NewCommentModel;
				commentModel.text = res.name;
				commentModel.requestId = this.requestId;
				commentModel.commentKind = CommentKindEnum.general;
				this.requestsService.addComment(commentModel).subscribe(() => { });
			}

			if (!!this.attachments && this.attachments.length > 0) {
				this.attachmentsService.uploadWithResumableIfMobile(new UploadAttachmentsModel({
					requestId: this.requestId, activityId: this.activityId, attachments: this.attachments
				})).subscribe(() => { });
			}

			this.devices.forEach(device => {
				if (!!device.uploadAttachments && device.uploadAttachments.length > 0) {
					this.attachmentsService.uploadWithResumableIfMobile(new UploadAttachmentsModel({
						requestId: this.requestId, activityId: this.activityId, attachments: device.uploadAttachments
					})).subscribe(() => { });
				}
			});

			this.devicesService.installationCompleteSuccessfull(this.requestId, this.devicesInfo, this.activityId).subscribe(ics => {

				if (!ics.isSuccessful) {
					this.dataSaving = false;
					this.notificationService.error({
						title: 'Ошибка',
						message: ics.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				} else {
					this.dataSaving = false;
					if (!!this.onContinueEvent) {
						this.onContinueEvent.emit();
					}
					this.dialog.close();
				}
			});
		});
	}

	cancel() {
		this.onCancelEvent.emit();
		this.dialog.close();
	}

	get formCompleted(): boolean {
		return this.activityResultId !== null && this.completedDateTime !== null &&
			this.devices !== null && this.devices.length > 0 && this.devices.every(d => d.isValid)
			&& !!this.activityResultId && this.activityResultId > 0;
	}

	get devicesInfo(): DeviceInfo[] {
		return this.devices.map(device => {
			var deviceInfo = new DeviceInfo();
			deviceInfo.serialNumber = device.serialNumber;
			deviceInfo.nomenclature = device.nomenclature;
			return deviceInfo;
		});
	}
}