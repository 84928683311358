import { ExpertiseActSettingByDeviceType } from "./expertise-act-setting-by-device-type";
import { ExpertiseActSettingOption } from "./expertise-act-setting-option";

export class ExpertiseActSetting {
	settingId: number;
	name: string;
	formType: string;
	groupName: string;
	formOrder: number;
	groupOrder: number;
	hidden: boolean;
	isRequired: boolean;
	options: ExpertiseActSettingOption[] = [];
	settingByDeviceTypes: ExpertiseActSettingByDeviceType[] = [];
}
