import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { AppService } from "../../../../app.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { ComponentPageBase } from "../../../../shared/components/component-page-base/component-page-base";
import { EntityViewModel } from "../../../../shared/models/core/EntityViewModel";
import { KeyValueObject } from "../../../../shared/models/core/KeyValueObject";
import { ServiceModeItem } from "../../../../shared/models/services/service-mode-item";
import { ServiceModesService } from "../../../../shared/services/service-modes.service";
import { ServiceModeSchedule } from '../../../../shared/models/services/service-mode-schedule';

@Component({
	selector: 'service-mode-form',
	templateUrl: './service-mode.form.html',
	styleUrls: [
		'./service-mode.form.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ServiceModeForm extends ComponentPageBase<ServiceModeItem> implements OnInit {

	@Input() header: string;
	@Input() showCancelButton: boolean = false;
	@Input() modeId: number;
	@Input() extEditFlag: boolean = true;

	@Output() afterSaveChangesEvent = new EventEmitter<KeyValueObject>();
	@Output() afterCancelEvent = new EventEmitter();

	public schedule: ServiceModeSchedule;

	timeMask: string = "00:00";

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: ServiceModesService,
		private injector: Injector
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/service-modes';
	}

	extRefresh(modeId: number) {
		this.modeId = modeId;
		this.ngOnInit();
	}

	ngOnInit(): void {
		this.entityId = this.route.snapshot.paramMap.get('serviceModeId');

		if (!this.entityId && !!this.modeId) {
			this.entityId = this.modeId.toString();
		}

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId)
				.subscribe(vm => {
					this.entityName = `${vm.entity.name}`;
					vm.entity.serviceModeHoursFromDate = this.appService.parseDateTime(vm.entity.serviceModeHoursFromDate);
					vm.entity.serviceModeHoursToDate = this.appService.parseDateTime(vm.entity.serviceModeHoursToDate);
					this.schedule = new ServiceModeSchedule(vm.entity.schedule);

					this.dataBind(vm);
				});

		} else {

			this.schedule = new ServiceModeSchedule();

			this.entityViewModel = new EntityViewModel<ServiceModeItem>();
			this.entityViewModel.entity = new ServiceModeItem();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;

		}
	}

	getUtcDate(time: number): Date {
		const now = new Date();
		return new Date(time + now.getTimezoneOffset() * 60000)
	}

	resetDateToMinValue(date: Date): Date {
		date.setDate(1);
		date.setFullYear(1);
		date.setMonth(1);
		return date;
	}

	onChangeSchedule(): void {
		const entity = this.entityViewModel.entity;
		if (!!entity.serviceModeHoursToDate && !!entity.serviceModeHoursFromDate) {

			entity.serviceModeHoursToDate = this.resetDateToMinValue(entity.serviceModeHoursToDate);
			entity.serviceModeHoursFromDate = this.resetDateToMinValue(entity.serviceModeHoursFromDate);

			let utcDateDiffDirty = this.getUtcDate(entity.serviceModeHoursToDate.getTime()).getTime() -
				this.getUtcDate(entity.serviceModeHoursFromDate.getTime()).getTime();

			let utcDateDiff = new Date(utcDateDiffDirty);
			let hoursTime = utcDateDiff.getTime() * this.schedule.getTotalDaysInSchedule();
			let hours = hoursTime / 36e5;
			let dt = new Date(hoursTime);
			entity.countOfHoursString = `${Math.trunc(hours)}:${dt.getMinutes().toString().padStart(2, "0")}`;
		} else {
			entity.countOfHoursString = "";
		}
	}

	saveChanges(): void {
		this.entityViewModel.entity.schedule = this.schedule.getMask();

		super.saveChanges();
	}

	afterSaveChanges(): void {
		const entity = this.entityViewModel.entity;
		const keyValueObject = new KeyValueObject(Number(this.entityId), `${entity.name} ${entity.schedule} ${entity.serviceModeHoursFrom}-${entity.serviceModeHoursTo} Часов в неделю: ${entity.countOfHours}`);

		if (this.afterSaveChangesEvent) {
			this.afterSaveChangesEvent.emit(keyValueObject);
			this.closeDialog();
		}
	}

	closeDialog(): void {
		if (!this.showCancelButton) {
			return;
		}

		const dialog = this.injector.get(DialogRef);
		dialog.close();
	}

	afterCancel(): void {
		if (this.afterCancelEvent) {
			this.afterCancelEvent.emit();
		}

		this.closeDialog();
	}

}
