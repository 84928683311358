import { Guid } from "guid-typescript";
import { DeliveryDeviceConditions } from "./delivery-device-conditions.enum";

export class DeliveryEquipment {

	number: Guid;
	id: number = 0;
	serialNumber: string;
	deviceTypeId: number;
	deviceTypeName: string;
	deviceSupplierId: number;
	deviceSupplierName: string;
	deviceModelId: number;
	deviceModelName: string;
	nomenclature: string;
	deviceConditionId: number = DeliveryDeviceConditions.default;
	confirmationSerialNumber: string;
	reserveSuccess: boolean = false;
	connectionTypeId: number;
	/** Вид оборудования */
	deviceKindId?: number;
	isComponent: boolean;
	tryingReturn: boolean;
	serialNumberIsConfirmed: boolean;
	reserveResultId: number;
	warehouseMoveFromName: string;

	warehouseMoveFromId: number;
	warehouseAreaMoveFromId: number;

	get deviceIsBroken(): boolean {
		return this.deviceConditionId == DeliveryDeviceConditions.broken;
	}
}
