import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { StatePersistingService } from "../../../shared/services/state-persisting.service";
import { AttachmentTypeConfigurationListItemDto } from "../../../shared/models/attachment/attachment-type-configuration-list-item-dto";
import { AttachmentTypeConfigurationsService } from "../../../shared/services/attachment-type-configurations.service";
import { ReportsService } from "../../../shared/services/reports.service";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";

@Component({
	selector: 'attachment-type-configurations-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class AttachmentTypeConfigurationsListPage extends ListPageBase<AttachmentTypeConfigurationListItemDto> {

	constructor(
		protected appService: AppService,
		protected router: Router,
		protected dataService: AttachmentTypeConfigurationsService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Конфигурации типов вложений';
	}

	onListPageInit(): void {

	}

	processListItems(items: AttachmentTypeConfigurationListItemDto[]): void {

	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/categories/attachment-type-configuration', dataItem.attachmentTypeConfigurationId]);
	}

}
