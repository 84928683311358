import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../../../app.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject, of } from "rxjs";
import { OSMService } from "../../../../shared/services/oSM.service";
import { SegmentData } from "../../../../shared/models/oSM/segment-data";
import { CheckedState } from "@progress/kendo-angular-treeview";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { SegmentDto } from "../../../../shared/models/oSM/segment-dto";

@Component({
	selector: 'add-service-center-region',
	templateUrl: './add-service-center-region.page.html',
	styleUrls: [
		'./add-service-center-region.page.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class AddServiceCenterRegionPage implements OnInit {
	public form: FormGroup;

	private _destroy$ = new Subject<boolean>();

	nomenclatures: string[] = [];
	data = [];
	initialData: SegmentData[] = [];
	searchValue = "";


	_checkedKeys: number[] = [];
	get checkedKeys(): number[] {
		return this._checkedKeys;
	}
	@Input() set checkedKeys(value: number[]) {
		this._checkedKeys = value;
	}
	@Input() nomenclatureId: number;
	@Input() nomenclatureName: string;
	@Output() onContinueEvent = new EventEmitter<any>();
	
	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		private osmSerivice: OSMService,
		private dialog: DialogRef,
		private fb: FormBuilder
	) {
		this.appService.pageTitle = 'Новая номенклатура';
		this._buildForm();
	}
	public key = "osmRegionId";

	get cardHeight(): number {
		return window.innerHeight - 200;
	}

	ngOnInit(): void {
		this._loadData();		
	}
	uncheckAllChildren(node: SegmentData, toExclude: number[]){
		if (!node || !node.includedSegments.length) {
            return;
        }
        for (const segment of node.includedSegments) {
            for (const childNode of segment.segmentData) {
				const index = this.checkedKeys.indexOf(childNode.osmRegionId, 0);

				if (index > -1) {
					toExclude.push(childNode.osmRegionId);
				}
				this.uncheckAllChildren(childNode, toExclude);
            }
        }
        return true;
	}

	nodeIncludesString(node: SegmentData, value: string){
		var value = value.toLowerCase();
		if (node.value.toLowerCase().includes(value)){
			return true;
		}
		if (!node || !node.includedSegments.length) {
            return false;
        }
        for (const segment of node.includedSegments) {
			if (segment.segmentData.some(x => x.value.toLowerCase().includes(value)))
			{
				return true;
			}
            for (const childNode of segment.segmentData) {
				let childNodeContains = this.nodeIncludesString(childNode, value);
				if (childNodeContains){
					return true;
				}
            }
        }
        return false;
	}

	expandCheckedParents(node: SegmentData, keys: number[]): boolean{
		var expanded = false;
		var anyChildExpanded = false;
		if (!node || !node.includedSegments.length) {
            return false;
        }
		for (const segment of node.includedSegments) {
            for (const childNode of segment.segmentData) {
				if (this.expandCheckedParents(childNode, keys)){
					anyChildExpanded = true;
				}
            }
        }
		if (anyChildExpanded || node.includedSegments.some(x => x.segmentData.some(x => keys.includes(x.osmRegionId)))){
			this.expandedKeys.push(node.osmRegionId);
			expanded = true;
		}
		return expanded;
	}

	public isChecked = (dataItem: any, index: string): CheckedState => {
		if (this.containsItem(dataItem)) {
		  return "checked";
		}
	
		if (this.isIndeterminate(dataItem.items)) {
		  return "indeterminate";
		}
	
		return "none";
	};

	public isDisabled = (dataItem: SegmentData): boolean => {
		return !this.nodeIncludesString(dataItem, this.searchValue);
	};

	public isExpanded = (dataItem: any, index: string) => {
		return (this.expandedKeys.indexOf(dataItem.osmRegionId) > -1) 
		|| (this.searchValue.length > 0 && this.nodeIncludesString(dataItem, this.searchValue));
	};
	
	private containsItem(item: any): boolean {
		return this.checkedKeys.indexOf(item[this.key]) > -1;
	}

	public expandedKeys: number[] = [];
	public handleCollapse(node) {
		this.expandedKeys = this.expandedKeys.filter((k) => k !== node.dataItem.osmRegionId);
	  }
 
   	public handleExpand(node) {
	 	this.expandedKeys = this.expandedKeys.concat(node.dataItem.osmRegionId);
   	}
	
	private isIndeterminate(items: any[] = []): boolean {
		let idx = 0;
		let item;
	
		while ((item = items[idx])) {
		  if (this.isIndeterminate(item.items) || this.containsItem(item)) {
			return true;
		  }
	
		  idx += 1;
		}
	
		return false;
	}

	public editHandler() {
		var filteredKeys = this.excludeChildren();
		this.onContinueEvent.emit(filteredKeys);
		this.dialog.close();
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public _loadData(){
		this.osmSerivice.getRegions("State").subscribe(x => {
			this.initialData = x.segmentData;
			this.data = this.initialData;

			this.initialData.forEach(element => {
				this.expandCheckedParents(element, this.checkedKeys);
			});
			
		});
	}

	private excludeChildren(){
		var toExclude = [];
        this.checkedKeys.forEach(key => {
            const node = this.findNodeByKey(this.initialData, key);
            this.uncheckAllChildren(node, toExclude);
        });
		return this.checkedKeys.filter(x => !toExclude.includes(x))
    }

	private findNodeByKey(nodes: SegmentData[], key: any): SegmentData {
		for (const node of nodes) {
			if (node.osmRegionId === key) {
				return node;
			}
			const childNodes = [].concat(...node.includedSegments.map(seg => seg.segmentData));
			const childNode = this.findNodeByKey(childNodes, key);
			if (childNode) {
				return childNode;
			}
		}
		return null;
	}
	private _buildForm(): void {
		this.form = this.fb.group({
            input: [null]
        });
	}

	public fetchChildren(node: SegmentData): Observable<SegmentData[]> {
		var values = node.includedSegments.map(x => x.segmentData).reduce((a, b) => a.concat(b), []);
		return of(values);
	}
	
	public hasChildren(node: SegmentData): boolean {
		return node.includedSegments.some(x => x.segmentData.length > 0);
	}
}
