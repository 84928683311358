import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { from, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { AgreementListItem } from '../models/agreement/agreement-list-item';
import { Agreement } from '../models/agreement/agreement';
import { ResponseContentType } from '@angular/http';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { NotificationType } from '../../core/services/notification-type';
import { filter, take, tap } from 'rxjs/operators';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { Expertise } from './../models/reports/expertise';
import { UploadAttachmentsModel, UploadFile } from '../models/attachment';
import { GeneralEquipmentReportQuery } from '../models/reports/general-equipment-report-query';
import { GeneralEquipmentReportDto } from '../models/general-equipment-report/general-equipment-report-dto';
import { KendoDataSourceHelper } from '../../core/helpers/kendo-data-source-helper';
import { ExpertizeByPeriodItem } from '../models/reports/expertiseperioditem';
import { TidObjectsTimeQuery } from '../models/reports/tid-objects-time-query';
import { TidObjectsTimeDto } from '../models/tid-objects-time/tid-objects-time.dto';
import { ListViewModel } from '../models/core/ListViewModel';
import { GeneralEquipmentExcelReportQuery } from '../models/reports/GeneralEquipmentExcelReportQuery';
import { ReportExampleFileDataDto } from '../models/reports/report-example-file-data.dto';

@Injectable()
export class ReportsService extends DataService<Agreement, AgreementListItem> {

	constructor(http: HttpClient, notificationService: NotificationService, private kendoDataSourceHelper: KendoDataSourceHelper) {
		super(http, notificationService);
		this.controllerName = 'reports';
	}

	public getOvertime(type: string, start?: string, end?: string, excludeExpertises?: boolean, serviceCentersIds?: number[]): Observable<any> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/overtime?start=${start}&end=${end}&type=${type}&excludeExpertises=${excludeExpertises}&serviceCentersIds=${serviceCentersIds}`;
		return this.http.get(url, { responseType: 'blob' });
	}

	public getMk(start?: string, end?: string): Observable<any> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/mk-report`;
		return this.http.post(url, {dateFrom: start, dateTo: end}, { responseType: 'blob' });
	}

	public getStopReason(FileName?: string, FileData?: File): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('fileName', FileName);
		formData.append('fileData', FileData, FileData.name);
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/stopping-reason-report`;
		return this.http.post(url, formData, { responseType: 'blob' });
	}

	public downloadStopReasonExample(): Observable<any> {
		return this.http.get<ActionDataResult<ReportExampleFileDataDto>>(`${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/download-stop-file-example`)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка при скачивании файла',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {
			if (errorResponse.status === 904)
				return;
			this.notificationService.error({
				title: 'Ошибка при скачивании файла',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});
		}));
	}

	public generalEquipmentReportExcel(data: GeneralEquipmentExcelReportQuery): Observable<any> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/general-equipment-excel-report`;
		return this.http.post(url, data, { responseType: 'blob' });
	}

	public generalEquipmentReport(generalEquipmentReportQuery: GeneralEquipmentReportQuery): Observable<ActionDataResult<GeneralEquipmentReportDto[]>> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/general-equipment-report`;
		return this.http.post<ActionDataResult<GeneralEquipmentReportDto[]>>(url, generalEquipmentReportQuery)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		},
		errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}

	public tidObjectsTime(state: DataSourceRequestState, tidObjectsTimeQuery: TidObjectsTimeQuery): Observable<ListViewModel<TidObjectsTimeDto>> {
		var queryStr = `${toDataSourceRequestString(state)}`;

		//хак, это ошибка компонента грид
		queryStr = queryStr.replace(/T\d\d-00-0/g, 'T00-00-00');
		
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/tid-objects-time?${queryStr}`;

		return this.http.post<ActionDataResult<ListViewModel<TidObjectsTimeDto>>>(url, tidObjectsTimeQuery)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		},
		errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}))
		.pipe(take(1))
		.pipe(filter(x => x.isSuccessful))
		.map(x => x.data);
	}

	public getPsb(start?: string, end?: string): Observable<any> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/psb-report`;
		return this.http.post(url, {dateFrom: start, dateTo: end}, { responseType: 'blob' });
	}

	public getShelfLife(start?: string, end?: string, nomenclatureId?: number, warehouseCity?: string[], bankOwnerCodes?: string[]): Observable<any> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/shelf-life-report`;
		return this.http.post(url, {beginDate: start, endDate: end, nomenclatureId, warehouseCity, bankOwner: bankOwnerCodes}, { responseType: 'blob' });
	}
	
	public getStockExchangeRequests(regionId?: number): Observable<any> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/stock-exchange-requests-report`;
		return this.http.post(url, { regionId }, { responseType: 'json' });
	}

	public getReturnedEquipment(start?: string, end?: string): Observable<any> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/retequip-report`;
		return this.http.post(url, {dateFrom: start, dateTo: end}, { responseType: 'blob' });
	}

	public getExpertisesData(serialNumbersText: string, serialNumbersFile: File): Observable<ActionDataResult<Expertise[]>> {
		const formData: FormData = new FormData();
		if (serialNumbersText !== null){
			formData.append('serialNumbersText', serialNumbersText);
		}

		if (serialNumbersFile !== null){
			formData.append('serialNumbersFile', serialNumbersFile, serialNumbersFile.name);
		}

		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/expertises-data`;
		return this.http.post<ActionDataResult<Expertise[]>>(url, formData)
			.pipe(tap(resp => {

					if (!resp.isSuccessful) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}

				},
				errorResponse => {

					if (errorResponse.status === 904)
						return;

					this.notificationService.error({
						title: 'Ошибка',
						message: errorResponse.error.errorDescription,
						notificationType: NotificationType.SweetAlert
					});

				}));

	}

	public getExpertisesPeriod(dateFrom: string, dateTo: string): Observable<ActionDataResult<ExpertizeByPeriodItem[]>> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/expertises-period`;
		return this.http.post<ActionDataResult<ExpertizeByPeriodItem[]>>(url, { dateFrom, dateTo }, httpOptions)
			.pipe(tap(resp => {
				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
				
			},
				errorResponse => {
					if (errorResponse.status === 904)
						return;

					this.notificationService.error({
						title: 'Ошибка',
						message: errorResponse.error.errorDescription,
						notificationType: NotificationType.SweetAlert
					});

				}));

	}

	getPsbRequestsMatchingReport(fileName: string, fileData: string, recoverMissingStatuses: boolean): Observable<ActionDataResult<UploadFile>> {
		return this.postQuery<UploadFile>(`${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/psb-requests-matching`, {
			fileName: fileName,
			fileData: fileData,
			recoverMissingStatuses: recoverMissingStatuses
		}, null, false);
	}

	getPsbRequestsMatchingSampleFile(): Observable<string> {
		return this.getQuery<string>(`${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/psb-requests-matching-sample`);
	}

	getTimeFund(start: string, end: string, isCompletion: boolean, clientIds: number[], requestTypeIds: number[], requestStatusIds: number[]): Observable<any> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/time-fund-report`;
		return this.http.post(url, {start, end, isCompletion, clientIds, requestTypeIds, requestStatusIds}, { responseType: 'json' });
	}

	getExchangeSmzReport(start?: string, end?: string, regionId?: number): Observable<any> {
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/exchange-smz-requests-report`;
		return this.http.post(url, { dateFrom: start, dateTo: end, regionId }, { responseType: 'json'});
	}
}
