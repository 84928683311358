import { Attachment } from '../attachment';
import { AgreementListItem } from './agreement-list-item';
import { AgreementService } from './agreement-service';
import { AgreementDistanceCalculationType } from './agreement-distance-calculation-type';
import { SlaCutoff } from '../sla/sla-cutoff';

export class Agreement extends AgreementListItem {
	name: string;
	agreementServiceTypeId?: number = null;
	agreementTidServiceOnDismantleTypeId?: number = null;
	slaCutoffs: SlaCutoff[] = [];
	agreementServices: AgreementService[] = [];
  attachments: Attachment[] = [];
	agreementDistanceCalculationTypes: AgreementDistanceCalculationType[] = [];
}
