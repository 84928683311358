import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { RequestRegistrationFilterListItemDto } from '../../../shared/models/request-registration-filter/request-registration-filter-list-item-dto';
import { RequestRegistrationFiltersService } from '../../../shared/services/request-registration-filters.service';
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';

@Component({
	selector: 'automatic-registration-requests',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AutomaticRegistrationRequestsListPage extends ListPageBase<RequestRegistrationFilterListItemDto> {

	userGroups: KeyValueObject[] = [];
	serviceCenters: KeyValueObject[] = [];
	bankEquerContragents: KeyValueObject[] = [];
	public roles: KeyValueObject[] = [];

	constructor(
		appService: AppService,
		router: Router,
		protected dataService: RequestRegistrationFiltersService,
		private lookupService: LookupService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService
	) {
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Автоматическая регистрация заявок';
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/admin/automatic-registration-request', dataItem.requestRegistrationFilterId]);
	}

	onListPageInit(): void {
	}
	processListItems(items: RequestRegistrationFilterListItemDto[]): void {
	}
}
