import { Injectable, OnDestroy } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { MultipleError } from '../models/request/multiple-error';
import { environment } from "../../../environments/environment";
import { Observable, Subject, of } from 'rxjs';
import { FinancialReconciliationReportResult } from '../models/request/financial-reconciliation-report-result';

@Injectable({
    providedIn: 'root'
})
export class SignalRNotificationsService {
    public context: any;
    
    private initialized: boolean = false;
    private reconciliationInitialized = false;
    
    private connectionEstablished: boolean = false;
    private connectionEstablishedEvent = new Subject<any>();

    private connection: signalR.HubConnection;
    private messageSubjects: { [key: string]: Subject<any> } = {};

    private onDestroy$: Subject<void> = new Subject<void>();

    constructor() {
    }

    public initReconciliation(file: File){
        if (!this.reconciliationInitialized){
            this.connection.on('FinancialReconciliationCompleted', (response) => {
                this.financialReconciliationCompleted(response);
            });

            this.reconciliationInitialized = true;
        }

        this.SignalRConnected(file);
    }

    private financialReconciliationCompleted(response: FinancialReconciliationReportResult): void {
        if (this.onFinancialReconciliationCompleted && typeof this.onFinancialReconciliationCompleted === 'function') {
            this.onFinancialReconciliationCompleted(this.context, response);
        } else {
            ///todo: сделать обработку результата, если пользователь ушел со страницы заявок
        }
    }

    private SignalRConnected(file: File): void {
        if (this.onSignalRConnected && typeof this.onSignalRConnected === 'function') {
            this.onSignalRConnected(this.context, file);
        } else {
            ///todo: сделать обработку результата, если пользователь ушел со страницы заявок
        }
    }

    public onFinancialReconciliationCompleted = (context: any, response: FinancialReconciliationReportResult) => {};

    public onSignalRConnected = (context: any, file: File) => {};

    closeConnection(){
        if (this.connection) {
            this.connection.stop().then(() => {
                console.log('SignalR connection stopped');
                this.connection = undefined;
            }).catch(err => {
                console.error('Error stopping SignalR connection:', err);
            });
        }
    }

    startConnection(): Observable<any> {
        if (this.initialized && this.connectionEstablished){
            return of(true);
        }
        if (this.initialized){
            return this.connectionEstablishedEvent.asObservable();
        }

        window.addEventListener('beforeunload', () => {
            // Close the SignalR connection
            this.connection.stop().then(() => {
                console.log('SignalR connection stopped');
            }).catch(err => {
                console.error('Error stopping SignalR connection:', err);
            });
        });

        this.initialized = true;

        const options: signalR.IHttpConnectionOptions = {
            accessTokenFactory: () => {
                let authUserJson = localStorage.getItem("authUser");
                let authUser = JSON.parse(authUserJson);
                return authUser.bearerToken;
            }
        };

        this.connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(`${environment.baseUrl}/hubs/notifications`, options)
            .withAutomaticReconnect()
            .build();
        
        this.connection
            .start()
            .then(() => {
                console.log('Connection started');
                this.connectionEstablishedEvent.next();
                this.connectionEstablished = true;
            })
            .catch((err) => console.log('Error while starting connection: ' + err));
          
        this.connection.onclose((error) => {
            console.log('SignalR disconnected.');
            console.log(error);
        });

        return this.connectionEstablishedEvent.asObservable();    
    }

    public addToGroup(groupName: string): void {
        this.connection.invoke('AddToGroup', groupName)
          .catch(err => console.error(err));
    }

    public getOrCreateMessageListener(messageAddress: string): Observable<any> {
        if (this.messageSubjects[messageAddress]) {
            return this.messageSubjects[messageAddress].asObservable();
        }

        let messageSubject = new Subject<any>();
        this.messageSubjects[messageAddress] = messageSubject;        
        
        this.connection.on(messageAddress, (message) => {
            messageSubject.next(message);
        });

        return this.messageSubjects[messageAddress].asObservable();
    }

    ngOnDestroy(): void {
        Object.values(this.messageSubjects).forEach(subject => subject.complete());
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}