import { DataSourceRequestState } from "@progress/kendo-data-query";

export class TidObjectsTimeQuery {
    clientsIds: number[] = [];
    customerServiceDateStartMin: Date;
    customerServiceDateStartMax: Date;
    customerServiceDateEndMin: Date;
    customerServiceDateEndMax: Date;
    customerServiceDaysStart: Date;
    customerServiceDaysEnd: Date;
}
