export class KeyValueObject {

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }

	id: number;
    name: string;
    groupName: string;
    integraCode: string;
    disabled: boolean;
}
