import { Observable, forkJoin, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { Component } from "@angular/core";
import { KeyValueObject } from "../../../../models/core/KeyValueObject";
import { DeviceConditionsService } from "../../../../services/device-conditions.service";
import { DismantledSeriesGeneralPropertiesData } from "./dismantled-series-general-properties-data";

@Component({
	selector: 'dismantled-series-general-properties',
	templateUrl: './dismantled-series-general-properties.component.html',
	styleUrls: ['./dismantled-series-general-properties.component.scss',
		'../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class DismantledSeriesGeneralProperties extends BaseFlowComponent<DismantledSeriesGeneralPropertiesData> {

	sslMask: string = "AA AA AA";

	notBrokenDeviceConditions: KeyValueObject[] = [];
	brokenDeviceConditions: KeyValueObject[] = [];
	isLoaded: boolean = false;

	constructor(
		public deviceConditionsService: DeviceConditionsService) {
		super();
	}

	initData() {
		this.isLoaded = false;
		const notBrokenDeviceConditions$ = this.deviceConditionsService.getConditionsByContragentsConditionType(this.data.contragentId, false);
		const brokenDeviceConditions$ = this.deviceConditionsService.getConditionsByContragentsConditionType(this.data.contragentId, true);

		if (this.data.cryptoKey && this.data.cryptoKey.length > 0) {
			this.data.cryptoKey = this.data.cryptoKey.replace(/ /g, '');
		}

		forkJoin([notBrokenDeviceConditions$, brokenDeviceConditions$])
			.subscribe((response) => {
				this.notBrokenDeviceConditions = response[0].map(x => new KeyValueObject(x.deviceConditionId, x.name));
				this.brokenDeviceConditions = response[1].map(x => new KeyValueObject(x.deviceConditionId, x.name));
				this.data.isDeviceBroken
					? this.setSingleDeviceCondition(this.brokenDeviceConditions)
					: this.setSingleDeviceCondition(this.notBrokenDeviceConditions);
				this.isLoaded = true;
			});
	}

	saveChanges(): Observable<any> {

		this.data.deviceConditionName = this.currentDeviceConditions.find(x => x.id == this.data.deviceConditionId).name;
		if (this.data.cryptoKey != null && this.data.cryptoKey.length > 0) {
			let key = this.data.cryptoKey;
			this.data.cryptoKey = key.substring(0, 2) + ' ' + key.substring(2, 4) + ' ' + key.substring(4, 6);
		}
		return of({});
	}

	changeDeviceBroken() {
		let currentDeviceIsBroken = !this.data.isDeviceBroken;
		if (!currentDeviceIsBroken) {
			this.data.brokenDescription = null;
			this.data.hasCryptoKey = true;
			this.setSingleDeviceCondition(this.notBrokenDeviceConditions);
		}
		else {
			this.data.hasCryptoKey = false;
			this.setSingleDeviceCondition(this.brokenDeviceConditions);
		}
	}

	setSingleDeviceCondition(deviceConditions: KeyValueObject[] = []): void {
		if (deviceConditions.length == 1) {
			this.data.deviceConditionId = deviceConditions[0].id;
		} else {
			this.data.deviceConditionId = null;
		}
	}

	get currentDeviceConditions(): KeyValueObject[] {
		return this.data.isDeviceBroken ? this.brokenDeviceConditions : this.notBrokenDeviceConditions;
	}

	get cryptoKeyValid(): boolean {
		return !this.data.hasCryptoKey || this.data.cryptoKey == null || this.data.cryptoKey == '' ||
			this.data.cryptoKey.trim().length == 6 || this.data.cryptoKey.trim().length == 8;
	}
}
