import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { NotificationType } from '../../../core/services/notification-type';
import { NotificationService } from '../../../core/services/notification.service';
import { ActionDataResult } from '../../models/core/ActionDataResult';
import { ExtendedHierarchyLookupModel } from '../../models/core/ExtendedHierarchyLookupModel';
import { EngubatorRegionSegment } from '../../models/engubator/engubator.region';
import { EngubatorDataService } from './engubator.data.service';

@Injectable()
export class EngubatorRegionSegmentsService extends EngubatorDataService<EngubatorRegionSegment, EngubatorRegionSegment>{
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'regions';
	}

	protected getNewTItem(): EngubatorRegionSegment {
		return new EngubatorRegionSegment();
	}

	public getHierarchyData(): Observable<ExtendedHierarchyLookupModel<EngubatorRegionSegment>[]> {

		return this.http.get<ActionDataResult<EngubatorRegionSegment[]>>(`${this.baseUrl()}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === this.unauthorizedCode)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => this.regionSegmentsToHierarchyLookupModel(x.data))
			;
	}

	//index массив для передачи параметра byRef
	private regionSegmentsToHierarchyLookupModel(segments: EngubatorRegionSegment[], index: number[] = [0]): ExtendedHierarchyLookupModel<EngubatorRegionSegment>[] {
		var result: ExtendedHierarchyLookupModel<EngubatorRegionSegment>[] = [];
		var parentId = index[0] == 0 ? null : index[0];
		segments.forEach((val) => {
			result.push({ id: ++index[0], parentId: parentId, name: val.name, entity: val });
			if (val.regionSegments)
				result = result.concat(this.regionSegmentsToHierarchyLookupModel(val.regionSegments, index));
		});

		return result;
	}
}