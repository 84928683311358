import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../../core/services/data.service";
import { NotificationService } from "../../core/services/notification.service";
import { ServiceCategoryItem } from "../models/services/service-category-item";
import { ServiceCategoryListItem } from "../models/services/service-category-list-item";
import { ServiceCategoryWithModeLookup } from '../models/services/service-category-with-mode-lookup';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from "../models/core/ActionDataResult";
import { KeyValueObject } from "../models/core/KeyValueObject";

@Injectable()
export class ServiceCategoriesService extends DataService<ServiceCategoryListItem, ServiceCategoryItem> {

	constructor(
		protected http: HttpClient,
		protected notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'service-categories';
	}

	getAllWithModes(): Observable<ServiceCategoryWithModeLookup[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/with-modes`);
	}

	getByAgreementId(agreementId: number): Observable<ActionDataResult<KeyValueObject[]>> {
		return this.http.get<ActionDataResult<KeyValueObject[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-by-agreement/${agreementId}`);
	}

	getByContragentsIds(contragentsIds: number[]): Observable<ActionDataResult<KeyValueObject[]>> {
		return this.http.get<ActionDataResult<KeyValueObject[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-by-contragents?contragentsIds=${contragentsIds.join(",")}`);
	}
}