import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotificationService } from "../../../../core/services/notification.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { CreateIntakeRequest } from "../../../../shared/models/box-berry/create-intake-request";
import { WarehousesService } from "../../../../shared/services/warehouses.service";
import { DialogRef } from "@progress/kendo-angular-dialog";

@Component({
	selector: 'add-intake-info',
	templateUrl: './add-intake-info.page.html',
	styleUrls: [
		'./add-intake-info.page.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class AddIntakeInfoPage implements OnInit {
	public form: FormGroup;
	cellPhoneMask: string = "+7(000) 000-0000";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

	private _destroy$ = new Subject<boolean>();
	@Input() data = new CreateIntakeRequest({});
	@Input() senderWarehouseId: number;

	@Output() onContinueEvent = new EventEmitter<any>();
	
	constructor(
		protected notificationService: NotificationService,
		private fb: FormBuilder,
		private warehouseService: WarehousesService,
		public dialog: DialogRef,
	) {
		this._buildForm();
	}

	ngOnInit(): void {
		this._loadData();	
	}

	saveData(){
		this.onContinueEvent.emit(this.data);
		this.dialog.close();
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public _loadData(){
		this.warehouseService.getWarehouseByAreaId(this.senderWarehouseId).subscribe(response => {
			this.data.zip = response.postCode;
			this.data.city = response.cityName;
			this.data.street = response.street;
			this.data.house = response.house;
			this.data.contactPerson = response.fullName;
			this.data.contactPhone = response.phoneNumber;
		})
	}

	private _buildForm(): void {
		this.form = this.fb.group({
            zip: [null, Validators.required],
			city: [null, Validators.required],
			street: [null, Validators.required],
			house: [null, Validators.required],
			contactPerson: [null, Validators.required],
			contactPhone: [null, Validators.required],
			takingDate: [null, Validators.required],
			comment: [null, Validators.required],
        });
	}
}