import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import {RequestPriority} from "../models/request-priority/request-priority";

@Injectable()
export class RequestPrioritiesService extends DataService<RequestPriority, RequestPriority> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'request-priorities';
	}
}
