import { Component, ViewEncapsulation } from "@angular/core";
import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { RequestStoppingReasonListItem } from "../../../shared/models/request/request-stopping-reason-list-item";
import { AppService } from "../../../app.service";
import { Router } from "@angular/router";
import { RequestStoppingReasonsService } from "../../../shared/services/request-stopping-reasons.service";
import { StatePersistingService } from "../../../shared/services/state-persisting.service";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { LookupService } from "../../../shared/services/lookup.service";
import { DataSourceRequestState } from "@progress/kendo-data-query";

@Component({
	selector: 'request-stopping-reasons-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RequestStoppingReasonsListPage extends ListPageBase<RequestStoppingReasonListItem> {
    
	requestStoppingReasonKinds: KeyValueObject[] = [];

	constructor(
		appService: AppService,
		router: Router,
		protected lookupService: LookupService,
		protected dataService: RequestStoppingReasonsService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Причины остановки заявки';
	}

    onListPageInit(): void {
		this.gridSettings.state.sort = [];
		this.setGridSettings(this.gridSettings);

		this.lookupService.getData('stopping-reason-kinds', null).subscribe((data) => {
			this.requestStoppingReasonKinds = data;
		});
    }

	processListItems(items: RequestStoppingReasonListItem[]): void 
	{ }

	cellClick({ dataItem, columnIndex }: any): void {
		if (columnIndex > 2)
			return;

		this.router.navigate(['/categories/request-stopping-reason', dataItem.requestStoppingReasonId]);
	}

	createNewReason() {
		var reason = new RequestStoppingReasonListItem();
		return reason;
	}

	saveHandler({ sender, dataItem, rowIndex }) {
		if (dataItem.requestStoppingReasonKindId == null || dataItem.requestStoppingReasonKindId == -1)
			dataItem.requestStoppingReasonKindId = null;

		let operation = !dataItem.requestStoppingReasonId
			? this.dataService.create(dataItem)
			: this.dataService.update(dataItem, "Изменения причины остановки");

		operation.subscribe(() => {
			this.loadData();
			sender.closeRow(rowIndex);
		});
	}
}