import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from "@angular/core";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { Agreement } from "../../../shared/models/agreement/agreement";
import { Attachment, UploadFile } from "../../../shared/models/attachment";
import { AttachmentsService } from "../../../shared/services/attachments.service";
import { saveAs } from 'file-saver';
import { UploadAttachmentsComponent } from "../../../shared/components/upload-attachments/upload-attachments.component";


@Component({
	selector: 'documents',
	templateUrl: './documents.component.html',
	styleUrls: []
})
export class Documents implements OnInit {
	@Input() entityViewModel: EntityViewModel<Agreement>;
	@Input() canEditForm: boolean;
	@Output() onSave: EventEmitter<any> = new EventEmitter();

	@ViewChild('uploadAttachments') uploadAttachments: UploadAttachmentsComponent;

	constructor(
		private attachmentsService: AttachmentsService
	) {
	}

	ngOnInit(): void {
	}

	onUploadFilesAdded(event: any): void {
		this.onSave.emit(event);
	}

	downloadFile(attachment: Attachment): void {
		this.attachmentsService.getAgreementAttachmentById(attachment.attachmentId)
			.subscribe(blob => {
				saveAs(blob, attachment.fileName, { type: 'application/octet-stream' });
			});
	}

}
