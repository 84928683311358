import { CommentKindEnum } from "../../enums/comment-kind.enum";
import { ChangeStatusModel } from "./change-status-model";

export class PerformerUserGroupModel extends ChangeStatusModel {
    performerUserId: number;
    performerGroupId: number;
    comment: string;

    constructor(requestId: number, newStatusId: number, performerUserId: number, performerUserGroupId: number, comment: string, commentKind: CommentKindEnum = CommentKindEnum.internal){
        super(requestId, newStatusId);
        this.requestId = requestId;
        this.newStatusId = newStatusId;
        this.performerGroupId = performerUserGroupId;
        this.performerUserId = performerUserId;
        this.comment = comment;
        this.commentKind = commentKind;
    }
}