import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {forkJoin, Observable, Subject} from 'rxjs';
import {DialogRef} from '@progress/kendo-angular-dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {RequestUninstallDevicesService} from '../../../services/request-uninstall-devices.service';
import {DeviceValidation} from '../../../models';
import { switchMap, takeUntil, tap} from 'rxjs/operators';
import {KeyValueObject} from '../../../models/core/KeyValueObject';
import {HierarchyLookupModel} from '../../../models/core/HierarchyLookupModel';
import {groupBy} from '@progress/kendo-data-query';
import {LookupService} from '../../../services/lookup.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {FilterUtil} from '../../../utils';
import { NewRequestDevice } from '../../../models/request/new-request/new-request-device';
import { AppService } from '../../../../app.service';
import { Attachment, UploadAttachmentsModel, UploadFile } from '../../../models/attachment';
import { HttpEventType } from '@angular/common/http';
import { AttachmentsService } from '../../../services/attachments.service';
import { AttachmentType, RequestType } from '../../../enums';
import { ActivitiesService } from '../../../services/activities.service';
import { NotificationType } from '../../../../core/services/notification-type';
import { DeviceKindEnum } from '../../../enums/device-kind.enum';
import { DeviceTypesService } from '../../../services/device-types.service';
import { KeyValueDataObject } from '../../../models/core/KeyValueDataObject';
import { DeviceConditionsService } from '../../../services/device-conditions.service';
import { DeviceConditionDto } from '../../../models/device/device-condition.dto';
import { DeviceConditionEnum } from '../../../enums/device-conditions.enum';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-set-device-serial-numbers',
  templateUrl: './set-device-serial-numbers.component.html',
  styleUrls: ['./set-device-serial-numbers.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class SetDeviceSerialNumbersComponent implements OnInit, OnDestroy {

	@Input() public index: number;
	@Input() public length: number;

	@Input() public requestId: number;
	@Input() public reinitForm: boolean = false;
	@Input() public requestTypeId: number;
	@Input() public activityId: number;
	@Input() public device: NewRequestDevice;
	@Input() public devices: NewRequestDevice[] = null;
	@Input() public contragentId: number;

	@Output() public  continueEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public  checkNextSerialNumberEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public  cancelEmitter: EventEmitter<void> = new EventEmitter();
	get cardHeight(): number {
		return (this.isMobileDevice && this.isEngineerInterface) ? 10000 : window.innerHeight - 325;
	}

	@ViewChild('barcodeLink') barcodeLink: ElementRef;
	@ViewChild('barcodeButton') barcodeButton: ElementRef;

	deviceTypes: KeyValueDataObject<number>[] = [];
	deviceConnectionTypes: KeyValueObject[] = [];
	deviceModels: HierarchyLookupModel[] = [];
	deviceSuppliers: KeyValueObject[] = [];
	deviceConditionsSource: DeviceConditionDto[] = [];
	deviceConditions: KeyValueObject[] = [];
	deviceConnectionTypesGrouped: any[] = [];
	deviceConnectionTypesGroupedDropdown: any[] = [];
	supplierModels: HierarchyLookupModel[] = [];
	uploadModel: UploadAttachmentsModel = null;
	isPartPrevious: boolean = false;
	lastSn: string = null;

	deviceConditionIdChanged: boolean = false;
	isDeviceBrokenChanged: boolean = false;

	public isShown: boolean = false;
	public isConfirmed: boolean = false;

	public validationMessage: string;
	public formDevice: FormGroup;
	public dataSaving = false;
	public uploadPercentages: number = null;
	public visibleAttachmentTypes: number[] = [<number>AttachmentType.uninstalledDevicesFotos];
	public attachments: Attachment[] = [];

	private _destroy$ = new Subject<boolean>();
	private isSnConfirmedChanging: boolean = false;
	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

  	constructor(
	  	public dialog: DialogRef,
		private _fb: FormBuilder,
		private _uninstallDevicesService: RequestUninstallDevicesService,
		private _lookupService: LookupService,
		private appService: AppService,
		private attachmentsService: AttachmentsService,
		private activitiesService: ActivitiesService,
		private _notificationService: NotificationService,
		private deviceTypesService: DeviceTypesService,
		private deviceConditionsService: DeviceConditionsService) {
	}

	get deviceTypeValue() {
		let deviceTypeId = this.formDevice.get('deviceTypeId').value;
		let deviceType = this.deviceTypes.find(dt => dt.id === deviceTypeId);
		if (!!deviceType)
			return deviceType.name;
	}

	public ngOnInit(): void {
		this._buildForm();

		const deviceTypes$ = this.deviceTypesService.getDeviceTypesWithKinds();
		const deviceConnectionTypes$ = this._lookupService.getData('device-connection-types', {
			skip: 0,
			take: null,
			filter: { logic: 'and', filters: [{ field: 'ParentDeviceConnectionTypeId', operator: 'isnotnull' }] }
		});
		const deviceModels$ = this._lookupService.getHierarchyData('device-models', {
			skip: 0,
			take: null,
			filter: { logic: 'and', filters: [{ field: 'Name', operator: 'isnotnullorempty' }] }
		});
		const deviceConditions$ = this.deviceConditionsService.getConditionsByContragentsConditionType(this.contragentId, this.formDevice.get("isDeviceBroken").value);

		forkJoin([deviceTypes$, deviceConnectionTypes$, deviceModels$, deviceConditions$])
			.subscribe(results => {
				this.deviceTypes = results[0];
				this.deviceConnectionTypes = results[1];
				this.deviceConnectionTypesGrouped = groupBy(results[1], [{field: 'groupName'}]);
				this.deviceConnectionTypesGroupedDropdown = this.deviceConnectionTypesGrouped;
				this.deviceModels = results[2];
				this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null).map(x => new KeyValueObject(x.id, x.name));
				
				if (this.device) {
					this.supplierModels = this.getDeviceModels(this.device.deviceSupplierId);
				}

				this.deviceConditionsSource = results[3];

				this.deviceConditions = this.deviceConditionsSource
					.map(x => new KeyValueObject(x.deviceConditionId, x.name));

				if (this.deviceConditions.length === 1) {
					this.formDevice.patchValue({
						deviceConditionId: this.deviceConditions[0].id,
					});
				} else {
					let currentValue = this.formDevice.get("deviceConditionId").value;
					this.formDevice.patchValue({
						deviceConditionId: this.deviceConditions.map(x => x.id).includes(currentValue) ? currentValue : null,
					});
				}
			});

		this.formDevice.get('isNotDefined').valueChanges
			.pipe(takeUntil(this._destroy$))
			.subscribe((isNotDefined: boolean) => {
				this.validationMessage = '';

				this.formDevice.get('isSnValid').setValue(false);

				this.formDevice.get('serialNumber')[isNotDefined ? 'disable' : 'enable']();
				this.formDevice.get('isSnConfirmed')[isNotDefined ? 'disable' : 'enable']();

				if (!isNotDefined) {
					this.updateValidation('serialNumber', !isNotDefined, this.device.serialNumber);
					this.updateValidation('deviceTypeId', isNotDefined, this.device.deviceTypeId);
					this.updateValidation('deviceConnectionTypeId', isNotDefined, this.device.deviceConnectionTypeId);
					this.updateValidation('deviceSupplierId', isNotDefined, this.device.deviceSupplierId);
					this.updateValidation('deviceModelId', isNotDefined, this.device.deviceModelId);
					this.updateValidation('deviceConditionId', isNotDefined, this.device.deviceConditionId);
				} else {
					this.formDevice.get('isSnConfirmed').setValue(false);
					
					this.updateValidation('serialNumber', !isNotDefined, null);
					this.updateValidation('deviceTypeId', isNotDefined, null);
					this.updateValidation('deviceConnectionTypeId', isNotDefined, null);
					this.updateValidation('deviceSupplierId', isNotDefined, null);
					this.updateValidation('deviceModelId', isNotDefined, null);
					this.updateValidation('deviceConditionId', isNotDefined, null);
				}

				if (this.isPart) {
					this.updateValidation('serialNumber', false, null);
					this.updateValidation('isSnConfirmed', false, true);
				}
			});

		this.formDevice.get('isSnConfirmed').valueChanges
			.pipe(takeUntil(this._destroy$))
			.subscribe((isSnConfirmed: boolean) => {
				if (!isSnConfirmed) {
					this.formDevice.get('isSnValid').setValue(false);
				}
		});

		this.formDevice.get('deviceTypeId').valueChanges
			.pipe(takeUntil(this._destroy$))
			.subscribe((deviceTypeId: number) => {
				let deviceType = this.deviceTypes.find(f => f.id === deviceTypeId);
				if (!!deviceType) {
					this.device.deviceKindId = deviceType.data;
					this.device.deviceTypeId = deviceTypeId;

					this.device.isNotDefinedSn = this.formDevice.get('isNotDefined').value;
					this.device.isDeviceBroken = this.formDevice.get('isDeviceBroken').value;
					this.device.serialNumber = this.formDevice.get('serialNumber').value;
					this.device.isSnConfirmed = this.formDevice.get('isSnConfirmed').value;
					this.device.brokenDescription = this.formDevice.get('brokenDescription').value;
					this.device.deviceTypeId = this.formDevice.get('deviceTypeId').value;
					this.device.deviceConnectionTypeId = this.formDevice.get('deviceConnectionTypeId').value;
					this.device.deviceSupplierId = this.formDevice.get('deviceSupplierId').value;
					this.device.deviceModelId = this.formDevice.get('deviceModelId').value;
					this.device.deviceConditionId = this.formDevice.get('deviceConditionId').value;

					if (!!this.device.serialNumber) {
						this.lastSn = this.device.serialNumber;
					}
				}
		});

		this.formDevice.get('isDeviceBroken').valueChanges
			.pipe(takeUntil(this._destroy$))
			.subscribe((isBroken: boolean) => {
				var newDeviceId = isBroken
					? <number>DeviceConditionEnum.broken
					: <number>DeviceConditionEnum.used;

				this.isDeviceBrokenChanged = true;

				if (!this.deviceConditionIdChanged) {
					this.formDevice.get("deviceConditionId").setValue(newDeviceId);
				} else {
					this.deviceConditionIdChanged = false;
				}
		});

		this.formDevice.get('deviceConditionId').valueChanges
			.pipe(takeUntil(this._destroy$))
			.subscribe((id: number) => {
				var isBroken = id === <number>DeviceConditionEnum.broken;

				this.deviceConditionIdChanged = true;

				if (!this.isDeviceBrokenChanged) {
					this.formDevice.get("isDeviceBroken").setValue(isBroken);
				} else {
					this.isDeviceBrokenChanged = false;
				}

			});

		if (this.device) {
			this.formDevice.patchValue({
				isNotDefined: this.device.isNotDefinedSn,
				isDeviceBroken: this.device.isDeviceBroken,
				serialNumber: this.device.serialNumber,
				isSnValid: false,
				isSnConfirmed: this.device.isSnConfirmed,
				brokenDescription: this.device.brokenDescription,
				deviceTypeId: this.device.deviceTypeId,
				deviceConnectionTypeId: this.device.deviceConnectionTypeId,
				deviceSupplierId: this.device.deviceSupplierId,
				deviceModelId: this.device.deviceModelId,
				deviceConditionId: this.device.deviceConditionId,
				validationModeId: 1, //Sn
			});

			if (!this.device.isDeviceBroken) {
				this.formDevice.get('brokenDescription').disable();
			}
		}
	}

	get isPart(): boolean {
		let isPartRes = this.device.deviceKindId === <number>DeviceKindEnum.component;
		
		if (this.isPartPrevious !== isPartRes 
			&& !!this.device) {
				this.isPartPrevious = isPartRes;
				if (isPartRes) {
					this.device.serialNumber = null;
				} else {
					this.device.serialNumber = this.lastSn;
				}
				this.resetFields();
				this.ngOnInit();
		}

		return isPartRes;
	}

	resetFields(): void {
		this.formDevice.get('serialNumber')['enable']();
		this.formDevice.get('isSnConfirmed')['enable']();
	}

	public changeDeviceBroken() {
		//тут почему-то isDeviceBroken работает наоборот
		let isDeviceBroken = this.formDevice.get("isDeviceBroken").value;
		this.deviceConditionsService.getConditionsByContragentsConditionType(this.contragentId, !isDeviceBroken)
		.subscribe(res => {
			let brokenDescriptionControl = this.formDevice.get("brokenDescription");
			
			if (isDeviceBroken) {
				brokenDescriptionControl.setValue(null);
				brokenDescriptionControl.disable();
			}
			else {
				brokenDescriptionControl.enable();
			}

			this.deviceConditionsSource = res;
			this.deviceConditions = this.deviceConditionsSource
				.map(x => new KeyValueObject(x.deviceConditionId, x.name));

			if (this.deviceConditions.length === 1) {
				this.formDevice.get("deviceConditionId").setValue(this.deviceConditions[0].id);
			} else {
				this.formDevice.get("deviceConditionId").setValue(null);
			}
		});
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public saveChanges() {
		const body = { 
				requestId: this.requestId,
				requestUninstallDeviceId: this.device.requestDeviceId,
				deviceKindId: this.device.deviceKindId,
				...this.formDevice.getRawValue()
			};

		this.validationMessage = null;
		this.dataSaving = true;
		this._uninstallDevicesService.validateSnPostQuery(body)
			.pipe(
				tap(() => this.dataSaving = false),
				takeUntil(this._destroy$))
			.subscribe((response: DeviceValidation) => {
				if (response.success) {
					if (response.validationRequired) {
						this.checkNextSerialNumberEmitter.next(true);

						if (this.devices !== null) {
							this.device = this.getNextDevice();
							if (this.device === null) {
								this.continueEmitter.next(true);
								this.uploadAttachedFilesForUninstallAndCloseNew(response.createdOrUpdatedRequestUninstallDeviceId, true);
								return;
							}
						}
						
						if (this.reinitForm) {
							this.resetFields();
							this.ngOnInit();
						}
					} else {
						this.continueEmitter.next(true);
						this.uploadAttachedFilesForUninstallAndCloseNew(response.createdOrUpdatedRequestUninstallDeviceId, true);
						return;
					}

					this.uploadAttachedFilesForUninstallAndCloseNew(response.createdOrUpdatedRequestUninstallDeviceId, false);
				} else {
					this.validationMessage = response.message;

					this.formDevice.get('isSnValid').setValue(response.isSnValid);
					this.formDevice.get('validationModeId').setValue(response.validationModeId);

					if (!response.isError) {
						this._notificationService.confirmation({
							title: 'Подтверждение',
							message: response.message,
							showCancelButton: true,
							cancelButtonText: 'Назад',
							confirmButtonText: 'Да',
							showCloseButton: false,
							enableHtml: true
						},
							() => {
								switch (this.formDevice.get('validationModeId').value) {
									case 1: //Sn
										this.formDevice.get('isSnConfirmed').setValue(true);
										this.formDevice.get('deviceTypeId').setValue(null);
										this.formDevice.get('deviceConnectionTypeId').setValue(null);
										this.formDevice.get('deviceSupplierId').setValue(null);
										this.formDevice.get('deviceModelId').setValue(null);
										this.formDevice.get('deviceConditionId').setValue(null);
										this.formDevice.get('isSnValid').setValue(true);
										this.formDevice.get('serialNumber')['disable']();
										break;

									case 2: //Params
										const bodyFinal = {
											requestId: this.requestId,
											requestUninstallDeviceId: this.device.requestDeviceId,
											deviceKindId: this.device.deviceKindId,
											...this.formDevice.getRawValue()
										};

										this._uninstallDevicesService.validateSnPostQuery(bodyFinal)
											.pipe(
												tap(() => this.dataSaving = false),
												takeUntil(this._destroy$))
											.subscribe((response: DeviceValidation) => {
												if (response.success) {
													if (response.validationRequired) {
														this.checkNextSerialNumberEmitter.next(true);

														if (this.devices !== null) {
															this.device = this.getNextDevice();
															if (this.device === null) {
																this.continueEmitter.next(true);
																this.uploadAttachedFilesForUninstallAndCloseNew(response.createdOrUpdatedRequestUninstallDeviceId, true);
																return;
															}
														}

														if (this.reinitForm) {
															this.resetFields();
															this.ngOnInit();
														}
													} else {
														this.continueEmitter.next(true);
														this.uploadAttachedFilesForUninstallAndCloseNew(response.createdOrUpdatedRequestUninstallDeviceId, true);
														return;
													}

													this.uploadAttachedFilesForUninstallAndCloseNew(response.createdOrUpdatedRequestUninstallDeviceId, false);
												} else {
													this.validationMessage = response.message;
													this.formDevice.get('isSnValid').setValue(response.isSnValid);
													this.formDevice.get('validationModeId').setValue(response.validationModeId);
													this._notificationService.error({
														title: 'Ошибка',
														message: response.message,
														notificationType: NotificationType.SweetAlert
													});
												}
											});
										break;
									default:
										break;
								}
							},
							() => { });
					} else {
						this._notificationService.error({
							title: 'Ошибка',
							message: response.message,
							notificationType: NotificationType.SweetAlert
						});
					}
				}
			});
	}

	@Output() public checkDevices: EventEmitter<{next: boolean, justValidate: boolean}> = new EventEmitter<{next: boolean, justValidate: boolean}>();

	public saveChanges2(justValidate: boolean, afterValidationFunction: Function = null) {

		const body = { 
				justValidate: justValidate,
				requestId: this.requestId,
				requestUninstallDeviceId: this.device.requestDeviceId,
				deviceKindId: this.device.deviceKindId,
				...this.formDevice.getRawValue()
			};

		this.validationMessage = null;
		this._uninstallDevicesService.validateSnPostQuery(body)
			.pipe(takeUntil(this._destroy$))
			.subscribe((response: DeviceValidation) => {
				if (response.success) {
					this.checkDevices.next({next: true, justValidate: justValidate});
					if (!justValidate)
						this.uploadAttachedFilesForUninstallAndCloseNew(response.createdOrUpdatedRequestUninstallDeviceId, true);
					if (afterValidationFunction != null)
						afterValidationFunction();
					return;
				} else {
					this.validationMessage = response.message;

					this.formDevice.get('isSnValid').setValue(response.isSnValid);
					this.formDevice.get('validationModeId').setValue(response.validationModeId);

					if (!response.isError) {
						this._notificationService.confirmation({
							title: 'Подтверждение',
							message: response.message,
							showCancelButton: true,
							cancelButtonText: 'Назад',
							confirmButtonText: 'Да',
							showCloseButton: false,
							enableHtml: true
						},
							() => {
								switch (this.formDevice.get('validationModeId').value) {
									case 1: //Sn
										this.formDevice.get('isSnConfirmed').setValue(true);
										this.formDevice.get('isSnValid').setValue(true);
										this.formDevice.get('serialNumber')['disable']();
										this.checkDevices.next({next: false, justValidate: justValidate});
										break;

									case 2: //Params
										const bodyFinal = {
											justValidate: justValidate,
											requestId: this.requestId,
											requestUninstallDeviceId: this.device.requestDeviceId,
											deviceKindId: this.device.deviceKindId,
											...this.formDevice.getRawValue()
										};

										this._uninstallDevicesService.validateSnPostQuery(bodyFinal)
											.pipe(takeUntil(this._destroy$))
											.subscribe((response: DeviceValidation) => {
												if (response.success) {
													this.checkDevices.next({next: true, justValidate: justValidate});
													if (!justValidate)
														this.uploadAttachedFilesForUninstallAndCloseNew(response.createdOrUpdatedRequestUninstallDeviceId, true);
													if (afterValidationFunction != null)
														afterValidationFunction();
													return;
												} else {
													this.validationMessage = response.message;
													this.formDevice.get('isSnValid').setValue(response.isSnValid);
													this.formDevice.get('validationModeId').setValue(response.validationModeId);
													this._notificationService.error({
														title: 'Ошибка',
														message: response.message,
														notificationType: NotificationType.SweetAlert
													});
													this.checkDevices.next({next: false, justValidate: justValidate});
													return;
												}
											});
										break;
									default:
										break;
								}
							},
							() => { this.checkDevices.next({next: false, justValidate: justValidate}); });
					} else {
						this._notificationService.error({
							title: 'Ошибка',
							message: response.message,
							notificationType: NotificationType.SweetAlert
						});
						this.checkDevices.next({next: false, justValidate: justValidate});
					}
				}
			});
	}

	public cancel() {
		if (this.dialog && typeof this.dialog.close === 'function') {
			this.dialog.close();
		}
		else if (this.cancelEmitter) {
			this.cancelEmitter.emit();
		}
	}

	private getNextDevice(): NewRequestDevice {
		let res = null;
		if (!!this.devices && this.devices.length > 0) {
			this.devices = this.devices.filter(f => f.requestDeviceId !== this.device.requestDeviceId);

			if (this.devices.length > 0) {
				res = this.devices[0];
			}
		}

		return res;
	}

	public getDeviceModels(supplierId: number): HierarchyLookupModel[] {
		return FilterUtil.getDeviceModelsBySuplierId(this.deviceModels, supplierId);
	}

	public valueChange(dataItem: any, label: string) {
		if (label === 'Производитель устройства') {
			this.supplierModels = this.getDeviceModels(dataItem.id);
		}
	}

	private _buildForm(): void {
		this.formDevice = this._fb.group({
			serialNumber: [null, Validators.required],
			isSnConfirmed: [null, Validators.requiredTrue],
			isSnValid: [null],
			isNotDefined: [null],
			deviceTypeId: [null, Validators.required],
			deviceConnectionTypeId: [null],
			deviceSupplierId: [null, Validators.required],
			deviceModelId: [null, Validators.required],
			deviceConditionId: [null, Validators.required],
			isDeviceBroken: [false],
			brokenDescription: [null],
			validationModeId: [null],
		});
	}

	private updateValidation(controlName: string, required: boolean, value: any, preserveValue: boolean = false): void {
		const snCtrl = this.formDevice.get(controlName);
		if (required) {
			snCtrl.setValidators(Validators.required);
		} else {
			snCtrl.clearValidators();
		}
		if (!preserveValue) {
			snCtrl.setValue(value);
			snCtrl.updateValueAndValidity();
		}
	}

	get attachmentsFiltered(): Attachment[] {
		if (!!this.device) {
			return this.attachments.filter(f => f.requestDeviceId === this.device.requestDeviceId);
		} else {
			return [];
		}
	}

	public onAttachmentAdded(attachments: UploadFile[]) {
		this.uploadModel = new UploadAttachmentsModel({ requestId: this.requestId, activityId: this.activityId, attachments });

		let availableRequestTypesIds = [
			<number>RequestType.uninstall,
			<number>RequestType.changeConfig,
		];

		if (availableRequestTypesIds.includes(this.requestTypeId)) {
			this.uploadModel = new UploadAttachmentsModel({
				requestId: this.requestId,
				requestUninstallDeviceId: this.device.requestDeviceId,
				attachments
			});

			let requestDeviceId: number = null;
			if (!!this.device) {
				requestDeviceId = this.device.requestDeviceId;
			}

			this.attachments = attachments.map(m => ({
				attachmentName: m.fileName,
				attachmentTypeId: m.attachmentTypeId,
				fileName: m.fileName,
				requestDeviceId: requestDeviceId,
			}) as Attachment);
		} else {
			this.attachmentsService.uploadWithResumableIfMobile(this.uploadModel)
				.pipe(tap(x => {
					switch (x.type) {
						case HttpEventType.UploadProgress:
							this.uploadPercentages = Math.round(x.loaded / (x.total != null ? x.total : 0) * 100);

							if (this.uploadPercentages == 100)
								this.uploadPercentages = null;
					}
				}))
				.pipe(switchMap(() => this.activitiesService.getById(this.activityId)))
				.subscribe(x => {
					this.attachments = x.entity.attachments;
				});
		}
	}

	private uploadAttachedFilesForUninstallAndCloseNew(createdOrUpdatedRequestUninstallDeviceId: number, closeDialog: boolean): void {
		let availableRequestTypesIds = [
			<number>RequestType.uninstall,
			<number>RequestType.changeConfig,
		];

		if (availableRequestTypesIds.includes(this.requestTypeId)
			&& !!this.uploadModel
			&& this.uploadModel.attachments.length > 0) {
			this.uploadModel.requestUninstallDeviceId = createdOrUpdatedRequestUninstallDeviceId;

			this.attachmentsService.uploadWithResumableIfMobile(this.uploadModel)
				.pipe(tap(x => {
					switch (x.type) {
						case HttpEventType.UploadProgress:
							this.uploadPercentages = Math.round(x.loaded / (x.total != null ? x.total : 0) * 100);

							if (this.uploadPercentages == 100)
								this.uploadPercentages = null;
					}
				}))
				.pipe(switchMap(() => this.activitiesService.getById(this.activityId)))
				.subscribe(x => {
					this.attachments = x.entity.attachments.filter(f => f.attachmentTypeId === <number>AttachmentType.uninstalledDevicesFotos);
				});
		}
	}

	getSimpleInputClass(control: any, controlName: string) {
		if (!control) return { 'sn-border': true };
		if (controlName === 'serialNumber') {
			if (control.disabled && !this.formDevice.get('isNotDefined').value) {
				return {
					'is-invalid': (control.touched || control.dirty) && control.invalid,
					'sn-border': this.formDevice.get('isSnValid').value
				};
			} else {
				return {
					'is-invalid': (control.touched || control.dirty) && control.invalid
				};
			}
		} else {
			return {
				'is-invalid': (control.touched || control.dirty) && control.invalid
			};
		}
	}

	get isMobileDevice() {
		return this.appService.isMobileDevice;
	}

	get hasAttachmentsOnMobile(): boolean {
		if (this.isMobileDevice) {
			return this.attachments.length > 0
		}

		return true;
	}

	get cantProceed(): boolean {
		let cantProceed = this.formDevice.invalid || (this.formDevice.pristine && !this.device) || this.dataSaving;

		if (!cantProceed && !this.isConfirmed)
			this.isConfirmed = true;

		if (cantProceed && this.isConfirmed)
			this.isConfirmed = false;

		return cantProceed;
	}
	

	public get isEngineerInterface(): boolean {
		return this.requestTypeId === <number>RequestType.changeConfig
			|| this.requestTypeId === <number>RequestType.uninstall
			|| this.requestTypeId === <number>RequestType.uninstallOld
			|| this.requestTypeId === <number>RequestType.serviceOrReplace;
	}

	public supplierOnChange(inputValue: any)
	{
		this.formDevice.controls['deviceModelId'].setValue(null);
		this.supplierModels = this.getDeviceModels(inputValue.id);
	}

	public handleConnectionTypeFilter(value: any) {
		let filtered = this.deviceConnectionTypes.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
		this.deviceConnectionTypesGroupedDropdown = groupBy(filtered, [{field: 'groupName'}]);	
	}
}
