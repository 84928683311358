import { ApplicationRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { Request } from '../../../shared/models/request/request';
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { DeliveryService } from "../../../shared/services/delivery/delivery.service";
import { TransportCompanyService } from "../../../shared/services/delivery/transport-company.service";
import { WarehousesService } from "../../../shared/services/warehouses.service";
import { DeliveryData } from "./classes/delivery-data";
import { DeliveryAddress } from "./classes/delivery-address";
import { NotificationService } from "../../../core/services/notification.service";
import { NotificationType } from "../../../core/services/notification-type";
import { DeliveryDataDog, Package } from "./classes/delivery-data-dog";
import { formatDate } from "@angular/common";
import { Observable } from "rxjs";
import { finalize, map, tap } from "rxjs/operators";
import { DeliveryTrack } from "./classes/delivery-track";
import { NgForm, Validators } from "@angular/forms";
import { GridSettings } from "../../../shared/models/grid/grid-settings";
import { AttachmentType, RequestStatus } from "../../../shared/enums";
import { UploadAttachmentsModel, UploadFile } from "../../../shared/models/attachment";
import { AttachmentsService } from "../../../shared/services/attachments.service";
import { RequestFile } from "../../../shared/models/attachment/request-file";
import { RequestFilesService } from "../../../shared/services/request-files.service";
import { UploadAttachmentsComponent } from "../../../shared/components/upload-attachments/upload-attachments.component";
import { BoxBerryService } from "../../../shared/services/delivery/box-berry.service";
import { CreateIntakeRequest } from "../../../shared/models/box-berry/create-intake-request";
import { DeliveryTransportCompanyDto } from "./classes/delivery-transport-company-dto";
import { DialogService } from "@progress/kendo-angular-dialog";
import { AddIntakeInfoPage } from "./add-intake-info/add-intake-info.page";
import { ParselFile } from "../../../shared/models/box-berry/parcel-file";
import { ChangeStatusModel } from "../../../shared/models/change-status-models/change-status-model";
import { RequestsService } from "../../../shared/services/requests.service";
import { IntlService } from "@progress/kendo-angular-intl";

/** Компонент для отображения информации о доставке */
@Component({
	selector: 'request-delivery',
	templateUrl: './request-delivery.component.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./request-delivery.component.scss',
	]
})

export class RequestDeliveryComponent implements OnInit {

	@ViewChild("deliveryForm") public deliveryForm: NgForm;
	@Input() request: Request;
	@ViewChild("uploadAttachments") uploadAttachmentsComponent: UploadAttachmentsComponent;
	files: RequestFile[] = [];
	filesLoading = false;
	filesUploading = false;

	cellPhoneMask: string = "+7(000) 000-0000";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

	deliveryPrice: Number;
	currentTk: DeliveryTransportCompanyDto;

	creatingDeliveryTk = false;
	creatingIntake = false;

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	public loading: boolean = true;
	public needToUpdateAndRecalculateDelivery = false;
	public needToUpdateDelivery = false;

	public deliveryData: DeliveryData;
	public packages: Package[] = [];

	public dataSaving = false;

	visibleAttachmentTypes = [<number>AttachmentType.transferAcceptanceCertificate, <number>AttachmentType.packingList];

	public processingApprovalStatus: string = "На согласовании";
	public completedApprovalStatus: string = "Согласовано";

	public approvalStatuses: KeyValueObject[] = new Array(
		new KeyValueObject(1, this.processingApprovalStatus),
		new KeyValueObject(2, this.completedApprovalStatus));

	public customAttachmentTypes: KeyValueObject[] = new Array(
		new KeyValueObject(AttachmentType.packingList, "Товарная накладная (1ст. подпись)"));

	public warehouseOwners: KeyValueObject[] = [];
	public transportCompanies: DeliveryTransportCompanyDto[] = [];
	public warehouseAddresses: DeliveryAddress[] = [];
	
	private attachments: UploadFile[] = [];

	constructor(
		private warehousesService: WarehousesService,
		private deliveryService: DeliveryService,
		private transportCompanyService: TransportCompanyService,
		private notificationService: NotificationService,
		private attachmentsService: AttachmentsService,
		private requestFilesService: RequestFilesService,
		private ref: ApplicationRef,
		private boxBerryService: BoxBerryService,
		private dialogService: DialogService,
		private requestsSerivce: RequestsService,
		private intl: IntlService,
	) { }

	get isCanEditDeliveryData(): boolean {
		return !this.deliveryData.approvalStatus || this.deliveryData.approvalStatus === "";
	}

	get isTkIntegration(): boolean {
		return this.currentTk && this.currentTk.createIntegration;
	}

	setCurrentTk(){
		this.currentTk = this.transportCompanies.find(x => x.id === this.deliveryData.currentTransportCompanyId);
	}

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 5,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "weight",
				title: "Вес, (гр)",
				filterable: false,
				width: 200,
			},
			{
				field: "length",
				title: "Длина, (см)",
				filterable: false,
				width: 200,
			},
			{
				field: "width",
				title: "Ширина, (см)",
				filterable: false,
				width: 200,
			},
			{
				field: "height",
				title: "Высота, (см)",
				width: 200,
				filterable: false,
			}
		]
	};

    ngOnInit(): void {
		
		this.deliveryData = new DeliveryData();
		this.deliveryData.externalId = this.request.integraCode;

		this.loadDropDownData();
		this.loadFiles();
		
		this.deliveryService.getDelivery(this.deliveryData.externalId).subscribe(data => {
			if (data)
				setTimeout(() => this.getSenderAndRecipientDataFromDog(data), 1000);
			else
				setTimeout(() => this.getSenderAndRecipientDataFromRequest(), 1000);
		});
    }

	ngAfterViewInit(): void {
		this.validateDeliveryForm();
	}

	private async validateDeliveryForm() {
		let i = 60;
		while (!this.deliveryForm) {
			await new Promise(resolve => setTimeout(resolve, 1000));
			i--;
			if (i === 0)
				break;
		}

		if (this.deliveryData.currentTransportCompanyId == 1) {
			this.deliveryForm.form.get("weight").setValidators([Validators.min(0), Validators.max(10000000)]);
			this.deliveryForm.form.get("length").setValidators([Validators.min(0), Validators.max(1500)]);
			this.deliveryForm.form.get("width").setValidators([Validators.min(0), Validators.max(1500)]);
			this.deliveryForm.form.get("height").setValidators([Validators.min(0), Validators.max(1500)]);
		}

	}

	loadFiles() {

		this.filesLoading = true;

		let requestFilesServiceListStateFilters = [];

		requestFilesServiceListStateFilters.push({ field: 'requestId', operator: 'eq', value: this.request.requestId });

		this.requestFilesService.list({
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: requestFilesServiceListStateFilters }
		}).subscribe(x => {
			
			this.files = x.data.filter(x => x.attachmentTypeIds.every(x => this.visibleAttachmentTypes.includes(x)));

			this.filesLoading = false;
		});
	}

	getByFileImageClassByExtension(extension: string): string {

		var imageClass = "fa-file";

		switch (extension) {
			case ".xls":
			case ".xlsx":
				imageClass = "fa-file-excel";
				break;
			case ".doc":
			case ".docx":
				imageClass = "fa-file-word";
				break;
			case ".pdf":
				imageClass = "fa-file-pdf";
				break;
			case ".zip":
			case ".rar":
				imageClass = "fa-file-archive";
				break;
			case ".ppt":
			case ".pptx":
				imageClass = "fa-file-powerpoint";
				break;
		}

		return imageClass;
	}

	saveDisabled(){
		if (this.deliveryForm){
			return this.deliveryForm.form.pristine;
		}
		return false;
	}

	saveData(){
		this.dataSaving = true;
		this.deliveryService.updateDelivery(this.deliveryData)
		.pipe(
			finalize(() => {
				this.dataSaving = false;
			}))
		.subscribe(data => {
			if (data.isSuccessful) {
				this.needToUpdateDelivery = false;
				this.calculating = false;

				this.notificationService.success({
					title: 'Успех',
					message: `Доставка сохранена`,
					notificationType: NotificationType.Toast
				});
			}

			this.deliveryForm.form.markAsPristine();
		});
	}

	onAttachmentsAdded(attachments: UploadFile[]) {
		this.attachments = attachments;
	}

	uploadFiles(){
		if (this.attachments.length <= 0)
			return;

		if (this.attachments.length > 0 && this.attachments.some(x => x.attachmentTypeIds.length === 0)) {
			this.notificationService.error({
				title: 'Ошибка',
				message: "Укажите типы для загружаемых файлов",
				notificationType: NotificationType.SweetAlert
			});

			return;
		}
		const uploadModel = new UploadAttachmentsModel({ requestId: this.request.requestId, attachments: this.attachments });

		this.filesUploading = false;
		this.attachmentsService.upload(uploadModel)
			.pipe(
				tap(() => this.filesUploading = false))
			.subscribe(x => {
				this.attachments = [];
				if(this.uploadAttachmentsComponent){
					this.uploadAttachmentsComponent.uploadFiles = [];
					if(this.uploadAttachmentsComponent.uploader){
						this.uploadAttachmentsComponent.uploader.clearFiles();
					}
				}
				this.loadFiles();
			});
	}

	private loadDropDownData(): void {
		this.warehousesService.warehousesContragentsOwnersLookup().subscribe(data => this.warehouseOwners = data);
		this.transportCompanyService.list().subscribe(data => {
			this.transportCompanies = data;
		})
		this.warehousesService.getAllWarehousesAddresses().subscribe(data => this.warehouseAddresses = data);
	}

	private getSenderAndRecipientDataFromRequest(): void {
		if (!!this.request.senderWarehouseAreaId) {
			this.warehousesService.getWarehouseByAreaId(this.request.senderWarehouseAreaId).subscribe(data => {
				this.deliveryData.senderOwner = this.warehouseOwners.find(wo => wo.id === data.contragentOwnerId);
				this.deliveryData.senderAddress = this.warehouseAddresses.find(wo => wo.addressId === data.addressId);
			});
		}

		if (!!this.request.recipientWarehouseAreaId) {
			this.warehousesService.getWarehouseByAreaId(this.request.recipientWarehouseAreaId).subscribe(data => {
				this.deliveryData.recipientOwner = this.warehouseOwners.find(wo => wo.id === data.contragentOwnerId) 
				this.deliveryData.recipientAddress = this.warehouseAddresses.find(wo => wo.addressId === data.addressId);
			});
		}

		this.deliveryData.track = {} as DeliveryTrack;

		this.loading = false;
		this.needToUpdateAndRecalculateDelivery = true;
		this.ref.tick();
	}

	private getSenderAndRecipientDataFromDog(data: DeliveryDataDog): void {
		this.deliveryData.senderPhone = data.sender.phone;
		this.deliveryData.senderPerson = data.sender.contactPerson;
		this.deliveryData.senderOwner = this.warehouseOwners.find(wo => wo.name === data.sender.company);
		let senderAddress = this.warehouseAddresses.find(wo => wo.fullAddress === data.sender.fullAddress);
		this.deliveryData.senderAddress = !!senderAddress
			? senderAddress
			: {addressId: null, fullAddress: data.sender.fullAddress, city: null, street: null, house: null, latitude: null, longitude: null, postCode: null};

		this.deliveryData.recipientPhone = data.recipient.phone;
		this.deliveryData.recipientPerson = data.recipient.contactPerson;
		this.deliveryData.recipientOwner = this.warehouseOwners.find(wo => wo.name === data.recipient.company);
		let recipientAddress = this.warehouseAddresses.find(wo => wo.fullAddress === data.recipient.fullAddress);
		this.deliveryData.recipientAddress = !!recipientAddress
			? recipientAddress
			: {addressId: null, fullAddress: data.sender.fullAddress, city: null, street: null, house: null, latitude: null, longitude: null, postCode: null};

		this.deliveryData.packages = data.packages;

		this.deliveryData.track = data.tracks.length > 0 
			? data.tracks[0]
			: {} as DeliveryTrack;

		if (!this.deliveryData.track.transportCompanyId){
			var tk = this.transportCompanies.find(x => x.name.toLowerCase() === this.request.carrierName.toLowerCase());
			if (tk){
				this.deliveryData.track.transportCompanyId = tk.id;
			}
		}

		this.deliveryData.trackNumber = !!this.deliveryData.track.publicTrackNumber 
			? this.deliveryData.track.publicTrackNumber 
			: this.deliveryData.track.internalTrackNumber

		this.deliveryData.costs = data.costs;

		let currentCost = data.costs.some(c => !!c.approvalStatus)
			? data.costs.find(c => !!c.approvalStatus)
			: data.costs[0];

		this.deliveryData.currentCost = currentCost;

		if (!!this.deliveryData.currentCost) {
			this.deliveryData.coefficient = !!currentCost.coefficient ? currentCost.coefficient : 1;
			this.deliveryData.totalSum = !!currentCost.totalCost ? currentCost.totalCost : currentCost.sum;
			this.deliveryData.currentTransportCompanyId = currentCost.transportCompanyId;

			this.deliveryData.track.transportCompanyId = !!this.deliveryData.track.transportCompanyId
				? this.deliveryData.track.transportCompanyId
				: this.deliveryData.currentTransportCompanyId;

			let statusName = undefined;
			let status = this.approvalStatuses.find(as => as.id === currentCost.approvalStatus);
			if (!!status)
				statusName = status.name
			this.deliveryData.approvalStatus = statusName;
		} else {
			this.needToUpdateAndRecalculateDelivery = true;
		}

		// if (!!this.deliveryData.trackNumber)
		// 	this.track();

		this.loading = false;
		this.ref.tick();

		this.packages = data.packages;
		this.deliveryData.transportCompanyNumber = data.transportCompanyNumber;
		this.deliveryData.intakeNumber = data.intakeNumber;
		this.deliveryData.currentTransportCompanyId = data.transportCompanyId;
		this.deliveryData.takingTimeFrom = data.takingTimeFrom;

		this.deliveryData.senderTransportCompanyLocationCode = data.sender.transportCompanyLocationCode;
		this.deliveryData.recipientTransportCompanyLocationCode = data.recipient.transportCompanyLocationCode;

		this.setCurrentTk();

		if (this.isTkIntegration){
			this.boxBerryService.calculateDeliveryCost(this.currentTk.name, this.deliveryData.externalId)
			.subscribe(response =>{
				if (response){
					this.deliveryPrice = response.deliveryPrice; 
				}
			})
		}
	}

	public transportCompanyChanged(): void {
		if (this.deliveryData.currentTransportCompanyId == 1) {
			setTimeout(() => {
				this.deliveryForm.form.get("weight").setValidators([Validators.min(0), Validators.max(10000000)]);
				this.deliveryForm.form.get("length").setValidators([Validators.min(0), Validators.max(1500)]);
				this.deliveryForm.form.get("width").setValidators([Validators.min(0), Validators.max(1500)]);
				this.deliveryForm.form.get("height").setValidators([Validators.min(0), Validators.max(1500)]);
			}, 0);
		}

		this.setNeedToUpdateAndRecalculateDelivery()
	}
	
	
	public calculating: boolean = false;

	public calculate(): void {
		if (!this.deliveryData.externalId)
			this.error("Не заполнено поле IntegraCode у заявки");
		else if (!this.deliveryData.senderAddress)
			this.error("Не заполнено поле Адрес отправителя");
		else if (!this.deliveryData.senderOwner)
			this.error("Не заполнено поле Наименование отправителя");
		else if (!this.deliveryData.senderPerson)
			this.error("Не заполнено поле Контактное лицо отправителя");
		else if (!this.deliveryData.senderPhone)
			this.error("Не заполнено поле Телефон отправителя");
		else if (!this.deliveryData.recipientAddress)
			this.error("Не заполнено поле Адрес получателя");
		else if (!this.deliveryData.recipientOwner)
			this.error("Не заполнено поле Наименование получателя");
		else if (!this.deliveryData.recipientPerson)
			this.error("Не заполнено поле Контактное лицо получателя");
		else if (!this.deliveryData.recipientPhone)
			this.error("Не заполнено поле Телефон получателя");
		else if (!this.deliveryData.packages[0].weight)
			this.error("Не заполнено поле Вес");
		else if (this.deliveryForm.invalid)
			this.error("Форма невалидна")
		else {
			this.calculating = true;

			if (this.needToUpdateAndRecalculateDelivery) {
				this.deliveryService.calculateAndCreateIfNotExist(this.deliveryData).subscribe(data => {
					if (!!data) {
						this.deliveryData.costs = data;
						this.deliveryData.currentCost = data[0];
						this.deliveryData.coefficient = 1;
		
						if (!!this.deliveryData.currentCost) {
							this.deliveryData.totalSum = this.deliveryData.currentCost.sum;
	
							this.notificationService.success({
								title: 'Успех',
								message: `Доставка сохранена, тарифы получены`,
								notificationType: NotificationType.Toast
							});
	
							this.needToUpdateAndRecalculateDelivery = false;
						} else {
							this.notificationService.error({
								title: 'Ошибка',
								message: `Не удалось получить тарифы по заданным параметрам. Измените параметры и попробуйте еще раз`,
								notificationType: NotificationType.Toast
							});
	
							this.needToUpdateAndRecalculateDelivery = true;
						}
					}

					this.calculating = false;
					this.ref.tick();
				});
			}

			if (this.needToUpdateDelivery) {
				this.deliveryService.updateDelivery(this.deliveryData).subscribe(data => {
					if (data.isSuccessful) {
						this.needToUpdateDelivery = false;
						this.calculating = false;

						this.notificationService.success({
							title: 'Успех',
							message: `Доставка сохранена`,
							notificationType: NotificationType.Toast
						});
					}
				});
			}
		}
	}

	private error(errorText: string): void {
		this.notificationService.error({
			title: 'Ошибка',
			message: errorText,
			notificationType: NotificationType.SweetAlert
		});
	}

	// public track(): void {
	// 	if (!this.deliveryData.track.internalTrackNumber) {
	// 		this.deliveryData.track.publicTrackNumber = this.deliveryData.trackNumber;

	// 		this.deliveryService.trackAndCreateIfNotExist(this.deliveryData)
	// 			.subscribe(data => {
	// 				if (this.deliveryData.track.publicTrackNumber === data.track.publicTrackNumber || this.deliveryData.track.internalTrackNumber === data.track.internalTrackNumber) {
	// 					let sortedHistory = data.track.history.sort((objA, objB) => new Date(objA.dateTime).getTime() - new Date(objB.dateTime).getTime());
	// 					this.deliveryData.history = sortedHistory;
	// 					this.deliveryData.callDate = formatDate(sortedHistory[0].dateTime, 'dd.MM.yyyy HH:mm', 'ru_RU');
		
	// 					this.notificationService.success({
	// 						title: 'Успех',
	// 						message: `История статусов получена`,
	// 						notificationType: NotificationType.Toast
	// 					});
	// 				}
	// 			});
	// 	} else {
	// 		this.deliveryService.getDeliveryHistory(this.deliveryData.track.internalTrackNumber).subscribe(data => {
	// 			if (this.deliveryData.track.publicTrackNumber === data.track.publicTrackNumber || this.deliveryData.track.internalTrackNumber === data.track.internalTrackNumber) {
	// 				let sortedHistory = data.track.history.sort((objA, objB) => new Date(objA.dateTime).getTime() - new Date(objB.dateTime).getTime());
	// 				this.deliveryData.history = sortedHistory;
	// 				this.deliveryData.callDate = formatDate(sortedHistory[0].dateTime, 'dd.MM.yyyy HH:mm', 'ru_RU');
	// 				this.deliveryData.errors = data.errors;
	// 				this.deliveryData.trackNumber = !!data.track.publicTrackNumber ? data.track.publicTrackNumber : data.track.internalTrackNumber

	// 				this.notificationService.success({
	// 					title: 'Успех',
	// 					message: `История статусов получена`,
	// 					notificationType: NotificationType.Toast
	// 				});
	// 			}
	// 		});
	// 	}
	// }

	public changeTotalSum(): void {
		if (!this.deliveryData.coefficient)
			this.deliveryData.coefficient = 1;

		this.deliveryData.totalSum =  Number((this.deliveryData.currentCost.sum * this.deliveryData.coefficient).toFixed(0));
	}

	public setApprovalStatus(approvalStatus: string = null): void {

		let statusId = undefined;
		let status = this.approvalStatuses.find(as => as.name === approvalStatus);
		if (status!!)
			statusId = status.id

		this.deliveryService.setApprovalStatus(statusId, this.deliveryData)
			.subscribe(data => {
				if (data.isSuccessful) {
					this.deliveryData.approvalStatus = approvalStatus;

					this.notificationService.success({
						title: 'Успех',
						message: `Статус изменен`,
						notificationType: NotificationType.Toast
					});
				}
			});
	}

	public orderDelivery(): void {
		this.deliveryService.orderDelivery(this.deliveryData.currentCost.deliveryCostId).subscribe(data => {
			this.deliveryData.track.publicTrackNumber = data.track.publicTrackNumber;
			this.deliveryData.track.internalTrackNumber = data.track.internalTrackNumber;
			this.deliveryData.track.transportCompanyId = this.deliveryData.currentTransportCompanyId;
			this.deliveryData.trackNumber = !!data.track.publicTrackNumber ? data.track.publicTrackNumber : data.track.internalTrackNumber;

			if (!!data.track.history && data.track.history.length > 0) {
				let sortedHistory = data.track.history.sort((objA, objB) => objA.dateTime.getTime() - objB.dateTime.getTime());
				this.deliveryData.history = sortedHistory;
				this.deliveryData.callDate = formatDate(sortedHistory[0].dateTime, 'dd.MM.yyyy HH:mm', 'ru_RU');
			}
			
			this.deliveryData.errors = data.errors;

			this.notificationService.success({
				title: 'Сервис доставки',
				message: `Заказ оформлен, трек номер:<br>${this.deliveryData.trackNumber}`,
				notificationType: NotificationType.SweetAlert,
				enableHtml: true
			});
		})
	}

	public deleteOrder(): void {
		this.deliveryService.deleteOrder(this.deliveryData.currentTransportCompanyId, this.deliveryData.externalId).subscribe(data => {
			if (data.isSuccessful) {
				this.notificationService.success({
					title: 'Сервис доставки',
					message: `Заказ удален`,
					notificationType: NotificationType.SweetAlert,
					enableHtml: true
				});
				this.loading = true;
				this.ngOnInit();
			}
		})
	}

	public setNeedToUpdateAndRecalculateDelivery(): void {
		this.deliveryData.currentCost = null;
		this.deliveryData.costs = [];
		this.needToUpdateAndRecalculateDelivery = true;
		this.needToUpdateDelivery = false;
	}

	public setNeedToUpdateDelivery(): void {
		if (!this.needToUpdateAndRecalculateDelivery)
			this.needToUpdateDelivery = true;
	}

	public valueNormalizer = (text: Observable<string>) =>
    text.pipe(
      map((content: string) => {
        return {
          value: null,
          fullAddress: content,
        };
      })
    );

	createDeliveryTk(){
		if (!this.deliveryData.recipientPhone || !this.deliveryData.recipientPerson){
			this.notificationService.error({
				title: 'Ошибка',
				message: "Необходимо заполнить Контактное лицо или телефон получателя и сохранить данные",
				notificationType: NotificationType.SweetAlert
			});
			return;
		}
		this.creatingDeliveryTk = true;
		this.boxBerryService.createDelivery(this.currentTk.name, this.deliveryData.externalId, this.request.requestId)
		.pipe(
			tap(() => this.creatingDeliveryTk = false)
		)	
		.subscribe(response => {
			if (response){
				this.deliveryData.transportCompanyNumber = response.transportCompanyNumber;
				this.uploadTkFiles(response.files);

				this.deliveryService.getDelivery(this.deliveryData.externalId).subscribe(data => {
					if (data)
						setTimeout(() => this.getSenderAndRecipientDataFromDog(data), 1000);
					else
						setTimeout(() => this.getSenderAndRecipientDataFromRequest(), 1000);
				});
			}
		})
	}

	async uploadTkFiles(files: ParselFile[]){
		let uploadFiles = [];
		const uploadModel = new UploadAttachmentsModel({
			requestId: this.request.requestId
		});
		for (let index = 0; index < files.length; index++) {
			const element = files[index];
			let file = new UploadFile;
			file.fileName = element.name;
			file.fileExtension = '.pdf';
			file.attachmentTypeIds = [AttachmentType.transferAcceptanceCertificate];
			file.attachmentTypeId = AttachmentType.transferAcceptanceCertificate;
			file.fileData = element.description;
			uploadFiles.push(file);
			uploadModel.attachments = uploadFiles;
		}
		this.attachmentsService.uploadWithResumableIfMobile(uploadModel).subscribe(() => { 
			this.loadFiles();
		});
	}

	callCourier(){
		const installDialogRef = this.dialogService.open({
			title: "Данные отправителя",
			content: AddIntakeInfoPage,
			width: '400px'
		});

		let request = new CreateIntakeRequest({
			externalId: this.deliveryData.externalId	
		});

		const dialog = <AddIntakeInfoPage>installDialogRef.content.instance
		dialog.data = request;
		dialog.senderWarehouseId = this.request.senderWarehouseAreaId;
		dialog.onContinueEvent
		.subscribe((response: CreateIntakeRequest) => {	
			response.externalId = this.deliveryData.externalId;
			this.creatingIntake = true;
			this.boxBerryService.createIntake(this.currentTk.name, response, this.request.requestId)
			.pipe(
				tap(() => this.creatingIntake = false)
			)		
			.subscribe(createIntakeResponse => {
				if (createIntakeResponse){
					this.deliveryData.intakeNumber = createIntakeResponse.intakeNumber;
					this.deliveryData.takingTimeFrom = response.takingDate;
					if (this.request.workflowTransitions.find(x => x.requestStatusToId == <number>RequestStatus.readyToShipToCarrier)){
						let model = new ChangeStatusModel(this.request.requestId, <number>RequestStatus.readyToShipToCarrier);
						this.requestsSerivce.changeStatus(model)
							.subscribe(() => {});
					}
				}
			});
		});
	}

	
	parseMultiTimezoneDate(dateTime: any) {

		if (dateTime == null) {
			return '';
		}

		var parsedDate = new Date();

		if (dateTime instanceof Date) {
			parsedDate = dateTime;
		} else if (typeof dateTime === 'string') {
			if (dateTime.startsWith("1753")) {
				return '';
			}

			parsedDate = this.intl.parseDate(dateTime);
		}

		return `${this.intl.formatDate(parsedDate, 'dd.MM.yyyy HH:mm')}`;
	}
}