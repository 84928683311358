export class ArticlesCountModel {
    articleMask: string;
    requestQuantity: number;
    isPart: boolean;
    itemId: number;
    responseQuantity: number;

    constructor(articleMask: string
        , requestQuantity: number
        , isPart: boolean
        , itemId: number = 0
        , responseQuantity: number = 0) {
        this.articleMask = articleMask;
        this.requestQuantity = requestQuantity;
        this.isPart = isPart;
        this.itemId = itemId;
        this.responseQuantity = responseQuantity;
    }
}