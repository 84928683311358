import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { NewRequestDevice } from "../../../models/request/new-request/new-request-device";

export class DeviceRow {
    device: NewRequestDevice = new NewRequestDevice;
    
    isError: boolean = false;
    isSelected: boolean = false;

    deviceModels: KeyValueObject[] = [];
    
    isSerialNumberEnabled: boolean = true;
    isIsNotDefinedSnEnabled: boolean = true;
    isDeviceConnectionTypeEnabled: boolean = true;
    isHasCryptoKeyAndCompleteKitEnabled: boolean = true;
    isCryptoKeyEnabled: boolean = false;
    isSubscriberNumberEnabled: boolean = false;
}