import { Component, EventEmitter, Output, Input } from '@angular/core';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SetPerformerGroupModel } from './set-performer-group.model';
import { WorkflowTransition } from '../../../models/request/workflow-transition';

@Component({
	selector: 'set-performer-group',
	templateUrl: './set-performer-group.html',
	styleUrls: ['./set-performer-group.scss']
})
export class SetPerformerGroup {

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	@Output()
	onContinueEvent = new EventEmitter<SetPerformerGroupModel>();

	users: KeyValueObject[] = [];

	@Input()
	setPerformerGroupModel: SetPerformerGroupModel;

	@Input()
	requestId: number;
	
	@Input()
	workflowTransition: WorkflowTransition;

	constructor(public dialog: DialogRef) {}

	cancel() {
		this.dialog.close();
	}

	continue(){
		this.onContinueEvent.emit(this.setPerformerGroupModel);
		this.cancel();
	}

}
