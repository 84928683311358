import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { GridSettings } from '../../../../models/grid/grid-settings';
import { Package } from './models/package';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DialogRef } from '@progress/kendo-angular-dialog';


@Component({
	selector: 'set-sn-set-package-size',
	templateUrl: './set-sn-set-package-size.component.html',
	styleUrls: ['../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class SetSnSetPackageSizeComponent implements OnInit {


    @Output()
	onContinueEvent = new EventEmitter<Package[]>();

    @Input()
    requestTypeId: number;

    @Input()
    requestId: number;

    gridEditing = false;

    public gridView: GridDataResult;

    public form: FormGroup;

    packages: Package[] = [];

    constructor(		
        public dialog: DialogRef
    ) {
        this._buildForm();
        this.loadItems();
	}

    public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 5,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "weight",
				title: "Вес, (гр)",
				filterable: false,
				width: 200,
			},
			{
				field: "length",
				title: "Длина, (см)",
				filterable: false,
				width: 200,
			},
			{
				field: "width",
				title: "Ширина, (см)",
				filterable: false,
				width: 200,
			},
			{
				field: "height",
				title: "Высота, (см)",
				width: 200,
				filterable: false,
			}
		]
	};

    ngOnInit(): void {
        
    }

    addHandler(event): void {
        this.gridEditing = true;
    }

    editHandler(obj: any): void {
        this.gridEditing = true;
        const dataItem = obj.dataItem as Package;
		if (!!dataItem) {
		}
    }
    saveHandler(event): void {
        this.gridEditing = false;
    }

    continue(){
        this.onContinueEvent.emit(this.packages);
        this.close();
    }
    close(){
        this.dialog.close();
    }

    
	get createPackage(): any {

		return function () {
			const device = new Package(0,0,0,0);
			return device;
		};
	}

	private _buildForm(): void {
		this.form = new FormGroup({
			weight: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(10000000)]),
			length: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1500)]),
            width: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1500)]),
			height: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1500)]),
		});
	}

    public pageChange({ skip, take }: PageChangeEvent): void {
        this.gridSettings.state.skip = skip;
		this.gridSettings.state.take = take;
        this.loadItems();
      }
    
      private loadItems(): void {
        this.gridView = {
          data: this.packages.slice(this.gridSettings.state.skip, this.gridSettings.state.skip + this.gridSettings.state.take),
          total: this.packages.length,
        };
      }
	
}