import { KeyValueObject } from "../core/KeyValueObject";

export class UserGroup {
	id: number;
	name: string;
	responsibleEmail: string;
	defaultPerformerUserId?: number;
	parentId?: number;
	users: KeyValueObject[] = [];
	contragentId?: number;
}
