import { Component, EventEmitter, Output } from '@angular/core';
import { ReportsService } from '../../../shared/services/reports.service';
import { Expertise } from '../../../shared/models/reports/expertise';

@Component({
    selector: 'expertises-form',
    templateUrl: './expertises.form.html',
    styleUrls: [
          './expertises.form.scss',
          '../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
      ]
  })
export class ExpertisesForm {

    @Output()
    public onLoadedData: EventEmitter<Expertise[]> = new EventEmitter<Expertise[]>();

    public loadingData: boolean = false;
    public serialNumbersText: string = null;
    public serialNumbersFile: File = null;

    constructor(
        private reportsService: ReportsService
    ) {
        
    }

    onChoosedFile(file: File): void {
        this.serialNumbersFile = file[0];
        this.loadData();
    }

    loadData(): void {
        this.loadingData = true;
        this.reportsService.getExpertisesData(this.serialNumbersText, this.serialNumbersFile)
	        .subscribe(result => {
		        if (result.isSuccessful && this.onLoadedData) {
			        this.onLoadedData.emit(result.data);
		        }

		        this.loadingData = false;
	        });
    }
}
