export class EngubatorSkill {
    id: number;
    name: string;
    composition: EngubatorSkillComposition[] = [];
}

export class EngubatorSkillComposition {
    id: number;
    requestTypeId: number;
    deviceModelId?: number;
    processDuration: number;
    skill: EngubatorSkill;
}