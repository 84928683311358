import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { catchError, concatMap, finalize, takeUntil } from "rxjs/operators";
import { Subject, forkJoin, of, throwError } from "rxjs";
import { NewRequestDevice } from "../../../models/request/new-request/new-request-device";
import { DeviceTypeEnum } from "../../../enums/device-type.enum";
import { DeviceStatus } from "../../../enums";
import { RequestsService } from "../../../services/requests.service";
import { RequestUninstallDevicesService } from "../../../services/request-uninstall-devices.service";
import { DeviceValidation } from "../../../models";
import { NotificationService } from "../../../../core/services/notification.service";
import { NotificationType } from "../../../../core/services/notification-type";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { SecurityService } from "../../../../core/services/security.service";

@Component({
	selector: 'app-check-devices-at-warehouse-mobile',
	templateUrl: './check-devices-at-warehouse-mobile.component.html',
	styleUrls: [
		'./check-devices-at-warehouse-mobile.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class CheckDevicesAtWarehouseMobileComponent implements OnInit {
	public form: FormGroup;

	height = 400;

	public gridEditing = false;
	public dataSaving = false;

	public devices: NewRequestDevice[] = [];

	private _destroy$ = new Subject<boolean>();

	@Input() requestId: number;
	@Input() shortcutReturnEquipment: boolean = false;
	@Output() onContinueEvent = new EventEmitter<any>();
	@Output() onCancelEvent = new EventEmitter<any>();
	
	constructor(
		public dialog: DialogRef,
		protected route: ActivatedRoute,
		protected router: Router,
		protected requestsService: RequestsService,
		private _uninstallDevicesService: RequestUninstallDevicesService,
		protected notificationService: NotificationService,
		private securityService: SecurityService
	) {
		this._buildForm();
	}

	get cardHeight(): number {
		return window.innerHeight - 200;
	}

	ngOnInit(): void {
		this._loadData();
		
	}

	cancel(){
		this.onCancelEvent.emit();
	}

	public editHandler() {
		if (!this.gridEditing) {
			this.gridEditing = true;
		}
	}

	public cancelEdit(): void {
		this.gridEditing = false;
	}

	public dialogClose() {
		if (!!this.dialog 
			&& !!this.dialog.close
			&& typeof this.dialog.close === 'function') {
				this.dialog.close();
		}
	}

	saveDataValidation(validationStep: number = 0){
		switch (validationStep) {
			case 0:
				var hasEmptyNomenclature = this.devices.some(x => !x.nomenclature);

				if (hasEmptyNomenclature && this.shortcutReturnEquipment){
					this.notificationService.info({
						title: 'Отсутствует номенклатура',
						message: `Системе не удалось произвести идентификацию модели оборудования. Для использования в других заявках, дождитесь финальной обработки заявки и перевода в "Выполнено".`,
						notificationType: NotificationType.SweetAlert,
						confirmationFunction: () => {
							validationStep++;
							this.saveDataValidation(validationStep);
						}
					});
				} else {
					validationStep++;
					this.saveDataValidation(validationStep);
				}
				break;				
			default:
				this.saveData();
				break;
		}
	}

	public saveData() {
		this.dataSaving = true;

		of(...this.devices)
		.pipe(
			concatMap(device => this._uninstallDevicesService.validateDeviceToWarehouseAction(device)),
			takeUntil(this._destroy$),
			finalize(() => {
				this.onContinueEvent.emit(this.devices);
				this.dialogClose();
				this.dataSaving = false;
			}))
		.subscribe((response: DeviceValidation) => {
			if (!response.success) {
				this.notificationService.error({
					title: `Ошибка при сдаче оборудования`,
					message: `${response.message}`,
					notificationType: NotificationType.Toast
				});
			}
		});
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public select(device: NewRequestDevice) {
		if (this.securityService.isPartialDeviceReturn()){
			if (!device.nomenclature){
				this.notificationService.error({
					title: 'Ошибка',
					message: 'Оборудование не идентифицировано. Вы не можете его использовать в дальнейшем. Пожалуйста, сдайте его на склад для идентификации.',
					notificationType: NotificationType.SweetAlert
				});
			} else {
				device.isSelected = !device.isSelected;
				device.shouldBeReturnedToWarehouse = device.isSelected;
			}
		}
	}

	public _loadData(){
		this.requestsService.getRequestUninstallDevices(this.requestId)
		.pipe(takeUntil(this._destroy$))
		.subscribe(result => {
			this.devices = result.filter(x => x.deviceStatusId === DeviceStatus.deInstalled);
			this.devices.forEach(x => {
				x.isSelected = true;
				x.shouldBeReturnedToWarehouse = true;
			});
		});
	}

	get devicesLength() {
		return this.devices.length;
	}

	get selectedDevicesLength() {
		let selectedDevices = this.devices.filter(x => x.isSelected).length;
		return selectedDevices;
	}

	deviceTypeIsSim(device: NewRequestDevice) {
		return device.deviceTypeId == DeviceTypeEnum.simCard;
	}

	getICCID(device: NewRequestDevice): string{
		if(device.iccid){
			return device.iccid;
		} else {
			return device.serialNumber;
		}
	}

	private _buildForm(): void {
		this.form = new FormGroup({});
	}
}