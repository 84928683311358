import { Component, OnInit, Input } from "@angular/core";
import { CategoryChangeLog } from "../../../models/category/category-change-log";
import { CategoriesService } from "../../../services/categories.service";

@Component({
	selector: 'change-log-grid',
	templateUrl: './change-log.grid.html',
	styleUrls: [
		'./change-log.grid.scss',
	]
})
export class ChangeLogGrid implements OnInit {

	categoryChangeLogs: CategoryChangeLog[] = [];

	@Input() categoryId: number;
	@Input() entityId: number;

	constructor(
		private categoriesService: CategoriesService) {

	}

	ngOnInit(): void {
		this.categoriesService.getChangeLogForEntity(this.categoryId, this.entityId)
			.subscribe(result => {
				this.categoryChangeLogs = result;
			});
	}

}
