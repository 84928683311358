import { KeyValueObject } from "../../../../shared/models/core/KeyValueObject";
import { Cost } from "./delivery-cost";
import { DeliveryAddress } from "./delivery-address";
import { DeliveryTrackHistoryDog } from "./delivery-track-history-dog";
import { DeliveryTrack } from "./delivery-track";
import { DeliveryOrderErrorDog } from "./delivery-order-error-dog";
import { Package } from "./delivery-data-dog";
import { DeliveryItem } from "./delivery-item";

export class DeliveryData {
	externalId: string;

	trackNumber: string;
	track: DeliveryTrack;
	callDate: string;

	senderAddress: DeliveryAddress;
	senderOwner: KeyValueObject;
	senderPerson: string;
	senderPhone: string;
	senderTransportCompanyLocationCode: string;

	recipientAddress: DeliveryAddress;
    recipientOwner: KeyValueObject;
	recipientPerson: string;
	recipientPhone: string;
	recipientTransportCompanyLocationCode: string;

	takingTimeFrom: Date;
	transportCompanyNumber: string;
	intakeNumber: string;
    currentTransportCompanyId: number;
	internalTrackNumber: string;

	packages: Package[] = [];
	deliveryItems: DeliveryItem[] = [];

	currentCost: Cost;
	costs: Cost[];

	coefficient: number;

	totalSum: number;

	approvalStatus: string;

	history: DeliveryTrackHistoryDog[];

	errors: DeliveryOrderErrorDog[];
}