import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Merchant } from '../../shared/models/merchant/Merchant';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { MerchantLookupItem } from '../../shared/models/merchant/merchant-lookup-item';
import { CustomerObjectLookupModel } from '../../shared/models/customerObject/customer-object-lookup-model';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { ContragentLookupModel } from '../../shared/models/contragent/contragent-lookup-model';

@Injectable()
export class MerchantsService extends DataService<Merchant, Merchant> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'merchants';
	}

	public getCustomerObjects(merchantId: number, customerObjectId: number = null): Observable<CustomerObjectLookupModel[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${merchantId}/${customerObjectId}/customer-objects`);
	}

	public fullLookup(customerContragentId: number): Observable<MerchantLookupItem[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/full-lookup?customerContragentId=${customerContragentId}`);
	}

	public getLegalNames(name: string): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/legal-names?name=${name}`);
	}
	public getContragentByMerchant(merchantId: number): Observable<ContragentLookupModel> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${merchantId}/contragent-lookup-item`);
	}

}
