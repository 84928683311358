import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RoleSpecialRight} from '../../../shared/models';

@Component({
  selector: 'app-role-special-right-list',
  templateUrl: './role-special-right-list.component.html',
  styleUrls: ['./role-special-right-list.component.scss']
})
export class RoleSpecialRightListComponent implements OnInit {

	@Input() roleSpecialRights: RoleSpecialRight[] = [];
	@Input() canEditForm: boolean;
	@Output() changeAction: EventEmitter<void> = new EventEmitter();

  roleSpecialRightsFiltered: RoleSpecialRight[] = [];

  constructor() { }

  public ngOnInit() {
    this.roleSpecialRightsFiltered = this.roleSpecialRights.filter(f => !!!f.specialRightsGroupId);
  }

	onChange() {
		this.changeAction.emit();
	}
}
