import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { WarehouseAreaCell } from '../models/warehouse/warehouse-area-cell';

@Injectable()
export class WarehouseCellsService extends DataService<WarehouseAreaCell, WarehouseAreaCell> {
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'warehouse-cells';
	}

	getByWarehouse(warehouseId: number): Observable<WarehouseAreaCell[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/warehouse/${warehouseId}`);
	}
}
