import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { ActivitiesListPage } from './list/list.page';
//import { NotificationButtonComponent } from "../requests/request-components/request-notification-button/notification-button.component";

export const activitiesRoutes: Routes = [
	{ path: '', component: ActivitiesListPage, canActivate: [AuthGuard] },
];

@NgModule({
	imports: [],
	exports: [RouterModule]
})
export class ActivitiesRoutingModule {
	public static components = [
		ActivitiesListPage,
		//NotificationButtonComponent
	];
}