export class SendNewRequestsFromGateToRabbitCommand {
    constructor(
        ignoreExchangeFilters: boolean,
        sutsRequestNums: string[] = []
    ) {
        this.ignoreExchangeFilters = ignoreExchangeFilters;
        this.sutsRequestNums = sutsRequestNums;
    }
    ignoreExchangeFilters: boolean;
    sutsRequestNums: string[] = [];
}