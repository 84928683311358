import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationService } from '../../core/services/notification.service';
import { DataService } from '../../core/services/data.service';
import { environment } from '../../../environments/environment';
import { KeyValueObject } from '../models/core/KeyValueObject';
import { RegionDto } from '../models/region/region.dto';
import { map } from 'rxjs/operators';
import { ActionDataResult } from '../models/core/ActionDataResult';

@Injectable()
export class RegionsService extends DataService<RegionDto, RegionDto> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'regions';
	}

	public regionsSingleLookup(cityIds: number[] = [], warehousePartnerCodes: string[] = []): Observable<KeyValueObject[]> {
		if (cityIds.length === 0 && warehousePartnerCodes.length === 0) {
			return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/regions-single-lookup`);
		} else {
			return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/regions-single-lookup`, { cityIds, warehousePartnerCodes })
				.pipe(map<ActionDataResult<KeyValueObject[]>, KeyValueObject[]>(v => v.data));
		}
	}
}
