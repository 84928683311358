import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { EntityViewModel } from '../../../shared/models/core/EntityViewModel';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { MerchantsService } from '../merchants.service';
import { Merchant } from '../../../shared/models/merchant/Merchant';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import {CategoryType} from "../../../shared/enums/category-type.enum";

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};


@Component({
  selector: 'categories-merchant',
  templateUrl: './merchant.page.html',
  styleUrls: [
    './merchant.page.scss',
    '../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
  ]
})
export class MerchantPage extends ComponentPageBase<Merchant> implements OnInit {

	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.merchants;
  headerName = "Основное";
  headerCustomerObjectName = "Новый объект ТСП";
  headerContragentName = "Новый контрагент";
  headerAcquirerName = "Новый банк эквайер";

  contragents: KeyValueObject[] = [];
  customerObjects: KeyValueObject[] = [];

  public isNewCustomerObjectVisible: boolean;
  public isNewObjectButtonEnabled: boolean;
  public isNewContragentVisible: boolean;
  public isNewAcquirerVisible: boolean;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected appService: AppService,
    protected notificationService: NotificationService,
    protected dataService: MerchantsService
  ) {
    super(route, router, appService, notificationService, dataService);
    this.listPageRoutePath = 'categories/merchants';
    this.appService.pageTitle = 'Новое ТСП';
    this.isNewCustomerObjectVisible = false;
    this.isNewContragentVisible = false;
    this.isNewAcquirerVisible = false;
    this.isNewObjectButtonEnabled = true;
  }

  ngOnInit() {

    this.entityId = this.route.snapshot.paramMap.get('merchantId');

    if (this.entityId != null) {

      this.dataService.getById(+this.entityId).subscribe(vm => {
        this.entityName = `${vm.entity.mid} ${vm.entity.name}`;
        this.dataBind(vm);
      });

    } else {

      this.entityViewModel = new EntityViewModel<Merchant>();
      this.entityViewModel.entity = new Merchant();
      this.entityViewModel.entity.createdDate = new Date();
      this.canEditForm = true;
      this.isNew = true;
    }

  }

  afterSaveChangesChild() {
    if (this.isNew) {
      this.router.navigate([this.listPageRoutePath]);
    }
  }
}
