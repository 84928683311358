import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { NotificationService } from "../../../core/services/notification.service";
import { EngubatorDataService } from "./engubator.data.service";
import { EngubatorSkill, EngubatorSkillComposition } from "../../models/engubator/engubator.skill";
import { ActionDataResult } from "../../models/core/ActionDataResult";
import { Observable } from "rxjs";
import { NotificationType } from "../../../core/services/notification-type";
import { tap, take } from 'rxjs/operators';

@Injectable()
export class EngubatorSkillsService extends EngubatorDataService<EngubatorSkill, EngubatorSkill>{
    constructor(http: HttpClient, notificationService: NotificationService) {
        super(http, notificationService);
        this.controllerName = 'skills';
    }

    protected getNewTItem(): EngubatorSkill {
        return new EngubatorSkill();
    }

    public addSkillComposition(composition: EngubatorSkillComposition): Observable<any> {
        var data: EngubatorSkillComposition = Object.assign({}, composition);
        delete data.skill;
        return this.http.post<ActionDataResult<number>>(`${this.baseUrl()}/${composition.skill.id}/composition`, data)
            .pipe(tap(resp => {
                if (resp.isSuccessful) {
                    this.notificationService.success({
                        title: 'Создание нового элемента в сервисе подбора инженеров',
                        message: 'Элемент создан успешно',
                        notificationType: NotificationType.Toast
                    });
                }
                else {
                    this.notificationService.error({
                        title: 'Ошибка при создании элемента в сервисе подбора инженеров',
                        message: resp.errorDescription,
                        notificationType: NotificationType.Toast
                    });
                }
            },
                errorResponse => {
                    if (errorResponse.status === this.unauthorizedCode)
                        return;

                    this.notificationService.error({
                        title: 'Ошибка при создании элемента в сервисе подбора инженеров',
                        message: errorResponse.message ? errorResponse.message : errorResponse.error.errorDescription,
                        notificationType: NotificationType.Toast
                    });

                }
            ))
            .pipe(take(1));
    }

    public updateSkillComposition(composition: EngubatorSkillComposition): Observable<any> {
        var data: EngubatorSkillComposition = Object.assign({}, composition);
        delete data.skill;
        return this.http.put<ActionDataResult<number>>(`${this.baseUrl()}/${composition.skill.id}/composition/${composition.id}`, data)
            .pipe(tap(resp => {
                if (resp.isSuccessful) {
                    this.notificationService.success({
                        title: 'Изменение элемента в сервисе подбора инженеров',
                        message: 'Изменения сохранены успешно',
                        notificationType: NotificationType.Toast
                    });
                }
                else {
                    this.notificationService.error({
                        title: 'Ошибка обновления элемента в сервисе подбора инженеров',
                        message: resp.errorDescription,
                        notificationType: NotificationType.Toast
                    });
                }
            },
                errorResponse => {
                    if (errorResponse.status === this.unauthorizedCode)
                        return;

                    this.notificationService.error({
                        title: 'Ошибка обновления элемента в сервисе подбора инженеров',
                        message: errorResponse.message ? errorResponse.message : errorResponse.error.errorDescription,
                        notificationType: NotificationType.Toast
                    });

                }
            ))
            .pipe(take(1));
    }
}