import { Observable, Subscription, of } from "rxjs";
import { BaseFlowComponent } from "../../../base-flow-component/base-flow-component";
import { GetLastNomenclatureBySeriesQuery } from "../../../../../../models/for-1c/get-last-nomenclature-by-series/get-last-nomenclature-by-series-1c-query";
import { Guid } from "guid-typescript";
import { Component, Input } from "@angular/core";
import { NotificationService } from "../../../../../../../core/services/notification.service";
import { NotificationType } from "../../../../../../../core/services/notification-type";
import { KeyValueObject } from "../../../../../../models/core/KeyValueObject";
import { FormGroup } from "@angular/forms";
import { InstallFlowDeviceSerialData } from "./install-flow-device-serial-data";
import { DevicesService } from "../../../../../../services/devices.service";
import { SearchDeviceData } from "../../../../../../models/device/search-device-data";
import { tap } from "rxjs/operators";

@Component({
    selector: 'install-flow-device-serial',
    templateUrl: './install-flow-device-serial.component.html',
    styleUrls: ['./install-flow-device-serial.component.scss',
        '../../../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class InstallFlowDeviceSerialComponent 
    extends BaseFlowComponent<InstallFlowDeviceSerialData> 
{
	public form: FormGroup;
    @Input() requestId: number;

    oldSerialNumber: string = null;
    serialNumberValid: boolean = true;
    partNumbers: KeyValueObject[] = [];
    nomenclatureByPartNumber: string = null;

    lookingForSerial = false;

    constructor(
        private deviceService: DevicesService,
        private notificationService: NotificationService) {
        super();
    }

    initData(): void {
        if (this.data && this.data.serialNumber) {
            this.oldSerialNumber = this.data.serialNumber;
        }
        console.log(this.data);
    }

    saveChanges(): Observable<any> {
        return of({});
    }

    validateInputs() {    
        if (this.data.isMultiple) {
            this.validateMultipleSerialNumbers();
        }
        else {
            this.validateSingleSerialNumber();
        }
    }

    validateSingleSerialNumber() {
        this.oldSerialNumber = this.data.serialNumber;

        this.lookingForSerial = true;
        this.deviceService.isRemainsDevices(this.requestId, this.data.serialNumber, "")
        .pipe(
            tap(() => this.lookingForSerial = false))
        .subscribe((resp: SearchDeviceData) => {
            var deviceData = resp;

            if (deviceData.isInEngineerWarehouse || deviceData.isInMainWarehouse) {
                this.data.nomenclature = deviceData.nomenclature;
                this.data.valid = true;
                this.goToNext();
            } else {
                this.notificationService.error({
                    title: 'Ошибка',
                    message: 'По серийному номеру не найдены остатки в 1с. Исправьте номер и попробуйте еще раз, или свяжитесь с координатором',
                    notificationType: NotificationType.Toast
                });
            }
        });
    }

    validateMultipleSerialNumbers() {
        let allIsValid = true;
        this.lookingForSerial = true;
        this.data.serialItems.forEach((serialItem, index) => {
            this.deviceService.isRemainsDevices(this.requestId, serialItem.serialNumber, "")
            .subscribe((resp: SearchDeviceData) => {
                var deviceData = resp;
    
                if (deviceData.isInEngineerWarehouse || deviceData.isInMainWarehouse) {
                    serialItem.nomenclature = deviceData.nomenclature;
                    serialItem.isSuccessful = true;
                    if (index == this.data.serialItems.length - 1) {
                        this.lookingForSerial = false;
                        if (allIsValid) {
                            this.data.valid = true;
                            this.goToNext();
                        }
                    }
                } else {
                    allIsValid = false;
                    this.notificationService.error({
                        title: 'Ошибка',
                        message: 'По серийному номеру не найдены остатки в 1с. Исправьте номер и попробуйте еще раз, или свяжитесь с координатором',
                        notificationType: NotificationType.Toast
                    });
                }
            });
        });
    }

    get continueDisabled() {
        if (!this.data.isMultiple) return !this.data.serialNumber;
        else return !this.data.serialItems.every(x => x.serialNumber != null && x.serialNumber.trim() != '');
    }
}