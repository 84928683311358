export class CarrierCompanyTrackModel {
    carrierId: number;
    companyName: string;
    trackNumber: string;
    estimatedDeliveryTime?: Date;

    constructor(carrierId: number, companyName: string, trackNumber: string, estimatedDeliveryTime: Date = null) {
        this.carrierId = carrierId;
        this.companyName = companyName;
        this.trackNumber = trackNumber;
        this.estimatedDeliveryTime = estimatedDeliveryTime;
    }
}