import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivityStatusEnum } from "../../../shared/enums";
import { DeviceTypeEnum } from "../../../shared/enums/device-type.enum";
import { FormType } from "../../../shared/enums/form-type.enum";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { ExpertiseActDevice } from "../../../shared/models/expertise-act/expertise-act-device";
import { ExpertiseActForm } from "../../../shared/models/expertise-act/expertise-act-form";
import { ExpertiseActFormList } from "../../../shared/models/expertise-act/expertise-act-form-list";
import { ExpertiseActSetting } from "../../../shared/models/expertise-act/expertise-act-setting";
import { RequestDevicesService } from "../../../shared/services/request-devices.service";
import { ExpertiseActCheckbox } from "./expertise-form-components/checkbox/expertise-act-checkbox.component";
import { ExpertiseFormDropdownList } from "./expertise-form-components/dropdown-list/expertise-form-dropdown-list.component";
import { ExpertiseFormMultiselectList } from "./expertise-form-components/multiselect-list/expertise-form-multiselect-list.component";
import { RequestType } from '../../../shared/enums/request-type.enum';

@Component({
	selector: 'expertise-form',
	templateUrl: './expertise-form.component.html',
	styleUrls: [
		'./expertise-form.component.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ExpertiseFormComponent implements OnInit {

	private devicesForSaving: ExpertiseActDevice[] = [];

	@ViewChild('entityForm') public entityForm: NgForm;
	@ViewChildren(ExpertiseActCheckbox) checkboxes: QueryList<ExpertiseActCheckbox>;
	@ViewChildren(ExpertiseFormDropdownList) selects: QueryList<ExpertiseFormDropdownList>;
	@ViewChildren(ExpertiseFormMultiselectList) multiselects: QueryList<ExpertiseFormMultiselectList>;

	@Input() requestId: number;
	@Input() activityId: number;
	@Input() externalId: string;
	@Input() activityStatusId: number;
	@Input() contragentId: number;
	@Input() requestTypeId: number;

	@Output() onSave = new EventEmitter<any>();

	vtbCustomerContragentId = 1055;
	
	TSTypeFieldName = 'Тип ТО';

	prefilledFieldsMap = [
		{ name: 'Серийный номер', isSelectForm: false, mapper: (name, device, formListData) => device.serialNumber },
		{ name: 'Производитель', isSelectForm: true, mapper: (name, device, formListData) => {
				const setting = formListData.allForms.filter(x => x.name === name)[0];
				const option = setting.options.filter(z => z.value === device.manufacturer)[0];
				return option ? option : null;
			}
		},
		{ name: 'Парт-номер', isSelectForm: false, mapper: (name, device, formListData) => device.partNumber },
		{ name: 'Модель', isSelectForm: false, mapper: (name, device, formListData) => device.deviceModelName },
		{ name: 'Тип связи', isSelectForm: true, mapper: (name, device, formListData) => {
			const setting = formListData.allForms.filter(x => x.name === 'Тип связи')[0];
			const option = setting.options.filter(z => z.deviceConnectionTypeId === device.deviceConnectionTypeId)[0];
			return option ? option : null;
		}},
		{ name: 'Тип ТО', isSelectForm: true, mapper: (name, device, formListData) => {
			if (this.requestTypeId && this.requestTypeId === <number>RequestType.changeConfig) {
				return null;
			}

			const setting = formListData.allForms.filter(x => x.name === 'Тип ТО')[0];
			const option = setting.options.filter(z => z.deviceTypeId === device.deviceTypeId)[0];
			return option ? option : null;
		}},
	];

	lastGroupName: string = null;

	formFilledData = {};
	selectFormFilledData = {};

	allSelectListsData = [];

	allForms = [];
	formListData: ExpertiseActFormList = new ExpertiseActFormList();

	dataSaving: boolean = false;

	constructor(
		private requestDevicesService: RequestDevicesService,
	) {

	}

	ngOnInit(): void {
		this.requestDevicesService.getExpertiseActForm(this.requestId, this.activityId)
			.subscribe(result => {
				if (result.isSuccessful) {
					this.createFilledForms(result.data);
					this.formListData = result.data;
					this.allForms = this.formListData.allForms;
				}
			});
	}

	onCompleteExpertise(device: ExpertiseActDevice): void {
		this.saveChanges(device, true);
	}

	onChangeTextInput(device: ExpertiseActDevice): void {
		this.saveChanges(device);
	}

	onChangeSelect(event: any, device: ExpertiseActDevice): void {
		if (this.selectFormFilledData[event.tabName][event.formData.name] !== event.value) {
			this.selectFormFilledData[event.tabName][event.formData.name] = event.value;

			if (event.formData.name === this.TSTypeFieldName) {
				const choosenDeviceTypeId = event.formData.options.filter(x => x.optionId === event.value)[0].deviceTypeId;
				this.allForms = this.formListData.allForms
					.filter(x => x.options
						.some((z => (z.deviceTypeId === choosenDeviceTypeId) || (z.deviceTypeId === null) as any)));

				Object.keys(this.formFilledData[event.tabName])
					.map(key => {
						const settings = this.allForms.filter(x => x.name === key);
						if (settings.length > 0) {

							const setting = settings[0];
							if (setting.formType === FormType.Checkbox) {
								const optionId = this.formFilledData[event.tabName][key];
								const options = setting.options.filter(x => x.optionId === optionId);
								if (options.length > 0 && options[0].deviceTypeId && options[0].deviceTypeId !== choosenDeviceTypeId) {
									this.checkboxes
										.filter(x => x.formData.name === setting.name)
										.map(checkbox => checkbox.selectedItem = false);

									delete this.formFilledData[event.tabName][key];
								}
							}

						} else {
							delete this.formFilledData[event.tabName][key];
						}
					});
			}

			const formDataManufacturer = this.formListData.allForms
				.filter(x => x.name === 'Версия PSI PED*(Только для ТО Ingenico)')[0];
			this.checkByManufacturerIngenico(formDataManufacturer, device);

			this.saveChanges(device);
		}
	}

	onChangeMultiselect(event: any, device: ExpertiseActDevice): void {
		this.selectFormFilledData[event.tabName][event.formData.name] = event.value;
		this.saveChanges(device);
	}

	onChangeCheckbox(data: any): void {
		let option;
		if (data.formData.options.length > 2) {
			const select = this.selects.filter(x => x.isTSType)[0];
			const deviceTypeId = select.formData.options.filter(x => x.optionId === select.selectedItem)[0].deviceTypeId;
			option = (data.event.target.checked
				? data.formData.options.filter(x => x.value === 'Да' && x.deviceTypeId === deviceTypeId)
				: data.formData.options.filter(x => x.value === 'Нет' && x.deviceTypeId === deviceTypeId))[0];
		} else {
			option = (data.event.target.checked
				? data.formData.options.filter(x => x.value === 'Да')
				: data.formData.options.filter(x => x.value === 'Нет'))[0];
		}

		if (option) {
			this.formFilledData[this.getTabName(data.device)][data.formData.name] = option.optionId;

			const formDataManufacturer = this.formListData.allForms
				.filter(x => x.name === 'Наличие Secure Link*(Только для ТО VeriFone)')[0];
			this.checkByManufacturerVerifone(formDataManufacturer, data.device);

			this.saveChanges(data.device);
		}
	
	}

	tryGetFormValue(formData: ExpertiseActSetting, device: ExpertiseActDevice): string {
		if (device.filledForms.length > 0) {
			const filteredFilledForms = device.filledForms.filter(x => x.option.settingId === formData.settingId);
			if (filteredFilledForms.length === 1) {
				return filteredFilledForms[0].textValue ? filteredFilledForms[0].textValue : filteredFilledForms[0].option.value;
			}
		}

		return null;
	}

	tabChanged(event): void {

	}

	getTabName(device: ExpertiseActDevice): string {
		return device.name ? device.name : device.deviceTypeName;
	}

	getGroupName(formData: ExpertiseActSetting): string {
		this.lastGroupName = formData.groupName;
		return formData.groupName;
	}

	getSelectData(formData: ExpertiseActSetting): KeyValueObject[] {
		const selectData = formData.options.map(option => { return { id: option.optionId, name: option.value } as KeyValueObject; });
		this.allSelectListsData.push(selectData);
		return selectData;
	}

	showTextForm(formData: ExpertiseActSetting, device: ExpertiseActDevice): boolean {
		if (device.deviceTypeId === <number>DeviceTypeEnum.pinPad && formData.name === 'Версия ПО/ОС') {
			return false;
		}

		return true;
	}

	showFormGroup(tabName: string, formData: ExpertiseActSetting): boolean {
		const groupName = this.getGroupName(formData);
		if (groupName === 'Выполнить проверку связи/загрузку ключей (рабочих)' || groupName === 'Выполнить тестовые операции (оплата, отмена, сверка итогов)') {
			const includedTSId = this.formFilledData[tabName]['ТО включается'];
			if (!includedTSId) {
				return false;
			}

			const includedTSForm = this.formListData.allForms.filter(x => x.name === 'ТО включается')[0];
			const includedTSValue = includedTSForm.options.filter(x => x.optionId === includedTSId)[0];
			if (includedTSValue.value === 'Нет') {
				return false;
			}
		}

		return true;
	}

	showSelectForm(tabName: string, formData: ExpertiseActSetting): boolean {
		if (formData.name === 'Чек') {
			const tsTypeId = this.selectFormFilledData[tabName]['Тип ТО'];
			if (tsTypeId) {
				const tsTypeForm = this.formListData.allForms.filter(x => x.name === 'Тип ТО')[0];
				const tsTypeValue = tsTypeForm.options.filter(x => x.optionId === tsTypeId)[0];
				if (tsTypeValue.value === 'Внешняя PIN-клавиатура') {
					return false;
				}
			}
		}

		return true;
	}

	isTextForm(formData: ExpertiseActSetting): boolean {
		return formData.formType === FormType.TextInput;
	}

	isSelectForm(formData: ExpertiseActSetting): boolean {
		return formData.formType === FormType.Select;
	}

	isMultiselectForm(formData: ExpertiseActSetting): boolean {
		return formData.formType === FormType.Multiselect;
	}

	isCheckboxForm(formData: ExpertiseActSetting): boolean {
		return formData.formType === FormType.Checkbox;
	}

	isCompletedWorkActivityStatus(): boolean {
		return this.activityStatusId === <number>ActivityStatusEnum.workCompleted;
	}

	isRequiredTextFrom(tabName: string, formData: ExpertiseActSetting): boolean {
		const appearenceIds = this.selectFormFilledData[tabName]['Внешний вид'];
		if (appearenceIds) {
			const appearenceForm = this.formListData.allForms.filter(x => x.name === 'Внешний вид')[0];
			const appearences = appearenceForm.options.filter(x => appearenceIds.some(id => id === x.optionId));
			formData.isRequired = !appearences.some(x => x.value === 'Товарный вид');
		}

		return formData.isRequired;
	}

	checkByManufacturerIngenico(formData: ExpertiseActSetting, device: ExpertiseActDevice): boolean {
		return this.checkByManufacturer('Ingenico', 'Версия PSI PED*(Только для ТО Ingenico)', formData, device);
	}

	checkByManufacturerVerifone(formData: ExpertiseActSetting, device: ExpertiseActDevice): boolean {
		return this.checkByManufacturer('VeriFone', 'Наличие Secure Link*(Только для ТО VeriFone)', formData, device);
	}

	checkByManufacturer(manufacturer: string, formName: string, formData: ExpertiseActSetting, device: ExpertiseActDevice): boolean {
		if (formData.name === formName) {
			if (device.manufacturer && device.manufacturer === manufacturer) {
				return true;
			}

			const option = this.getChoosenManufacturerOption(device);
			if (!option || option.value !== manufacturer) {
				this.formFilledData[this.getTabName(device)][formName] = null;
				return false;
			}

		}

		return true;
	}

	checkByVtbContragent(formData: ExpertiseActSetting): boolean {
		if (formData.name === 'Тип процессинга' || formData.name === 'Наличие Secure Link*(Только для ТО VeriFone)') {
			if (this.vtbCustomerContragentId !== this.contragentId) {
				return false;
			}
		}

		return true;
	}

	isValidExpertise(): boolean {
		const validCheckboxes = this.checkboxes
			.filter(x => x.isRequired)
			.every(x => x.selectedItem);

		const validSelects = !this.selects
			.filter(x => x.formData.isRequired)
			.some(x => !x.selectedItem);

		const validMultiselects = this.multiselects
			.filter(x => x.formData.isRequired)
			.every(x => x.selectedItems && x.selectedItems.length > 0);

		return validCheckboxes && validSelects && validMultiselects;
	}

	showCheckbox(formData: ExpertiseActSetting, device: ExpertiseActDevice): boolean {
		const filledForms = this.selectFormFilledData[this.getTabName(device)];
		if (!filledForms) {
			return false;
		}

		let optionWithCurrentTypeId;
		const choosenDeviceTypeOptionId = filledForms[this.TSTypeFieldName];
		if (choosenDeviceTypeOptionId) {
			const settingWithCurrentTypeId = this.formListData.allForms.filter(x => x.options.some((z => z.optionId === choosenDeviceTypeOptionId) as any))[0];
			optionWithCurrentTypeId = settingWithCurrentTypeId.options.filter(x => x.optionId === choosenDeviceTypeOptionId)[0];

			if (optionWithCurrentTypeId) {
				device.deviceTypeId = optionWithCurrentTypeId.deviceTypeId;
			}
		}

		return formData.options.some((x => !x.deviceTypeId && !x.deviceModelId && !x.deviceConnectionTypeId && !x.deviceConnectionTypeId) as any) ||
			(optionWithCurrentTypeId && formData.options.some((x => x.deviceTypeId === optionWithCurrentTypeId.deviceTypeId) as any));
	}

	private createFilledForms(formListData: ExpertiseActFormList): void {
		formListData.devices.map((device, index) => {
			const tabSetName = this.getTabName(device);

			this.formFilledData[tabSetName] = {};
			this.selectFormFilledData[tabSetName] = {};

			device.filledForms.map(filledForm => {
				const form = formListData.allForms.filter(x => x.settingId === filledForm.option.settingId)[0];

				if (form.formType === FormType.Select) {
					this.selectFormFilledData[tabSetName][form.name] = filledForm.optionId;
				} else if (form.formType === FormType.Multiselect) {
					if (!this.selectFormFilledData[tabSetName][form.name]) {
						this.selectFormFilledData[tabSetName][form.name] = [];
					}

					this.selectFormFilledData[tabSetName][form.name].push(filledForm.optionId);
				} else if (form.formType === FormType.Checkbox) {
					this.formFilledData[tabSetName][form.name] = filledForm.optionId;
				} else {
					this.formFilledData[tabSetName][form.name] = filledForm.textValue
						? filledForm.textValue
						: filledForm.option.value;
				}

			});

			this.createEmptyFormsData(tabSetName, formListData);
			this.createPrefilledStaticFields(tabSetName, formListData, device);

		});
	}

	private createEmptyFormsData(tabSetName: string, formListData: ExpertiseActFormList): void {
		formListData.allForms
			.filter(x => x.formType === FormType.Select || x.formType === FormType.Multiselect)
			.map(formData => {
				if (!this.selectFormFilledData[tabSetName][formData.name]) {
					this.selectFormFilledData[tabSetName][formData.name] = null;
				}
			});

		formListData.allForms
			.filter(x => x.formType === FormType.Checkbox)
			.map(formData => {
				if (!this.formFilledData[tabSetName][formData.name]) {
					const noOption = formData.options.filter(x => x.value === 'Нет')[0];
					if (noOption) {
						this.formFilledData[tabSetName][formData.name] = noOption.optionId;
					}
				}
			});
	}

	private createPrefilledStaticFields(tabSetName: string, formListData: ExpertiseActFormList, device: ExpertiseActDevice): void {
		this.prefilledFieldsMap.map(map => {
			if (formListData.allForms.some((x => x.name === map.name) as any)) {
				if (map.isSelectForm) {
					if (!this.selectFormFilledData[tabSetName][map.name]) {
						const option = map.mapper(map.name, device, formListData);
						if (option) {
							this.selectFormFilledData[tabSetName][map.name] = option.optionId;
							device.filledForms.push({ optionId: option.optionId, option: option  } as ExpertiseActForm);
						}
					}
				} else {
					if (!this.formFilledData[tabSetName][map.name]) {
						this.formFilledData[tabSetName][map.name] = map.mapper(map.name, device, formListData);
					}
				}

			}
		});
	}

	private getChoosenManufacturerOption(device: ExpertiseActDevice): any {
		const filledManufacurer = this.selectFormFilledData[this.getTabName(device)]['Производитель'];
		if (filledManufacurer) {
			const manufacturerForms = this.formListData.allForms.filter(x => x.name === 'Производитель');
			const options = manufacturerForms[0].options.filter(x => x.optionId === filledManufacurer);
			if (options.length > 0) {
				return options[0];
			}
		}

		return null;
	}

	private saveChanges(device: ExpertiseActDevice, isFinished: boolean = false): void {

		if (device.isFinished) {
			return;
		}

		if (this.dataSaving) {
			this.devicesForSaving.push(device);
			return;
		}

		this.dataSaving = true;

		const expertiseResult = {
			requestId: this.requestId,
			activityId: this.activityId,
			externalId: this.externalId,
			device: {
				...device,
				isFinished: isFinished,
				name: this.getTabName(device),
				filledForms: []
			}
		};

		const filledForms = expertiseResult.device.filledForms;

		const tabName = this.getTabName(device);

		this.addRequestData(filledForms);
		this.mapFilledFormsToExpertiseForm(filledForms, this.formFilledData[tabName]);
		this.mapFilledFormsToExpertiseForm(filledForms, this.selectFormFilledData[tabName]);

		expertiseResult.device.filledForms = expertiseResult.device.filledForms.filter(x => x.optionId);

		this.requestDevicesService.saveExpertiseData(expertiseResult)
			.subscribe(result => {
				this.dataSaving = false;
				if (result.isSuccessful) {
					device.isFinished = isFinished;

					if (this.onSave) {
						this.onSave.emit();
					}

					if (this.devicesForSaving.length > 0) {
						this.saveChanges(this.devicesForSaving.shift(), false);
					}
				}
			});
		
	}

	private mapFilledFormsToExpertiseForm(filledForms: ExpertiseActForm[], formFilledData): void {
		Object.keys(formFilledData)
			.map(settingName => {
				const settings = this.formListData.allForms.filter(x => x.name === settingName);
				if (settings.length > 0) {
					const setting = settings[0];
					if (setting.formType === FormType.TextInput) {

						filledForms.push({
							textValue: formFilledData[settingName],
							optionId: setting.options[0].optionId
						} as ExpertiseActForm);

					} else if (setting.formType === FormType.Multiselect) {

						const filledValues = formFilledData[settingName];
						if (filledValues && filledValues.length > 0) {
							filledValues.map(value => {
								filledForms.push({
									optionId: value.id ? value.id : value
								} as ExpertiseActForm);
							});
						}

					} else {

						filledForms.push({
							optionId: formFilledData[settingName]
						} as ExpertiseActForm);

					}
				}
			});
	}

	private addRequestData(filledForms: ExpertiseActForm[]): void {
		const externalIdForm = this.formListData.allForms.filter(x => x.name === 'Номер заявки в системе Заказчика')[0];
		const requestIdForm = this.formListData.allForms.filter(x => x.name === 'Номер заявки в системе Исполнителя')[0];

		filledForms.push({
			textValue: this.externalId,
			optionId: externalIdForm.options[0].optionId
		} as ExpertiseActForm);

		filledForms.push({
			textValue: this.requestId.toString(),
			optionId: requestIdForm.options[0].optionId
		} as ExpertiseActForm);
	}

}
