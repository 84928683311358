import { UploadFile } from "../../../models/attachment";

export class AddingDeviceModel {
    serialNumber: string = "";
    nomenclature: string = "";
    
    index: number;
    isValid: boolean;

    uploadAttachments: UploadFile[] = [];
}