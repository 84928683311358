import { CommentKindEnum } from "../../../enums/comment-kind.enum";

export class SetPerformerGroupModel {

    constructor(userGroupId?: number, userId?: number, comment?: string){
        this.userGroupId = userGroupId;
        this.comment = comment;
    }

    userGroupId?: number;
    comment: string;
    commentKind: CommentKindEnum;
}