import { DeviceInfo } from "../../../../../models/device/device-info";

export class InstallFlowSummarySummaryData {
    activityId: number;
    requestId: number;

    activityResultId: number;
    activityResultName: string;
    completedDateTime: Date;
    comment: string;
    activityWorkflowTransitionId: number;

    devicesToInstall: DeviceInfo[] = [];

    generalEquipments: SummaryDeviceEquipment[] = [];

    simcardEquipments: SummarySimcardEquipment[] = [];
}

export class SummaryDeviceEquipment {
    nomenclature: string;
    serialNumber: string;
}

export class SummarySimcardEquipment {
    nomenclature: string;
    iccid: string;
}