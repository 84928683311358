export enum ActivitySuccessResultFlowOrder {

    /** Результат работ */
    completeActivityResultProperties = 1,

    /** Основное оборудование */
    completeActivityDismantledSeries = 2,

    /** Добавление 'Основное оборудование' */
    completeActivityDismantledSeriesAddedMore = 3,

    /** Сведения о SIM-карте */
    completeActivityDismantledSimcard = 4,

    /** Добавление 'Сведения о SIM-карте' */
    completeActivityDismantledSimcardAddedMore = 5,

    /** Фото документов */
    completeActivityRequiredAttachment = 6,

    /** Резюме */
    completeActivitySummary = 7,

    /** Подпись */
    completeActivitySignature = 8
}