import { BaseObject } from '../core/BaseObject';
import { WorkflowTransition } from './workflow-transition';
import { CustomerContact } from './customer-contact';
import { NewRequestService } from './new-request/new-request-service';
import { UploadFile } from '../attachment/upload-file';
import { RequestDeviceWarehouseRefillDto } from './request-device-warehouse-refills';
import { Contact } from '../contact/contact';
import { KeyValueObject } from '../core/KeyValueObject';
import { RequestPersonalData } from './request-personal-data';
import { SelfEmployedData } from '../User/selfEmployedData';
import { RequestCommonData } from './request-common-data';

export class Request extends BaseObject {
	requestId: number;

	createdDate: Date;
	createdDateMoscow: Date;
	createdDateLocal: Date;

	externalId: string;
	projectId: number;

	utcTimezoneShift: number;
	utcTimezoneShiftString: string;

	requestTypeId: number;
	requestTypeName: string;

	requestTypeKindId: number;

	initTypeId: number;
	initTypeName: string;

	authorContragentId: number;

	authorUserId: number;
	authorUserGroupId: number;

	performerUserId: number;
	performerUserGroupId: number;
	performerUserGroupName: string;

	statusId: number;
	statusName: string;
	statusKindId: number;

	partnerStatusAlias: string;
	partnerStatusAliasName: string;

	agreementNumber: number;
	agreementId: number;

	priorityId: number;
	priorityName: string;

	description: string;

	hoursToDeadlineString: string;

	spentHoursString: string;

	serviceCenterId: number;
	serviceCenterIsAlienGroup?: boolean;
	customerContragentId: number;
	customerContragentIntegraCode: string;
	customerContragentName: string;
	canSendAttachmentsToCustomer: boolean;

	merchantId: number;
	merchantName: string;
	merchantContragentAddress: string;
	merchantContragentName: string;

	customerObjectId: number;
	customerObjectName: string;
	customerObjectAddress: string;
	customerObjectDistanceFromCityKm: number;
	requestDistanceFromCityKm: number;

	mid: string;
	legalName: string;
	actualName: string;
	address: string;
	workplace: string;

	sla: number;
	slaStartDate: Date;
	slaStartDateMoscow: Date;
	slaStartDateLocal: Date;

	slaDueDate: Date;
	slaDueDateMoscow: Date;
	slaDueDateLocal: Date;

	locationAddress: string;

	warehouseId: number;

	senderWarehouseId: number;
	senderWarehouseAddress: string;
	senderWarehouseName: string;
	senderWarehouseNameAndAddress: string;
	senderWarehouseIsAutoSelectSn: boolean;

	recipientWarehouseId: number;
	recipientWarehouseAddress: string;
	recipientWarehouseName: string;
	recipientWarehouseNameAndAddress: string;

	lastActivityId: number;
	attributeString: string;
	requestAttributeId: number;

	servicePrice: number;
	distancePrice: number;
	expertisePrice: number;
	totalPricePublish: number;
	totalPriceComplete: number;

	endOfSlaDate: Date;
	endOfSlaDateMoscow: Date;
	endOfSlaDateLocal: Date;

	finishDate: Date;
	finishDateMoscow: Date;
	finishDateLocal: Date;

	finishDateFact: Date;
	finishDateFactLocal: Date;

	contragentContacts: Contact[] = [];

  acquirerId?: number | null;
  bankAcquirer: string;
  tidNumbers: string[] = [];
  /**
   * LTER-2145 Номера Tid, которые были заменены новыми
   */
  tidNumbersMigrationFrom: string[] = [];
  acceptedTidNumbers: KeyValueObject[] = [];

	lastActivityStatusId: number;
	lastActivityStatusName: string;

	workflowTransitions: WorkflowTransition[] = [];
	customerContacts: CustomerContact[] = [];
	requestServices: NewRequestService[] = [];
	requestAttachments: UploadFile[] = [];

	requestDeviceWarehouseRefills: RequestDeviceWarehouseRefillDto[] = [];

	createNewSelfEmployedAccountCommand: RequestPersonalData = new RequestPersonalData();
	senderWarehouseCells: string[] = [];
	recipientWarehouseCells: string[] = [];


	carrierId: number;
	carrierName: string;
	trackNumber: string;
	lastCriticalNotification: string;

	supplierContragentName: string;

	onlyOneRecipientWarehouseCellNumber: string;
	recipientWarehouseAreaCellNumberStorage: string;

	acquiredBankName: string;

	externalProjectId: string;
	externalProjectName: string;
	externalProjectCoeff: number;
	externalProjectDataEnd: Date;
	externalProjectDataEndMoscow: Date;
	externalProjectDataEndLocal: Date;
	reconciliationCompleted: boolean;
	reconciliationContractorCompleted: boolean;

	customerCreatedDate: Date | null;

	isGroup: boolean;
	isInGroupExt: boolean;
	isInGroup: boolean;

	merchantRegionName: string;

	stoppedUntilLocal: Date;

	customerObjectLatitude?: number;
	customerObjectLongitude?: number;

	requestIdsToChangeCustomerObjectAddress: number[] = [];

	isNewPricingAlgorithmExchangeRequest: boolean;
	directPrice?: number;
	startPrice?: number;
	maxPrice?: number;
	currentPrice?: number;
	finalPrice?: number;
	engineerRating: number;

	requestRegistrationFilterId?: number;
	requestRegistrationFilterVersion?: number;

	recipientWarehouseAreaId?: number;

	parentRequestId: number;
	parentExternalId: string;
	childCount: number;

	baseMspRequestTypeId?: number;
	/**
		* Наименование типа заявки от заказчика
	**/
	originalRequestType: string;
	replacePinPad: boolean;


	recipientWarehouseAreaIntegraCode: string;
	senderWarehouseAreaIntegraCode: string;
	serviceCenterWarehouseAreaIntegraCode: string;
	serviceCenterWarehouseAreaId?: number;
	senderWarehouseAreaId?: number;
	integraCode: string;
  	tidServiceCategoryId?: number;
	selfEmployedData: SelfEmployedData;
	isSelfEmployedActivity: boolean;
	notifyCustomerObjectAddressChange: boolean = false;

	unknownDistance: boolean = false;
	commonData: RequestCommonData;
	
	changeDefaultCustomerObjectServiceCenterBelonging: boolean;

	cancelReserve: boolean;

	isOneRoleSC: boolean;

	plannedDate: Date;
	plannedDateLocal: Date;

	reasonForStopping: string;

	defaultScWarehouseName: string;
	defaultScWarehouseId: number;
}
