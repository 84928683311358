import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import {RequestStatusDto} from "../models/request/request-status.dto";

@Injectable()
export class RequestStatusesService extends DataService<RequestStatusDto, RequestStatusDto> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'request-statuses';
	}
}
