export enum DeviceStatus {
    // На складе
    inStock = 1,

    // Зарезервировано
    reserved = 2,

    // Диагностика
    diagnostics = 3,

    // Неработоспособное
    inoperative = 4,

    // Списано
    writtenOff = 5,

    // Установлено на объекте
    installed = 6,

    // Отгружено по заявке
    shippedOnRequest = 7,

    // К демонтажу
    toUninstall = 8,

    // В запросе
    inRequest = 9,

    // Демонтировано
    deInstalled = 10,

    // Сдано на склад
    deliveredToWarehouse = 11,

    // Принято на склад
    receivedAtWarehouse = 12,

    // Собрано для перемещения
    assembledToMove = 13,

    // Ожидает сбора для перемещения
    awaitingAssemblyToMove = 14,

    // Принято на складе получателя
    acceptedAtTheRecipientWarehouse = 15,

    /**Не принято на складе получателя*/
	notReceivedAtTheRecipientsWarehouse = 16,

    // Доступно для перемещения
    availableToMove = 17,
    
    // Не найден
    notFound = 18,
    
    // Частично доступно к перемещению
    partiallyMoveable = 19,

    // Не подтверждено в БЧ
    notConfirmedInEth = 20,

    // Ожидает приема на складе
    awaitingReceiveAtWarehouse = 21,

    // Добавить артикул в 1C ERP
    addArticleTo1cErp = 22,

    /**Возврат на классический склад */
	returnToClassicWarehouse = 23,
}
