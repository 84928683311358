﻿import {Component, Input, ViewEncapsulation, OnInit} from "@angular/core";
import {ILoadEvent} from "angular8-yandex-maps";
import {ContragentPolygon} from "../../../../../shared/models/contragent/contragent-polygon";
import {ContragentsService} from "../../../contragents.service";
import {LookupService} from "../../../../../shared/services/lookup.service";
import {DataSourceRequestState} from "@progress/kendo-data-query";
import {KeyValueObject} from "../../../../../shared/models/core/KeyValueObject";
import {NotificationType} from "../../../../../core/services/notification-type";
import {NotificationService} from "../../../../../core/services/notification.service";

@Component({
	selector: 'contragent-polygon',
	templateUrl: './contragent-polygon.component.html',
	styleUrls: [
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	],
	encapsulation: ViewEncapsulation.None
})
export class ContragentPolygonComponent implements OnInit {

	@Input()
	public contragentId: number;

	@Input()
	public choosenServiceCenter: any = null;

	public isLoading: boolean = false;
	public polygonsFile: File = null;
	public polygons: ContragentPolygon[] = [];

	serviceCenters: KeyValueObject[] = [];

	private yaMap;
	private ymaps;
	private polygonObjects: any[] = [];

	constructor(
		private dataService: ContragentsService,
		private lookupService: LookupService,
		private notificationService: NotificationService,
	){

	}

	ngOnInit(): void {

	}

	onLoadMap(loadEvent: ILoadEvent) {
		this.yaMap = loadEvent.instance;
		this.ymaps = loadEvent.ymaps;

		const serviceCenterState: DataSourceRequestState = {
			filter: { logic: 'or', filters: [
					{ field: 'ContragentId', operator: 'eq', value: this.contragentId },
				] }
		};

		if (this.choosenServiceCenter){
			this.loadPolygons(this.choosenServiceCenter.id);
		} else {
			this.lookupService.getData('service-centers', serviceCenterState)
				.subscribe(data => {
					this.serviceCenters = data;
					if (this.serviceCenters.length > 0) {
						this.choosenServiceCenter = this.serviceCenters[0];
						this.loadPolygons(this.choosenServiceCenter.id);
					}
				});
		}

	}

	onChoosedFile(event: any): void {
		const file = event.target.files[0];
		if (file.type.includes('json')){
			this.polygonsFile = event.target.files[0];

			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				const event = e as any;
				const json = JSON.parse(event.target.result);

				let count = 0;
				this.polygons = json.geometries[0].coordinates.map(coordinates => {
					const polygon = new ContragentPolygon();
					if (count === 0)
						polygon.isBase = true;
					polygon.coordinates = coordinates[0].map(x => [x[1], x[0]]);

					count++;

					return polygon;
				});
				this.setPolygons();
			}

			fileReader.readAsText(file);
		} else {
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Некорректный формат файла. Файл должен быть в формате json.',
				notificationType: NotificationType.SweetAlert
			});
		}
	}

	onServiceCenterChange(serviceCenter: any) {
		if (serviceCenter && serviceCenter.id) {
			this.loadPolygons(serviceCenter.id);
			this.polygonsFile = null;
		}
	}

	uploadPolygon(): void {
		this.isLoading = true;
		this.dataService.uploadPolygons(this.contragentId, this.choosenServiceCenter.id, this.polygonsFile)
			.subscribe(result => {
				if (result.isSuccessful) {
					this.polygons = result.data;
					this.setPolygons();
				}

				this.isLoading = false;
			}, error => this.isLoading = false);
	}

	private setPolygons(): void {
		if (this.polygonObjects.length > 0) {
			this.polygonObjects.map(x => this.yaMap.geoObjects.remove(x.polygon));
			this.polygonObjects = [];
		}

		this.polygons.forEach(polygon => {
			if (polygon.coordinates.length === 0)
				return;

			const polygonObject = this.createPolygon(polygon);
			this.polygonObjects.push({ polygon: polygonObject, isBase: polygon.isBase });
			this.yaMap.geoObjects.add(polygonObject);
		});

		if (this.polygons.length > 0) {
		 	this.setMapCenter();
		} else if (this.choosenServiceCenter.addressLatitude && this.choosenServiceCenter.addressLongitude) {
			this.yaMap.setCenter([this.choosenServiceCenter.addressLatitude, this.choosenServiceCenter.addressLongitude], 9, {
				checkZoomRange: true
			});
		}
	}

	private createPolygon(polygon: ContragentPolygon): any {
		const polygonObject = new this.ymaps.Polygon([
			polygon.coordinates
		], {
			hintContent: 'Полигон',
		}, {
			fillColor: '#00FF0088',
			strokeWidth: 5,
		});

		return polygonObject;
	}

	private setMapCenter(): void {
		const bounds = this.getPolygonBounds();
		if (!bounds)
			return;

		this.yaMap.setBounds(bounds, {
			checkZoomRange: false,
			zoomMargin: 25
		});
	}

	private getPolygonBounds():number[] {
		try {
			const polygonObjects = this.polygonObjects.filter(x => x.isBase);
			return polygonObjects.length > 0 ? polygonObjects[0].polygon.geometry.getBounds() : null;
		} catch {
			console.log('Polygon wasn`t found');
		}
	}

	private loadPolygons(serviceCenterId: number): void {
		this.isLoading = true;
		this.dataService.getPolygonsBySc(serviceCenterId)
			.subscribe(result => {
				if (result.isSuccessful){
					this.polygons = result.data;
					this.setPolygons();
				} else {
					if (this.choosenServiceCenter.addressLatitude && this.choosenServiceCenter.addressLongitude) {
						this.yaMap.setCenter([this.choosenServiceCenter.addressLatitude, this.choosenServiceCenter.addressLongitude], 9, {
							checkZoomRange: true
						});
					}
				}

				this.isLoading = false;
			}, error => this.isLoading = false);
	}
}
