import { Component, Inject, Injector, OnInit, Renderer2 } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from "@angular/material";
import { Contact } from "../../models/contact/contact";
import { UsersService } from "../../../admin/users/users.service";
import { NotificationService } from "../../../core/services/notification.service";
import { NotificationType } from "../../../core/services/notification-type";
import { Router } from "@angular/router";
import { SecurityService } from "../../../core/services/security.service";
import { CustomerContact } from "../../models/request/customer-contact";
import { RequestsService } from "../../services/requests.service";
import { ContragentContactsService } from "../../services/contragent-contacts.service";
import { CustomerEnum } from "../../enums/customer.enum";
import { ContactTypeEnum } from "../../enums/contact-type.enum";
import { ContragentContact } from "../../models/contragent/contragent-contact";
import { WarehousesService } from "../../services/warehouses.service";
import { WarehouseContact } from "../../models/warehouse/warehouse-contact";
import { ServiceCenterEnum } from "../../enums/service-center.enum";
import { CallToCustomerDestEnum } from "../../enums/call-to-customer-dest.enum";
import { forkJoin } from "rxjs";
import { DialogRef } from "@progress/kendo-angular-dialog";

@Component({
	selector: 'call-contacts',
	templateUrl: './call-contacts.component.html',
	styleUrls: [
		'./call-contacts.component.scss',
		'../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class CallContactsComponent implements OnInit {

	isFromRequest: boolean = false;
	requestId: number = null;

	currentRoute: string;
	
	tspContacts: CustomerContact[] = [];
	lanterSupports: ContragentContact[] = [];
	scWarehouseContacts: WarehouseContact[] = [];
	lanterWarehouseContacts: WarehouseContact[] = [];
	bankSupports: ContragentContact[] = [];

	contragentContactsButtomSheetVisibility: boolean = false;

	lanterSupportEnumVal = CallToCustomerDestEnum.lanterSupport;
	warehouseEnumVal = CallToCustomerDestEnum.warehouse;
	bankSupportEnumVal = CallToCustomerDestEnum.bankSupport;

	get showContactsTsp(): boolean {
		return this.isFromRequest && this.tspContacts !== null && this.tspContacts.length > 0;
	}

	get showLanterSupport(): boolean {
		return this.lanterSupports !== null && this.lanterSupports.length > 0;
	}

	get showWarehouseContactsSc(): boolean {
		return this.isFromRequest && this.securityService.isSelfEmployedEngineer()
			&& this.scWarehouseContacts != null && this.scWarehouseContacts.length > 0;
	}

	get showWarehouseContactsLanter(): boolean {
		return !this.isFromRequest
			&& this.lanterWarehouseContacts !== null && this.lanterWarehouseContacts.length > 0;
	}

	get showBankSupport(): boolean {
		return this.isFromRequest
			&& this.bankSupports !== null && this.bankSupports.length > 0;
	}

	get cardHeight(): number {
		return window.innerHeight - 260;
	}

	constructor(
		private bottomSheet: MatBottomSheet,
		//@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
		private usersService: UsersService,
		private notificationService: NotificationService,
		private router: Router,
		private securityService: SecurityService,
		private requestsService: RequestsService,
		private contragentContactsService: ContragentContactsService,
		private warehousesService: WarehousesService,
		private injector: Injector,
		private renderer: Renderer2
	) {
		// this.isFromRequest = data.isFromRequest;
	}

	ngOnInit(): void {

		setTimeout(() => {
			this.attachOverlayClick();
		  });

		this.currentRoute = this.router.url;
		let split = this.currentRoute.split("/");

		if (split !== null && split.length >= 2) {
			let tail = `${split[split.length - 2]}/${split[split.length - 1]}`;

			var regex = new RegExp('requests/[0-9]*');
			this.isFromRequest = regex.test(tail);

			if (this.isFromRequest) {
				this.requestId = +split[split.length - 1];
			}
		}

		// if (this.isFromRequest) {
		// 	forkJoin([this.requestsService.getCustomerContacts(this.requestId),
		// 		this.requestsService.requestContragent(this.requestId),
		// 		this.contragentContactsService.getByContragent(CustomerEnum.lanter, ContactTypeEnum.support),
		// 		this.requestsService.requestSc(this.requestId),
		// 	]).subscribe(results => {
	
		// 		let subs = [];
	
		// 		this.tspContacts = results[0];
		// 		let con = results[1];
		// 		this.contragentContactsService.getByContragent(con, ContactTypeEnum.support).subscribe(res => this.bankSupports = res.data);
				
		// 		this.lanterSupports = results[2].data;
		// 		let sc = results[3];
		// 		this.warehousesService.scWarehouseContacts(sc).subscribe(res => this.scWarehouseContacts = res);
	
		// 		forkJoin(subs).subscribe(subResults => {
		// 			this.bankSupports = subResults[0].data;
		// 			this.scWarehouseContacts = subResults[1];
		// 		});
		// 	});
		// } else {
		// 	forkJoin([
		// 		this.contragentContactsService.getByContragent(CustomerEnum.lanter, ContactTypeEnum.support),
		// 		this.warehousesService.scWarehouseContacts(ServiceCenterEnum.lanter)
		// 	]).subscribe(results => {
		// 		this.lanterSupports = results[0].data;
	
		// 		this.lanterWarehouseContacts = results[1];
		// 	});
		// }

		

		if (this.isFromRequest) {
			this.requestsService.getCustomerContacts(this.requestId).subscribe(res => this.tspContacts = res);

			this.requestsService.requestContragent(this.requestId).subscribe(con => {
				this.contragentContactsService.getByContragent(con, ContactTypeEnum.support).subscribe(res => this.bankSupports = res.data);
			});
		}
		
		this.contragentContactsService.getByContragent(CustomerEnum.lanter, ContactTypeEnum.support).subscribe(res => this.lanterSupports = res.data);

		if (this.isFromRequest && this.securityService.isSelfEmployedEngineer()) {
			this.requestsService.requestSc(this.requestId).subscribe(sc =>{
				this.warehousesService.scWarehouseContacts(sc, true).subscribe(res => this.scWarehouseContacts = res);
			});
		}

		if (!this.isFromRequest) {
			this.warehousesService.scWarehouseContacts(ServiceCenterEnum.lanter, false).subscribe(res => this.lanterWarehouseContacts = res);
		}
	}

	hideNumber(number: string): string {
		if (number.length >= 12) {
			return number.replace(number.substring(1,8),"********")
		}

		if (number.length < 12 && number.length >= 8) {
			return number.replace(number.substring(1,6),"******")
		}

		if (number.length < 8 && number.length >= 6) {
			return number.replace(number.substring(1,4),"****")
		}

		if (number.length < 6) {
			return number.replace(number.substring(1,3),"***")
		}
	}

	openContragentContactsButtomSheetListCancel(event: MouseEvent) {
		this.bottomSheet.dismiss();
		event.preventDefault();
	}

	callToCustomerService(contragentContact: Contact, event: MouseEvent, callToCustomerDest: CallToCustomerDestEnum) {

		this.bottomSheet.dismiss();
		event.preventDefault();

		this.usersService.callToCustomerService(this.requestId, contragentContact, callToCustomerDest).subscribe(x => {

			if (x.isSuccessful) {

				this.notificationService.success({
					title: '',
					message: `Устанавливается соединение... Ожидайте входящего звонка`,
					notificationType: NotificationType.SweetAlert
				});

			}

		});

	}

	callToTsp(сustomerContact: CustomerContact, event: MouseEvent) {

		this.bottomSheet.dismiss();
		event.preventDefault();

		this.usersService.callToTsp(сustomerContact).subscribe(x => {

			if (x.isSuccessful) {

				this.notificationService.success({
					title: '',
					message: `Устанавливается соединение... Ожидайте входящего звонка`,
					notificationType: NotificationType.SweetAlert
				});

			}

		});

	}

	public onCancel(): void {
		this.closeDialog();
	}

	private closeDialog(): void {
		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}

	private onOverlayClick: Function;

	private attachOverlayClick(): void {
		if (this.onOverlayClick) { this.onOverlayClick(); }
  
		const overlay = document.body.querySelector('kendo-dialog .k-overlay');
		this.onOverlayClick = this.renderer.listen(overlay, 'click', () => {
			this.closeDialog();
		});
	  }
}