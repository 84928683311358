import { HttpClient } from "@angular/common/http";
import { DataService } from "../../core/services/data.service";
import { NotificationService } from "../../core/services/notification.service";
import { RequestDeviceWarehouseRefillDto } from "../models/request/request-device-warehouse-refills";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ActionResult } from "../models/core/ActionResult";
import { Observable } from "rxjs";
import { ActionDataResult } from "../models/core/ActionDataResult";

@Injectable()
export class RequestDeviceWarehouseRefillsService extends DataService<RequestDeviceWarehouseRefillDto, RequestDeviceWarehouseRefillDto> {
	
    constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'request-device-warehouse-refills';
	}

	public createRequestDeviceWarehouseRefillByNomenclature(request: RequestDeviceWarehouseRefillDto): Observable<ActionResult> {
		return this.postQuery<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/create-by-nomenclature`, request);
	}
	public deleteRequestDeviceWarehouseRefillByNomenclature(request: RequestDeviceWarehouseRefillDto): Observable<ActionResult> {
		return this.postQuery<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/delete-by-nomenclature`, request);
	}
	public getRequestDeviceWarehouseRefillsWithParts(requestId: number): Observable<ActionDataResult<RequestDeviceWarehouseRefillDto[]>> {
		return this.postQuery<RequestDeviceWarehouseRefillDto[]>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/request-device-warehouse-refills-with-parts`, {requestId: requestId});
	}
}