import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { KeyValueObject } from '../../models/core/KeyValueObject';
import { LookupService } from '../../services/lookup.service';

@Component({
	selector: 'lookup-dropdown',
	templateUrl: './lookup-dropdown.component.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./lookup-dropdown.component.scss'
	]
})
export class LookupDropDownComponent implements OnInit, OnDestroy {

	private sub: Subscription;

	public filterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	public defaultItem: KeyValueObject = new KeyValueObject(null, '(Не выбран)'); 
	public loading: boolean = false;
	public data: KeyValueObject[] = [];

	@Input()
	controllerName: string = null;

	@Input()
	showDefaultItem: boolean = false;

	@Input()
	showFilter: boolean = false;

	@Input() selectedId?: number = null;
  	@Output() selectedIdChange? = new EventEmitter<number>();

	@Output() afterDataLoaded? = new EventEmitter<KeyValueObject[]>();

	constructor(private lookupService: LookupService) {	}

	ngOnInit(): void {

		if (this.controllerName != null) {

			this.loading = true;

			this.sub = this.lookupService.getData(this.controllerName, null).subscribe(res => {
				this.data = res;
				this.loading = false;

				if (this.afterDataLoaded)
					this.afterDataLoaded.emit(this.data);
			});
		}
		
	}

	public onChange(value?: number): void {

		if (this.selectedIdChange != null) {
			this.selectedIdChange.emit(value);
		}

    }

	ngOnDestroy(): void {

		if (this.sub != null){
			this.sub.unsubscribe();
		}

	}

}