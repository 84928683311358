import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from '../models/core/ActionDataResult';

@Injectable()
export class AddressService {

	protected controllerVersion = 1;
	protected controllerName = "address";

	constructor(
		public http: HttpClient,
		public notificationService: NotificationService
	) { }

	public addAddressOrCoordinatesByName(address: string,
        customerObjectId?: number,
        latitude?: number,
        longitude?: number,
        logMessage?: string,
		dadataTryCount: number = 1,
		suppressException: boolean = false): Observable<ActionDataResult<number>> {

		return this.http.post<ActionDataResult<number>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/add-address-or-coordinates-by-name`, 
        { 
            fullName: address,
            customerObjectId,
            latitude,
            longitude,
            logMessage,
			dadataTryCount,
			suppressException
        });
	}
	
}