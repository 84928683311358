import { NewRequestDevice } from "../request/new-request/new-request-device";

export class SetSerialNumbersFormDevice {

	constructor(device: NewRequestDevice) {
		this.device = device;
	}

	processed: boolean = false;
	device: NewRequestDevice;

}
