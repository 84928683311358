import { Component, OnInit } from "@angular/core";
import { CellClickEvent, GridComponent, GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { SecurityService } from "../../core/services/security.service";
import { KeyValueObject } from "../../shared/models/core/KeyValueObject";
import { EngubatorPlanningZone } from "../../shared/models/engubator/engubator.planning-zone";
import { EngubatorCommonSetting } from "../../shared/models/engubator/engubator.setting";
import { EngubatorSkill, EngubatorSkillComposition } from "../../shared/models/engubator/engubator.skill";
import { VeerouteCommonSettingsService } from "../../shared/services/engubator/engubator.common-settings.service";
import { EngubatorPlanningZonesService } from "../../shared/services/engubator/engubator.planningZones.service";
import { EngubatorSkillsService } from "../../shared/services/engubator/engubator.skills.service";
import { LookupService } from "../../shared/services/lookup.service";
import { EngubatorRegionSegmentsService } from "../../shared/services/engubator/engubator.region-segments.service";
import { ExtendedHierarchyLookupModel } from "../../shared/models/core/ExtendedHierarchyLookupModel";
import { EngubatorRegionSegment } from "../../shared/models/engubator/engubator.region";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { GridSettings } from "../../shared/models/grid/grid-settings";
import { AppService } from "../../app.service";

@Component({
	selector: 'veeroute-settings',
	templateUrl: './veeroute-settings.page.html',
	styleUrls: [
		'./veeroute-settings.page.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})

export class VeerouteSettingsPage implements OnInit {

	commonSettings: EngubatorCommonSetting[];
	skills: EngubatorSkill[];
	skillCompositions: EngubatorSkillComposition[] = [];
	regions: ExtendedHierarchyLookupModel<EngubatorRegionSegment>[];
	requestTypesLookup: KeyValueObject[] = [];
	noDeviceModelString: string = '-';
	deviceModelsLookup: KeyValueObject[] = [{ id: null, name: this.noDeviceModelString, groupName: null, disabled: false, integraCode: null }];
	planningZones: EngubatorPlanningZone[];
	availableRegions: EngubatorRegionSegment[] = [];
	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'startsWith' };

	constructor(
		protected lookupService: LookupService,
		protected commonSettingsService: VeerouteCommonSettingsService,
		protected planningZoneService: EngubatorPlanningZonesService,
		protected skillsService: EngubatorSkillsService,
		protected regionsService: EngubatorRegionSegmentsService,
		protected securityService: SecurityService,
		protected appService: AppService
	) {
		appService.pageTitle = 'Параметры Veeroute';
	}

	public skillsGridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 50,
		},
		columnsConfig: []
	}

	ngOnInit(): void {
		this.commonSettingsLoading = true;
		this.skillsLoading = true;
		this.zonesLoading = true;
		this.lookupService.getData("request-types", null).subscribe(data => this.requestTypesLookup = data);
		this.lookupService.getData("device-models", null).subscribe(data => this.deviceModelsLookup = this.deviceModelsLookup.concat(data));
		this.loadCommonSettings();
		this.loadSkills();
		this.regionsService.getHierarchyData()
			.pipe(finalize(() => this.zonesLoading = false))
			.subscribe(data => {
				this.regions = data;
			});
		this.loadPlanningZones();
	}

	private loadCommonSettings(): void {
		this.commonSettingsService.list(null)
			.pipe(finalize(() => this.commonSettingsLoading = false))
			.subscribe(data => {
				this.commonSettings = data.data;
			});
	}

	private loadSkills(): void {
		this.skillsService.list('includeComposition=true')
			.pipe(finalize(() => this.skillsLoading = false))
			.subscribe(data => {
				this.skills = data.data;
				this.skillCompositions = [].concat(...this.skills.map(m => { m.composition.forEach(e => e.skill = m); return m.composition; }));
				this.loadSkillItems();
			});
	}

	private loadPlanningZones(): void {
		this.planningZoneService.list()
			.pipe(finalize(() => this.zonesLoading = false))
			.subscribe(data => {
				this.planningZones = data.data;
			});
	}

	public loading(): boolean {
		return this.commonSettingsLoading || this.skillsLoading || this.zonesLoading;
	}

	public getSkillName(id: number): string {
		return this.skills.find(f => f.id == +id).name;
	}

	public getRequestTypeName(id: number): string {
		return this.requestTypesLookup.find(f => f.id == id).name;
	}

	public getDeviceModelName(id: number): string {
		if (id)
			return this.deviceModelsLookup.find(f => f.id == id).name;
		return this.noDeviceModelString;
	}

	get hasAddCommonSettingsClaim(): boolean {
		return this.securityService.hasClaim('cat-Engubator.CommonSettings-add');
	}

	get hasEditCommonSettingsClaim(): boolean {
		return this.securityService.hasClaim('cat-Engubator.CommonSettings-edit');
	}

	get hasAddSkillsClaim(): boolean {
		return this.securityService.hasClaim('cat-Skill-add');
	}

	get hasEditSkillsClaim(): boolean {
		return this.securityService.hasClaim('cat-Skill-edit');
	}

	get hasAddZonesClaim(): boolean {
		return this.securityService.hasClaim('cat-ServiceZone-add');
	}

	get hasEditZonesClaim(): boolean {
		return this.securityService.hasClaim('cat-ServiceZone-edit');
	}

	//>>таблица commonSettingsGrid
	commonSettingsLoading: boolean = false;
	editedCommonSettingsGridRowIndex: number;

	createNewCommonSetting() {
		return () => {
			return new EngubatorCommonSetting();
		};
	}

	addOrUpdateCommonSetting(dataItem: EngubatorCommonSetting, isNew: boolean): void {
		this.commonSettingsLoading = true;
		if (isNew) {
			this.commonSettingsService.create(dataItem)
				.subscribe(result => {
					this.commonSettings.push(dataItem);
					this.commonSettingsLoading = false;
				}, error => {
					this.commonSettingsLoading = false;
				});
		} else {
			this.commonSettingsService.update(dataItem, 'Обновлено успешно', false)
				.subscribe(result => {
					this.commonSettingsLoading = false;
				}, error => {
					this.commonSettingsLoading = false;
				});
		}
	}

	cancelAddOrUpdateCommonSetting(isNew: boolean): void {
		if (!isNew) {
			this.commonSettingsLoading = true;
			this.commonSettingsService.list(null)
				.pipe(finalize(() => this.commonSettingsLoading = false))
				.subscribe(data => {
					this.commonSettings = data.data;
				});
		}
	}

	commonSettingsGridCellClick(event: CellClickEvent): void {
		this.closeCurrentCommonSettingsGridRow(event.sender);
		this.editedCommonSettingsGridRowIndex = event.rowIndex;
		event.sender.editRow(event.rowIndex);
	}

	addCommonSettingRowCommand(event: CellClickEvent): void {
		this.closeCurrentCommonSettingsGridRow(event.sender);
	}

	private closeCurrentCommonSettingsGridRow(grid: GridComponent): void {
		if (this.editedCommonSettingsGridRowIndex + 1)
			grid.closeRow(this.editedCommonSettingsGridRowIndex);
	}
	//<<таблица commonSettingsGrid

	//>>таблица skillsGrid
	skillsLoading: boolean = false;
	editedSkillCompositionsGridRowIndex: number;

	createNewSkillComposition() {
		return () => {
			return new EngubatorSkillComposition();
		};
	}

	addOrUpdateSkillComposition(dataItem: EngubatorSkillComposition, isNew: boolean): void {
		this.skillsLoading = true;
		if (isNew) { //добавляем SkillComposition
			if (dataItem.skill.id == 0) { //добавляем Skill //Данный вариант законсервирован. Для расконсервации необходимо в kendo-combobox#skillName присвоить [allowCustom]="true"
				dataItem.skill.composition.push(dataItem);
				var skill = dataItem.skill;
				delete dataItem.skill;
				this.skillsService.create(skill)
					.subscribe(result => {
						this.loadSkills();
					}, error => {
						this.skillsLoading = false;
					});
			} else { //добавляем SkillComposition в существующий Skill
				this.skillsService.addSkillComposition(dataItem)
					.subscribe(result => {
						if (result.isSuccessful) {
							dataItem.id = result.data.id;
							dataItem.skill.composition.push(dataItem);
						}
						this.skillsLoading = false;
					}, error => {
						this.skillsLoading = false;
					});
			}
		} else { //изменяем SkillComposition
			if (dataItem.deviceModelId == 0) dataItem.deviceModelId = null;
			this.skillsService.updateSkillComposition(dataItem)
				.subscribe(result => {
					if (result.isSuccessful)
						dataItem.id = result.data.id;
					this.skillsLoading = false;
				}, error => {
					this.skillsLoading = false;
				});
		}
	}

	cancelAddOrUpdateSkillComposition(isNew: boolean): void {
		if (!isNew) {
			this.skillsLoading = true;
			this.loadSkills();
		}
	}

	skillCompositionsGridCellClick(event: CellClickEvent): void {
		this.closeCurrentSkillCompositionsGridRow(event.sender);
		this.editedSkillCompositionsGridRowIndex = event.rowIndex;
		event.sender.editRow(event.rowIndex);
	}

	addSkillCompositionRowCommand(event: CellClickEvent): void {
		this.closeCurrentSkillCompositionsGridRow(event.sender);
	}

	private closeCurrentSkillCompositionsGridRow(grid: GridComponent): void {
		if (this.editedSkillCompositionsGridRowIndex + 1)
			grid.closeRow(this.editedSkillCompositionsGridRowIndex);
	}

	public skillNameValueNormalizer = (text: Observable<string>) =>
		text.pipe(
			map((content: string) => {
				var newSkill: EngubatorSkill = { id: 0, name: content, composition: [] };
				return newSkill;
			})
		);
	//<<таблица skillsGrid

	//>>таблица zonesGrid
	zonesLoading: boolean = false;
	editedZonesGridRowIndex: number;

	createNewZone() {
		return () => {
			return new EngubatorPlanningZone();
		};
	}

	addOrUpdateZone(dataItem: EngubatorPlanningZone, isNew: boolean): void {
		this.zonesLoading = true;
		if (isNew) {
			this.planningZoneService.create(dataItem)
				.subscribe(result => {
					dataItem.id = result.data.id;
					this.planningZones.push(dataItem);
					this.zonesLoading = false;
				}, error => {
					this.zonesLoading = false;
				});
		} else {
			this.planningZoneService.update(dataItem, 'Обновлено успешно', false, undefined, `?id=${dataItem.id}`)
				.pipe(finalize(() => this.planningZoneService.list()
					.subscribe(data => {
						this.planningZones = data.data;
						this.zonesLoading = false;
					})))
				.subscribe();
		}
	}

	cancelAddOrUpdateZone(isNew: boolean): void {
		if (!isNew) {
			this.zonesLoading = true;
			this.planningZoneService.list()
				.pipe(finalize(() => this.zonesLoading = false))
				.subscribe(data => {
					this.planningZones = data.data;
				});
		}

	}

	zonesGridCellClick(event: CellClickEvent): void {
		this.closeCurrentZonesGridRow(event.sender);
		this.editedZonesGridRowIndex = event.rowIndex;
		event.sender.editRow(event.rowIndex);
		this.availableRegions = this.regions.filter(f => ![].concat.apply([], this.planningZones.filter(f2 => f2.id != event.dataItem.id).map(m => m.regions)).includes(f.entity.regionFiasId)).map(m => m.entity);
	}

	addZoneRowCommand(event: CellClickEvent): void {
		this.closeCurrentZonesGridRow(event.sender);
	}

	private closeCurrentZonesGridRow(grid: GridComponent): void {
		if (this.editedZonesGridRowIndex + 1)
			grid.closeRow(this.editedZonesGridRowIndex);
	}

	public getRegionsName(regionIds: string[]): string[] {
		return this.regions.filter(x => regionIds.includes(x.entity.regionFiasId)).map(x => x.name);
	}
	//<<таблица zonesGrid

	public skillsGridView: GridDataResult;

	private loadSkillItems(): void {
		this.skillsGridView = {
		  data: this.skillCompositions.slice(this.skillsGridSettings.state.skip, this.skillsGridSettings.state.skip + this.skillsGridSettings.state.take),
		  total: this.skillCompositions.length,
		};
	}

	public skillsPageChange({ skip, take }: PageChangeEvent): void {
		this.skillsGridSettings.state.skip = skip;
		this.skillsGridSettings.state.take = take;
		this.loadSkillItems();
	}
}