import { UploadFileData } from "../../../../models/attachment/upload-file-data";

export class CompleteActivityRequiredAttachmentData {

    requestId: number;
    activityId: number;
    attachmentTypeId: number;
    attachmentTypeName: string;

    attachments: UploadFileData[] = [];
}