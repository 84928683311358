import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { CustomerContact } from '../../models/request/customer-contact';
import { Request } from '../../models/request/request';
import { RequestCustomerContactService } from '../../services/request-customer-contacts.service';
import { RequestsService } from '../../services/requests.service';
import { NotificationService } from '../../../core/services/notification.service';
import { RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { SecurityService } from '../../../core/services/security.service';
import { UsersService } from '../../../admin/users/users.service';
import { NotificationType } from '../../../core/services/notification-type';

@Component({
	selector: 'lanter-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

	@Input() entity: Request = null;
	@Input() activitiesGridHeight = 500;
	@Input() rowClass: any = null;
	@Input() activitySelectableSettings: SelectableSettings = { checkboxOnly: false, mode: "single" };
	entityId: number;

	customerContacts: CustomerContact[] = [];
	selectedCustomerContacts: number[] = [];
	selectedCustomerContact: CustomerContact = null;
	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

	constructor(
		protected dataService: RequestsService,
		private requestCustomerContactService: RequestCustomerContactService,
		private notificationService: NotificationService,
		private securityService: SecurityService,
		private usersService: UsersService) {

	}

	ngOnInit() {

		this.entityId = this.entity.requestId;

		if (this.entityId != null) {
			this.loadCustomerContacts(+this.entityId);
		}
	}

	public customerContactsRemoveHandler({ dataItem }: any) {
		const res = this.customerContacts;
		res.push((<CustomerContact>dataItem));
		this.viewCustomerContacts(res);
		this.notificationService.confirmation({
			title: 'Контакт',
			message: 'Вы собираетесь удалить контакт без возможности восстановления!',
			type: 'question',
			confirmButtonText: 'Удалить',
			cancelButtonText: 'Отмена',
			showCloseButton: true
		}, () => {
			this.requestCustomerContactService.remove(`${(<CustomerContact>dataItem).contactId}`, 'Контакт удален', '').subscribe(x => {

				if (x.isSuccessful) {
					this.loadCustomerContacts(this.entity.requestId);
					this.selectedCustomerContacts = [];
					this.selectedCustomerContact = null;
				}
			});
		});
	}


	public customerContactSaveHandler({ dataItem }: any) {

		const isNew = !!dataItem.contactId;

		const observable = isNew
			? this.requestCustomerContactService.update(dataItem, 'Контакт обновлен')
			: this.requestCustomerContactService.create(dataItem);

		observable.subscribe(x => {
			if (x.isSuccessful) {
				this.loadCustomerContacts(this.entity.requestId);
			} else {
				dataItem = null;
			}
		});
	}

	createNewCustomerContact(request: Request): any {
		return function () {
			const customerContact = new CustomerContact({
				requestId: request.requestId,
				contactPhone: null,
				contactEmail: null,
				contactName: null,
			});
			return customerContact;
		};
	}

	loadCustomerContacts(requestId: number) {
		this.dataService.getCustomerContacts(requestId).subscribe(res => {
			this.viewCustomerContacts(res);
		});
	}

	viewCustomerContacts(res) {
		this.customerContacts = res;
		if (this.customerContacts.length > 0) {
			const result = this.customerContacts;
			this.showCustomerContactById(result[0].contactId);
		}
	}

	public addContact() {
		this.entity.customerContacts.push(new CustomerContact());
	}

	showCustomerContactById(contactId: number) {

		this.selectedCustomerContacts = [];
		this.selectedCustomerContacts.push(contactId);
	}

	public get isModifiable() {
		return this.securityService.isAdmin() || this.securityService.canEditTspContacts();
	}

	public isCustomerContactSelected = (e: RowArgs) => this.selectedCustomerContacts.indexOf(e.dataItem.contactId) >= 0;

	public callToTsp(contact: CustomerContact) {
		this.usersService.callToTsp(contact).subscribe(x => {
			if (x.isSuccessful) {
				this.notificationService.success({
					title: '',
					message: `Устанавливается соединение... Ожидайте входящего звонка`,
					notificationType: NotificationType.SweetAlert
				});
			}
		});
	}

	get canMakingCallFromRequestTsp(): boolean {
		return this.securityService.canMakingCallFromRequestTsp()
	}
}
