import { Injectable } from '@angular/core';
import { UploadFile } from '../models/attachment/upload-file';
import Resumable from 'resumablejs';
import { Observable, forkJoin, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { concatMap } from 'rxjs/operators';
import { Guid } from 'guid-typescript';

@Injectable()
export class UploadInChunksService  {
  private controllerName = '';
	private controllerVersion = 1;

  private chunkSize = Math.round((1 * 1024 * 1024) / 5);
  imageExtensions: string[] = ['.png', '.jpg', '.jpeg'];

  constructor() {
    this.controllerName = 'attachments';
  }

  public getChunkSize(): any{
    return this.chunkSize;
  }

  initResumable(){
    var authUser = localStorage.getItem("authUser");
    let header = "";
    if (authUser) {
      header =`Bearer ${JSON.parse(authUser).bearerToken}`
    };
    return new Resumable({
      target: `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/upload-chunk`,
      headers: {
        Authorization: header,
      },
      query: {
      },
      chunkSize: this.chunkSize,
      simultaneousUploads: 3,
      maxChunkRetries: 5,
      chunkRetryInterval: 1000,
      testChunks: false
    });
  }

  uploadFiles(data: UploadFile[]): Observable<any> {
    if (data.length == 0){
      return new Observable((observer) => {
        observer.next();
        observer.complete();
      });
    }
    return of(data).pipe(
      concatMap((files) => {
        const observables = files.map((file) => {
          return new Observable((observer) => {
            let resumable = this.initResumable();
            file.resumableUid = Guid.create().toString();
            resumable.opts.query = {"resumableUid" : file.resumableUid, "fileUid" : file.uid};

            const blob = new Blob([file.fileData], { type: 'image/*' });
            const createdfile = new File([blob], file.fileName, { type: 'image/*' });
        
            resumable.addFile(createdfile);

            resumable.on('fileSuccess', (uploadedFile, result) => {
              let chunkSize = this.chunkSize;
              var resultObj = JSON.parse(result);
              observer.next({ file: uploadedFile, resultObj, chunkSize });
              observer.complete();
            });

            resumable.on("filesAdded", (f: any) => {
              resumable.upload();
            });

            resumable.on('fileError', (uploadedFile, message) => {
              observer.error({ file: uploadedFile, message });
            });
          });
        });

        return forkJoin(observables);
      })
    );
  }
}