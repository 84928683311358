import { SecurityService } from './../../core/services/security.service';
import { Component, Input, ChangeDetectionStrategy, AfterViewInit, HostBinding, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { LayoutService } from '../layout.service';
import { timer } from 'rxjs/internal/observable/timer';
import { Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-layout-sidenav',
	templateUrl: './layout-sidenav.component.html',
	styles: [':host { display: block; }'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSidenavComponent implements AfterViewInit {

	@Input() orientation = 'vertical';

	@HostBinding('class.layout-sidenav') private hostClassVertical = false;
	@HostBinding('class.layout-sidenav-horizontal') private hostClassHorizontal = false;
	@HostBinding('class.flex-grow-0') private hostClassFlex = false;

	constructor(private router: Router,
				private appService: AppService,
				private layoutService: LayoutService,
				private securityService: SecurityService
				) {
		// Set host classes
		this.hostClassVertical = this.orientation !== 'horizontal';
		this.hostClassHorizontal = !this.hostClassVertical;
		this.hostClassFlex = this.hostClassHorizontal;
	}

	ngAfterViewInit() {
		// Safari bugfix
		this.layoutService._redrawLayoutSidenav();
	}

	getClasses() {
		let bg = this.appService.layoutSidenavBg;

		if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
			bg = bg
				.replace(' sidenav-dark', '')
				.replace(' sidenav-light', '')
				.replace('-darker', '')
				.replace('-dark', '');
		}

		return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
	}

	isActive(url) {
		return this.router.isActive(url, true);
	}

	isMenuActive(url) {
		return this.router.isActive(url, false);
	}

	isMenuOpen(url) {
		return this.router.isActive(url, false) && this.orientation !== 'horizontal';
	}

	toggleSidenav() {
		this.layoutService.toggleCollapsed();
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	logout(){
		this.securityService.logout();
		this.router.navigateByUrl('login');
	}
	isVisible: boolean = false;
	open() {
		this.isVisible = !this.isVisible;
	}

	get isGeneralEquipmentReport(): boolean {
		return this.securityService.isSpecialRightClaimValid("GeneralEquipmentReport");
	}

	get catReportsReadCatalog(): boolean {
		return this.securityService.hasClaim('cat-reports-read-catalog');
	}
}
