import { FormItem } from "../../form-item";
import { SerialNumbersPart } from "./serial-numbers-part";

export class SerialNumbersFormPart extends FormItem {

	constructor(part: SerialNumbersPart) {
		super();

		this.part = part;

		this.isValidSerialNumber = false;
		this.inputValue = null;
		this.errorMessage = null;
	}

	part: SerialNumbersPart;
}
