import { Component, OnInit, ViewChild } from "@angular/core";
import { NotificationService } from "../../core/services/notification.service";
import { saveAs } from 'file-saver';
import { addDays } from '@progress/kendo-date-math';
import { KeyValueObject } from "../../shared/models/core/KeyValueObject";
import { NgForm } from "@angular/forms";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { LookupService } from "../../shared/services/lookup.service";
import { ListPageBaseLocal } from "../../shared/components/list-page-base/list-page-base-local";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { ColumnSettings } from "../../shared/models/grid/column-settings";
import { TidObjectsTimeQuery } from "../../shared/models/reports/tid-objects-time-query";
import { TidObjectsTimeDto } from "../../shared/models/tid-objects-time/tid-objects-time.dto";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { ContragentType } from "../../shared/enums";
import { ListPageBaseReport } from "../../shared/components/list-page-base/list-page-base-report";
import { AppService } from "../../app.service";
import { StatePersistingService } from "../../shared/services/state-persisting.service";
import { ListViewModel } from "../../shared/models/core/ListViewModel";
import { Observable } from "rxjs";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { ExportExcelFromGridService } from "../../shared/services/export-excel-from-grid.service";
import { ReportsService } from "../../shared/services/reports.service";

@Component({
	selector: 'tid-objects-time',
	templateUrl: './tid-objects-time.page.html',
	styleUrls: [
		'./tid-objects-time.page.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/ngx-contextmenu/ngx-contextmenu.scss'
	],
	animations: [
		trigger('smoothCollapse', [
			state('initial', style({
				height: '0',
				opacity: '0'
			})),
			state('final', style({
				opacity: '1'
			})),
			transition('initial=>final', animate('400ms')),
			transition('final=>initial', animate('400ms'))
		]),
	]
})
export class TidObjectsTime extends ListPageBaseReport<TidObjectsTimeDto> {
	
	onListPageInit(): void {
	}

	@ViewChild('entityForm') public entityForm: NgForm;

	public dataGenerating = false;

	contragents: KeyValueObject[] = [];

	excelFileName: string;

	isCollapsed: boolean = false;

	tidObjectsTimeQuery: TidObjectsTimeQuery = new TidObjectsTimeQuery();
	
	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	constructor(
		appService: AppService,
		protected exportExcelFromGridService: ExportExcelFromGridService,
		private notificationService: NotificationService,
		protected persistingService: StatePersistingService,
		private reportService: ReportsService,
		private lookupService: LookupService
	) {
		super(appService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Отчет о сроках на АО';
	}

	public gridSettings: any = {
		clearFilter: true,
		state: {
			skip: 0,
			take: 100,
			sort: [],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'tidNumber',
				title: 'TID',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'acquirerName',
				title: 'Банк-эквайер',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'customerContragentName',
				title: 'Клиент',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'agreementNumber',
				title: 'Номер договора с клиентом',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'merchantLegalName',
				title: 'ИНН, Юридическое наименование ТСП',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'legalAddress',
				title: 'Юр.адрес расположения ТСП',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'serviceCategoryName',
				title: 'Категория обслуживания',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'mid',
				title: 'MID',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'customerServiceDateStart',
				format: 'dd.MM.yyyy',
				title: 'Дата постановки на АО',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 9,
				field: 'customerServiceDateEnd',
				format: 'dd.MM.yyyy',
				title: 'Дата снятия с АО',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false
			},
            {
				orderIndex: 10,
				field: 'customerServiceDaysCount',
				title: 'Количество дней на АО',
				filter: 'numeric',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 11,
				field: 'customerObjectName',
				title: 'Фактическое наименование ТСП в локации',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 12,
				field: 'serviceCenterName',
				title: 'Исполнитель (подрядчик, СЦ)',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 13,
				field: 'customerObjectDistanceFromCityKm',
				title: 'Удаленность, км',
				filter: 'numeric',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 14,
				field: 'customerObjectAddress',
				title: 'Фактический адрес установки оборудования',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 15,
				field: 'actualPlace',
				title: 'Место установки оборудования',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 16,
				field: 'createdDate',
				format: 'dd.MM.yyyy HH:mm',
				title: 'Дата создания',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 17,
				field: 'createdUserDisplayName',
				title: 'Создал',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 18,
				field: 'updatedDate',
				format: 'dd.MM.yyyy HH:mm',
				title: 'Дата изменения',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 19,
				field: 'updatedUserDisplayName',
				title: 'Изменил',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
		]
	}

	get reportColumnSettings(): ColumnSettings[] {
		return this.gridSettings.columnsConfig.map((v) => {
			if (v.field === 'dateOfLastTransaction') {
				return <ColumnSettings>{
					field: 'dateOfLastTransactionString',
					filter: 'string',
					filterable: v.filterable,
					hidden: v.hidden,
					orderIndex: v.orderIndex,
					title: v.title,
					width: v.width
				}
			} else {
				return v;
			}
		});
	}

	processListItems(items: TidObjectsTimeDto[]): void {

	}

	ngOnInit(): void {
		var clientsState: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: ContragentType.bankEquer }] }
		};

		this.lookupService.getData("contragents", clientsState).subscribe(data => this.contragents = data);

		super.ngOnInit();
		this.excelFileName = this.getExcelFileName();
	}

	protected dataMethod(state: DataSourceRequestState): Observable<ListViewModel<TidObjectsTimeDto>> {
		return this.reportService.tidObjectsTime(state, this.tidObjectsTimeQuery);
	}

	get isGridHasData(): boolean {
		return this.listViewModel 
			&& this.listViewModel.data
			&& this.listViewModel.data.length > 0;
	}

	get isExportDisabled(): boolean {
		return !!!this.tidObjectsTimeQuery.customerServiceDaysStart
			|| !!!this.tidObjectsTimeQuery.customerServiceDaysEnd;
	}

	private getExcelFileName(): string {
		let dateTime = new Date();
		let date = dateTime.toLocaleDateString();
		let time = dateTime.toLocaleTimeString();
		let re = new RegExp(':', 'g');
		time = time.replace(re, "_");
		return `Отчет_о_сроках_на_АО_создан_${date} ${time}.xlsx`;
	}

	public gridDataloaded(): void {
		this.dataGenerating = false;
		this.isCollapsed = true;
	}

	generateReportTable() {
		this.dataGenerating = true;
		this.loadGridData();
	}

	public onRequestsReport(): void {
		this.dataGenerating = true;

		this.getXlsx('tid-objects-time-excel', this.getExcelFileName(), this.tidObjectsTimeQuery,
			(c) => {
				
			},
			() => {
				this.dataGenerating = false;
			});
	}
}
