export class Package {
    weight: number;
    width: number;
    length: number;
    height: number;

    constructor(weight: number
        , width: number
        , length: number
        , height: number) {
        this.weight = weight;
        this.width = width;
        this.length = length;
        this.height = height;
    }
}
