import {Component, Injector, OnInit, ViewEncapsulation, EventEmitter} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import { AppService } from "../../../app.service";
import { NotificationService } from "../../../core/services/notification.service";
import { ComponentPageBase } from "../../../shared/components/component-page-base/component-page-base";
import { Agreement } from "../../../shared/models/agreement/agreement";
import { Attachment, UploadFile } from "../../../shared/models/attachment";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { AgreementsService } from "../../../shared/services/agreements.service";
import { LookupService } from "../../../shared/services/lookup.service";
import { ContragentForm } from "../../contragents/contragent/contragent-form/contragent.form";
import { CategoryType } from "../../../shared/enums/category-type.enum";
import { forkJoin } from "rxjs";
import { ServiceCategoriesService } from '../../../shared/services/service-categories.service';
import { ServiceCategoryWithModeLookup } from '../../../shared/models/services/service-category-with-mode-lookup';
import { RowClassArgs } from "@progress/kendo-angular-grid";
import { Guid } from "guid-typescript";
import { ContragentsService } from '../../contragents/contragents.service';
import { ContragentType } from "../../../shared/enums";
import { AgreementDistanceCalculationType } from "../../../shared/models/agreement/agreement-distance-calculation-type";
import { DistanceCalculationType } from "../../../shared/enums/distance-calculation-type.enum";
import { AgreementServiceType } from "../../../shared/enums/agreement-service-type.enum";

@Component({
	selector: 'agreement-page',
	templateUrl: './agreement.page.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: [
		'./agreement.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class AgreementPage extends ComponentPageBase<Agreement> implements OnInit {
	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.agreements;
	headerContragentName = "Новый контрагент";
	showHeader: boolean = true;
	agreementId: string;
	initialContragentId: number;

	contragents: KeyValueObject[] = [];
	agreementStates: KeyValueObject[] = [];
	parentAgreements: KeyValueObject[] = [];
	agreementTypes: KeyValueObject[] = [];
	distanceCalculationTypes: KeyValueObject[] = [];
	agreementServiceTypes: KeyValueObject[] = [];
	tidServiceOnDismantleTypes: KeyValueObject[] = [];
	uploadFiles: UploadFile[] = [];
	services: KeyValueObject[] = [];
	serviceTypes: KeyValueObject[] = [];
	serviceModes: KeyValueObject[] = [];
	serviceModesForDropdown: KeyValueObject[] = [];
	serviceCategories: ServiceCategoryWithModeLookup[] = [];

	cities: KeyValueObject[] = [];

	afterSaveChangesOnModal = new EventEmitter<any>();

	slaCustomerDates = [
		{name: 'По дате регистрации во внутренней системе', value: false},
		{name: 'По дате регистрации в системе заказчика', value: true},
	];

	slaCustomerEndDates = [
		{name: 'По дате "Дата окончания работ"', value: false},
		{name: 'По дате "Дата фактического завершения работ (по талону)"', value: true},
	];

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: AgreementsService,
		private injector: Injector,
		private dialogService: DialogService,
		private lookupService: LookupService,
		private serviceCategoriesService: ServiceCategoriesService,
		private contragentsService: ContragentsService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/agreements';
		this.appService.pageTitle = 'Новый договор';
	}

	ngOnInit(): void {
		if (this.showHeader)
			this.entityId = this.route.snapshot.paramMap.get('agreementId');
		else
			this.entityId = this.agreementId;

		this.lookupService.getData('agreement-states', null).subscribe(data => this.agreementStates = data);
		this.lookupService.getData('agreement-types', null).subscribe(data => this.agreementTypes = data);
		this.lookupService.getData('agreement-service-types', null).subscribe(data => this.agreementServiceTypes = data);
		this.lookupService.getData('agreement-tid-service-on-dismantle-types', null).subscribe(data => this.tidServiceOnDismantleTypes = data);

		forkJoin([
				this.lookupService.getData('services', null),
				this.serviceCategoriesService.getAllWithModes(),
				this.lookupService.getData('service-types', null),
				this.lookupService.getData('service-modes', null),
				this.lookupService.getData('distance-calculation-types', null),
				this.lookupService.getData('cities', null),
			])
			.subscribe(results => {
				this.services = results[0];
				this.serviceCategories = results[1];
				this.serviceTypes = results[2];
				this.serviceModes = results[3];
				this.distanceCalculationTypes = results[4];
				this.cities = results[5];

				if (this.entityId != null) {

					this.dataService.getById(+this.entityId)
						.subscribe(vm => {
							this.entityName = `${vm.entity.number}`;

							vm.entity.agreementDate = new Date(vm.entity.agreementDate);
							if (vm.entity.endDate) {
								vm.entity.endDate = new Date(vm.entity.endDate);
							}

							this.dataBind(vm);

							this.setAvailableServiceModes();
						});

				} else {

					this.entityViewModel = new EntityViewModel<Agreement>();
					this.entityViewModel.entity = new Agreement();
					this.entityViewModel.entity.createdDate = new Date();
					this.entityViewModel.entity.slaCustomerDate = false;
					this.entityViewModel.entity.slaByFactEndDate = false;
					this.canEditForm = true;
					this.isNew = true;

					this.setAvailableServiceModes();

				}

				this.lookupService.getData('agreements', null).subscribe(data => {
					this.parentAgreements = this.entityId != null ? data.filter(x => x.id.toString() !== this.entityId) : data;

					this.parentAgreements.unshift({ id: null, name: '-', groupName: null, integraCode: null, disabled: false });
				});

				this.contragentsService.getByTypes([
					<number>ContragentType.bankEquer,
					<number>ContragentType.serviceCenter,
					<number>ContragentType.selfCompany,
				])
				.subscribe(result => {
					this.contragents = result.data;
					if (this.initialContragentId)
						this.entityViewModel.entity.contragentId = this.initialContragentId;
				});

			});

		this.rowClassServices = this.rowClassServices.bind(this);
	}

	public rowClassServices(context: RowClassArgs) {
		const agreeementServices = this.entityViewModel.entity.agreementServices
			.filter(x => x.serviceId === context.dataItem.serviceId && x.serviceCategoryId === context.dataItem.serviceCategoryId && x.serviceTypeId === context.dataItem.serviceTypeId);

		return {
			'cursor-pointer': true,
			'danger-row': agreeementServices.length > 1,
		};
	}

	closeModal(): void {
		const dialog = this.injector.get(DialogRef);
		dialog.close();
	}

	onChangeAgreementDate(date: Date): void {
		if (date.getFullYear() > 1000) {
			if (this.entityViewModel.entity.endDate && date > new Date(this.entityViewModel.entity.endDate)) {
				if (this.showHeader) {
					this.notificationService.confirmation({
						title: 'Не валидная дата соглашения',
						message: 'Дата соглашения не может быть позже чем дата окончания',
						confirmButtonText: 'Ok',
						showCancelButton: false,
					});
				} else {
					this.notificationService.info({
						title: 'Не валидная дата соглашения',
						message: 'Дата соглашения не может быть позже чем дата окончания',
					});
				}
			} else {
				this.entityViewModel.entity.agreementDate = date;
			}
		}

	}

	onChangeEndDate(date: Date): void {
		if (date.getFullYear() > 1000) {
			if (this.entityViewModel.entity.agreementDate && date < new Date(this.entityViewModel.entity.agreementDate)) {
				if (this.showHeader) {
					this.notificationService.confirmation({
						title: 'Не валидная дата окончания',
						message: 'Дата окончания не может быть меньше чем дата соглашения',
						confirmButtonText: 'Ok',
						showCancelButton: false,
					});
				} else {
					this.notificationService.info({
						title: 'Не валидная дата окончания',
						message: 'Дата окончания не может быть меньше чем дата соглашения',
					});
				}
			} else {
				this.entityViewModel.entity.endDate = date;
			}
		}
	}

	onChangeIntegraCode(event: any): void {
		const integraCode = event.target.value;
		if (integraCode) {
			var regex = new RegExp('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$');
			if (!regex.test(integraCode)) {

				this.notificationService.confirmation({
					title: 'Не валидный интегра код',
					message: 'Введён некорректный GUID',
					confirmButtonText: 'Ok',
					showCancelButton: false,
				});

			}
		}
	}

	onChangeDistanceCalculationType(distanceCalculationTypeId: number, dataItem: AgreementDistanceCalculationType): void {
		if (distanceCalculationTypeId !== <number>DistanceCalculationType.fromPerformerCityBorderWithBufferZone) {
			dataItem.distanceBufferZone = null;
		}
	}

	canEditRuleForTid(): boolean {
		var goodServiceType = this.entityViewModel.entity.agreementServiceTypeId == AgreementServiceType.abonent
			|| this.entityViewModel.entity.agreementServiceTypeId == AgreementServiceType.complex;
		var result = this.canEditForm && goodServiceType;
		if (!goodServiceType) {
			this.entityViewModel.entity.agreementTidServiceOnDismantleTypeId = null;
		}
		return result;
	}

	isValidDates(): boolean {
		if (!this.entityViewModel.entity.endDate && this.entityViewModel.entity.agreementDate) {
			return true;
		}

		return new Date(this.entityViewModel.entity.agreementDate) < new Date(this.entityViewModel.entity.endDate);
	}

	generateIntegraCode(): void {
		this.entityViewModel.entity.integraCode = Guid.create().toString();
	}

	saveFileHandler(uploadFiles: UploadFile[]) {
		this.uploadFiles = uploadFiles;
		this.saveHandler(uploadFiles);
	}

	saveChanges(): void {
		this.uploadFiles.map(fileData => {
			const attachment = {
				fileName: fileData.fileName,
				fileBase64: fileData.fileData
			} as Attachment;

			this.entityViewModel.entity.attachments.push(attachment);
		});

		super.saveChanges();
	}

	afterSaveChanges(): void {
		if (this.showHeader) {
			if (!this.isNew) {
				this.dataService.getById(this.entityViewModel.entity.agreementId)
					.subscribe(vm => {
						vm.entity.agreementDate = new Date(vm.entity.agreementDate);
						vm.entity.endDate = new Date(vm.entity.endDate);

						this.dataBind(vm);
					});
			}

			super.afterSaveChanges();
		} else {
			if (this.afterSaveChangesOnModal){
				this.afterSaveChangesOnModal.emit();
			}
			this.closeModal();
		}
	}

	showAddContragentObject(): void {
		this.showWindowContragent();
	}

	saveHandler(event: any): void {
		this.appService.MarkFormDirty(this.entityForm);
	}

	removeHandler(event: any): void {
		this.appService.MarkFormDirty(this.entityForm);
	}

	agreementServiceRemoveHandler(event: any): void {
		if (event.dataItem.hasRequestServices) {
			const agreementServices = [...this.entityViewModel.entity.agreementServices];
			this.notificationService.confirmation({
				title: 'Удаление невозможно',
				message: 'Привязка не может быть удалена, так как использовалась в заявках.',
				confirmButtonText: 'Ok',
				showCancelButton: false,
			},
			() => this.entityViewModel.entity.agreementServices = agreementServices);
		} else {
			this.appService.MarkFormDirty(this.entityForm);
		}
	}

	hasDuplicateServices(): boolean {
		const agreementServices = this.entityViewModel.entity.agreementServices;
		return agreementServices
			.some((agreementService => agreementServices.filter(x => x.serviceId === agreementService.serviceId &&
				x.serviceCategoryId === agreementService.serviceCategoryId &&
				x.serviceTypeId === agreementService.serviceTypeId).length > 1) as any);
	}

	private showWindowContragent() {

		const dialogRef = this.dialogService.open({ content: ContragentForm, width: '100%' });

		const contragentForm = <ContragentForm>dialogRef.content.instance;
		contragentForm.header = this.headerContragentName;
		contragentForm.showCancelButton = true;
		contragentForm.afterSaveChangesEvent.subscribe((value) => this.afterSaveChangesContragentChild(value));
	}

	private afterSaveChangesContragentChild(value: KeyValueObject) {
		this.contragents.push(value);
		this.entityViewModel.entity.contragentId = value.id;
		this.entityForm.controls["contragent"].markAsDirty();
	}

	setAvailableServiceModes(): void {
		if (this.entityViewModel.entity.agreementServices.length > 0) {
			const availableServiceModes = this.entityViewModel.entity.agreementServices
				.map(agreementService => {
					const serviceCategoryLookup =
						this.serviceCategories.filter(x => x.id === agreementService.serviceCategoryId)[0];
					return serviceCategoryLookup.serviceModeId;
				})
				.filter((val, index, arr) => arr.indexOf(val) === index);

			this.serviceModesForDropdown = this.serviceModes
				.filter(x => availableServiceModes.some((serviceModeId => serviceModeId === x.id) as any));
		} else {
			this.serviceModesForDropdown = [...this.serviceModes];
		}

		this.serviceModesForDropdown.unshift({ id: null, name: 'Для всех режимов', groupName: null, integraCode: null, disabled: false });
	}
}
