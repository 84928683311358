import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { ActionResult } from '../models/core/ActionResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { KeyValueObject } from '../models/core/KeyValueObject';
import { ServiceCenterEngineers } from '../models/engineers/service-center-engineers';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { tap } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { EngineerLookupModel } from '../models/engineers/engineer-lookup-model';
import { UpdateServiceCenterCustomCommand } from '../models/request/update-service-center-custom/update-service-center-custom-command';

@Injectable()
export class ServiceCentersService extends DataService<KeyValueObject, KeyValueObject> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'service-centers';
	}

	public serviceCentersCitiesLookup(): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/service-centers-cities-lookup`);
	}

	public engineersMultipleSc(model: ServiceCenterEngineers): Observable<ActionDataResult<EngineerLookupModel[]>> {
		return this.http.post<ActionDataResult<EngineerLookupModel[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/engineers-multiple-sc`, model)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка получения инженеров по сервисным центрам у выбранных заявок',
					message: resp.errorDescription,
					notificationType: NotificationType.Toast
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка получения инженеров по сервисным центрам у выбранных заявок',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.Toast
			});

		}));
	}

	public getServiceCenterByRegion(address: string, customerContragentId: number): Observable<number> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/service-centers-by-region?address=` + address + `&customerContragentId=` + customerContragentId);
	}

	public getEngineers(serviceCenterId: number, requestId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${serviceCenterId}/engineers?requestId=` + requestId);
	}

	public getWarehouses(serviceCenterId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${serviceCenterId}/warehouses`);
	}

	public updateServiceCenterCustom(model: UpdateServiceCenterCustomCommand): Observable<ActionResult> {
		return this.http.post<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/update-service-center-custom`, model)
			.pipe(tap(resp => {

				if (resp.isSuccessful) {
					this.notificationService.success({
						title: 'Обновления сервис-центра прошло успешно!',
						message: 'Изменения сохранены успешно!',
						notificationType: NotificationType.Toast
					});
				} else {
					this.notificationService.error({
						title: 'Ошибка при изменении полей сервис-центра',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при изменении полей сервис-центра',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}));
	}
}
