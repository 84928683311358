import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { AgreementListItem } from '../models/agreement/agreement-list-item';
import { Agreement } from '../models/agreement/agreement';
import { ServiceAgreementInfo } from '../models/services/service-agreement-info';
import { KeyValueDataObject } from '../models/core/KeyValueDataObject';

@Injectable()
export class AgreementsService extends DataService<AgreementListItem, Agreement> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'agreements';
	}

	public getServices(agreementId: number, requestTypeId: number): Observable<KeyValueDataObject<ServiceAgreementInfo[]>[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${agreementId}/services?requestTypeId=${requestTypeId}`);
	}

	public getCategories(agreementId: number, requestTypeId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${agreementId}/categories?requestTypeId=${requestTypeId}`);
	}

	public getServiceTypes(agreementId: number, requestTypeId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${agreementId}/service-types?requestTypeId=${requestTypeId}`);
	}

	public getTidSingle(agreementId: number): Observable<KeyValueObject> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${agreementId}/tid-single`);
	}

	public getRequestTypes(agreementId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${agreementId}/request-types`);
	}
}
