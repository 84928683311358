export class CreateUpdateActivityMultiple {
    requestId: number;
    engineerId: number;
    isChangeActivity: boolean;
    planStartDate: Date;

    constructor(requestId: number, engineerId: number, isChangeActivity: boolean, planStartDate: Date) {
        this.requestId = requestId;
        this.engineerId = engineerId;
        this.isChangeActivity = isChangeActivity;
        this.planStartDate = planStartDate;
    }
}