import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { KeyValueObject } from "../../../models/core/KeyValueObject";

@Component({
	selector: 'edit-tids-card',
	templateUrl: './edit-tids.component.html',
	styleUrls: []
})
export class EditTidsComponent implements OnInit {

	@Input() tids: KeyValueObject[] = [];

	@Output() save = new EventEmitter<any>();
	@Output() cancel = new EventEmitter<any>();

	tidsForChange: KeyValueObject[] = [];

	constructor(
		private injector: Injector,
	) {

	}

	ngOnInit(): void {
		this.tidsForChange = this.tids.map(tid => {
			const name = tid.name.split('|')[0].trim();
			return new KeyValueObject(tid.id, name);
		});
	}

	public onSave(): void {
		this.tids.map(tid => {
			const splittedTidWithAddress = tid.name.split('|');
			const editedTid = this.tidsForChange.filter(x => x.id === tid.id)[0];
			const tidAddress = splittedTidWithAddress.length > 1 ? ` | ${splittedTidWithAddress[1].trim()}` : '';
			tid.name = `${editedTid.name}${tidAddress}`;
		});

		if (this.save) {
			this.save.emit(this.tids);
		}

		if (this.save) {
			this.save.emit(this.tids);
		}

		this.closeDialog();
	}

	public onCancel(): void {
		if (this.cancel) {
			this.cancel.emit(this.tids);
		}

		this.closeDialog();
	}

	private closeDialog(): void {
		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}

}
