import { IFreeNomenclature } from "../../interfaces";

export class RequestDeviceWarehouseRefillDto {
    requestDeviceWarehouseRefillId: number;
    requestId: number;
    deviceTypeId: number;
    deviceTypeName: string;
    requestDeviceId: number;

    serialNumber: string;
    requestSerialNumber: string;
    articleNumber: string;
    selectedArticleNumber: string;

    requestManufacturer: string;
    manufacturer: string;

    requestModel: string;
    model: string;

    deviceConnectionTypeNameFromArticleMask: string;

    deviceConnectionTypeId: number;
    deviceModelId: number;
    deviceSupplierId: number;
    isPinPadRequired: boolean;
    deviceId?: number;

    quantity: number;
    quantityReal: number;
    quantityAvailable: number;

    isArticleMappedCorrect: boolean;

	  description: string;
    hasAcceptedIn1C: boolean;
    isDevicePart: boolean;

    deviceStatusId: number;
    deviceStatusName: string;
    itemID: number;

    recipientWarehouseCellNumber: string;
    senderWarehouseCellNumber: string;
    comment: string;
    commentObject: any;
    descriptionOfFailure: string;
    existsInErp: boolean;
    checkedBy1CApi: boolean;
    articleId: number;

    isMkInitType: boolean;
    deviceServiceTypeName: string;
    deviceServiceTypeId?: number;
    nomenclature: string;

    selectedNomenclature: IFreeNomenclature;
    nomenclatures: IFreeNomenclature[] = [];
}




// public int RequestDeviceWarehouseRefillId { get; set; }

// public int RequestId { get; set; }
// public Request Request { get; set; }

// public int DeviceTypeId { get; set; }
// public DeviceType DeviceType { get; set; }

// public string SerialNumber { get; set; }
// public string ArticleNumber { get; set; }
// public string Manufacturer { get; set; }
// public string Model { get; set; }

// public int? DeviceModelId { get; set; }
// public DeviceModel DeviceModel { get; set; }

// public int? DeviceSupplierId { get; set; }
// public DeviceModel DeviceSupplier { get; set; }

// public int? DeviceId { get; set; }
// public Device.Device Device { get; set; }

// public int? DeviceConfigurationId { get; set; }
// public DeviceConfiguration DeviceConfiguration { get; set; }

// public bool? HasAcceptedIn1C { get; set; }
