export class ServiceModeSchedule {

	constructor(mask: string = null) {
		if (mask) {
			const maskValues = mask.split('|');

			this.mondayIncluded = this.isIncludedDay(maskValues[0]);
			this.tuesdayIncluded = this.isIncludedDay(maskValues[1]);
			this.wednesdayIncluded = this.isIncludedDay(maskValues[2]);
			this.thursdayIncluded = this.isIncludedDay(maskValues[3]);
			this.fridayIncluded = this.isIncludedDay(maskValues[4]);
			this.saturdayIncluded = this.isIncludedDay(maskValues[5]);
			this.sundayIncluded = this.isIncludedDay(maskValues[6]);
		}
	}

	mondayIncluded: boolean = false;
	tuesdayIncluded: boolean = false;
	wednesdayIncluded: boolean = false;
	thursdayIncluded: boolean = false;
	fridayIncluded: boolean = false;
	saturdayIncluded: boolean = false;
	sundayIncluded: boolean = false;

	public getMask(): string {

		const mondayMask = this.getMaskValue(this.mondayIncluded);
		const tuesdayMask = this.getMaskValue(this.tuesdayIncluded);
		const wednesdayMask = this.getMaskValue(this.wednesdayIncluded);
		const thursdayMask = this.getMaskValue(this.thursdayIncluded);
		const fridayMask = this.getMaskValue(this.fridayIncluded);
		const saturdayMask = this.getMaskValue(this.saturdayIncluded);
		const sundayMask = this.getMaskValue(this.sundayIncluded);

		return `${mondayMask}|${tuesdayMask}|${wednesdayMask}|${thursdayMask}|${fridayMask}|${saturdayMask}|${sundayMask}`;
	}

	public getTotalDaysInSchedule(): number {
		const monday = this.mondayIncluded ? 1 : 0;
		const tuesday = this.tuesdayIncluded ? 1 : 0;
		const wednesday = this.wednesdayIncluded ? 1 : 0;
		const thursday = this.thursdayIncluded ? 1 : 0;
		const friday = this.fridayIncluded ? 1 : 0;
		const saturday = this.saturdayIncluded ? 1 : 0;
		const sunday = this.sundayIncluded ? 1 : 0;

		return monday + tuesday + wednesday + thursday + friday + saturday + sunday;
	}

	private getMaskValue(dayIncluded: boolean) {
		return dayIncluded ? '1' : '0';
	}

	private isIncludedDay(maskValue: string): boolean {
		return maskValue === '1';
	}

}
