import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ActivityStatusEnum, DeviceStatus, RequestInitTypeEnum, RequestType } from '../../../enums';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { NewRequestDevice } from '../../../models/request/new-request/new-request-device';
import { WarehousesService } from '../../../services/warehouses.service';
import { WarehouseWithCells } from '../../../models/warehouses/warehouse-with-cells';
import { RequestsService } from '../../../services/requests.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { RequestUninstallDevicesService } from '../../../services/request-uninstall-devices.service';
import { DevicesService } from '../../../services/devices.service';
import { WarehouseCellType } from '../../../enums/warehouse-cell-type.enum';
import { UpdateRequestCustomCommand } from '../../../models/update-request-custom/update-request-custom.command';
import { forkJoin, Observable } from 'rxjs';
import { DeviceServiceType } from '../../../enums/device-service-type.enum';
import { RequestDevicesService } from '../../../services/request-devices.service';
import { ReserveResult } from '../../../enums/reserve-result.enum';
import { LookupService } from '../../../services/lookup.service';
import { SendStateWithLogic } from '../../../models/request/send-state-with-logic';
import { DeviceKindEnum } from '../../../enums/device-kind.enum';
import { SetSnComponents } from '../set-sn-components/set-sn-components';
import { DeviceConditionsService } from '../../../services/device-conditions.service';

@Component({
	selector: 'set-sn-receive',
	templateUrl: './set-sn-receive.component.html',
	styleUrls: ['./set-sn-receive.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class SetSnReceive implements OnInit {

	@ViewChild('setSnComponents') public setSnComponents: SetSnComponents;

	@Input()
	public requestId: number;

	@Input()
	recipientWarehouseId: number;

	@Input()
	serviceCenterId: number;

	@Input()
	activityStatusFromId: number;

	@Input()
	public requestTypeId: number;

	@Input()
	public initTypeId: number;

	@Input()
	showComponentsArticles: boolean = false;

	@Input()
	contragentId: number;

	@Output()
	onContinueEvent = new EventEmitter();

	readOnlyComponentsDeviceStatusId: number = <number>DeviceStatus.toUninstall;
	selectedComponentsDeviceStatusId: number = <number>DeviceStatus.receivedAtWarehouse;
	returningBrokenDevicesReadOnlyComponentsDeviceStatusId: number = <number>DeviceStatus.shippedOnRequest;
	returningBrokenDevicesSelectedComponentsDeviceStatusId: number = <number>DeviceStatus.receivedAtWarehouse;

	get cardHeight(): number {
		return window.innerHeight - 325;
	}

	formWarehouses: KeyValueObject[] = [];
	recipientWarehouseAreas: KeyValueObject[] = [];
	recipientWarehouseCells: KeyValueObject[] = [];
	warehousesWithCells: WarehouseWithCells[] = [];
	uninstalledDevices: NewRequestDevice[] = [];
	recipientWarehouseCellId: number;
	recipientWarehouseAreaId: number;
	deviceConditions: KeyValueObject[] = [];

	public validationMessage: string;

	public dataSaving = false;

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	constructor(public dialog: DialogRef,
		private warehousesService: WarehousesService,
		private uninstallDevicesService: RequestUninstallDevicesService,
		private requestDevicesService: RequestDevicesService,
		private devicesService: DevicesService,
		private requestsService: RequestsService,
		private deviceConditionsService: DeviceConditionsService) {
	}

	get returningBrokenDevices(): boolean {
		return (!this.isMkInitType && !!this.activityStatusFromId && [<number>ActivityStatusEnum.awaitingReturnToWarehouse, <number>ActivityStatusEnum.awaitingWarehouseAcceptance].includes(this.activityStatusFromId))
		
			|| (this.isMkInitType && !!this.activityStatusFromId && this.activityStatusFromId === <number>ActivityStatusEnum.awaitingReturnToWarehouse)

			|| (this.isMkInitType 
					&& this.requestTypeId === <number>RequestType.remoteInstall
					&& !!this.activityStatusFromId
					&& this.activityStatusFromId === <number>ActivityStatusEnum.returnOfNotWorkingEquipment);
	}

	get isMkInitType(): boolean {
		return this.initTypeId === <number>RequestInitTypeEnum.blockChain;
	}

	get showDeviceConditions(): boolean {
		return !this.isMkInitType && this.returningBrokenDevices;
	}

	isPart(device: NewRequestDevice): boolean {
		if (!!device.deviceKindId){
			return device.deviceKindId  === <number>DeviceKindEnum.component;
		}

		return false;
	}

	public ngOnInit(): void {

		if (this.returningBrokenDevices) {
			if (!this.isMkInitType) {
				this.deviceConditionsService.getConditionsByContragent(this.contragentId).subscribe(data => {
					this.deviceConditions = data.map(x => new KeyValueObject(x.deviceConditionId, x.name))
				});
			}

			this.requestsService.getRequestDevices(this.requestId).subscribe(res => {
				if (this.isMkInitType) {
					this.uninstalledDevices = res.filter(f => !!f.reserveSuccess);
				} else {
					this.uninstalledDevices = res.filter(f => !!f.issuedSuccess && !!!f.isWrittenOff
						&& ![ReserveResult.reserveCanceled
							, ReserveResult.requestForReserveCancelling
							, ReserveResult.returnedToWarehouse
							, ReserveResult.awaitingReturnToWarehouse
							, ReserveResult.notFound
							, ReserveResult.notBooked].includes(f.reserveResultId));
				}

				this.setKtoHeader(this.uninstalledDevices);
			});

		} else {
			switch (this.requestTypeId) {
				case <number>RequestType.remoteInstall:
					this.requestsService.getRequestDevices(this.requestId).subscribe(res => {
						if (this.isMkInitType) {
							this.uninstalledDevices = res.filter(f => !!f.reserveSuccess);
						}
		
						this.setKtoHeader(this.uninstalledDevices);
					});
					break;
				default:
					this.requestsService.getRequestUninstallDevices(this.requestId).subscribe(res => {
						switch (this.requestTypeId) {
							case <number>RequestType.expertise:
								this.uninstalledDevices = res.filter(f => f.deviceStatusId === <number>DeviceStatus.diagnostics);
								break;
							default:
								this.uninstalledDevices = res.filter(f => f.deviceStatusId === <number>DeviceStatus.deInstalled);
								break;
						}
		
						this.setKtoHeader(this.uninstalledDevices);
					});
					break;
			}
		}

		this.warehousesService.getAllWarehousesWithCellsFiltered(this.serviceCenterId, <number>WarehouseCellType.storage, this.isMkInitType)
			.subscribe(result => {
				this.warehousesWithCells = result.data;
				this.formWarehouses = this.warehousesWithCells
					.map(warehouse => new KeyValueObject(warehouse.warehouseId, warehouse.name));

				if (!!this.recipientWarehouseId) {
					this.recipientWarehouseChange(this.recipientWarehouseId);
					this.recipientWarehouseAreaChange(this.recipientWarehouseAreaId);
				}
			});
	}

	getDeviceName(newRequestDevice: NewRequestDevice): string {
		var values = [newRequestDevice.deviceTypeName
			, newRequestDevice.deviceSupplierName
			, newRequestDevice.deviceModelName];
		return values.filter(x => x).join(' ');
	}

	isVipDevice(m: NewRequestDevice): boolean {
		return m.deviceServiceTypeId === <number>DeviceServiceType.KTO_MSP;
	}

	setKtoHeader(m: NewRequestDevice[]) {
		let soId = 0;
		m.forEach(f => {
			let deviceServiceTypeId = f.deviceServiceTypeId === null ? <number>DeviceServiceType.SINGLE_REQUEST : f.deviceServiceTypeId;

			if (deviceServiceTypeId !== soId) {
				soId = deviceServiceTypeId;
				f.deviceServiceTypeNameHeader = f.deviceServiceTypeName;
			}
		});
	}

	deviceServiceTypeNameHeaderExists(m: NewRequestDevice): boolean {
		return !!m.deviceServiceTypeNameHeader;
	}

	public saveChanges() {
		if (!this.cantProceed) {

			this.dataSaving = true;

			let voidObservables: Observable<any>[] = [];
			let deviceObservables: Observable<any>[] = [];

			switch (this.requestTypeId) {
				case <number>RequestType.remoteInstall:
					this.uninstalledDevices.forEach(uninstalledDevice => {
						uninstalledDevice.returnWarehouseCellId = this.recipientWarehouseCellId;
						uninstalledDevice.reserveResultId = <number>ReserveResult.awaitingReturnToWarehouse;
	
						voidObservables.push(this.requestDevicesService.update(uninstalledDevice, "Ячейка для оборудования проставлена", false, true));
	
						if (uninstalledDevice.deviceId !== null) {
							deviceObservables.push(this.devicesService.getById(uninstalledDevice.deviceId));
						}
					});
	
					let updateRequestCustomCommand = new UpdateRequestCustomCommand(this.requestId);
					updateRequestCustomCommand.recipientWarehouseAreaId = this.recipientWarehouseAreaId;
					updateRequestCustomCommand.updateRecipientWarehouseAreaId = true;
	
					voidObservables.push(this.requestsService.updateRequestCustom(updateRequestCustomCommand));
					break;
				default:
					if (!this.returningBrokenDevices) {
						this.uninstalledDevices.forEach(uninstalledDevice => {
							uninstalledDevice.warehouseCellId = this.recipientWarehouseCellId;
							uninstalledDevice.deviceStatusId = <number>DeviceStatus.receivedAtWarehouse;
		
							voidObservables.push(this.uninstallDevicesService.update(uninstalledDevice, "Ячейка для оборудования проставлена", false, true));
		
							if (uninstalledDevice.deviceId !== null) {
								deviceObservables.push(this.devicesService.getById(uninstalledDevice.deviceId));
							}
						});
		
						let updateRequestCustomCommand = new UpdateRequestCustomCommand(this.requestId);
						updateRequestCustomCommand.recipientWarehouseAreaId = this.recipientWarehouseAreaId;
						updateRequestCustomCommand.updateRecipientWarehouseAreaId = true;
		
						voidObservables.push(this.requestsService.updateRequestCustom(updateRequestCustomCommand));
					} else {
						this.uninstalledDevices.forEach(uninstalledDevice => {
							uninstalledDevice.returnWarehouseCellId = this.recipientWarehouseCellId;
							uninstalledDevice.reserveResultId = <number>ReserveResult.awaitingReturnToWarehouse;
		
							voidObservables.push(this.requestDevicesService.update(uninstalledDevice, "Ячейка для оборудования проставлена", false, true));
		
							if (uninstalledDevice.deviceId !== null) {
								deviceObservables.push(this.devicesService.getById(uninstalledDevice.deviceId));
							}
						});
		
						let updateRequestCustomCommand = new UpdateRequestCustomCommand(this.requestId);
						updateRequestCustomCommand.recipientWarehouseAreaId = this.recipientWarehouseAreaId;
						updateRequestCustomCommand.updateRecipientWarehouseAreaId = true;
		
						voidObservables.push(this.requestsService.updateRequestCustom(updateRequestCustomCommand));
					}
					break;
			}

			if (deviceObservables.length > 0) {
				forkJoin(deviceObservables)
					.subscribe(results => {
						results.forEach(res => {
							if (res.entity !== null) {
								res.entity.deviceStatusId = <number>DeviceStatus.receivedAtWarehouse;
								voidObservables.push(this.devicesService.update(res.entity, "Статус оборудования обновлен", false, true));
							}
						});

						forkJoin(voidObservables)
							.subscribe(() => {
								switch (this.requestTypeId) {
									case <number>RequestType.remoteInstall:
										this.requestsService.sendStateWithLogic(
											new SendStateWithLogic(this.requestId, "storeEquipAccepted_fromUi", this.returningBrokenDevices, !!this.setSnComponents ? this.setSnComponents.selectedComponentsArticles : null))
												.subscribe(() => {
													this.cancel();
													this.dataSaving = false;
										});
										break;
								
									default:
										if (this.onContinueEvent) {
											this.onContinueEvent.emit(!!this.setSnComponents ? this.setSnComponents.selectedComponentsArticles : null);
										}
										break;
								}
								
								this.dataSaving = false;
								this.dialog.close();
							});
					});
			} else {
				forkJoin(voidObservables)
					.subscribe(() => {
						switch (this.requestTypeId) {
							case <number>RequestType.remoteInstall:
								this.requestsService.sendStateWithLogic(
									new SendStateWithLogic(this.requestId, "storeEquipAccepted_fromUi", this.returningBrokenDevices, !!this.setSnComponents ? this.setSnComponents.selectedComponentsArticles : null))
										.subscribe(() => {
											this.cancel();
											this.dataSaving = false;
								});
								break;
						
							default:
								if (this.onContinueEvent) {
									this.onContinueEvent.emit(!!this.setSnComponents ? this.setSnComponents.selectedComponentsArticles : null);
								}
								break;
						}
						this.dataSaving = false;
						this.dialog.close();
					});
			}
		}
	}

	public cancel() {
		this.dialog.close();
	}

	public recipientWarehouseChange(warehouseId: any): void {
		this.recipientWarehouseAreas = [];
		this.recipientWarehouseAreas = this.getWarehoseAreas(warehouseId);
		this.recipientWarehouseAreaChange(this.recipientWarehouseAreaId);
	}

	recipientWarehouseAreaChange(warehouseAreaId: any): void {
		this.recipientWarehouseCells = [];
		this.recipientWarehouseCells = this.getWarehoseAreaCells(warehouseAreaId);
	}

	public getWarehouseCells(warehouseId: number): KeyValueObject[] {
		this.recipientWarehouseCellId = null;
		const warehouses = this.warehousesWithCells.filter(x => x.warehouseId === warehouseId);
		if (warehouses.length > 0) {
			const warehouse = warehouses[0];
			const warehouseCells = warehouse.warehouseСells.map(cell => new KeyValueObject(cell.warehouseCellId, cell.cellCode));
			if (warehouseCells.length === 1) {
				this.recipientWarehouseCellId = warehouseCells[0].id
			}

			//warehouseCells.unshift(new KeyValueObject(null, "Не выбрано"));

			return warehouseCells;
		}

		return [];
	}

	getWarehoseAreaCells(warehouseAreaId: number): KeyValueObject[] {
		this.recipientWarehouseCellId = null;
		const warehouseAreas = this.warehousesWithCells.map(p => p.warehouseAreas)
			.reduce((a, b) => a.concat(b), [])
			.filter(flt => flt.warehouseAreaId === warehouseAreaId);
		
		if (warehouseAreas.length > 0) {
			const warehouseArea = warehouseAreas[0];
			const warehouseAreaCells = warehouseArea.warehouseAreaCells.map(cell => new KeyValueObject(cell.warehouseCellId, cell.cellCode));
			if (warehouseAreas.length === 1) {
				this.recipientWarehouseCellId = warehouseAreaCells[0].id
			}

			return warehouseAreaCells;
		}

		return [];
	}

	getWarehoseAreas(warehouseId: number): KeyValueObject[] {
		this.recipientWarehouseAreaId = null;
		const warehouses = this.warehousesWithCells.filter(x => x.warehouseId === warehouseId);
		if (warehouses.length > 0) {
			const warehouse = warehouses[0];
			const warehouseAreas = warehouse.warehouseAreas.map(area => new KeyValueObject(area.warehouseAreaId, area.name));
			if (warehouseAreas.length === 1) {
				this.recipientWarehouseAreaId = warehouseAreas[0].id
			}

			return warehouseAreas;
		}

		return [];
	}

	onKeyserialNumber(event: any, newRequestDevice: NewRequestDevice): void {
		newRequestDevice.isInputSnError = false;
	}

	onFocusOut(newRequestDevice: NewRequestDevice, event: any, i: number): void {
		let uninstalledDevice = this.uninstalledDevices.filter(f => f.requestDeviceId === newRequestDevice.requestDeviceId
			&& f.serialNumber === newRequestDevice.inputSerialNumber);

		newRequestDevice.isInputSnError = uninstalledDevice === null || uninstalledDevice.length === 0;
	}

	get cantProceed(): boolean {
		return this.uninstalledDevices.length === 0 || this.uninstalledDevices.some(e => e.isInputSnError);
	}
}
