import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../core/services/notification.service";
import { ReportsService } from "../../shared/services/reports.service";
import { ReportPageBase } from "../components/report-page-base";
import { saveAs } from "file-saver";
import { NotificationType } from "../../core/services/notification-type";
import { KeyValueObject } from "../../shared/models/core/KeyValueObject";
import { RegionsService } from "../../shared/services/regions.service";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { AppService } from "../../app.service";

@Component({
	selector: 'echange-smz-report-page',
	templateUrl: './exchange-smz-report.page.html',
	styleUrls: [
		'./exchange-smz-report.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ExchangeSmzReportPage extends ReportPageBase implements OnInit {
    regions: KeyValueObject[] = [];
    regionId: number;

    constructor (
		private reportsService: ReportsService,
        private regionsService: RegionsService,
		private notificationService: NotificationService,
        private appService: AppService
    ) {
        super();
        appService.pageTitle = 'Отчет по заявкам с биржи СМЗ';
    }

    public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	ngOnInit(): void {
        this.regionsService.regionsSingleLookup().subscribe(data => this.regions = data);
	}

	generateReport() {
        this.generateReportByDateRange((startDate, endDate) => {
            this.reportsService.getExchangeSmzReport(startDate, endDate, this.regionId)
                .subscribe(x => {
                    console.log(x);
                    if (x.isSuccessful) {
                        const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${x.data}`;
                        const localDate = new Date().toLocaleString().replace(',', '');
                        saveAs(dataURI, `Отчет_по_заявкам_с_биржи_СМЗ_создан_${localDate}.xlsx`);
                    } else {
                        this.notificationService.error({
                            title: 'Ошибка при построении отчета',
                            message: x.errorDescription,
                            notificationType: NotificationType.Toast
                        });
                    }
                    this.dataGenerating = false;
                });
        });
	}
}
