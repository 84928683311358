import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { WarehouseWorkplaceRequestsListPage } from './list/list.page';
import { EquipmentMoveMode } from './equipment-move-mode/equipment-move-mode';
import { EquipmentSearchMode } from './equipment-search-mode/equipment-search-mode';
import { SearchBySNList } from './search-by-sn-list/search-by-sn-list';

//import { NotificationButtonComponent } from "../requests/request-components/request-notification-button/notification-button.component";

export const warehouseWorkplaceRequestsRoutes: Routes = [
	{ path: '', component: WarehouseWorkplaceRequestsListPage, canActivate: [AuthGuard] },
];

@NgModule({
	imports: [],
	exports: [RouterModule]
})
export class WarehouseWorkplaceRequestsRoutingModule {
	public static components = [
		WarehouseWorkplaceRequestsListPage,
		SearchBySNList,
		EquipmentMoveMode,
		EquipmentSearchMode
	];
}