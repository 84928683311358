import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { IntlService } from '@progress/kendo-angular-intl';
import { Router } from '@angular/router';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { KeyValueObject } from './shared/models/core/KeyValueObject';
import { addDays, isEqualDate } from '@progress/kendo-date-math';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class AppService {
	//private navigationEventsSubscription: Subscription;

	// последний переход произошел с использованием кнопки Back
	private _isBackNavButtonPressed: boolean;

	public clearAllGridFilters = new Subject();

	get IsBackNavButtonPressed(): boolean {
		return this._isBackNavButtonPressed;
	}

	constructor(
		private titleService: Title,
		private intl: IntlService,
		private router: Router) {

		// this.navigationEventsSubscription = this.router.events
		// 	.subscribe(event => {
		// 		if (event instanceof NavigationStart) {
		// 			var navStartEvent = <NavigationStart>event;

		// 			// была нажата кнопка Back
		// 			if (navStartEvent.navigationTrigger === "popstate") {
		// 				this._isBackNavButtonPressed = true;
		// 			}
		// 			else {
		// 				this._isBackNavButtonPressed = false;
		// 			}
		// 		}
		// 	});

		// отписываться не будем, но можем
		//this.navigationEventsSubscription.unsubscribe();
	}

	// была удалена запись, после чего должен был произойти переход по маршруту RouteAfterEntityDeleted
	public IsEntityDeleted: boolean;
	public RouteAfterEntityDeleted: string;

	get pageTitle(): string {
		return this.titleService.getTitle();
	}

	// Set page title
	set pageTitle(value: string) {

		var title = "Лантер.Сервис";

		if (!!value) {
			title = `${value} - ${title}`;
		}

		this.titleService.setTitle(title);
	}

	// Check for RTL layout
	get isRTL() {
		return document.documentElement.getAttribute('dir') === 'rtl' ||
			document.body.getAttribute('dir') === 'rtl';
	}

	// Check if IE10
	get isIE10() {
		return typeof document['documentMode'] === 'number' && document['documentMode'] === 10;
	}

	// Layout navbar color
	get layoutNavbarBg() {
		//return 'dark';
		return 'navbar-theme';
	}

	// Layout sidenav color
	get layoutSidenavBg() {
		return 'sidenav-theme';
		//return 'primary-dark sidenav-dark';
	}

	// Layout footer color
	get layoutFooterBg() {
		return 'footer-theme';
	}

	// Animate scrollTop
	scrollTop(to: number, duration: number, element = document.scrollingElement || document.documentElement) {
		if (element.scrollTop === to) { return; }
		const start = element.scrollTop;
		const change = to - start;
		const startDate = +new Date();

		// t = current time; b = start value; c = change in value; d = duration
		const easeInOutQuad = (t, b, c, d) => {
			t /= d / 2;
			if (t < 1) { return c / 2 * t * t + b; }
			t--;
			return -c / 2 * (t * (t - 2) - 1) + b;
		};

		const animateScroll = function () {
			const currentDate = +new Date();
			const currentTime = currentDate - startDate;
			element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
			if (currentTime < duration) {
				requestAnimationFrame(animateScroll);
			} else {
				element.scrollTop = to;
			}
		};

		animateScroll();
	}

	MarkFormPristine(form: NgForm): void {
		Object.keys(form.controls).forEach(control => {
			form.controls[control].markAsPristine();
		});
	}

	MarkFormDirty(form: NgForm): void {
		Object.keys(form.controls).forEach(control => {
			form.controls[control].markAsDirty();
		});
	}

	parseDate(date: Date): Date {
		return new Date(this.intl.formatDate(date, 'yyyy-MM-dd'));
	}

	parseDateTime(date: Date): Date {
		return new Date(this.intl.formatDate(date, 'yyyy-MM-ddTHH:mm:ss'));
	}


	convertTimeFromString(timeStr: string): Date {
		var timeParts = timeStr.split(":");

		var dtNow = new Date();
		dtNow.setHours(+timeParts[0], +timeParts[1], 0);

		return dtNow;
	}

	convertTimeToString(date: Date): string {
		return this.intl.formatDate(date, 'HH:mm');
	}

	getDateCellText(value: Date, camelCaseCapital: boolean = false): string {

		if (!value)
			return '';

		const today = new Date();
		const yesterday = addDays(today, -1);
		const dayBeforeYesterday = addDays(today, -2);

		value = this.parseDateTime(value);
		const time = this.intl.formatDate(value, 'HH:mm');



		if (isEqualDate(today, value)) {
			return `${camelCaseCapital ? 'сегодня' : 'Сегодня' } в ${time}`;
		} else if (isEqualDate(yesterday, value)) {
			return `${camelCaseCapital ? 'вчера' : 'Вчера' } в ${time}`;
		} /*else if (isEqualDate(dayBeforeYesterday, value)) {
			return `Позавчера в ${time}`;
		}*/

		return this.intl.formatDate(value, 'dd.MM.yyyy HH:mm');
	}

	/* наименование компонента, для которого предназначен OuterFilter */
	public OuterFilterRoute: string = "";

	/* фильтр, устанавливаемый для компонента извне */
	public OuterFilter: DataSourceRequestState = null;

	getNameById(data: KeyValueObject[], id: number): string {
		const filtered = data.filter(x => x.id == id);

		if (filtered.length === 0) {
			return '';
		}

		return filtered[0].name;

	}

	get isMobileDevice(): boolean{
		var ua = navigator.userAgent;
		if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) return true;
		return false;
	}

	getGeoPosition(onSuccessCallback: (position: Position) => any){

		if (!window.navigator || !window.navigator.geolocation){
			alert('Доступ к геопозиции запрещен');
			return;
		}

		window.navigator.geolocation.getCurrentPosition(onSuccessCallback,
			error => {
				switch (error.code) {
					case 1:
						alert('Доступ к геопозиции запрещен');
						break;
					case 2:
						alert('Геопозиция недоступна');
						break;
				}
			}
		);

	}

}
