export enum RequestRegistrationFilterElementOperatorEnum {
    //Равно
    Equal = 1,
    //Не равно
    NotEqual = 2,
    //Больше
    More = 3,
    //Больше или равно
    MoreOrEqual = 4,
    //Меньше
    Less = 5,
    //Меньше или равно
    LessOrEqual = 6,
    //Входит в...
    IncludedIn = 7,
    //Не входит в...
    NotIncludedIn = 8,
}