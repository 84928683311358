import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ComponentPageBase } from "../../../shared/components/component-page-base/component-page-base";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { NotificationService } from "../../../core/services/notification.service";
import { LookupService } from "../../../shared/services/lookup.service";
import { DialogService } from "@progress/kendo-angular-dialog";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { ContragentType } from "../../../shared/enums";
import { StoppingReasonTypeRuleDto } from "../../../shared/models/stopping-reason-type-rules/stopping-reason-type-rule.dto";
import { StoppingReasonTypeRulesService } from "../../../shared/services/stopping-reason-type-rules.service";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
	selector: 'stopping-reason-type-rule',
	templateUrl: './stopping-reason-type-rule.page.html',
	styleUrls: [
		'./stopping-reason-type-rule.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class StoppingReasonTypeRulePage extends ComponentPageBase<StoppingReasonTypeRuleDto> implements OnInit {

	hasChanges(): boolean {
		this.entityForm.controls['performerGroupId'].markAsPristine();
		return super.hasChanges();
	}

    private stoppingReasonTypeRuleId: string;
	private ruleCopyMode: boolean;

	private allowedRetryTimespanFormValue: string;
	private stoppedForATimeFormValue: string;

    private stoppingReasonKinds: KeyValueObject[] = [];
    private requestTypes: KeyValueObject[] = [];
    private contragents: KeyValueObject[] = [];
    private customerStoppingRuleOptions: KeyValueObject[] = [];

    constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: StoppingReasonTypeRulesService,
		protected dialogService: DialogService,
        private lookupService: LookupService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/stopping-reason-type-rules';
		this.appService.pageTitle = 'Правило для типа остановки';
	}

    ngOnInit(): void {	
        this.stoppingReasonTypeRuleId = this.route.snapshot.paramMap.get('stoppingReasonTypeRuleId');
		this.ruleCopyMode = this.route.snapshot.url.filter(x => x.path == 'copy').length != 0;

		if (this.stoppingReasonTypeRuleId != null) {
			this.dataService.getById(+this.stoppingReasonTypeRuleId)
				.subscribe(vm => {
					if (this.ruleCopyMode) {
						vm.entity.stoppingReasonTypeRuleId = 0;
						this.canEditForm = true;
						this.isNew = true;		
					}
					else {
						this.entityId = this.stoppingReasonTypeRuleId;
						this.entityName = `№${vm.entity.stoppingReasonTypeRuleId}`;
					}

					this.stoppedForATimeFormValue = this.convertMinutes(vm.entity.stoppedForATime);
					this.allowedRetryTimespanFormValue = this.convertMinutes(vm.entity.allowedRetryTimespan);

					this.dataBind(vm);
				});
		}
		else {
			this.entityViewModel = new EntityViewModel<StoppingReasonTypeRuleDto>();
			this.entityViewModel.entity = new StoppingReasonTypeRuleDto();
			this.canEditForm = true;
			this.isNew = true;
		}

        this.initLookups();
    }

    private initLookups() {
        this.initContragentsLookup();
        this.dataService.getCustomerStoppingRuleOptions().subscribe(data => {
            this.customerStoppingRuleOptions = data;
        });
        this.lookupService.getData("stopping-reason-kinds", null).subscribe(data => {
            this.stoppingReasonKinds = data;
        });
        this.lookupService.getData("request-types", null).subscribe(data => {
            this.requestTypes = data;
        });
    }

    private initContragentsLookup() {
        var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: ContragentType.bankEquer }] }
		};
        this.lookupService.getData("contragents", state).subscribe(data => {
            this.contragents = data;
        });
    }

	beforeSaveChanges(): void {
		this.entityViewModel.entity.allowedRetryTimespan = this.parseTime(this.allowedRetryTimespanFormValue);
		this.entityViewModel.entity.stoppedForATime = this.parseTime(this.stoppedForATimeFormValue);
	}

	afterSaveChanges() {
		if (!this.entityId)
			return;

		this.router.navigate(['/categories/stopping-reason-type-rule/', this.entityId]);
	}

	private convertMinutes(value: number): string {
		if (value != null) {
			var hours = Math.floor(value / 60);
			var remainingMinutes = value % 60;
			
			var hoursString = hours.toString().padStart(2, '0');
			var minutesString = remainingMinutes.toString().padStart(2, '0');
			
			return hoursString + ':' + minutesString;
		}

		return null;
	}

	private parseTime(value: string): number {
		if (value != null && value.trim() != '') {
			let values = value.split(':');
			let minutes = +values[0] * 60 + +values[1];

			return minutes;
		}

		return null;
	}
}