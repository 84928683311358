export class Attachment {
	attachmentId: number;
	attachmentName: string;
	attachmentTypeName: string;
	AttachmentTypeNames: string[] = [];
	attachmentTypeId: number;
	attachmentTypeIds: number[] = [];
	attachmentExtension: string;
	attachmentSize: number;
	fileName: string;
	filePath: string;
	fileBase64: string;
	requestDeviceId: number;
	pdfNumberOfPages: number = 1;
	rotation: number = 0;
}