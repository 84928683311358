import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { RequestsService } from '../../services/requests.service';
import { RequestSlaStepDto } from '../../models/request/request-sla-step-dto';
import { State } from '@progress/kendo-data-query';

@Component({
	selector: 'request-sla-steps',
	templateUrl: './request-sla-steps.component.html',
	styleUrls: ['./request-sla-steps.component.scss']
})
export class RequestSlaStepsComponent implements OnInit {
	gridHeight: number;

	@Input() requestId: number;

	slaSteps: RequestSlaStepDto[] = [];

	public state: State = { sort: [{ field: 'startDateMoscow', dir: 'desc' }] };

	constructor(
		protected dataService: RequestsService) {

	}

	ngOnInit() {
		this.loadSlaSteps();
		this.gridHeight = window.innerHeight - 175;
	}

	loadSlaSteps(): void {
		this.dataService.getRequestSlaSteps(this.requestId).subscribe(res => {
			this.slaSteps = res;
		});
	}
}
