import { TransferDevice } from "./transfer-device";

export class Notify1CAboutWarehouseReceive {
    requestId: number;
    transferDevices: TransferDevice[] = [];

	constructor(requestId: number, transferDevices : TransferDevice[] = []) {
        this.requestId = requestId;
        this.transferDevices = transferDevices;
    }
}