import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SetSnModel } from './set-sn.model';
import { RequestsService } from '../../../services/requests.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { RequestType } from '../../../enums/request-type.enum';
import { RequestDeviceWarehouseRefillDto } from '../../../models/request/request-device-warehouse-refills';
import { DeviceStatus } from '../../../enums/device-status.enum';
import { Notify1CAboutWarehouseReceive } from '../../../models/request/notify-1c-about-warehouse-receive';
import { TransferDevice } from '../../../models/request/transfer-device';
import { SendStateWithLogic } from '../../../models/request/send-state-with-logic';
import { UsersService } from '../../../../admin/users/users.service';
import { NotificationType } from '../../../../core/services/notification-type';
import { GetSerialNumbersForTransferDto } from '../../../models/for-1c/get-serial-numbers-for-transfer/get-serial-numbers-for-transfer';
import { ArticlesCountModel } from './articles-count.model';
import { DataSourceRequestState, groupBy, GroupResult } from '@progress/kendo-data-query';
import { SetSnModes } from './set-sn-modes.enum';
import { AbsentArticles } from './absent-articles.model';
import { DevicesService } from '../../../services/devices.service';
import { SelectSn } from '../../../models/device/select-sn/select-sn.model';
import { SelectSnData } from '../../../models/device/select-sn/select-sn-data.model';
import { ActivityStatusEnum } from '../../../enums/activity-status.enum';
import { For1CService } from '../../../services/for-1c.service';
import { ArticleMaskSerialNumbersModel } from './article-mask-serial-numbers.model';
import { RequestDevicesService } from '../../../services/request-devices.service';
import { SerialNumbersDevice } from '../../../models/device/serial-numbers-1C/serial-numbers-device';
import { ArticleClass } from '../../../models/for-1c/get-serial-numbers-for-transfer/article-class';
import { NgForm } from '@angular/forms';
import { AttachmentType, RequestInitTypeEnum } from '../../../enums';
import { AttachmentSpot } from '../../../enums/attachment-spot.enum';
import { DeviceServiceType } from '../../../enums/device-service-type.enum';
import { SnWithServiceTypeModel } from './sn-with-service-type.model';
import { DataClass } from '../../../models/for-1c/get-serial-numbers-for-transfer/data-class';
import { CustomerEnum } from '../../../enums/customer.enum';
import { LookupService } from '../../../services/lookup.service';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AppService } from '../../../../app.service';
import { ArticlesService } from '../../../services/articles.service';
import { SerialnumberClass } from '../../../models/for-1c/get-serial-numbers-for-transfer/serialnumber-class';
import { SetSnComponents } from '../set-sn-components/set-sn-components';
import { SetSnSetPackageSizeComponent } from './set-sn-set-size/set-sn-set-package-size.component';
import { DeliveryService } from '../../../services/delivery/delivery.service';
import { DeliveryDataDog, Package } from '../../../../requests/request-components/request-delivery/classes/delivery-data-dog';
import { WarehouseAreaCell } from '../../../models/warehouse/warehouse-area-cell';
import { WarehouseCellsService } from '../../../services/warehouse-cells.service';
import { NewCommentModel } from '../../../models/request/comments/new-comment-model';
import { CommentKindEnum } from '../../../enums/comment-kind.enum';
import { WarehousesService } from '../../../services/warehouses.service';
import { DeliveryAddress } from '../../../../requests/request-components/request-delivery/classes/delivery-address';
import { Request } from '../../../models/request/request';
import { DeliveryData } from '../../../../requests/request-components/request-delivery/classes/delivery-data';
import { DeliveryTrack } from '../../../../requests/request-components/request-delivery/classes/delivery-track';
import { DeliveryItem } from '../../../../requests/request-components/request-delivery/classes/delivery-item';
import { TransportCompanyService } from '../../../services/delivery/transport-company.service';
import { DeliveryTransportCompanyDto } from '../../../../requests/request-components/request-delivery/classes/delivery-transport-company-dto';

@Component({
	selector: 'set-sn',
	templateUrl: './set-sn.html',
	styleUrls: ['./set-sn.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class SetSn implements OnInit {

	@ViewChild('snForm') public snForm: NgForm;
	@ViewChild('setSnComponents') public setSnComponents: SetSnComponents;

	@Output()
	onContinueEvent = new EventEmitter();
	@Output()
	onOpenTransfer = new EventEmitter();

	@Output()
	onRefreshActivityStatus = new EventEmitter();

	@Input()
	requestId: number;
	@Input() 
	integraCode: string;
	@Input()
	requestTypeId: number;
	@Input()
	activityStatusId: number;
	@Input()
	activityId: number;

	@Input()
	warehouseTransferRequestSerialNumberExists: boolean = false;

	@Input()
	isSameWarehouses: boolean = false;

	@Input()
	initTypeId: number;

	@Input()
	customerContragentId: number;

	@Input()
	showComponentsArticles: boolean = false;

	loading: boolean = true; 
	isAutorecieving = false;

	public warehouseOwners: KeyValueObject[] = [];
	public warehouseAddresses: DeliveryAddress[] = [];
	request: Request;

	selectedComponentsDeviceStatusId: number = <number>DeviceStatus.shippedOnRequest;

	getSerialNumbersForTransferDtoFrom1C: GetSerialNumbersForTransferDto;

	//Режими отображения формы (пока используется только для заявок с типом "Перемещение")
	setSnModes: SetSnModes = SetSnModes.standart;

	setSnModels: SetSnModel[] = [];
	setSnModelsDevicesAndParts: SetSnModel[] = [];
	setSnModelsWithSn: SetSnModel[] = [];

	requestDeviceWarehouseRefills: RequestDeviceWarehouseRefillDto[] = [];

	serialNumberNotMatch: string = "Серийный номер не совпадает!";

	serialNumberNotFound: string = "Серийный номер не найден!";
	cellNumberNotFound: string = "Ячейка не найдена!";

	dataSaving: boolean = false;
	deviceOrPartExists?: boolean = null;

	msgArticlesMasks: string;
	msgRequestQuantities: string;
	msgResponseQuantities: string;
	articlesCountAvailable: ArticlesCountModel[] = [];
	senderWarehouseCells: string[] = [];
	singlecell: string;
	recipientWarehouseCells: string[] = [];
	declineReason: string;

	serialNumberSetterValue: string;

	recipientWarehouseCellSetterValue: string;

	isAutoSelectSn: boolean = false;
	autoSelectSnProcess: boolean = false;

	visibleAttachmentTypes: number[] = [<number>AttachmentType.transferAcceptanceCertificate, <number>AttachmentType.transferCertificateForRepair];
	rsReceivedSpotId: number = <number>AttachmentSpot.rsReceived;
	warehouseRefillAcceptanceSpotId: number = <number>AttachmentSpot.warehouseRefillAcceptance;
	isActLoaded: boolean = false;
	articlesSource: KeyValueObject[] = [];
	articles: KeyValueObject[] = [];

	swicthToReceived: string = "swicthToReceived";
	changeStatusCore: string = "changeStatusCore";
	changeStatusCoreAndSendRefillTo1C_fromUi: string = "changeStatusCoreAndSendRefillTo1C_fromUi";
	sendRefillTo1C_fromUi: string = "sendRefillTo1C_fromUi";

	@ViewChild("standartPerfectScrollbar")
	standartPerfectScrollbar?: any;

	@ViewChild("standartPerfectScrollbarArticles")
	standartPerfectScrollbarArticles?: any;

	public transportCompanies: DeliveryTransportCompanyDto[] = [];

	filterArticleValues = [];

	newNewSnId: number = 0;

	article1cError: string = null;

	public articlesFilter: string;

	autoSelectedRecipientWarehouseAreaCellNumberField: string = null;

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	private devicesSerialNumbersFrom1C: SerialNumbersDevice[] = [];
	private warehouseCells: WarehouseAreaCell[] = [];

	public noDevices: boolean = false;

	constructor(
		public dialog: DialogRef,
		protected requestsService: RequestsService,
		protected notificationService: NotificationService,
		protected usersService: UsersService,
		protected devicesService: DevicesService,
		private for1CService: For1CService,
		private requestDevicesService: RequestDevicesService,
		private lookupService: LookupService,
		private appService: AppService,
		private articlesService: ArticlesService,
		private dialogService: DialogService,
		private deliveryService: DeliveryService,
		private transportCompanyService: TransportCompanyService,
		private warehousesService: WarehousesService,
	) {

	}

	get isReceiveTransfer(): boolean {
		return this.activityStatusId >= <number>ActivityStatusEnum.awaitingWarehouseAcceptance
			|| (this.requestTypeId === <number>RequestType.warehouseRefill && !this.isMkInitType);
	}

	get attachmentSpotId(): number {
		return this.isWarehouseRefillNotMk ? this.warehouseRefillAcceptanceSpotId : this.rsReceivedSpotId;
	}

	get cardHeight(): number {
		return window.innerHeight - 400;
	}

	get actRequired(): boolean {
		return !this.isSameWarehouses && !this.isActLoaded;
	}

	get receivedOkDisabled(): boolean {
		return this.actRequired;
	}

	get isContragentPsb(): boolean {
		return this.customerContragentId === <number>CustomerEnum.psb;
	}

	isVipDevice(m: SetSnModel): boolean {
		return m.deviceServiceTypeId === <number>DeviceServiceType.KTO_MSP;
	}

	get isMkInitType(): boolean {
		return this.initTypeId === <number>RequestInitTypeEnum.blockChain;
	}

	get isByNomenclature(): boolean {
		return !this.isMkInitType && this.requestDeviceWarehouseRefills.some(s => !!s.nomenclature);
	}

	get approveSnCaption(): string {
		let caption = "Продолжить";

		if (this.isMkInitType && this.isWarehouseTransfer) {
			caption = "Отправить результат в БЧ";
		}

		if (this.isWarehouseRefillNotMk) {
			caption = "Прием окончен";
		}

		return caption;
	}

	getDeviceOrPartNotFound(requestDeviceWarehouseRefillDto: RequestDeviceWarehouseRefillDto): boolean {
		return requestDeviceWarehouseRefillDto.deviceStatusId === <number>DeviceStatus.notFound;
	}

	loadWarehouseRefillData(): void {
		this.loading = true;
		if (this.onOpenTransfer) {
			this.onOpenTransfer.emit();
		}
		this.setSnModes = SetSnModes.standart;

		this.requestsService.getById(this.requestId).subscribe(res => {

			let autoSelectedRecipientWarehouseAreaCellNumber = null

			if (!!res.entity.recipientWarehouseAreaCellNumberStorage) {
				autoSelectedRecipientWarehouseAreaCellNumber = res.entity.recipientWarehouseAreaCellNumberStorage;
			}

			if (!!res.entity.onlyOneRecipientWarehouseCellNumber) {
				this.isAutoSelectSn = res.entity.senderWarehouseIsAutoSelectSn;
				autoSelectedRecipientWarehouseAreaCellNumber = res.entity.onlyOneRecipientWarehouseCellNumber;
			}

			this.autoSelectedRecipientWarehouseAreaCellNumberField = autoSelectedRecipientWarehouseAreaCellNumber;

			this.isAutoSelectSn = res.entity.senderWarehouseIsAutoSelectSn;
			this.recipientWarehouseCells = res.entity.recipientWarehouseCells;
			this.requestDeviceWarehouseRefills = res.entity.requestDeviceWarehouseRefills;
			//Устройства и комплектующие для расчета количеств (хватает или нет)
			this.setSnModelsDevicesAndParts = this.requestDeviceWarehouseRefills
				.map(m => new SetSnModel(m.requestDeviceWarehouseRefillId
					, m.manufacturer
					, m.model
					, m.deviceTypeName
					, m.deviceStatusId === <number>DeviceStatus.acceptedAtTheRecipientWarehouse ? m.serialNumber : null
					, false
					, m.articleNumber
					, m.isDevicePart
					, m.deviceStatusId === <number>DeviceStatus.acceptedAtTheRecipientWarehouse ? m.quantity : null
					, m.itemID
					, m.selectedArticleNumber
					, this.serialNumberNotMatch
					, m.deviceStatusId === <number>DeviceStatus.acceptedAtTheRecipientWarehouse ? m.recipientWarehouseCellNumber : autoSelectedRecipientWarehouseAreaCellNumber
					, false
					, this.cellNumberNotFound
					, m.deviceConnectionTypeNameFromArticleMask
					, m.serialNumber
					, m.quantity
					, m.deviceStatusId === <number>DeviceStatus.notReceivedAtTheRecipientsWarehouse
					, null
					, m.deviceStatusId
					, m.quantityAvailable
					, m.deviceServiceTypeId
					, m.deviceServiceTypeName
					, m.nomenclature));

			this.setSnModelsDevicesAndParts = this.sortSetSnModelAndSetKtoHeader(this.setSnModelsDevicesAndParts);

			//Только устройства, для вывода на форму
			this.setSnModels = res.entity.requestDeviceWarehouseRefills
				.filter(f => this.isByNomenclature || f.isArticleMappedCorrect)
				.map(m => new SetSnModel(m.requestDeviceWarehouseRefillId
					, m.manufacturer
					, m.model
					, m.deviceTypeName
					, m.deviceStatusId === <number>DeviceStatus.acceptedAtTheRecipientWarehouse ? m.serialNumber : null
					, false
					, m.articleNumber
					, m.isDevicePart
					, m.deviceStatusId === <number>DeviceStatus.acceptedAtTheRecipientWarehouse ? m.quantity : null
					, m.itemID
					, m.selectedArticleNumber
					, this.serialNumberNotMatch
					, m.deviceStatusId === <number>DeviceStatus.acceptedAtTheRecipientWarehouse ? m.recipientWarehouseCellNumber : autoSelectedRecipientWarehouseAreaCellNumber
					, false
					, this.cellNumberNotFound
					, m.deviceConnectionTypeNameFromArticleMask
					, m.serialNumber
					, m.quantity
					, m.deviceStatusId === <number>DeviceStatus.notReceivedAtTheRecipientsWarehouse
					, null
					, m.deviceStatusId
					, m.quantityAvailable
					, m.deviceServiceTypeId
					, m.deviceServiceTypeName
					, m.nomenclature));

			this.setSnModels = this.sortSetSnModelAndSetKtoHeader(this.setSnModels);

			this.requestsService.getById(this.requestId).subscribe(res => {
				this.senderWarehouseCells = res.entity.senderWarehouseCells;
				if (this.senderWarehouseCells.length === 1) {
					this.setSnModels.filter(v => !v.cellNumber).forEach(v => v.cellNumber = this.senderWarehouseCells[0]);
					this.singlecell = this.senderWarehouseCells[0];
				}
			});

			this.setDeviceOrPartExists();

			this.loading = false;
		});
	}

	//Загрузка данных для типа заявок "Перемещение"
	loadWarehouseTransferData(): void {
		this.loading = true;
		if (this.onOpenTransfer) {
			this.onOpenTransfer.emit();
		}

		if (this.isReceiveTransfer) {
			//Прием по перемещению
			this.setSnModes = SetSnModes.awaitingReceive;

			this.loading = true;
			this.requestsService.getById(this.requestId).subscribe(res => {

				this.requestDeviceWarehouseRefills = res.entity.requestDeviceWarehouseRefills;
				this.recipientWarehouseCells = res.entity.recipientWarehouseCells;
				this.setSnModels = this.requestDeviceWarehouseRefills
					.filter(f => f.quantityReal > 0 && f.deviceStatusId !== <number>DeviceStatus.notConfirmedInEth)
					.map(m => new SetSnModel(m.requestDeviceWarehouseRefillId
						, m.manufacturer
						, m.model
						, m.deviceTypeName
						, m.deviceStatusId === <number>DeviceStatus.acceptedAtTheRecipientWarehouse ? m.serialNumber : null
						, false
						, m.articleNumber
						, m.isDevicePart
						, m.deviceStatusId === <number>DeviceStatus.acceptedAtTheRecipientWarehouse ? m.quantity : null
						, m.itemID
						, m.selectedArticleNumber
						, this.serialNumberNotMatch
						, m.deviceStatusId === <number>DeviceStatus.acceptedAtTheRecipientWarehouse ? m.recipientWarehouseCellNumber : null
						, false
						, this.cellNumberNotFound
						, m.deviceConnectionTypeNameFromArticleMask
						, m.serialNumber
						, m.quantity
						, null
						, null
						, m.deviceStatusId
						, m.quantityAvailable
						, m.deviceServiceTypeId
						, m.deviceServiceTypeName
						, m.nomenclature));

				this.setSnModels = this.sortSetSnModelAndSetKtoHeader(this.setSnModels);

				this.prepareDevicesForRecieveForm();

				this.setDeviceOrPartExists();
				this.loading = false;
			});
		} else {
			//Отправка по перемещению
			this.for1CService.getSerialNumbersForTransfer(this.requestId).subscribe(snRes => {
				this.getSerialNumbersForTransferDtoFrom1C = snRes;

				this.requestsService.getById(this.requestId).subscribe(res => {

					this.request = res.entity;
					this.warehousesService.warehousesContragentsOwnersLookup().subscribe(data => this.warehouseOwners = data);
					this.warehousesService.getAllWarehousesAddresses().subscribe(data => this.warehouseAddresses = data);

					this.isAutoSelectSn = res.entity.senderWarehouseIsAutoSelectSn;

					this.requestDeviceWarehouseRefills = res.entity.requestDeviceWarehouseRefills;
					//Устройства и комплектующие для расчета количеств (хватает или нет)
					this.setSnModelsDevicesAndParts = this.requestDeviceWarehouseRefills
						.filter(f => this.isByNomenclature || f.isArticleMappedCorrect)
						.map(m => new SetSnModel(m.requestDeviceWarehouseRefillId
							, m.manufacturer
							, m.model
							, m.deviceTypeName
							, m.serialNumber
							, false
							, m.articleNumber
							, m.isDevicePart
							, this.initDeviceOrPartQuantyty(m)
							, m.itemID
							, null
							, this.serialNumberNotFound
							, m.senderWarehouseCellNumber
							, false
							, this.cellNumberNotFound
							, m.deviceConnectionTypeNameFromArticleMask
							, null
							, m.quantity
							, this.getDeviceOrPartNotFound(m)
							, m.requestSerialNumber
							, m.deviceStatusId
							, m.quantityAvailable
							, m.deviceServiceTypeId
							, m.deviceServiceTypeName
							, m.nomenclature));

					this.setSnModelsDevicesAndParts = this.sortSetSnModelAndSetKtoHeader(this.setSnModelsDevicesAndParts);

					// if (!this.warehouseTransferRequestSerialNumberExists) {
					// 	let absentArticles = this.getAbsentArticlesWith1C(this.setSnModelsDevicesAndParts);
					// 	if (absentArticles.length > 0) {
					// 		this.msgArticlesMasks = absentArticles.map(m => m.articlesMask).join("; ");
					// 		this.msgRequestQuantities = absentArticles.map(m => m.requestQuantity).join("; ");
					// 		this.msgResponseQuantities = absentArticles.map(m => m.responseQuantity).join("; ");

					// 		this.setSnModes = SetSnModes.notEnough;
					// 		this.loading = false;
					// 		return;
					// 	}
					// }

					//Только устройства, для вывода на форму
					this.setSnModels = res.entity.requestDeviceWarehouseRefills
						.filter(f => this.isByNomenclature || f.isArticleMappedCorrect)
						.map(m => new SetSnModel(m.requestDeviceWarehouseRefillId
							, m.manufacturer
							, m.model
							, m.deviceTypeName
							, m.serialNumber
							, false
							, m.articleNumber
							, m.isDevicePart
							, this.initDeviceOrPartQuantyty(m)
							, m.itemID
							, null
							, this.serialNumberNotFound
							, m.senderWarehouseCellNumber
							, false
							, this.cellNumberNotFound
							, m.deviceConnectionTypeNameFromArticleMask
							, null
							, m.quantity
							, this.getDeviceOrPartNotFound(m)
							, m.requestSerialNumber
							, m.deviceStatusId
							, m.quantityAvailable
							, m.deviceServiceTypeId
							, m.deviceServiceTypeName
							, m.nomenclature));

					this.setSnModels = this.sortSetSnModelAndSetKtoHeader(this.setSnModels);

					this.requestsService.getById(this.requestId).subscribe(res => {
						this.senderWarehouseCells = res.entity.senderWarehouseCells;
						if (this.senderWarehouseCells.length === 1) {
							this.setSnModels.filter(v => !v.cellNumber).forEach(v => v.cellNumber = this.senderWarehouseCells[0]);
							this.singlecell = this.senderWarehouseCells[0];
						}
					});

					this.setDeviceOrPartExists();

					this.prepareDevicesForAssembleForm();

					if (this.notAvailableDeviceOrPartExsists) {
						this.setSnModes = SetSnModes.notEnough;
					}

					this.loadTransportCompanies();

					this.loading = false;
				});
			});
		}
	}

	moveSnToPlaceholder(){
		this.setSnModels
		.forEach(x => {
			x.placeholderSn = x.sn;
		});

		this.setSnModels
		.filter(x => !x.serialnumberMapped)
		.forEach(x => {
			x.sn = null;
		})
	}
	moveSnToPlaceholderRecieve(){
		this.setSnModels
		.forEach(x => {
			x.placeholderSn = x.snDb;
		});

		this.setSnModels
		.filter(x => !this.isAlreadyRecievedModel(x))
		.forEach(x => {
			x.sn = null;
		});
	}

	prepareDevicesForAssembleForm(){
		let data1C = this.getSerialNumbersForTransferDtoFrom1C.data;

		//Маппинг уже ранее замапленных девайсов
		this.setSnModels.filter(x => 
			this.isAlreadyPreparedToMoveModel(x))
			.forEach(setSnModel => {
				let articles1C = data1C.map(p => p.articles)
					.reduce((a, b) => a.concat(b), [])
					.filter(x => x.nomenclature === setSnModel.nomenclature);
					
					if (articles1C.length > 0) {
						let serialNumbers: SerialnumberClass[] = [];

						articles1C.forEach(f => {
							serialNumbers.push(...f.serialNumbers);
						});

						let serialNumbersNotAutoSelected = serialNumbers.filter(f => !f.isAutoSelected);

						let serialNumber =  serialNumbersNotAutoSelected.find(f => this.getDeviceServiceTypeIdByDescriptionSafe(f.deviceServiceTypeName) === this.getDeviceServiceTypeIdSafe(setSnModel.deviceServiceTypeId)
							&& f.serialNumber === setSnModel.sn);
						

						if (!!serialNumber) {
							setSnModel.serialnumberMapped = true;
							serialNumber.isAutoSelected = true;
						}
					}	
			});


		//Перевод SN в placeholder для незамапленных девайсов
		this.moveSnToPlaceholder();
	}

	prepareDevicesForRecieveForm(){
		//Маппинг уже ранее замапленных девайсов
		this.setSnModels.filter(x => 
			this.isAlreadyRecievedModel(x))
			.forEach(setSnModel => {
				setSnModel.serialnumberMapped = true;	
			});


		//Перевод SN в placeholder для незамапленных девайсов
		this.moveSnToPlaceholderRecieve();
	}

	isAlreadyPreparedToMoveModel(setSnModel: SetSnModel): boolean{
		return setSnModel.deviceStatusId == <number>DeviceStatus.assembledToMove
		|| setSnModel.deviceStatusId == <number>DeviceStatus.awaitingAssemblyToMove;
	}

	isAlreadyRecievedModel(setSnModel: SetSnModel): boolean{
		return setSnModel.deviceStatusId == <number>DeviceStatus.acceptedAtTheRecipientWarehouse;
	}

	makeEnum(enumObject): number[] {
		var all = [];
		for (var key in enumObject) {
			all.push(enumObject[key]);
		}
		all = Object.values(all).filter((v) => !isNaN(Number(v)))
		return all;
	}

	sortSetSnModelAndSetKtoHeader(m: SetSnModel[]): SetSnModel[] {
		let mr: SetSnModel[] = [];
		const segments = this.makeEnum(DeviceServiceType);

		const groupedKeys = m.reduce((group: { [key: number]: SetSnModel[] }, item) => {
			if (!group[this.getDeviceServiceTypeIdSafe(item.deviceServiceTypeId)]) {
				group[this.getDeviceServiceTypeIdSafe(item.deviceServiceTypeId)] = [];
			}
			group[this.getDeviceServiceTypeIdSafe(item.deviceServiceTypeId)].push(item);
			return group;
		}, {});

		m = [];

		segments.forEach(f => {
			if (!!groupedKeys[f]) {
				groupedKeys[f].sort(function (x, y) {
					// true values first
					//return (x.notFound === y.notFound) ? 0 : x.notFound ? -1 : 1;
					// false values first
					return (x.notFound === y.notFound) ? 0 : x.notFound ? 1 : -1;
				});

				groupedKeys[f].forEach(ff => {
					m.push(ff);
				})
			}
		});

		let soId = 0;
		m.forEach(f => {
			let deviceServiceTypeId = this.getDeviceServiceTypeIdSafe(f.deviceServiceTypeId);

			if (deviceServiceTypeId !== soId) {
				soId = deviceServiceTypeId;
				f.deviceServiceTypeNameHeader = f.deviceServiceTypeName;
			}
		});

		return m;
	}

	deviceServiceTypeNameHeaderExists(m: SetSnModel): boolean {
		return !!m.deviceServiceTypeNameHeader;
	}

	filesLoaded(uploadedFilesCount: number): void {
		this.isActLoaded = uploadedFilesCount > 0;
	}

	setDeviceOrPartExists(): void {
		this.deviceOrPartExists = this.setSnModels.some(s => !s.notFound);
	}

	initDeviceOrPartQuantyty(requestDeviceWarehouseRefillDto: RequestDeviceWarehouseRefillDto): number {
		//let qr: number = requestDeviceWarehouseRefillDto.quantityReal;

		return this.getDeviceOrPartNotFound(requestDeviceWarehouseRefillDto) ? 0 : requestDeviceWarehouseRefillDto.quantity;
	}

	ngOnInit() {
		switch (this.requestTypeId) {
			case <number>RequestType.warehouseRefill:
				if (!this.isMkInitType) {
					var contragentState: DataSourceRequestState = {
						skip: 0,
						take: null,
						filter: { logic: "or", filters: [{ field: "ContragentId", operator: "eq", value: <number>CustomerEnum.psb }] }
					};
					this.lookupService.getData("articles", contragentState).subscribe(data => {
						this.articlesSource = data;
						this.articles = this.articlesSource.slice(0);
					});
					this.loadWarehouseRefillData();
				}
				break;
			case <number>RequestType.warehouseTransfer:
			case <number>RequestType.foreighWarehouseTransfer:
			case <number>RequestType.internalWarehouseTransfer:
				this.loadWarehouseTransferData();
				break;
			case <number>RequestType.installCRE:
			case <number>RequestType.changeConfigCRE:
				this.loading = true;

				this.requestDevicesService.requestDevicesSerialNumbersFrom1C(this.requestId, this.activityId)
					.subscribe(result => {
						if (!result.isSuccessful) {
							this.loading = false;
							return;
						}

						this.devicesSerialNumbersFrom1C = result.data.devices;

						this.requestsService.getRequestDevices(this.requestId).subscribe(res => {
							this.setSnModels = res
								.filter(x => x.reserveResult === 'Ожидает выдачи инженеру')
								.map(m => {

									let serialNumbersForValidation: string[] = [];
									if (m.serialNumber) {
										serialNumbersForValidation = [m.serialNumber];
									} else {
										const devicesSerialNumbersFrom1C = this.devicesSerialNumbersFrom1C
											.filter(x => x.requestDeviceId === m.requestDeviceId);
										if (devicesSerialNumbersFrom1C.length > 0) {
											serialNumbersForValidation = devicesSerialNumbersFrom1C[0].serialNumbers.map(x => x.sn);
										}
									}

									const requireValidation = m.deviceTypeId !== 10;

									return {
										requestDeviceId: m.requestDeviceId,
										vendor: m.deviceSupplierName,
										model: m.deviceModelName,
										type: m.deviceTypeName,
										errorMessage: this.serialNumberNotMatch,
										snForValidation: serialNumbersForValidation,
										requireValidation: requireValidation,
										isError: false,
										isPart: false,
										deviceServiceTypeId: m.deviceServiceTypeId,
										deviceServiceTypeName: m.deviceServiceTypeName,
									} as SetSnModel;

								});

							this.setSnModels = this.sortSetSnModelAndSetKtoHeader(this.setSnModels);

							this.loading = false;
						});
					});

				break;
			default:
				this.loading = true;
				this.requestsService.getRequestDevices(this.requestId).subscribe(res => {
					this.setSnModels = res.filter(f => f.serialNumber !== null && f.serialNumber !== "")
						.map(m => new SetSnModel(m.requestDeviceId
							, m.deviceSupplierName
							, m.deviceModelName
							, m.deviceTypeName
							, null
							, false
							, null
							, false
							, null
							, null
							, null
							, this.serialNumberNotMatch
							, null
							, null
							, null
							, null
							, null
							, null
							, null
							, null
							, null
							, null
							, m.deviceServiceTypeId
							, m.deviceServiceTypeName));

					this.setSnModels = this.sortSetSnModelAndSetKtoHeader(this.setSnModels);

					this.setSnModelsWithSn = res.filter(f => f.serialNumber !== null && f.serialNumber !== "")
						.map(m => new SetSnModel(m.requestDeviceId
							, m.deviceSupplierName
							, m.deviceModelName
							, m.deviceTypeName
							, m.serialNumber
							, false
							, null
							, false
							, null
							, null
							, null
							, this.serialNumberNotMatch
							, null
							, null
							, null
							, null
							, null
							, null
							, null
							, null
							, null
							, null
							, m.deviceServiceTypeId
							, m.deviceServiceTypeName));

					this.setSnModelsWithSn = this.sortSetSnModelAndSetKtoHeader(this.setSnModelsWithSn);

					if (this.setSnModelsWithSn.length === 0) {
						this.noDevices = true;

						// https://jira.zampa.tech/browse/LTER-3245?focusedCommentId=100790&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-100790
						// 	if (this.onContinueEvent) {
						// 		this.onContinueEvent.emit();
						// 	}

						// 	this.cancel();
						// }
					}

					this.loading = false;
				});

				break;
		}
	}

	getDeviceName(setSnModel: SetSnModel): string {
		var values = [setSnModel.type, setSnModel.vendor, setSnModel.model, setSnModel.deviceConnectionTypeNameFromArticleMask, setSnModel.nomenclature];
		return values.filter(x => x).join(', ');
	}

	//Получение остутсвующих артикулов для формирования rsNotEnough XML (по факту)
	getAbsentArticlesReal(): AbsentArticles[] {
		let res: AbsentArticles[] = [];
		let setSnModelsPresent = this.setSnModels.filter(flt =>
			(!flt.isPart
				&& flt.sn !== ""
				&& flt.sn !== null
				&& !flt.isError)
			||
			flt.isPart);

		let gaPresent = this.groupArticles(setSnModelsPresent);
		let deviceCount = this.setSnModels.filter(flt => !flt.isPart).length;
		gaPresent.forEach(f => {
			if (!f.isPart) {
				if (f.requestQuantity !== deviceCount) {
					this.articlesCountAvailable.push(new ArticlesCountModel(f.articleMask, f.requestQuantity, f.isPart, f.itemId, f.requestQuantity));
				}
			} else {
				let partQuantity = this.requestDeviceWarehouseRefills.filter(flt => flt.isDevicePart
					&& flt.articleNumber === f.articleMask)[0].quantity;

				if (f.requestQuantity !== partQuantity) {
					this.articlesCountAvailable.push(new ArticlesCountModel(f.articleMask, f.requestQuantity, f.isPart, f.itemId, f.requestQuantity));
				}
			}

		});

		let setSnModelsAbsent = this.setSnModels.filter(flt =>
		(!flt.isPart
			&& (flt.sn === ""
				|| flt.sn === null
				|| flt.isError)));
		let gaAbsent = this.groupArticles(setSnModelsAbsent);
		gaAbsent.forEach(f => {
			if (!this.articlesCountAvailable.map(m => m.articleMask).includes(f.articleMask)) {
				this.articlesCountAvailable.push(new ArticlesCountModel(f.articleMask, 0, f.isPart, f.itemId, 0));
			}
		});

		res = gaAbsent.map(m => new AbsentArticles(m.articleMask, m.requestQuantity, m.responseQuantity));
		return res;
	}

	//Получение остутсвующих артикулов для фотмерования rsNotEnough XML (если не пришли из 1с)
	getAbsentArticlesWith1C(setSnModels: SetSnModel[]): AbsentArticles[] {
		let res: AbsentArticles[] = [];

		let ga = this.groupArticles(setSnModels);

		ga.forEach(f => {
			if (!this.getSerialNumbersForTransferDtoFrom1C.data.map(m => m.articleMask).includes(f.articleMask) && f.responseQuantity === 0) {
				this.articlesCountAvailable.push(new ArticlesCountModel(f.articleMask, f.requestQuantity, f.isPart, f.itemId, f.responseQuantity));
				res.push(new AbsentArticles(f.articleMask, f.requestQuantity, f.responseQuantity));
			} else {
				if (f.responseQuantity < f.requestQuantity) {
					res.push(new AbsentArticles(f.articleMask, f.requestQuantity, f.responseQuantity));
					this.articlesCountAvailable.push(new ArticlesCountModel(f.articleMask, f.requestQuantity, f.isPart, f.itemId, f.responseQuantity));
				}
			}
		});

		return res;
	}

	//Группировка артикулов, т.к. в модели SetSnModel маски артикулов повторяются, но нам надо их посчитать
	groupArticles(setSnModels: SetSnModel[]): ArticlesCountModel[] {
		let res: ArticlesCountModel[] = [];
		let gb = groupBy(setSnModels, [{ field: "articleMask" }]);

		let articles = [];
		this.getSerialNumbersForTransferDtoFrom1C.data.forEach(item => item.articles.map(article => articles.push(article)));
		for (let i = 0; i < gb.length; i++) {
			const el = <GroupResult>gb[i];
			const setSnModel = <SetSnModel>el.items[0];

			let responseQuantity = 0;
			const articleResponseDataFrom1C = this.getSerialNumbersForTransferDtoFrom1C.data.filter(x => x.articleMask == el.value);
			if (articleResponseDataFrom1C.length > 0) {
				articleResponseDataFrom1C
					.map(mask => mask.articles.map(article => {
						article.serialNumbers.map(serialNumber => responseQuantity += serialNumber.quantity);
					}));
			}

			//TODO костыль
			if (responseQuantity === 0) {
				articles
					.filter(x => x.article === el.value)
					.map(article => {
						article.serialNumbers.map(serialNumber => responseQuantity += serialNumber.quantity);
					});
			}

			if (setSnModel.isPart) {
				res.push(new ArticlesCountModel(el.value, (setSnModel.quantity === null ? 0 : setSnModel.quantity), true, setSnModel.itemId, responseQuantity));
			} else {
				res.push(new ArticlesCountModel(el.value, el.items.length, setSnModel.isPart, setSnModel.itemId, responseQuantity));
			}
		}

		return res;
	}

	get isWarehouseTransfer(): boolean {
		return [RequestType.warehouseTransfer,
		RequestType.foreighWarehouseTransfer,
		RequestType.internalWarehouseTransfer].includes(this.requestTypeId);
	}

	get isWarehouseRefillNotMk(): boolean {
		return !this.isMkInitType && [RequestType.warehouseRefill].includes(this.requestTypeId);
	}

	get isArticleNotSelected(): boolean {
		return this.requestDeviceWarehouseRefills
			.filter(f => f.isArticleMappedCorrect
				&& !f.isDevicePart)
			.some(s => !s.selectedArticleNumber);
	}

	//Проверка возможности завершения задания с отправкой нехватки
	get canStopTask(): boolean {
		let isEmptyPartQuantity = this.setSnModels.filter(f => f.isPart).some(s => s.quantity === 0 || s.quantity === null);
		let isEmptySn = this.setSnModels.filter(f => !f.isPart).some(s => s.sn === "" || s.sn === null);
		let isValidationError = this.setSnModels.some(s => s.isError || s.isCellError);
		return isEmptyPartQuantity || isEmptySn || isValidationError;
	}

	continueDisabled(): boolean {
		if (this.requestTypeId === <number>RequestType.installCRE || this.requestTypeId === <number>RequestType.changeConfigCRE)
			return this.setSnModels.some(x => x.isError);

		if (!!this.setSnComponents)
			return this.setSnComponents.continueDisabled();

		return false;
	}

	cancel() {
		if (this.dialog)
			this.dialog.close();
	}

	cancelTask(): void {
		this.setSnModes = SetSnModes.cancelTask;
	}

	//Сброс выбранных устройств и отправка в бек
	resetDevices(): void {
		if (this.isReceiveTransfer) {
			let selectSnData = this.getAllSelectedDevicesAndParts().map(m => new SelectSnData(m.requestDeviceId
				, null
				, DeviceStatus.assembledToMove
				, m.isPart
				, m.serialNumber
				, m.selectedCount));

			this.devicesService.selectSn(new SelectSn(this.requestId, selectSnData, false, this.isReceiveTransfer)).subscribe(res => {
				if (res.isSuccessful) {
					this.setSnModes = SetSnModes.awaitingReceive;
					this.loadWarehouseTransferData();
				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: res.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
			});
		} else {
			let selectSnData = this.getAllSelectedDevicesAndParts().map(m => new SelectSnData(m.requestDeviceId
				, null
				, DeviceStatus.inRequest
				, m.isPart
				, null
				, m.selectedCount));

			this.devicesService.selectSn(new SelectSn(this.requestId, selectSnData, false, this.isReceiveTransfer)).subscribe(res => {
				if (res.isSuccessful) {
					this.setSnModes = SetSnModes.standart;
					this.loadWarehouseTransferData();
				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: res.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
			});
		}
	}

	returnStandart(): void {
		this.setSnModes = SetSnModes.standart;
	}

	return(): void{
		if (this.isReceiveTransfer){
			this.returnAwaitingReceive();
		} else {
			this.returnStandart();
		}
	}

	returnAwaitingReceive(): void {
		this.setSnModes = SetSnModes.awaitingReceive;
	}

	//Завершение задания, тут тоже отправляется rsNotEnough XML
	stopSendTask(): void {
		this.checkAllSnValidationAndSet1CArticle()
		this.checkAllPartQuantityValidationTransferDb();

		let absentArticles = this.getAbsentArticlesReal();
		this.msgArticlesMasks = absentArticles.map(m => m.articlesMask).join("; ");
		this.setSnModes = SetSnModes.stopSendTask;
	}

	stopReceiveTask(): void {
		this.setSnModes = SetSnModes.stopReceiveTask;
	}

	stopTaskConfirm(): void {
		this.sendCommand();
		this.cancel();
	}

	//Этот метод использовался при упрощенной реализации заявок на перемещение
	sendTo1C(): void {
		let transferDevices = this.setSnModels.map(m => new TransferDevice(m.requestDeviceId, m.sn));

		this.dataSaving = true;
		this.requestsService.notify1CAboutWarehouseTransfer(
			new Notify1CAboutWarehouseReceive(this.requestId, transferDevices))
			.subscribe(res => {
				let isError = false;
				this.setSnModels.forEach(f => {
					let isFind = res.data.filter(fil => fil.requestDeviceId === f.requestDeviceId).map(m => m.isFind)[0];

					if (!isFind) {
						f.isError = true;
						isError = true;
					}
				});

				if (!isError) {
					this.cancel();
				}

				this.dataSaving = false;
			});
	}

	get disabled1C(): boolean {
		//return this.setSnModels.length === 0;
		return false;
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	processCustomState(state: string): void {
		switch (state) {
			case this.swicthToReceived:
				if (!this.isWarehouseRefillNotMk) {
					this.visibleAttachmentTypes = [<number>AttachmentType.transferAcceptanceCertificate
						, <number>AttachmentType.transferCertificateForRepair];
				} else {
					this.visibleAttachmentTypes = [<number>AttachmentType.transferAcceptanceCertificate];
				}
				this.setSnModes = SetSnModes.received;
				this.dataSaving = false;
				break;

			case this.changeStatusCore:
				if (this.onContinueEvent) {
					this.onContinueEvent.emit();
				}

				this.dataSaving = false;
				this.dialog.close();
				break;

			case this.changeStatusCoreAndSendRefillTo1C_fromUi:

				this.requestsService.sendStateWithLogic(
					new SendStateWithLogic(this.requestId, state))
					.subscribe(() => {
						if (this.onContinueEvent) {
							this.onContinueEvent.emit();
						}

						this.dataSaving = false;
						this.cancel();
					});

				break;

			case this.sendRefillTo1C_fromUi:

				this.requestsService.sendStateWithLogic(
					new SendStateWithLogic(this.requestId, state))
					.subscribe(() => {
						if (this.onRefreshActivityStatus) {
							this.onRefreshActivityStatus.emit();
						}

						this.dataSaving = false;
						this.cancel();
					});

				break;
			default:
				break;
		}
	}

	//Отправка перевода состояния заявки в БЧ с формированием нужного XML в беке (вся логика в беке)
	sendCommand(saveData: boolean = false,
		state: string = null,
		processMkLogic: boolean = true): void {

		this.dataSaving = true;

		let customAliases = [
			this.swicthToReceived,
			this.changeStatusCore,
			this.changeStatusCoreAndSendRefillTo1C_fromUi,
			this.sendRefillTo1C_fromUi,
		];

		if (saveData) {
			let selectSnData = this.getAllSelectedDevicesAndParts();

			if (this.isWarehouseRefillNotMk) {
				selectSnData.forEach(f => {
					if (f.notFound !== null && f.notFound) {
						f.deviceStatusId = <number>DeviceStatus.notReceivedAtTheRecipientsWarehouse;
					} else {
						f.deviceStatusId = <number>DeviceStatus.acceptedAtTheRecipientWarehouse;
					}
				});
			}

			this.devicesService.selectSn(new SelectSn(this.requestId, selectSnData, false, this.isReceiveTransfer)).subscribe(res => {
				if (res.isSuccessful) {

					if (!!state) {
						if (customAliases.includes(state)) {
							this.processCustomState(state);
						} else {
							this.requestsService.sendStateWithLogic(
								new SendStateWithLogic(this.requestId, state))
								.subscribe(() => {
									this.refreshActivityStatus();
									this.cancel();
									this.dataSaving = false;
								});
						}
					} else {
						this.dataSaving = false;
					}

				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: res.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
					this.dataSaving = false;
				}
			});
		} else {
			if (!!state) {
				if (customAliases.includes(state)) {
					this.processCustomState(state);
				} else {
					this.requestsService.sendStateWithLogic(
						new SendStateWithLogic(this.requestId, state))
						.subscribe(() => {
							this.refreshActivityStatus();
							this.cancel();
							this.dataSaving = false;
						});
				}
			}
		}

		if (processMkLogic) {
			switch (this.setSnModes) {
				case SetSnModes.selected:
					let blockchainState = "rsSelected_fromUi";
					if (this.warehouseTransferRequestSerialNumberExists) {
						blockchainState = "bySNRs_fromUi";
					}

					this.requestsService.sendStateWithLogic(
						new SendStateWithLogic(this.requestId, blockchainState))
						.subscribe(() => {
							this.refreshActivityStatus();
							this.cancel();
							this.dataSaving = false;
						});
					break;
				case SetSnModes.notEnough:
				case SetSnModes.stopSendTask:
					this.requestsService.sendStateWithLogic(
						new SendStateWithLogic(this.requestId, "rsNotEnough_fromUi", this.articlesCountAvailable))
						.subscribe(() => {
							this.refreshActivityStatus();
							this.cancel();
							this.dataSaving = false;
						});
					break;
				case SetSnModes.received:
					this.requestsService.sendStateWithLogic(
						new SendStateWithLogic(this.requestId, "rsReceived_fromUi", this.articlesCountAvailable))
						.subscribe(() => {
							this.refreshActivityStatus();
							this.cancel();
							this.dataSaving = false;
						});
					break;
				case SetSnModes.stopReceiveTask:
					this.requestsService.sendStateWithLogic(
						new SendStateWithLogic(this.requestId, "declinedRs_fromUi", this.declineReason))
						.subscribe(() => {
							this.refreshActivityStatus();
							this.cancel();
							this.dataSaving = false;
						});
					break;
				default:
					break;
			}
		}
	}

	refreshActivityStatus(): void {
		if (this.isByNomenclature && this.onRefreshActivityStatus) {
			this.onRefreshActivityStatus.emit();
		}
	}

	continue() {
		if (this.requestTypeId === <number>RequestType.installCRE || this.requestTypeId === <number>RequestType.changeConfigCRE) {
			if (this.setSnModels.some(x => x.isError))
				return;

			if (this.onContinueEvent) {
				this.onContinueEvent.emit();
			}

			this.dialog.close();

			return;
		}


		let isError = false;
		this.setSnModels.forEach(f => {
			let snDb = this.setSnModelsWithSn.filter(fil => fil.requestDeviceId === f.requestDeviceId).map(m => m.sn)[0];

			if (f.sn !== snDb) {
				f.isError = true;
				isError = true;
			}
		});

		switch (this.requestTypeId) {
			case <number>RequestType.remoteInstall:
				if (!isError) {
					let stateFromUi = this.activityStatusId === <number>ActivityStatusEnum.issuedPartsReplacement
						? "issuedCompAdjustment_fromUi"
						: "storeEquipIssued_fromUi";

					this.requestsService.sendStateWithLogic(
						new SendStateWithLogic(this.requestId, stateFromUi, null, !!this.setSnComponents ? this.setSnComponents.selectedComponentsArticles : null))
						.subscribe(() => {
							this.refreshActivityStatus();
							this.cancel();
							this.dataSaving = false;
						});
				}
				break;

			default:
				if (!isError) {
					if (this.onContinueEvent) {
						this.onContinueEvent.emit(!!this.setSnComponents ? this.setSnComponents.selectedComponentsArticles : null);
					}

					this.cancel();
				}
				break;
		}
	}

	//Проверка артикула для одной строки (используется на анфокус и нажатие Enter)
	checkOneSnValidationAndSet1CArticle(setSnModel: SetSnModel, isByNomenclature: boolean = false): boolean {
		let isError = false;
		let articles1C = [];

		if (setSnModel.notFound) {
			return isError;
		}

		if (this.getSerialNumbersForTransferDtoFrom1C.data !== null
			&& this.getSerialNumbersForTransferDtoFrom1C.data.length > 0) {

			let data1C = this.getSerialNumbersForTransferDtoFrom1C.data.find(fil => fil.articleMask === setSnModel.articleMask);

			if (this.isByNomenclature) {
				data1C = new DataClass;
				data1C.articles = this.getSerialNumbersForTransferDtoFrom1C.data.map(p => p.articles)
					.reduce((a, b) => a.concat(b), [])
					.filter(flt => flt.nomenclature === setSnModel.nomenclature);
			}

			if (!!data1C) {
				articles1C = data1C.articles;
			}
		}

		setSnModel.errorMessage = this.serialNumberNotFound;
		setSnModel.articleNumber = null;
		setSnModel.nomenclatureChecked = false;

		articles1C.forEach(article1C => {
			if (article1C.serialNumbers.filter(flt => this.getDeviceServiceTypeIdByDescriptionSafe(flt.deviceServiceTypeName) === this.getDeviceServiceTypeIdSafe(setSnModel.deviceServiceTypeId))
				.map(m => m.serialNumber).includes(setSnModel.sn)) {

				if (!this.setSnModels
					.filter(flt => !flt.notFound && flt.requestDeviceId !== setSnModel.requestDeviceId)
					.map(m => m.sn)
					.includes(setSnModel.sn)) {

					let check = this.compareArticleMaskAndArticle(setSnModel.articleMask, article1C.article);

					if (this.isByNomenclature) {
						check = setSnModel.nomenclature === article1C.nomenclature;
					}

					if (check) {
						setSnModel.articleNumber = article1C.article;
						setSnModel.nomenclatureChecked = check;
						setSnModel.isError = false;
					} else {
						if (this.isByNomenclature) {
							setSnModel.errorMessage = `[${setSnModel.sn}]: номенклатура [${article1C.nomenclature}] не соответствует номенклатуре в запросе [${setSnModel.nomenclature}]`;
						} else {
							setSnModel.errorMessage = `[${setSnModel.sn}]: артикул [${article1C.article}] не соответствует маске в запросе [${setSnModel.articleMask}]`;
						}
					}

				} else {
					setSnModel.errorMessage = "Данный серийный номер уже указан в другом поле!";
				}
			}
		});

		if (this.isByNomenclature) {
			if (!setSnModel.nomenclatureChecked) {
				setSnModel.isError = true;
				isError = true;
			}
		} else {
			if (setSnModel.articleNumber === null || !setSnModel.nomenclatureChecked) {
				setSnModel.isError = true;
				isError = true;
			}
		}

		return isError;
	}

	getDeviceServiceTypeIdSafe(deviceServiceTypeId?: number): number {
		if (!!deviceServiceTypeId) {
			return deviceServiceTypeId;
		} else {
			return <number>DeviceServiceType.SINGLE_REQUEST
		}
	}

	getDeviceServiceTypeIdByDescriptionSafe(desc: string): number {
		switch (desc) {
			case "KTO":
				return <number>DeviceServiceType.KTO;
			case "KTO MSP":
				return <number>DeviceServiceType.KTO_MSP;
			case "Разовые заявки":
				return <number>DeviceServiceType.SINGLE_REQUEST;
			case "KIB":
				return <number>DeviceServiceType.KIB;
			case "SMB":
				return <number>DeviceServiceType.SMB;
			default:
				return <number>DeviceServiceType.SINGLE_REQUEST;
		}
	}

	compareArticleMaskAndArticle(articleMask: string, article: string): boolean {
		if (!!!articleMask || !!!article || articleMask.trim() === "" || article.trim() === "") {
			return false;
		}

		let charIndex = articleMask.indexOf("*");
		let articleMaskSub = articleMask.substring(0, charIndex);
		return article.includes(articleMaskSub);
	}

	//Группирока серийников по маскам
	groupSerialNumbersByArticleMask(): ArticleMaskSerialNumbersModel[] {
		let res: ArticleMaskSerialNumbersModel[] = [];
		let gb = groupBy(this.requestDeviceWarehouseRefills
			.filter(f => f.deviceStatusId !== <number>DeviceStatus.notFound), [{ field: "articleNumber" }]);

		for (let i = 0; i < gb.length; i++) {
			const el = <GroupResult>gb[i];
			const requestDeviceWarehouseRefil = <RequestDeviceWarehouseRefillDto>el.items[0];
			let serialNumbers = (<RequestDeviceWarehouseRefillDto[]>el.items).map(m => new SnWithServiceTypeModel(m.serialNumber, this.getDeviceServiceTypeIdSafe(m.deviceServiceTypeId)));

			if (this.warehouseTransferRequestSerialNumberExists) {
				serialNumbers = (<RequestDeviceWarehouseRefillDto[]>el.items).map(m => new SnWithServiceTypeModel(m.requestSerialNumber, this.getDeviceServiceTypeIdSafe(m.deviceServiceTypeId)));
			}

			res.push(new ArticleMaskSerialNumbersModel(el.value, serialNumbers, requestDeviceWarehouseRefil.isDevicePart));
		}

		return res;
	}

	//Проверка наличия ячейки в базе СД при приеме у перемещения
	checkOneCellValidationTransferDb(setSnModel: SetSnModel): boolean {
		let isError = false;

		let gb = this.groupSerialNumbersByArticleMask();
		let flt = gb.filter(f => f.articleMask === setSnModel.articleMask);
		if (!this.isReceiveTransfer) {
			if (flt && flt.length > 0) {
				if (!this.senderWarehouseCells.includes(setSnModel.cellNumber)) {
					isError = true;
					setSnModel.isCellError = true;
				}
			} else {
				isError = true;
				setSnModel.isCellError = true;
			}
		} else {
			if (flt && flt.length > 0) {
				if (!this.recipientWarehouseCells.includes(setSnModel.cellNumber)) {
					isError = true;
					setSnModel.isCellError = true;
				}
			} else {
				isError = true;
				setSnModel.isCellError = true;
			}
		}

		return isError;

	}

	//Проверка наличия всех ячеек в базе СД при приеме у перемещения
	checkAllCellValidationTransferDb(): boolean {
		let isError = false;

		this.setSnModels.forEach(setSnModel => {
			let err = this.checkOneCellValidationTransferDb(setSnModel);
			if (!isError) {
				isError = err;
			}
		});

		return isError;
	}

	//Проверка количества для комплектующего в БД
	checkOnePartQuantityValidationTransferDb(setSnModel: SetSnModel): boolean {
		let isError = false;

		if (setSnModel.notFound) {
			return false;
		}

		let flt = this.requestDeviceWarehouseRefills.filter(f => f.isDevicePart && f.requestDeviceWarehouseRefillId == setSnModel.requestDeviceId);
		if (flt && flt.length > 0) {

			if (!this.isReceiveTransfer) {

				if (flt[0].quantity < setSnModel.quantity) {
					isError = true;
					setSnModel.isError = true;
					setSnModel.errorMessage = `Количество должно быть менее или равно [${flt[0].quantity}]`;
				} else if (flt[0].quantityAvailable < setSnModel.quantity) {
					isError = true;
					setSnModel.isError = true;
					setSnModel.errorMessage = `Доступное количество в 1С [${flt[0].quantityAvailable}]`;
				}
			} else {

				if (flt[0].quantityReal !== setSnModel.quantity) {
					isError = true;
					setSnModel.isError = true;
					setSnModel.errorMessage = `Количество должно быть равно [${flt[0].quantityReal}]`;
				}
			}
		}
		return isError;
	}

	//Проверка количества для комплектующего в 1С
	checkOnePartQuantityValidationTransfer1C(setSnModel: SetSnModel): boolean {
		if (!setSnModel.isPart) {
			return;
		}

		let isError = false;
		let articles1C: ArticleClass[] = [];

		if (this.getSerialNumbersForTransferDtoFrom1C.data !== null
			&& this.getSerialNumbersForTransferDtoFrom1C.data.length > 0) {
			let data1C = this.getSerialNumbersForTransferDtoFrom1C.data.find(fil => fil.articleMask === setSnModel.articleMask);

			if (!!data1C) {
				articles1C = data1C.articles;
			}
		}

		if (articles1C.map(m => m.article).includes(setSnModel.articleMask)) {
			let findedArticles = articles1C.filter(f => f.article === setSnModel.articleMask);
			let serialNumbers1C = [];

			findedArticles.forEach(article1C => article1C.serialNumbers.forEach(sn1C => serialNumbers1C.push(sn1C)));
			let fullQuantity1C = serialNumbers1C.map(m => m.quantity).reduce((a, b) => a + b, 0);

			let findedQuantity = this.setSnModels
				.filter(f => f.isPart && f.articleMask === setSnModel.articleMask)
				.map(m => m.quantity)
				.reduce((a, b) => a + b, 0);

			let restQuantity1C = fullQuantity1C - findedQuantity;

			if (restQuantity1C < setSnModel.quantity) {
				setSnModel.isError = true;
				isError = true;

				setSnModel.errorMessage = `Оставшееся количество в 1С [${restQuantity1C}]`;
			}
		} else {
			setSnModel.isError = true;
			isError = true;
			setSnModel.errorMessage = `Оставшееся количество в 1С [0]`;
		}

		return isError;
	}

	//Проверка количества для всех комплектующих в БД
	checkAllPartQuantityValidationTransferDb(): boolean {
		let isError = false;

		this.setSnModels.forEach(setSnModel => {
			let err = this.checkOnePartQuantityValidationTransferDb(setSnModel);
			if (!isError) {
				isError = err;
			}
		});

		return isError;
	}

	//Проверка количества для всех комплектующих в 1С
	checkAllPartQuantityValidationTransfer1C(): boolean {
		let isError = false;

		this.setSnModels.filter(flt => flt.isPart).forEach(setSnModel => {
			let err = this.checkOnePartQuantityValidationTransfer1C(setSnModel);
			if (!isError) {
				isError = err;
			}
		});

		return isError;
	}

	//Проверка наличия серийника в базе СД при приеме у перемещения
	checkOneSnValidationTransferDb(setSnModel: SetSnModel): boolean {
		let isError = false;

		if (setSnModel.notFound
			|| setSnModel.isAdded) {
			return false;
		}

		let gb = this.groupSerialNumbersByArticleMask();
		let flt = gb.filter(f => f.articleMask === setSnModel.articleMask);
		if (flt && flt.length > 0) {
			if (!flt[0].isPart) {
				if (flt[0].serialNumbers.filter(flt => this.getDeviceServiceTypeIdSafe(flt.deviceServiceTypeId) === this.getDeviceServiceTypeIdSafe(setSnModel.deviceServiceTypeId))
					.map(m => m.serialNumber)
					.includes(setSnModel.sn)) {

					if (this.setSnModels
						.filter(flt => flt.requestDeviceId !== setSnModel.requestDeviceId)
						.map(m => m.sn)
						.includes(setSnModel.sn)) {
						isError = true;
						setSnModel.errorMessage = "Данный серийный номер уже указан в другом поле!";
					}
				} else {
					isError = true;
					setSnModel.errorMessage = this.serialNumberNotFound;
				}
			}
		} else {
			isError = true;
			setSnModel.errorMessage = this.serialNumberNotFound;
		}

		if (!isError) {
			setSnModel.articleNumber = setSnModel.articleMask;
		}

		setSnModel.isError = isError;
		return isError;
	}

	//Проверка наличия всех серийников в базе СД при приеме у перемещения
	checkAllSnValidationTransferDb(): boolean {
		let isError = false;

		this.setSnModels.forEach(setSnModel => {
			let err = this.checkOneSnValidationTransferDb(setSnModel);
			if (!isError) {
				isError = err;
			}
		});

		return isError;
	}

	//Проверка всех введенных артикулов (используется для отправки выбранных артикулов в бек и reSelected XML в БЧ)
	checkAllSnValidationAndSet1CArticle(): boolean {

		let isError = false;

		this.setSnModels.filter(f => !f.notFound && !f.isPart).forEach(setSnModel => {
			let articles1C = [];
			if (this.getSerialNumbersForTransferDtoFrom1C.data !== null
				&& this.getSerialNumbersForTransferDtoFrom1C.data.length > 0) {

				let data1C = this.getSerialNumbersForTransferDtoFrom1C.data.find(fil => fil.articleMask === setSnModel.articleMask);

				if (this.isByNomenclature) {
					data1C = new DataClass;
					data1C.articles = this.getSerialNumbersForTransferDtoFrom1C.data.map(p => p.articles)
						.reduce((a, b) => a.concat(b), [])
						.filter(flt => flt.nomenclature === setSnModel.nomenclature);
				}

				if (!!data1C) {
					articles1C = data1C.articles;
				}
			}

			setSnModel.errorMessage = this.serialNumberNotFound;
			setSnModel.articleNumber = null;
			setSnModel.nomenclatureChecked = false;

			articles1C.forEach(article1C => {
				if (article1C.serialNumbers.filter(flt => this.getDeviceServiceTypeIdByDescriptionSafe(flt.deviceServiceTypeName) === this.getDeviceServiceTypeIdSafe(setSnModel.deviceServiceTypeId))
					.map(m => m.serialNumber).includes(setSnModel.sn)) {

					if (!this.setSnModels
						.filter(flt => !flt.notFound && flt.requestDeviceId !== setSnModel.requestDeviceId)
						.map(m => m.sn)
						.includes(setSnModel.sn)) {

						let check = this.compareArticleMaskAndArticle(setSnModel.articleMask, article1C.article);

						if (this.isByNomenclature) {
							check = setSnModel.nomenclature === article1C.nomenclature;
						}

						if (check) {
							setSnModel.articleNumber = article1C.article;
							setSnModel.nomenclatureChecked = check;
						} else {
							if (this.isByNomenclature) {
								setSnModel.errorMessage = `[${setSnModel.sn}]: номенклатура [${article1C.nomenclature}] не соответствует номенклатуре в запросе [${setSnModel.nomenclature}]`;
							} else {
								setSnModel.errorMessage = `[${setSnModel.sn}]: артикул [${article1C.article}] не соответствует маске в запросе [${setSnModel.articleMask}]`;
							}
						}

					} else {
						setSnModel.errorMessage = "Данный серийный номен уже указан в другом поле!"
					}
				}
			});
		});

		this.setSnModels.filter(f => !f.notFound && !f.isPart).forEach(setSnModel => {
			if (this.isByNomenclature) {
				if (!setSnModel.nomenclatureChecked) {
					setSnModel.isError = true;
					isError = true;
				}
			} else {
				if (setSnModel.articleNumber === null || !setSnModel.nomenclatureChecked) {
					setSnModel.isError = true;
					isError = true;
				}
			}
		});


		return isError;
	}

	//Отправка выбранных артикулов в бек и rsSelected XML в БЧ при отправке у перемещения
	approveSnFrom1C(): void {
		let isSnError = true;

		if (this.warehouseTransferRequestSerialNumberExists
			|| this.isWarehouseRefillNotMk) {
			isSnError = this.checkAllSnValidationTransferDb();
		} else {
			isSnError = this.checkAllSnValidationAndSet1CArticle();
		}

		//let isQuantityError1C = this.checkAllPartQuantityValidationTransfer1C();
		let isQuantityErrorDb = this.checkAllPartQuantityValidationTransferDb();
		//let isError = isSnError || isQuantityError1C || isQuantityErrorDb;
		let isError = isSnError || isQuantityErrorDb;

		if (this.isWarehouseRefillNotMk) {
			this.setSnModes = SetSnModes.selected;
		} else {
			if (!isError) {
				let selectSnData = this.getAllSelectedDevicesAndParts();

				this.devicesService.selectSn(new SelectSn(this.requestId, selectSnData, false, this.isReceiveTransfer)).subscribe(res => {
					if (res.isSuccessful) {
						if (!this.isReceiveTransfer){
							this.setSizeAndContinue(selectSnData)
						} else {
							this.setSnModes = SetSnModes.selected;
						}
					} else {
						this.notificationService.error({
							title: 'Ошибка',
							message: res.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}
				});
			}
		}
	}



	//Отправка выбранных артикулов в бек и rsReceived XML в БЧ при приеме у перемещения
	approveSnFromDb(): void {
		let isSnError = this.checkAllSnValidationTransferDb()
		let isCellError = this.checkAllCellValidationTransferDb();
		let quantityError = this.checkAllPartQuantityValidationTransferDb();
		let isError = isSnError || isCellError || quantityError;

		if (!isError) {
			const selectSn = () => {
				let selectSnData = this.getAllSelectedDevicesAndParts();

				this.devicesService.selectSn(new SelectSn(this.requestId, selectSnData, false, this.isReceiveTransfer)).subscribe(res => {
					if (res.isSuccessful) {
						this.setSnModes = SetSnModes.received;
					} else {
						this.notificationService.error({
							title: 'Ошибка',
							message: res.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}
				});
			};

			if (!this.setSnModelsDevicesAndParts || this.setSnModelsDevicesAndParts.length === 0) {
				this.requestsService.getById(this.requestId).subscribe(res => {

					this.requestDeviceWarehouseRefills = res.entity.requestDeviceWarehouseRefills;
					//Устройства и комплектующие для расчета количеств (хватает или нет)
					this.setSnModelsDevicesAndParts = this.requestDeviceWarehouseRefills
						.filter(f => f.isArticleMappedCorrect)
						.map(m => new SetSnModel(m.requestDeviceWarehouseRefillId
							, m.manufacturer
							, m.model
							, m.deviceTypeName
							, m.serialNumber
							, false
							, m.articleNumber
							, m.isDevicePart
							, m.quantity
							, m.itemID
							, null
							, this.serialNumberNotFound
							, m.recipientWarehouseCellNumber
							, false
							, this.cellNumberNotFound
							, m.deviceConnectionTypeNameFromArticleMask));

					selectSn();
				});

			} else {
				selectSn();
			}
		}
	}

	//Получение всех устройств с комплектующими и конвертация в модель SelectSnData для отправки в бек
	getAllSelectedDevicesAndParts(): SelectSnData[] {
		let deviceStatus: number;
		if (this.isReceiveTransfer) {
			deviceStatus = <number>DeviceStatus.acceptedAtTheRecipientWarehouse;
		} else {
			deviceStatus = <number>DeviceStatus.assembledToMove;
		}
		let isRecipientCellNumber = (m: SetSnModel) => !m.isPart || (m.isPart && this.isReceiveTransfer);
		return this.setSnModels
			.map(m => new SelectSnData(
				m.requestDeviceId
				, m.articleNumber
				, deviceStatus
				, m.isPart
				, m.sn
				, m.quantity
				, isRecipientCellNumber(m) ? m.cellNumber : null
				, isRecipientCellNumber(m) ? null : m.cellNumber
				, m.notFound
				, m.nomenclature
				, m.isAdded
				, m.articleId
				, m.deviceTypeId
				, m.deviceSupplierId
				, m.deviceModelId
				, m.vendor
				, m.model
				, m.type
			));
	}

		convertModelToSnData(device: SetSnModel, status: DeviceStatus): SelectSnData {
			let deviceStatus: number;
			let isRecipientCellNumber = (m: SetSnModel) => !m.isPart || (m.isPart && this.isReceiveTransfer);
			return new SelectSnData(
					device.requestDeviceId
					, device.articleNumber
					, <number>status
					, device.isPart
					, device.sn
					, device.quantity
					, isRecipientCellNumber(device) ? device.cellNumber : null
					, isRecipientCellNumber(device) ? null : device.cellNumber
					, device.notFound
					, device.nomenclature
					, device.isAdded
					, device.articleId
					, device.deviceTypeId
					, device.deviceSupplierId
					, device.deviceModelId
					, device.vendor
					, device.model
					, device.type
				);
		}

	//Получение элементов по ng-reflect-name
	getInputById(tag: string, id: string): any {
		let allInputs = document.getElementsByTagName(tag);
		for (let i = 0, n = allInputs.length; i < n; i++) {
			if (allInputs[i].getAttribute("id") !== null
				&& allInputs[i].getAttribute("id") === id) {
				return allInputs[i];
			}
		}
		return null;
	}

	//Проверка на существование и enabled элемента
	isElementExistsAndEnabled(element: any): boolean {
		return element && !element.disabled;
	}

	focusElementIfExistsAndEnabled(element: any): boolean {
		if (this.isElementExistsAndEnabled(element)) {
			element.focus();
			return true;
		} else {
			return false;
		}
	}

	clickElementIfExistsAndEnabled(element: any): boolean {
		if (this.isElementExistsAndEnabled(element)) {
			element.click();
			return true;
		} else {
			return false;
		}
	}

	//Событие на ввод символа в элемент input серийного номера
	onKeyserialNumber(event: any, setSnModel: SetSnModel): void {
		if (this.requestTypeId === <number>RequestType.installCRE || this.requestTypeId === <number>RequestType.changeConfigCRE) {
			setSnModel.isError = !setSnModel.snForValidation.some(x => x === event.target.value);
			return;
		}

		setSnModel.isError = false;
	}
	//Событие на ввод символа в элемент input ячейки
	onKeyCellNumber(setSnModel: SetSnModel): void {
		setSnModel.isCellError = false;
	}
	//Событие на ввод символа в элемент input количества
	onKeypartQuantity(setSnModel: SetSnModel): void {
		setSnModel.isError = false;
	}

	quantityKeyPress(event: KeyboardEvent) {
		const pattern = /[0-9]/;
		if (!pattern.test(event.code)) {
			event.preventDefault();
		}
	}

	//Событие на анфокус элемента input серийного номера
	onFocusOut(setSnModel: SetSnModel, event: any, i: number): void {
		if (this.requestTypeId === <number>RequestType.installCRE || this.requestTypeId === <number>RequestType.changeConfigCRE) {
			setSnModel.isError = !setSnModel.snForValidation.some(x => x === event.target.value);
			return;
		}

		let updateData = (deviceStatus: DeviceStatus) => {
			let selectSnData: SelectSnData[] = [];
			let selectSnDataOne: SelectSnData = null;

			let isRecipientCellNumber = (m: SetSnModel) => !m.isPart || (m.isPart && this.isReceiveTransfer);

			selectSnDataOne = new SelectSnData(setSnModel.requestDeviceId
				, setSnModel.articleNumber
				, <number>deviceStatus
				, setSnModel.isPart
				, setSnModel.sn
				, setSnModel.quantity
				, isRecipientCellNumber(setSnModel) ? setSnModel.cellNumber : null
				, isRecipientCellNumber(setSnModel) ? null : setSnModel.cellNumber);


			selectSnData.push(selectSnDataOne);

			this.devicesService.selectSn(new SelectSn(this.requestId, selectSnData, true, this.isReceiveTransfer)).subscribe(res => {
				if (!res.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: res.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
			});
		};


		if (!this.isReceiveTransfer) {
			let isError = true;

			if (this.warehouseTransferRequestSerialNumberExists) {
				isError = this.checkOneSnValidationTransferDb(setSnModel);
			} else {
				isError = this.checkOneSnValidationAndSet1CArticle(setSnModel, this.isByNomenclature);
			}

			if (!isError) {
				//Обновляем данные в БД
				updateData(DeviceStatus.assembledToMove);
			}
		} else {
			if (this.isWarehouseRefillNotMk
				&& !setSnModel.isAdded) {
				let isError = true;
				isError = this.checkOneSnValidationTransferDb(setSnModel);
				if (!isError) {
					//Обновляем данные в БД
					updateData(DeviceStatus.acceptedAtTheRecipientWarehouse);
				}
			} else {
				this.checkOneSnValidationTransferDb(setSnModel);
			}
		}
	}

	//Событие на анфокус элемента input ячейки
	onCellFocusOut(setSnModel: SetSnModel, event: any, i: number): void {
		let isSnError = true;

		if (!setSnModel.isPart) {
			isSnError = this.checkOneSnValidationTransferDb(setSnModel);
		} else {
			isSnError = this.checkOnePartQuantityValidationTransferDb(setSnModel);
		}

		let isCellError = this.checkOneCellValidationTransferDb(setSnModel);
		let isError = isSnError || isCellError;

		if (!isError) {
			//Обновляем данные в БД
			let selectSnData: SelectSnData[] = [];
			let selectSnDataOne: SelectSnData = null;
			if (this.isReceiveTransfer) {
				selectSnDataOne = new SelectSnData(setSnModel.requestDeviceId
					, setSnModel.articleNumber
					, <number>DeviceStatus.acceptedAtTheRecipientWarehouse
					, setSnModel.isPart
					, setSnModel.sn
					, setSnModel.quantity
					, setSnModel.cellNumber);
			} else {
				selectSnDataOne = new SelectSnData(setSnModel.requestDeviceId
					, setSnModel.articleNumber
					, <number>DeviceStatus.assembledToMove
					, setSnModel.isPart
					, setSnModel.sn
					, setSnModel.quantity
					, null
					, setSnModel.cellNumber);
			}

			selectSnData.push(selectSnDataOne);

			this.devicesService.selectSn(new SelectSn(this.requestId, selectSnData, true, this.isReceiveTransfer)).subscribe(res => {
				if (!res.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: res.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
			});
		}
	}

	//Событие на анфокус элемента input количества
	onPartQuantityFocusOut(setSnModel: SetSnModel, event: any, i: number): void {
		if (this.isReceiveTransfer) {
			this.checkOnePartQuantityValidationTransferDb(setSnModel);
		} else {
			//this.checkOnePartQuantityValidationTransfer1C(setSnModel);
			this.checkOnePartQuantityValidationTransferDb(setSnModel);
		}
	}

	//Событие на нажатие клавиши Enter элемента input ввода серийного номера
	onKeyEnter(setSnModel: SetSnModel, event: any, i: number): void {
		if (this.requestTypeId === <number>RequestType.installCRE || this.requestTypeId === <number>RequestType.changeConfigCRE) {
			setSnModel.isError = !setSnModel.snForValidation.some(x => x === event.target.value);
			return;
		}

		if (this.isReceiveTransfer) {
			let isError = this.checkOneSnValidationTransferDb(setSnModel);

			if (!isError) {
				let nextInputElement = this.getInputById("input", `warehouseCell${i}`);

				if (nextInputElement) {
					nextInputElement.focus();
				} else {
					let approveButton = document.getElementById("approveSnFromDb");
					approveButton.click();
				}
			} else {
				let inputElement = this.getInputById("input", `serialNumber${i}`);
				inputElement.value = null;
			}
		} else {
			let isError = true;

			if (this.warehouseTransferRequestSerialNumberExists) {
				isError = this.checkOneSnValidationTransferDb(setSnModel);
			} else {
				isError = this.checkOneSnValidationAndSet1CArticle(setSnModel, this.isByNomenclature);
			}

			if (!isError) {

				let nextInputElement = this.getInputById("input", `serialNumber${i + 1}`);
				if (this.focusElementIfExistsAndEnabled(nextInputElement)) {
					return;
				}

				let nextQuantityInputElement = this.getInputById("input", `partQuantity${i + 1}`);
				if (this.focusElementIfExistsAndEnabled(nextQuantityInputElement)) {
					return;
				}

				let approveButton = document.getElementById("approveSnFrom1C");
				if (this.clickElementIfExistsAndEnabled(approveButton)) {
					return;
				}

			} else {
				let inputElement = this.getInputById("input", `serialNumber${i}`);
				inputElement.value = null;
			}
		}
	}

	//Событие на нажатие клавиши Enter элемента input ввода номера ячейки
	onCellKeyEnter(setSnModel: SetSnModel, event: any, i: number): void {
		let isError = this.checkOneCellValidationTransferDb(setSnModel);

		if (!isError) {

			let nextSnInputElement = this.getInputById("input", `serialNumber${i + 1}`);
			if (this.focusElementIfExistsAndEnabled(nextSnInputElement)) {
				return;
			}

			let nextQuantityInputElement = this.getInputById("input", `partQuantity${i + 1}`);
			if (this.focusElementIfExistsAndEnabled(nextQuantityInputElement)) {
				return;
			}

			let approveSnFromDbButton = document.getElementById("approveSnFromDb");
			if (this.clickElementIfExistsAndEnabled(approveSnFromDbButton)) {
				return;
			}

			let approveSnFrom1CButton = document.getElementById("approveSnFrom1C");
			if (this.clickElementIfExistsAndEnabled(approveSnFrom1CButton)) {
				return;
			}

		} else {
			let inputElement = this.getInputById("input", `warehouseCell${i}`);
			inputElement.value = null;
		}
	}

	//Событие на нажатие клавиши Enter элемента input ввода количества
	onPartQuantityKeyEnter(setSnModel: SetSnModel, event: any, i: number): void {
		if (this.isReceiveTransfer) {
			let isError = this.checkOnePartQuantityValidationTransferDb(setSnModel);

			if (!isError) {
				let nextInputElement = this.getInputById("input", `warehouseCell${i}`);

				if (nextInputElement) {
					nextInputElement.focus();
				} else {
					let approveButton = document.getElementById("approveSnFromDb");
					approveButton.click();
				}
			} else {
				let inputElement = this.getInputById("input", `partQuantity${i}`);
				inputElement.value = null;
			}
		}
		else {
			// if (this.checkOnePartQuantityValidationTransfer1C(setSnModel)) {
			// 	let inputElement = this.getInputById("input", `partQuantity${i}`);
			// 	inputElement.value = null;
			// 	return;
			// }

			if (this.checkOnePartQuantityValidationTransferDb(setSnModel)) {
				let inputElement = this.getInputById("input", `partQuantity${i}`);
				inputElement.value = null;
				return;
			}

			let nextWarehouseCellElement = this.getInputById("input", `warehouseCell${i}`);
			if (this.focusElementIfExistsAndEnabled(nextWarehouseCellElement)) {
				return;
			}

			let nextSerialNumberElement = this.getInputById("input", `serialNumber${i + 1}`);
			if (this.focusElementIfExistsAndEnabled(nextSerialNumberElement)) {
				return;
			}

			let nextPartQuantityElement = this.getInputById("input", `partQuantity${i + 1}`);
			if (this.focusElementIfExistsAndEnabled(nextPartQuantityElement)) {
				return;
			}

			let approveButton = document.getElementById("approveSnFrom1C");
			if (this.clickElementIfExistsAndEnabled(approveButton)) {
				return;
			}
		}

	}

	resetAutoSelected(): void {
		var mapped = this.setSnModels.filter(x => x.serialnumberMapped);
		this.getSerialNumbersForTransferDtoFrom1C
			.data
			.forEach(mask => {
			mask.isAutoSelected = false;
			mask.articles
				.forEach(article => {
				article.isAutoSelected = false;
				article.serialNumbers
					.filter(x => !mapped.find(y => y.sn === x.serialNumber))
					.forEach(sn => {
					sn.isAutoSelected = false;
				});
			});
		});
	}

	autoSelectSn(): void {
		this.autoSelectSnProcess = true;
		try {					
			this.setSnModels
			.filter(f => !f.serialnumberMapped)
			.forEach(f => {
				f.sn = null;
				f.quantity = null;
			});

			switch (this.setSnModes) {
				case SetSnModes.standart:
					{
						this.resetAutoSelected();

						if (this.warehouseTransferRequestSerialNumberExists) {
							this.setSnModels.filter(f => !f.notFound && !f.serialnumberMapped).forEach(setSnModel => {
								setSnModel.sn = setSnModel.requestSn;
								setSnModel.isError = false;
								setSnModel.cellNumber = !!this.singlecell && !setSnModel.cellNumber ? this.singlecell : setSnModel.cellNumber;
							});
						} else {
							let data1C = this.getSerialNumbersForTransferDtoFrom1C.data;

							this.setSnModels
							.filter(f => !f.serialnumberMapped)
							.forEach(setSnModel => {
								if (setSnModel.isPart) {
									setSnModel.quantity = Math.min(setSnModel.initQuantity, setSnModel.quantityAvailable, setSnModel.quantityDb);
									setSnModel.isError = false;
									if (data1C.length > 0) {
										let articles1C = data1C.map(p => p.articles)
											.reduce((a, b) => a.concat(b), [])
											.filter(x => x.nomenclature === setSnModel.nomenclature);

										if (articles1C.length > 0) {
											let serialNumbers: SerialnumberClass[] = [];

											articles1C.forEach(f => {
												serialNumbers.push(...f.serialNumbers);
											});

											let serialNumber = serialNumbers.length > 0 ? serialNumbers.shift() : null;

											if (!!serialNumber) {
												setSnModel.quantity = Math.min(setSnModel.initQuantity, serialNumber.quantity, setSnModel.quantityDb);
											}
										}
									}								
								} else {
									let articles1C = data1C.map(p => p.articles)
										.reduce((a, b) => a.concat(b), [])
										.filter(x => x.nomenclature === setSnModel.nomenclature && !x.isAutoSelected);
									
									if (articles1C.length > 0) {
										let serialNumbers: SerialnumberClass[] = [];

										articles1C.forEach(f => {
											serialNumbers.push(...f.serialNumbers);
										});

										let serialNumbersNotAutoSelected = serialNumbers.filter(f => !f.isAutoSelected);

										let serialNumber = serialNumbersNotAutoSelected.find(f => this.getDeviceServiceTypeIdByDescriptionSafe(f.deviceServiceTypeName) === this.getDeviceServiceTypeIdSafe(setSnModel.deviceServiceTypeId));

										if (setSnModel.placeholderSn){
											serialNumber =  serialNumbersNotAutoSelected.find(f => this.getDeviceServiceTypeIdByDescriptionSafe(f.deviceServiceTypeName) === this.getDeviceServiceTypeIdSafe(setSnModel.deviceServiceTypeId)
											&& f.serialNumber === setSnModel.placeholderSn);
										}

										if (!!serialNumber) {
											setSnModel.sn = serialNumber.serialNumber;
											setSnModel.isError = false;
											setSnModel.cellNumber = !!this.singlecell && !setSnModel.cellNumber ? this.singlecell : setSnModel.cellNumber;
											serialNumber.isAutoSelected = true;
										}
									}	
									
									articles1C.forEach(f => {
										if (f.serialNumbers.every(e => e.isAutoSelected)) {
											f.isAutoSelected = true;
										}
									});
								}
							});
						}

						if (this.setSnModels
							.filter(f => !f.notFound)
							.every(e => !e.isPart && e.sn === null)) {
							//Ничего не найдено
							this.notificationService.error({
								title: 'Ошибка',
								message: 'Не найдено серийных номеров для автоматического заполения',
								notificationType: NotificationType.SweetAlert
							});
						} else if (this.setSnModels
							.filter(f => !f.notFound)
							.some(s => !s.isPart && s.sn === null)) {
							//Частично не найдено
							this.notificationService.info({
								title: 'Информация',
								message: 'Найдены не все серийные номера для автоматического заполения',
								notificationType: NotificationType.SweetAlert
							});
						}
					}
					break;
				case SetSnModes.awaitingReceive:
					{
						this.setSnModels.forEach(f => {
							f.sn = f.snDb;
							f.quantity = f.quantityDb;
						});
					}
					break;
			}

		} catch (error) {
			this.notificationService.error({
				title: 'Ошибка',
				message: error,
				notificationType: NotificationType.SweetAlert
			});
		}

		var commentModel = new NewCommentModel;
		commentModel.text = 'Серийные номера заполнены автоподбором';
		commentModel.requestId = this.requestId;
		commentModel.commentKind = CommentKindEnum.internal;
		commentModel.activityId = this.activityId;
		this.requestsService.addComment(commentModel).subscribe(() => { 
			this.autoSelectSnProcess = false;
		});
	}

	get autoSelectSnDisabled(): boolean {
		let devices = this.setSnModels.filter(f => !f.isPart);
		return devices.length > 0 && devices.every(e => e.notFound);
	}

	get notSelectedDeviceOrPartExsists(): boolean {
		return this.setSnModels.some(s => (!s.isPart && s.notFound) || (s.isPart && s.quantity < s.quantityDb));
	}

	get notAvailableDeviceOrPartExsists(): boolean {
		return this.setSnModels.some(s => (!s.isPart && s.notFound) || (s.isPart && s.quantityDb > s.quantityAvailable));
	}

	get absentDeviceOrPartDescriptionArray(): { text: string, format: string }[] {
		let res: { text: string, format: string }[] = [];

		switch (this.setSnModes) {
			case <number>SetSnModes.selected:
				{
					if (this.notSelectedDeviceOrPartExsists) {

						// res.push({ text: "Внимание!", format: "i" });
						// res.push({ text: "В заявке осталось не собранное для перемещения оборудование:", format: "i" });

						let notFoundDevices = this.setSnModels.filter(f => (!f.isPart && f.notFound) || (f.isPart && f.quantity < f.quantityDb));
						let devicesOrParts = notFoundDevices.map(m => m.isPart ? `${m.vendor} ${m.model}, ${m.articleMask}; В заявке - ${m.quantityDb}; Собрано - ${m.quantity}` : `${m.vendor} ${m.model}, ${m.articleMask}, ${m.requestSn}`);

						if (!this.warehouseTransferRequestSerialNumberExists) {
							devicesOrParts = notFoundDevices.map(m => m.isPart ? `${m.vendor} ${m.model}, ${m.articleMask}; В заявке - ${m.quantityDb}; Собрано - ${m.quantity}` : `${m.vendor} ${m.model}, ${m.articleMask}`);
						}

						if (this.isByNomenclature) {
							devicesOrParts = notFoundDevices.map(m => m.isPart ? `${m.vendor} ${m.model}, ${m.nomenclature}; В заявке - ${m.quantityDb}; Собрано - ${m.quantity}` : `${m.vendor} ${m.model}, ${m.nomenclature}`);
						}

						devicesOrParts.forEach(f => res.push({ text: f, format: "b" }));

						// res.push({ text: "Вы уверены, что хотите отправить в БЧ результаты сбора в таком состоянии?", format: "i" });
					}
				}
				break;

			case <number>SetSnModes.notEnough:
				{
					if (this.notAvailableDeviceOrPartExsists) {

						// res.push({ text: "Внимание!", format: "i" });
						// res.push({ text: "Для артикула(ов) нет достаточного количества для перемещения:", format: "i" });

						let notFoundDevices = this.setSnModels.filter(f => (!f.isPart && f.notFound) || (f.isPart && f.quantityDb > f.quantityAvailable));
						let devicesOrParts = notFoundDevices.map(m => m.isPart ? `${m.vendor} ${m.model}, ${m.articleMask}; В заявке - ${m.quantityDb}; В наличии на складе - ${m.quantityAvailable}` : `${m.vendor} ${m.model}, ${m.articleMask}, ${m.requestSn}`);

						if (!this.warehouseTransferRequestSerialNumberExists) {
							devicesOrParts = notFoundDevices.map(m => m.isPart ? `${m.vendor} ${m.model}, ${m.articleMask}; В заявке - ${m.quantityDb}; В наличии на складе - ${m.quantityAvailable}` : `${m.vendor} ${m.model}, ${m.articleMask}`);
						}

						if (this.isByNomenclature) {
							devicesOrParts = notFoundDevices.map(m => m.isPart ? `${m.vendor} ${m.model}, ${m.nomenclature}; В заявке - ${m.quantityDb}; В наличии на складе - ${m.quantityAvailable}` : `${m.vendor} ${m.model}, ${m.nomenclature}`);
						}

						devicesOrParts.forEach(f => res.push({ text: f, format: "b" }));

						// res.push({ text: "Продолжить сбор?", format: "i" });
					}
				}
				break;

			default:
				break;
		}

		return res;
	}

	get approveSnFrom1cDisebled(): boolean {
		if (this.setSnModels.every(e => e.notFound)) {
			return this.continueDisabled() || this.disabled1C || this.loading;
		} else {
			return this.continueDisabledAndLoading || this.disabled1C;
		}
	}

	get continueDisabledAndLoading(): boolean {
		return this.validAndLoading || this.continueDisabled();
	}

	get validAndLoading(): boolean {
		return !this.snForm.valid || this.loading;
	}

	continueCollection(): void {
		this.setSnModes = SetSnModes.standart;
	}

	setSizeAndContinue(data: SelectSnData[]): void {
		let deliveryRequest = new DeliveryData();
		if (!!this.request.senderWarehouseAreaId) {
			this.warehousesService.getWarehouseByAreaId(this.request.senderWarehouseAreaId).subscribe(data => {
				deliveryRequest.senderOwner = this.warehouseOwners.find(wo => wo.id === data.contragentOwnerId);
				deliveryRequest.senderAddress = this.warehouseAddresses.find(wo => wo.addressId === data.addressId);
			});
		}

		if (!!this.request.recipientWarehouseAreaId) {
			this.warehousesService.getWarehouseByAreaId(this.request.recipientWarehouseAreaId).subscribe(data => {
				deliveryRequest.recipientOwner = this.warehouseOwners.find(wo => wo.id === data.contragentOwnerId) 
				deliveryRequest.recipientAddress = this.warehouseAddresses.find(wo => wo.addressId === data.addressId);
				deliveryRequest.recipientTransportCompanyLocationCode = data.partnerCode;
			});
		}
		deliveryRequest.track = {} as DeliveryTrack;

		
		const dialogRef = this.dialogService.open({
			content: SetSnSetPackageSizeComponent,
			width: this.isMobileDevice ? '90%' : '70%',
			title: `Настройка груза`,
		});
		
		const form = <SetSnSetPackageSizeComponent>dialogRef.content.instance;
		form.requestId = this.requestId;
		form.onContinueEvent.subscribe((result) => {
			const packages: Package[] = result.map(obj => ({
				weight: obj.weight,
				length: obj.length,
				width: obj.width,
				height: obj.height,
			}));

			var items = [];

			var transportCompany = this.transportCompanies.find(x => x.name.toLowerCase() === this.request.carrierName.toLowerCase());
			if (transportCompany){
				deliveryRequest.track.transportCompanyId = transportCompany.id;
			}

			data.forEach(element => {
				let device = this.setSnModels.find(x => x.nomenclature === element.nomenclature);
				let deviceQuantity = device.quantity ? device.quantity : device.quantityDb;
				let item = new DeliveryItem({
					article: element.nomenclature,
					name: element.serialNumber,
					quantity: element.selectedCount ? element.selectedCount : deviceQuantity
				})
				items.push(item);
			});

			deliveryRequest.externalId = this.integraCode;
			deliveryRequest.packages = packages;
			deliveryRequest.deliveryItems = items;
			deliveryRequest.internalTrackNumber = `${this.request.requestId}-${this.activityId}`;
			this.deliveryService.createDelivery(deliveryRequest).subscribe((result) =>
			{
				this.sendCommand(false, "rsSelected_fromUi", false);
			});
		});
	}


	completeTask(): void {
		this.notificationService.confirmation(
			{
				title: "Перемещение",
				message: "В задании на сбор остались несобранные позиции. Уверены, что хотите завершить задание?",
				showCloseButton: false,
				cancelButtonText: "Отмена",
				confirmButtonText: "Завершить",
				type: 'question',
			}, () => {
				this.setSnModels
					.filter(f => (f.isPart && !!!f.quantity) || (!f.isPart && !!!f.sn))
					.forEach(f => {
						f.notFound = true;
						f.isError = false;
						f.isCellError = false;
					});
			});
	}

	addNewSn() {
		this.newNewSnId--;

		this.setSnModels.push({
			requestDeviceId: this.newNewSnId,
			isPart: false,
			quantity: 0,
			quantityAvailable: 0,
			deviceStatusId: <number>DeviceStatus.acceptedAtTheRecipientWarehouse,
			isAdded: true,
			requireValidation: true,
			notFound: false,
			cellNumber: this.autoSelectedRecipientWarehouseAreaCellNumberField,
		} as SetSnModel);

		setTimeout(() => {
			if (this.standartPerfectScrollbar) {
				this.standartPerfectScrollbar.nativeElement.scrollTop = this.standartPerfectScrollbar.nativeElement.scrollHeight;
			}
		}, 100);
	}

	articlesHandleFilter(value: any): void {
		this.article1cError = null;
		this.articlesFilter = value;
		this.articles = this.articlesSource.filter(
			f => f.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
		);
	}

	addNewArticle(value: any) {
		this.articlesService.checkArticleBy1cAndCreate(this.requestId, value).subscribe(res => {
			if (res.isSuccessful) {
				if (!!res.data.articleId) {
					let elem = { id: res.data.articleId, name: value } as KeyValueObject;
					this.articlesSource.push(elem);

					this.articlesHandleFilter(this.articlesFilter);
				} else {
					this.article1cError = res.data.errorMessage;
				}
			}
		});
	}

	articleChanged(articleId: number, setSnModel: SetSnModel): void {
		this.articlesService.getArticle(articleId).subscribe(res => {
			setSnModel.articleNumber = res.name;
			setSnModel.nomenclature = res.name;
			setSnModel.type = res.deviceTypeName;
			setSnModel.vendor = res.deviceSupplierName;
			setSnModel.model = res.deviceModelName;
			setSnModel.deviceConnectionTypeNameFromArticleMask = res.deviceConnectionTypeName;

			setSnModel.articleId = res.articleId;

			setSnModel.deviceTypeId = res.deviceTypeId;
			setSnModel.deviceSupplierId = res.vendorId;
			setSnModel.deviceModelId = res.modelId;
		});
	}

	get notFoundEqipmentText(): string {
		if (this.isMkInitType) {
			return "В заявке осталось не собранное для перемещения оборудование";
		}

		if (!this.isMkInitType
			&& this.isWarehouseRefillNotMk) {
			return "В заявке осталось не принятое оборудование";
		}
	}

	get notFoundEqipmentQuestion(): string {
		if (this.isMkInitType) {
			return "Вы уверены, что хотите отправить в БЧ результаты сбора в таком состоянии?";
		}

		if (!this.isMkInitType
			&& this.isWarehouseRefillNotMk) {
			return "Вы уверены, что хотите завершить прием?";
		}
	}

	get receivedText(): string {
		return this.isWarehouseRefillNotMk ?
			"Оборудование готово к приему!" :
			"Вы приняли все оборудование для перемещения!";
	}

	get receivedActRequiredText(): string {
		return this.isWarehouseRefillNotMk ?
			"Для завершения приема приложите акт приема-передачи и укажите тип документа" :
			"Для завершения отправки груза приложите акт приема-передачи и укажите тип документа";
	}

	removeNewArticleForm(setSnModel: SetSnModel): void {
		this.setSnModels = this.setSnModels.filter(f => f.requestDeviceId !== setSnModel.requestDeviceId);
	}

	chbNotFoundChanged(setSnModel: SetSnModel): void {
		if (setSnModel.notFound !== null && setSnModel.notFound) {
			setSnModel.isError = false;
			setSnModel.isCellError = false;
		}
	}

	onEnterSerialNumberSetter(event: any){
		let anythingMapped = false;
		let models = this.setSnModels.filter(x => !x.serialnumberMapped && !x.isPart);
		for(let model of models){
			if (this.getSerialNumbersForTransferDtoFrom1C.data !== null
				&& this.getSerialNumbersForTransferDtoFrom1C.data.length > 0) {
				let data1C = this.getSerialNumbersForTransferDtoFrom1C.data;

				let articles1C = data1C.map(p => p.articles)
					.reduce((a, b) => a.concat(b), [])
					.filter(x => x.nomenclature === model.nomenclature);
					
					if (articles1C.length > 0) {
						let serialNumbers: SerialnumberClass[] = [];

						articles1C.forEach(f => {
							serialNumbers.push(...f.serialNumbers);
						});

						let serialNumbersNotAutoSelected = serialNumbers.filter(f => !f.isAutoSelected);
						let serialNumber: SerialnumberClass;
						if (model.placeholderSn){
							serialNumber =  serialNumbersNotAutoSelected.find(f => this.getDeviceServiceTypeIdByDescriptionSafe(f.deviceServiceTypeName) === this.getDeviceServiceTypeIdSafe(model.deviceServiceTypeId)
							&& f.serialNumber === this.serialNumberSetterValue && f.serialNumber === model.placeholderSn);
						} else {
							serialNumber =  serialNumbersNotAutoSelected.find(f => this.getDeviceServiceTypeIdByDescriptionSafe(f.deviceServiceTypeName) === this.getDeviceServiceTypeIdSafe(model.deviceServiceTypeId)
							&& f.serialNumber === this.serialNumberSetterValue);
						}

						if (!!serialNumber) {
							model.sn = serialNumber.serialNumber;
							model.isError = false;
							model.cellNumber = !!this.singlecell && !model.cellNumber ? this.singlecell : model.cellNumber;
							serialNumber.isAutoSelected = true;
							model.serialnumberMapped = true;
							anythingMapped = true;
						
							let device = this.convertModelToSnData(model, <number>DeviceStatus.awaitingAssemblyToMove)
							let data = new SelectSn(this.requestId, [device], false, this.isReceiveTransfer)
							this.saveDevice(data);
							break;
						}
					}	
			}
		}
		this.serialNumberSetterValue = null;

		if (!anythingMapped){
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Не удалось подобрать устройство',
				notificationType: NotificationType.Toast
			});
			this.playSound("../../assets/audio/error-sound.wav");
		} else {
			this.notificationService.success({
				title: 'Успех',
				message: 'Устройство добавлено',
				notificationType: NotificationType.Toast
			});
			this.playSound("../../assets/audio/success-sound.wav");
		}

		if (anythingMapped){
			this.sortModelsByMapped();
		}
	}

	saveDevice(device: SelectSn){
		this.devicesService.selectSn(device).subscribe(res => {
			if (!res.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка',
					message: res.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}
		});
	}

	playSound(path: string): void {
		const audio = new Audio(path);
		audio.load();
		audio.play();
	  }

	public unmapModel(model: SetSnModel){
		if (this.getSerialNumbersForTransferDtoFrom1C.data !== null
			&& this.getSerialNumbersForTransferDtoFrom1C.data.length > 0) {
			let data1C = this.getSerialNumbersForTransferDtoFrom1C.data;
			let articles1C = data1C.map(p => p.articles)
				.reduce((a, b) => a.concat(b), [])
				.filter(x => x.nomenclature === model.nomenclature);
				
			if (articles1C.length > 0) {
				let serialNumbers: SerialnumberClass[] = [];

				articles1C.forEach(f => {
					serialNumbers.push(...f.serialNumbers);
				});
				let serialNumber: SerialnumberClass;

				serialNumber = serialNumbers.find(f => f.serialNumber === model.sn);
				
				if (!!serialNumber) {
					model.sn = null;
					model.serialnumberMapped = false;
					serialNumber.isAutoSelected = false;
				}
			}	
		}

		this.sortModelsByMapped();
	}

	sortModelsByMapped(){
		this.setSnModels.sort((a, b) => 
			{
				if (a.serialnumberMapped !== b.serialnumberMapped) {
					return a.serialnumberMapped ? 1 : -1;
				} else {
					if (a.placeholderSn && b.placeholderSn){
						return a.placeholderSn.localeCompare(b.placeholderSn);
					} else if (a.placeholderSn && !b.placeholderSn) {
						return 1;
					} else {
						return -1
					}
				}
			})
	}

	setPlaceholder(model: SetSnModel){
		if(model.placeholderSn){
			return model.placeholderSn;
		} else {
			return "Введите серийный номер";
		}
	}

	onEnterSerialNumberForRecieveSetter(event: any){
		let anythingMapped = false;
		if (this.recipientWarehouseCellSetterValue == null){
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Выберите ячейку',
				notificationType: NotificationType.SweetAlert
			});
			return;
		}
		let model = this.setSnModels.filter(x => x.placeholderSn).find(x => x.placeholderSn === this.serialNumberSetterValue && !x.serialnumberMapped);
		
		if(model){
			model.sn = model.placeholderSn;
			model.serialnumberMapped = true;
			model.cellNumber = this.recipientWarehouseCellSetterValue;
			model.isError = false;
			anythingMapped = true;

			let device = this.convertModelToSnData(model, <number>DeviceStatus.acceptedAtTheRecipientWarehouse)
			let data = new SelectSn(this.requestId, [device], false, this.isReceiveTransfer)
			this.saveDevice(data);
		}
		this.serialNumberSetterValue = null;

		if (!anythingMapped){
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Не удалось подобрать устройство',
				notificationType: NotificationType.Toast
			});
			this.playSound("../../assets/audio/error-sound.wav");
		} else {
			this.notificationService.success({
				title: 'Успех',
				message: 'Устройство добавлено',
				notificationType: NotificationType.Toast
			});
			this.playSound("../../assets/audio/success-sound.wav");
		}

		this.sortModelsByMapped();
	}

	autoRecieve(){
		this.isAutorecieving = true;
		if (this.recipientWarehouseCellSetterValue == null){
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Выберите ячейку',
				notificationType: NotificationType.SweetAlert
			});
			this.isAutorecieving = false;
			return;
		}
		let devices = this.setSnModels.filter(x => !x.isPart && !x.serialnumberMapped);
		let parts = this.setSnModels.filter(x => x.isPart && !x.serialnumberMapped);

		for(let model of devices){
			model.sn = model.placeholderSn;
			model.cellNumber = this.recipientWarehouseCellSetterValue;
		}
		for(let model of parts){
			model.cellNumber = this.recipientWarehouseCellSetterValue;
			model.quantity = model.quantityDb;
		}
		this.sortModelsByMapped();

		if (this.setSnModels
			.filter(f => !f.notFound)
			.every(e => !e.isPart && e.sn === null)) {
			//Ничего не найдено
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Не найдено серийных номеров для автоматического приема',
				notificationType: NotificationType.SweetAlert
			});
		} else if (this.setSnModels
			.filter(f => !f.notFound)
			.some(s => !s.isPart && s.sn === null)) {
			//Частично не найдено
			this.notificationService.info({
				title: 'Информация',
				message: 'Найдены не все серийные номера для автоматического приема',
				notificationType: NotificationType.SweetAlert
			});
		}
		
		var commentModel = new NewCommentModel;
		commentModel.text = 'Оборудование принято автоприемом';
		commentModel.requestId = this.requestId;
		commentModel.commentKind = CommentKindEnum.internal;
		commentModel.activityId = this.activityId;
		commentModel.engineerNotifyWays = null;
		this.requestsService.addComment(commentModel).subscribe(() => {
			this.isAutorecieving = false;
		 });
	}
	public unmapRecieveModel(model: SetSnModel){
		if(model){
			model.sn = null;
			model.serialnumberMapped = false;
			model.cellNumber = null;
		}

		this.sortModelsByMapped();
	}

	private loadTransportCompanies(): void {
		this.transportCompanyService.list().subscribe(data => {
			this.transportCompanies = data;
		})
	}
}
