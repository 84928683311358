import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { KeyValueObject } from "../../../../../shared/models/core/KeyValueObject";
import { ExpertiseActDevice } from "../../../../../shared/models/expertise-act/expertise-act-device";
import { ExpertiseActSetting } from "../../../../../shared/models/expertise-act/expertise-act-setting";

@Component({
	selector: 'expertise-form-dropdown',
	templateUrl: './expertise-form-dropdown-list.component.html',
	styleUrls: []
})
export class ExpertiseFormDropdownList implements  OnInit {

	@Input() tabName: string;
	@Input() formData: ExpertiseActSetting;
	@Input() device: ExpertiseActDevice;
	@Input() selectFormFilledData: any;

	@Output() onChange = new EventEmitter<any>();

	selectedItem: number = null;
	listData: KeyValueObject[] = [];

	isTSType: boolean = false;
	deviceTypeId: number = null;

	costructor() {

	}

	ngOnInit(): void {
		this.listData = this.formData.options
			.map(option => { return { id: option.optionId, name: option.value } as KeyValueObject; });

		const filledId = this.selectFormFilledData[this.tabName][this.formData.name];
		if (filledId) {

			this.selectedItem = filledId;

		} else {

			const filledForms = this.device.filledForms.filter(x => x.option.settingId === this.formData.settingId);
			if (filledForms.length > 0) {
				this.selectedItem = filledForms[0].optionId;
			}

			if (this.formData.name === 'Чек') {
				const options = this.formData.options.filter(x => x.value === 'Чек не печатается');
				if (options.length > 0) {
					this.selectedItem = options[0].optionId;
					this.onChangeSelect(this.selectedItem);
				}
			}

		}

		this.isTSType = this.formData.name === 'Тип ТО';
		this.setDeviceTypeId();
	}

	onChangeSelect(value: any): void {
		this.setDeviceTypeId();
		if (this.onChange) {
			this.onChange.emit(({tabName: this.tabName, formData: this.formData, value: value}) as any);
		}
	}

	private setDeviceTypeId(): void {
		if (this.formData.name === 'Тип ТО' && this.selectedItem) {
			this.deviceTypeId = this.formData.options.filter(x => x.optionId === this.selectedItem)[0].deviceTypeId;
		}
	}

}
