import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "../../core/services/data.service";
import { NotificationService } from "../../core/services/notification.service";
import { ServiceModeItem } from "../models/services/service-mode-item";

@Injectable()
export class ServiceModesService extends DataService<ServiceModeItem, ServiceModeItem> {

	constructor(
		protected http: HttpClient,
		protected notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'service-modes';
	}

}
