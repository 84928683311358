export class WarehouseContact {
    warehouseContactId: number;
    warehouseId: number;
    contactId: number;
    phone: string;
    extPhone: string;
    lastName: string;
    firstName: string;
    middleName: string;
    forSmz: boolean;
    contragentId: number;
    typeId: number;
}
