export enum RequestStatus {
	/** Зарегистрирована */
	Registered = 1,

	/** Обрабатывается */
	InProgress = 2,

	/** Загрузка конфигурации */
	LoadConfiguration = 3,

	/** Подготовка оборудования */
	DevicePreparation = 4,

	/** Назначено на СЦ */
	Assigned = 5,

	/** Выполнение работ */
	InWork = 6,

	/** Работы выполнены */
	WorkCompleted = 7,

	/** На проверке */
	Verification = 8,

	/** Выполнена */
	Completed = 9,

	/** Отменена */
	Cancelled = 10,

	/** Остановлена */
	Stopped = 11,

	/** Назначено на инженера */
	AssignedToEngineer = 12,

	/** Назначено на склад */
	AssignedToWarehouse = 13,

	/** Назначено на кладовщика */
	AssignedToStorekeeper = 14,

	/** Назначена на СП */
	AssignToSp = 15,

	/** Прием оборудования кладовщиком */
	acceptanceDeviceByStorekeeper = 16,

	/** Принято оборудование кладовщиком */
	acceptanceDeviceByStorekeeperCompleted = 17,

	/** Ожидает сбора для перемещения */
	awaitingCollectionForMoving = 20,

	/** Сбор для перемещения */
	collectionForMoving = 21,

	/** Готово к отправке в ТК */
	readyToShipToCarrier = 27,

	/** Отправлено в ТК */
	sentToTk = 28,

	/** Отмена (возврат на склад) */
	CancelledBackToWareHouse = 29,

	/** Ожидает согласования */
	pendingApproval = 33,

	/** Ожидает подтверждения из БЧ */
	awaitingConfirmationFromEth = 34,

	/** Открытая публикация */
	openPublication = 38,

	/** Ожидается оприходование ТО */
	DevicePostingExpecting = 49,

	/** Подготовка конфигурации */
	ConfigurationPreparing = 50,
}
