import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { RequestListItem } from '../models/request/request-list-item';
import { Request } from '../models/request/request';
import { RequestComment } from '../models/request/request-comment';
import { Observable } from 'rxjs';
import { NotificationType } from '../../core/services/notification-type';
import { ActionResult } from '../models/core/ActionResult';
import { tap, filter, take } from 'rxjs/operators';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { NewRequestDevice } from '../models/request/new-request/new-request-device';
import { Activity } from '../models/activity/activity';
import { UploadFile } from '../models/attachment/upload-file';
import { RequestChangeLog } from "../models/request/request-change-log";
import { NewRequestService } from '../models/request/new-request/new-request-service';
import { KeyValueObject } from '../models/core/KeyValueObject';
import { MultipleError } from '../models/request/multiple-error';
import { ChangeStatusModel } from '../models/change-status-models/change-status-model';
import { ChangePerformerUser } from '../models/request/change-performer-user';
import { CustomerContact } from '../models/request/customer-contact';
import { Call } from '../models/call/call';
import { CompositeFilterDescriptor, DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { ListViewModel } from '../models/core/ListViewModel';
import { SelectedArticleNumberDto } from '../models/request/selected-article-number';
import { MapRequestDto } from '../models/request/map-request';
import { RequestNotification } from '../models/request/request-notification';
import { KendoDataSourceHelper } from '../../core/helpers/kendo-data-source-helper';
import { RequestGroupDto } from '../models/request/request-group-dto';
import { RequestDeviceWarehouseTransferSummaryDto } from '../models/request/request-device-warehouse-transfer-summary-dto';
import { RequestSlaStepDto } from '../models/request/request-sla-step-dto';
import { CustomerObjectInfo } from '../models/customerObject/customer-object-info';
import { NewCommentModel } from '../models/request/comments/new-comment-model';
import { CommentKind } from '../models/request/comments/comment-kind';
import { UpdateRequestCustomCommand } from '../models/update-request-custom/update-request-custom.command';
import { PerformerGroupModel } from '../models/change-status-models/performer-group-model';
import { GetRequestTypeName } from '../models/request/request-type-name';
import { ReportExampleFileDataDto } from '../models/reports/report-example-file-data.dto';
import { SetPlannedDateModel } from '../components/workflow-transitions/set-planned-date/models/SetPlannedDateModel';
import { ValidateAndSavePlannedDateDto } from '../components/workflow-transitions/set-planned-date/models/ValidateAndSavePlannedDateDto';
import { CheckChangeStatusToOpenPublicationResult } from '../models/request/check-change-status-to-open-publication-result';
import { CanChangeServiceCenterResponse } from '../models/request/can-change-service-center-response';


@Injectable()
export class RequestsService extends DataService<RequestListItem, Request> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService,
		private kendoDataSourceHelper: KendoDataSourceHelper) {
		super(http, notificationService);
		this.controllerName = 'requests';
	}

	public requestList(state: DataSourceRequestState, visibleColumnNames: string[]): Observable<ListViewModel<RequestListItem>> {
		var queryStr = `${toDataSourceRequestString(state)}`;

		//хак, это ошибка компонента грид
		queryStr = queryStr.replace(/T\d\d-00-0/g, 'T00-00-00');

		return this.http.post<ActionDataResult<ListViewModel<RequestListItem>>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/list`, {
			state: queryStr,
			visibleColumnNames: visibleColumnNames
		})
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;

	}

	public changePerformerUserMultiple(model: ChangePerformerUser[]): Observable<ActionDataResult<MultipleError[]>> {
		return this.http.post<ActionDataResult<MultipleError[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-performer-user-multiple`, model)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка массового изменения исполнителей',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка массового изменения исполнителей',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}

	public changeStatusMultiple(model: ChangeStatusModel[]): Observable<ActionDataResult<MultipleError[]>> {
		return this.http.post<ActionDataResult<MultipleError[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-status-multiple`, model)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка массового изменения статусов',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка массового изменения статусов',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}

	public changePerformerUserGroupMultiple(model: PerformerGroupModel[]): Observable<ActionDataResult<MultipleError[]>> {
		return this.http.post<ActionDataResult<MultipleError[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-performer-group-multiple`, model)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка массового изменения группы ответственного',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка массового изменения группы ответственного',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}

	public sendStateWithLogic(model: any): Observable<ActionResult> {
		return this.http.post<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/send-state-with-logic`, model)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка отправки изменения состояния для заявки в БЧ с логикой',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка отправкиизменения состояния для заявки в БЧ с логикой',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}));
	}

	public notify1CAboutWarehouseTransfer(model: any): Observable<ActionDataResult<SelectedArticleNumberDto[]>> {
		return this.http.post<ActionDataResult<SelectedArticleNumberDto[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/notify-1c-about-warehouse-receive`, model)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка отправки сообщения о перемещении в 1С',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка отправки сообщения о перемещении в 1С',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}));
	}

	public updateRequestCustom(model: UpdateRequestCustomCommand): Observable<ActionResult> {
		return this.http.post<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/update-request-custom`, model)
			.pipe(tap(resp => {

				if (resp.isSuccessful) {
					this.notificationService.success({
						title: 'Обновления заявки прошло успешно!',
						message: 'Изменения сохранены успешно!',
						notificationType: NotificationType.Toast
					});
				} else {
					this.notificationService.error({
						title: 'Ошибка при изменении полей заявки',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при изменении полей заявки',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}));
	}

	public changeStatus(model: any): Observable<ActionResult> {
		return this.http.post<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-status`, model)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при изменении статуса',
						message: resp.errorDescription,
						notificationType: this.notificationService.getNotificationTypeByExceptionCode(resp.code)
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при изменении статуса',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}

	public addComment(commentModel: NewCommentModel): Observable<HttpEvent<any>> {

		return this.http.post(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/add-comment`, commentModel, {
			reportProgress: true,
			observe: "events"
		}).pipe();



		// return this.http.post(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/add-comment`, {
		// 		requestId: requestId,
		// 		activityId: activityId,
		// 		text: text,
		// 		uploadAttachments: uploadAttachments
		// 	}, { reportProgress: true, observe: "events" })
		// 	.pipe(
		// 		map(event => this.getEventMessage(event)),
		// 		tap(resp => {

		// 		if (!resp.isSuccessful) {
		// 			this.notificationService.error({
		// 				title: 'Ошибка при отправке комментария',
		// 				message: resp.errorDescription,
		// 				notificationType: NotificationType.Toast
		// 			});
		// 		}

		// 	}, errorResponse => {

		// 		if (errorResponse.status === 904)
		// 			return;

		// 		this.notificationService.error({
		// 			title: 'Ошибка при отправке комментария',
		// 			message: errorResponse.error.errorDescription,
		// 			notificationType: NotificationType.Toast
		// 		});

		// 	}));
	}

	// private getEventMessage(event: HttpEvent<any>, file: File) {
	// 	switch (event.type) {
	// 	  case HttpEventType.UploadProgress:
	// 		// Compute and show the % done:
	// 		const percentDone = Math.round(100 * event.loaded / (event.total ?? 0));
	// 		return `File "${file.name}" is ${percentDone}% uploaded.`;
	// 	}
	//   }

	public getRequestDeviceWarehouseTransferSummary(requestId: number): Observable<RequestDeviceWarehouseTransferSummaryDto[]> {

		var url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/request-device-warehouse-transfer-summary`;

		return this.http.get<ActionDataResult<RequestDeviceWarehouseTransferSummaryDto[]>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public getComments(requestId: number, activityId: number, isMobile: boolean = false, includeAllRequestComments: boolean = false): Observable<RequestComment[]> {

		var url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/comments?activityId=${activityId}&isMobile=${isMobile}&includeAllRequestComments=${includeAllRequestComments}`;

		// if (activityId){
		// 	url += `?activityId=${activityId}`;
		// }

		return this.http.get<ActionDataResult<RequestComment[]>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	getCommentsExcel(requestId: number, activityId?: number, isMobile: boolean = false): Observable<ActionDataResult<UploadFile>> {
		return this.postQuery<UploadFile>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/comments-excel`, {
			requestId: requestId,
			activityId: activityId,
			isMobile: isMobile
		}, null, false);
	}


	public getRequestGroup(requestId: number): Observable<RequestGroupDto[]> {
		var url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/group`;

		return this.http.get<ActionDataResult<RequestGroupDto[]>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public getNotifications(requestId: number): Observable<RequestNotification[]> {
		var url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/notifications`;

		return this.http.get<ActionDataResult<RequestNotification[]>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public markAsReadRequestNotifications(requestId): Observable<ActionResult> {
		return this.http.post<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/notifications/read`, {
			requestId: requestId,
			actionToDo: 'Кнопка'
		})
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при отправке комментария',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при отправке комментария',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}));
	}

	public getActivities(requestId: number): Observable<Activity[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/activities`);
	}

	public getCustomerContacts(requestId: number): Observable<CustomerContact[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/customer-contacts`);
	}

	public getChangeLog(requestId: number): Observable<RequestChangeLog[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/change-log`);
	}

	public addChangeLog(request: RequestChangeLog): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-log`, request);
	}

	public getRequestSlaSteps(requestId: number): Observable<RequestSlaStepDto[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/sla-steps`);
	}

	public linkDevice(requestDeviceId: number, deviceId: number): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/link-device`, {
			requestDeviceId: requestDeviceId,
			deviceId: deviceId
		});
	}

	public linkPinpad(requestDeviceId: number, pinpadDeviceId: number): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/link-pinpad`, {
			requestDeviceId: requestDeviceId,
			pinpadDeviceId: pinpadDeviceId
		});
	}

	public getRequestDevices(requestId: number): Observable<NewRequestDevice[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/request-devices`);
	}

	public getRequestDevicesByTid(requestId: number): Observable<NewRequestDevice[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/request-devices-by-tid`);
	}

	public getRequestUninstallDevices(requestId: number): Observable<NewRequestDevice[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/request-uninstall-devices`);
	}

	public requestSc(requestId: number): Observable<number> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/request-sc?requestId=${requestId}`);
	}

	public requestContragent(requestId: number): Observable<number> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/request-contragent?requestId=${requestId}`);
	}

	public getRequestServices(requestId: number): Observable<NewRequestService[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/request-cervices`);
	}

	public changePerformerGroup(requestId: number, newPerformerGroupId: number, requestChangeLogComment: string): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-performer-group`, {
			requestId: requestId,
			performerGroupId: newPerformerGroupId,
			requestChangeLogComment: requestChangeLogComment
		}, "Группа исполнителя успешно изменена");
	}

	public changePerformerUser(requestId: number, newPerformerUserId?: number): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-performer`, {
			requestId: requestId,
			performerUserId: newPerformerUserId
		}, "Ответственный успешно изменен");
	}

	public changeReconciliationCompleted(requestId: number, value: boolean): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-reconciliation`, {
			requestId: requestId,
			value: value
		});
	}

	public getRequestCalls(requestId: number, state: DataSourceRequestState): Observable<ListViewModel<Call>> {

		var queryStr = `${toDataSourceRequestString(state)}`;

		//хак, это ошибка компонента грид
		queryStr = queryStr.replace(/T00-00-0/g, 'T00-00-00');
		queryStr = queryStr.replace(/T03-00-0/g, 'T00-00-00');

		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/calls?${queryStr}`);
	}

	public getFilterLookupData(filterLookupName: string): Observable<KeyValueObject[]> {

		return this.http.get<ActionDataResult<KeyValueObject[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/filter-lookups/${filterLookupName}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public getMapRequests(compositeFilter: CompositeFilterDescriptor): Observable<ListViewModel<MapRequestDto>> {

		const queryStr = this.kendoDataSourceHelper.toQueryString(compositeFilter);
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/map?${queryStr}`;

		return this.http.get<ActionDataResult<ListViewModel<MapRequestDto>>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public loadFinancialReconciliation(file: File): Observable<ActionResult> {
		const formData: FormData = new FormData();
		formData.append('financialReconciliationFile', file, file.name);
		return this.http.post<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/load-financial-reconciliation`, formData)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при загрузки файла фин. сверки',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при загрузки файла фин. сверки',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}

	public slaDates(): Observable<string[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/sla-dates`);
	}


	public checkNewFinishFactDate(requestId: number, newFinishDateFact: Date): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/check-new-finish-fact`, {
			requestId: requestId,
			newFinishDateFact: newFinishDateFact
		}, "", false);
	}

	public customerObjectInfo(customerObjectId: number): Observable<CustomerObjectInfo> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/customer-object-info?customerObjectId=${customerObjectId}`);
	}

	public getRequestIdsByCustomerObjectIfEngineerNotArrived(customerObjectId: number): Observable<number[]> {
		var url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/request-ids-by-customer-object?customerObjectId=${customerObjectId}`;

		return this.http.get<ActionDataResult<number[]>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public commentKinds(): Observable<CommentKind[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/comment-kinds`);
	}

	public changeFinishDateFact(requestId: number, finishDateFact: Date, force: boolean = false): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-finish-date-fact`, {
			requestId: requestId,
			finishDateFact: finishDateFact,
			force: force
		}, "Дата фактического завершения работ изменена");
	}

	public processRequestByAlias(requestId: number, alias: string): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/process-request-by-alias`, {
			requestId: requestId,
			alias: alias
		}, "Заявка обработана");
	}

	public checkNeedAddressChangeUserConfirmation(requestId:number, newFullAddress:string):Observable<boolean>{
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/check-need-address-change-user-confirmation?requestId=${requestId}&newFullAddress=${newFullAddress}`);
	}

	public calculateAndSetPrice(requestId:number):Observable<ActionDataResult<number>>{
		return this.postQuery<number>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/calculate-and-set-price`, {requestId: requestId});
	}

	public getRequestTypeNames(ids: number[]): Observable<ActionDataResult<GetRequestTypeName[]>> {
		return this.postQuery<GetRequestTypeName[]>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-request-type-names`, {ids: ids});
	}

	public downloadFinancialReconciliationTemplate(): Observable<ActionDataResult<ReportExampleFileDataDto>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/download-financial-reconciliation-template`;
		return this.http.get<ActionDataResult<ReportExampleFileDataDto>>(url)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка при скачивании файла',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка при скачивании файла',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}

	public shortcutReturnEquipment(activityId: number, activityStatusToId: number = null): Observable<ActionResult> {
		return this.postQuery<GetRequestTypeName[]>(
			`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/shortcut-return-equipment`, 
			{activityId: activityId, activityStatusToId: activityStatusToId},
			null,
			true,
			NotificationType.SweetAlert
		);
	}

	public validateAndSavePlannedDate(model: SetPlannedDateModel): Observable<ActionDataResult<ValidateAndSavePlannedDateDto>> {
		return this.postQuery<ValidateAndSavePlannedDateDto>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/validate-and-save-planned-date`, model);
	}

	public checkChangeStatusToOpenPublication(requestId: number, newStatusId: number): Observable<ActionDataResult<CheckChangeStatusToOpenPublicationResult>> {
		return this.postQuery<CheckChangeStatusToOpenPublicationResult>(
			`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/check-change-status-to-open-publication`, {
				requestId: requestId,
				newStatusId: newStatusId
			}
		);
	}

	
	public canChangeServiceCenter(requestId: number): Observable<ActionDataResult<CanChangeServiceCenterResponse>> {
		return this.postQuery<CanChangeServiceCenterResponse>(
			`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/can-change-service-center`, {
				requestId: requestId
			}
		);
	}
}
