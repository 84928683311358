import { Component, Input, OnInit } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { addDays } from '@progress/kendo-date-math';
import { AppService } from '../../../app.service';
import { NotificationType } from '../../../core/services/notification-type';
import { NotificationService } from '../../../core/services/notification.service';
import { ListViewModel } from '../../models/core/ListViewModel';
import { GridSettings } from '../../models/grid/grid-settings';
import { GetRequestsForCheckQuery } from '../../models/request-registration-filter/get-requests-for-check-query';
import { RequestsForCheckDto } from '../../models/request-registration-filter/requests-for-check-dto';
import { SendNewRequestsFromGateToRabbitCommand } from '../../models/request-registration-filter/send-new-requests-from-gate-to-rabbit-command';
import { RequestRegistrationFiltersService } from '../../services/request-registration-filters.service';
import { StatePersistingService } from '../../services/state-persisting.service';
import { PersistenceGridFeatures } from '../grid/persistence-grid-features/persistence-grid-features';

@Component({
	selector: 'automatic-registration-requests-grid',
	templateUrl: './automatic-registration-requests-grid.component.html',
	styleUrls: [
		'./automatic-registration-requests-grid.component.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})

export class AutomaticRegistrationRequestsGridComponent extends PersistenceGridFeatures implements OnInit {

	@Input()
	requestRegistrationFilterId: number;

	@Input()
	canEditForm: boolean;

	elementCount: number = 0;

	diasbleAddButton: boolean = false;

	public range = { start: addDays(new Date(), -2), end: new Date() };

	gridHeight: number;

	data: RequestsForCheckDto[] = [];

	listViewModel: ListViewModel<RequestsForCheckDto> = new ListViewModel<RequestsForCheckDto>();

	loadingData: boolean;

	isRegistrationMode: boolean;

	selectable: any = { enabled: false, checkboxOnly: true };

	gridDataFull: RequestsForCheckDto[] = [];

	gridSelectedRequests: RequestsForCheckDto[] = [];

	public requestsGridGridSelectedCallback = (args) => args.dataItem;

	public enableSaveGridSettings: boolean = true;

	gridSettingsStorageKey: string = 'automatic-registration-requests-grid';

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'externalId', dir: 'asc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'rowIndex',
				title: '№ пп',
				filter: 'string',
				filterable: false,
				width: 100,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'publishedDate',
				title: 'Дата и время публикации на бирже',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm:ss',
				filterable: false,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'externalId',
				title: 'Внешний номер',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'requestTypeName',
				title: 'Тип заявки',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'category',
				title: 'Категория обслуживания',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'requestStatusName',
				title: 'Статус заявки',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'requestStatusEthName',
				title: 'Статус БЧ',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'region',
				title: 'Регион заявки',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'city',
				title: 'Город ТСП',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 9,
				field: 'merchantLegalName',
				title: 'Юрлицо ТСП',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 10,
				field: 'merchantAddress',
				title: 'Адрес ТСП',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 11,
				field: 'warehouseRegionName',
				title: 'Регион склада',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 12,
				field: 'warehousePartnerCode',
				title: 'Идентификатор склада',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 13,
				field: 'warehouseOwner',
				title: 'Владелец склада',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 14,
				field: 'serviceNames',
				title: 'Услуги',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 15,
				field: 'distanceKm',
				title: 'Удаленность объектов от границы города расположения СЦ\\склада, км.',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 16,
				field: 'timeSpentOnExchangeAsString',
				title: 'Время нахождения заявки на бирже (часы, минуты, секунды)',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 17,
				field: 'directPrice',
				title: 'Директивная стоимость',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 18,
				field: 'maxPrice',
				title: 'Максимальная биржевая стоимость',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 19,
				field: 'currentPrice',
				title: 'Фактическая биржевая стоимость',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 20,
				field: 'inn',
				title: 'ИНН (компании, СМЗ), которые забрали заявку',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 21,
				field: 'noteFromTheCustomer',
				title: 'Примечание от заказчика',
				filter: 'string',
				filterable: false,
				width: 250,
				hidden: false
			}
		]
	}

	constructor(
		protected notificationService: NotificationService,
		protected appService: AppService,
		protected persistingService: StatePersistingService,
		protected dataService: RequestRegistrationFiltersService
	) {
		super(appService, persistingService);
	}

	public loadGridData(): void {

	}

	ngOnInit() {
		super.ngOnInit();

		this.gridHeight = window.innerHeight - 108;

		this.listViewModel.data = [];
		this.listViewModel.total = 0;
	}

	public onExcelExport(e: any): void {

		function compare(a: any, b: any) {
			if (a.orderIndex < b.orderIndex) {
				return -1;
			}
			if (a.orderIndex > b.orderIndex) {
				return 1;
			}
			return 0;
		}

		const rows = [{
			type: 'header',
			level: null,
			cells: this.gridSettings.columnsConfig
				.filter(f => !f.hidden)
				.sort(compare)
				.map((columnData, _) => {
					return { value: columnData.title, background: '#DCDCDC' }
				})
		}];

		e.workbook.sheets[0].rows = this.data.map((item, _) => {
			rows.push({
				type: 'data',
				level: null,
				cells: this.gridSettings.columnsConfig
					.filter(f => !f.hidden)
					.sort(compare)
					.map((columnData, _) => {
						if (columnData.field === 'publishedDate') {
							return { value: item['publishedDateStringMoscow'], background: null }
						} else {
							let val = item[columnData.field];

							if (Array.isArray(val)) {
								val = val.join('; ');
							}

							return { value: val, background: null }
						}
					})
			});
		});

		for (let i = 1; i < rows.length; i++) {
			const row = rows[i];
			row.cells[0].value = `${i}`;
		}

		e.workbook.sheets[0].rows = rows;
	}

	public dataStateChange(state: DataStateChangeEvent): void {
		this.gridSettings.state = state;

		const filteredData = this.filterData(this.data, state);
		this.listViewModel.total = filteredData.length;

		this.listViewModel.data = this.sortData(filteredData, state).slice(state.skip, state.skip + state.take);
	}

	public updateData(data: RequestsForCheckDto[]): void {
		this.data = data;

		const state = this.gridSettings.state;
		this.dataStateChange({
			skip: state.skip,
			take: state.take,
			filter: state.filter,
			sort: state.sort
		});
	}

	private filterData(data: RequestsForCheckDto[], state: DataStateChangeEvent): RequestsForCheckDto[] {
		state.filter.filters.forEach(filterDesriptor => {
			if ((<FilterDescriptor>filterDesriptor) !== undefined) {

				const filter = (<FilterDescriptor>filterDesriptor);
				if ((<string>filter.value) !== undefined && filter.operator === 'contains') {
					data = data.filter(x => this.getFieldValue(x, filter.field as string).toLowerCase().includes(filter.value.toLowerCase()));
				} else if ((<Date>filter.value) !== undefined && filter.operator === 'gte') {
					data = data.filter(x => {
						const itemDateValue = this.getFieldValue(x, filter.field as string);
						return itemDateValue === null ? false : new Date(itemDateValue) >= new Date(filter.value);
					});
				} else if ((<Date>filter.value) !== undefined && filter.operator === 'lte') {
					data = data.filter(x => {
						const itemDateValue = this.getFieldValue(x, filter.field as string);
						return itemDateValue === null ? false : new Date(itemDateValue) <= new Date(filter.value);
					});
				}

			} else if ((<CompositeFilterDescriptor>filterDesriptor) !== undefined) {

				const filterDescriptor = (<CompositeFilterDescriptor>filterDesriptor);
				data = this.filterData(data, {
					skip: state.skip,
					take: state.take,
					filter: filterDescriptor
				});

			}
		});

		return data;
	}

	private sortData(data: RequestsForCheckDto[], state: DataStateChangeEvent): RequestsForCheckDto[] {
		state.sort.forEach(sortDescriptor => {
			const fieldType = this.gridSettings.columnsConfig.filter(x => x.field === sortDescriptor.field)[0].filter;
			if (fieldType === 'string') {
				data = this.sortByString(sortDescriptor, data);
			} else if (fieldType === 'date') {
				data = this.sortByDate(sortDescriptor, data);
			}
		});

		return data;
	}

	private sortByString(sortDescriptor: SortDescriptor, data: RequestsForCheckDto[]): RequestsForCheckDto[] {
		const compareAscFunc = (current, next) => {
			const currentFieldValue: string = this.getFieldValue(current, sortDescriptor.field);
			const nextFieldValue: string = this.getFieldValue(next, sortDescriptor.field);
			if (!!nextFieldValue) {
				return nextFieldValue.localeCompare(currentFieldValue);
			}
		};

		const compareDescFunc = (current, next) => {
			const currentFieldValue: string = this.getFieldValue(current, sortDescriptor.field);
			const nextFieldValue: string = this.getFieldValue(next, sortDescriptor.field);
			if (!!currentFieldValue) {
				return currentFieldValue.localeCompare(nextFieldValue);
			}
		};

		return sortDescriptor.dir === 'asc'
			? data.sort((current, next) => compareAscFunc(current, next))
			: data.sort((current, next) => compareDescFunc(current, next));
	}

	private sortByDate(sortDescriptor: SortDescriptor, data: RequestsForCheckDto[]): RequestsForCheckDto[] {
		const compareAscFunc = (current, next) => {
			const currentFieldValue: Date = this.getFieldValue(current, sortDescriptor.field);
			const nextFieldValue: Date = this.getFieldValue(next, sortDescriptor.field);
			return currentFieldValue > nextFieldValue ? 1 : -1;
		};

		const compareDescFunc = (current, next) => {
			const currentFieldValue: Date = this.getFieldValue(current, sortDescriptor.field);
			const nextFieldValue: Date = this.getFieldValue(next, sortDescriptor.field);
			return currentFieldValue > nextFieldValue ? -1 : 1;
		};

		return sortDescriptor.dir === 'asc'
			? data.sort((current, next) => compareAscFunc(current, next))
			: data.sort((current, next) => compareDescFunc(current, next));
	}

	private getFieldValue(item: RequestsForCheckDto, fieldName: string): any {
		return item[fieldName];
	}

	parseDate(str) {
		var mdy = str.split('/');
		return new Date(mdy[2], mdy[0] - 1, mdy[1]);
	}

	datediff(first, second) {
		// Take the difference between the dates and divide by milliseconds per day.
		// Round to nearest whole number to deal with DST.
		return Math.round((second - first) / (1000 * 60 * 60 * 24));
	}

	checkRequests(): void {

		this.listViewModel.data = [];
		this.listViewModel.total = 0;
		this.gridDataFull = [];

		this.isRegistrationMode = false;
		this.registrationModeChanged(false);
		let dateEnd = new Date(this.range.end);
		dateEnd = addDays(dateEnd, 1);

		const startDate = this.getDate(this.range.start);
		const endDate = this.getDate(dateEnd);

		let days = this.datediff(this.range.start, this.range.end);

		if (days > 14) {
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Выбранный диапазон дат не может быть более 14 дней',
				notificationType: NotificationType.SweetAlert
			});

			return;
		}

		const startDateName = this.range.start.toLocaleDateString().replace(',', '');
		const endDateName = this.range.end.toLocaleDateString().replace(',', '');

		this.loadingData = true;
		this.dataService.requestsForCheck(new GetRequestsForCheckQuery(this.requestRegistrationFilterId, startDate, endDate)).subscribe(res => {
			if (res.isSuccessful && !!res.data && res.data.length > 0) {
				this.gridDataFull = res.data;
				this.updateData(res.data);
			} else {
				this.updateData([]);
			}
			this.loadingData = false;
		});
	}

	getDate(date) {
		return new Date(date).toJSON();
	}

	registerSelected(): void {
		this.loadingData = true;
		let sutsRequestNums = this.gridSelectedRequests.map(m => m.externalId);
		this.gridSelectedRequests = [];
		this.dataService.sendNewRequestsFromGateToRabbit(
			new SendNewRequestsFromGateToRabbitCommand(true, sutsRequestNums)).subscribe(res => {
				if (res.isSuccessful) {
					this.notificationService.success({
						title: 'Успех',
						message: 'Задание на регистрацию заявок отправлено',
						notificationType: NotificationType.SweetAlert
					});
				}
				this.loadingData = false;
			});
	}

	registrationModeChanged(val: boolean): void {
		this.gridSelectedRequests = [];
		this.selectable.enabled = val;
		if (val) {
			let data = this.gridDataFull.filter(f => f.requestStatusEthName === "published");
			this.updateData(data);
		} else {
			this.updateData(this.gridDataFull);
		}
	}
}