export class DismantledSeriesGeneralPropertiesData {

    needSecurityKeyForReserve: boolean;
    contragentId: number;

    deviceConditionId: number;
    deviceConditionName: string;
    isDeviceBroken: boolean;
    brokenDescription: string;
    completeKit: boolean;
    hasCryptoKey: boolean;
    cryptoKey: string;

    constructor(
        needSecurityKeyForReserve: boolean,
        contragentId: number) 
    {
        this.needSecurityKeyForReserve = needSecurityKeyForReserve;
        this.contragentId = contragentId;
    }
}