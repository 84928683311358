import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import {RequestStatusMspAliasGroupDto} from "../models/request/request-status-msp-alias-group.dto";

@Injectable()
export class RequestStatusMspAliasGroupsService extends DataService<RequestStatusMspAliasGroupDto, RequestStatusMspAliasGroupDto> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'request-status-msp-alias-groups';
	}
}
