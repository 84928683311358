import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { NotificationService } from "../../../core/services/notification.service";
import { ComponentPageBase } from "../../../shared/components/component-page-base/component-page-base";
import { CategoryType } from "../../../shared/enums/category-type.enum";
import { AttachmentTypeDto } from "../../../shared/models/attachment/attachment-type-dto";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { AttachmentTypesService } from "../../../shared/services/attachment-types.service";
import { LookupService } from "../../../shared/services/lookup.service";

@Component({
	selector: 'attachment-type-page',
	templateUrl: './attachment-type.page.html',
	styleUrls: [
		'./attachment-type.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class AttachmentTypePage extends ComponentPageBase<AttachmentTypeDto> implements OnInit {

	public activeIdString: any = "main";
	public categoryTypeId = <number>CategoryType.serviceModes;

	public attachmentTypeId;

	public attachmentTypeConfigurations: KeyValueObject[] = [];

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: AttachmentTypesService,
		private lookupService: LookupService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/attachment-types';
		this.appService.pageTitle = 'Новый тип вложения';
	}

	ngOnInit(): void {
		this.attachmentTypeId = this.route.snapshot.paramMap.get('attachmentTypeId');

		if (this.attachmentTypeId != null) {

			this.dataService.getById(+this.attachmentTypeId)
				.subscribe(vm => {
					this.entityName = `${vm.entity.name}`;
					this.entityId = this.attachmentTypeId;
					this.dataBind(vm);
				});

		} else {

			this.entityViewModel = new EntityViewModel<AttachmentTypeDto>();
			this.entityViewModel.entity = new AttachmentTypeDto();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;

		}

		this.lookupService.getData("attachment-type-configurations", null).subscribe(data => this.attachmentTypeConfigurations = data);
	}

	afterSaveChangesChild(event: any): void {
		if (this.isNew) {
			this.router.navigate([this.listPageRoutePath]);
		}
	}

}
