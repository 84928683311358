import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { AuthUserDto } from '../../shared/models/Security/AuthUserDto';
import { LoginDto } from '../../shared/models/LoginDto';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	})
};

@Injectable({
	providedIn: 'root'
})
export class SecurityService {
	_currentUser: AuthUserDto = new AuthUserDto();

	get currentUser(): AuthUserDto {
		return this._currentUser;
	}

	set currentUser(value: AuthUserDto) {
		this._currentUser = value;
	}

	constructor(private http: HttpClient) {
	}

	login(entity: LoginDto): Observable<ActionDataResult<AuthUserDto>> {
		if (!entity.switchRequest) {
			// Initialize security object
			this.resetSecurityObject();
		}

		const apiUrl = environment.apiUrl + 'v1/security/login';

		return this.http.post<ActionDataResult<AuthUserDto>>(apiUrl, entity, httpOptions).pipe(
			tap(res => {

				if (res.isSuccessful) {
					if (entity.switchRequest) {
						this.resetSecurityObject();
					}

					this._currentUser = res.data;
					localStorage.setItem('authUser', JSON.stringify(this._currentUser));
				}

			}));
	}

	logout(): void {
		this.resetSecurityObject();
	}

	resetSecurityObject(): void {

		if (this._currentUser) {
			this._currentUser.userName = '';
			this._currentUser.bearerToken = '';
			this._currentUser.isAuthenticated = false;
			this._currentUser.claims = [];
		}

		localStorage.removeItem('authUser');
	}

	isClient(): boolean {
		return this.isClaimValid('IsClient');
	}

	isOnlyClient(): boolean {
		return this.isClaimValid('IsOnlyClient');
	}

	isOnlyStorekeeper(): boolean {
		return this.isClaimValid('isOnlyStorekeeper');
	}

	isEngineer(): boolean {
		return this.isClaimValid('IsEngineer');
	}
	isCostVisible(): boolean {
		return this.isSpecialRightClaimValid('DisplayTheCostOfExchangeRequests');
	}

	isOnlyEngineer(): boolean {
		return this.isClaimValid('IsOnlyEngineer');
	}
	isOnlySCPartnerEngineer(): boolean {
		return this.isClaimValid('IsOnlySCPartnerEngineer');
	}
	
	isAdmin(): boolean {
		return this.isClaimValid('IsAdmin');
	}

	isSCUser(): boolean {
		return this.isClaimValid('IsSCUser');
	}

	isLanterCoordinator(): boolean {
	  return this.isClaimValid('IsLanterCoordinator');
	}
	
	isSCPartnerCoordinator(): boolean {
		return this.isSpecialRightClaimValid('PartnerCoordinator');
	}

	isCanEditRequestDevices(): boolean {
		return this.isSpecialRightClaimValid('CanEditRequestDevices');
	}

	isSCPartnerEngineer(): boolean {
		return this.isSpecialRightClaimValid('PartnerServiceCenterEngineer');
	}

	isPartialDeviceReturn(): boolean {
		return this.isSpecialRightClaimValid('PartialDeviceReturn');
	}

	isStorekeeper(): boolean {
		return this.isClaimValid('IsStorekeeper');
	}

	isSelfEmployedEngineer(): boolean {
		return this.isClaimValid("IsSelfEmployedEngineer");
	}

	isServiceBoundariesFilter(): boolean {
		return this.isSpecialRightClaimValid('ServiceBoundariesFilter');
	}

	canEditRequestPerformerInSelectedSpecStates(): boolean {
		return this.isSpecialRightClaimValid('EditRequestPerformerInSelectedSpecStates');
	}

	canMakingCallFromRequestTsp(): boolean {
		return this.isSpecialRightClaimValid('MakingCallFromRequestTsp');
	}

	isPhoneVisibileInMobileVersion(): boolean {
		return this.isSpecialRightClaimValid('PhoneVisibilityMobileVersion');
	}	
		
	canEditTspContacts(): boolean {
		return this.isSpecialRightClaimValid('EditTspContacts');
	}
	
	canEditingEquipmentInProgressInRequest(): boolean {
		return this.isSpecialRightClaimValid('EditingEquipmentInProgressInRequest');
	}

	hasClaim(claimKey: any, claimValue?: any): boolean {
		let ret = false;

		if (this.isClaimValid('IsAdmin')) {
			return true;
		}

		// See if an array of values was passed in.
		if (typeof claimKey === 'string') {
			ret = this.isClaimValid(claimKey, claimValue);
		} else {
			const claims: string[] = claimKey;
			if (claims) {
				for (let index = 0; index < claims.length; index++) {
					ret = this.isClaimValid(claims[index]);
					// If one is successful, then let them in
					if (ret) {
						break;
					}
				}
			}
		}

		return ret;
	}

	public isSpecialRightClaimValid(key: string): boolean {
		let claimKey = `role-${key}-right`;
		return this.isClaimValid(claimKey);
	}
	
	private isClaimValid(claimKey: string, claimValue?: string): boolean {
		let ret = false;
		let auth: AuthUserDto = null;

		// Retrieve security object
		auth = this._currentUser;
		if (auth) {
			// See if the claim type has a value
			// *hasClaim="'claimType:value'"
			if (claimKey.indexOf(':') >= 0) {
				const words: string[] = claimKey.split(':');
				claimKey = words[0].toLowerCase();
				claimValue = words[1];
			} else {
				claimKey = claimKey.toLowerCase();
				// Either get the claim value, or assume 'true'
				claimValue = claimValue ? claimValue : 'true';
			}
			// Attempt to find the claim
			ret = auth.claims.find(c =>
				c.key.toLowerCase() == claimKey &&
				c.value == claimValue) != null;
		}

		return ret;
	}

	public check(): Observable<ActionDataResult<boolean>> {
		return this.http.post<ActionDataResult<boolean>>(environment.apiUrl + 'v1/security/check', null);;
	}
}
