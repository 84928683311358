import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../shared/services/reports.service';
import { JsonPipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { LookupService } from '../../shared/services/lookup.service';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-overtime',
  templateUrl: './overtime.component.html',
  styleUrls: [
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
    ]
})
export class OvertimeComponent implements OnInit {

  public range = { start: null, end: null };
  public type = 'issue';
  public excludeExpertises = true;
  public dataGenerating = false;

  public serviceCenters: KeyValueObject[] = [];
  public selectedServiceCentersIds: number[] = [];
  
  public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

  constructor(
    private reportsService: ReportsService,
    private lookupService: LookupService,
    private appService: AppService
  ) {
      appService.pageTitle = 'Экспорт в систему расчета переработок';
   }

  ngOnInit() {
    this.lookupService.getData('service-centers', null).subscribe(data => this.serviceCenters = data);
  }

  generateReport() {
    this.dataGenerating = true;
    this.reportsService.getOvertime(this.type, this.getStart(), this.getEnd(), this.excludeExpertises, this.selectedServiceCentersIds).subscribe(blob => {
			saveAs(blob, 'report_overtime.xls', { type: 'application/octet-stream' });
      this.dataGenerating = false;
		});
  }

  getStart() {
    return new Date(this.range.start).toJSON();
  }
  getEnd() {
    return new Date(this.range.end).toJSON();
  }
}
