import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { LaddaModule } from 'angular2-ladda';
import { RolesListPage, RolePage, RolesService, RoleSpecialRightListComponent } from './roles';
import { UsersListPage } from './users/list/list.page';
import { UserPage } from './users/user/user.page';
import { UsersService } from './users/users.service';
import { ExcelModule, FilterService } from '@progress/kendo-angular-grid';
import { TextMaskModule } from 'angular2-text-mask';
import { AutomaticRegistrationRequestsListPage } from './automatic-registration-requests/list/list.page';
import { AutomaticRegistrationRequestPage } from './automatic-registration-requests/automatic-registration-request/automatic-registration-request.page';
import { VeerouteSettingsPage } from './veeroute/veeroute-settings.page';
import { AutomaticPublicationRequestsListPage } from './automatic-publication-requests/list/list.page';
import { AutomaticPublicationRequestPage } from './automatic-publication-requests/automatic-publication-request/automatic-publication-request.page';
import { RoleAccessTab } from './roles/role-access-tab/role-access-tab.component';

@NgModule({
	declarations: [
		RolesListPage,
		RolePage,
		RoleSpecialRightListComponent,

		UsersListPage,
		UserPage,

		AutomaticRegistrationRequestsListPage,
		AutomaticRegistrationRequestPage,

		AutomaticPublicationRequestsListPage,
		AutomaticPublicationRequestPage,

		VeerouteSettingsPage,

		RoleAccessTab
	],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		RouterModule,
		PasswordStrengthBarModule,
		LaddaModule,
		ExcelModule,
		TextMaskModule
	],
	providers: [
		RolesService,
		UsersService,
		FilterService,
	]
})
export class AdminModule { }
