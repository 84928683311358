import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '../services/security.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private securityService: SecurityService,
		private router: Router) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		
		var authUser = localStorage.getItem("authUser");
		let categoryPermissionClaim: string = next.data["categoryPermissionClaim"];

		if (authUser)
			this.securityService.currentUser = JSON.parse(authUser);

		const now = new Date();
		const utcCurrent = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
		if (!this.securityService.currentUser.isAuthenticated
			|| (categoryPermissionClaim != null && !this.securityService.hasClaim(categoryPermissionClaim))
			|| this.securityService.currentUser.expiresInUtc < utcCurrent)
		{
			this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
			return false;
		}

		return true;
	}
}
