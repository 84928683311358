export class SetSnModel {
    requestDeviceId: number;
    sn: string;
    vendor: string;
    model: string;
    type: string;
    isError: boolean;
    articleMask: string;
    isPart: boolean;
    quantity: number;
    itemId: number;
    articleNumber: string;
    errorMessage: string;
    cellNumber: string;
    isCellError: boolean;
    cellErrorMessage: string;
    deviceConnectionTypeNameFromArticleMask: string;
    snDb: string;
    placeholderSn: string;
    quantityDb: number;
		snForValidation: string[] = [];
		requireValidation: boolean = true;
    notFound: boolean = false;
    requestSn: string = null;
    deviceStatusId: number = null;
    quantityAvailable: number;
    deviceServiceTypeId?: number;
    deviceServiceTypeName?: string;
    deviceServiceTypeNameHeader?: string;
    initQuantity: number;
    nomenclature: string;
    nomenclatureChecked: boolean = false;
    isAdded: boolean;
    articleId: number;
    deviceTypeId?: number;
    deviceSupplierId?: number;
    deviceModelId?: number;
    serialnumberMapped: boolean = false;

    constructor(requestDeviceId: number
        , vendor: string
        , model: string
        , type: string
        , sn: string = null
        , isError: boolean = false
        , articleMask: string = null
        , isPart: boolean = false
        , quantity: number = null
        , itemId: number = null
        , articleNumber: string = null
        , errorMessage: string = null
        , cellNumber: string = null
        , isCellError: boolean = false
        , cellErrorMessage: string = null
        , deviceConnectionTypeNameFromArticleMask: string = null
        , snDb: string = null
        , quantityDb: number = null
        , notFound: boolean = false
        , requestSerialNumber: string = null
        , deviceStatusId: number = null
        , quantityAvailable: number = null
        , deviceServiceTypeId: number = null
        , deviceServiceTypeName: string = null
        , nomenclature: string = null
        , isAdded: boolean = false) {
        this.requestDeviceId = requestDeviceId;
        this.vendor = vendor;
        this.model = model;
        this.type = type;
        this.sn = sn;
        this.isError = isError;
        this.articleMask = articleMask;
        this.isPart = isPart;
        this.itemId = itemId;
        this.articleNumber = articleNumber;
        this.errorMessage = errorMessage;
        this.cellNumber = cellNumber;
        this.isCellError = isCellError;
        this.cellErrorMessage = cellErrorMessage;
        this.deviceConnectionTypeNameFromArticleMask = deviceConnectionTypeNameFromArticleMask;
        this.snDb = snDb;
        this.quantity = isPart ? null : quantity;
        this.initQuantity = quantity;
        this.quantityDb = quantityDb;
        this.quantityAvailable = quantityAvailable;
        this.notFound = notFound;
        this.requestSn = requestSerialNumber;
        this.deviceStatusId = deviceStatusId;
        this.deviceServiceTypeId = deviceServiceTypeId;
        this.deviceServiceTypeName = deviceServiceTypeName;
        this.nomenclature = nomenclature;
        this.isAdded = isAdded;
    }
}
