import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AgreementListItem } from '../models/agreement/agreement-list-item';
import { Agreement } from '../models/agreement/agreement';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { KendoDataSourceHelper } from '../../core/helpers/kendo-data-source-helper';

@Injectable()
export class ExportExcelFromGridService extends DataService<Agreement, AgreementListItem> {

	constructor(http: HttpClient, notificationService: NotificationService, private kendoDataSourceHelper: KendoDataSourceHelper) {
		super(http, notificationService);
		this.controllerName = 'export-excel-from-grid';
	}

	public getGridReport(entitiesName: string, total: number, state: DataSourceRequestState, columnConfigs: any[] = null, fillToType: boolean = false): Observable<any> {
		var queryStr = this.kendoDataSourceHelper.StatetoQueryStrings(state);
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/${entitiesName}?${queryStr}&totalRequests=${total}`;
		return this.http.post(url, { totalRequests: total, columnConfigs: columnConfigs, fillToType: fillToType}, { responseType: 'blob' });
	}

	public getGridReportModeled<M>(model: M, entitiesName: string, total: number, state: DataSourceRequestState, columnConfigs: any[] = null): Observable<any> {
		let modelRecord = model as Record<string, any>;

		modelRecord.entitiesName = entitiesName;
		modelRecord.columnConfigs = columnConfigs;

		var queryStr = this.kendoDataSourceHelper.StatetoQueryStrings(state);
		const url = `${environment.reportsApiUrl}v${this.controllerVersion}/${this.controllerName}/${entitiesName}?${queryStr}&totalRequests=${total}`;
		return this.http.post(url, modelRecord, { responseType: 'blob' });
	}
}
