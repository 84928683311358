import { OnInit, Type } from '@angular/core';
import { GridSettings } from '../../models/grid/grid-settings';
import { ListViewModel } from '../../models/core/ListViewModel';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

export abstract class ListPageBaseLocal<T> implements OnInit {

    gridHeight: number;

    public data: T[] = [];

    public listViewModel: ListViewModel<T> = new ListViewModel<T>();
    
    abstract processListItems(items: T[]): void;

    public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: []
    }
    
    public exportInProgress: boolean = false;

    constructor(){}

    ngOnInit(): void {
        this.gridHeight = window.innerHeight - 108;

        this.listViewModel.data = [];
        this.listViewModel.total = 0;
    }

    public async onExcelExport(e: any): Promise<void> {
        const rows = [{
            type: 'header',
            level: null,
            cells: this.gridSettings.columnsConfig.map((columnData, _) => {
                return { value: columnData.title, background: '#DCDCDC' }
            })
        }];

        e.workbook.sheets[0].rows = this.data.map((item, _) => {
            rows.push({
                type: 'data',
                level: null,
                cells: this.gridSettings.columnsConfig.map((columnData, _) => {
                    return { value: item[columnData.field], background: null }
                })
            });
        });

        e.workbook.sheets[0].rows = rows;
    }

    public dataStateChange(state: DataStateChangeEvent): void{
        this.gridSettings.state = state;

        const filteredData = this.filterData(this.data, state);
        this.listViewModel.total = filteredData.length;
        
        this.listViewModel.data = this.sortData(filteredData, state).slice(state.skip, state.skip + state.take);
    }

    public updateData(data: T[]): void{
        this.data = data;

        const state = this.gridSettings.state;
        this.dataStateChange({
            skip: state.skip,
            take: state.take,
            filter: state.filter,
            sort: state.sort 
        });
    }

    getFiledFilterType(field: string): string {
        let col = this.gridSettings.columnsConfig.find(f => f.field === field);
        if(!!col) {
           return col.filter;
        }

        return null;
    }

    toLowerCaseSafe(s: any): string {
        if (!!s) {
            return s.toLowerCase();
        }

        return "";
    }

    public filterData(data: T[], state: DataStateChangeEvent): T[]{
        state.filter.filters.forEach(filterDesriptor => {
            if ((<FilterDescriptor>filterDesriptor) !== undefined){
                const filter = (<FilterDescriptor>filterDesriptor);

                const filterType = this.getFiledFilterType(filter.field as string);

                if ((<string>filter.value) !== undefined && filter.operator === 'contains') {
                    data = data.filter(x => this.toLowerCaseSafe(this.getFieldValue(x, filter.field as string)).includes(this.toLowerCaseSafe(filter.value)));
                } else if ((<Date>filter.value) !== undefined && filter.operator === 'gte') {
                    data = data.filter(x => {
                        const itemDateValue = this.getFieldValue(x, filter.field as string);
                        return itemDateValue === null ? false : new Date(itemDateValue) >= new Date(filter.value);
                    });
                } else if ((<Date>filter.value) !== undefined && filter.operator === 'lte') {
                    data = data.filter(x => {
                        const itemDateValue = this.getFieldValue(x, filter.field as string);
                        return itemDateValue === null ? false : new Date(itemDateValue) <= new Date(filter.value);
                    });
                } else if (filter.operator === 'eq') {
                    switch (filterType) {
                        case 'numeric':
                            data = data.filter(x => {
                                const itemDateValue = this.getFieldValue(x, filter.field as string);
                                return itemDateValue === null ? false : <number>itemDateValue == <number>filter.value;
                            });
                            break;
                        case 'date':
                            data = data.filter(x => {
                                const itemDateValue = this.getFieldValue(x, filter.field as string);
                                return itemDateValue === null ? false : new Date(itemDateValue) == new Date(filter.value);
                            });
                            break;
                        case 'boolean':
                            data = data.filter(x => {
                                const itemDateValue = this.getFieldValue(x, filter.field as string);
                                return itemDateValue === null ? false : itemDateValue == filter.value;
                            })
                        default:
                            break;
                    }
                }
                
            } else if ((<CompositeFilterDescriptor>filterDesriptor) !== undefined){

                const filterDescriptor = (<CompositeFilterDescriptor>filterDesriptor);
                data = this.filterData(data, {
                    skip: state.skip,
                    take: state.take,
                    filter: filterDescriptor
                });

            }
        });

        return data;
    }

    private sortData(data: T[], state: DataStateChangeEvent): T[]{
        state.sort.forEach(sortDescriptor => {
            const fieldType = this.getFiledFilterType(sortDescriptor.field);

            if (fieldType === 'string'){
                data = this.sortByString(sortDescriptor, data);
            } else if (fieldType === 'date'){
                data = this.sortByDate(sortDescriptor, data);
            } else if (fieldType === 'numeric'){
                data = this.sortByDate(sortDescriptor, data);
            }
        });

        return data;
    }

    private sortByString(sortDescriptor: SortDescriptor, data: T[]): T[] {
        const compareAscFunc = (current, next) => {
            let currentFieldValue: string = this.getFieldValue(current, sortDescriptor.field);
            let nextFieldValue: string = this.getFieldValue(next, sortDescriptor.field);

            if (!!!currentFieldValue) {
                currentFieldValue = "";
            }

            if (!!!nextFieldValue) {
                nextFieldValue = "";
            }

            return nextFieldValue.localeCompare(currentFieldValue);
        };

        const compareDescFunc = (current, next) => {
            let currentFieldValue: string = this.getFieldValue(current, sortDescriptor.field);
            let nextFieldValue: string = this.getFieldValue(next, sortDescriptor.field);

            if (!!!currentFieldValue) {
                currentFieldValue = "";
            }

            if (!!!nextFieldValue) {
                nextFieldValue = "";
            }

            return currentFieldValue.localeCompare(nextFieldValue);
        };

        return sortDescriptor.dir === 'asc'
            ? data.sort((current, next) => compareAscFunc(current, next))
            : data.sort((current, next) => compareDescFunc(current, next));
    }

    private sortByDate(sortDescriptor: SortDescriptor, data: T[]): T[] {
        let minDate = new Date(-8640000000000000).toISOString() as any;

        const compareAscFunc = (current, next) => {
            let currentFieldValue: Date = this.getFieldValue(current, sortDescriptor.field);
            let nextFieldValue: Date = this.getFieldValue(next, sortDescriptor.field);

            if (!!!currentFieldValue) {
                currentFieldValue = minDate;
            }

            if (!!!nextFieldValue) {
                nextFieldValue = minDate;
            }

            return currentFieldValue > nextFieldValue ? 1 : -1;
        };

        const compareDescFunc = (current, next) => {
            let currentFieldValue: Date = this.getFieldValue(current, sortDescriptor.field);
            let nextFieldValue: Date = this.getFieldValue(next, sortDescriptor.field);

            if (!!!currentFieldValue) {
                currentFieldValue = minDate;
            }

            if (!!!nextFieldValue) {
                nextFieldValue = minDate;
            }

            return currentFieldValue > nextFieldValue ? -1 : 1;
        };

        return sortDescriptor.dir === 'asc'
            ? data.sort((current, next) => compareAscFunc(current, next))
            : data.sort((current, next) => compareDescFunc(current, next));
    }

    private getFieldValue(item: T, fieldName: string): any {
        return item[fieldName];
    }
} 
