﻿import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AgreementListItem} from "../../../../../shared/models/agreement/agreement-list-item";
import {AppService} from "../../../../../app.service";
import {ContragentBaseComponent} from "../contragent-base.component";
import {CellClickEvent, DataStateChangeEvent} from "@progress/kendo-angular-grid";
import {process} from "@progress/kendo-data-query";
import {KeyValueObject} from "../../../../../shared/models/core/KeyValueObject";
import {LookupService} from "../../../../../shared/services/lookup.service";
import {DialogService} from "@progress/kendo-angular-dialog";
import {AgreementPage} from "../../../../agreements/agreement/agreement.page";
import { getDate } from "@progress/kendo-date-math";
import {ContragentType} from "../../../../../shared/enums";

@Component({
	selector: 'contragent-agreements',
	templateUrl: './contragent-agreements.component.html',
	styleUrls: [
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ContragentAgreementsComponent extends ContragentBaseComponent<AgreementListItem> implements OnInit {

	private _agreements;

	@Input()
	public contragentId;

	@Input()
	public contragentTypeId;

	@Input()
	public set agreements(agreements: AgreementListItem[]) {
		this._agreements = agreements;

		this.updateDates();
		this.dataStateChange(this.gridState);
	}

	public get agreements() {
		return this._agreements;
	}

	@Output()
	public afterSaveChanges = new EventEmitter<any>();

	public agreementStates: KeyValueObject[] = [];

	private clickedRowItem: AgreementListItem;

	constructor(
		protected appService: AppService,
		protected dialogService: DialogService,
		private lookupService: LookupService,
	) {
		super(appService);
	}

	ngOnInit(): void {
		this.lookupService.getData('agreement-states', null).subscribe(data => this.agreementStates = data);

		this.afterSaveChanges.subscribe(result => {
			this.dataSaving = false;
		});
	}

	cellClick(event: CellClickEvent) {
		this.clickedRowItem = event.dataItem;
	}

	onDblClick() {
		if (this.clickedRowItem) {
			const multipleOperationsComponent = this.createAgreementModal();
			multipleOperationsComponent.agreementId = this.clickedRowItem.agreementId as unknown as string;
		}
	}

	dataStateChange(state: DataStateChangeEvent): void {
		const result = process(this.agreements, state);
		this.gridData = result.data;
		this.gridState = state;
	}

	canCreateAgreement(): boolean {
		return this.contragentId &&
			(<number>ContragentType.bankEquer === this.contragentTypeId ||
				<number>ContragentType.serviceCenter === this.contragentTypeId ||
				<number>ContragentType.selfCompany === this.contragentTypeId)
	}

	getStateName(dataItem: AgreementListItem): string {
		const states = this.agreementStates.filter(x => x.id === dataItem.stateId);
		return states.length > 0 ? states[0].name : '';
	}

	openNewAgreementModal(): void {
		this.createAgreementModal();
	}

	openAgreementInNewTab(agreementId: any): void {
		if (agreementId){
			window.open(`/categories/agreement/${agreementId}`, '_blank');
		}
	}

	private updateDates(): void {
		this.agreements.forEach(agreement => {
			agreement.createdDateTime = new Date(agreement.createdDate);
			agreement.agreementDateTime = new Date(agreement.agreementDate);
			agreement.endDateTime = new Date(agreement.endDate);

			agreement.createdDate = getDate(new Date(agreement.createdDateTime));
			agreement.agreementDate = getDate(new Date(agreement.agreementDateTime));
			agreement.endDate = getDate(new Date(agreement.endDateTime));
		});
	}

	private createAgreementModal(): AgreementPage {
		const dialogRef = this.dialogService.open({ content: AgreementPage, width: '90%', height: '90%' });

		const multipleOperationsComponent = <AgreementPage>dialogRef.content.instance;
		multipleOperationsComponent.showHeader = false;
		multipleOperationsComponent.afterSaveChangesOnModal.subscribe(result => {
			this.dataSaving = true;
			if (this.afterSaveChanges){
				this.afterSaveChanges.emit();
			}
		});

		multipleOperationsComponent.initialContragentId = this.contragentId;

		return multipleOperationsComponent;
	}

}
