import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginPage } from './login.page';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		LaddaModule
	],
	declarations: [LoginPage],
})
export class LoginModule { }