export class CreateIntakeRequest
{   
    externalId: string;
    zip: string;
    city: string;
    street: string;
    house: string;
    building: string;
    housing: string;
    flat: string;
    contactPerson: string;
    contactPhone: string;
    takingDate: Date;
    takingTimeFrom: string = "09:00";
    takingTimeTo: string = "18:00";
    comment: string;
	constructor(obj: Partial<CreateIntakeRequest>) {
		Object.assign(this, obj);
	}
}