import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { RequestStoppingReasonKindDto } from '../models/request/request-stopping-reason-kind-dto';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class RequestStoppingReasonKindsService extends 
    DataService<RequestStoppingReasonKindDto, RequestStoppingReasonKindDto> 
{
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'stopping-reason-kinds';
	}

	getByReasonId(reasonId: number): Observable<RequestStoppingReasonKindDto> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-by-reason-id/${reasonId}`;
		return this.getQuery<RequestStoppingReasonKindDto>(url);
	}
}