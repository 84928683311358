import { Component, OnInit } from "@angular/core";
import { ReportPageBase } from "../components/report-page-base";
import { ReportsService } from "../../shared/services/reports.service";
import { NotificationService } from "../../core/services/notification.service";
import { NotificationType } from "../../core/services/notification-type";
import { LookupService } from '../../shared/services/lookup.service';
import { KeyValueObject } from "../../shared/models/core/KeyValueObject";
import { forkJoin } from "rxjs";
import { DataSourceRequestState, groupBy } from '@progress/kendo-data-query';
import { saveAs } from 'file-saver';
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { WarehousesService } from "../../shared/services/warehouses.service";
import { ContragentType } from "../../shared/enums";
import { AppService } from "../../app.service";

@Component({
	selector: 'shelf-life-1c-report-page',
	templateUrl: './shelf-life-1c-report.page.html',
	styleUrls: [
		'./shelf-life-1c-report.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ShelfLife1cReportPage extends ReportPageBase implements OnInit {

	nomenclatureId: number = null;
	warehouseCities: KeyValueObject[] = [];
	warehouseCityNames: string[] = null;
	bankOwners: KeyValueObject[] = [];
	bankOwnerCodes: string[] = null;

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	
	constructor(
		private reportsService: ReportsService,
        private lookupService: LookupService,
		private warehousesService: WarehousesService,
		private notificationService: NotificationService,
		private appService: AppService
	) {
		super();
		appService.pageTitle = 'Отчет по срокам хранения';
	}

	ngOnInit(): void {
		this.range.start.setHours(0, 0, 0, 0);
		this.range.end.setHours(0, 0, 0, 0);

		var clientsState: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: ContragentType.bankEquer }] }
		};

		const banks$ = this.lookupService.getData("contragents", clientsState)
		const warehouseCities$ = this.warehousesService.warehousesCitiesLookup()
		forkJoin([banks$, warehouseCities$]).subscribe(results => {
			this.bankOwners = results[0];
			this.warehouseCities = results[1];
		});
	}

	generateReport() {
		this.generateReportByDateRange((startDate, endDate) => {
			this.reportsService.getShelfLife(startDate, endDate, this.nomenclatureId, this.warehouseCityNames, this.bankOwnerCodes)
				.subscribe(blob => {
					if (blob.type === 'application/octet-stream') {
						const localDate = new Date().toLocaleString().replace(',', '');
						saveAs(blob, `Отчет_по_срокам_хранения_от_${startDate}_по_${endDate}_создан_${localDate}.xlsx`);
						this.dataGenerating = false;

					} else if (blob.type === 'application/json') {
						blob
							.text()
							.then(result => {
								const response = JSON.parse(result);
								if (!response.isSuccessful) {
									this.notificationService.error({
										title: 'Ошибка',
										message: response.errorDescription as string,
										notificationType: NotificationType.SweetAlert
									});
								}

								this.dataGenerating = false;
							});
					}
				});
		});
	}
}
