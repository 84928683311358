import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { OrgTreeNode } from './org-tree-node';


@Component({
	selector: 'org-tree',
	templateUrl: './org-tree.component.html',
	styleUrls: [
		'./org-tree.component.scss'
	]
})
export class OrgTreeComponent implements OnInit {

	@Input()
	nodes: OrgTreeNode[] = [];

	@Input()
	direction: string;

	@Input()
	isInline: boolean;

	@Output()
	itemClick = new EventEmitter<OrgTreeNode>();

	ngOnInit() {
	}

	ngAfterViewChecked(): void {
		if (this.isTreeExists) {
			let allNodes = this.getAllNodesRecursive();
			allNodes.forEach(node => {
				this.markTreeNodeRequestStatus(node.name, node.nameColor, node.titleColor, node.backColor, this.isInline);
			});
		}
	}

	private getAllNodesRecursive(node: OrgTreeNode = null): OrgTreeNode[] {
		let nodes: OrgTreeNode[] = [];
		if (this.nodes.length > 0 && node === null) {
			return this.getAllNodesRecursive(this.nodes[0]);
		} else if (!!node) {
			nodes.push(node);
			if (!!node.childs) {
				node.childs.forEach(child => {
					this.getAllNodesRecursive(child).forEach(childRec => {
						nodes.push(childRec);
					});
				});
			}
		}

		return nodes;
	}

	nodeClick(node: OrgTreeNode): void {
		if (this.itemClick) {
			this.itemClick.emit(node);
		}
	}

	private markTreeNodeRequestStatus(nodeName: string, nameColor: string, titleColor: string, backColor: string, isInline: boolean): void {
		if (!!document.getElementsByClassName("group-node") && document.getElementsByClassName("group-node").length > 0) {
			const nodes = document.getElementsByClassName("group-node");
			for (let index = 0; index < nodes.length; index++) {
				const node = nodes[index];

				if (!!node.children && node.children.length > 0 && !!node.children[0].children) {

					if (node.children[0].children.length > 0 && node.children[0].children[0].innerHTML === nodeName) {

						if (!!backColor) {
							node.setAttribute("style", `background-color:${backColor}`);
						}

						if (isInline) {
							const nameObject = node.children[0].children[0];
							let span = document.createElement("span");
							let cl = nameObject.getAttribute("class");
							span.innerHTML = nameObject.innerHTML;
							nameObject.parentNode.replaceChild(span, nameObject);
							span.setAttribute("class", cl);
						}

						if (!!nameColor) {
							const nameObject = node.children[0].children[0];
							nameObject.setAttribute("style", `color:${nameColor}`);
						}

						if (node.children[0].children.length > 1 && isInline) {
							const titleObject = node.children[0].children[1];
							let span = document.createElement("span");
							let cl = titleObject.getAttribute("class");
							span.innerHTML = titleObject.innerHTML;
							titleObject.parentNode.replaceChild(span, titleObject);
							span.setAttribute("class", cl);
						}

						if (node.children[0].children.length > 1 && !!titleColor) {
							const titleObject = node.children[0].children[1];
							titleObject.setAttribute("style", `color:${titleColor}`);
						}
					}
				}
			}
		}
	}

	private get isTreeExists(): boolean {
		return !!document.getElementsByClassName("group-node") && document.getElementsByClassName("group-node").length > 0;
	}
}