import { Component, Input, OnInit } from '@angular/core';
import { NotificationType } from '../../../core/services/notification-type';
import { NotificationService } from '../../../core/services/notification.service';
import { Activity } from '../../../shared/models/activity/activity';
import { ActivityFinesService } from '../../../shared/services/activity-fines/activity-fines.serivce';
import { FineTypesArray } from './fine-type.model';
import { Fine } from './request-fine.model';

@Component({
	selector: 'activity-fines',
	templateUrl: './activity-fines.component.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ActivityFinesComponent implements OnInit {

	@Input()
	activity: Activity;

	@Input()
	canEditFines: boolean;

	fineTypesGroupedArrays: FineTypesArray[][] = [];
	fines: Fine[] = [];

	loading: boolean = true;
	saving: boolean = false;

	constructor(
		private activityFinesService: ActivityFinesService,
		private notificationService: NotificationService,
	) { }

    ngOnInit(): void {
        
    }

	public loadFines(fineTypesArrays: FineTypesArray[], fines: Fine[]) {
		// для теста
		// fineTypesArrays.push({ columnNumber: 3, fineTypes: [
		// 	{ id: 1, name: "Акт не сдан в течение 3 дней", description: "Акт не сдан в течение 3 рабочих дней после выполнения заявки", orderId: 5 },
		// 	{ id: 2, name: "Акт не сдан после инд. разбора", description: "Акт не сдан после индивидуального разбора с СМЗ, срок - 3 рабочих дня после предупреждения", orderId: 4 },
		// 	{ id: 3, name: "Акт", description: "Акт сдан и оформлен корректно", orderId: 3 },
		// 	{ id: 4, name: "Инженер не является СМЗ", description: "Инженер не является СМЗ", orderId: 2 },
		// 	{ id: 5, name: "Сдано", description: "Оборудование сдано на склад", orderId: 1 }
		// ]});

		// fineTypesArrays.push({ columnNumber: 2, fineTypes: [
		// 	{ id: 6, name: "Дата выезда за рамками SLA", description: "СМЗ навязал свою дату выезда, которая выходит за рамки выполнения SLA", orderId: 1 },
		// 	{ id: 7, name: "Срок SLA", description: "Срок выполнения заявки превышен, заявка 'сгорела' до выполнения СМЗ", orderId: 2 },
		// 	{ id: 8, name: "Терминал не сдан", description: "Терминал не сдан на склад в течение 3 рабочий дней после выполнения заявки", orderId: 3 },
		// 	{ id: 9, name: "Некорректная информация для оплаты", description: "Некорректная информация для оплаты", orderId: 4 }
		// ]});

		// fineTypesArrays.push({ columnNumber: 1, fineTypes: [
		// 	{ id: 2, name: "Акт не сдан в течение 3 дней", description: "Акт не сдан в течение 3 рабочих дней после выполнения заявки", orderId: 1 },
		// 	{ id: 2, name: "Акт не сдан после инд. разбора", description: "Акт не сдан после индивидуального разбора с СМЗ, срок - 3 рабочих дня после предупреждения", orderId: 2 },
		// ]});

		// fines.push({ activityId: 730697, engineerId: 2618, engineerIntegraCode: 'E474A6F4-CF08-4879-9D2D-7EE08BE32CC8', fineTypeId: 1});

		this.loading = true;

		fines = fines.filter(f => f.activityId === this.activity.activityId &&
			f.engineerId === this.activity.performerUserId &&
			f.engineerIntegraCode.toLowerCase() === this.activity.performerUserIntegraCode.toLowerCase())

		fineTypesArrays.forEach(fineTypesArray => fineTypesArray.fineTypes.forEach(ft => { ft.checked = fines.some(f => f.fineTypeId == ft.id); }))

		let c = 0;
		fineTypesArrays.sort((a,b) => (a.columnNumber < b.columnNumber ? -1 : 1))
		for (let i = 0; i < fineTypesArrays.length; i+=3) {
			this.fineTypesGroupedArrays[c] = [];
			for (let j = 0; j < 3; j++) {
				if (i+j < fineTypesArrays.length)
					this.fineTypesGroupedArrays[c][j] = fineTypesArrays[i+j];
					this.fineTypesGroupedArrays[c][j].fineTypes.sort((a,b) => (a.orderId < b.orderId ? -1 : 1));
			}
			c++;
		}

		this.fines = fines;

		this.loading = false;
	}

	get isFinesChanged(): boolean {
		return this.fineTypesGroupedArrays.some(fineTypesArrays => {
			return fineTypesArrays.some(fineTypesArray => {
				return fineTypesArray.fineTypes.some(fineType => (fineType.checked && !this.fines.some(f => f.fineTypeId == fineType.id)) ||
					(!fineType.checked && this.fines.some(f => f.fineTypeId == fineType.id)))
			})
		})
	}

	public save() {
		this.saving = true;

		let finesToSave: Fine[] = [];
		this.fineTypesGroupedArrays.forEach(fineTypesArrays => {
			fineTypesArrays.forEach(fineTypesArray => {
				fineTypesArray.fineTypes.forEach(fineType => {
					if (fineType.checked) {
						finesToSave.push({
							activityId: this.activity.activityId,
							engineerId: this.activity.performerUserId,
							engineerIntegraCode: this.activity.performerUserIntegraCode,
							fineTypeId: fineType.id
						});
					}
				})
			})
		});

		this.activityFinesService.putFines(this.activity.requestId, this.activity.activityId, finesToSave).subscribe(resp => {
			if (resp.isSuccessful) {
				this.notificationService.success({
					title: 'Сервис штрафов и событий',
					message: 'Штрафы и события сохранены',
					notificationType: NotificationType.Toast
				});

				this.fines = finesToSave;
			}

			this.saving = false;
		})
	}
}