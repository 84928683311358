import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { NotificationService } from "../../../core/services/notification.service";
import { ComponentPageBase } from "../../../shared/components/component-page-base/component-page-base";
import { CategoryType } from "../../../shared/enums/category-type.enum";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { ServiceModeItem } from "../../../shared/models/services/service-mode-item";
import { ServiceModesService } from "../../../shared/services/service-modes.service";

@Component({
	selector: 'service-mode-page',
	templateUrl: './service-mode.page.html',
	styleUrls: [
		'./service-mode.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ServiceModePage extends ComponentPageBase<ServiceModeItem> implements OnInit {

	public activeIdString: any = "main";
	public categoryTypeId = <number>CategoryType.serviceModes;

	public serviceModeId;

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: ServiceModesService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/service-modes';
		this.appService.pageTitle = 'Новый режим обслуживания';
	}

	ngOnInit(): void {
		this.serviceModeId = this.route.snapshot.paramMap.get('serviceModeId');

		if (this.serviceModeId != null) {

			this.dataService.getById(+this.serviceModeId)
				.subscribe(vm => {
					this.entityName = `${vm.entity.name}`;
					this.dataBind(vm);
				});

		} else {

			this.entityViewModel = new EntityViewModel<ServiceModeItem>();
			this.entityViewModel.entity = new ServiceModeItem();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;

		}

	}

	afterSaveChangesChild(event: any): void {
		if (this.isNew) {
			this.router.navigate([this.listPageRoutePath]);
		}
	}

	addHours(value: any, increment: number): Date {
		if (!value) {
			return null;
		}
		let date = new Date(Date.parse(value));
		date.setHours(date.getHours() + increment);
		return date;
	}
}
