import { BaseObject } from '../core/BaseObject';
import { CustomerObjectWorkingHours } from '../customerObject/customer-object-working-hours';

export class Tid extends BaseObject {
	tidId: number;
	tidNumber: string;
	/**
	 * LTER-2145 Номер Tid, который был заменен новым
	 */
	tidNumberMigrationFrom: string;

	customerObjectId: number;
	customerObjectName: string;
	customerObjectAddress: string;
	customerObjectDistanceFromCityKm: number;
	workingHours: CustomerObjectWorkingHours[] = [];
	
	deviceId: number;
	deviceName: string;

	contragentId: number;
	contragentName: string;

	merchantId: number;
	merchantLegalName: string;
	mid: string;
	
	startDate: Date;
	endDate: Date;

	customerServiceDateStart: Date;
	customerServiceDateEnd: Date;

	customerContragentName: string;
	customerContragentId: number;

	acquirerName: string;
	acquirerId: number;

	serviceCenterId: number;
	serviceCenterName: string;
	
	bindAgreementsIds: number[] = [];
	bindServiceCentersIds: number[] = [];
	bindMerchantsIds: number[] = [];
	bindCustomerContragentsIds: number[] = [];

	regionId: number;
	regionName: string;

	legalAddress: string;

	agreementId: number;
	agreementNumber: string;

	actualPlace: string;

	serviceCategoryId?: number;
	serviceCategoryName: string;

	installedSerialNumbers: string;
}
