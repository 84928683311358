import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {

	@Input() title: string;

	@Input() index: number = 0;

  @Input() active: boolean = false;

  @Output() toggleAccordion: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onClick(event) {
		this.active = !this.active;
    event.preventDefault();
    this.toggleAccordion.emit({index: this.index, active: this.active});
  }

}
