import { KeyValueObject } from '../core/KeyValueObject';
import { EngubatorRegion } from './engubator.region';

export class EngubatorRequest {
	requestId: number;
	integraCode: string;
	serviceZone: KeyValueObject;
	planningZone: KeyValueObject;
	isPedestrian: boolean;
	estimateDuration: number;
	requiredSkills: KeyValueObject[] = [];
	requestState: KeyValueObject;
	engineer: KeyValueObject;
	region: EngubatorRegion = new EngubatorRegion();
}
