import { ArticleDto } from "../article/article.dto";

export class SendStateWithLogic {
    requestId: number;
    blockchainState: string;
    dataModel: any;
    componentsArticles: ArticleDto[] = [];

    constructor(requestId: number, blockchainState: string, dataModel: any = null, componentsArticles: ArticleDto[] = []) {
        this.requestId = requestId;
        this.blockchainState = blockchainState;
        this.dataModel = dataModel;
        this.componentsArticles = componentsArticles;
    }
}