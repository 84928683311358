import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { NotificationService } from "../../../core/services/notification.service";
import { ComponentPageBase } from "../../../shared/components/component-page-base/component-page-base";
import { CategoryType } from "../../../shared/enums/category-type.enum";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { ServiceTypeItem } from "../../../shared/models/services/service-type-item";
import { ServiceTypesService } from "../../../shared/services/service-types.service";

@Component({
	selector: 'service-type-page',
	templateUrl: './service-type.page.html',
	styleUrls: [
		'./service-type.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class ServiceTypePage extends ComponentPageBase<ServiceTypeItem> implements OnInit {

	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.serviceTypes;

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: ServiceTypesService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/service-types';
		this.appService.pageTitle = 'Новый тип обслуживания';
	}

	get cardHeight(): number {
		return window.innerHeight - 200;
	}

	ngOnInit(): void {
		this.entityId = this.route.snapshot.paramMap.get('serviceTypeId');

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId)
				.subscribe(vm => {
					this.entityName = `${vm.entity.name}`;

					this.dataBind(vm);
				});

		} else {

			this.entityViewModel = new EntityViewModel<ServiceTypeItem>();
			this.entityViewModel.entity = new ServiceTypeItem();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;

		}
	}

}
