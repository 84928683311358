import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../../app.service';
import { Attachment } from '../../models/attachment/attachment';
import { AttachmentsService } from '../../services/attachments.service';
import { saveAs } from 'file-saver';
import { UploadFile } from '../../models/attachment';
import { ImagePopup } from '../image-popup/image-popup.component';
import { DialogService } from '@progress/kendo-angular-dialog';

@Component({
	selector: 'attachments',
	templateUrl: './attachments.component.html',
	styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent {

	@Input()
	attachments: Attachment[] = [];

	@Input() preview = false;


	imageExtensions: string[] = ['.png', '.jpg', '.jpeg'];

	constructor(
		private appService: AppService,
		private attachmentsService: AttachmentsService,
		protected dialogService: DialogService) {
			
		}

	downloadFile(attachment: Attachment){
		this.attachmentsService.getAttachmentById(attachment.attachmentId).subscribe(blob => {
			saveAs(blob, attachment.attachmentName, { type: 'application/octet-stream' });
		});
	}

	isImageExtension(extension: string): boolean {

		if (extension == null)
			return false;


		extension = extension.toLowerCase();

		return this.imageExtensions.some(ext => extension.endsWith(ext));
	}
	
	async openPreview(file: Attachment) {

		if (!this.isImageExtension(file.attachmentName))
			return;

		const dialogRef = this.dialogService.open({ content: ImagePopup, width: this.appService.isMobileDevice ? '90%' : '90%', height: '90%' });

		const imagePopup = <ImagePopup>dialogRef.content.instance;

		var blob = await this.attachmentsService.getAttachmentById(file.attachmentId).toPromise();
		var reader = new FileReader();
		var self = this;
		reader.readAsDataURL(blob); 
		reader.onloadend = function() {
			var base64data = (reader.result as string).split(',')[1];                
			imagePopup.src = self.getImageUrl(base64data);
		}
	}

	getImageUrl(file) {
		return `data:image/png;base64,${file}`
	}

}
