import { SelectSnData } from "./select-sn-data.model";

export class SelectSn {
    requestId: number;
    selectSnDatas: SelectSnData[] = [];
    isSelectOne: boolean;
    isReceiveTransfer: boolean = false;

    constructor(requestId: number
        , selectSnDatas: SelectSnData[]
        , isSelectOne: boolean
        , isReceiveTransfer: boolean = false) {
        this.requestId = requestId;
        this.selectSnDatas = selectSnDatas;
        this.isSelectOne = isSelectOne;
        this.isReceiveTransfer = isReceiveTransfer;
    }
}