import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { ActionResult } from '../models/core/ActionResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { CustomerContact } from '../models/request/customer-contact';
import { ListViewModel } from '../models/core/ListViewModel';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { tap, filter } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';

@Injectable()
export class RequestCustomerContactService extends DataService<CustomerContact, CustomerContact> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'request-customer-contacts';
    }
}