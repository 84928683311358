import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { DeviceInfo } from "../../../models/device/device-info";
import { AddingDeviceModel } from "./adding-device.model";
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DevicesService } from "../../../services/devices.service";
import { request } from "http";
import { SearchDeviceData } from "../../../models/device/search-device-data";
import { ActionDataResult } from "../../../models/core/ActionDataResult";
import { UploadFile } from "../../../models/attachment";

@Component({
    selector: 'adding-device',
    templateUrl: './adding-device.html',
    styleUrls: ['./adding-device.scss',
        '../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class AddingDevice implements AfterViewInit {

    @Input() number: number;
    @Input() requestId: number;

    @Output() setDevice = new EventEmitter<AddingDeviceModel>();

    @ViewChild('serialNumberInput') serialNumberInput: ElementRef;

    deviceModel: AddingDeviceModel = new AddingDeviceModel();

    nomenclatures: KeyValueObject[] = [];

    isShown: Boolean = true;
    useSerialNumber: Boolean = true;

    hasError: boolean = false;
    message: string = null;

    isValidSerialNumber: boolean = false;
    isValidDeviceName: boolean = false;

    constructor(
        public deviceService: DevicesService
    ) { }

    ngAfterViewInit() {
        fromEvent(this.serialNumberInput.nativeElement, 'keyup')
            .pipe(
                map((evt: any) => evt.target.value),
                debounceTime(1000),
                distinctUntilChanged(),
            ).subscribe((text: string) => this.validateSerialNumber(text));
    }

    private refreshDevice() {
        this.deviceModel.index = this.number;
        this.deviceModel.isValid = this.deviceModel !== null &&
            (this.useSerialNumber && this.isValidSerialNumber || !this.useSerialNumber && this.isValidDeviceName)

        this.setDevice.emit(this.deviceModel);
    }

    private validateSerialNumber(serialNumber: string) {
        var validateError = false;
        
        if (!!serialNumber) {
            this.deviceService.isRemainsDevices(this.requestId, serialNumber, "").subscribe((resp: SearchDeviceData) => {

                var deviceData = resp;

                if (deviceData.isInEngineerWarehouse === null && deviceData.isInMainWarehouse === null) {
                    validateError = true;
                    this.message = "По серийному номеру не найдено оборудование в 1с. Свяжитесь с координатором";
                }
                if (!deviceData.isInEngineerWarehouse && !deviceData.isInMainWarehouse) {
                    validateError = true;
                    this.message = "По серийному номеру не найдены остатки в 1с. Свяжитесь с координатором";
                }
                else if (!deviceData.isInEngineerWarehouse && deviceData.isInMainWarehouse)
                    this.message = "Убедитесь, что серийный номер введен корректно. Оборудования нет на складе инженера";
                else
                    this.message = deviceData.nomenclature;

                this.hasError = validateError;
                this.isValidSerialNumber = !validateError;
                this.deviceModel.nomenclature = "";
                this.refreshDevice();
            });
        } else {
            validateError = true;
            this.message = "Пустой серийный номер";

            this.hasError = validateError;
            this.isValidSerialNumber = !validateError;
            this.deviceModel.nomenclature = "";
            this.refreshDevice();
        }
    }

    private validateDeviceName(deviceName: string) {
        this.deviceService.isRemainsDevices(this.requestId, "", deviceName).subscribe((resp: SearchDeviceData) => {

            var deviceData = resp;
            var validateError = false;

            if (deviceData.isInEngineerWarehouse === null && deviceData.isInMainWarehouse === null) {
                validateError = true;
                this.message = "Номенклатура не найдена в 1с. Свяжитесь с координатором";
            }
            else if (!deviceData.isInEngineerWarehouse && !deviceData.isInMainWarehouse) {
                validateError = true;
                this.message = "По номенклатуре не найдены остатки в 1с. Свяжитесь с координатором";
            }
            else
                this.message = deviceData.nomenclature;

            this.hasError = validateError;
            this.isValidDeviceName = !validateError;
            this.deviceModel.serialNumber = "";
            this.refreshDevice();
        });
    }

    saveAttachments(attachments: UploadFile[]) {
        this.deviceModel.uploadAttachments = attachments;
        this.refreshDevice();
    }

    changeDeviceName() {
        this.validateDeviceName(this.deviceModel.nomenclature);
    }

    async filterNomenclatures(text: string) {
        if (text !== null) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.loadNomenclatures(text);
        }
    }

    private loadNomenclatures(text: string) {
        if (!!text) {
            this.deviceService.getNomenclatures(text).subscribe((resp: string[]) => {
                this.nomenclatures = resp.map((item, index) => new KeyValueObject(index, item));
            });
        }
    }

    onSerialNumberChange(text: string){
        if (text){
            this.serialNumberInput.nativeElement.value = text.trim();
            this.deviceModel.serialNumber = text.trim();
        }
    }
}