import { DataService } from "../../core/services/data.service";
import { RequestEmail } from "../models/request/request-email";
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../core/services/notification.service';
import { ActionDataResult } from "../models/core/ActionDataResult";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { NotificationType } from "../../core/services/notification-type";
import { filter, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { DownloadRequestEmailResponse } from "../models/request/download-request-email-response";
import {ActionResult} from "../models/core/ActionResult";

@Injectable()
export class RequestEmailsService extends DataService<RequestEmail, RequestEmail> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'request-emails';
	}

	getAll(requestId: number): Observable<ActionDataResult<RequestEmail[]>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-all?requestId=${requestId}`;
		return this.http.get<ActionDataResult<RequestEmail[]>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			;
	}

	download(email: RequestEmail): Observable<ActionDataResult<DownloadRequestEmailResponse>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/download`;
		return this.http.post<ActionDataResult<DownloadRequestEmailResponse>>(url, { ...email, date: email.dateTime })
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при скачивании email',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при скачивании email',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}

	forward(requestId: number, subject: string, body: string, recipients: string[], attachments: string[]): Observable<ActionResult> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/forward`;
		return this.http.post<ActionResult>(url, {requestId: requestId, subject: subject, body: body, recipients: recipients, attachments: attachments})
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при отправке сообщения',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при отправке сообщения',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}

}
