import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { NotificationService } from "../../../core/services/notification.service";
import { tap, filter, take } from 'rxjs/operators';
import { NotificationType } from "../../../core/services/notification-type";
import { ListViewModel } from "../../models/core/ListViewModel";
import { EntityViewModel } from "../../models/core/EntityViewModel";
import { ActionDataResult } from "../../models/core/ActionDataResult";

@Injectable()
export abstract class EngubatorDataService<TListItem, TItem> {

	protected controllerName = '';
	protected controllerVersion = 1;
	protected unauthorizedCode = 401;

	constructor(protected http: HttpClient,
		protected notificationService: NotificationService) {
	}

	protected baseUrl(): string {
		return `${environment.engubatorAddress}v${this.controllerVersion}/${this.controllerName}`;
	}

	public list(queryString: string = ''): Observable<ListViewModel<TListItem>> {
		queryString = queryString === '' ? '' : `?${queryString}`;
		return this.http.get<ActionDataResult<TListItem[]>>(`${this.baseUrl()}${queryString}`)
			.pipe(tap(resp => {
				if (!resp.isSuccessful) {
					this.notificationService.warning({ title: 'Сервис подбора инженеров', message: resp.errorDescription });
				}

			},
				errorResponse => {
					if (errorResponse.status === this.unauthorizedCode)
						return;
					this.notificationService.warning({ title: '', message: 'Не удалось получить данные от сервиса подбора инженеров' });
				}
			))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(m => { return { data: m.data, total: m.data.length } });
	}

	public getById(id: number | string): Observable<EntityViewModel<TItem>> {
		return this.http.get<ActionDataResult<TItem>>(`${this.baseUrl()}/${id}`)
			.pipe(tap(resp => this.onGetByIdResponse(resp),
				errorResponse => {
					if (errorResponse.status === this.unauthorizedCode)
						return;
					this.notificationService.warning({ title: '', message: 'Не удалось получить данные от сервиса подбора инженеров' });
				}
			))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(m => { return { entity: m.data, canDelete: 0, isNew: m.code == 102 } });
	}

	protected onGetByIdResponse(response: ActionDataResult<TItem>): void {
		if (!response.isSuccessful) {
			if (response.code === 102) { //TItem не найден
				response.data = this.getNewTItem();
				response.isSuccessful = true;
			}
			else {
				this.notificationService.warning({ title: 'Сервис подбора инженеров', message: response.errorDescription });
			}
		}
	}

	public create(obj: TItem, errorNotificationType: NotificationType = NotificationType.Toast, dontShowCustomError: boolean = false): Observable<any> {
		return this.http.post<ActionDataResult<number | string>>(this.baseUrl(), obj)
			.pipe(tap(resp => {
				if (resp.isSuccessful) {
					this.notificationService.success({
						title: 'Создание нового элемента в сервисе подбора инженеров',
						message: 'Элемент создан успешно',
						notificationType: NotificationType.Toast
					});
				}
				else {
					if (!dontShowCustomError) {
						this.notificationService.error({
							title: 'Ошибка при создании элемента в сервисе подбора инженеров',
							message: resp.errorDescription,
							notificationType: errorNotificationType
						});
					}
				}
			},
				errorResponse => {
					if (errorResponse.status === this.unauthorizedCode)
						return;

					this.notificationService.error({
						title: 'Ошибка при создании элемента в сервисе подбора инженеров',
						message: errorResponse.message ? errorResponse.message : errorResponse.error.errorDescription,
						notificationType: NotificationType.Toast
					});

				}
			))
			.pipe(take(1));
	}

	public update(obj: TItem, successMessageTitle: string, dontShowCustomError: boolean = false, dontShowCustomSuccess: boolean = false, queryString: string = ''): Observable<any> {
		return this.http.put<any>(`${this.baseUrl()}${queryString}`, obj)
			.pipe(tap(resp => {
				if (resp.isSuccessful) {
					if (!dontShowCustomSuccess) {
						this.notificationService.success({
							title: successMessageTitle ? successMessageTitle : 'Изменение элемента в сервисе подбора инженеров',
							message: 'Изменения сохранены успешно',
							notificationType: NotificationType.Toast
						});
					}
				}
				else {
					if (!dontShowCustomError) {
						this.notificationService.error({
							title: 'Ошибка обновления элемента в сервисе подбора инженеров',
							message: resp.errorDescription,
							notificationType: NotificationType.Toast
						});
					}
				}
			},
				errorResponse => {
					if (errorResponse.status === this.unauthorizedCode)
						return;

					this.notificationService.error({
						title: 'Ошибка обновления элемента в сервисе подбора инженеров',
						message: errorResponse.message ? errorResponse.message : errorResponse.error.errorDescription,
						notificationType: NotificationType.Toast
					});

				}
			))
			.pipe(take(1));
	}

	protected abstract getNewTItem(): TItem;
}
