import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";

@Component({
	selector: 'confirm-device-different',
	templateUrl: './confirm-device-different.html',
	styleUrls: ['./confirm-device-different.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class ConfirmDeviceDifferent implements OnInit {

    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter();

    ngOnInit() {
    }

    constructor(
        public dialog: DialogRef
    ) { }

    cancelAction() {
        if (!!this.cancel) {
            this.cancel.emit();
        }
        this.dialog.close();
    }

    confirmAction() {
        if (!!this.confirm) {
            this.confirm.emit();
        }        
        this.dialog.close();
    }
}