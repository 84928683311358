import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppService } from "../../../../app.service";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { OSMService } from "../../../../shared/services/oSM.service";
import { GridSettings } from "../../../../shared/models/grid/grid-settings";
import { ServiceCenterResponse } from "../../../../shared/models/oSM/service-center-response";
import { RegionModel } from "../models/region-model";
import { KeyValueObject } from "../../../../shared/models/core/KeyValueObject";
import { DataResult, process, State } from '@progress/kendo-data-query';
import { LinkServiceCenterRegionsRequest } from "../../../../shared/models/oSM/list-service-regions-request";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { DialogService } from "@progress/kendo-angular-dialog";
import { AddServiceCenterRegionPage } from "../add-service-center-region/add-service-center-region.page";
import { ContragentsService } from "../../contragents.service";

@Component({
	selector: 'osm-regions-grid',
	templateUrl: './osm-regions-grid.page.html',
	styleUrls: [
		'./osm-regions-grid.page.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class OsmRegionsGridPage implements OnInit {
	public form: FormGroup;

	private _destroy$ = new Subject<boolean>();

	oSMRegionSaving = false;
	deletingOSMRegion: number[] = [];
	deletingAllSCRegions = false;
	canEditOSMRegions = false;

	changeLogPropertyName = 'Районы обслуживания';
	public oSMRegionsGridData: DataResult;

	choosenRegions: RegionModel[] = [];
	checkedRegionIds: number[] = [];

	regionValuesLookups: {} = {
		"region" : [],
		"state" : [],
		"county" : [],
		"city" : [],
		"town" : [],
		"village" : [],
		"city_district" : [],
		"suburb" : [],
	};
	
	private _integraCode: string;

	@Input()
	set integraCode(value: string) {
		this._integraCode = value;
		this.setOsmRegionsForServiceCenter(value);
	}
	@Input()
	 	name: string;

	get integraCode(): string {
		return this._integraCode;
	}

	constructor(
		protected appService: AppService,
		private osmSerivice: OSMService,
		protected dialogService: DialogService,
		private contragentsService: ContragentsService
	) {
		this._buildForm();
		this.allData = this.allData.bind(this);
	}

	ngOnInit(): void {
		this._loadData();
	}

	
	public oSMRegionsGridSettings: GridSettings = {
		state: {
			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "region",
				title: "Округ",
				filter: "string",
				filterable: true,
				width: 250,
			},
			{
				field: "state",
				title: "Область(край)",
				filter: "string",
				filterable: true,
				width: 250,
			},
			{
				field: "county",
				title: "Городской округ",
				filter: "string",
				filterable: true,
				width: 250,
			},
			{
				field: "city",
				title: "Город",
				filter: "string",
				filterable: true,
				width: 250,
			},
			{
				field: "town",
				title: "Населенный пункт",
				filter: "string",
				filterable: true,
				width: 250,
			},
			{
				field: "village",
				title: "Поселение",
				filter: "string",
				filterable: true,
				width: 250,
			},
			{
				field: "city_district",
				title: "Района города",
				filter: "string",
				filterable: true,
				width: 250,
			},
			{
				field: "suburb",
				title: "Микрорайон",
				filter: "string",
				filterable: true,
				width: 250,
			}
		]
	};

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public continue() {
	}
	public _loadData(){
	}

	private _buildForm(): void {
		this.form = new FormGroup({
		});
	}

	setOsmRegionsForServiceCenter(integraCode: string){
		this.osmSerivice.getServiceCenterRegions(integraCode).subscribe(x => 
			{
				this.setOSMData(x);
			}
		);
	}

	setOSMData(data: ServiceCenterResponse){
		this.checkedRegionIds = [];
		this.choosenRegions = data.regions
				.map((v) => {
					var model = new RegionModel();
					model.serviceCenterRegionId = v.serviceCenterRegionId;
					model.osmRegionId = v.osmRegionId;
					v.segmentLevels.forEach(x => {
						model[x.segmentName.toLowerCase()] = x.segmentValue;
					});
					this.checkedRegionIds.push(v.osmRegionId);
					return model;
				});
		this.loadOSMRegionsGridSettings();

		this.oSMRegionsGridSettings.columnsConfig.forEach(element => {
			this.regionValuesLookups[element.field] = this.getLookupsByName(element.field);
		});
	}

	getValueByName(dataItem: any, name: string): string {
		var value = dataItem[name];
		return value ? value : "";
	}

	getLookupsByName(name: string): KeyValueObject[] {
		var values = this.choosenRegions
		.map(x => x[name])
		.filter(onlyUnique)
		.map((v, i) => {
			return new KeyValueObject(i, v);
		});

		function onlyUnique(value, index, array) {
			return array.indexOf(value) === index;
		}

		if (!values || values.length == 0){
			return [];
		}
		return values;
	}

	public osmRegionsDataStateChange(state: State): void {
		this.oSMRegionsGridSettings.state = state;
		this.oSMRegionsGridData = process(this.choosenRegions, state);
	}

	loadOSMRegionsGridSettings() {
		this.oSMRegionsGridData = process(this.choosenRegions, this.oSMRegionsGridSettings.state);
	}

	onClickAddServiceCenterRegion(){
		const installDialogRef = this.dialogService.open({
			title: "Добавить районы обслуживания",
			content: AddServiceCenterRegionPage,
			width: '400px'
		});

		const dialog = <AddServiceCenterRegionPage>installDialogRef.content.instance
		dialog.checkedKeys = this.checkedRegionIds;
		dialog.onContinueEvent
		.subscribe((checkedKeys: number[]) => {	
			installDialogRef.close;
			if (checkedKeys.length > 0){
				this.oSMRegionSaving = true;
				var request = new LinkServiceCenterRegionsRequest();
				request.oSMRegionIdToLink = checkedKeys;
				let regionsToLog = request.oSMRegionIdToLink.filter(y => !this.choosenRegions.find(x => x.osmRegionId === y));
				this.osmSerivice.linkServiceCenterRegions(this.integraCode, request).subscribe(x => {
					this.setOSMData(x);
					this.oSMRegionSaving = false;
					regionsToLog.forEach(element => {
						let comment = 'Добавление связки';
						let segment = this.choosenRegions.find(x => x.osmRegionId === element);
						let value = `СЦ: ${this.name}, Имя сегмента: ${this.getFirstNonEmptyField(segment)}`;
						this.contragentsService.addChangeLog(this.integraCode, value, comment, this.changeLogPropertyName).subscribe(() => {});
					});
				});
			}
		});
	}

	onEditOSMRegions(){
		this.canEditOSMRegions = !this.canEditOSMRegions;
	}

	removeOSMRegion(dataItem: RegionModel){
		this.deletingOSMRegion.push(dataItem.serviceCenterRegionId);
		this.osmSerivice.deleteServiceCenterRegion(dataItem.serviceCenterRegionId).subscribe(x => {
			this.setOSMData(x);
			const index = this.deletingOSMRegion.indexOf(dataItem.serviceCenterRegionId, 0);
			if (index > -1) {
				this.deletingOSMRegion.splice(index, 1);
			}
			let comment = 'Удаление связки';
			let value = `СЦ: ${this.name}, Имя сегмента: ${this.getFirstNonEmptyField(dataItem)}`;
			this.contragentsService.addChangeLog(this.integraCode, value, comment, this.changeLogPropertyName).subscribe(() => {});;
		})
	}

	getFirstNonEmptyField(fields: RegionModel){
		let fieldOrder = [
			'suburb',
			'city_district',
			'village',
			'town',
			'city',
			'county',
			'state',
			'region'
		];
	  
		for (const field of fieldOrder) {
		  if (fields[field]) {
			return fields[field];
		  }
		}
	  
		return "";
	}	  

	deleteAllSCRegions(){
		this.deletingAllSCRegions = true;
		this.osmSerivice.deleteAllServiceCenterRegions(this.integraCode).subscribe(x => {
			this.setOSMData(x);
			this.deletingAllSCRegions = false;
			let comment = 'Удаление всех связок';
			let value = `СЦ: ${this.name}`;
			this.contragentsService.addChangeLog(this.integraCode, value, comment, this.changeLogPropertyName).subscribe(() => {});;
		})
	}

	public allData = (): ExcelExportData => {
		const result: ExcelExportData = {
			data: process(this.choosenRegions, this.oSMRegionsGridSettings.state).data
		  };
	  
		  return result;
	};
}