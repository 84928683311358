import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { NewRequestService } from '../models/request/new-request/new-request-service';

@Injectable()
export class RequestServicesService extends DataService<NewRequestService, NewRequestService> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'request-services';
  }
}

