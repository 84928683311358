import { CommentKindEnum } from "../../enums/comment-kind.enum";
import { ChangeStatusModel } from "./change-status-model";

export class CommentModel extends ChangeStatusModel {
    
    constructor(requestId: number, newStatusId: number, comment: string, commentKind: CommentKindEnum = CommentKindEnum.internal){
        super(requestId, newStatusId);
        this.requestId = requestId;
        this.newStatusId = newStatusId;
        this.comment = comment;
        this.commentKind = commentKind;
    }
}