import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { CustomerObject } from '../../../shared/models/customerObject/CustomerObject';
import { CustomerObjectsService } from '../customer-objects.service';
import { EntityViewModel } from '../../../shared/models/core/EntityViewModel';
import { CategoryType } from '../../../shared/enums/category-type.enum';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};


@Component({
  selector: 'categories-customer-object',
  templateUrl: './customer-object.page.html',
  styleUrls: [
    './customer-object.page.scss',
    '../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
  ]
})
export class CustomerObjectPage extends ComponentPageBase<CustomerObject> {

  headerName = "Основное";
	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.customerObjects;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected appService: AppService,
    protected notificationService: NotificationService,
    protected dataService: CustomerObjectsService
  ) {
    super(route, router, appService, notificationService, dataService);
    this.listPageRoutePath = 'categories/customer-objects';
    this.appService.pageTitle = 'Новый объект ТСП';
  }

  ngOnInit() {

    this.entityId = this.route.snapshot.paramMap.get('customerObjectId');

    if (this.entityId != null) {

      this.dataService.getById(+this.entityId).subscribe(vm => {
        this.entityName = `${vm.entity.name}`;
        this.dataBind(vm);
      });

    } else {

      this.entityViewModel = new EntityViewModel<CustomerObject>();
      this.entityViewModel.entity = new CustomerObject();
      this.entityViewModel.entity.createdDate = new Date();
      this.canEditForm = true;
      this.isNew = true;
    }
  }

  afterSaveChangesChild() {
    if (this.isNew) {
      this.router.navigate([this.listPageRoutePath]);
    }
  }
}
