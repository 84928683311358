import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LookupService } from '../../../services/lookup.service';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { UserGroupsService } from '../../../services/user-groups.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SetPerformerUserGroupModel } from './set-performer.model';
import { WorkflowTransition } from '../../../models/request/workflow-transition';

@Component({
	selector: 'set-performer-user-group',
	templateUrl: './set-performer-user-group.html',
	styleUrls: ['./set-performer-user-group.scss']
})
export class SetPerformerUserGroup implements OnInit {

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	@Output()
	onContinueEvent = new EventEmitter<SetPerformerUserGroupModel>();

	@Output()
	onCancelEvent = new EventEmitter();

	users: KeyValueObject[] = [];
	
	protected userGroupContragentId?: number;

	@Input()
	public isPerformerUserGroupVisible: boolean = true;

	@Input()
	setPerformerUserGroupModel: SetPerformerUserGroupModel;

	@Input()
	workflowTransition: WorkflowTransition;

	@Input()
	requestId: number;

	constructor(
		private lookupService: LookupService,
		public dialog: DialogRef,
		private userGroupsService: UserGroupsService
	) {}

	ngOnInit() {
		this.groupChange(this.setPerformerUserGroupModel.userGroupId);
	}

	cancel() {
		this.onCancelEvent.emit();
		this.dialog.close();
	}

	continue() {
		this.onContinueEvent.emit(this.setPerformerUserGroupModel);
		this.dialog.close();
	}

	groupChange(groupId: number): void {
		this.userGroupsService.getUsersByGroup(groupId).subscribe(res => this.users = res);
		this.userGroupsService.getContragentIdByUserGroup(groupId).subscribe(res => this.userGroupContragentId = res);
	}
}
