/** фильтр для выбора инструкций */
export class Filter {
	constructor(
		customerCode: number,
		requestType: number,
		deviceType: number,
		deviceVendor: number,
		deviceModel: number) {
		this.customerCode = customerCode;
		this.requestType = requestType;
		this.deviceType = deviceType;
		this.deviceVendor = deviceVendor;
		this.deviceModel = deviceModel;
	}
	/**Id контрагента*/
	customerCode: number;
	/** Тип заявки */
	requestType: number;
	/** Тип устройства */
	deviceType: number;
	/** Производитель */
	deviceVendor: number;
	/** Модель устройства */
	deviceModel: number;
}
