import { BaseObject } from "../core/BaseObject";
import { RequestRegistrationFilterElementDto } from "./request-registration-filter-element-dto";

export class RequestRegistrationFilterDto extends BaseObject {
    requestRegistrationFilterId: number;
    name: string;
    description: string;
    isActive: boolean;
    version: number;
    isDeleted: boolean;
    requestRegistrationFilterElements: RequestRegistrationFilterElementDto[] = [];
}