import { UploadFile } from '../attachment/upload-file';

export class RequestFile {
	fileId: number;
	requestId: number;
	createdDate: Date;
	authorUserName: string;
	fileName: string;
	fileExtension: string;
	attachmentTypeIds: number[] = [];
	attachmentTypeNames: string;
	requestCommentText: string;
	activityId?: number;
	activityName: string;
	sizeInBytes?: number;

	/** служебное поле для обновления файла*/
	fileData: string;

	/** служебное поле для добавления пачки файлов */
	files: UploadFile[] = [];
}
