import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { EthUser } from '../models/ethereum-gate/eth-user';
import { ActionDataResult } from '../models/core/ActionDataResult';

@Injectable()
export class EthereumGateService extends DataService<EthUser, EthUser> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'ethereumGate';
	}

    public getUsers(mobilePhone: string, email: string, name: string): Observable<ActionDataResult<EthUser[]>> {
		var apiDownloadUrl = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/getUsers?mobilePhone=${mobilePhone}&email=${email}&name=${name}`;
		return this.http.get<ActionDataResult<EthUser[]>>(apiDownloadUrl);
	}
}