import { ServiceCenterEngineersData } from "./service-center-engineers-data";

export class ServiceCenterEngineers {
		isMapRequest: boolean;
    data: ServiceCenterEngineersData[];

    constructor(data: ServiceCenterEngineersData[], isMapRequest = false) {
			this.isMapRequest = isMapRequest;
      this.data = data;
    }
}
