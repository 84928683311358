import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { UserFilter } from '../models/user-filter/user-filter';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { ActionResult } from '../models/core/ActionResult';

@Injectable()
export class UserFiltersService extends DataService<UserFilter, UserFilter> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'user-filters';
	}

	public setDefaultUserFilter(newDefaultUserFilterId: number): Observable<ActionResult> {
		return this.putQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/set-default-user-filter`, {
			newDefaultUserFilterId: newDefaultUserFilterId
		});
	}

}