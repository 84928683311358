import { Component, Input, OnInit, ViewChild, ElementRef, Directive, HostListener, ViewChildren, QueryList } from '@angular/core';
import { RequestsService } from '../../services/requests.service';
import { NewRequestDevice } from '../../models/request/new-request/new-request-device';
import { Request } from '../../models/request/request';
import { IntlService } from '@progress/kendo-angular-intl';
import { state, style, trigger } from '@angular/animations';
import { UserGroup } from '../../models/user-group/user-group';
import { NewCommentModel } from '../../models/request/comments/new-comment-model';
import { CommentKindEnum } from '../../enums/comment-kind.enum';
import { AppService } from '../../../app.service';
import { SecurityService } from '../../../core/services/security.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../core/services/notification.service';
import { ActionResult } from '../../models/core/ActionResult';
import { NotificationType } from '../../../core/services/notification-type';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DeviceStatus, RequestType } from '../../enums';
import { Attachment } from '../../models/attachment';
import { CarouselComponent} from 'ngx-bootstrap/carousel'
import { DevicesService } from '../../services/devices.service';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { Device } from '../../models';
import { PinchZoomComponent } from 'ngx-pinch-zoom';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { RequestDevicesService } from '../../services/request-devices.service';
import { ReserveResult } from '../../enums/reserve-result.enum';

@Component({
	selector: 'image-popup-extended',
	templateUrl: './image-popup-extended.component.html',
	styleUrls: [
		'./image-popup-extended.component.scss',
		'../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	],
	animations: [
          trigger('rotatedState', [
            state('rotate0', style({ transform: 'rotate(0)' })),
            state('rotate90', style({ transform: 'rotate(90deg) scale(0.97)' })),
            state('rotate180', style({ transform: 'rotate(180deg)' })),
            state('rotate270', style({ transform: 'rotate(270deg) scale(0.97)' }))
        ])
	]
})


export class ImagePopupExtended implements OnInit {
	public form: FormGroup;
	@Input() requestId: number;
	@Input() entity: Request;
	@Input() serviceCenter: string = ''
	@Input() userGroups: UserGroup[] = [];
	@Input() fileId: number;
	dataChanged = false;
	isDefaultGeneral = false;
	show = false;
	color = "rgba(0,0,0,0.1)";

	zoomed = false;

	pdfZoom = 1;
	minPdfZoom = 1;
	maxPdfZoom = 3;

	currentPdfPage = 1;

	@Input() canEditFinishFactDate: boolean = true;

	@Input() files: Attachment[] = [];
	commentToAdd: string = '';

	@ViewChild("carousel") 
	scroll: CarouselComponent;
	state: string = 'rotate0';
	dataSaving = false;
	commentSaving = false;

	@ViewChildren('zoom') pinchZoomComponents: QueryList<PinchZoomComponent>;

	states: string[] = ['rotate0','rotate90','rotate180', 'rotate270']

	requestUninstallDevices: NewRequestDevice[] = [];
	requestDevices: NewRequestDevice[] = [];
	
	constructor(private requestsService: RequestsService,
		private _fb: FormBuilder,
		private intl: IntlService,	
		private appService: AppService,
		private securityService: SecurityService,
		private notificationService: NotificationService,
		private deviceService: DevicesService
	) {
	}

	ngOnInit(): void {
		this._buildForm();
		this.requestsService.getRequestUninstallDevices(this.requestId)
			.subscribe(items => {
			 	this.requestUninstallDevices = items
					.filter(x => x.deviceStatusId === DeviceStatus.deInstalled || 
						x.deviceStatusId === DeviceStatus.deliveredToWarehouse ||
						x.deviceStatusId === DeviceStatus.receivedAtWarehouse ||
						x.deviceStatusId === DeviceStatus.returnToClassicWarehouse)
					.filter(
						(item, i, arr) => arr.findIndex(t => t.serialNumber === item.serialNumber) === i);	
		});

		var state = this.loadDeviceState();
		this.requestsService.getRequestDevices(this.requestId).subscribe(devices => {
			if (this.excludeCancellatedReserveDevices){
				this.requestDevices = devices.filter(x => x.reserveResultId == <number>ReserveResult.installedOnTsp);
			} else {
				this.requestDevices = devices;
			}
		});
		setTimeout(() => {
			this.initInitialImage();
			this.show = true;
		}, 100);
	}

	private _buildForm(): void {
		this.form = this._fb.group({
			externalId: [{value: null, disabled: true}],
			tidNumbers: [{value: null, disabled: true}],
			finishDateFact: [{value: null, disabled: true}],
			finishDateFactLocal: [null, Validators.required],
			serviceCenter: [{value: null, disabled: true}],
			merchantContragentName: [{value: null, disabled: true}],
			customerObjectAddress: [{value: null, disabled: true}],
			userGroup: [null, Validators.required],
			commentToAdd: [{value: null}]	
		});
	}

	loadDeviceState(){
		var customState: DataSourceRequestState = {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDate', dir: 'desc' }]
		};
		return customState;
	}

	initInitialImage(){
		var index = this.files.findIndex(x => x.attachmentId === this.fileId);
		this.scroll.activeSlide = index ? index : 0;
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	onMouseWheel(event){
		event.preventDefault();
		if (event.deltaY < 0) {
			if (this.pdfZoom <= this.maxPdfZoom){
				this.pdfZoom += 0.1;
			}
		} else {
			if (this.pdfZoom > this.minPdfZoom){
				this.pdfZoom -= 0.1;
			}
		}
	}

	getListBody(item: NewRequestDevice): string {
		if (item){
			if (item.nomenclature){
				return item.nomenclature;
			} else {
				var supplier = item.deviceSupplierName;
				return `${supplier}, ${item.deviceModelName}`;
			}
		}
	}
	
	performerUserGroupChange(event: number){
		if (event){
			this.dataChanged = true;
		}
	}

	finishDateFactLocalChange(event: Date){
		this.dataChanged = true;
	}

	changeCommentType(bool: boolean){
		this.isDefaultGeneral = bool;
	}

	zoom(){
		var activePinchZoom  = this.pinchZoomComponents.toArray()[this.scroll.activeSlide];
		if (activePinchZoom){
			activePinchZoom.toggleZoom();
		}
		if (!this.zoomed){
			this.pdfZoom = 2;
		} else {
			this.pdfZoom = 1;
		}
		this.zoomed = !this.zoomed;
	}
	
	addComment(){
		this.commentSaving = true;
		var commentModel = new NewCommentModel;
		commentModel.text = this.commentToAdd;
		commentModel.requestId = this.requestId;
		commentModel.commentKind = this.isDefaultGeneral  ? CommentKindEnum.general : CommentKindEnum.internal;
		this.requestsService.addComment(commentModel).subscribe(x => { 
			switch (x.type) {
				case HttpEventType.Response:
					if (x instanceof HttpResponse) {
						var result = <ActionResult>x.body;

					if (!result.isSuccessful) {

						this.notificationService.error({
							title: 'Ошибка',
							message: result.errorDescription,
							notificationType: NotificationType.SweetAlert
						});

						this.commentSaving = false;
						return;
					} else {
						this.notificationService.success({
							title: '',
							message: "Комментарий успешно отправлен",
							notificationType: NotificationType.Toast
						});
						}
						this.commentSaving = false;
					}
			}
		});
	}
	
	rotateLeft(){		
		//Поровот картинки
		if (!this.isCurrentSlidePdf()){
			let position = this.states.indexOf(this.state);	
			if(position === 0){
				this.state = this.states[3];
			} else {
				this.state = this.states[position-1];
			}	
		} else {
			//Поровот PDF
			var file = this.getCurrentFile();
			if (file.rotation == 0){
				file.rotation = 270;
			} else {
				file.rotation = file.rotation - 90;
			}
		}	
	}
	  
	rotateRight(){
		//Поровот картинки
		if (!this.isCurrentSlidePdf()){
			let position = this.states.indexOf(this.state);
			if(position === 3){
				this.state = this.states[0];
			} else {
				this.state = this.states[position+1];
			}
		} else {
			//Поровот PDF
			var file = this.getCurrentFile();
			if (file.rotation == 270){
				file.rotation = 0;
			} else {
				file.rotation = file.rotation + 90;
			}
		}
	}
	
	nextImage(){
		this.scroll.nextSlide();

		//Сброс ротации
		this.state = this.states[0];
		
		this.resetPdfSettings();
	}

	previousImage(){
		this.scroll.previousSlide();

		//Сброс ротации
		this.state = this.states[0];

		this.resetPdfSettings();
	}

	nextPage() {
		if (this.currentPdfPage < this.getCurrentPdfNumOfPages()) {
			this.currentPdfPage = this.currentPdfPage + 1;
		}
	}

	prevPage() {
		if (this.currentPdfPage > 1) {
			this.currentPdfPage = this.currentPdfPage - 1;
		}
	}
	
	parseMultiTimezoneDate(dateTime: any) {
		if (dateTime == null) {
			return '';
		}

		var parsedDate = new Date();

		if (dateTime instanceof Date) {
			parsedDate = dateTime;
		} else if (typeof dateTime === 'string') {
			if (dateTime.startsWith("1753")) {
				return '';
			}

			parsedDate = this.intl.parseDate(dateTime);
		}

		return `${this.intl.formatDate(parsedDate, 'dd.MM.yyyy HH:mm')} ${this.entity.utcTimezoneShiftString}`;
	}

	saveChanges(){
		this.validateSaveChanges().then(result => {
			if (result) {
		const changedControls = Object.keys(this.form.controls).filter(key => this.form.controls[key].dirty);
		if (changedControls.includes('finishDateFactLocal')) {
			this.dataSaving = true;
			this.requestsService.changeFinishDateFact(this.entity.requestId, this.entity.finishDateFactLocal, true)
				.subscribe(result => {
					this.dataSaving = false;

					if (!result.isSuccessful)
						return;
				})
		}
				if (changedControls.includes('userGroup')) {
			this.dataSaving = true;
			let comment = "Группа ответственного изменена из компонента для просмотра изображений";
			this.requestsService.changePerformerGroup(this.requestId, this.entity.performerUserGroupId, comment).subscribe(result => {
				this.dataSaving = false;

				if (!result.isSuccessful)
						return;
			 });
		}
		this.dataChanged = false;
	}
		});
	}

	getImageUrl(file) {
		return `data:image/png;base64,${file}`
	}

	isCurrentSlidePdf(){
		if (!this.scroll)
			return false;
		let value = this.isPdf(this.files[this.scroll.activeSlide].attachmentExtension);
		return value;
	}

	isPdf(extension: string){
		extension = extension.toLowerCase();

		return extension === '.pdf';
	}

	public isNonEmptyString(str: string): boolean {
		return str && str.length > 0;
	}
	public get canEditPerformerInSelectedSpecStates(): boolean {
		return this.securityService.canEditRequestPerformerInSelectedSpecStates();
	}

	get hasEditRequestClaim(): boolean {
		return this.securityService.hasClaim('cat-requests-edit');
	}

	public get isAdmin(): boolean {
		return this.securityService.isAdmin();
	}

	get coordiantorCanEdit() {
		return !this.securityService.isSCPartnerCoordinator() || (this.securityService.isSCPartnerCoordinator() && !this.entity.serviceCenterIsAlienGroup);
	}

	get excludeCancellatedReserveDevices(): boolean { 
		return this.entity.requestTypeId == RequestType.install || 
			   this.entity.requestTypeId == RequestType.changeConfig;
	}

	async validateSaveChanges() {
		const changedControls = Object.keys(this.form.controls).filter(key => this.form.controls[key].dirty);
		if (changedControls.includes('finishDateFactLocal')) {
			try {
				const result = await this.requestsService.checkNewFinishFactDate(this.requestId, this.entity.finishDateFactLocal).toPromise();
				if (!result.isSuccessful) {
					this.notificationService.confirmation({
						message: result.errorDescription,
						type: 'question',
						cancelButtonText: 'Ок',
						showCloseButton: true,
						showConfirmButton: false
					}, () => { }, () => this.dataSaving = false);
					return false;
				} else {
					return true;
			}
			} catch (error) {
				this.notificationService.error({
					title: 'Validation error',
					message: error,
					notificationType: NotificationType.Toast
				});
				return false;
			}
		}
	}

	callBackFn(pdf: PDFDocumentProxy, item: Attachment) {
		item.pdfNumberOfPages = pdf.numPages;
	}

	getCurrentPdfNumOfPages() {
		return this.files[this.scroll.activeSlide].pdfNumberOfPages;
	}

	getCurrentFile(): Attachment {
		return this.files[this.scroll.activeSlide];
	}

	resetPdfSettings() {
		this.currentPdfPage = 1;
		this.pdfZoom = 1;
	}
}