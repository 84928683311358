import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { Agreement } from "../../../shared/models/agreement/agreement";
import { AgreementDistanceCalculationType } from "../../../shared/models/agreement/agreement-distance-calculation-type";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { DistanceCalculationType } from "../../../shared/enums/distance-calculation-type.enum";

@Component({
	selector: 'calc-distance',
	templateUrl: './calc-distance.component.html',
	styleUrls: []
})
export class CalcDistance implements OnInit {

	@Input() entityViewModel: EntityViewModel<Agreement>;
	@Input() canEditForm: boolean;
	@Input() cities: KeyValueObject[] = [];
	@Input() distanceCalculationTypes: KeyValueObject[] =[];
	@Output() onSave: EventEmitter<any> = new EventEmitter();
	@Output() onRemoveHandler: EventEmitter<any> = new EventEmitter();

	ngOnInit(): void {
	}

	createNewDistanceCalculationType(): any {
		return () => {
			const distanceCalculationType = new AgreementDistanceCalculationType();
			return distanceCalculationType;
		};
	}

	saveDistanceHandler(event: any): void {
		this.onSave.emit(event);
	}

	removeHandler(event: any): void {
		this.onRemoveHandler.emit(event);
	}

	distanceTypeCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {
		if (!this.canEditForm || columnIndex === 4) {
			return;
		}
		sender.editRow(rowIndex);
	}

	getCityNames(dataItem: AgreementDistanceCalculationType): string {
		const cityNames = this.cities.filter(x => dataItem.cityIds.includes(x.id)).map(x => x.name);
		return cityNames.join(', ');
	}

	getDistanceCalculationTypeName(dataItem: AgreementDistanceCalculationType): string {
		const distanceCalculationTypes = this.distanceCalculationTypes.filter(x => x.id === dataItem.distanceCalculationTypeId);
		return distanceCalculationTypes.length > 0 ? distanceCalculationTypes[0].name : '';
	}

	isDistanceCalculationTypeWithBufferZone(dataItem: AgreementDistanceCalculationType): boolean {
		return dataItem && dataItem.distanceCalculationTypeId === <number>DistanceCalculationType.fromPerformerCityBorderWithBufferZone;
	}

	onCheckDeafaultDistanceCalculationType(dataItem: AgreementDistanceCalculationType): void {
		if (dataItem.isDefault) {
			this.entityViewModel.entity.agreementDistanceCalculationTypes
				.map(distanceCalculationType => {
					if (distanceCalculationType !== dataItem && distanceCalculationType.isDefault) {
						distanceCalculationType.isDefault = false;
					}
				});

			dataItem.cityIds = [];
		}
	}

	canSaveDistanceCalculationType(dataItem: AgreementDistanceCalculationType): boolean {
		if (dataItem.distanceCalculationTypeId === <number>DistanceCalculationType.fromPerformerCityBorderWithBufferZone && !dataItem.distanceBufferZone) {
			return false;
		}

		if (dataItem.isDefault) {
			return dataItem.distanceCalculationTypeId ? true : false;
		}

		return dataItem.cityIds && dataItem.cityIds.length > 0 && dataItem.distanceCalculationTypeId ? true : false;
	}
}
