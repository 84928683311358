import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { OvertimeComponent } from './overtime/overtime.component';
import { ExpertiseListPage } from './expertises/list/list.page';
import { MkReportPage } from './mk/mk-report.page';
import { PsbReportPage } from './psb/psb-report.page';
import { PsbRequestsMatchingReport } from "./psb-requests-matching/psb-requests-matching.page";
import { RetEquipReportPage } from "./retequip/retequip-report.page";
import { GeneralEquipmentReport } from './general-equipment-report/general-equipment-report.page';
import { ShelfLife1cReportPage } from './shelf-life-1c/shelf-life-1c-report.page';
import { StockExchangeRequestsReportPage } from './stock-exchange-requests/stock-exchange-requests-report.page';
import { TimeFundReportPage } from './time-fund/time-fund-report.page';
import { ExchangeSmzReportPage } from './exchange-smz/exchange-smz-report.page';
import { ExpertisePeriodReport } from './expertiseperiod/expertiseperiod.page';
import { TidObjectsTime } from './tid-objects-time/tid-objects-time.page';
import { StopReasonPage } from './stop-reason/stop-reason.page';

export const reportsRoutes: Routes = [
	{ path: "overtime", component: OvertimeComponent, canActivate: [AuthGuard] },
	{
		path: "expertises",
		component: ExpertiseListPage,
		canActivate: [AuthGuard],
	},
	{ path: "mk", component: MkReportPage, canActivate: [AuthGuard] },
	{ path: "sr", component: StopReasonPage, canActivate: [AuthGuard] },
	{ path: "psb", component: PsbReportPage, canActivate: [AuthGuard] },
	{
		path: "psb-requests-matching",
		component: PsbRequestsMatchingReport,
		canActivate: [AuthGuard],
	},
	{ path: 'retequip', component: RetEquipReportPage, canActivate: [AuthGuard] },
	{ path: 'general-equipment', component: GeneralEquipmentReport, canActivate: [AuthGuard] },
	{ path: 'shelflife', component: ShelfLife1cReportPage, canActivate: [AuthGuard] },
	{ path: 'stockexchangerequests', component: StockExchangeRequestsReportPage, canActivate: [AuthGuard] },
	{ path: 'timefund', component: TimeFundReportPage, canActivate: [AuthGuard] },
	{ path: 'exchangesmzrequests', component: ExchangeSmzReportPage, canActivate: [AuthGuard] },
	{ path: 'expertiseperiod', component: ExpertisePeriodReport, canActivate: [AuthGuard] },
	{ path: 'tid-objects-time', component: TidObjectsTime, canActivate: [AuthGuard] },
];

@NgModule({
	imports: [],
	exports: [RouterModule],
})
export class ReportsRoutingModule {
	public static components = [
		OvertimeComponent,
		ExpertiseListPage,
		MkReportPage,
		StopReasonPage,
		PsbReportPage,
		PsbRequestsMatchingReport,
		RetEquipReportPage,
		GeneralEquipmentReport,
		ShelfLife1cReportPage,
		StockExchangeRequestsReportPage,
		TimeFundReportPage,
		ExchangeSmzReportPage,
		ExpertisePeriodReport,
		TidObjectsTime
	];
}
