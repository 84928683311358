import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../../app.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { DeviceItem } from '../../../models/device/device-item';
import { EntityViewModel } from '../../../models/core/EntityViewModel';
import { DevicesService } from '../../../services/devices.service';
import { LookupService } from '../../../services/lookup.service';
import { ComponentPageBase } from '../../component-page-base/component-page-base';
import { CategoryType } from '../../../enums/category-type.enum';

@Component({
	selector: 'categories-device',
	templateUrl: './device.page.html',
	styleUrls: [
		'./device.page.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class DevicePage extends ComponentPageBase<DeviceItem> implements OnInit {

	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.devices;

    constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: DevicesService,
        private lookupService: LookupService
    ) {
        super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/devices';
		this.appService.pageTitle = 'Новое устройство';
    }

    ngOnInit(): void {
        this.entityId = this.route.snapshot.paramMap.get('deviceId');
        if (this.entityId != null) {
			this.dataService.getById(+this.entityId).subscribe(vm => {
				this.entityName = `${vm.entity.name}`;
				this.dataBind(vm);
			});
		} else {
			this.entityViewModel = new EntityViewModel<DeviceItem>();
			this.entityViewModel.entity = new DeviceItem();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;
		}
    }
}
