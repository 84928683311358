import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { NotificationService } from "../../../core/services/notification.service";
import { ActionDataResult } from "../../models/core/ActionDataResult";
import { CreateDeliveryResponse } from "../../models/box-berry/create-delivery-response";
import { CalculateDeliveryCostResponse } from "../../models/box-berry/calculate-delivery-cost-response";
import { CreateIntakeResponse } from "../../models/box-berry/create-intake-response";
import { CreateIntakeRequest } from "../../models/box-berry/create-intake-request";
import { RequestsService } from "../requests.service";
import { RequestChangeLog } from "../../models/request/request-change-log";
import { CommentKindEnum } from "../../enums/comment-kind.enum";

@Injectable()
export class BoxBerryService {
    protected controllerVersion = 1;

    constructor(protected http: HttpClient,
        protected notificationService: NotificationService,
        private requestsService: RequestsService) {
    }

    protected baseUrl(): string {
        return `${environment.dogAddress}v${this.controllerVersion}`;
    }

    public createDelivery(transportCompany: string, externalId: string, requestId: number): Observable<CreateDeliveryResponse> {
        let errorMessage = `Произошла ошибка при автоматическом создании посылки в ТК ${transportCompany}. Пожалуйста, оформите доставку вручную в личном кабинете ТК.`;
        let propertyName = 'Заявка на создание доставки';
		return this.http.post<ActionDataResult<CreateDeliveryResponse>>(`${this.baseUrl()}/${transportCompany}`, { externalId : externalId})
			.pipe(tap(resp => {
				if (!resp.isSuccessful) {
                    this.notificationService.warning({ title: `Сервис ${transportCompany}`, message: errorMessage });
                    this.addLoggingInfo(errorMessage, propertyName, requestId);
				} else {
                    let message = `Заявка на доставку в ТК ${transportCompany} успешно оформлена. Трек-номер: ${resp.data.transportCompanyNumber}`;
                    this.notificationService.info({ title: `Сервис ${transportCompany}`, message: message });
                    this.addLoggingInfo(message, propertyName, requestId);
                }
			}, errorResponse => {
                this.notificationService.warning({ title: `Сервис ${transportCompany}`, message: errorMessage });
                this.addLoggingInfo(errorMessage, propertyName, requestId);
			}))
            .map(m => { return m.data });
    }

    public calculateDeliveryCost(transportCompany: string, externalId: string): Observable<CalculateDeliveryCostResponse> {
        return this.http.get<ActionDataResult<CalculateDeliveryCostResponse>>(`${this.baseUrl()}/${transportCompany}/calculate-delivery-cost/?externalId=${externalId}`)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: `Сервис ${transportCompany}`, message: resp.errorDescription });
            }
        }, errorResponse => {
            this.notificationService.warning({ title: `Сервис ${transportCompany}`, message: errorResponse.errorDescription });
        }))
        .map(m => { return m.data });
    }

    public createIntake(transportCompany: string, request: CreateIntakeRequest, requestId: number): Observable<CreateIntakeResponse> {
        let errorMessage = `Произошла ошибка при автоматическом создании заявки на забор груза в ТК ${transportCompany}. Пожалуйста, оформите забор груза вручную в личном кабинете ТК.`;
        let propertyName = 'Заявка на забор груза';
        return this.http.post<ActionDataResult<CreateIntakeResponse>>(`${this.baseUrl()}/${transportCompany}/create-intake`, request)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: `Сервис ${transportCompany}`, message: errorMessage });
                this.addLoggingInfo(errorMessage, propertyName, requestId);
            } else {
                let message = `Заявка на забор груза в ТК ${transportCompany} успешно оформлена`;
                this.notificationService.info({ title: `Сервис ${transportCompany}`, message: message });
                this.addLoggingInfo(message, propertyName, requestId);
            }
        }, errorResponse => {
            this.notificationService.warning({ title: `Сервис ${transportCompany}`, message: errorMessage });
            this.addLoggingInfo(errorMessage, propertyName, requestId);
        }))
        .map(m => { return m.data });
    }

    private addLoggingInfo(
        message: string,
        propertyName: string,
        requestId: number){
        this.requestsService.addChangeLog(new RequestChangeLog({
            propertyName: propertyName,
            comment: message,
            oldValue: "",
            newValue: "",
            requestId: requestId
        })).subscribe(() => {});
        this.requestsService.addComment({
            text: message,
            requestId: requestId,
            activityId: null,
            uploadAttachments: [],
            notifyCustomer: false,
            notifyEngineer: false,
            engineerNotifyWays: [],
            commentKind: CommentKindEnum.system
        }).subscribe(x => { });
    }
}