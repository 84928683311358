import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { AgreementListItem } from '../../../shared/models/agreement/agreement-list-item';
import { StatePersistingService } from "../../../shared/services/state-persisting.service";
import { AgreementsService } from '../../../shared/services/agreements.service';
import { AppService } from "../../../app.service";
import { Router } from "@angular/router";
import { LookupService } from "../../../shared/services/lookup.service";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { Component } from "@angular/core";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";

@Component({
	selector: 'agreement-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class AgreementListPage extends ListPageBase<AgreementListItem> {

	contragents: KeyValueObject[] = [];
	agreementStates: KeyValueObject[] = [];
	agreementTypes: KeyValueObject[] = [];

	constructor(
		protected appService: AppService,
		protected router: Router,
		protected dataService: AgreementsService,
		protected persistingService: StatePersistingService,
		private lookupService: LookupService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Договоры';
	}

	onListPageInit(): void {
		this.lookupService.getData('contragents', null).subscribe(data => this.contragents = data);
		this.lookupService.getData('agreement-states', null).subscribe(data => this.agreementStates = data);
		this.lookupService.getData('agreement-types', null).subscribe(data => this.agreementTypes = data);
	}

	processListItems(items: AgreementListItem[]): void {

	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/categories/agreement', dataItem.agreementId]);
	}

	onClickParentContractLink(dataItem: AgreementListItem): void {
		if (dataItem.parentAgreementId) {
			window.open(`/categories/agreement/${dataItem.parentAgreementId}`, '_blank');
		}
	}

}
