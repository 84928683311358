import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ArticleDto } from '../../../models/article/article.dto';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { RequestsService } from '../../../services/requests.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { UsersService } from '../../../../admin/users/users.service';
import { DevicesService } from '../../../services/devices.service';
import { ArticlesService } from '../../../services/articles.service';
import { SendStateWithLogic } from '../../../models/request/send-state-with-logic';
import { ActivityStatusEnum, DeviceStatus, RequestType } from '../../../enums';
import { RequestDevicesService } from '../../../services/request-devices.service';
import { NotificationType } from '../../../../core/services/notification-type';

@Component({
	selector: 'set-sn-components',
	templateUrl: './set-sn-somponents.html',
	styleUrls: ['../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class SetSnComponents implements OnInit {

    @ViewChild("standartPerfectScrollbarArticles")
	standartPerfectScrollbarArticles?: any;

    @Output()
	onContinueEvent = new EventEmitter();

    @Input()
    requestTypeId: number;

    @Input()
    requestId: number;

    @Input()
    activityStatusId: number;

    @Input()
    isEmbedded: boolean = false;

    @Input()
    showReadOnlySection: boolean = false;

    @Input()
    readOnlyHeaderText: string;

    @Input()
    readOnlyComponentsDeviceStatusId: number;

    @Input()
    selectedComponentsDeviceStatusId: number = <number>DeviceStatus.shippedOnRequest;

    @Input()
    headerText: string = "Уточнить комплектующие";

    @Input()
    returningBrokenDevices: boolean = false;

    componentsArticlesSource: ArticleDto[] = [];
	componentsArticlesData: ArticleDto[] = [];
	selectedComponentsArticles: ArticleDto[] = [];
    readOnlyComponentsArticles: ArticleDto[] = [];

    constructor(
		public dialog: DialogRef,
		protected requestsService: RequestsService,
		protected notificationService: NotificationService,
		protected usersService: UsersService,
		protected devicesService: DevicesService,
        protected articlesService: ArticlesService,
        protected requestDevicesService: RequestDevicesService
	) {

	}

    get cardHeight(): number {
		return window.innerHeight - 300;
	}

    ngOnInit(): void {
        let getArticlesForDropDown = this.returningBrokenDevices 
            ? this.requestDevicesService.getRequestComponents(this.requestId, this.readOnlyComponentsDeviceStatusId)
            : this.articlesService.getComponentsArticles();

        getArticlesForDropDown.subscribe(res => {
            this.componentsArticlesSource = res.sort((a, b) => a.name.localeCompare(b.name));
            this.componentsArticlesData = res.sort((a, b) => a.name.localeCompare(b.name));

            if (this.showReadOnlySection) {

                if (this.returningBrokenDevices) {
                    this.readOnlyComponentsArticles = res;
                } else {
                    this.requestDevicesService.getRequestComponents(this.requestId, this.readOnlyComponentsDeviceStatusId).subscribe(r => {
                        this.readOnlyComponentsArticles = r
                        this.readOnlyComponentsArticles.forEach(x => x.name = x.modelName);
                    });
                }
            }

            this.requestDevicesService.getRequestComponents(this.requestId, this.selectedComponentsDeviceStatusId).subscribe(r => {
                let resTemp: ArticleDto[] = [];
                r.forEach(x => {
                    let temp = Object.assign({}, x);
                    temp.id = Math.random();
                    resTemp.push(temp);
                });
                this.selectedComponentsArticles = resTemp;
            });
        });
    }

    handleFilter(value: string) {
		this.componentsArticlesData = this.componentsArticlesSource
		.filter(s => {
			return (!!s.name && s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) || 
				(!!s.deviceModelName && s.deviceModelName.toLowerCase().indexOf(value.toLowerCase()) !== -1)
		})
        .sort((a, b) => a.name.localeCompare(b.name));
	}

    addNewComponentArticle() {

		this.selectedComponentsArticles.push(new ArticleDto);

		setTimeout(() => {
			if (this.standartPerfectScrollbarArticles) {
				this.standartPerfectScrollbarArticles.nativeElement.scrollTop = this.standartPerfectScrollbarArticles.nativeElement.scrollHeight;
			}
		}, 100);
	}

    removeComponentArticle(index: number) {
		this.selectedComponentsArticles = this.selectedComponentsArticles.filter(x => x.id !== index);
	}

    public copyArticle(index: number) {
        window.navigator['clipboard'].writeText(this.readOnlyComponentsArticles[index].name);
        
        this.notificationService.success({
            title: '',
            message: 'Скопировано в буфер',
            notificationType: NotificationType.Toast
        });
    }

    continue() {
        switch (this.requestTypeId) {
			case <number>RequestType.remoteInstall:
                let stateFromUi = this.activityStatusId === <number>ActivityStatusEnum.issuedPartsReplacement
                    ? "issuedCompAdjustment_fromUi"
                    : "storeEquipIssued_fromUi";

                this.requestsService.sendStateWithLogic(
                    new SendStateWithLogic(this.requestId, stateFromUi, null, this.selectedComponentsArticles))
                    .subscribe(() => {
                        this.cancel();
                    });
				break;
		
			default:
                if (this.onContinueEvent) {
                    this.onContinueEvent.emit(this.selectedComponentsArticles);
                }
    
                this.cancel();
				break;
		}
    }

    cancel() {
		if (this.dialog)
			this.dialog.close();
	}

    public continueDisabled(): boolean {
        return this.selectedComponentsArticles.some(x => !x.articleId);
    }

    valueChange(test: ArticleDto, index: number) {
        this.selectedComponentsArticles[index] = Object.assign({}, test);
        this.selectedComponentsArticles[index].id = Math.random();
    }

    trackByFn(index, item) {
        return item.id;
	}
}