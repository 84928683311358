import { Component, OnInit, EventEmitter, Output, Injector, Input } from '@angular/core';
import { LookupService } from '../../../services/lookup.service';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { UserGroupsService } from '../../../services/user-groups.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SetCommentModel } from './set-comment.model';
import { DevicesService } from '../../../services/devices.service';
import { ActivitiesService } from '../../../services/activities.service';

@Component({
	selector: 'set-comment',
	templateUrl: './set-comment.html',
	styleUrls: ['./set-comment.scss']
})
export class SetComment implements OnInit {

	@Output()
	onContinueEvent = new EventEmitter<SetCommentModel>();

	@Input()
	setCommentModel: SetCommentModel = new SetCommentModel('');
	@Input()
	requestId: number;
	@Input()
	activityId: number;
	@Input()
	nextActivityStatusId: number;
	@Input()
	hideCommentKind: boolean = false;
	@Input()
	showActivityResult: boolean = false;
	@Input()
	useInstallationCompleteSuccessfull: boolean = false;

	activityResults: KeyValueObject[] = [];
	dataSaving: boolean = false;

	constructor(
		public activitiesService: ActivitiesService,
		public devicesService: DevicesService,
		public dialog : DialogRef
	) { }

	ngOnInit() {
		if (this.showActivityResult) {
			this.activitiesService.getActivityResults(this.activityId, this.nextActivityStatusId).subscribe(resp => {
				if (!!resp) {
					let respKv = resp.map(m => new KeyValueObject(m.activityResultId, m.activityResult));
					this.activityResults = respKv;
					if (resp.length === 1) this.setCommentModel.activityResultId = respKv[0].id;
				}
			});
		}
	}

	cancel() {
		this.dialog.close();
	}

	continue() {
		this.dataSaving = true;
		if (this.useInstallationCompleteSuccessfull) {
			this.devicesService.installationCompleteSuccessfull(this.requestId, [], this.activityId).subscribe(() => {
				this.onContinueEvent.emit(this.setCommentModel);
				this.cancel();
				this.dataSaving = false;
			});
		}
		else {
			this.onContinueEvent.emit(this.setCommentModel);
			this.cancel();
			this.dataSaving = false;
		}
	}
}
