export enum ReserveResult {
    /** Не найдено */
	notFound = 1,

    /** Резерв аннулирован */
	reserveCanceled = 2,

    /** Не забронирован */
	notBooked = 3,

    /** Возвращено на склад */
    returnedToWarehouse = 4,

    /** Сделан запрос резерва */
    reserveRequest = 5,

    /** Ожидает выдачи инженеру */
    pendingIssueToEngineer = 6,

    /** Запрос на снятие резерва */
    requestForReserveCancelling = 7,

    /** Ожидает возврата на склад */
    awaitingReturnToWarehouse = 8,

    /** Забронирован */
    booked = 9,

    /** Оборудование получено инженером */
    equipmentReceivedByEngineer = 10,

    /** Ожидает перемещения на склад СЦ */
    awaitingTransferToServiceCenterWarehouse = 11,

    /** Установлено на тсп */
    installedOnTsp = 13,

    /** Получение инженером */
    receivingEngineer = 14,

    /** Неисправно */
    broken = 15,
}
