import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { tap, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NotificationType } from '../../core/services/notification-type';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { AddressSuggestion } from '../models/dadata-address/address-suggestion';

@Injectable()
export class DaDataService {

	protected controllerVersion = 1;
	protected controllerName = "dadata";

	constructor(
		public http: HttpClient,
		public notificationService: NotificationService
	) { }

	public suggest(address: string): Observable<AddressSuggestion[]> {

		return this.http.post<ActionDataResult<AddressSuggestion[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/suggest`, { address: address })
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}
	
}