﻿export class ContragentContact {
	contactId: number;
	serviceCenterId?: number;
	contragentId: number;
	fullName: string;
	email: string;
	phone: string;
	isBasic: boolean;
	responsibilityAreaId: number;
}
