import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NotificationType } from "../../../../core/services/notification-type";
import { NotificationService } from "../../../../core/services/notification.service";

@Component({
	selector: 'mobile-text-dialog',
	templateUrl: './mobile-text-dialog.component.html',
	styleUrls: [
		'./mobile-text-dialog.component.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})


export class MobileTextDialog {	
	constructor(
		private notificationService: NotificationService,
	) {
	}

	fontSize: number = 16;
	maxFontSize: number = 32;
	minFontSize: number = 16;
	@Input() text: string = "";
	@Output() closeEvent = new EventEmitter<any>();
	@ViewChild('textArea') textArea: ElementRef;

	increaseFontSize() {
		if (this.fontSize == this.maxFontSize)
			return;
		this.fontSize += 2;
		if (this.textArea && this.textArea.nativeElement) {		
			this.textArea.nativeElement.style.height = 'auto';
		  	this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight * 1.2 + 100) + 'px';
		}
	}

	decreaseFontSize() {
		if (this.fontSize == this.minFontSize)
			return;
		this.fontSize -= 2;
		if (this.textArea && this.textArea.nativeElement) {		
			this.textArea.nativeElement.style.height = 'auto';
		  	this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight) + 'px';
		}
	}

	public close() {
		this.closeEvent.emit();
	}

	copyToClp(txt, message = ""){
		var m = document;
		txt = m.createTextNode(txt);
		var w = window;
		var b = m.body;
		b.appendChild(txt);
		var d = m.createRange();
		var g = w.getSelection;
		d.selectNodeContents(txt);
		g().removeAllRanges();
		g().addRange(d);
		m.execCommand('copy');
		g().removeAllRanges();
		txt.remove();

		this.notificationService.success({
			title: 'Успех',
			message: message,
			notificationType: NotificationType.Toast
		});
	}
}