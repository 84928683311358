import { Component, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { WarehouseListItem } from '../../../shared/models/warehouse/warehouse-list-item';
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { WarehousesService } from '../../../shared/services/warehouses.service';

@Component({
	selector: 'categories-warehouses-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class WarehousesListPage extends ListPageBase<WarehouseListItem> implements AfterViewInit {
    @ViewChild('filterAllInput') filterAllInput: ElementRef;

	constructor(
		appService: AppService,
		router: Router,
		protected dataService: WarehousesService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Склады';
	}

    onListPageInit() {

    }

    ngAfterViewInit() {
		fromEvent(this.filterAllInput.nativeElement, 'keyup')
		.pipe(
			map((evt: any) => evt.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe((text: string) => this.onFilter(text));
    }

    processListItems(items: WarehouseListItem[]): void {}

    public onFilter(inputValue: string): void {

		var customState: DataSourceRequestState = {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: "or",
				filters: [
					{
						field: 'name',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'description',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'integraCode',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'partnerCode',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'phone',
						operator: 'contains',
						value: inputValue
					},
				],
			}
		};
		
		this.loadData(customState);
    }

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/categories/warehouse', dataItem.warehouseId]);
	}
}
