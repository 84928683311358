import { Component, OnInit } from "@angular/core";
import { ReportsService } from "../../shared/services/reports.service";
import { NotificationType } from "../../core/services/notification-type";
import { NotificationService } from "../../core/services/notification.service";
import { saveAs } from 'file-saver';
import { addDays } from '@progress/kendo-date-math';
import { AppService } from "../../app.service";

@Component({
	selector: 'mk-report-page',
	templateUrl: './mk-report.page.html',
	styleUrls: [
		'./mk-report.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class MkReportPage implements OnInit {

	public range = { start: new Date(), end: new Date() };
	public dataGenerating = false;

	constructor(
		private reportsService: ReportsService,
		private notificationService: NotificationService,
		private appService: AppService) {
			appService.pageTitle = 'Отчет по заявкам МультиКарта'
	}
	ngOnInit(): void {
		this.range.start.setHours(0, 0, 0, 0);
		this.range.end.setHours(0, 0, 0, 0);
	}

	generateReport() {
		this.dataGenerating = true;

		let dateEnd = new Date(this.range.end);
		dateEnd = addDays(dateEnd, 1);

		const startDate = this.getDate(this.range.start);
		const endDate = this.getDate(dateEnd);

		const startDateName = this.range.start.toLocaleDateString().replace(',', '');
		const endDateName = this.range.end.toLocaleDateString().replace(',', '');
		
		this.reportsService.getMk(startDate, endDate)
			.subscribe(blob => {
				if (blob.type === 'application/octet-stream') {
					const localDate = new Date().toLocaleString().replace(',', '');
					saveAs(blob, `Отчет_по_выполненным_заявкам_от_${startDateName}_по_${endDateName}_создан_${localDate}.xlsx`);
					this.dataGenerating = false;

				} else if (blob.type === 'application/json') {
					blob
						.text()
						.then(result => {
							const response = JSON.parse(result);
							if (!response.isSuccessful) {
								this.notificationService.error({
									title: 'Ошибка',
									message: response.errorDescription as string,
									notificationType: NotificationType.SweetAlert
								});
							}

							this.dataGenerating = false;
						});
				}
			});
	}

	getDate(date) {
		return new Date(date).toJSON();
	}
}
