import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { NotificationService } from "../../../core/services/notification.service";
import { ComponentPageBase } from "../../../shared/components/component-page-base/component-page-base";
import { CategoryType } from "../../../shared/enums/category-type.enum";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { ServiceItem } from "../../../shared/models/services/service-item";
import { CategoryServicesService } from "../../../shared/services/category-services.service";
import { LookupService } from "../../../shared/services/lookup.service";

@Component({
	selector: 'service-page',
	templateUrl: './service.page.html',
	styleUrls: [
		'./service.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ServicePage extends ComponentPageBase<ServiceItem> implements OnInit {

	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.services;
	requestTypes: KeyValueObject[] = [];
	areAllRequestTypesSelected: boolean = false;

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: CategoryServicesService,
		protected lookupService: LookupService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/services';
		this.appService.pageTitle = 'Новая услуга';
	}

	ngOnInit(): void {
		this.lookupService.getData('request-types', null).subscribe(data => {
			this.requestTypes = data;

			this.entityId = this.route.snapshot.paramMap.get('serviceId');

			if (this.entityId != null) {

				this.dataService.getById(+this.entityId)
					.subscribe(vm => {
						this.entityName = `${vm.entity.name}`;

						this.dataBind(vm);
						this.requestTypesCheckChange();
					});

			} else {

				this.entityViewModel = new EntityViewModel<ServiceItem>();
				this.entityViewModel.entity = new ServiceItem();
				this.entityViewModel.entity.createdDate = new Date();
				this.canEditForm = true;
				this.isNew = true;
				this.requestTypesCheckChange();

			}
		});
	}

	get toggleAllText() {
		return this.areAllRequestTypesSelected ? 'Отменить все' : 'Выбрать все';
	}

	allRequestTypesCheckClick() {
		this.areAllRequestTypesSelected = !this.areAllRequestTypesSelected;
		this.entityViewModel.entity.requestTypeIds = this.areAllRequestTypesSelected ? this.requestTypes.map((v , i) => v.id) : [];
	}

	requestTypesCheckChange() {
		this.areAllRequestTypesSelected = this.requestTypes.length === this.entityViewModel.entity.requestTypeIds.length;
	}

	afterSaveChanges(): void {
		if (!this.isNew) {
			this.dataService.getById(this.entityViewModel.entity.id)
				.subscribe(vm => {
					this.dataBind(vm);
					this.requestTypesCheckChange();
				});
		}
		super.afterSaveChanges();
	}
}
