import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AppService } from '../../../app.service';
import { AddressSuggestion } from '../../models/dadata-address/address-suggestion';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { DaDataService } from '../../services/dadata.service';

@Component({
	selector: 'dadata-address',
	templateUrl: './dadata-address.component.html'
})
export class DaDataAddressComponent {
	addressValueRaw: any;
	addressSuggestion: AddressSuggestion;
	addressValueString: string;

	@ViewChild("address") address: NgForm;

	@Input()
	required: boolean = false;

	@Input()
	readOnly: boolean = false;

	@Output()
	onAddressChange: EventEmitter<AddressSuggestion> = new EventEmitter();

	adresses: AddressSuggestion[] = [];

	isAddressSelected: boolean = false;

	formatter = (item: AddressSuggestion) => item.address;

	public initValue(value: string){
		this.isAddressSelected = !!value;
		this.addressValueRawGetSet = new AddressSuggestion(value);
	}

	get addressValueRawGetSet(): any {
		return this.addressValueRaw;
	}

	set addressValueRawGetSet(value: any) {
		this.addressValueRaw = value;
		if (value instanceof AddressSuggestion) {
			this.addressValueString = value.address;
		} else {
			this.addressValueString = value;
		}
	}

	search = (text$: Observable<string>) =>
		text$
			.debounceTime(600)
			.distinctUntilChanged()
			.switchMap(address =>
				address ? this.daDataService.suggest(address).map(response => response) : Observable.of([])
				.catch(() => {
					return Observable.of([]);
				}));

	constructor(private appService: AppService,
				private daDataService: DaDataService) { }

	get isRandomAddress(): boolean {
		return !this.isAddressSelected && !!this.addressValueString;
	}


	public onChangeAddress(){
		if (this.onAddressChange) {
			if (!!this.addressSuggestion && !!this.addressSuggestion.address) {
				this.isAddressSelected = true;
				this.onAddressChange.emit(this.addressSuggestion);
				this.addressSuggestion = null;
			} else {
				this.isAddressSelected = false;
				this.onAddressChange.emit(new AddressSuggestion(this.addressValueString));
			}
		}
	}

	public onSelectAddress(obj: any) {
		this.addressSuggestion = <AddressSuggestion>obj.item;
	}

	public isValid() : boolean {
		return this.address.valid;
	}

}
