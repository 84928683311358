﻿import {ContragentContact} from "./contragent-contact";
import {ContragentScUserGroup} from "./contragent-sc-user-group";

export class ContragentSc {
	serviceCenterId: number;
	name: string;
	address: string;
	addressLatitude?: number;
	addressLongitude?: number;
	userGroups: ContragentScUserGroup[] = [];
	contacts: ContragentContact[] = [];
	isActual: boolean;
	isOneRoleSC: boolean;
	integraCode: string;
}