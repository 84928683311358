export class CompleteActivitySummaryData {
    activityId: number;
    requestId: number;

    activityResultId: number;
    activityResultName: string;
    completedDateTime: Date;

    generalEquipments: CompleteActivitySummaryGeneralEquipment[] = [];

    simcardEquipments: CompleteActivitySummarySimcardEquipment[] = [];
}

export class CompleteActivitySummaryGeneralEquipment {
    nomenclature: string;
    serialNumber: string;
    partNumber: string;
    deviceConditionName: string;
}

export class CompleteActivitySummarySimcardEquipment {
    deviceModelName: string;
    iccid: string;
    subscriberNumber: string;
}