import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { DialogService } from "@progress/kendo-angular-dialog";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { Agreement } from "../../../shared/models/agreement/agreement";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { NotificationService } from "../../../core/services/notification.service";
import { AgreementService } from "../../../shared/models/agreement/agreement-service";
import { AddBulkAgreementServicesForm } from "./add-bulk-agreement-services.component";
import { ServiceCategoryWithModeLookup } from "../../../shared/models/services/service-category-with-mode-lookup";
import { GroupDescriptor } from "@progress/kendo-data-query";

@Component({
	selector: 'services',
	templateUrl: './services.component.html',
	styleUrls: []
})
export class Services implements OnInit {
	private showConfirmationMessage: boolean = true;
	@Input() entityViewModel: EntityViewModel<Agreement>;
	@Input() canEditForm: boolean;
	@Input() services: KeyValueObject[] = [];
	@Input() serviceCategories: ServiceCategoryWithModeLookup[] = [];
	@Input() serviceTypes: KeyValueObject[] = [];
	@Input() serviceModes: KeyValueObject[] = [];
	@Input() serviceModesForDropdown: KeyValueObject[] = [];
	@Output() onSave: EventEmitter<any> = new EventEmitter();
	@Output() onRemoveHandler: EventEmitter<any> = new EventEmitter();
	@Output() onSetAvailableServiceModes: EventEmitter<any> = new EventEmitter();

	constructor(
		protected notificationService: NotificationService,
		private dialogService: DialogService,
	) {
	}

	ngOnInit(): void {
	}

	public groups: GroupDescriptor[] = [{ field: "serviceId" }];

	saveHandler(event: any): void {
		this.onSave.emit(event);
	}

	createNewAgreementService(): any {
		return () => {
			const agreementService = new AgreementService();
			agreementService.serviceTypeId = this.entityViewModel.entity.agreementServiceTypeId;
			return agreementService;
		};
	}

	saveDistanceHandler(event: any): void {
		this.onSave.emit(event);
	}

	agreementServiceRemoveHandler(event: any): void {
		if (event.dataItem.hasRequestServices) {
			const agreementServices = [...this.entityViewModel.entity.agreementServices];
			this.notificationService.confirmation({
				title: 'Удаление невозможно',
				message: 'Привязка не может быть удалена, так как использовалась в заявках.',
				confirmButtonText: 'Ok',
				showCancelButton: false,
			},
				() => this.entityViewModel.entity.agreementServices = agreementServices);
		} else {
			this.onRemoveHandler.emit(event);
		}
	}

	agreementServiceCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {
		if (!this.canEditForm || columnIndex === 3) {
			return;
		}
		sender.editRow(rowIndex);
	}

	showAddBulkServices(): void {
		const dialogRef = this.dialogService.open({ content: AddBulkAgreementServicesForm, width: '60%', height: '60%' });

		const addBulkAgreementServicesForm = <AddBulkAgreementServicesForm>dialogRef.content.instance;

		addBulkAgreementServicesForm.onAdd.subscribe(result => this.onAddBulkServices(result));

		addBulkAgreementServicesForm.services = this.services;
		addBulkAgreementServicesForm.serviceCategories = this.serviceCategories;
		addBulkAgreementServicesForm.serviceTypes = this.serviceTypes;
	}

	async onAddBulkServices(agreementService: AgreementService[]): Promise<void> {
		agreementService = await this.checkAgreementServiceForServiceType(agreementService);
		this.entityViewModel.entity.agreementServices =
			this.entityViewModel.entity.agreementServices.concat(agreementService);
		this.onSetAvailableServiceModes.emit();

		this.onRemoveHandler.emit();
	}

	getServiceName(dataItem: AgreementService): string {
		const services = this.services.filter(x => x.id === dataItem.serviceId);
		return services.length > 0 ? services[0].name : '';
	}

	getServiceNameForGroup(id: number): string {
		const services = this.services.filter(x => x.id === id);
		return services.length > 0 ? services[0].name : '';
	}

	getServiceCategoryName(dataItem: AgreementService): string {
		const serviceCategories = this.serviceCategories.filter(x => x.id === dataItem.serviceCategoryId);
		return serviceCategories.length > 0 ? serviceCategories[0].name : '';
	}

	getServiceTypeName(dataItem: AgreementService): string {
		const serviceTypes = this.serviceTypes.filter(x => x.id === dataItem.serviceTypeId);
		return serviceTypes.length > 0 ? serviceTypes[0].name : '';
	}

	async onChangeAgreementService(dataItem: AgreementService): Promise<void> {
		if (this.existsAgreementService(dataItem)) {
			this.notificationService.confirmation({
				title: 'Выбранная привязка уже существует',
				message: 'Проверьте введённые данные',
				confirmButtonText: 'Ok',
				showCancelButton: false,
			});
		}
		if (this.existsServiceWithAnotherType(dataItem)) {
			this.notificationService.confirmation({
				title: 'Проверьте введённые данные',
				message: 'Выбранная услуга уже привязана к другому типу обслуживания',
				confirmButtonText: 'Ok',
				showCancelButton: false,
			});
		}
		if (dataItem.serviceTypeId != undefined) {
			let dataItems: AgreementService[] = [dataItem];
			var result = await this.checkAgreementServiceForServiceType(dataItems);
			if (result.length == 0) {
				dataItem.serviceTypeId = this.entityViewModel.entity.agreementServiceTypeId;
			}
		}
	}

	existsAgreementService(dataItem: AgreementService): boolean {
		return this.entityViewModel.entity.agreementServices
			.some((x => x !== dataItem &&
				x.serviceCategoryId === dataItem.serviceCategoryId &&
				x.serviceId === dataItem.serviceId &&
				x.serviceTypeId === dataItem.serviceTypeId) as any);
	}

	existsServiceWithAnotherType(dataItem: AgreementService): boolean {
		return this.entityViewModel.entity.agreementServices
			.some((x => x !== dataItem &&
				x.serviceId === dataItem.serviceId &&
				dataItem.serviceTypeId != null && x.serviceTypeId != dataItem.serviceTypeId) as any);
	}

	onSaveAgreementService(event: any): void {
		this.onSetAvailableServiceModes.emit();
	}

	onRemoveAgreementService(): void {
		this.onSetAvailableServiceModes.emit();
	}

	hasEmptyFieldAgreementService(dataItem: AgreementService): boolean {
		return !dataItem.serviceTypeId || !dataItem.serviceCategoryId || !dataItem.serviceId;
	}

	async checkAgreementServiceForServiceType(agreementServices: AgreementService[]): Promise<AgreementService[]> {
		if (this.showConfirmationMessage) {
			const invalidServiceTypes = agreementServices.filter(agreementService => agreementService.serviceTypeId !== this.entityViewModel.entity.agreementServiceTypeId);

			if (invalidServiceTypes.length > 0) {
				const result = await this.notificationService.confirmation({
					message: 'Выбранный тип обслуживания не совпадает с типом обслуживания по договору, всё равно добавить?',
					title: 'Проверьте введённые данные',
					confirmButtonText: 'Ок',
					cancelButtonText: 'Отмена',
					showCancelButton: true,
				});
				if (result.value) {
					this.showConfirmationMessage = false;
				} else if (result.dismiss == "cancel") {
					agreementServices = agreementServices.filter(agreementService => agreementService.serviceTypeId === this.entityViewModel.entity.agreementServiceTypeId);
				}
			}
		}
		return agreementServices;
	}
}
