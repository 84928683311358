import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Activity } from '../models/activity/activity';
import { ActionResult } from '../models/core/ActionResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { CreateUpdateActivityMultiple } from '../models/request/create-update-activity-multiple';
import { MultipleError } from '../models/request/multiple-error';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { filter, take, tap } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { KeyValueObject } from '../models/core/KeyValueObject';
import { ActivityListItem } from '../../shared/models/activity/activity-list-item';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { ListViewModel } from '../models/core/ListViewModel';
import { ArticleDto } from '../models/article/article.dto';
import { ActivityResult } from '../models/activity/activity-result';
import { DeviceInfo } from '../models/device/device-info';
import { ActivityResultDto } from '../models/activity/activity-result-dto';
import { ActivityFinishSuccesfully } from '../models/activity/activity-finish-succesfully';
import { ActivityFinishSuccesfullyResult } from '../models/activity/activity-finish-succesfully-result';

@Injectable()
export class ActivitiesService extends DataService<ActivityListItem, Activity> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'activities';
	}

	public activityList(state: DataSourceRequestState, visibleColumnNames: string[]): Observable<ListViewModel<ActivityListItem>> {
		var queryStr = `${toDataSourceRequestString(state)}`;

		//хак, это ошибка компонента грид
		queryStr = queryStr.replace(/T\d\d-00-0/g, 'T00-00-00');

		return this.http.post<ActionDataResult<ListViewModel<ActivityListItem>>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/list?${queryStr}`, {
			visibleColumnNames: visibleColumnNames
		})
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}))
		.pipe(take(1))
		.pipe(filter(x => x.isSuccessful))
		.map(x => x.data)
		;

	}

	public changeStatus(activityId: number, newActivityStatusId: number, comment: string, activityReasonId: number = null, activityWorkflowTransitionId: number = null, componentsArticles: ArticleDto[] = [], stoppedUntil: Date = null, RequestStoppingReasonId: number = null): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-status`, {
			activityId: activityId,
			newActivityStatusId: newActivityStatusId,
			comment: comment,
			activityReasonId : activityReasonId,
			activityWorkflowTransitionId: activityWorkflowTransitionId,
			componentsArticles: componentsArticles,
			stoppedUntil: stoppedUntil,
			requestStoppingReasonId: RequestStoppingReasonId
		})
		.pipe(tap(resp => {
			if (resp.code == 993 || resp.code == 994) {
				this.notificationService.error({
					title: 'Ошибка',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}
		}));
	}

	public createUpdateActivityMultiple(model: CreateUpdateActivityMultiple[]): Observable<ActionDataResult<MultipleError[]>> {
		return this.http.post<ActionDataResult<MultipleError[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/create-update-activity-multiple`, model)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка массового назначения инженеров',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка массового назначения инженеров',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}

	public getTechSupportUsers(): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/tech-support-users`);
	}

	public createUnknownDistanceActivityMk(requestId: number): Observable<ActionDataResult<number>> {
		return this.http.post<ActionDataResult<number>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/create-unknown-distance-activity-mk`, {requestId})
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка создания активности для согласования выезда',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка создания активности для согласования выезда',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}

	public getActivityResults(activityId: number, nextActivityStatusId: number): Observable<ActivityResultDto[]> {
		var queryString = `activity-results?activityId=${activityId}&nextActivityStatusId=${nextActivityStatusId}`;
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${queryString}`);
	}

	public getAllActivityResults(): Observable<ActivityResult[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/activity-results-all`);
	}

	public setActivityResult(activityId: number, activityResultId: number): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/activity-result`,
		{
			activityId: activityId,
			activityResultId: activityResultId
		});
	}

	public finishSuccesfully(model: ActivityFinishSuccesfully): Observable<ActionDataResult<ActivityFinishSuccesfullyResult>> {
		return this.postQuery<ActivityFinishSuccesfullyResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/finish-succesfully`, model)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка при успешном завершении активности',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка при успешном завершении активности',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}
}
