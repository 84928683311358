import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import {ActivityStatus} from "../models/activity-status/activity-status";
import {ActivityType} from "../models/activity-type/activity-type";

@Injectable()
export class ActivityTypesService extends DataService<ActivityType, ActivityType> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'activity-types';
	}
}
