import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LaddaModule } from 'angular2-ladda';
import { RequestsRoutingModule } from './requests-routing.module';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DirectoriesModule } from '../categories/categories.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list'
import { SerialNumbersValidationForm } from './request-components/serial-numbers-validation-form/serial-numbers-validation-form';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { RequestEmailComponent } from './request-components/request-email/request-email.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
	declarations: [
		RequestsRoutingModule.components,
	],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		RouterModule,
		LaddaModule,
		ExcelModule,
		LayoutModule,
		PerfectScrollbarModule,
		DirectoriesModule,
		NgbModule,
		MatBottomSheetModule,
		MatListModule,
		InfiniteScrollModule,
		TextMaskModule,
	],
	entryComponents:[
		SerialNumbersValidationForm,
		RequestEmailComponent,
	],
	providers: [
		DialogRef
	]
})
export class RequestsModule { }
