import * as Mustache from 'mustache';
import { Injectable } from '@angular/core';

@Injectable()
export class TemplateEngineService {

	constructor() { }

	public render(template: string, data: any): string {
        if (Array.isArray(data)) {
            data = { data: data };
        }

        return Mustache.render(template, data);
	}
}
