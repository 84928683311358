import { Component, EventEmitter, Output } from "@angular/core";
import {
	FileRestrictions,
	SelectEvent,
	UploadEvent,
} from "@progress/kendo-angular-upload";
import { UploadFile } from "../../shared/models/attachment";
import { ReportsService } from "../../shared/services/reports.service";
import { saveAs } from '@progress/kendo-file-saver';
import { NotificationService } from "../../core/services/notification.service";
import { NotificationType } from "../../core/services/notification-type";
import { AppService } from "../../app.service";

@Component({
	selector: "psb-requests-matching",
	templateUrl: "./psb-requests-matching.page.html",
	styleUrls: [
		"./psb-requests-matching.page.scss",
		"../../../vendor/libs/angular2-ladda/angular2-ladda.scss",
	],
})
export class PsbRequestsMatchingReport {
	reportFileRestrictions: FileRestrictions = {
		allowedExtensions: [".csv", ".xls", ".xlsx"],
	};

	uploadFile: UploadFile;
	canUpload: boolean;
    fileUploading: boolean;
	recoverMissingStatuses: boolean;

	constructor(
		private reportsService: ReportsService,
        private notificationService: NotificationService,
		private appService: AppService
	) {
		appService.pageTitle = 'Сверка статусов заявок ПСБ';
	}

    matchFile(){

        this.fileUploading = true;

        this.reportsService.getPsbRequestsMatchingReport(this.uploadFile.fileName, this.uploadFile.fileData, this.recoverMissingStatuses).subscribe(x => {

            if (x.isSuccessful) {
                const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${x.data.fileData}`;
                saveAs(dataURI, x.data.fileName);
            } else {
                this.notificationService.error({
                    title: 'Ошибка при построении отчета',
                    message: x.errorDescription,
                    notificationType: NotificationType.Toast
                });
            }

            this.fileUploading = false;
        });

    }

    downloadSampleFile(){
        this.reportsService.getPsbRequestsMatchingSampleFile().subscribe(data => {
            const dataURI = `data:text/csv;base64,${data}`;
            saveAs(dataURI, "psb-requests-sample.csv");
        });

    }

	uploadEventHandler(e: UploadEvent) {
		const files = e.files;

		files.forEach((file) => {
			if (file) {
				const reader = new FileReader();

				const self = this;

				reader.onload = function (readerEvt: any) {
					const binaryString = <string>readerEvt.target.result;

					self.uploadFile = new UploadFile();
					self.uploadFile.fileData = btoa(binaryString);
					self.uploadFile.fileName = file.name;
				};

				reader.readAsBinaryString(file.rawFile);
			}
		});
	}

	successUploadedEventHandler(e: any) {
		this.canUpload = true;
	}

	public selectEventHandler(e: SelectEvent): void {
		if (e.files.length > 1) {
            this.notificationService.error({
                title: 'Доступна загрузка лишь одного файла',
                message: "",
                notificationType: NotificationType.SweetAlert
            });
            e.preventDefault();
		}
	}
}
