import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../app.service";
import { NotificationService } from "../../core/services/notification.service";
import { ComponentPageBase } from "../../shared/components/component-page-base/component-page-base";
import { CategoryType } from "../../shared/enums/category-type.enum";
import { ColorSchemeSettings } from "../../shared/models/color-scheme-settings/color-scheme-settings";
import { ColorSchemeSettingsService } from "../../shared/services/color-scheme-settings.service";

@Component({
	selector: 'color-scheme-settings-page',
	templateUrl: './color-scheme-settings.page.html',
	styleUrls: [
		'./color-scheme-settings.page.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
	]
})
export class ColorSchemeSettingsPage extends ComponentPageBase<ColorSchemeSettings> implements OnInit {

	categoryTypeId = <number>CategoryType.colorSchemeSettings;

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: ColorSchemeSettingsService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/color-scheme-settings';
		this.entityName = 'Настройка цветовых схем';
	}

	ngOnInit(): void {

		this.dataService.getById(0)
			.subscribe(vm => {
				this.dataBind(vm);
			});
	}
}
