import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { EntityViewModel } from '../../../shared/models/core/EntityViewModel';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { DialogService } from '@progress/kendo-angular-dialog';
import { RequestRegistrationFilterDto } from '../../../shared/models/request-registration-filter/request-registration-filter-dto';
import { RequestRegistrationFiltersService } from '../../../shared/services/request-registration-filters.service';
import { RequestRegistrationFilterElementDto } from '../../../shared/models/request-registration-filter/request-registration-filter-element-dto';
import { RequestRegistrationFilterElementTypeEnum } from '../../../shared/enums/request-registration-filter-element-type.enum';

@Component({
	selector: 'automatic-registration-request',
	templateUrl: './automatic-registration-request.page.html',
	styleUrls: [
		'./automatic-registration-request.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class AutomaticRegistrationRequestPage extends ComponentPageBase<any> implements OnInit {

	elementCount: number = 0;

	diasbleAddButton: boolean = false;

	excludedRequestRegistrationFilterElementTypes: number[] = [
		RequestRegistrationFilterElementTypeEnum.warehouseRegion,
	];
	
	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dialogService: DialogService,
		protected dataService: RequestRegistrationFiltersService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'admin/automatic-registration-requests';
		this.appService.pageTitle = 'Новый фильтр';
	}

	ngAfterViewInit(): void {
	}


	ngOnInit() {
		this.entityId = this.route.snapshot.paramMap.get('requestRegistrationFilterId');
		//this.entityIdConst = this.entityId;

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId).subscribe(vm => {
				this.entityName = vm.entity.name;
				this.dataBind(vm);
			});
		} else {

			this.entityViewModel = new EntityViewModel<RequestRegistrationFilterDto>();
			this.entityViewModel.entity = new RequestRegistrationFilterDto();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;
			this.dataService.elementTypesLookup().subscribe(data => {
				this.entityViewModel.entity.requestRegistrationFilterElements = data
					.filter(v => !this.excludedRequestRegistrationFilterElementTypes.includes(v.id))
					.map((v, i) => {
						let item = new RequestRegistrationFilterElementDto();
						item.requestRegistrationFilterElementTypeId = v.id;
						item.requestRegistrationFilterElementId = -(i + 1);
						return item;
					});
			});
		}
	}

	afterSaveChanges() {

		if (!!!this.entityId) {
			
			this.dataService.getById(+this.entityIdConst).subscribe(vm => {
				this.entityViewModel.entity.version = vm.entity.version;
			});

			return;
		}

		this.router.navigate(['/admin/automatic-registration-request/', this.entityId]);
	}

	public addElement() {
		let requestRegistrationFilterElementDto: RequestRegistrationFilterElementDto = new RequestRegistrationFilterElementDto();
		this.elementCount--;
		if (!this.isNew) {
			requestRegistrationFilterElementDto.requestRegistrationFilterId = this.entityViewModel.entity.requestRegistrationFilterId;
		}
		requestRegistrationFilterElementDto.requestRegistrationFilterElementId = this.elementCount;
		this.entityViewModel.entity.requestRegistrationFilterElements.push(requestRegistrationFilterElementDto);
	}

	removeElement(id: number) {
		let index = this.entityViewModel.entity.requestRegistrationFilterElements
			.map(m => m.requestRegistrationFilterElementId)
			.indexOf(id);

		this.entityViewModel.entity.requestRegistrationFilterElements.splice(index, 1);
	}

	addDisable(disabled: boolean) {
		let len = this.entityViewModel.entity.requestRegistrationFilterElements.length;
		this.diasbleAddButton = disabled
			|| (len > 0
				&& (!!!this.entityViewModel.entity.requestRegistrationFilterElements[len - 1].requestRegistrationFilterElementTypeId
					|| !!!this.entityViewModel.entity.requestRegistrationFilterElements[len - 1].requestRegistrationFilterElementOperatorId
					|| this.entityViewModel.entity.requestRegistrationFilterElements[len - 1].requestRegistrationFilterElementValues.length === 0));
	}

	public get saveDisabled(): boolean {
		return this.entityViewModel.entity.requestRegistrationFilterElements.length == 0
			|| (this.entityViewModel.entity.requestRegistrationFilterElements.length > 0
				&& (this.entityViewModel.entity.requestRegistrationFilterElements.some(s => !!!s.requestRegistrationFilterElementOperatorId)
					|| this.entityViewModel.entity.requestRegistrationFilterElements.some(s => !!!s.requestRegistrationFilterElementTypeId)
					|| this.entityViewModel.entity.requestRegistrationFilterElements.some(s => s.requestRegistrationFilterElementValues.length == 0)))
	}

	public saveHandler() {
		this.appService.MarkFormDirty(this.entityForm);
	}

	public removeHandler() {
		this.appService.MarkFormDirty(this.entityForm);
	}

	public markDirty() {
		this.appService.MarkFormDirty(this.entityForm);
	}


	
}

