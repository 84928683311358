import { Component, Input, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { RequestsService } from '../../services/requests.service';
import { RequestComment } from '../../models/request/request-comment';
import { SecurityService } from '../../../core/services/security.service';
import { addDays, isEqualDate } from '@progress/kendo-date-math';
import { IntlService } from '@progress/kendo-angular-intl';
import { AppService } from '../../../app.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { UploadFile } from '../../models/attachment/upload-file';
import { Attachment } from '../../models/attachment/attachment';
import { AttachmentsService } from '../../services/attachments.service';
import { saveAs } from 'file-saver';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ActionResult } from '../../models/core/ActionResult';
import { NotificationType } from '../../../core/services/notification-type';
import { NotificationService } from '../../../core/services/notification.service';
import { KeyValueObject } from '../../models/core/KeyValueObject';
import { SelectedCommentsFilter } from '../../models/request/comments/selected-comment-filter';
import { NewCommentModel } from '../../models/request/comments/new-comment-model';
import { CommentKindEnum } from '../../enums/comment-kind.enum';
import { UsersService } from '../../../admin/users/users.service';
import { RoleEnum } from '../../enums';
import { CommentTypeEnum } from '../../enums/comment-type.enum';
import { SignalRNotificationsService } from '../../../shared/services/signalr-notifications.service';

@Component({
	selector: 'comments',
	templateUrl: './comments.component.html',
	styleUrls: [
		'./comments.component.scss',
		'../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../../vendor/styles/pages/chat.scss'
	]
})
export class CommentsComponent implements AfterViewInit {

	@ViewChild('perfectScrollbar') perfectScrollbar: PerfectScrollbarComponent;

	@Input()
	elementHeight: number;

	@Input()
	requestId: number;

	@Input()
	stoppingReasons: KeyValueObject[];

	@Input()
	activityId: number;

	@Input()
	sendButtonDisabled: false;

	@Input()
	selectedCommentsFilter: SelectedCommentsFilter;

	@Input()
	notifyEngineerEmailSwitchDisabled: boolean = false;

	@Input()
	activityPerformerId: number;

	@Input()
	disableAddingComment: boolean = false;

	
	@Input()
	isMobile: boolean = false;

	@Input()
	includeAllRequestComments: boolean = false;

	@Output()
	onCommentAdded: EventEmitter<any> = new EventEmitter();

	showAddAttachments: boolean = false;

	comments: RequestComment[] = [];
	sessionUserId: number;
	isCommentSending: any = false;
	commentSendPercentages?: number;
	selectedStoppingReasonId?: number;

	terminalConfigAddedMessageListener = "terminalConfigAdded"

	notifyEngineerTelegram: boolean = false;
	notifyEngineerEmail: boolean = false;

	newCommentModel: NewCommentModel = new NewCommentModel();

	public notifyEngineerTelegramSwitchDisabled: boolean = true;

	constructor(private requestsService: RequestsService,
		private securityService: SecurityService,
		private intl: IntlService,
		protected notificationService: NotificationService,
		private appService: AppService,
		private attachmentsService: AttachmentsService,
		private userService: UsersService,
		private signalR: SignalRNotificationsService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		this.sessionUserId = securityService.currentUser.userId;
		this.newCommentModel.notifyCustomer = true;
	}

	ngAfterViewInit(): void {		
		this.loadComments(0, this.activityPerformerId);
		this.initSignalR(this.requestId.toString());
	}

	private initSignalR(entityId: string){
		this.signalR.startConnection().subscribe(() => {
			this.signalR.addToGroup(entityId);
			this.signalR.getOrCreateMessageListener(this.terminalConfigAddedMessageListener).subscribe(status => {
				this.loadComments(0, this.activityPerformerId);
			});
		});
	}
	

	loadComments(scrollToBottomSpeed: number, activityPerformerIdExt: number) {
		this.activityPerformerId = activityPerformerIdExt;

		if (!!this.activityPerformerId) {
			this.userService.checkUserInRole(this.activityPerformerId, <number>RoleEnum.selfEmployedEngineer)
			.subscribe(isSmz => this.notifyEngineerTelegramSwitchDisabled = !isSmz);
		} else {
			this.notifyEngineerTelegramSwitchDisabled = true;
		}

		this.requestsService.getComments(this.requestId, this.activityId, this.isMobile, this.includeAllRequestComments).subscribe(data => {

			data.forEach(comment => {
				comment.createdDateLocal = this.appService.parseDate(comment.createdDateLocal);
			});

			this.comments = data;
			this.isCommentSending = false;

			this.changeDetectorRef.detectChanges();

			// хак для корректной отработки скрола после отправки комментария
			setTimeout(() => {
				this.scrollToBottom(scrollToBottomSpeed);
			}, 500);

		});
	}
	commentTextKeyDown(event) {
		if (event.ctrlKey && event.key === 'Enter') {
			this.addComment();
		}
	}

	addComment() {

		if (!this.newCommentModel.text && !this.selectedStoppingReasonId)
			return;

		if (this.newCommentModel.uploadAttachments.length > 0 && this.newCommentModel.uploadAttachments.some(x => x.attachmentTypeIds.length === 0)) {
			this.notificationService.error({
				title: 'Ошибка',
				message: "Укажите типы для загружаемых файлов",
				notificationType: NotificationType.SweetAlert
			});

			return;
		}

		this.isCommentSending = true;

		if (this.selectedStoppingReasonId) {
			this.newCommentModel.text = this.stoppingReasons.find(x => x.id === this.selectedStoppingReasonId).name + ' Комментарий - ' + this.newCommentModel.text;
		}

		this.newCommentModel.requestId = this.requestId;
		this.newCommentModel.activityId = this.activityId;
		this.newCommentModel.commentType = CommentTypeEnum.user;

		this.newCommentModel.commentKind = CommentKindEnum.internal;

		switch (this.selectedCommentsFilter.selectedTab) {
			case "general":
				this.newCommentModel.commentKind = CommentKindEnum.general;
			break;
		}

		if (this.selectedCommentsFilter.selectedTab !== "all" && this.newCommentModel.notifyCustomer) {
			this.newCommentModel.notifyCustomer = false;
		}

		if (this.notifyEngineerTelegram) {
			this.newCommentModel.engineerNotifyWays.push('telegram');
			this.newCommentModel.notifyEngineer = true;
		}

		if (this.notifyEngineerEmail) {
			this.newCommentModel.engineerNotifyWays.push('email');
			this.newCommentModel.notifyEngineer = true;
		}

		if (this.selectedCommentsFilter.selectedTab === "all" && this.newCommentModel.notifyCustomer && this.canAddGeneralComment) {
			this.newCommentModel.commentKind = CommentKindEnum.general;
		}

		this.requestsService.addComment(this.newCommentModel).subscribe(x => {

			switch (x.type) {
				case HttpEventType.UploadProgress:
					this.commentSendPercentages = Math.round((x.loaded / (x.total != null ? x.total : 0) * 100));

				case HttpEventType.Response:
					if (x instanceof HttpResponse) {
						var result = <ActionResult>x.body;

					if (!result.isSuccessful) {

						this.notificationService.error({
							title: 'Ошибка',
							message: result.errorDescription,
							notificationType: NotificationType.SweetAlert
						});

						this.isCommentSending = false;
						return;
					}

					this.newCommentModel.text = '';
					this.newCommentModel.uploadAttachments = [];
					this.showAddAttachments = false;
					this.selectedStoppingReasonId = null;

					this.newCommentModel.notifyCustomer = true;
					this.newCommentModel.notifyEngineer = false;

					this.notifyEngineerTelegram = false;
					this.notifyEngineerEmail = false;
					this.newCommentModel.engineerNotifyWays = [];

					this.loadComments(300, this.activityPerformerId);

					this.onCommentAdded.emit();
				}
			}

		});

	}

	getDateCellText(value: Date) {

		const today = new Date();
		const yesterday = addDays(today, -1);
		const time = this.intl.formatDate(value, 'HH:mm')

		if (isEqualDate(today, value)) {
			return `Сегодня в ${time}`;
		} else if (isEqualDate(yesterday, value)) {
			return `Вчера в ${time}`;
		}

		return this.intl.formatDate(value, 'dd.MM.yyyy HH:mm');
	}

	scrollToBottom(speed: number) {

		if (this.perfectScrollbar)
			this.perfectScrollbar.directiveRef.scrollToBottom(-200, speed);

	}

	onAttachmentsAdded(uploadFiles: UploadFile[]) {
		this.newCommentModel.uploadAttachments = uploadFiles;
	}

	toggleAttachments() {
		this.showAddAttachments = !this.showAddAttachments;
		this.commentSendPercentages = null;
	}

	downloadFile(attachment: Attachment) {
		this.attachmentsService.getAttachmentById(attachment.attachmentId).subscribe(blob => {
			saveAs(blob, attachment.attachmentName, { type: 'application/octet-stream' });
		});
	}

	setStopingReason(stopingReasonId?: number){
		this.selectedStoppingReasonId = stopingReasonId;
	}

	getCommentAuthorInfo(comment: RequestComment): string {

		var result = "";

		if (comment.createdUserRoles.length > 0) {
			result += comment.createdUserRoles.length == 1 ? "Роль: " : "Роли: ";
			result += comment.createdUserRoles.join(', ');
		}

		if (comment.createdUserServiceCenters.length > 0) {
			result += result.length > 0 ? ". " : "";
			result += "Сервисные центры: " + comment.createdUserServiceCenters.join(', ');
		}

		return result;
	}

	getCommentAuthorShortInfo(comment: RequestComment): string {

		var result = "";

		if (comment.createdUserRoles.length > 0) {
			result += comment.createdUserRoles[0];
		}

		if (comment.createdUserServiceCenters.length > 0) {
			result += result.length > 0 ? " " : "";
			result += `(${comment.createdUserServiceCenters[0]})`;
		}

		return result;
	}

	getCommentKindName(comment: RequestComment): string {
		return this.includeAllRequestComments && comment.activityNumber
			? `${comment.commentKindName}\u00A0(${comment.activityNumber})`
			: comment.commentKindName;
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	get isUserClient(): boolean {
		return this.securityService.isClient();
	}

	get canViewAttachments(): boolean {
		return this.securityService.hasClaim("cat-requestfiles-read");
	}

	get canAddGeneralComment(): boolean {
		return this.securityService.hasClaim(`comment-kind-${<number>CommentKindEnum.general}-add`);
	}

	get canAddInternalComment(): boolean {
		return this.securityService.hasClaim(`comment-kind-${<number>CommentKindEnum.internal}-add`);
	}

	get canAddComment(): boolean {
		if (this.disableAddingComment)
			return false;
		else {
			switch (this.selectedCommentsFilter.selectedTab) {
				case "general":
					return this.canAddGeneralComment;
				case "internal":
					return this.canAddInternalComment;
				case "all":
					return this.canAddGeneralComment || this.canAddInternalComment;
				default:
					return false;
			}
		}
	}

	 typeMessagesColor(messageType) {
		switch(messageType) {
			case 1:
				return '#070594';
			case 2:
				return '#05B1AA';
			case 3:
				return '#FF5D5D';
		}
	}

	public notifyEngineer(comment: RequestComment) {
		return !!comment.engineerNotifyWays && comment.engineerNotifyWays.length > 0;
	}

	public checkNotifyWay(comment: RequestComment, value: string) {
		return !!comment.engineerNotifyWays && comment.engineerNotifyWays.includes(value);
	}

}