import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { RequestStoppingReasonListItem } from '../models/request/request-stopping-reason-list-item';
import { RequestStoppingReasonDto } from '../models/request/request-stopping-reason-dto';

@Injectable()
export class RequestStoppingReasonsService extends 
    DataService<RequestStoppingReasonListItem, RequestStoppingReasonDto> 
{
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'stopping-reasons';
	}
}