import { Component, OnInit, Input, EventEmitter, Output, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';
import { AppService } from '../../../../app.service';
import { EntityViewModel } from '../../../../shared/models/core/EntityViewModel';
import { ComponentPageBase } from '../../../../shared/components/component-page-base/component-page-base';
import { LookupService } from '../../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../../shared/models/core/KeyValueObject';
import { ContragentsService } from '../../contragents.service';
import { Contragent } from '../../../../shared/models/contragent/Contragent';
import { DialogRef } from '@progress/kendo-angular-dialog';

const flatten = filter => {
	const filters = filter.filters;
	if (filters) {
		return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
	}
	return [];
};


@Component({
	selector: 'categories-contragent-form',
	templateUrl: './contragent.form.html',
	styleUrls: [
		'./contragent.form.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ContragentForm extends ComponentPageBase<Contragent> implements OnInit {

	@Input() contragentTypeId: number;
	@Input() header: string;
	@Input() showCancelButton: boolean;
	@Output() afterSaveChangesEvent = new EventEmitter<KeyValueObject>();
	@Output() afterCancelEvent = new EventEmitter();

	projects: KeyValueObject[] = [];
	contragentTypes: KeyValueObject[] = [];
	contragents: KeyValueObject[] = [];

	public dropDownVirtualSettings: any = {
		itemHeight: 28,
	};

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: ContragentsService,
		private lookupService: LookupService,
		private injector: Injector
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/contragents';
	}

	ngOnInit() {
		this.entityId = this.route.snapshot.paramMap.get('contragentId');

		this.initLookups();

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId).subscribe(vm => {
				this.entityName = `${vm.entity.name}`;
				this.dataBind(vm);
			});

		} else {

			this.entityViewModel = new EntityViewModel<Contragent>();
			this.entityViewModel.entity = new Contragent();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;
		}
	}

	initLookups() {
		this.lookupService.getData("projects", null).subscribe(data => this.projects = data);
		this.lookupService.getData("contragents", null).subscribe(data => this.contragents = data);
		this.lookupService.getData("contragentTypes", null).subscribe(data => {
			this.contragentTypes = data;
			if (this.contragentTypeId !== null) {
				this.entityViewModel.entity.contragentTypeId = this.contragentTypeId;
			}
		});
	}

	closeDialog() {
		if (!this.showCancelButton)
			return;

		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}

	afterSaveChanges() {
		var val = new KeyValueObject(Number(this.entityId), this.entityViewModel.entity.name);
		this.afterSaveChangesEvent.emit(val);
		this.closeDialog();
	}

	afterCancel() {
		this.afterCancelEvent.emit();
		this.closeDialog();
	}
}
