import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { NotificationService } from "../../../core/services/notification.service";
import { Fine } from "../../../requests/request-components/activity-fines/request-fine.model";
import { ActionDataResult } from "../../models/core/ActionDataResult";
import { ActionResult } from "../../models/core/ActionResult";

@Injectable()
export class ActivityFinesService {

    protected controllerName = 'fines';
    protected controllerVersion = 1;

    constructor(protected http: HttpClient,
        protected notificationService: NotificationService) {
    }

    protected baseUrl(): string {
        return `${environment.finesAddress}v${this.controllerVersion}/${this.controllerName}`;
    }

    public getFines(requestId: number): Observable<Fine[]> {
		return this.http.get<ActionDataResult<Fine[]>>(`${this.baseUrl()}?requestId=${requestId}`)
			.pipe(tap(resp => {
				if (!resp.isSuccessful) {
                    this.notificationService.error({ title: 'Сервис штрафов и событий', message: resp.errorDescription });
				}
			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

                this.notificationService.error({ title: 'Сервис штрафов и событий', message: errorResponse.errorDescription });
			}))
            .map(m => { return m.data });
    }

    public putFines(requestId: number, activityId: number, fines: Fine[]): Observable<ActionResult> {
		return this.http.put<ActionResult>(`${this.baseUrl()}`, {requestId: requestId, activityId: activityId, fines: fines})
			.pipe(tap(resp => {
				if (!resp.isSuccessful) {
                    this.notificationService.error({ title: 'Сервис штрафов и событий', message: resp.errorDescription });
				}
			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

                this.notificationService.error({ title: 'Сервис штрафов и событий', message: errorResponse.errorDescription });
			}));
    }
}
