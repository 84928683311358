import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Call } from '../models/call/call';
import { CallRecord } from '../models/call/call-record';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { ActionDataResult } from '../models/core/ActionDataResult';

@Injectable()
export class CallsService extends DataService<Call, Call> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'calls';
	}

	public loadRecord(callId: number): Observable<CallRecord> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${callId}/record`);
	}

}