import { Component, HostBinding, Input } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
	selector: 'mobile-footer',
	templateUrl: './mobile-footer.component.html',
	styles: [':host { display: block; }']
})
export class MobileFooterComponent {
	@HostBinding('class.layout-footer') private hostClassMain = true;

	@Input()
	flatMode: boolean = false;

	constructor(private appService: AppService) { }

	currentBg() {
		return `bg-${this.appService.layoutFooterBg}`;
	}
}