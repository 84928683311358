import { Component, Input, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { RequestRegistrationFiltersService } from '../../services/request-registration-filters.service';
import { RequestRegistrationFilterChangeLogDto } from '../../models/request-registration-filter/request-registration-filter-change-log-dto';

@Component({
    selector: 'request-registration-filter-change-log',
    templateUrl: './request-registration-filter-change-log.component.html',
    styleUrls: ['./request-registration-filter-change-log.component.scss']
})
export class RequestRegistrationFilterChangeLogComponent implements OnInit {

    @Input() requestRegistrationFilterId: number;

    requestRegistrationFilterChangeLogs: RequestRegistrationFilterChangeLogDto[] = [];

    public historyState: State = { sort: [{ field: 'changeDate', dir: 'desc' }] };

    constructor(
        protected dataService: RequestRegistrationFiltersService) {

    }

    ngOnInit() {
        this.loadRequestRegistrationFilterChangeLog();
    }

    loadRequestRegistrationFilterChangeLog(): void {
		this.dataService.requestRegistrationFilterChangeLog(this.requestRegistrationFilterId).subscribe(res => {
			this.requestRegistrationFilterChangeLogs = res;
		});
	}
}
