import { Component, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { LookupService } from '../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { ContragentType } from '../../../shared/enums';
import { Router } from '@angular/router';
import { TidsService } from '../../../shared/services/tids.service';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { Tid } from '../../../shared/models/tid/tid';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { ContragentsService } from '../../contragents/contragents.service';
import { ColumnVisibilityChangeEvent } from '@progress/kendo-angular-grid';
import { FileUtil } from '../../../shared/utils';
import {saveAs} from 'file-saver';
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';

@Component({
	selector: 'categories-tids-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class TidsListPage extends ListPageBase<Tid> implements OnInit, AfterViewInit  {

	//@ViewChild('filterAllInput') filterAllInput: ElementRef;
	clients: KeyValueObject[] = [];
	acquirers: KeyValueObject[] = [];
	regions: KeyValueObject[] = [];
	public dataGenerating = false;

	constructor(
		appService: AppService,
		router: Router,
		private lookupService: LookupService,
		private contragentsService: ContragentsService,
		protected dataService: TidsService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'TID-объекты';
	}

	public enableSaveGridSettings: boolean = true;
	gridSettingsStorageKey: string = 'tids-grid-settings';
	public gridSettings: any = {
		clearFilter: true,
		state: {
			skip: 0,
			take: 100,
			sort: [],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'tidNumber',
				title: 'TID',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'acquirerName',
				title: 'Банк-эквайер',
				filter: 'lookup',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'customerContragentName',
				title: 'Клиент',
				filter: 'lookup',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'agreementNumber',
				title: 'Номер договора с клиентом',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'merchantLegalName',
				title: 'ИНН, Юридическое наименование ТСП',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'legalAddress',
				title: 'Юр.адрес расположения ТСП',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'serviceCategoryName',
				title: 'Категория обслуживания',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'mid',
				title: 'MID',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'customerServiceDateStart',
				format: 'dd.MM.yyyy',
				title: 'Дата постановки на АО',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 9,
				field: 'customerServiceDateEnd',
				format: 'dd.MM.yyyy',
				title: 'Дата снятия с АО',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 10,
				field: 'customerObjectName',
				title: 'Фактическое наименование ТСП в локации',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 11,
				field: 'serviceCenterName',
				title: 'Исполнитель (подрядчик, СЦ)',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 12,
				field: 'customerObjectDistanceFromCityKm',
				title: 'Удаленность, км',
				filter: 'numeric',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 13,
				field: 'customerObjectAddress',
				title: 'Фактический адрес установки оборудования',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 14,
				field: 'actualPlace',
				title: 'Место установки оборудования',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 15,
				field: 'createdDate',
				format: 'dd.MM.yyyy HH:mm',
				title: 'Дата создания',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 16,
				field: 'createdUserDisplayName',
				title: 'Создал',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 17,
				field: 'updatedDate',
				format: 'dd.MM.yyyy HH:mm',
				title: 'Дата изменения',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 18,
				field: 'updatedUserDisplayName',
				title: 'Изменил',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},			
			{
				orderIndex: 19,
				field: 'installedSerialNumbers',
				title: 'Установленные С/Н',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
		]
	}

	onListPageInit(): void {
		var clientsState: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: ContragentType.bankEquer }] }
		};

		this.lookupService.getData("contragents", clientsState).subscribe(data => this.clients = data);
		this.lookupService.getData("regions", null).subscribe(data => this.regions = data);
		this.contragentsService.contragentsAcquirers().subscribe(data => this.acquirers = data);
	}

	ngAfterViewInit(): void {
		// fromEvent(this.filterAllInput.nativeElement, 'keyup')
		// .pipe(
		// 	map((evt: any) => evt.target.value),
		// 	debounceTime(500),
		// 	distinctUntilChanged(),
		// ).subscribe((text: string) => this.onFilter(text));
	}

	processListItems(items: Tid[]): void { }

	public cellClick({ dataItem }: any): void {
		window.open(`/categories/tid/${dataItem.tidId}`, '_blank');
	}

	public onFilter(inputValue: string): void {

		var customState: DataSourceRequestState = {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: "or",
				filters: [
					{
						field: 'tidNumber',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'customerObjectName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'deviceName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'contragentName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'merchantName',
						operator: 'contains',
						value: inputValue
					}
				],
			}
		};
		
		this.loadData(customState);
	}

	public onGridReport(): void {
		this.dataGenerating = true;

		this.getXlsx('tids', 'TIDы.xlsx',
			(c) => {},
			() => {
				this.dataGenerating = false;
			});
	}

	public onVisibilityChange(event: ColumnVisibilityChangeEvent): void {
		if (!this.enableSaveGridSettings) {
			return;
		}
		this.actualizeHiddenColumnsFilteringAndSorting(event.columns);
		super.onVisibilityChange(event);
		this.loadData();
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	downloadExample(): void {
		this.dataService.downloadTidExportFileExample()
		.subscribe(result => {
			if (result.isSuccessful) {
				const bytes = FileUtil.base64ToArrayBuffer(result.data.data);
				const blob = new Blob([bytes], { type: result.data.extension });
				saveAs(blob, result.data.fileName);
			}

			this.loading = false;
		}, error => this.loading = false);
	}
}
