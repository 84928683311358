import { Component, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '../../../shared/models/Role/Role';
import { RolesService } from '../roles.service';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';

@Component({
	selector: 'admin-roles',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RolesListPage extends ListPageBase<Role> implements AfterViewInit {

	@ViewChild('filterAllInput') filterAllInput: ElementRef;

	constructor(
		appService: AppService,
		router: Router,
		protected dataService: RolesService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService) {
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Роли';
	}

	onListPageInit(): void { }

	ngAfterViewInit(): void {
		fromEvent(this.filterAllInput.nativeElement, 'keyup')
		.pipe(
			map((evt: any) => evt.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe((text: string) => this.onFilter(text));
	}

	processListItems(items: Role[]): void { }

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/admin/role', dataItem.roleId]);
	}

	public onFilter(inputValue: string): void {

		var customState: DataSourceRequestState = {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: "or",
				filters: [
					{
						field: 'name',
						operator: 'contains',
						value: inputValue
					}
				],
			}
		};
		
		this.loadData(customState);
    }
}
