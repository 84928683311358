import { Input, Output, EventEmitter, Component } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { EquipmentMoveModel } from "./equipment-move.model";
import { NotificationService } from "../../core/services/notification.service";
import { NotificationType } from "../../core/services/notification-type";

@Component({
	selector: "equipment-move-mode",
	templateUrl: "./equipment-move-mode.html",
	styleUrls: ["./equipment-move-mode.scss"],
})
export class EquipmentMoveMode {
    
    @Input()
    equipmentMoveModel: EquipmentMoveModel = {
        cellNumber: "",
        serialNumbers: []
    };

    @Output() 
    onContinueEvent = new EventEmitter<EquipmentMoveModel>();
    onCancelEvent = new EventEmitter();

    constructor(
        public dialog: DialogRef,
        public notificationService: NotificationService
    ) { }

    send() {
        if (this.equipmentMoveModel.cellNumber == null
            || this.equipmentMoveModel.cellNumber.trim().length == 0
            || this.equipmentMoveModel.serialNumbers.length == 0) {
            this.notificationService.info({ 
                title: "",
                message: "Укажите ячейку и серийные номера",
                notificationType: NotificationType.Toast
            });
        }
        else {
            this.onContinueEvent.emit(this.equipmentMoveModel);
            this.dialog.close();
        }
    }

    cancel() {
		this.onCancelEvent.emit();
		this.dialog.close();
	}
}