import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { ActivityOperation } from '../models/activity/activity-operation';

@Injectable()
export class ActivityOperationsService extends DataService<ActivityOperation, ActivityOperation> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'activity-operations';
	}
}