import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { RequestPublicationSettingListItemDto } from '../models/request-publication-setting-list-item-dto';
import { RequestPublicationSettingsService } from '../../../shared/services/request-publication-settings.service';
import { ListPageBaseLocal } from '../../../shared/components/list-page-base/list-page-base-local';
import { GridSettings } from '../../../shared/models/grid/grid-settings';

@Component({
	selector: 'automatic-publication-requests',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AutomaticPublicationRequestsListPage extends ListPageBaseLocal<RequestPublicationSettingListItemDto> {

	constructor(
		appService: AppService,
		private router: Router,
		private dataService: RequestPublicationSettingsService
	) {
		super();
		appService.pageTitle = 'Автопубликация заявок';
	}
	
	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'createdDate',
				title: 'Дата создания',
				filter: 'date',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'name',
				title: 'Наименование',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'serviceCenterName',
				title: 'Сервисный центр',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'isActive',
				title: 'Активен',
				filter: 'boolean',
				filterable: true,
				width: 250,
				hidden: false
			}
		]
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/admin/automatic-publication-request', dataItem.id]);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.dataService.allSettings().subscribe(r => {
			this.data = r;
			this.listViewModel.data = this.data;
		});
	}

	processListItems(items: RequestPublicationSettingListItemDto[]): void {
	}

	public rowClass() {
		return {
			'cursor-pointer': true
		};
	}
}
