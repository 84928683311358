import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActionResult } from '../models/core/ActionResult';


@Injectable()
export class ExternalService extends DataService<any, any> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService,) {
		super(http, notificationService);
		this.controllerName = 'external';
	}

	public requestBackToLanterSD(externalRequestId: string):Observable<ActionResult>{
		return this.postQuery<number>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/request-back-to-lanter-sd`, {externalRequestId: externalRequestId});
	}
}