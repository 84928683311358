﻿import {DataStateChangeEvent} from "@progress/kendo-angular-grid";
import {AppService} from "../../../../app.service";

export class ContragentBaseComponent<TData> {

	public dataSaving: boolean = false;
	public showMobileFooter: boolean = false;
	public gridHeight = 300;
	public gridData: TData[] = [];

	public gridState: DataStateChangeEvent = {
		skip: 0,
		take: 10,
		sort: [],
		filter: { logic: 'and', filters: [] }
	};

	constructor(
		protected appService: AppService,
	){
		this.showMobileFooter = appService.isMobileDevice;
	}

}
