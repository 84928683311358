import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../app.service";
import { NotificationService } from "../../core/services/notification.service";
import { ComponentPageBase } from "../../shared/components/component-page-base/component-page-base";
import { EntityViewModel } from "../../shared/models/core/EntityViewModel";
import { NomenclatureDto } from "../../shared/models/nomenclature/nomenclatureDto";
import { NomenclatureService } from "../../shared/services/nomenclature.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PartnumberDto } from "../../shared/models/nomenclature/partnumberDto";
import { ArticlesService } from "../../shared/services/articles.service";
import { DevicesService } from "../../shared/services/devices.service";
import { takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
	selector: 'nomenclature-page',
	templateUrl: './nomenclature.page.html',
	styleUrls: [
		'./nomenclature.page.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
	]
})
export class NomenclaturePage extends ComponentPageBase<NomenclatureDto> implements OnInit {
	public form: FormGroup;
	public gridEditing = false;
	public dataSaving = false;
	public dataDeleting = false;

	partnumberSub;
	nomenclatureSub;
	delaySearch = null;
	delayNomenclatureSearch = null;

	private _destroy$ = new Subject<boolean>();

	partNumbers: PartnumberDto[] = [];
	articlePartnumbers : PartnumberDto[] = [];

	nomenclatures: string[] = [];

	partnumberToAddName: string;

	@Input() nomenclatureId: number;
	@Input() nomenclatureName: string;
	@Output() onContinueEvent = new EventEmitter<any>();
	
	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: NomenclatureService,
		private devicesService: DevicesService,
		private articlesService: ArticlesService
	) {
		super(route, router, appService, notificationService, dataService);
		this.appService.pageTitle = 'Новая номенклатура';
		this._buildForm();
	}

	get cardHeight(): number {
		return window.innerHeight - 200;
	}

	ngOnInit(): void {
		this._loadData();
		
	}

	public editHandler() {
		if (!this.gridEditing) {
			this.gridEditing = true;
		}
	}

	public cancelEdit(): void {
		this.gridEditing = false;
	}

	public deletePartnumber(partNumber: PartnumberDto){
		this.dataDeleting = true;
		this.articlesService.remove(partNumber.articleId.toString(), 'Удаление артикула', 'Артикул удален успешно')
		.pipe(
			tap(() => this.dataDeleting = false),
			takeUntil(this._destroy$))
		.subscribe(vm => {
			this._loadData();
			this.onContinueEvent.emit();
		});
	}

	public savePartnumber() {
		this.dataSaving = true;
		this.dataService.createNomenclatureOrAddPartnumber(this.nomenclatureId, this.nomenclatureName, this.partnumberToAddName)
		.pipe(
			tap(() => this.dataSaving = false),
			takeUntil(this._destroy$))
		.subscribe(vm => {
			if (vm.isSuccessful){
				this.partnumberToAddName = null;
				let newiD = +vm.data;
				this.nomenclatureId = newiD;
				this._loadData();
				this.onContinueEvent.emit();
			}
		});
	}

	public handlePartnumberOpen(): void {
		this.partNumbers = [];
	}

	handleFilter(value) {	
		if (!value || value.length == 0){
			return;
		}
		if (this.delaySearch) {
			clearTimeout(this.delaySearch);
		}
		this.delaySearch = setTimeout(() => {
			if (this.partnumberSub){
				this.partnumberSub.unsubscribe();
			}

			this.partnumberSub = this.articlesService.getPartnumberByArticle(value)
			.pipe(
				takeUntil(this._destroy$))
			.subscribe(x =>
			{
				this.partNumbers = x;
			});
		}, 1000);
	}

	
	public handleNomenclatureOpen(): void {
		this.nomenclatures = [];
	}

	handleNomenclatureFilter(value) {	
		if (!value || value.length == 0){
			return;
		}
		if (this.delayNomenclatureSearch) {
			clearTimeout(this.delayNomenclatureSearch);
		}
		this.delayNomenclatureSearch = setTimeout(() => {
			if (this.nomenclatureSub){
				this.nomenclatureSub.unsubscribe();
			}

			this.nomenclatureSub = this.devicesService.getNomenclatures(value)
			.pipe(
				takeUntil(this._destroy$))
			.subscribe(x =>
			{
				this.nomenclatures = x;
			});
		}, 1000);
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public continue() {
	}
	public _loadData(){
		if (this.nomenclatureId != null) {
			this.form.controls['nomenclatureName'].disable();
			this.dataService.getPartnumberByNomenclature(this.nomenclatureId)
			.pipe(
				takeUntil(this._destroy$))
			.subscribe(vm => {
				this.articlePartnumbers = vm.partnumbers;
				this.gridEditing = false;
			});
		}
	}

	private _buildForm(): void {
		this.form = new FormGroup({
			nomenclatureName: new FormControl(null, Validators.required),
			partnumberToAdd: new FormControl(null, Validators.required),
		});
	}
}