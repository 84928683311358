import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, take, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { DataService } from "../../core/services/data.service";
import { NotificationType } from "../../core/services/notification-type";
import { NotificationService } from "../../core/services/notification.service";
import { Carrier } from "../models/carrier/carrier";
import { ActionDataResult } from "../models/core/ActionDataResult";
import { EntityViewModel } from "../models/core/EntityViewModel";

@Injectable()
export class CarriersService extends DataService<Carrier, Carrier> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'carriers';
	}
	
	public findById(id: number): Observable<EntityViewModel<Carrier>> {
		return this.http.get<ActionDataResult<EntityViewModel<Carrier>>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${id}`)
		.pipe(tap(resp => {

			if (resp.code === 904)
				return;

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}))
		.pipe(take(1))
		.pipe(filter(x => x.isSuccessful))
		.map(x => x.data)
		;
	}
}
