import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';
import { NotificationType } from '../../../../core/services/notification-type';
import { NotificationService } from '../../../../core/services/notification.service';
import { NewRequestDevice } from '../../../models/request/new-request/new-request-device';
import { SetDeviceSerialNumbersComponent } from '../set-device-serial-numbers';
import { AddingDeviceModel } from '../adding-device/adding-device.model';
import { UploadAttachmentsModel, UploadFile } from '../../../models/attachment';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { ActivitiesService } from '../../../services/activities.service';
import { DeviceInfo } from '../../../models/device/device-info';
import { ConfirmDeviceDifferent } from '../confirm-device-different/confirm-device-different';
import { DevicesService } from '../../../services/devices.service';
import { NewCommentModel } from '../../../models/request/comments/new-comment-model';
import { CommentKindEnum } from '../../../enums/comment-kind.enum';
import { RequestsService } from '../../../services/requests.service';
import { AttachmentsService } from '../../../services/attachments.service';
import { AppService } from '../../../../app.service';

@Component({
	selector: 'complete-work-uninstall-new',
	templateUrl: './complete-work-uninstall-new.component.html',
	styleUrls: ['./complete-work-uninstall-new.component.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})

export class CompleteWorkUninstallNew implements OnInit, OnDestroy {

	@ViewChildren("setDeviceSerialNumbersComponent") public setDeviceSerialNumbersComponents: QueryList<SetDeviceSerialNumbersComponent>;

	@Input() public requestId: number;
	@Input() public requestTypeId: number;
	@Input() public activityId: number;
	@Input() public devices: NewRequestDevice[] = [];
	@Input() public showComment: boolean = false;
	@Input() public contragentId: number;
	@Input() public nextActivityStatusId: number;
	@Input() public activityStatusToId: number;
	@Input() public activityWorkflowTransitionId: number;

	@Input() public withActivityResult: boolean = false;
	@Input() public withUsingDevices: boolean = false;
	@Input() public uninstallDevicesRequired: boolean = true;
	@Input() public isServiceOrReplace: boolean = true;

	@Output() public changeActivityStatus: EventEmitter<void> = new EventEmitter();
	@Output() public continueEmitter: EventEmitter<string> = new EventEmitter<string>();
	@Output() public cancelEmitter: EventEmitter<void> = new EventEmitter();

	confirmSuccessfullFunction: any;
	activityResults: KeyValueObject[] = [];

	activityResultId: number;
	commentResultText: string = null;
	completedDateTime: Date = null;
	attachments: UploadFile[] = [];
	installDevices: AddingDeviceModel[] = [];

	isValidLastEquipment: Boolean = false;

	commentText: string = '';

	constructor(
		public dialog: DialogRef,
		public activitiesService: ActivitiesService,
		public requestsService: RequestsService,
		public attachmentsService: AttachmentsService,
		public dialogService: DialogService,
		public devicesService: DevicesService,
		private notificationService: NotificationService,
		protected appService: AppService) {
	}

	private _destroy$ = new Subject<boolean>();

	public dataSaving = false;

	tempRequestDeviceId: number = 0;

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	ngOnInit(): void {
		this.completedDateTime = new Date();
		this.installDevices.push(new AddingDeviceModel());
		this.activitiesService.getActivityResults(this.activityId, this.nextActivityStatusId).subscribe(resp => {
			if (!!resp) {
				let respKv = resp.map(m => new KeyValueObject(m.activityResultId, m.activityResult));
				this.activityResults = respKv;
				if (resp.length === 1) this.activityResultId = respKv[0].id;
			}
		});
	}

	get formCompleted(): boolean {
		if (!this.withActivityResult)
			return true;

		var devicesValidation = !this.withUsingDevices ||
			(this.installDevices !== null && this.installDevices.length > 0 && this.installDevices.every(d => d.isValid));

		return this.activityResultId !== null && this.completedDateTime !== null &&
			devicesValidation && !!this.activityResultId && this.activityResultId > 0;
	}

	setInstallDevice(addingDevice: AddingDeviceModel) {
		this.installDevices[addingDevice.index].nomenclature = addingDevice.nomenclature;
		this.installDevices[addingDevice.index].serialNumber = addingDevice.serialNumber;
		this.installDevices[addingDevice.index].uploadAttachments = addingDevice.uploadAttachments;
		this.installDevices[addingDevice.index].isValid = addingDevice.isValid;

		this.isValidLastEquipment = this.installDevices.every(d => d.isValid);
	}

	addInstallDevice() {
		this.installDevices.push(new AddingDeviceModel());
		this.isValidLastEquipment = false;
	}

	public addDevice() {
		let newDevice = new NewRequestDevice();
		this.tempRequestDeviceId--;
		newDevice.requestDeviceId = this.tempRequestDeviceId;
		newDevice.requestId = this.requestId;
		this.devices.push(newDevice);
	}

	private confirmedComponents: SetDeviceSerialNumbersComponent[];

	public saveChanges() {
		this.dataSaving = true;

		this.confirmedComponents = this.setDeviceSerialNumbersComponents.toArray().filter(c => c.isConfirmed);

		if (this.confirmedComponents.length === 0 && this.uninstallDevicesRequired) {
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Демонтированное оборудование отсутствует',
				notificationType: NotificationType.Toast
			});
			this.dataSaving = false;
			return;
		}

		if (this.withUsingDevices) {
			this.devicesService.reservedEqualUsed(this.requestId, this.installDevices).subscribe((resp) => {
				if (!resp.data) {
					let width = `500px`;
					if (this.isMobileDevice) {
						width = `80%`
					}
					// notification.confirmation перекрывается текущим диалоговым окном
					const confirmDeviceDifferentDialogRef = this.dialogService.open({
						content: ConfirmDeviceDifferent,
						width: width
					});

					const confirmDeviceDifferent = <ConfirmDeviceDifferent>confirmDeviceDifferentDialogRef.content.instance;
					confirmDeviceDifferent.confirm.subscribe(() => {
						this.completeDialog();
					});

					confirmDeviceDifferent.cancel.subscribe(() => {
						this.dataSaving = false;
					});
				}
				else {
					this.completeDialog();
				}
			});
		}
		else if (this.withActivityResult) {
			this.completeDialog();
			this.dataSaving = false;
		}
	}

	validateCompleteDialogData() {
		if (this.completedDateTime) {
			this.requestsService.checkNewFinishFactDate(this.requestId, this.completedDateTime)
				.subscribe(result => {

					if (result.isSuccessful) {
						this.processComleteDialog();
					} else {
						this.notificationService
							.confirmation({
								message: result.errorDescription,
								type: 'question',
								cancelButtonText: 'Отмена',
								showCloseButton: true,
								showConfirmButton:false
							}, () => { } , () => this.dataSaving = false);
					}
				});
		} else {
			this.processComleteDialog();
		}
	}

	completeDialog() {
		this.validateCompleteDialogData();
	}

	processComleteDialog() {
		if (!this.withUsingDevices) {
			this.confirmSuccessfullFunction = () => {
				this.activitiesService.setActivityResult(this.activityId, this.activityResultId).subscribe(() => {
					this.requestsService.changeFinishDateFact(this.requestId, this.completedDateTime, true).subscribe(() => { });

					if (this.commentResultText !== null && this.commentResultText !== "" && !!this.commentResultText) {
						var commentModel = new NewCommentModel;
						commentModel.text = this.commentResultText;
						commentModel.requestId = this.requestId;
						commentModel.commentKind = CommentKindEnum.internal;
						commentModel.activityId = this.activityId;
						this.requestsService.addComment(commentModel).subscribe(() => { });
					}

					let res = this.activityResults.find(f => f.id === this.activityResultId);

					if (res !== null && res.name !== null && res.name !== "" && !!res.name) {
						var commentModel = new NewCommentModel;
						commentModel.text = res.name;
						commentModel.requestId = this.requestId;
						commentModel.commentKind = CommentKindEnum.general;
						this.requestsService.addComment(commentModel).subscribe(() => { });
					}

					if (!!this.attachments && this.attachments.length > 0) {
						this.attachmentsService.uploadWithResumableIfMobile(new UploadAttachmentsModel({
							requestId: this.requestId, activityId: this.activityId, attachments: this.attachments
						})).subscribe(() => { });
					}
				});
			}

			if (this.uninstallDevicesRequired && this.confirmedComponents.length > 0) {
				this.confirmedComponents[0].saveChanges2(true, this.confirmSuccessfullFunction);
			}
			else {
				this.confirmSuccessfullFunction();
				this.cancel();
			}
		} else {
			this.confirmSuccessfullFunction = () => {
				this.activitiesService.setActivityResult(this.activityId, this.activityResultId).subscribe(() => {
					this.requestsService.changeFinishDateFact(this.requestId, this.completedDateTime, true).subscribe(() => { });

					if (this.commentResultText !== null && this.commentResultText !== "" && !!this.commentResultText) {
						var commentModel = new NewCommentModel;
						commentModel.text = this.commentResultText;
						commentModel.requestId = this.requestId;
						commentModel.commentKind = CommentKindEnum.internal;
						commentModel.activityId = this.activityId;
						this.requestsService.addComment(commentModel).subscribe(() => { });
					}

					let res = this.activityResults.find(f => f.id === this.activityResultId);

					if (res !== null && res.name !== null && res.name !== "" && !!res.name) {
						var commentModel = new NewCommentModel;
						commentModel.text = res.name;
						commentModel.requestId = this.requestId;
						commentModel.commentKind = CommentKindEnum.general;
						this.requestsService.addComment(commentModel).subscribe(() => { });
					}

					if (!!this.attachments && this.attachments.length > 0) {
						this.attachmentsService.uploadWithResumableIfMobile(new UploadAttachmentsModel({
							requestId: this.requestId, activityId: this.activityId, attachments: this.attachments
						})).subscribe(() => { });
					}

					this.installDevices.forEach(device => {
						if (!!device.uploadAttachments && device.uploadAttachments.length > 0) {
							this.attachmentsService.uploadWithResumableIfMobile(new UploadAttachmentsModel({
								requestId: this.requestId, activityId: this.activityId, attachments: device.uploadAttachments
							})).subscribe(() => { });
						}
					});
				});
			}

			if (!this.uninstallDevicesRequired && this.confirmedComponents.length === 0) {
				this.devicesService.installationCompleteSuccessfull(this.requestId, this.installDevices, this.activityId).subscribe(ics => {
					if (!ics.isSuccessful) {
						this.notificationService.error({
							title: 'Ошибка',
							message: ics.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
						this.dataSaving = false;
					} else {
						this.confirmSuccessfullFunction();

						if (!!this.continueEmitter) {
							this.continueEmitter.emit();
						}
						this.cancel();
					}
				});
			} else {
				this.confirmedComponents[0].saveChanges2(true, this.confirmSuccessfullFunction);
			}
		}
	}

	public checkDevices(resp: { next: boolean, justValidate: boolean }) {
		if (resp.next) {
			this.confirmedComponents = this.confirmedComponents.filter(c => c !== this.confirmedComponents[0])
			if (this.confirmedComponents.length === 0) {
				if (resp.justValidate) {
					this.confirmedComponents = this.setDeviceSerialNumbersComponents.toArray().filter(c => c.isConfirmed);
					this.confirmedComponents[0].saveChanges2(false);
				} else {
					if (this.isServiceOrReplace) {
						this.devicesService.installationCompleteSuccessfull(this.requestId, this.installDevices, this.activityId).subscribe(ics => {
							if (!ics.isSuccessful) {
								this.notificationService.error({
									title: 'Ошибка',
									message: ics.errorDescription,
									notificationType: NotificationType.SweetAlert
								});
								this.dataSaving = false;
							} else {

								//this.confirmSuccessfullFunction();

								if (!!this.continueEmitter) {
									this.continueEmitter.emit();
								}

								this.dataSaving = false;
								this.cancel();
							}
						});
					} else {
						this.continueEmitter.next(this.commentText);
						this.dataSaving = false;
						this.cancel();
					}
					
				}
			} else {
				this.confirmedComponents[0].saveChanges2(resp.justValidate);
			}
		} else {
			this.dataSaving = false;
		}
	}

	get devicesInfo(): DeviceInfo[] {
		return this.devices.map(device => {
			var deviceInfo = new DeviceInfo();
			deviceInfo.serialNumber = device.serialNumber;
			deviceInfo.nomenclature = device.nomenclature;
			return deviceInfo;
		});
	}

	public cancel() {
		if (this.dialog && typeof this.dialog.close === 'function') {
			this.dialog.close();
		}
		else if (this.cancelEmitter) {
			this.cancelEmitter.emit();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}
}
