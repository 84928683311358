import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../shared/models/User/User';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { environment } from '../../../environments/environment';
import { ActionResult } from '../../shared/models/core/ActionResult';
import { CustomerContact } from '../../shared/models/request/customer-contact';
import { Contact } from '../../shared/models/contact/contact';
import { UserList } from '../../shared/models/User';
import { CheckTransactionAccountDto } from '../../shared/models/User/check-transaction-account';
import { PasswordOptions } from '../../shared/models/User/password-options';
import { PerformerContact } from '../../shared/models/request/perfomer-contact';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import { CallToCustomerDestEnum } from '../../shared/enums/call-to-customer-dest.enum';

@Injectable()
export class UsersService extends DataService<UserList, User> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'users';
	}

	public checkTransactionAccount(userId: number = null): Observable<CheckTransactionAccountDto> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${userId}/check-transaction-account`);
	}

	public getUsersByGroup(groupId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${groupId}/users-by-group`);
	}

	public getUsersByRole(roleId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${roleId}/users-by-role`);
	}

	public callToTsp(customerContact: CustomerContact): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/call-to-tsp`, { customerContact: customerContact });
	}

	public callToCustomerService(requestId: number, contragentContact: Contact, callToCustomerDest: CallToCustomerDestEnum = CallToCustomerDestEnum.lanterSupport): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/call-to-customer-service`, {
			requestId: requestId,
			contragentContact: contragentContact,
			callToCustomerDest: callToCustomerDest
		});
	}

	public callToPerformer(performerContact: PerformerContact): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/call-to-performer`, performerContact);
	}

	public getPasswordOptions(): Observable<PasswordOptions> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/password-options`);
	}

	public checkUserInRole(userId: number, roleId: number): Observable<boolean> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${userId}/${roleId}/check-user-in-role`);
	}
}
