﻿import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AgreementListItem} from "../../../../../shared/models/agreement/agreement-list-item";
import {AppService} from "../../../../../app.service";
import {ContragentBaseComponent} from "../contragent-base.component";
import {CellClickEvent, DataStateChangeEvent} from "@progress/kendo-angular-grid";
import {process} from "@progress/kendo-data-query";
import {KeyValueObject} from "../../../../../shared/models/core/KeyValueObject";
import {LookupService} from "../../../../../shared/services/lookup.service";
import {DialogService} from "@progress/kendo-angular-dialog";
import {AgreementPage} from "../../../../agreements/agreement/agreement.page";
import { getDate } from "@progress/kendo-date-math";
import { ContragentProject } from "../../../../../shared/models/contragent/contragent-project";
import { ContragentProjectsService } from "../../../../../shared/services/contragent-projects.service";
import { NotificationType } from "../../../../../core/services/notification-type";

@Component({
	selector: 'contragent-projects',
	templateUrl: './contragent-projects.component.html',
	styleUrls: [
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ContragentProjectsComponent extends ContragentBaseComponent<ContragentProject> implements OnInit {

	private _projects;

	@Input()
	public contragentId;

	@Input()
	public set projects(projects: ContragentProject[]) {
		this._projects = projects;

		this.updateDates();
		this.dataStateChange(this.gridState);
	}

	public get projects() {
		return this._projects;
	}

	@Output()
	public afterSaveChanges = new EventEmitter<any>();

	public agreements: KeyValueObject[] = [];

	private clickedRowItem: ContragentProject;

	constructor(
		protected appService: AppService,
		protected dialogService: DialogService,
		private lookupService: LookupService,
		private contragentProjectsService: ContragentProjectsService
	) {
		super(appService);
	}

	ngOnInit(): void {
		this.contragentProjectsService.getAgreementsLookup().subscribe(data => {
			this.agreements = data;
		});

		this.afterSaveChanges.subscribe(result => {
			this.dataSaving = false;
		});
	}

	reloadContragentProjects() {
		this.contragentProjectsService.list({
			sort: [{field: "createdDate", dir: "desc"}],
			filter: {
				logic: 'and',
				filters: [{ field: 'contragentId', operator: 'eq', value: this.contragentId }]
			}
		}).subscribe(x => this.projects = x.data)
	}

	onDblClick() {
		if (this.clickedRowItem) {
			const multipleOperationsComponent = this.createAgreementModal();
			multipleOperationsComponent.agreementId = this.clickedRowItem.agreementId as unknown as string;
		}
	}

	dataStateChange(state: DataStateChangeEvent): void {
		const result = process(this.agreements, state);
		this.gridData = result.data;
		this.gridState = state;
	}

	getAgreementName(dataItem: ContragentProject): string {
		const agreements = this.agreements.filter(x => x.id === dataItem.agreementId);
		return agreements.length > 0 ? agreements[0].name : '';
	}

	openNewAgreementModal(): void {
		this.createAgreementModal();
	}

	openAgreementInNewTab(agreementId: any): void {
		if (agreementId){
			window.open(`/categories/agreement/${agreementId}`, '_blank');
		}
	}

	private updateDates(): void {
		// this.agreements.forEach(agreement => {
		// 	agreement.createdDateTime = new Date(agreement.createdDate);
		// 	agreement.agreementDateTime = new Date(agreement.agreementDate);
		// 	agreement.endDateTime = new Date(agreement.endDate);

		// 	agreement.createdDate = getDate(new Date(agreement.createdDateTime));
		// 	agreement.agreementDate = getDate(new Date(agreement.agreementDateTime));
		// 	agreement.endDate = getDate(new Date(agreement.endDateTime));
		// });
	}

	private createAgreementModal(): AgreementPage {
		const dialogRef = this.dialogService.open({ content: AgreementPage, width: '90%', height: '90%' });

		const multipleOperationsComponent = <AgreementPage>dialogRef.content.instance;
		multipleOperationsComponent.showHeader = false;
		multipleOperationsComponent.afterSaveChangesOnModal.subscribe(result => {
			this.dataSaving = true;
			if (this.afterSaveChanges){
				this.afterSaveChanges.emit();
			}
		});

		multipleOperationsComponent.initialContragentId = this.contragentId;

		return multipleOperationsComponent;
	}

	get createNewProject(): any {
		return () => {
			var contragentProject = new ContragentProject();
			contragentProject.contragentProjectId = 0;
			contragentProject.contragentId = this.contragentId != null ? this.contragentId : 0;

			return contragentProject;
		};
	}

	get agreementsToAdd(): KeyValueObject[] {
		var ids = this.projects.map(x => x.agreementId);
		return this.agreements.filter(x => !ids.some(y => y == x.id));
	}

	public agreementChange(value: any, dataItem: ContragentProject): void {
		const agreements = this.agreements.filter(x => x.id === value);
		dataItem.projectName = agreements.length > 0 ? agreements[0].groupName : '';
	}

	saveProjectHandler(e: any): void {

		if (this.contragentId == null || this.contragentId == 0) {
			return;
		}

		var dataItem = e.dataItem;

		if (dataItem.contragentProjectId == 0) {
			this.contragentProjectsService.create(dataItem).subscribe(x => {
				this.reloadContragentProjects();
			});
		} else {
			this.contragentProjectsService.update(dataItem, "Проект обновлен").subscribe(x => {
				this.reloadContragentProjects();
			});
		}

		e.dataItem = null;
	}

	public cellClick({ sender, column, columnIndex, rowIndex }: any): void {

		if (!column.editor) {
			return;
		}

		sender.editRow(rowIndex);
	}

	public removeHandler({dataItem}) {

		if (this.contragentId != null && this.contragentId != 0) {
			this.contragentProjectsService.remove(dataItem.contragentProjectId, dataItem.projectName, "Проект удален").subscribe(x => {
				this.reloadContragentProjects();
			});
		}
	
	}

}
