export enum RequestRegistrationFilterElementTypeEnum {
	//Регион расположения склада
	warehouseRegion = 1,
	//Город расположения СЦ
	serviceCenterCity = 2,
	//Тип заявки
	requestType = 3,
	//Услуга, требуемая по заявке
	requestService = 4,
	//Удаленность от границ города, км.
	distanceBetweenObjectsAndWarehouse = 5,
	//Время нахождения заявки на бирже, после которого забирать заявку, сек.
	timeOnExchangeAfterRequestCanBeTakenSec = 6,
	//Начальная стоимость заявки
	requestInitialCost = 7,
	//Идентификатор склада
	warehouseId = 8,
	//Регион заявки
	requestRegion = 9,
	//Город расположения ТСП
	merchantCity = 10,
	//Текущая стоимость, руб
	currentPrice = 11,
	//Кол-во повышений цены, целочисленное
	currentPriceRaizeCounter = 12,
	//Владелец склада
	warehouseOwner = 13,
}
