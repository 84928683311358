import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TreeDropdownListComponent} from './components/tree-dropdownlist/tree-dropdown-list.component';
import {
	MatAutocompleteModule,
	MatButtonModule,
	MatFormFieldModule, MatIconModule,
	MatInputModule,
	MatSelectModule,
	MatTreeModule
} from '@angular/material';
import {TreeSourceService} from './services';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [TreeDropdownListComponent],
	providers: [TreeSourceService],
    imports: [
        CommonModule,

        // angular material
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTreeModule,
        MatIconModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
    ],
	exports: [
		TreeDropdownListComponent
	]
})
export class TreeDropdownListModule { }
