import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { GetSerialNumbersForTransferDto } from '../models/for-1c/get-serial-numbers-for-transfer/get-serial-numbers-for-transfer';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { GetNomenclaturesQuery } from '../models/for-1c/get-nomenclatures/get-nomenclatures-query';
import { GetCellBySnFrom1CQuery } from '../models/for-1c/get-warehouse-and-celll-by-sn/get-cell-by-sn-from-1c-query';
import { GetCellBySnFrom1CResponse } from '../models/for-1c/get-warehouse-and-celll-by-sn/get-cell-by-sn-from-1c-response';
import { SetCellBySnFrom1CQuery } from '../models/for-1c/set-warehouse-and-celll-by-sn/set-cell-by-sn-from-1c-query';
import { SetCellBySnFrom1CResponse } from '../models/for-1c/set-warehouse-and-celll-by-sn/set-cell-by-sn-from-1c-response';
import { SetServiceCellBySnFrom1CQuery } from '../models/for-1c/set-service-warehouse-and-celll-by-sn/set-service-cell-by-sn-from-1c-query';
import { GetContragentInfoFrom1CResponse } from '../models/for-1c/get-contragent-info/get-contragent-info-from-1c-response';
import { GetLastNomenclatureBySeriesQuery } from '../models/for-1c/get-last-nomenclature-by-series/get-last-nomenclature-by-series-1c-query';
import { GetLastNomenclatureBySeries1CResponse } from '../models/for-1c/get-last-nomenclature-by-series/get-last-nomenclature-by-series-1c-response';

@Injectable()
export class For1CService extends DataService<any, any> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'to1C';
	}

    public getSerialNumbersForTransfer(requestId: number): Observable<GetSerialNumbersForTransferDto> {
		return this.getQuery(`${this.baseUrl()}/${requestId}/get-serial-numbers-for-transfer`);
    }

	public getNomenclatures(getNomenclaturesQuery: GetNomenclaturesQuery): Observable<ActionDataResult<string[]>> {
		return this.postQuery(`${this.baseUrl()}/get-nomenclatures`, getNomenclaturesQuery);
    }

	public getWarehouseAndCellBySN(getCellBySnFrom1CQuery: GetCellBySnFrom1CQuery): Observable<ActionDataResult<GetCellBySnFrom1CResponse>> {
		return this.postQuery(`${this.baseUrl()}/get-warehouse-and-cell-by-sn`, getCellBySnFrom1CQuery);
	}

	public setWarehouseAndCellBySN(getCellBySnFrom1CQuery: SetCellBySnFrom1CQuery): Observable<ActionDataResult<SetCellBySnFrom1CResponse>> {
		return this.postQuery(`${this.baseUrl()}/set-warehouse-and-cell-by-sn`, getCellBySnFrom1CQuery);
	}

	public setServiceWarehouseAndCellBySN(getCellBySnFrom1CQuery: SetServiceCellBySnFrom1CQuery): Observable<ActionDataResult<SetCellBySnFrom1CResponse>> {
		return this.postQuery(`${this.baseUrl()}/set-service-warehouse-and-cell-by-sn`, getCellBySnFrom1CQuery);
	}

	public getContragentInfo(contragentIntegraCode: string): Observable<ActionDataResult<GetContragentInfoFrom1CResponse>> {
		return this.postQuery(`${this.baseUrl()}/get-contragent-info`, { contragentIntegraCode });
	}

	public getLastNomenclatureBySeries(getLastNomenclatureQuery: GetLastNomenclatureBySeriesQuery): Observable<ActionDataResult<GetLastNomenclatureBySeries1CResponse>> {
		return this.postQuery(`${this.baseUrl()}/get-last-nomenclature-by-series`, getLastNomenclatureQuery);
	}
}
