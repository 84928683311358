import { ProjectWorkflowTransitionType } from './workflow-transition-type';

export class WorkflowTransition {
    transitionId: number;
    requestStatusFromId: number;
    requestStatusToId: number;
    transitionName: string;
    customFontAwesomeIcon: string;
    buttonBackground: string;
    projectWorkflowTransitionType: ProjectWorkflowTransitionType;
    isCommentRequired: boolean;
    warningText: string;
    errorText: string;
}
