import { Component, Input } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { SetPerformerUserGroup } from "../set-performer-user-group/set-performer-user-group";
import { LookupService } from "../../../services/lookup.service";
import { UserGroupsService } from "../../../services/user-groups.service";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { CommentKindEnum } from "../../../enums/comment-kind.enum";
import { RequestStoppingReasonAliasesService } from "../../../services/request-stopping-reason-aliases.service";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { UserGroup } from "../../../models/user-group/user-group";

@Component({
	selector: "set-reason-for-stopping",
	templateUrl: "./set-reason-for-stopping.html",
	styleUrls: ["./set-reason-for-stopping.scss"],
})
export class SetReasonForStopping extends SetPerformerUserGroup {
	@Input()
	stoppedUntil?: Date;
	isClientAlfaBank: boolean = false;
	isCommentRequired: boolean = false;
	minDate: Date = new Date(new Date().getTime() + 5 * 60000);
	stoppingReason?: number = null;
	stoppingReasons: KeyValueObject[] = [];
	userGroups: UserGroup[] = [];
	
	@Input()
	requestCustomerContragentId?: number = null;

	constructor(
		lookupService: LookupService,
		dialog: DialogRef,
		userGroupsService: UserGroupsService,
		private requestStoppingReasonAliasesService: RequestStoppingReasonAliasesService
	) {
		super(lookupService, dialog, userGroupsService);
	}

	get stoppedUntilRequired(): boolean {
		return !!!this.requestCustomerContragentId
			|| !!!this.userGroupContragentId
			|| this.userGroupContragentId !== this.requestCustomerContragentId;
	}

	afterCommentKindsLoaded(data: KeyValueObject[]) {
		if (data.some((x) => x.id === CommentKindEnum.general)) {
			this.setPerformerUserGroupModel.commentKind = CommentKindEnum.general;
		}
	}

	changeStoppingReason(event: any) {
		if (this.stoppingReason && this.stoppingReason !== 0) {
			var state: DataSourceRequestState = {
				skip: 0,
				take: null,
				filter: { logic: "and", filters: [
					{ field: "RequestStoppingReasonId", operator: "eq", value: this.stoppingReason },
					{ field: "ContragentId", operator: "eq", value: this.requestCustomerContragentId },
				] }
			};
			this.requestStoppingReasonAliasesService.list(state).subscribe(data => {
				this.isCommentRequired = data.data.find(x => 
					x.additionalCommentRequired) ? true : false;
			});
		}
	}
}
