import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings, PopupSettings } from '@progress/kendo-angular-dropdowns';
import { SetPlannedDateModel } from './models/SetPlannedDateModel';
import { CommentKindEnum } from '../../../enums/comment-kind.enum';
import { RequestsService } from '../../../services/requests.service';
import { PlannedDateValidationResult } from '../../../enums/planned-date-validation-result';
import { NotificationService } from '../../../../core/services/notification.service';
import { NotificationType } from '../../../../core/services/notification-type';

@Component({
  selector: 'app-set-planned-date',
  templateUrl: './set-planned-date.component.html',
  styleUrls: ['./set-planned-date.component.scss']
})
export class SetPlannedDateComponent implements OnInit, OnDestroy {
	@Input() requestId: number;
	@Output() onContinueEvent = new EventEmitter<any>();
	@Output() cancelEmitter = new EventEmitter<any>();
	dataSaving = false;

	popupSettings: PopupSettings = {
		popupClass: 'custom-popup'
	  };

	public form: FormGroup;
	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	stoppingReason?: number = null;

	private _destroy$: Subject<boolean> = new Subject<boolean>();
	@Input()
	model: SetPlannedDateModel = new SetPlannedDateModel();
  	constructor(
		private dialog: DialogRef,
		private requestsService: RequestsService,
		private notificationService: NotificationService) 
	{
		this._buildForm();
	}

  	public ngOnInit() {
		this.model = new SetPlannedDateModel(this.requestId, new Date(), CommentKindEnum.internal);
		this._loadData();
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public cancel() {
		if(this.dialog)
			this.dialog.close();
		this.cancelEmitter.emit();
	}

	public continue() {
		this.dataSaving = true;
		this.requestsService.validateAndSavePlannedDate(this.model).subscribe((result)=>
		{
			if(result.isSuccessful){
				let response = result.data;
				switch (response.validationResult) {
					case PlannedDateValidationResult.InvalidPlannedDate:
						{
							this.notificationService.confirmation({
								title: 'Ошибка валидации даты визита',
								message: response.validationMessage,
								type: 'question',
								confirmButtonText: 'Продолжить',
								cancelButtonText: 'Назад',
								showCloseButton: true
							}, () => {
								this.model.forceSet = true;
								this.dataSaving = true;
								this.requestsService.validateAndSavePlannedDate(this.model).subscribe(()=>
								{
									this.onContinueEvent.emit();
									this.cancel();
									this.dataSaving = false;
								});
							});
							break;
						}
					case PlannedDateValidationResult.InvalidLeftDuration:
						{
							this.notificationService.confirmation({
								title: 'Ошибка валидации даты визита',
								message: response.validationMessage,
								type: 'question',
								confirmButtonText: 'Да',
								cancelButtonText: 'Нет',
								showCloseButton: true
							}, () => {
								this.model.forceSet = true;
								this.dataSaving = true;
								this.requestsService.validateAndSavePlannedDate(this.model).subscribe(()=>
								{
									this.onContinueEvent.emit();
									this.cancel();
									this.dataSaving = false;
								});
							});
							break;
						}
					case PlannedDateValidationResult.Success:
						{
							this.onContinueEvent.emit();
							this.cancel();
							break;
						}
				}
			}
			this.dataSaving = false;
		})
	}

	private _loadData(): void {
	}

	private _buildForm(): void {
		this.form = new FormGroup({
			commentText: new FormControl(null),
			plannedDate: new FormControl(null, Validators.required)
		});
	}
}