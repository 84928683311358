import { Pipe, PipeTransform } from '@angular/core';
import { RequestComment } from '../models/request/request-comment';
import { SelectedCommentsFilter } from '../models/request/comments/selected-comment-filter';

@Pipe({
    name: 'CommentsFilter',
    pure: false
})
export class CommentsFilterPipe implements PipeTransform {
    transform(comments: RequestComment[], filter: SelectedCommentsFilter): any {

        if (!comments || !filter) {
            return comments;
        }

        return comments.filter(item => filter.selectedTab === "all" && ((item.commentKindId === 3 && filter.showSystemComments) || item.commentKindId !== 3) ||
                                    item.commentKindId === 1 && filter.selectedTab  === "general" ||
                                    item.commentKindId === 2 && filter.selectedTab  === "internal" ||
                                    item.commentKindId === 3 && filter.selectedTab  === "system"
                                );
    }
}