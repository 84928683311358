import { Injectable } from "@angular/core";
import { CompositeFilterDescriptor, DataSourceRequestState, FilterDescriptor, toDataSourceRequestString } from "@progress/kendo-data-query";

@Injectable()
export class KendoDataSourceHelper {

	constructor() {

	}

	public toQueryString(compositeFilter: CompositeFilterDescriptor): string {
		let queryStr = `${toDataSourceRequestString({ filter: compositeFilter } as DataSourceRequestState)}`;
		return this.replaceDateBug(queryStr);
	}

	public StatetoQueryStrings(state: DataSourceRequestState): string {
		let queryStr = `${toDataSourceRequestString(state)}`;
		return this.replaceDateBug(queryStr);
	}

	private replaceDateBug(queryStr: string): string {
		//хак, это ошибка компонента грид
		queryStr = queryStr.replace(/T\d{2}-00-0/g, 'T00-00-00');
		return queryStr;
	}
}
