import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ReportsService } from "../../shared/services/reports.service";
import { NotificationType } from "../../core/services/notification-type";
import { NotificationService } from "../../core/services/notification.service";
import { saveAs } from 'file-saver';
import { AppService } from "../../app.service";
import { FileUtil } from "../../shared/utils";

@Component({
	selector: 'stop-reason-report-page',
	templateUrl: './stop-reason.page.html',
	styleUrls: [
		'./stop-reason.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class StopReasonPage implements OnInit {
	public dataGenerating = false;
	public dataDownloading = false;
	private selectedFile: File | null = null;

	@ViewChild('fileInput') fileInput!: ElementRef;

	constructor(
		private reportsService: ReportsService,
		private notificationService: NotificationService,
		private appService: AppService) {
		appService.pageTitle = 'Отчет о причинах остановки'
	}
	ngOnInit(): void {

	}

	onFileSelected(event: any): void {
		this.selectedFile = event.target.files[0];
		this.generateReport();
		this.selectedFile = null;
		const inputFile = event.target;
		inputFile.value = '';
	}

	downloadTemplate(): void {
		this.dataDownloading = true;
		this.reportsService.downloadStopReasonExample()
			.subscribe(result => {
				if (result.isSuccessful) {
					const bytes = FileUtil.base64ToArrayBuffer(result.data.data);
					const blob = new Blob([bytes], { type: result.data.extension });
					saveAs(blob, result.data.fileName);
				}

				this.dataDownloading = false;
			}, error => this.dataDownloading = false);
	}
	generateReport() {
		if (!this.selectedFile) {
			this.notificationService.info({
				title: "Не удаётся сгенерировать отчёт",
				message: 'Не выбран файл',
			});
			return;
		}
		this.dataGenerating = true;
		this.reportsService.getStopReason(this.selectedFile.name, this.selectedFile)
			.subscribe(blob => {
				if (blob.type === 'application/octet-stream') {
					saveAs(blob, `Отчёт_о_причинах_остановки.xlsx`);
					this.dataGenerating = false;

				} else if (blob.type === 'application/json') {
					blob
						.text()
						.then(result => {
							const response = JSON.parse(result);
							if (!response.isSuccessful) {
								this.notificationService.error({
									title: 'Ошибка',
									message: response.errorDescription as string,
									notificationType: NotificationType.SweetAlert
								});
							}

							this.dataGenerating = false;
						});
				}
			});
	}
	importRequests() {
		// Открываем файловый диалог при нажатии на кнопку "Импорт заявок"
		this.fileInput.nativeElement.click();
	}
}
