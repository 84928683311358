import { Component, OnInit, Renderer, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { LoginDto } from '../shared/models/LoginDto';
import { NgForm } from '@angular/forms';
import { SecurityService } from '../core/services/security.service';

@Component({
	selector: 'login',
	templateUrl: './login.page.html',
	styleUrls: [
		'./login.page.scss',
		'../../vendor/styles/pages/authentication.scss',
		'../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class LoginPage implements OnInit {

	@ViewChild('loginForm') loginForm: NgForm;

	credentials: LoginDto = new LoginDto();
	returnUrl: string;
	error = '';
	dataLoading = false;
	tokenHasExpired: boolean = false;

	constructor(private appService: AppService,
		private securityService: SecurityService,
		private router: Router,
		private route: ActivatedRoute) {
		this.appService.pageTitle = 'Вход';
	}

	ngOnInit() {
		this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
		this.tokenHasExpired = this.route.snapshot.queryParamMap.get('tokenHasExpired') === "true";

		if (this.tokenHasExpired){
			this.error = "Истекло время сессии";
		}
	}

	signIn() {

		if (this.loginForm.invalid) {
			this.appService.MarkFormDirty(this.loginForm);
			return;
		}

		this.error = '';
		this.dataLoading = true;
		this.securityService.login(this.credentials).subscribe(res => {
			this.dataLoading = false;

			if (res.isSuccessful){
				this.router.navigateByUrl(this.returnUrl && this.returnUrl !== '/' ? this.returnUrl : 'requests');
			} else {
				this.error = res.errorDescription;
			}

		}, x => {
			this.dataLoading = false;
			this.error = x.errorDescription;
		});
	}

}
