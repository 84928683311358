import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {forkJoin, Observable, pipe, Subject} from 'rxjs';
import {DialogRef} from '@progress/kendo-angular-dialog';
import {finalize, takeUntil} from 'rxjs/operators';

import {RequestUninstallDevicesService} from '../../../services/request-uninstall-devices.service';
import {DeviceValidation} from '../../../models';

import {ActivityStatusEnum, DeviceStatus, RequestStatus} from '../../../enums';
import {DataSourceRequestState, FilterDescriptor, groupBy} from '@progress/kendo-data-query';
import {KeyValueObject} from '../../../models/core/KeyValueObject';
import {HierarchyLookupModel} from '../../../models/core/HierarchyLookupModel';
import {LookupService} from '../../../services/lookup.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {FilterUtil} from '../../../utils';
import { NewRequestDevice } from '../../../models/request/new-request/new-request-device';
import { ServiceCentersService } from '../../../services/service-centers.service';
import { DeviceKindEnum } from '../../../enums/device-kind.enum';
import { DeviceConditionDto } from '../../../models/device/device-condition.dto';
import { DeviceConditionsService } from '../../../services/device-conditions.service';
import { DeviceConditionEnum } from '../../../enums/device-conditions.enum';
import { For1CService } from '../../../services/for-1c.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ReserveResult } from '../../../enums/reserve-result.enum';
import { RequestsService } from '../../../services/requests.service';
import { NotificationType } from '../../../../core/services/notification-type';
import { SecurityService } from '../../../../core/services/security.service';

@Component({
  selector: 'app-check-devices-at-warehouse',
  templateUrl: './check-devices-at-warehouse.component.html',
  styleUrls: ['../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CheckDevicesAtWarehouseComponent implements OnInit, OnDestroy {

	@Input() public requestId: number;
	@Input() public newActivityStatusId: number;
	@Input() public warehouseId?: number;
	@Input() public showWarehouseForm: boolean = false;
	@Input() public device: NewRequestDevice;
	@Input() public devices: NewRequestDevice[] = [];
	@Input() public isReadOnly: boolean = false;
	@Input() public isMobile: boolean = false;
	@Input() public contragentId: number;
	@Input() public contragentIntegraCode: string;
	@Input() shortcutReturnEquipment: boolean = false;
	@Output() public continueEmitter: EventEmitter<{result: boolean, recipientWarehouseId, showWarehouseForm: boolean}>
		= new EventEmitter<{result: boolean, recipientWarehouseId, showWarehouseForm: boolean}>();
	@Output() public cancelEmitter :EventEmitter<any> = new EventEmitter();
	@Output() public  checkNextSerialNumberEmitter: EventEmitter<{result: boolean, recipientWarehouseId, showWarehouseForm: boolean}>
		= new EventEmitter<{result: boolean, recipientWarehouseId, showWarehouseForm: boolean}>();
	get cardHeight(): number {
		return window.innerHeight - 325;
	}
	get isValidateToWarehouse(): boolean {
		return this.newActivityStatusId === ActivityStatusEnum.awaitingReturnToWarehouse;
	}

	get shouldBeReturnedToWarehouse(): boolean {
		return this.formDevice.get('shouldBeReturnedToWarehouse').value;
	}

	@Input()
	serviceCenterId: number;
	kryptoKeyIsDisabled = false;

	deviceTypes: KeyValueObject[] = [];
	deviceConnectionTypes: KeyValueObject[] = [];
	deviceModels: HierarchyLookupModel[] = [];
	supplierModels: HierarchyLookupModel[] = [];
	deviceSuppliers: KeyValueObject[] = [];
	deviceConnectionTypesGrouped: any[] = [];
	deviceConnectionTypesGroupedDropdown: any[] = [];
	formWarehouses: KeyValueObject[] = [];
	formWarehouseAreas: KeyValueObject[] = [];
	formWarehouseCells: KeyValueObject[] = [];
	warehouses: KeyValueObject[] = [];
	warehouseAreas: KeyValueObject[] = [];
	warehouseCells: KeyValueObject[] = [];
	loadingWarehouseAreas: boolean;
	loadingWarehouseCells: boolean;
	deviceConditionsSource: DeviceConditionDto[] = [];
	deviceConditions: KeyValueObject[] = [];
	requestDevicesInstalledOnTsp: NewRequestDevice[] = [];

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	public validationMessage: string;

	public formDevice: FormGroup;

	public dataSaving = false;

	private _destroy$ = new Subject<boolean>();

  constructor(public dialog: DialogRef,
							private _fb: FormBuilder,
							private _uninstallDevicesService: RequestUninstallDevicesService,
							private _lookupService: LookupService,
							private _notificationService: NotificationService,
							private _serviceCentersService: ServiceCentersService,
							public requestsService: RequestsService,
							private deviceConditionsService: DeviceConditionsService,
							private notificationService: NotificationService,
							private _for1CService: For1CService,
							private securityService: SecurityService) {
		this._buildForm();
	}

	get snControlsDisabled(): boolean {
		return this.newActivityStatusId === RequestStatus.acceptanceDeviceByStorekeeper;
	}

	get isPart(): boolean {
		if (this.device){
			return this.device.deviceKindId === <number>DeviceKindEnum.component;
		}
		return false;
	}

	public ngOnInit(): void {
		if (!!this.contragentIntegraCode) {
			this._for1CService.getContragentInfo(this.contragentIntegraCode).subscribe(resp => {
				let hasCryptoKey = resp.data != undefined && resp.data.needSecurityKeyForReserve;
				if (hasCryptoKey === false){
					this.kryptoKeyIsDisabled = true;
					this.formDevice.get('hasCryptoKey')['disable']();
				}

				this.formDevice.patchValue({
					hasCryptoKey: hasCryptoKey,
				});
			});
		}

		this.requestsService.getRequestDevices(this.requestId).subscribe(res => {
			this.requestDevicesInstalledOnTsp = res.filter(f => f.issuedSuccess && f.isWrittenOff
				&& ReserveResult.installedOnTsp === f.reserveResultId);
		});

		const deviceTypes$ = this._lookupService.getData('device-types', null);
		const deviceConnectionTypes$ = this._lookupService.getData('device-connection-types', {
			skip: 0,
			take: null,
			filter: { logic: 'and', filters: [{ field: 'ParentDeviceConnectionTypeId', operator: 'isnotnull' }] }
		});
		const deviceModels$ = this._lookupService.getHierarchyData('device-models', null);
		
		const deviceConditions$ = this.deviceConditionsService.getConditionsByContragentsConditionType(this.contragentId, this.device.isDeviceBroken);

		forkJoin([deviceTypes$, deviceConnectionTypes$, deviceModels$, deviceConditions$])
			.subscribe(results => {
				this.deviceTypes = results[0];
				this.deviceConnectionTypes = results[1];
				this.deviceConnectionTypesGrouped = groupBy(results[1], [{field: 'groupName'}]);
				this.deviceConnectionTypesGroupedDropdown = this.deviceConnectionTypesGrouped;
				this.deviceModels = results[2];
				this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null).map(x => new KeyValueObject(x.id, x.name));
				
				if (this.device){
					this.supplierModels = this.getDeviceModels(this.device.deviceSupplierId);
				}

				this.deviceConditionsSource = results[3];

				this.deviceConditions = this.deviceConditionsSource
					.map(x => new KeyValueObject(x.deviceConditionId, x.name));
				
				if (this.deviceConditions.length === 1) {
					this.formDevice.patchValue({
						deviceConditionId: this.deviceConditions[0].id,
					});
				} else {
					let currentValue = this.formDevice.get("deviceConditionId").value;
					this.formDevice.patchValue({
						deviceConditionId: this.deviceConditions.map(x => x.id).includes(currentValue) ? currentValue : null,
					});
				}
			});

		if (this.showWarehouseForm) {
			this.loadWarehouses();
			if (this.warehouseId) {
				this.formDevice.patchValue({
					recipientWarehouseId: this.warehouseId,
				});
			}

			this.formDevice.get('recipientWarehouseId').setValidators(Validators.required);
			this.formDevice.get('warehouseAreaId').setValidators(Validators.required);
			this.formDevice.get('warehouseCellId').setValidators(Validators.required);
			this.formDevice.get('deviceConditionId').setValidators(Validators.required);
		}
		
		if (this.isReadOnly) {
			this.formDevice.get('serialNumber')['disable']();
			this.formDevice.get('isNotDefined')['disable']();
			this.formDevice.get('requestDeviceId')['disable']();
			this.formDevice.get('name')['disable']();
			this.formDevice.get('deviceTypeId')['disable']();
			this.formDevice.get('deviceConnectionTypeId')['disable']();
			this.formDevice.get('deviceSupplierId')['disable']();
			this.formDevice.get('deviceModelId')['disable']();
			this.formDevice.get('isDeviceBroken')['disable']();
			this.formDevice.get('brokenDescription')['disable']();
			this.formDevice.get('recipientWarehouseId')['disable']();
			this.formDevice.get('warehouseAreaId')['disable']();
			this.formDevice.get('warehouseCellId')['disable']();
			this.formDevice.get('deviceConditionId')['disable']();
			this.formDevice.get('hasCryptoKey')['disable']();
			this.formDevice.get('completeKit')['disable']();
		} else {
			this.formDevice.get('serialNumber')[this.snControlsDisabled ? 'disable' : 'enable']();
			this.formDevice.get('isNotDefined')[this.snControlsDisabled ? 'disable' : 'enable']();
		}

		this.formDevice.get('isDeviceBroken').valueChanges
			.pipe(takeUntil(this._destroy$))
			.subscribe((isBroken: boolean) => {
				var newDeviceId = isBroken
					? <number>DeviceConditionEnum.broken
					: <number>DeviceConditionEnum.used;

				this.formDevice.get("deviceConditionId").setValue(newDeviceId);
		});

		// this.formDevice.get('deviceConditionId').valueChanges
		// 	.pipe(takeUntil(this._destroy$))
		// 	.subscribe((id: number) => {
		// 		var isBroken = id === <number>DeviceConditionEnum.broken;
		// 		this.formDevice.get("isDeviceBroken").setValue(isBroken);
		// 	})

		if (this.device) {
			this.formDevice.patchValue({
				isNotDefined: this.device.isNotDefinedSn,
				isDeviceBroken: this.device.isDeviceBroken,
				name: this.device.name,
				serialNumber: this.device.serialNumber,
				brokenDescription: this.device.brokenDescription,
				deviceTypeId: this.device.deviceTypeId,
				deviceConnectionTypeId: this.device.deviceConnectionTypeId,
				deviceSupplierId: this.device.deviceSupplierId,
				deviceConditionId: this.device.deviceConditionId,
				deviceModelId: this.device.deviceModelId,
				requestDeviceId: this.device.requestDeviceId,
				hasCryptoKey: this.device.hasCryptoKey,
				completeKit: this.device.completeKit,
				shouldBeReturnedToWarehouse: this.device.shouldBeReturnedToWarehouse
			});

			if (!this.device.isDeviceBroken) {
				this.formDevice.get('brokenDescription').disable();
			}
		}

		this.updateValidation('deviceSupplierId', !this.formDevice.controls['deviceSupplierId'].disabled, false);
		this.updateValidation('deviceModelId', !this.formDevice.controls['deviceModelId'].disabled, false);
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public saveChanges() {
		if (this.formDevice.get("serialNumber").value) {
			let serialNumber = this.formDevice.get("serialNumber").value;
			let installDeviceAndUninstallDeviceMatchingSerialNumbers = this.requestDevicesInstalledOnTsp.some(x => x.serialNumber === serialNumber);
			if (installDeviceAndUninstallDeviceMatchingSerialNumbers){
				this.notificationService.error({
					title: 'Ошибка',
					message: 'Указанные серийные номера демонтированного оборудования числится среди установленных по этой же заявке. Проверьте корректность серийных номеров.',
					notificationType: NotificationType.Toast
				});
				this.dataSaving = false;
				return;
			}
		}

		this.formDevice.get('recipientWarehouseId').markAsPristine();
		this.formDevice.get('warehouseAreaId').markAsPristine();
		this.formDevice.get('warehouseCellId').markAsPristine();
		this.validateSaveChanges(0, !this.shouldBeReturnedToWarehouse);
	}

	public validateSaveChanges(validationStep: number = 0, skipValidation = false){
		if (skipValidation){
			this.saveDeviceData();
			return;
		}

		switch (validationStep) {
			case 0:
				if (this.formDevice.dirty) {
					this._notificationService.confirmation({
							message: 'Атрибуты оборудования изменены. Продолжить?',
							type: 'question',
							confirmButtonText: 'Да',
							cancelButtonText: 'Нет',
							showCloseButton: true
						}, () => 
						{
							validationStep++;
							this.validateSaveChanges(validationStep);
						});
				} else {
					validationStep++;
					this.validateSaveChanges(validationStep);
				}
				break;			
			case 1:			
				var hasEmptyNomenclature = this.devices.some(x => !x.nomenclature);	
				if (hasEmptyNomenclature && this.shortcutReturnEquipment){
					this.notificationService.info({
						title: 'Отсутствует номенклатура',
						message: `Системе не удалось произвести идентификацию модели оборудования. Для использования в других заявках, дождитесь финальной обработки заявки и перевода в "Выполнено".`,
						notificationType: NotificationType.SweetAlert,
						confirmationFunction: () => {
							validationStep++;
							this.validateSaveChanges(validationStep);
						}
					});
				} else {
					validationStep++;
					this.validateSaveChanges(validationStep);
				}
				break;	
			default:
				this.saveDeviceData();
				break;
		}
	}

	public cancel() {
		if (!!this.dialog 
			&& !!this.dialog.close
			&& typeof this.dialog.close === 'function') {
				this.dialog.close();
				this.cancelEmitter.emit();
		}
	}

	public loadWarehouses(): void {
		this._serviceCentersService.getWarehouses(this.serviceCenterId).subscribe(data => {
			this.warehouses = data;
			this.formWarehouses = data;
			if (data.length == 1) {
				this.formDevice.patchValue({
					recipientWarehouseId: data[0].id,
				});
				this.recipientWarehouseChange(data[0].id);
			}
		});
	}

	public recipientWarehouseChange(warehouseId: number): void {
		this.loadAreas(warehouseId);
	}

	public loadAreas(warehouseId: number): void {
		if (!!warehouseId) {
			this.loadingWarehouseAreas = true;
			var state: DataSourceRequestState = {
				filter: {
					logic: "and",
					filters: [{
						operator: "eq",
						field: "WarehouseId",
						value: warehouseId
					}]
				}
			}
			this._lookupService.getData('warehouse-areas', state).subscribe(data => {
				this.clearWarehouseAreas();
				this.warehouseAreas = data;
				this.formWarehouseAreas = data;
				if (data.length == 1) {
					this.formDevice.patchValue({
						warehouseAreaId: data[0].id,
					});
					this.warehouseAreaChange(data[0].id);
				}
			}).add(() => this.loadingWarehouseAreas = false);
		} else {
			this.clearWarehouseAreas();
		}		
	}

	public warehouseAreaChange(warehouseAreaId: number) {
		this.loadWarehouseCells(warehouseAreaId);
	}

	public loadWarehouseCells(warehouseAreaId: number): void {
		if (!!warehouseAreaId) {
			this.loadingWarehouseCells = true;
			var state: DataSourceRequestState = {
				filter: {
					logic: "and",
					filters: [{
						operator: "eq",
						field: "WarehouseAreaId",
						value: warehouseAreaId
					},
					{
						operator: "eq",
						field: "WarehouseCellTypeId",
						value: 2
					}]
				}
			}
			this._lookupService.getData('warehouse-cells', state).subscribe(data => {
				this.clearWarehouseCells();
				this.warehouseCells = data;
				this.formWarehouseCells = data;
				if (data.length == 1) {
					this.formDevice.patchValue({
						warehouseCellId: data[0].id,
					});
				}
			}).add(() => this.loadingWarehouseCells = false);
		} else {
			this.clearWarehouseCells()
		}		
	}

	private clearWarehouseAreas(): void {
		this.clearWarehouseCells();
		this.warehouseAreas = [];
		this.formWarehouseAreas = [];
		this.formDevice.get('warehouseAreaId').setValue(null);
	}

	private clearWarehouseCells(): void {
		this.warehouseCells = [];
		this.formWarehouseCells = [];
		this.formDevice.get('warehouseCellId').setValue(null);
	}

	public changeDeviceBroken() {
		//тут почему-то isDeviceBroken работает наоборот
		let isDeviceBroken = this.formDevice.get("isDeviceBroken").value;
		this.deviceConditionsService.getConditionsByContragentsConditionType(this.contragentId, !isDeviceBroken)
		.subscribe(res => {
			let brokenDescriptionControl = this.formDevice.get("brokenDescription");
			
			if (isDeviceBroken) {
				brokenDescriptionControl.setValue(null);
				brokenDescriptionControl.disable();
			}
			else {
				brokenDescriptionControl.enable();
			}

			this.deviceConditionsSource = res;
			this.deviceConditions = this.deviceConditionsSource
				.map(x => new KeyValueObject(x.deviceConditionId, x.name));

			if (this.deviceConditions.length === 1) {
				this.formDevice.get("deviceConditionId").setValue(this.deviceConditions[0].id);
			} else {
				this.formDevice.get("deviceConditionId").setValue(null);
			}
		});
	}

	public recipientWarehousesFilterChange(value: any): void {
		const searchString = value.toLowerCase();
		this.formWarehouses = this.warehouses
			.filter(x => x.name.toLowerCase().includes(searchString));
	}

	public warehouseAreasFilterChange(value: string): void {
		this.formWarehouseAreas = this.warehouseAreas
			.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
	}

	public warehouseCellsFilterChange(value: any): void {
		const searchString = value.toLowerCase();
		this.formWarehouseCells = this.warehouseCells
			.filter(x => x.name.toLowerCase().includes(searchString));
	}

	private _buildForm(): void {
		this.formDevice = this._fb.group({
			serialNumber: [null, Validators.required],
			isNotDefined: [null],
			requestDeviceId: [null],
			name: [null],
			deviceTypeId: [null],
			deviceConnectionTypeId: [null],
			deviceSupplierId: [null, Validators.required],
			deviceModelId: [null, Validators.required],
			isDeviceBroken: [false],
			brokenDescription: [null],
			recipientWarehouseId: [null],
			warehouseAreaId: [null],
			warehouseCellId: [null],
			deviceConditionId: [null],
			hasCryptoKey: [null],
			completeKit: [null],
			shouldBeReturnedToWarehouse: [null]
		});
	}

	private updateValidation(controlName: string, required: boolean, setValueToNull: boolean = true): void {
		const snCtrl = this.formDevice.get(controlName);
		if (required) {
			snCtrl.setValidators(Validators.required);
		} else {
			snCtrl.clearValidators();
		}

		if (setValueToNull)
		{
			snCtrl.setValue(null);
		}
		snCtrl.updateValueAndValidity();
	}

	public getDeviceModels(supplierId: number): HierarchyLookupModel[] {
		return FilterUtil.getDeviceModelsBySuplierId(this.deviceModels, supplierId);
	}

	public supplierOnChange(inputValue: any)
	{
		this.formDevice.controls['deviceModelId'].setValue(null);
		this.supplierModels = this.getDeviceModels(inputValue.id);
	}

	private _completeForm() {
  	const  deviceStatusId = this._getDeviceStatusByNewRequestStatusId();
  	console.log({deviceStatusId : deviceStatusId})
		this._uninstallDevicesService.getUndefinedDevice(this.requestId, deviceStatusId)
			.pipe(takeUntil(this._destroy$))
			.subscribe((device) => {
				if (device) {
					console.log(device);
					this.formDevice.patchValue(device);
					console.log(this.formDevice);
				} else {
					this.validationMessage = 'Не найдена строка с неопознанным серийным номером';
				}
			});
	}

	private _getDeviceStatusByNewRequestStatusId(): DeviceStatus {
		switch (this.newActivityStatusId) {
			case RequestStatus.acceptanceDeviceByStorekeeper: return DeviceStatus.deInstalled;
			case RequestStatus.acceptanceDeviceByStorekeeperCompleted: return DeviceStatus.deliveredToWarehouse;
			default: return DeviceStatus.deInstalled;
		}
	}

	private getNextDevice(): NewRequestDevice {
		let res = null;
		if (!!this.devices && this.devices.length > 0) {
			this.devices = this.devices.filter(f => f.requestDeviceId !== this.device.requestDeviceId);

			if (this.devices.length > 0) {
				res = this.devices[0];
			}
		}

		return res;
	}

	private saveDeviceData(): void {
		this.formDevice.get('requestDeviceId').setValue(this.device.requestDeviceId);

		const body = { requestId: this.requestId,
			deviceKindId: this.device.deviceKindId,
			...this.formDevice.getRawValue() };

		this.validationMessage = null;
		this.dataSaving = true;

		let action$: Observable<DeviceValidation>;
		if (this.newActivityStatusId === ActivityStatusEnum.awaitingReturnToWarehouse) {
			action$ = this._uninstallDevicesService.validateDeviceToWarehouseAction(body);
		} else if (this.newActivityStatusId === ActivityStatusEnum.warehouseReceivedDevices) {
			action$ = this._uninstallDevicesService.validateReceivingDeviceAtWarehouseAction(body);
		}

		action$.pipe(
				finalize(() => this.dataSaving = false),
				takeUntil(this._destroy$))
			.subscribe((response: DeviceValidation) => {
				if (response.success) {
					const result = {result: true, recipientWarehouseId: this.formDevice.get('recipientWarehouseId').value, showWarehouseForm: this.showWarehouseForm};
						if (this.devices !== null) {
							this.device = this.getNextDevice();
							if (this.device === null) {
								this.continueEmitter.next(result);
								this.cancel();
								return;
							}

							this.ngOnInit();
						}
				} else {
					this.validationMessage = response.message;
				}
			});

	}

	public handleConnectionTypeFilter(value: any) {
		let filtered = this.deviceConnectionTypes.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
		this.deviceConnectionTypesGroupedDropdown = groupBy(filtered, [{field: 'groupName'}]);	
	}

	isPartialDeviceReturn(): boolean {
		return this.securityService.isPartialDeviceReturn();
	}

	
	public returnedChanged(event: Event){
		if (!this.device.nomenclature){
			this.formDevice.get('shouldBeReturnedToWarehouse').setValue(true);
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Оборудование не идентифицировано. Вы не можете его использовать в дальнейшем. Пожалуйста сдайте его на склад для идентификации.',
				notificationType: NotificationType.SweetAlert
			});
		}

		const checkbox = event.target as HTMLInputElement;
		if (!checkbox.checked){
			this.disableOrEnableForm(true);
		} else {
			this.disableOrEnableForm(false);
		}
	}

	snDisabledValueChange(event: Event){
		this.validationMessage = '';
		const checkbox = event.target as HTMLInputElement;
		if (this.isPart) {
			this.updateValidation('serialNumber', false);
		} else {
			this.formDevice.get('serialNumber')[checkbox.checked ? 'disable' : 'enable']();
			this.updateValidation('serialNumber', !checkbox.checked);
		}
	}

	public disableOrEnableForm(disable: boolean){
		this.formDevice.get('serialNumber')[disable ? 'disable' : 'enable']();
		this.formDevice.get('isNotDefined')[disable ? 'disable' : 'enable']();
		this.formDevice.get('requestDeviceId')[disable ? 'disable' : 'enable']();
		this.formDevice.get('name')[disable ? 'disable' : 'enable']();
		this.formDevice.get('deviceTypeId')[disable ? 'disable' : 'enable']();
		this.formDevice.get('deviceConnectionTypeId')[disable ? 'disable' : 'enable']();
		this.formDevice.get('deviceSupplierId')[disable ? 'disable' : 'enable']();
		this.formDevice.get('deviceModelId')[disable ? 'disable' : 'enable']();
		this.formDevice.get('isDeviceBroken')[disable ? 'disable' : 'enable']();
		this.formDevice.get('brokenDescription')[disable ? 'disable' : 'enable']();
		this.formDevice.get('recipientWarehouseId')[disable ? 'disable' : 'enable']();
		this.formDevice.get('warehouseAreaId')[disable ? 'disable' : 'enable']();
		this.formDevice.get('warehouseCellId')[disable ? 'disable' : 'enable']();
		this.formDevice.get('deviceConditionId')[disable ? 'disable' : 'enable']();
		if (!this.kryptoKeyIsDisabled && !disable){
			this.formDevice.get('hasCryptoKey')['enable']();
		} else {
			this.formDevice.get('hasCryptoKey')['disable']();
		}
		this.formDevice.get('completeKit')[disable ? 'disable' : 'enable']();	
	}
}