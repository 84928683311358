import { ExpertiseActForm } from "./expertise-act-form";

export class ExpertiseActDevice {
	deviceId: number;
	deviceTypeId: number;
	name: string;
	deviceTypeName: string;
	serialNumber: string;
	manufacturer: string;
	partNumber: string;
	deviceModelName: string;
	deviceConnectionTypeId: number;
	isUninstallDevice: boolean;
	isFinished: boolean = false;
	filledForms: ExpertiseActForm[];

	choosen: boolean = false;
}
