import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'dual-choice',
    templateUrl: './dual-choice.component.html',
    styleUrls: ['./dual-choice.component.scss']
})
export class DualChoiceComponent {

    @Input() title: string;
    @Input() resultLinesTitle: string;
    @Input() resultLines: string[] = [];
    @Input() showResultLines: boolean = false;

    @Output() positiveEvent = new EventEmitter;
    @Output() negativeEvent = new EventEmitter;

    positiveClick() {
        this.positiveEvent.emit();
    }

    negativeClick() {
        this.negativeEvent.emit();
    }
}