import { Component } from '@angular/core';
import { ILoadEvent } from 'angular8-yandex-maps';

@Component({
	selector: 'map-ya-polygon',
	templateUrl: './map-ya-polygon.component.html',
	styleUrls: ['./map-ya-polygon.component.scss']
})
export class MapYaPolygonComponent {

	private drawingPolygonControlButton;
	private drawingPolygon;
	private drawingPolygonStateMonitor;

	private getSelectedRequestsButton;

	private isCompletedPolygon: boolean = false;

	protected drawingPolygonControlButtonData = {
		content: 'Режим выделения'
	};

	protected drawingPolygonControlButtonOptions = {
		float: 'right',
		floatIndex: 5,
		maxWidth: 150,
		size: 'medium'
	};

	protected drawingPolygonProperties = {};

	protected drawingPolygonOptions = {
		interactivityModel: 'default#geoObject',
		cursor: 'pointer',
		draggable: false,
		hasHint: false,
		editorDrawingCursor: "crosshair",
		editorMaxPoints: 5,
		fillColor: '#9FAD9F',
		fillOpacity: '0.5',
		strokeColor: '#A1A18F',
		strokeWidth: 2,
		editorMenuManager: (items) => []
	};

	protected getSelectedRequestsButtonData = {
		content: 'Отобрать заявки'
	};

	constructor() {
		
	}

	public addDrawingPolygonControls(loadEvent: ILoadEvent, onClickedPolygonCallback: (polygon: any) => void) {
		const addDrawingPolygonButtonOptions = {...this.drawingPolygonControlButtonOptions};
		addDrawingPolygonButtonOptions['layout'] = this.createCustomButton(loadEvent.ymaps);

		this.drawingPolygonControlButton = new loadEvent.ymaps.control.Button({
			data: this.drawingPolygonControlButtonData,
			options: addDrawingPolygonButtonOptions});

		this.drawingPolygonControlButton.events.add(['click'], event => {
			if (!this.drawingPolygonControlButton.isSelected()) {
				if (this.drawingPolygon) {
					loadEvent.instance.geoObjects.remove(this.drawingPolygon);
				}
				this.addDrawingPolygon(loadEvent, onClickedPolygonCallback);
				this.drawingPolygon.editor.startDrawing();
			} else {
				this.drawingPolygon.editor.stopDrawing();
				loadEvent.instance.geoObjects.remove(this.drawingPolygon);

				this.removeGetRequestsButton(loadEvent.instance);
			}
		});

		loadEvent.instance.controls.add(this.drawingPolygonControlButton);
	}

	public addDrawingPolygon(loadEvent: ILoadEvent, onClickedPolygonCallback: (polygon: any) => void) {
		this.drawingPolygon = new loadEvent.ymaps.Polygon([], this.drawingPolygonProperties, this.drawingPolygonOptions);

		loadEvent.instance.geoObjects.add(this.drawingPolygon);

		this.drawingPolygonStateMonitor = new loadEvent.ymaps.Monitor(this.drawingPolygon.editor.state);

		this.drawingPolygonStateMonitor.add('drawing', isDrawing => {
			this.isCompletedPolygon = !isDrawing;
			if (this.isCompletedPolygon) {
				this.createGetRequestsButton(loadEvent, onClickedPolygonCallback);
			} else {
				this.removeGetRequestsButton(loadEvent.instance);
			}
		});
	}

	public deselectGetRequestButton() {
		if (this.getSelectedRequestsButton) {
			this.getSelectedRequestsButton.deselect();
		}
	}

	public clear(yaMap: any) {
		if (this.drawingPolygon) {
			yaMap.geoObjects.remove(this.drawingPolygon);
		}

		this.drawingPolygonControlButton.deselect();

		this.removeGetRequestsButton(yaMap);
	}

	private removeGetRequestsButton(yaMap: any): void {
		yaMap.controls.remove(this.getSelectedRequestsButton);
	}

	private createGetRequestsButton(loadEvent: ILoadEvent, onClickedPolygonCallback: (polygon: any) => void): void {
		const getSelectedRequestsButtonOptions = {...this.drawingPolygonControlButtonOptions};
		getSelectedRequestsButtonOptions['layout'] = this.createCustomButton(loadEvent.ymaps, false);
		getSelectedRequestsButtonOptions.floatIndex = 1;

		this.getSelectedRequestsButton = new loadEvent.ymaps.control.Button({
			data: this.getSelectedRequestsButtonData,
			options: getSelectedRequestsButtonOptions});

		this.getSelectedRequestsButton.events.add(['click'], event => {
			if (this.isCompletedPolygon) {
				onClickedPolygonCallback(this.drawingPolygon);
			}
		});

		loadEvent.instance.controls.add(this.getSelectedRequestsButton);
	}

	private createCustomButton(ymaps: any, useOnSelectedButtonClass: boolean = true) {
		const onSelectedButtonCssClass = useOnSelectedButtonClass ? '{% if state.selected %} map-btn-selected{% endif %}' : '';
		return ymaps.templateLayoutFactory.createClass(
			`
				<button class="btn map-btn${onSelectedButtonCssClass}">{{ data.content }}</button/>
			`
		);
	}
}
