import { Component, OnInit } from "@angular/core";
import { NotificationType } from "../../core/services/notification-type";
import { NotificationService } from "../../core/services/notification.service";
import { ReportsService } from "../../shared/services/reports.service";
import { ReportPageBase } from "../components/report-page-base";
import { saveAs } from 'file-saver';
import { AppService } from "../../app.service";

@Component({
	selector: 'retequip-report-page',
	templateUrl: './retequip-report.page.html',
	styleUrls: [
		'./retequip-report.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class RetEquipReportPage extends ReportPageBase implements OnInit {

	constructor(
		private reportsService: ReportsService,
		private notificationService: NotificationService,
		private appService: AppService
	) {
		super();
		appService.pageTitle = 'Отчет по возвращённому оборудованию';
	}

	ngOnInit(): void {
		this.range = { start: null, end: null };
	}

	generateReport() {

		this.generateReportByDateRange((startDate, endDate) => {

			this.reportsService.getReturnedEquipment(startDate, endDate)
				.subscribe(blob => {
					if (blob.type === 'application/octet-stream') {
						const localDate = new Date().toLocaleString().replace(',', '');
						const title = 'Отчет_по_возвращенному_на_склад_оборудованию_' + (!startDate && !endDate
							? '' : (!startDate
							? `по_${endDate}` : (!endDate
							? `от_${startDate}` : `от_${startDate}_по_${endDate}`))) +
							`_создан_${localDate}.xlsx`;
						saveAs(blob, title);
						this.dataGenerating = false;

					} else if (blob.type === 'application/json') {
						blob
							.text()
							.then(result => {
								const response = JSON.parse(result);
								if (!response.isSuccessful) {
									this.notificationService.error({
										title: 'Ошибка',
										message: response.errorDescription as string,
										notificationType: NotificationType.SweetAlert
									});
								}

								this.dataGenerating = false;
							});
					}
				});
		});
	}
}
