import { Component, OnInit, EventEmitter, Output, Injector, Input, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ServiceCentersService } from '../../../services/service-centers.service';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { NewRequestDevice } from '../../../models/request/new-request/new-request-device';
import { DeviceStatus } from '../../../enums';
import { RequestsService } from '../../../services/requests.service';
import { DeviceKindEnum } from '../../../enums/device-kind.enum';
import { DeviceRow } from './device-row.model';
import { LookupService } from '../../../services/lookup.service';
import { groupBy } from '@progress/kendo-data-query/dist/npm/grouping/group.operators';
import { HierarchyLookupModel } from '../../../models/core/HierarchyLookupModel';
import { DeviceTypeEnum } from '../../../enums/device-type.enum';
import { DeviceTypesService } from '../../../services/device-types.service';
import { KeyValueDataObject } from '../../../models/core/KeyValueDataObject';
import { CustomerEnum } from '../../../enums/customer.enum';
import { DeviceConditionsService } from '../../../services/device-conditions.service';
import { DeviceConditionDto } from '../../../models/device/device-condition.dto';
import { DeviceConditionEnum } from '../../../enums/device-conditions.enum';
import { GetNomenclaturesQuery } from '../../../models/for-1c/get-nomenclatures/get-nomenclatures-query';
import { For1CService } from '../../../services/for-1c.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../../../../core/services/notification.service';
import { NotificationType } from '../../../../core/services/notification-type';
import { finalize, map } from 'rxjs/operators';
import { RequestDevicesService } from '../../../services/request-devices.service';
import { RequestUninstallDevicesService } from '../../../services/request-uninstall-devices.service';

@Component({
	selector: 'editing-dismantled-devices',
	templateUrl: './editing-dismantled-devices.html',
	styleUrls: ['./editing-dismantled-devices.scss']
})
export class EditingDismantledDevices implements OnInit {

	@ViewChild('deviceForm') public deviceForm: NgForm;

	@Output()
	onContinueEvent = new EventEmitter<any>();

	@Input()
	requestId: number;
	@Input()
	serviceCenterId: number;
	@Input()
	contragentId: number;
	@Input()
	contragentIntegraCode: string;

	needSecurityKeyForReserve: boolean = false;

	warehouses: KeyValueObject[] = [];
	selectedWarehouse: KeyValueObject;
	deviceRows: DeviceRow[] = [];
	selectedDeviceRow: DeviceRow;

	deviceTypes: KeyValueDataObject<number>[] = [];
	deviceConnectionTypes: any[] = [];
	deviceConnectionTypesGrouped: any[] = [];
	deviceConnectionTypesGroupedDropdown: any[] = [];
	deviceModels: HierarchyLookupModel[] = [];
	deviceSuppliers: KeyValueObject[] = [];
	deviceConditions: DeviceConditionDto[] = [];
	warehouseAreas: KeyValueObject[] = [];

	loading: boolean = false;
	saveData: boolean = false;
	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	get cardHeight(): number {
		return window.innerHeight - 200;
	}
	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};
	constructor(
		public dialog : DialogRef,
		private serviceCentersSerivce: ServiceCentersService,
		private requestsService: RequestsService,
		private lookupService: LookupService,
		private deviceTypesService: DeviceTypesService,
		private deviceConditionsService: DeviceConditionsService,
		private for1CService: For1CService,
		private notificationService: NotificationService,
		private requestDevicesService: RequestDevicesService,
		private requestUninstallDevicesService: RequestUninstallDevicesService,
	) { }

	ngOnInit() {
		this.loading = true;
		this.loadData();
	}

	private loadData(): void {
		this.for1CService.getContragentInfo(this.contragentIntegraCode).subscribe(r => this.needSecurityKeyForReserve = r.data.needSecurityKeyForReserve)

		// запрашиваем оборудование
		this.requestsService.getRequestUninstallDevices(this.requestId)
			.subscribe(data => {
				this.deviceRows = this.getDeviceRows(data);

				this.deviceRows.forEach(deviceRow => {
					let isNotComponent = deviceRow.device.deviceKindId !== <number>DeviceKindEnum.component;
					deviceRow.isSerialNumberEnabled = (deviceRow.device.isNotDefinedSn === null
						|| deviceRow.device.isNotDefinedSn === undefined
						|| deviceRow.device.isNotDefinedSn === false)
						&& isNotComponent;

					deviceRow.isIsNotDefinedSnEnabled = isNotComponent;
					deviceRow.isDeviceConnectionTypeEnabled = isNotComponent;
					deviceRow.isHasCryptoKeyAndCompleteKitEnabled = isNotComponent;
					deviceRow.isSubscriberNumberEnabled = deviceRow.device.deviceTypeId === <number>DeviceTypeEnum.simCard
						&& this.contragentId === <number>CustomerEnum.rtk;
				});

				this.selectedDeviceRow = this.deviceRows[0];
				//запрашиваем склады по сервисному центру
				this.serviceCentersSerivce.getWarehouses(this.serviceCenterId).subscribe(data => {
					this.warehouses = data;
					// определяем, что у всего оборудования один и тот же склад (не считаем те, у которых нет склада) и этот склад есть в ранее загруженном списке
					if (this.deviceRows.map(x => x.device).filter(x => !!x.warehouseId).map(x => x.warehouseId).filter((value, index, self) => self.indexOf(value) === index).length === 1
						&& this.warehouses.map(x => x.id).includes(this.deviceRows[0].device.warehouseId)) {
							// если условие выполнилось, считаем этот склад выбранным по умолчанию
							this.selectedWarehouse = new KeyValueObject(this.deviceRows[0].device.warehouseId, this.deviceRows[0].device.warehouseName);
							// прописываем всему оборудованию этот склад, чтобы не осталось оборудования без склада
							this.deviceRows.forEach(x => x.device.warehouseId = this.selectedWarehouse.id);

							this.lookupService.getData('warehouse-areas', {
								filter: { logic: "and", filters: [{ operator: "eq", field: "WarehouseId", value: this.selectedWarehouse.id }] }
							}).subscribe(data => {
								this.warehouseAreas = data;

								this.deviceRows.forEach(deviceRow => {
									if (!!deviceRow.device.warehouseAreaId) {
										this.lookupService.getData('warehouse-cells', {
											filter: { logic: "and", filters: [{ operator: "eq", field: "WarehouseAreaId", value: deviceRow.device.warehouseAreaId },
												{ operator: "eq", field: "WarehouseCellTypeId", value: 2 }]
											}
										}).subscribe(data => deviceRow.device.warehouseCells = data);
									}
								});
							});
						}

						this.loading = false;
				});

				setTimeout(() => {
					if (!!this.deviceForm) {
						Object.keys(this.deviceForm.controls).forEach(field => {
							this.deviceForm.controls[field].markAsTouched();
							this.deviceForm.controls[field].valueChanges
								.subscribe(_ => setTimeout(() => this.updateErrorRows(this.selectedDeviceRow)));
						});
					}
				}, 1000);

				this.lookupService.getHierarchyData('device-models', null).subscribe(data => {
					this.deviceModels = data.filter(x => x.parentId !== null);
					this.deviceSuppliers = data.filter(x => x.parentId === null).map(x => new KeyValueObject(x.id, x.name));

					this.deviceRows.forEach(deviceRow => {
						deviceRow.deviceModels = this.deviceModels.filter(x => x.parentId === deviceRow.device.deviceSupplierId).map(x => new KeyValueObject(x.id, x.name));

						let ingenicoSupplierId = !!this.deviceSuppliers.find(x => x.name === 'Ingenico') ? this.deviceSuppliers.find(x => x.name === 'Ingenico').id : null;
						deviceRow.isCryptoKeyEnabled =
							(deviceRow.device.deviceTypeId === <number>DeviceTypeEnum.terminal
							|| deviceRow.device.deviceTypeId === <number>DeviceTypeEnum.mobileTerminal
							|| deviceRow.device.deviceTypeId === <number>DeviceTypeEnum.intelligentPinPad)
							&& deviceRow.device.deviceSupplierId === ingenicoSupplierId
							&& this.contragentId === <number>CustomerEnum.rtk;
					});
				});

				this.deviceConditionsService.getConditionsByContragent(this.contragentId).subscribe(data => {
					this.deviceConditions = data;
					this.deviceRows.forEach(deviceRow => {
						deviceRow.device.deviceConditions = deviceRow.device.isDeviceBroken === true
							? this.deviceConditions.filter(x => x.deviceConditionTypeId == <number>DeviceConditionEnum.broken).map(x => new KeyValueObject(x.deviceConditionId, x.name))
							: this.deviceConditions.filter(x => x.deviceConditionTypeId == <number>DeviceConditionEnum.used).map(x => new KeyValueObject(x.deviceConditionId, x.name));
					});
				});

				this.getCryptoKeysAndSubscriberNumbersForAllDevices();

				this.deviceRows.forEach(deviceRow => {
					this.getNomenclatures(deviceRow);
					this.updateErrorRows(deviceRow);
				});
			});

		this.deviceTypesService.getDeviceTypesWithKinds().subscribe(data => this.deviceTypes = data);

		this.lookupService.getData('device-connection-types', { skip: 0, take: null,
			filter: { logic: 'and', filters: [{ field: 'ParentDeviceConnectionTypeId', operator: 'isnotnull' }] }
		}).subscribe(
			data => {
				this.deviceConnectionTypes = data;
				this.deviceConnectionTypesGrouped = groupBy(data, [{field: 'groupName'}]);
				this.deviceConnectionTypesGroupedDropdown = this.deviceConnectionTypesGrouped;
			});
	}

	private getDeviceRows(devices: NewRequestDevice[]): DeviceRow[] {
		let deviceRows = [];
		devices.filter(x => x.deviceStatusId === <number>DeviceStatus.receivedAtWarehouse).forEach(device => {
			deviceRows.push({device: device, isSelected: deviceRows.length === 0});
		})
		return deviceRows;
	}

	private getNomenclatures(deviceRow: DeviceRow) {
		let body = new GetNomenclaturesQuery;
		body.deviceTypeId = deviceRow.device.deviceTypeId;
		body.deviceModelName = deviceRow.device.deviceModelName;
		body.deviceSupplierName = deviceRow.device.deviceSupplierName;
		body.deviceConnectionTypeId = deviceRow.isDeviceConnectionTypeEnabled ? deviceRow.device.deviceConnectionTypeId : null;
		this.for1CService.getNomenclatures(body).subscribe(r => {
			if (r.isSuccessful) {
				let arr: any[] = [];
				r.data.forEach(n => arr.push({nomenclature: n}))
				deviceRow.device.nomenclatures = arr;
				
				let selectedNomenclature: any  = {nomenclature: deviceRow.device.nomenclature};
				deviceRow.device.selectedNomenclature = selectedNomenclature;
				if (!deviceRow.device.nomenclatures.some(n => n.nomenclature == deviceRow.device.nomenclature)) {

					if (deviceRow.device.nomenclatures.length === 1) {
						deviceRow.device.nomenclature = deviceRow.device.nomenclatures[0].nomenclature;
						let selectedNomenclature: any  = {nomenclature: deviceRow.device.nomenclature};
						deviceRow.device.selectedNomenclature = selectedNomenclature;
					} else {
						deviceRow.device.nomenclature = null;
						deviceRow.device.selectedNomenclature = null;
					}
				}
			} else {
				deviceRow.device.nomenclatures = [];
			}

			this.updateErrorRows(deviceRow);
		});
	}

	private getCryptoKeysAndSubscriberNumbersForAllDevices() {
		let deviceRows = this.deviceRows.filter(deviceRow => {
			let device = deviceRow.device;
			return (device.cryptoKey === null || device.cryptoKey === undefined || device.cryptoKey === '')
				&& (device.subscriberNumber === null || device.subscriberNumber === undefined || device.subscriberNumber === '')
		});

		let serialNumbers = deviceRows.map(x => x.device.serialNumber);
		if (serialNumbers.length !== 0) {
			this.requestDevicesService.getAvailableSerialNumbers(this.requestId, serialNumbers, [], false).pipe(map(viewModels => {
				return viewModels;
			})).subscribe(res => {
				if (!!res && !!res.data) {
					deviceRows.forEach(deviceRow => {
						let result = res.data.availableSerialNumbers.find(s => s.serialNumber === deviceRow.device.serialNumber) 
						if (!!result) {
							deviceRow.device.cryptoKey = result.cryptoKey;
							deviceRow.device.subscriberNumber = result.subscriberNumber;
						}
					});
				}
			});
		}
	}

	private getCryptoKeysAndSubscriberNumbersForSingleDevice(deviceRow: DeviceRow) {
		let device = deviceRow.device;
		if ((device.cryptoKey === null || device.cryptoKey === undefined || device.cryptoKey === '' || deviceRow.isCryptoKeyEnabled === false)
			&& (device.subscriberNumber === null || device.subscriberNumber === undefined || device.subscriberNumber === '' || deviceRow.isSubscriberNumberEnabled === false))
			{
				this.requestDevicesService.getAvailableSerialNumbers(this.requestId, [device.serialNumber], [], false).pipe(map(viewModels => {
					return viewModels;
				})).subscribe(res => {
					if (!!res && !!res.data) {
						let result = res.data.availableSerialNumbers.find(s => s.serialNumber === device.serialNumber) 
						if (!!result) {
							device.cryptoKey = result.cryptoKey;
							device.subscriberNumber = result.subscriberNumber;
						}
					}
				});
			}
	}
	
	private updateErrorRows(deviceRow: DeviceRow) {
		deviceRow.isError = deviceRow.device.selectedNomenclature === null || deviceRow.device.selectedNomenclature === undefined
			|| deviceRow.device.deviceTypeId === null || deviceRow.device.deviceTypeId === undefined
			|| deviceRow.device.deviceSupplierId === null || deviceRow.device.deviceSupplierId === undefined
			|| deviceRow.device.warehouseAreaId === null || deviceRow.device.warehouseAreaId === undefined
			|| deviceRow.device.warehouseCellId === null || deviceRow.device.warehouseCellId === undefined
			|| deviceRow.device.deviceConditionId === null || deviceRow.device.deviceConditionId === undefined;
	}

	cancel() {
		this.dialog.close();
	}

	getDeviceRowLabel(device: NewRequestDevice): string {
		let deviceSupplier = device.deviceSupplierName === null || device.deviceSupplierName === undefined || device.deviceSupplierName === ''
			? 'Н/Д'
			: device.deviceSupplierName;

		let deviceType = device.deviceTypeName === null || device.deviceTypeName === undefined || device.deviceTypeName === ''
			? 'Н/Д'
			: device.deviceTypeName;

		let deviceSerialNumber = device.isNotDefinedSn === true
			? 'C/Н не идентифицирован'
			: device.serialNumber === null || device.serialNumber === undefined || device.serialNumber === ''
				? 'Н/Д'
				: device.serialNumber;

		return device.deviceKindId === <number>DeviceKindEnum.component
			? `${deviceSupplier} ${deviceType}`
			: `${deviceSupplier} ${deviceType} - ${deviceSerialNumber}`;
	}

	prevDevice() {
		this.upsertDevice(this.selectedDeviceRow, false);
	}

	nextDevice() {
		this.upsertDevice(this.selectedDeviceRow, true);
	}

	upsertDevice(deviceRow: DeviceRow, isNext: boolean) {
		let device = deviceRow.device;

		if (deviceRow.isSerialNumberEnabled === false)
			device.serialNumber = null;

		if (deviceRow.isIsNotDefinedSnEnabled === false)
			device.isNotDefinedSn = null;

		if (deviceRow.isDeviceConnectionTypeEnabled === false)
			device.deviceConnectionTypeId = null;

		if (deviceRow.isHasCryptoKeyAndCompleteKitEnabled === false) {
			device.hasCryptoKey = null;
			device.completeKit = null;
		}

		if (deviceRow.isCryptoKeyEnabled === false)
			device.cryptoKey = null;

		if (deviceRow.isSubscriberNumberEnabled === false)
			device.subscriberNumber = null;
		else if (!!device.subscriberNumber && device.subscriberNumber !== '')
			device.subscriberNumber = '7' + device.subscriberNumber;

		this.saveData = true;
		if (this.deviceForm.valid) {
			let inc = isNext ? 1 : -1;

			if (this.deviceForm.pristine) {
				this.deviceForm.control.markAsPristine();
				let index = this.deviceRows.indexOf(this.selectedDeviceRow);
				this.deviceRows[index].isSelected = false;
				this.deviceRows[index + inc].isSelected = true;
				this.selectedDeviceRow = this.deviceRows[index + inc];
				this.saveData = false;
				return;
			}

			device.deviceModelId = device.deviceModelId === 0 ? null : device.deviceModelId;
			device.deviceTypeId = device.deviceTypeId === 0 ? null : device.deviceTypeId;

			if (!!device.selectedNomenclature && device.selectedNomenclature.nomenclature !== null) {
				device.quality = device.selectedNomenclature.deviceQuality;
				device.nomenclatureWarehouseIntegraCode = device.selectedNomenclature.warehouseIntegraCode;
				device.nomenclature = device.selectedNomenclature.nomenclature;
			} else if (!!!device.selectedNomenclature || device.selectedNomenclature.nomenclature === null) {
				device.quality = null;
				device.nomenclatureWarehouseIntegraCode = null;
				device.nomenclature = null;
			}

			const observable = !!device.requestDeviceId
			? this.requestUninstallDevicesService.update(device, 'Устройство обновлено')
			: this.requestUninstallDevicesService.create(device);

			observable.pipe(finalize(() => {}))
				.subscribe(x => {
					if (x.isSuccessful) {
						this.saveData = false;
						setTimeout(() => {
							this.deviceForm.control.markAsPristine();
							let index = this.deviceRows.indexOf(this.selectedDeviceRow);
							this.deviceRows[index].isSelected = false;
							this.deviceRows[index + inc].isSelected = true;
							this.selectedDeviceRow = this.deviceRows[index + inc];

							if (!device.requestDeviceId) {
								device.requestDeviceId = x.data;
							}
						});
					}
				});
		} else {
			this.notificationService.warning({
				title: 'Валидация',
				message: 'Не все обязательные поля заполнены. Пожалуйста, заполните для продолжения.',
				notificationType: NotificationType.SweetAlert
			});
			this.saveData = false;
		}
	}

	addNewDevice() {
		let newDeviceRow = new DeviceRow;
		newDeviceRow.device.hasCryptoKey = this.needSecurityKeyForReserve;
		newDeviceRow.device.completeKit = true;
		newDeviceRow.device.requestId = this.requestId;
		newDeviceRow.device.deviceStatusId = <number>DeviceStatus.receivedAtWarehouse;
		newDeviceRow.device.deviceConditions = this.deviceConditions.filter(x => x.deviceConditionTypeId == <number>DeviceConditionEnum.used).map(x => new KeyValueObject(x.deviceConditionId, x.name));
		this.deviceRows.push(newDeviceRow);

		if (!!this.selectedDeviceRow) {
			let index = this.deviceRows.indexOf(this.selectedDeviceRow);
			if ((index === this.deviceRows.length - 2 && this.selectedDeviceRow.isError === false && this.deviceForm.pristine) || this.deviceRows.length === 1)  {
				this.selectedDeviceRow = this.deviceRows[this.deviceRows.length - 1];
				this.selectedDeviceRow.isSelected = true;
	
				if (this.deviceRows.length !== 1)
					this.deviceRows[index].isSelected = false;
			}
		} else {
			this.selectedDeviceRow = this.deviceRows[0];
			this.selectedDeviceRow.isSelected = true;
		}

		this.deviceRows.forEach(deviceRow => {
			this.getNomenclatures(deviceRow);
			this.updateErrorRows(deviceRow);
		});

		setTimeout(() => {
			Object.keys(this.deviceForm.controls).forEach(field => {
				this.deviceForm.controls[field].markAsTouched();
				this.deviceForm.controls[field].valueChanges
					.subscribe(_ => setTimeout(() => this.updateErrorRows(this.selectedDeviceRow)));
			});
		}, 1000);
	}

	deleteDevice(deviceRow: DeviceRow) {
		this.notificationService.confirmation({
			title: 'Удаление',
			message: 'Вы уверены, что хотите удалить эту строку?',
			type: 'question',
			confirmButtonText: 'Да, удалить',
			cancelButtonText: 'Нет, оставить',
			showCloseButton: false,
		}, () => {
			this.saveData = true;
			let device = deviceRow.device;
			if (device.requestDeviceId === null || device.requestDeviceId === undefined) {
				let index = this.deviceRows.indexOf(deviceRow);
				if (index === this.deviceRows.indexOf(this.selectedDeviceRow)) {
					if (index + 1 !== this.deviceRows.length && this.deviceRows.length !== 1) {
						this.deviceRows[index + 1].isSelected = true;
						this.selectedDeviceRow = this.deviceRows[index + 1];
					} else {
						if (this.deviceRows.length === 1) {
							this.selectedDeviceRow = null;
						} else {
							this.deviceRows[index - 1].isSelected = true;
							this.selectedDeviceRow = this.deviceRows[index - 1];
						}
					}
				}
				this.deviceRows = this.deviceRows.filter(x => x.device !== device);
				this.saveData = false;
				setTimeout(() => this.deviceForm.control.markAsPristine());
			} else {
				this.requestUninstallDevicesService.remove(`${device.requestDeviceId}`, 'Устройство удалено из заявки', '')
					.subscribe(x => {
						if (x.isSuccessful) {
							let index = this.deviceRows.indexOf(deviceRow);
							if (index === this.deviceRows.indexOf(this.selectedDeviceRow)) {
								if (index + 1 !== this.deviceRows.length && this.deviceRows.length !== 1) {
									this.deviceRows[index + 1].isSelected = true;
									this.selectedDeviceRow = this.deviceRows[index + 1];
								} else {
									if (this.deviceRows.length === 1) {
										this.selectedDeviceRow = null;
									} else {
										this.deviceRows[index - 1].isSelected = true;
										this.selectedDeviceRow = this.deviceRows[index - 1];
									}
								}
							}
							this.deviceRows = this.deviceRows.filter(x => x.device.requestDeviceId !== device.requestDeviceId);
							this.saveData = false;
							setTimeout(() => this.deviceForm.control.markAsPristine());
						}
						this.saveData = false;
					});
			}
		}, () => {});
	}

	get isPrevDeviceDisabled(): boolean {
		if (this.deviceRows.length === 0)
			return true;
		let index = this.deviceRows.indexOf(this.selectedDeviceRow);
		return index === 0;
	}

	get isNextDeviceDisabled(): boolean {
		if (this.deviceRows.length === 0)
			return true;
		let index = this.deviceRows.indexOf(this.selectedDeviceRow);
		return index === this.deviceRows.length - 1;
	}

	warehouseChange(event: Event) {
		this.deviceRows.forEach(x => x.device.warehouseId = this.selectedWarehouse.id);

		this.lookupService.getData('warehouse-areas', {
			filter: { logic: "and", filters: [{ operator: "eq", field: "WarehouseId", value: this.selectedWarehouse.id }] }
		}).subscribe(data => {
			this.warehouseAreas = data;

			this.deviceRows.forEach(deviceRow => {
				if (!!deviceRow.device.warehouseAreaId) {
					this.lookupService.getData('warehouse-cells', {
						filter: { logic: "and", filters: [{ operator: "eq", field: "WarehouseAreaId", value: deviceRow.device.warehouseAreaId },
							{ operator: "eq", field: "WarehouseCellTypeId", value: 2 }]
						}
					}).subscribe(data => deviceRow.device.warehouseCells = data);
				}
			});
		});
	}

	serialNumberChange(event: Event) {
		this.getCryptoKeysAndSubscriberNumbersForSingleDevice(this.selectedDeviceRow);
	}

	isNotDefinedSnChange(isNotDefinedSn: any) {
		this.selectedDeviceRow.isSerialNumberEnabled = !isNotDefinedSn.target.checked
			&& this.selectedDeviceRow.device.deviceKindId !== <number>DeviceKindEnum.component;
	}

	// изменение "Тип устройства"
	deviceTypeChange(deviceTypeId: number) {
		let deviceType = this.deviceTypes.find(x => x.id === deviceTypeId);
		this.selectedDeviceRow.device.deviceKindId = deviceType.data;

		let isNotComponent = deviceType.data !== <number>DeviceKindEnum.component;
		this.selectedDeviceRow.isSerialNumberEnabled = (this.selectedDeviceRow.device.isNotDefinedSn === null
			|| this.selectedDeviceRow.device.isNotDefinedSn === undefined
			|| this.selectedDeviceRow.device.isNotDefinedSn === false)
			&& isNotComponent;

		this.selectedDeviceRow.isIsNotDefinedSnEnabled = isNotComponent;
		this.selectedDeviceRow.isDeviceConnectionTypeEnabled = isNotComponent;
		this.selectedDeviceRow.isHasCryptoKeyAndCompleteKitEnabled = isNotComponent;

		let ingenicoSupplierId = !!this.deviceSuppliers.find(x => x.name === 'Ingenico') ? this.deviceSuppliers.find(x => x.name === 'Ingenico').id : null;
		this.selectedDeviceRow.isCryptoKeyEnabled = (deviceTypeId === <number>DeviceTypeEnum.terminal
			|| deviceTypeId === <number>DeviceTypeEnum.mobileTerminal
			|| deviceTypeId === <number>DeviceTypeEnum.intelligentPinPad)
			&& this.selectedDeviceRow.device.deviceSupplierId === ingenicoSupplierId
			&& this.contragentId === <number>CustomerEnum.rtk;

		this.selectedDeviceRow.isSubscriberNumberEnabled = deviceTypeId === <number>DeviceTypeEnum.simCard
			&& this.contragentId === <number>CustomerEnum.rtk;

		let type = this.deviceTypes.find(x => x.id === deviceTypeId);
		this.selectedDeviceRow.device.deviceTypeName = type.name;

		this.getNomenclatures(this.selectedDeviceRow);
	}

	// изменение "Тип соединения устройства"
	deviceConnectionTypeChange(event: Event) {
		this.getNomenclatures(this.selectedDeviceRow);
	}

	// изменение "Производитель устройства"
	deviceSupplierChange(deviceSupplierId: number) {
		this.selectedDeviceRow.deviceModels = this.deviceModels.filter(x => x.parentId === deviceSupplierId).map(x => new KeyValueObject(x.id, x.name));
		if (!this.selectedDeviceRow.deviceModels.map(x => x.id).includes(this.selectedDeviceRow.device.deviceModelId))
				this.selectedDeviceRow.device.deviceModelId = this.selectedDeviceRow.deviceModels.length === 1
					? this.selectedDeviceRow.deviceModels[0].id
					: null;

		let ingenicoSupplierId = !!this.deviceSuppliers.find(x => x.name === 'Ingenico') ? this.deviceSuppliers.find(x => x.name === 'Ingenico').id : null;
		this.selectedDeviceRow.isCryptoKeyEnabled = (this.selectedDeviceRow.device.deviceTypeId === <number>DeviceTypeEnum.terminal
			|| this.selectedDeviceRow.device.deviceTypeId === <number>DeviceTypeEnum.mobileTerminal
			|| this.selectedDeviceRow.device.deviceTypeId === <number>DeviceTypeEnum.intelligentPinPad)
			&& deviceSupplierId === ingenicoSupplierId
			&& this.contragentId === <number>CustomerEnum.rtk;

		let supplier = this.deviceSuppliers.find(x => x.id === deviceSupplierId);
		this.selectedDeviceRow.device.deviceSupplierName = supplier.name;

		this.getNomenclatures(this.selectedDeviceRow);
	}

	// изменение "Модель устройства"
	deviceModelChange(deviceModelId: number) {
		let model = this.deviceModels.find(x => x.id === deviceModelId);
		this.selectedDeviceRow.device.deviceModelName = model.name;

		this.getNomenclatures(this.selectedDeviceRow);
	}

	deviceNomenclatureChange(event: Event) {
		this.selectedDeviceRow.device.nomenclature = this.selectedDeviceRow.device.selectedNomenclature.nomenclature;
	}

	warehouseAreaChange(warehouseAreaId: number) {
		this.lookupService.getData('warehouse-cells', {
			filter: { logic: "and", filters: [{ operator: "eq", field: "WarehouseAreaId", value: warehouseAreaId },
				{ operator: "eq", field: "WarehouseCellTypeId", value: 2 }]
			}
		}).subscribe(data => {
			this.selectedDeviceRow.device.warehouseCells = data;
			if (!this.selectedDeviceRow.device.warehouseCells.map(x => x.id).includes(this.selectedDeviceRow.device.warehouseCellId))
				this.selectedDeviceRow.device.warehouseCellId = this.selectedDeviceRow.device.warehouseCells.length === 1
					? this.selectedDeviceRow.device.warehouseCells[0].id
					: null;
		});
	}

	isDeviceBrokenChange(isDeviceBroken: any) {
		this.selectedDeviceRow.device.brokenDescription = null;
		this.selectedDeviceRow.device.deviceConditions = isDeviceBroken.target.checked
			? this.deviceConditions.filter(x => x.deviceConditionTypeId == <number>DeviceConditionEnum.broken).map(x => new KeyValueObject(x.deviceConditionId, x.name))
			: this.deviceConditions.filter(x => x.deviceConditionTypeId == <number>DeviceConditionEnum.used).map(x => new KeyValueObject(x.deviceConditionId, x.name));

		if (!this.selectedDeviceRow.device.deviceConditions.map(x => x.id).includes(this.selectedDeviceRow.device.deviceConditionId))
			this.selectedDeviceRow.device.deviceConditionId = this.selectedDeviceRow.device.deviceConditions.length === 1
				? this.selectedDeviceRow.device.deviceConditions[0].id
				: null;
	}

	invalidNomenclatureBySn(device: NewRequestDevice): boolean {
		return !!device.serialNumber && (!device.selectedNomenclature || !!!device.selectedNomenclature.nomenclature) && !!!device.nomenclature;
	}

	saveChanges() {
		this.saveData = true;
		if (this.deviceRows.length !== 0)
		{
			if (this.deviceForm.valid) {
				if (this.deviceForm.pristine) {
					if (!this.deviceRows.some(x => x.isError === true)) {
						if (!!this.onContinueEvent) this.onContinueEvent.emit();
						this.dialog.close();
					}
					else {
						this.saveData = false;
						this.notificationService.warning({
							title: 'Валидация',
							message: 'Не у всех строк заполнены обязательные атрибуты. Пожалуйста, проверьте данные для продолжения.',
							notificationType: NotificationType.SweetAlert
						});
					}
				} else {
					let device = this.selectedDeviceRow.device;

					if (this.selectedDeviceRow.isSerialNumberEnabled === false)
						device.serialNumber = null;
		
					if (this.selectedDeviceRow.isIsNotDefinedSnEnabled === false)
						device.isNotDefinedSn = null;
					
					if (this.selectedDeviceRow.isDeviceConnectionTypeEnabled === false)
						device.deviceConnectionTypeId = null;
					
					if (this.selectedDeviceRow.isHasCryptoKeyAndCompleteKitEnabled === false) {
						device.hasCryptoKey = null;
						device.completeKit = null;
					}
				
					if (this.selectedDeviceRow.isCryptoKeyEnabled === false)
						device.cryptoKey = null;
				
					if (this.selectedDeviceRow.isSubscriberNumberEnabled === false)
						device.subscriberNumber = null;
					else if (!!device.subscriberNumber && device.subscriberNumber !== '')
						device.subscriberNumber = '7' + device.subscriberNumber;

					device.deviceModelId = device.deviceModelId === 0 ? null : device.deviceModelId;
					device.deviceTypeId = device.deviceTypeId === 0 ? null : device.deviceTypeId;
		
					if (!!device.selectedNomenclature && device.selectedNomenclature.nomenclature !== null) {
						device.quality = device.selectedNomenclature.deviceQuality;
						device.nomenclatureWarehouseIntegraCode = device.selectedNomenclature.warehouseIntegraCode;
						device.nomenclature = device.selectedNomenclature.nomenclature;
					} else if (!!!device.selectedNomenclature || device.selectedNomenclature.nomenclature === null) {
						device.quality = null;
						device.nomenclatureWarehouseIntegraCode = null;
						device.nomenclature = null;
					}
		
					const observable = !!device.requestDeviceId
					? this.requestUninstallDevicesService.update(device, 'Устройство обновлено')
					: this.requestUninstallDevicesService.create(device);
		
					observable.pipe(finalize(() => {}))
						.subscribe(x => {
							if (x.isSuccessful) {
								if (!this.deviceRows.some(x => x.isError === true)) {
									if (!!this.onContinueEvent) this.onContinueEvent.emit();
									this.dialog.close();
								} else {
									this.saveData = false;
									this.notificationService.warning({
										title: 'Валидация',
										message: 'Не у всех строк заполнены обязательные атрибуты. Пожалуйста, проверьте данные для продолжения.',
										notificationType: NotificationType.SweetAlert
									});
								}
							}
						});
				}
			} else {
				this.saveData = false;
				this.notificationService.warning({
					title: 'Валидация',
					message: 'Не все обязательные поля заполнены. Пожалуйста, заполните для продолжения.',
					notificationType: NotificationType.SweetAlert
				});
			}
		} else {
			if (!!this.onContinueEvent) this.onContinueEvent.emit();
			this.dialog.close();
		}
	}

	public handleConnectionTypeFilter(value: any) {
		let filtered = this.deviceConnectionTypes.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
		this.deviceConnectionTypesGroupedDropdown = groupBy(filtered, [{field: 'groupName'}]);	
	}
}
