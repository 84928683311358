import { Component, Input, OnInit } from '@angular/core';
import { SecurityService } from '../../../core/services/security.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActionDataResult } from '../../../shared/models/core/ActionDataResult';
import { RequestsService } from '../../../shared/services/requests.service';
import { Instruction } from './classes/instruction';
import { Filter } from './classes/filter.instruction';
import { Request } from '../../../shared/models/request/request';
import { NewRequestDevice } from '../../../shared/models/request/new-request/new-request-device';
import { tap } from 'rxjs/operators';
import { RequestType } from '../../../shared/enums';
import { forkJoin } from 'rxjs';
import { AppService } from '../../../app.service';
import { DevicesService } from '../../../shared/services/devices.service';
import { environment } from '../../../../environments/environment';
import { ContragentsService } from '../../../categories/contragents/contragents.service';
import { DataResult, process, State } from '@progress/kendo-data-query';


/** Компонент для отображения инструкций */
@Component({
	selector: 'instructionslist',
	templateUrl: './instructionlist.component.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./instructionlist.component.scss'
	]
})
export class InstructionsListComponent implements OnInit {

	@Input()
	customerCode: number;
	@Input()
	request: Request
	@Input()
	onlyPreview: boolean = false

	devices: NewRequestDevice[]

	constructor(private http: HttpClient,
		private securityService: SecurityService,
		private requestsService: RequestsService,
		protected appService: AppService,
		private devicesService: DevicesService,
		private contragentsService: ContragentsService) { }

	public instruction: Instruction;
	public instructionDataResult: DataResult;
	public actionDataResult: any;
	public loading = false;

	public pagerState: State = {
		skip: 0,
		take: 6
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	public instructionsChangeState(state: State): void {
		this.pagerState = state;
		this.instructionDataResult = process(this.instruction.elementsFound, state);
	}

	getInstructions(): void {
		if (this.request.requestTypeId === RequestType.uninstall || this.request.requestTypeId === RequestType.uninstallOld) {
			this.requestsService.getRequestUninstallDevices(this.request.requestId).subscribe(requestUninstallDevices => this.receiveInstructions(requestUninstallDevices));
		}
		else if (this.request.requestTypeId === RequestType.changeConfig) {
			forkJoin(
				this.requestsService.getRequestUninstallDevices(this.request.requestId),
				this.requestsService.getRequestDevices(this.request.requestId))
			.subscribe(([requestUninstallDevices, requestDevices]) => {
				this.receiveInstructions(requestUninstallDevices.concat(requestDevices));
			});
		} else {
			this.requestsService.getRequestDevices(this.request.requestId).subscribe(requestDevices => this.receiveInstructions(requestDevices));
		}
	}

	public ngOnInit(): void {
		this.loading = true;
		this.getInstructions();
	}

	private clearUndefinedFromArray(data: number[]): number[] {
		data = data.filter(function (element) {
			return element !== undefined;
		});

		return data;
	}

	private receiveInstructions(items: NewRequestDevice[]) {
		var apiUrl = `${environment.instructionsAddress}v1/instructions/get-by-params`;
		
		var filters = items.map(device => {
			if (device.requestForInstruction === undefined || device.requestForInstruction) {
				return new Filter(
					this.customerCode,
					this.request.requestTypeId,
					device.deviceTypeId,
					device.deviceSupplierId,
					device.deviceModelId
				);
			}
		}).filter(f => !!f);

		if (!filters.length) {
			filters = [{
				customerCode: this.customerCode,
				requestType: this.request.requestTypeId,
				deviceType: null,
				deviceModel: null,
				deviceVendor: null
			}]
		}

		this.devices = items;
		var httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.securityService.currentUser.bearerToken}`,
			})
		};

		var entity = { filters, onlyPreview: this.onlyPreview };
		this.http.post<ActionDataResult<Instruction>> (apiUrl, entity, httpOptions)
			.pipe(tap(res => { }, error => { this.loading = false })).subscribe(res => {
				if (res.isSuccessful) {
					this.actionDataResult = res;
					this.instruction = res.data;
					this.instructionDataResult = process(this.instruction.elementsFound, this.pagerState);

					if (this.instruction.elementsNotFound.length > 0) {
						let deviceModelIds = this.instruction.elementsNotFound
							.map(m => m.deviceModel);

						deviceModelIds = this.clearUndefinedFromArray(deviceModelIds);

						let deviceTypeIds = this.instruction.elementsNotFound
							.map(m => m.deviceType);

						deviceTypeIds = this.clearUndefinedFromArray(deviceTypeIds);

						let deviceVendorIds = this.instruction.elementsNotFound
							.map(m => m.deviceVendor);

						deviceVendorIds = this.clearUndefinedFromArray(deviceVendorIds);

						let requestTypeIds = this.instruction.elementsNotFound
							.map(m => m.requestType);

						requestTypeIds = this.clearUndefinedFromArray(requestTypeIds);

						let contragentIds = this.instruction.elementsNotFound
							.map(m => m.customerCode);

						contragentIds = this.clearUndefinedFromArray(contragentIds);


						forkJoin([
							this.devicesService.getDeviceModelNames(deviceModelIds),
							this.devicesService.getDeviceTypeNames(deviceTypeIds),
							this.devicesService.getDeviceVendorNames(deviceVendorIds),
							this.requestsService.getRequestTypeNames(requestTypeIds),
							this.contragentsService.getContragentNames(contragentIds),
						]).subscribe(results => {
							let deviceModels = results[0].data;
							let deviceTypes = results[1].data;
							let deviceVendors = results[2].data;
							let requestTypes = results[3].data;
							let customerCodes = results[4].data;

							this.instruction.elementsNotFound.forEach(f => {
								let deviceModel = deviceModels.find(fi => fi.id == f.deviceModel);
								let deviceType = deviceTypes.find(fi => fi.id == f.deviceType);
								let deviceVendor = deviceVendors.find(fi => fi.id == f.deviceVendor);
								let requestType = requestTypes.find(fi => fi.id == f.requestType);
								let customerCode = customerCodes.find(fi => fi.id == f.customerCode);

								f.deviceModelName = !!deviceModel ? deviceModel.name : null;
								f.deviceTypeName = !!deviceType ? deviceType.name : null;
								f.deviceVendorName = !!deviceVendor ? deviceVendor.name : null;
								f.requestTypeName = !!requestType ? requestType.name : null;
								f.customerCodeName = !!customerCode ? customerCode.name : null;
							});
						});
					}
				}
				this.loading = false;
			});
	};
	
}





