import { BaseObject } from '../core/BaseObject';

export class Merchant extends BaseObject {
	merchantId: number;
	mid: string;
	name: string;
	contragentId: number;
	contragentName: string;
	contragentAddress: string;
	acquirerId: number;
	acquirerName: string;
	clientId: number;
	clientName: string;
	legalAddress: string;
	clientAgreementId: number;
	clientAgreementNumber: string;
	inn: string;
	customerObjects: Merchant.customerObject[];
	customerObjectAddress: string;
}

export namespace Merchant {
	export class customerObject {
		id: number;
		name: string;
		address: string;
		tidNumber: string;
	}
}
