export enum DeviceConditionEnum {
    /** Новый */
    new = 1,

    /** Неисправно */
    broken = 2,

    /** Б/у */
    used = 3,

    /** Экспертиза */
    expertise = 4
}