import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { UploadFile } from "../../../../models/attachment";
import { FileRestrictions, UploadEvent } from "@progress/kendo-angular-upload";
import { DialogService } from "@progress/kendo-angular-dialog";
import { NotificationService } from "../../../../../core/services/notification.service";
import { KeyValueObject } from "../../../../models/core/KeyValueObject";
import { AttachmentsService } from "../../../../services/attachments.service";
import { AppService } from "../../../../../app.service";
import { DOC_ORIENTATION, NgxImageCompressService } from "ngx-image-compress";
import { ImagePopup } from "../../../image-popup/image-popup.component";
import { NotificationType } from "../../../../../core/services/notification-type";
import { UploadFileData } from "../../../../models/attachment/upload-file-data";

@Component({
	selector: 'complete-activity-image-uploader',
	templateUrl: './complete-activity-image-uploader.component.html',
	styleUrls: [ './complete-activity-image-uploader.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CompleteActivityImageUploader {
	canUpload = false;

	@Input() showUploadButton = false;
	@Input() uploading = false;
	@Input() allowedExtensions: string[] = [];
	@Input() uploadPercentages?: number;
	@Input() selectName?: string = null;
	@Output() isCompressingEvent : EventEmitter<boolean> = new EventEmitter();

	@Input() uploadFiles: UploadFileData[] = [];

	@Output() onUploadFilesAdded: EventEmitter<UploadFile[]> = new EventEmitter<UploadFile[]>();
    @Output() onFileRemoved: EventEmitter<UploadFile> = new EventEmitter<UploadFile>();

	public extensionRestrictions: FileRestrictions = {};
	
	imageExtensions: string[] = ['.png', '.jpg', '.jpeg'];
	pngExtension: string = '.png';

	qualityCompression = 50;
	resolutionCompression = 50;

	fileSizeLimitKBytes: number = 2000;
	compressInProgressFileIds: string[] = [];
	attachmentTypes: KeyValueObject[] = [];

	constructor(
		private imageCompress: NgxImageCompressService,
		protected dialogService: DialogService,
		protected notificationService: NotificationService,
		private appService: AppService,
		private attachmentsService: AttachmentsService
	) {
	}

	ngOnInit(): void {	
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.allowedExtensions && changes.allowedExtensions.currentValue) {
			this.extensionRestrictions.allowedExtensions = changes.allowedExtensions.currentValue;
		}
	}

	get getSelectName(): string {
		if (this.selectName === null) {
			return "Добавить";
		} else {
			return this.selectName;
		}
	}

	get getLoadName(): string {
		if(this.isCompressing){
			return "Сжатие...";
		}
			return "Загрузить";
	}

	openPreview(file: UploadFile) {

		if (file.fileSize > this.fileSizeLimitKBytes * 1024)
			return;

		const dialogRef = this.dialogService.open({ content: ImagePopup, width: this.appService.isMobileDevice ? '90%' : '90%', height: '90%' });

		const imagePopup = <ImagePopup>dialogRef.content.instance;
        imagePopup.showRemoveButton = true;
        imagePopup.showFileInfo = true;
        imagePopup.fileName = file.fileName;
        imagePopup.fileSize = file.fileSize / 1024;

        imagePopup.showAsIcon = !this.isImageExtension(file.fileExtension);
        imagePopup.iconClass = this.getByFileImageClassByExtension(file.fileExtension);

		imagePopup.src = this.getImageUrl(file);

        imagePopup.removeEvent.subscribe(() => {
			this.uploadFiles = this.uploadFiles.filter(x => x.uid != file.uid);
			this.onFileRemoved.emit(file);
        });
	}

	getImageUrl(file: UploadFile) {
		return `data:image/png;base64,${file.fileData}`
	}

	async uploadEventHandler(e: UploadEvent, uploader) {

		let filesToCompress = [];
		let promises = [];
		const files = e.files;

		files.forEach(file => {
            
			if (file) {
				promises.push(new Promise((resolve, reject) => {
					try{
						const reader = new FileReader();
						const self = this;
						reader.onloadend = function (readerEvt: any) {
							const binaryString = <string>readerEvt.target.result;
	
							const uploadFile = new UploadFileData();
							uploadFile.uid = file.uid;
							uploadFile.fileName = file.name;
							uploadFile.fileExtension = file.extension;
							uploadFile.fileData = btoa(binaryString);
							uploadFile.fileSize = file.size;
	
							self.uploadFiles.push(uploadFile);
	
							if ((uploadFile.fileSize > self.fileSizeLimitKBytes * 1024) && self.isImageExtension(uploadFile.fileExtension)){
								filesToCompress.push(uploadFile);
							}
	
							resolve(reader.result);
						};
						
						reader.readAsBinaryString(file.rawFile);
					}
					catch(ex){
						this.notificationService.warning({
							title: 'Ошибка при добавлении файла',
							message: `Ошибка при добавлении файла ${file.name}`,
							notificationType: NotificationType.SweetAlert,
						});
						reject();
					}
				}));				
			}
		});	

		this.customAllSettled(promises).then((results) => {
			if (!this.showUploadButton && filesToCompress.length == 0) {
				this.onUploadFilesAdded.emit(this.uploadFiles);
			}

			this.compressFiles(uploader, filesToCompress);
		  });
	}

	customAllSettled(promises: Promise<any>[]): Promise<any[]> {
		return Promise.all(promises.map(p => 
		  p.then(
			value => ({ status: 'fulfilled', value }),
			reason => ({ status: 'rejected', reason })
		  )
		));
	  }

	removeEventHandler(event: any): void {
		this.uploadFiles = this.uploadFiles.filter(x => x.uid !== event.files[0].uid);
	}

	get isCompressing(): boolean {
		let compressing = this.compressInProgressFileIds.length > 0;
		return compressing;
	}
	
	upload(uploader: any) {
		this.onUploadFilesAdded.emit(this.uploadFiles);
		uploader.clearFiles();
		this.uploadFiles = [];
	}

	successUploadedEventHandler(e: any) {
		this.canUpload = true;
	}

	public removeFile(uploader, uid: string) {
		this.uploadFiles = this.uploadFiles.filter(x => x.uid !== uid);

		if (!this.showUploadButton) {
			this.onUploadFilesAdded.emit(this.uploadFiles);
		}
	}

	isImageExtension(extension: string): boolean {

		if (extension == null)
			return false;

		extension = extension.toLowerCase();

		return this.imageExtensions.some(ext => extension === ext);
	}

	getByFileImageClassByExtension(extension: string): string {

		var imageClass = "fa-file";

		switch (extension) {
			case ".xls":
			case ".xlsx":
				imageClass = "fa-file-excel";
				break;
			case ".doc":
			case ".docx":
				imageClass = "fa-file-word";
				break;
			case ".pdf":
				imageClass = "fa-file-pdf";
				break;
			case ".zip":
			case ".rar":
				imageClass = "fa-file-archive";
				break;
			case ".ppt":
			case ".pptx":
				imageClass = "fa-file-powerpoint";
				break;
		}

		return imageClass;
	}

	compressFiles(uploader, files: UploadFile[]){
		if (files.length == 0)
			return;

		this.isCompressingEvent.emit(true);

		files.forEach(fileToCompress => {
			this.compressInProgressFileIds.push(fileToCompress.uid);
			if (this.isMobileDevice){
				this.compressLocally(uploader, fileToCompress);
			} else {
				this.compressOnServer(uploader, fileToCompress);
			}
		})
	}

	compressOnServer(uploader, file: UploadFile){	
		this.attachmentsService.compressAttachment(file.fileName, file.fileData).subscribe(x => {
			file.fileName = x.data.fileName;
			file.fileData = x.data.fileData;
			file.fileSize = x.data.fileSizeBytes;

			const index = this.compressInProgressFileIds.indexOf(file.uid, 0);
			if (index > -1) {
				this.compressInProgressFileIds.splice(index, 1);
			}

			if (this.compressInProgressFileIds.length == 0){
				this.isCompressingEvent.emit(false);
			}

			if (file.fileSize > this.fileSizeLimitKBytes * 1024){

				this.notificationService.warning({
					title: 'Сжатие',
					message: `Размер файла ${file.fileName} после сжатия ${(file.fileSize / 1024).toFixed(2)} Кб, что не укладывается в максимально допустимый размер ${this.fileSizeLimitKBytes} Кб. Файл будет удален из очереди загрузки`,
					notificationType: NotificationType.SweetAlert,
				});

				this.removeFile(uploader, file.uid);
			}

			if (!this.showUploadButton && this.compressInProgressFileIds.length == 0) {
				this.onUploadFilesAdded.emit(this.uploadFiles);
			}
		});

	}

	compressLocally(uploader, file: UploadFile){
		let docType: DOC_ORIENTATION;
		let imageToCompress: string;

		if (this.isPng(file.fileExtension)){
			imageToCompress = `data:image/png;base64,${file.fileData}`;
			docType = DOC_ORIENTATION.NotDefined;
		} else {
			imageToCompress = `data:image/jpeg;base64,${file.fileData}`;
			docType = DOC_ORIENTATION.Up;
		}	

		this.imageCompress.compressFile(imageToCompress, docType, this.resolutionCompression, this.qualityCompression).then(
			result => {
				let imageeWithoutBase64 = result.replace(/^data:image\/[a-z]+;base64,/, "");

				file.fileData = imageeWithoutBase64;
				file.fileSize = this.imageCompress.byteCount(imageeWithoutBase64);

				const index = this.compressInProgressFileIds.indexOf(file.uid, 0);
				if (index > -1) {
					this.compressInProgressFileIds.splice(index, 1);
				}
				if (this.compressInProgressFileIds.length == 0){
					this.isCompressingEvent.emit(false);
				}

				if (file.fileSize > this.fileSizeLimitKBytes * 1024){

					this.notificationService.warning({
						title: 'Сжатие',
						message: `Размер файла после сжатия ${(file.fileSize / 1024).toFixed(2)} Кб, что не укладывается в максимально допустимый размер ${this.fileSizeLimitKBytes} Кб. Файл будет удален из очереди загрузки`,
						notificationType: NotificationType.SweetAlert,
					});

					this.removeFile(uploader, file.uid);
				}

				if (!this.showUploadButton && this.compressInProgressFileIds.length == 0) {
					this.onUploadFilesAdded.emit(this.uploadFiles);
				}
			}
		);
	}

	isPng(extension: string){

		if (extension == null)
			return false;

		if (extension === this.pngExtension){
			return true;
		}
		return false;
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}
}