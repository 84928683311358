import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerObject } from '../../shared/models/customerObject/CustomerObject';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { ServiceCenterLookupModel } from '../../shared/models/service-center/service-center-lookup';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { TidFullLookupModel } from '../../shared/models/tid/tid-full';

@Injectable()
export class CustomerObjectsService extends DataService<CustomerObject, CustomerObject> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'customer-objects';
	}

	public getServiceCenters(customerObjectId: number): Observable<ServiceCenterLookupModel[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${customerObjectId}/service-centers`);
	}

	public getTids(customerObjectId: number, agreementId: number = null): Observable<TidFullLookupModel[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${customerObjectId}/tids?agreementId=${agreementId}`);
	}

	public getEngineers(customerObjectId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${customerObjectId}/engineers`);
	}

}
