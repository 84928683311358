import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationService } from '../../core/services/notification.service';
import { WarehouseDto } from '../models/warehouse/warehouse-dto';
import { DataService } from '../../core/services/data.service';
import { WarehouseWithCells } from '../models/warehouses/warehouse-with-cells';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { KeyValueObject } from '../models/core/KeyValueObject';
import { map, tap } from 'rxjs/operators';
import { WarehouseListItem } from '../models/warehouse/warehouse-list-item';
import { WarehouseItem } from '../models/warehouse/warehouse-item';
import { DeliveryAddress } from '../../requests/request-components/request-delivery/classes/delivery-address';
import { MapWarehouseAreasCommand } from '../models/warehouseAreas/map-warehouse-areas-command';
import { ActionResult } from '../models/core/ActionResult';
import { NotificationType } from '../../core/services/notification-type';
import { WarehouseContact } from '../models/warehouse/warehouse-contact';
import { WarehouseRequestOptions } from '../models/warehouse/warehouse-request-options';

@Injectable()
export class WarehousesService extends DataService<WarehouseListItem, WarehouseItem> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'warehouses';
	}

	public warehousesCitiesLookup(warehousePartnerCodes: string[] = null): Observable<KeyValueObject[]> {
		if (warehousePartnerCodes) {
			return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/warehouses-cities-lookup`, { warehousePartnerCodes })
				.pipe(map<ActionDataResult<KeyValueObject[]>, KeyValueObject[]>(v => v.data));
		} else {
			return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/warehouses-cities-lookup`);
		}
	}

    getAll(): Observable<WarehouseDto[]> {
        return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-all`);
    }

	scWarehouseContacts(serviceCenterId: number, forSmz: boolean): Observable<WarehouseContact[]> {
        return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/sc-warehouse-contacts?serviceCenterId=${serviceCenterId}&forSmz=${forSmz}`);
    }

    public getAllWarehousesWithCells(): Observable<WarehouseWithCells[]> {
		return this.getQuery<WarehouseWithCells[]>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-all-with-cells`);
	}

	public getAllWarehousesWithCellsFiltered(serviceCenterId: number = null, cellTypeId: number = null, showCellsByUberAreas = true): Observable<ActionDataResult<WarehouseWithCells[]>> {
		return this.postQuery<WarehouseWithCells[]>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-all-with-cells-filtered`, { serviceCenterId: serviceCenterId, cellTypeId: cellTypeId, showCellsByUberAreas: showCellsByUberAreas });
	}

	public warehousesRegionsLookup(): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/warehouses-regions-lookup`);
	}

	public warehousesPartnerCodesLookup(cityIds: number[], regionIds: number[]): Observable<KeyValueObject[]> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/warehouses-partner-codes-lookup`, { cityIds, regionIds })
			.pipe(map<ActionDataResult<KeyValueObject[]>, KeyValueObject[]>(v => v.data));
	}

	public warehousesOwnersLookup(): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/warehouses-owners-lookup`);
	}

	public warehousesContragentsOwnersLookup(): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/warehouses-contragents-owners-lookup`);
	}

	public getAllWarehousesAddresses(): Observable<DeliveryAddress[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-all-addresses`);
	}

	public getWarehouseByAreaId(warehouseByAreaId: number): Observable<WarehouseItem> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-by-area-id/${warehouseByAreaId}`);
	}

	public getWarehouseRequestOptions(warehouseId: number): Observable<WarehouseRequestOptions> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/request-options/${warehouseId}`)
	}

	public mapWarehouseAreas(mapWarehouseAreasCommand: MapWarehouseAreasCommand): Observable<ActionResult> {
		return this.postQuery<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/map-warehouse-areas`, mapWarehouseAreasCommand)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка проверки артикула для складской заявки через 1С',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка проверки артикула для складской заявки через 1С',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}
}
