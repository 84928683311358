import { Component, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { LayoutService } from '../../../layout/layout.service';

@Component({
	selector: 'navbar-header-right-actions',
	templateUrl: './navbar-header-right-actions.component.html'
})
export class NavbarHeaderRightActionsComponent implements AfterViewInit {

	@ViewChild('navbarHeaderRightActionsTemplate') private navbarHeaderRightActionsTemplate: TemplateRef<any>;

	constructor(
		private layoutService: LayoutService
	) { }

	ngAfterViewInit(): void {
		this.layoutService.navbarHeaderRightActionsTemplate = this.navbarHeaderRightActionsTemplate;
	}
}