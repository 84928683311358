﻿import { CommentKindEnum } from "../../enums/comment-kind.enum";
import { PerformerUserGroupModel } from "./performer-user-group-model";

export class StopRequestModel extends PerformerUserGroupModel {
	stoppedUntil?: Date;
	requestStoppingReasonId?: number;

	constructor(
		requestId: number,
		newStatusId: number,
		performerUserId: number,
		performerUserGroupId: number,
		comment: string,
		stoppedUntil?: Date,
		commentKind: CommentKindEnum = CommentKindEnum.internal,
		requestStoppingReasonId?: number) {
			super(requestId, newStatusId, performerUserId, performerUserGroupId, comment, commentKind);
		this.stoppedUntil = stoppedUntil;
		this.requestStoppingReasonId = requestStoppingReasonId;
	}
}
