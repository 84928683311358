import { BaseObject } from "../core/BaseObject";

export class ServiceItem extends BaseObject {
	id: number;
	name: string;
	number: string;
	rate: string;
	serviceCode: string;
	description: string;
	requestTypeIds: number[] = [];
	requestTypeNames: string;
}
