import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from '../../../app.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { RequestFilesService } from '../../../shared/services/request-files.service';
import { RequestFile } from '../../../shared/models/attachment/request-file';
import { ImagePopup } from '../../../shared/components/image-popup/image-popup.component';
import { UploadFile } from '../../../shared/models/attachment/upload-file';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { LookupService } from '../../../shared/services/lookup.service';
import { Request } from '../../../shared/models/request/request';
import { RequestType } from '../../../shared/enums/request-type.enum';
import { AttachmentType } from '../../../shared/enums';
import { RequestPersonalData } from '../../../shared/models/request/request-personal-data';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
	selector: 'register-user-request',
	templateUrl: './register-user-request.component.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	],
	changeDetection: ChangeDetectionStrategy.Default
})
export class RegisterUserRequestComponent implements OnInit {

	@Input() request: Request = new Request();
	@Input() canEditForm: boolean;
	@Output() requestChange = new EventEmitter<Request>();
	@Output() showFileTabEvent = new EventEmitter();
	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};
	files: RequestFile[] = [];

	filesLoading: boolean = false;
	showAddFilePanel: boolean = false;
	showGrid: boolean = true;
	uploadInProgress: any = false;
	uploadFilesPercentages?: number;

	commentToAdd: string = "";
	filesToUpload: UploadFile[] = [];
	attachmentTypes: KeyValueObject[] = [];
	downloadFilesInProgress: number[] = [];

	imageExtensions: string[] = ['.png', '.jpg', '.jpeg'];

	constructor(
		private appService: AppService,
		private dialogService: DialogService,
		private RequestFilesService: RequestFilesService,
		private lookupService: LookupService
	) {
		if (this.request != undefined) {
			this.request.createNewSelfEmployedAccountCommand.deRegistrationDate = new Date(this.request.createNewSelfEmployedAccountCommand.deRegistrationDate);
			this.request.createNewSelfEmployedAccountCommand.registrationDate = new Date(this.request.createNewSelfEmployedAccountCommand.registrationDate);
			this.request.createNewSelfEmployedAccountCommand.passportDate = new Date(this.request.createNewSelfEmployedAccountCommand.passportDate);
		}
		else {
			this.request = new Request();
			this.request.createNewSelfEmployedAccountCommand = new RequestPersonalData();
			this.request.createNewSelfEmployedAccountCommand.deRegistrationDate = new Date();
			this.request.createNewSelfEmployedAccountCommand.registrationDate = new Date();
			this.request.createNewSelfEmployedAccountCommand.passportDate = new Date();
		}
	}

	ngOnInit(): void {
		this.loadFiles(this.request.requestId);
		if (this.request.createNewSelfEmployedAccountCommand == null) {
			this.request.createNewSelfEmployedAccountCommand = new RequestPersonalData();
		}
		if (this.request.createNewSelfEmployedAccountCommand.regionServiceCenter.length > 0) {
			this.request.createNewSelfEmployedAccountCommand.regionServiceCenter.forEach(sc => {
				if (this.request.createNewSelfEmployedAccountCommand.regionServiceCenterString != undefined) {
					this.request.createNewSelfEmployedAccountCommand.regionServiceCenterString = this.request.createNewSelfEmployedAccountCommand.regionServiceCenterString + sc.regionName + ";"
				}
				else {
					this.request.createNewSelfEmployedAccountCommand.regionServiceCenterString = sc.regionName + ";";
				}
			});
		}
		this.request.createNewSelfEmployedAccountCommand.deRegistrationDate = new Date(this.request.createNewSelfEmployedAccountCommand.deRegistrationDate);
		this.request.createNewSelfEmployedAccountCommand.registrationDate = new Date(this.request.createNewSelfEmployedAccountCommand.registrationDate);
		this.request.createNewSelfEmployedAccountCommand.passportDate = new Date(this.request.createNewSelfEmployedAccountCommand.passportDate);
		this.lookupService.getData("attachment-types", null).subscribe(data => this.attachmentTypes = data);
	}

	get isCreateUserRequest(): boolean {
		if (this.request.requestTypeId === <number>RequestType.registration) {
			return true;
		}
		return false;
	}

	loadFiles(requestId: number) {

		this.filesLoading = true;

		let requestFilesServiceListStateFilters = [];
		requestFilesServiceListStateFilters.push({ field: 'requestId', operator: 'eq', value: requestId });

		this.RequestFilesService.list({
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: requestFilesServiceListStateFilters }
		}).subscribe(x => {
			this.files = x.data;

			this.filesLoading = false;
		});
	}

	get selfiWithPassport(): RequestFile {
		let file = new RequestFile;
		if (this.files.find(x => x.attachmentTypeIds[0] === AttachmentType.selfieWithPassport)) {
			file = this.files.find(x => x.attachmentTypeIds[0] === AttachmentType.selfieWithPassport);
		}
		return file;
	}

	openImagePopup(imageUrl: string, file: RequestFile) {

		if (!this.isImageExtension(file.fileExtension))
			return;

		const dialogRef = this.dialogService.open({ content: ImagePopup, width: this.appService.isMobileDevice ? '90%' : '90%', height: '90%', title: file.fileName });

		const imagePopup = <ImagePopup>dialogRef.content.instance;

		imagePopup.src = imageUrl;
		imagePopup.includeAuth = true;
		imagePopup.showCloseButton = false;
	}

	showFileTab() {
		this.showFileTabEvent.emit();
	}

	getImageUrl(fileId: number): string {
		return `/api/v1/request-files/${fileId}/preview`
	}

	isImageExtension(extension: string): boolean {

		if (extension == null)
			return false;
		extension = extension.toLowerCase();
		return this.imageExtensions.some(ext => extension === ext);
	}
}
