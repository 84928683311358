import { Component, Input, OnInit } from '@angular/core';
import { RequestNotification } from "../../../models/request/request-notification";

@Component({
	selector: 'notification-button',
	templateUrl: './notification-button.component.html',
	styleUrls: ['./notification-button.scss']
})
export class NotificationButtonComponent implements OnInit {
	
	@Input()
	public data: RequestNotification;

	ngOnInit(): void {

	}
}
