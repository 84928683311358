import { ArticleDto } from "../article/article.dto";

export class ActivityChangeStatus {
    constructor(public activityId: number, 
        public newStatusId: number, 
        public comment: string = '', 
        public activityReasonId = null, 
        public componentsArticles: ArticleDto[] = [],
        public stoppedUntil?: Date,
        public requestStoppingReasonId?: number) {}
}
