import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { KeyValueObject } from '../../../../models/core/KeyValueObject';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { AppService } from '../../../../../app.service';
import { forkJoin, from, Observable, Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { concatMap, map } from 'rxjs/operators';
import { EntityViewModel } from "../../../../models/core/EntityViewModel";
import { LookupService } from '../../../../services/lookup.service';
declare var kendo: any;
@Component({
	selector: 'multiselect-lookup-filter-virtual',
	templateUrl: './multiselect-dropdown-filter-virtual.component.html',
})
export class MultiSelectLookupFilterVirtualComponent extends BaseFilterCellComponent implements OnInit, OnDestroy {

	public filterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	public virtual: any = { itemHeight: 28 };
	public emptyItem: KeyValueObject = new KeyValueObject(-1, "(не указан)");
	@Input()
	public selectId: string;

	@Input()
	public addFilters: boolean = true;

	@Input()
	public readRequestUrl: string;

	@Input()
	public filter: CompositeFilterDescriptor;

	@Input()
	public supressEmptyValue: boolean;

	@Input()
	public dataService: any;

	@Input()
	public fieldName: string;

	@Input()
	public pageSize: number | null = 50;

	@Input()
	public dataValueField: string = "id";

	@Output()
	onSelectedItem: EventEmitter<any> = new EventEmitter();

	selectedValues: number[] = [];
	multiselect: any;
	clearAllGridFilters: Subscription;
	eqMode: boolean = true;

	selectedItemsCache: KeyValueObject[] = [];

	refreshSelectedValues() {

		var existedFilters = this.filter.filters.filter(x => (<CompositeFilterDescriptor>x).filters && (<CompositeFilterDescriptor>x).filters.some(y => (<FilterDescriptor>y).field === this.fieldName));

		if (existedFilters && existedFilters.length > 0) {
			var fieldFilter = <CompositeFilterDescriptor>existedFilters[0];
			this.selectedValues = fieldFilter.filters.map(x => (<FilterDescriptor>x).value);
			this.eqMode = fieldFilter.filters.every(x => (<FilterDescriptor>x).operator == 'eq');
		} else {
			this.selectedValues = [];
		}
		if (this.multiselect != undefined) {
			this.multiselect.value(this.selectedValues);
		}
	}

	constructor(filterService: FilterService,
		protected appService: AppService,
		lookupService: LookupService
	) {
		super(filterService);
		setTimeout(() => {
			var url = `${environment.apiUrl}${this.readRequestUrl}`;
			var authUser = localStorage.getItem("authUser");
			var header;
			if (authUser) {
				header = `Bearer ${JSON.parse(authUser).bearerToken}`
			};
			var idToSelect = `#${this.selectId}`;
			this.multiselect = kendo.jQuery(idToSelect).data("kendoMultiSelect");
			var parentThis = this;

			if (this.multiselect == undefined) {
				kendo.jQuery(idToSelect).kendoMultiSelect({
					open: function (e) {
						if (!!!this.dataSource.options.transport) {
							this.setDataSource({
								transport: {
									read: {
										url: url,
										beforeSend: function (req) {
											req.setRequestHeader('Authorization', header);
										},
									}
								},
								schema: {
									type: "json",
									data: "data",
									total: "total"
								},
								pageSize: parentThis.pageSize,
								serverPaging: true,
								serverFiltering: true
							});

							// по каким-то причинам в этом месте dataSource не читается автоматически, поэтому читаем его вручную
							this.dataSource.read();
						}
					},
					placeholder: "",
					autoClose: false,
					dataTextField: "name",
					dataValueField: this.dataValueField,
					height: 300,
					virtual: {
						itemHeight: 30,
						mapValueTo: "dataItem",
						valueMapper: function (options) {

							let getState = (id: number): any => {
								return {
									skip: 0,
									take: null,
									sort: null,
									filter: {
										logic: 'and',
										filters: [{
											field: 'id',
											value: id,
											operator: 'eq'
										}]
									}
								}
							};

							let addToCache = (key: number, name: string) => {
								if (!parentThis.selectedItemsCache.map(m => m.id).includes(key)) {
									parentThis.selectedItemsCache.push(new KeyValueObject(key, name));
								}
							};

							let addFromOptions = () => {
								var skippedFirst = false;
								options.value.forEach(f => {
									if (skippedFirst) {
										if (!parentThis.selectedItemsCache.map(m => m.id).includes(f)) {
											lookupService.getDataByUrlExt(url, getState(f)).subscribe(vm2 => {
												let itemName = vm2[0].name;
												result.push({ id: f, name: itemName });
												addToCache(f, itemName);
											});
										} else {
											let itemName = parentThis.selectedItemsCache.find(fi => fi.id === f).name;
											result.push({ id: f, name: itemName });
										}
									}
									skippedFirst = true;
								});
								options.success(result);
							};

							var result = [];
							if (options.value != undefined) {
								if (!parentThis.selectedItemsCache.map(m => m.id).includes(options.value[0])) {
									lookupService.getDataByUrlExt(url, getState(options.value[0])).subscribe(vm => {
										let itemName = vm.find(x => x.id == options.value[0]).name;
									result.push({
										id: options.value[0],
											name: itemName
									});

										addToCache(options.value[0], itemName);

										addFromOptions();
								});
								} else {
									let itemName = parentThis.selectedItemsCache.find(fi => fi.id === options.value[0]).name;
									result.push({
										id: options.value[0],
										name: itemName
								});

									addFromOptions();
								}
							}
						}
					},
					filter: "contains",
					delay: 1000,
					tagMode: "single",
					tagTemplate: '<span># if (values.length < 2) { # #: dataItems[0].name #</span> # } else { # #: dataItems[0].name # и ещё несколько</span> # } #',
					autoBind: false
				});
				this.multiselect = kendo.jQuery(idToSelect).data("kendoMultiSelect");
			}
			this.multiselect.bind("change", function (e) {
				var values = this.listView.selectedDataItems().map(function (item) {
					return item.id;
				});
				this.listView._values = values;
				parentThis.onChange(values, parentThis);
			});
		}, 0);
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.refreshSelectedValues();

			this.clearAllGridFilters = this.appService.clearAllGridFilters.subscribe(() => {
				this.selectedValues = [];
				this.eqMode = true;
				if (this.multiselect != undefined) {
					this.multiselect.value(this.selectedValues);
				}
			});
		}, 10);
	}

	public onChange(ids: number[], parentThis: MultiSelectLookupFilterVirtualComponent): void {
		if (ids && ids.length > 0) {
			parentThis.selectedValues = [...ids].filter(onlyUnique);
		}
		if (!parentThis.addFilters) {
			if (parentThis.onSelectedItem) {
				parentThis.onSelectedItem.emit(ids);
			}
			return;
		}

		if (!ids || ids.length === 0) {
			parentThis.selectedValues = [];
			parentThis.applyFilter(parentThis.removeFilter(parentThis.fieldName));
		} else {

			var allFilters = parentThis.filter.filters;

			var currentFilter = parentThis.filter.filters.filter(x => (<CompositeFilterDescriptor>x).filters && (<CompositeFilterDescriptor>x).filters.some(y => (<FilterDescriptor>y).field === parentThis.fieldName));

			if (currentFilter.length > 0) {

				var fieldFilter = <CompositeFilterDescriptor>currentFilter[0];

				fieldFilter.filters = parentThis.selectedValues.map(value => parentThis.processSelectedValue(value));

				fieldFilter.logic = parentThis.eqMode ? 'or' : 'and';

			} else {
				allFilters.push({
					filters: parentThis.selectedValues.map(value => parentThis.processSelectedValue(value)),
					logic: parentThis.eqMode ? 'or' : 'and',
				});
			}
			parentThis.filterService.filter({
				filters: allFilters,
				logic: 'and'
			});

		}

		if (parentThis.onSelectedItem) {
			parentThis.onSelectedItem.emit(ids);
		}
	}

	processSelectedValue(value: number): FilterDescriptor {

		if (value === this.emptyItem.id) {
			return {
				field: this.fieldName,
				operator: this.eqMode ? 'isnull' : 'isnotnull',
				value: this.emptyItem.id
			}
		}

		return {
			field: this.fieldName,
			operator: this.eqMode ? 'eq' : 'neq',
			value
		}
	}

	changeFilterOperatorClick() {
		this.eqMode = !this.eqMode;
		this.onChange(this.selectedValues, this);
	}

	ngOnDestroy(): void {
		if (this.clearAllGridFilters) {
			this.clearAllGridFilters.unsubscribe();
		}
	}
}

function onlyUnique<T>(value: T, index: number, array: T[]): boolean {
	return array.indexOf(value) === index;
}

interface MultiSelectLookupDataService {
	getById(id: number | string): Observable<{
		[x: string]: any; entity: MultiSelectLookupDataEntity
	}>
}

interface MultiSelectLookupDataEntity {
	readonly name: string
}
