import { Component, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../../app.service';
import { GridSettings } from '../../../shared/models/grid/grid-settings';
import { Expertise } from '../../../shared/models/reports/expertise';
import { ListPageBaseLocal } from '../../../shared/components/list-page-base/list-page-base-local';

@Component({
    selector: 'expertise-report-list',
    templateUrl: './list.page.html',
    styleUrls: ['./list.page.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class ExpertiseListPage extends ListPageBaseLocal<Expertise> {

    public gridSettings: GridSettings = {
			state: {
					skip: 0,
					take: 100,
					sort: [],
					filter: { logic: 'and', filters: [] }
        },
        columnsConfig: [
        {
					orderIndex: 0,
					field: 'expertiseReason',
					title: 'Факт проведения экспертизы',
					filter: 'string',
					filterable: true,
					width: 250,
					hidden: false
        },
        {
					orderIndex: 1,
					field: 'requestExternalId',
					title: 'Номер заявки',
					filter: 'string',
					filterable: true,
					width: 250,
					hidden: false
        },
        {
					orderIndex: 2,
					field: 'createdDate',
					title: 'Дата проведения экспертизы',
					filter: 'date',
					format: 'dd.MM.yyyy HH:mm',
					filterable: true,
					width: 250,
					hidden: false
        },
        {
					orderIndex: 3,
					field: 'requestType',
					title: 'Тип заявки',
					filter: 'string',
					filterable: true,
					width: 250,
					hidden: false
        },
        {
					orderIndex: 4,
					field: 'performerFullName',
					title: 'Исполнитель',
					filter: 'string',
					filterable: true,
					width: 250,
					hidden: false
        },
        {
					orderIndex: 5,
					field: 'performerCompany',
					title: 'Компания исполнителя заявки',
					filter: 'string',
					filterable: true,
					width: 250,
					hidden: false
        },
        {
					orderIndex: 6,
					field: 'expertiseResult',
					title: 'Результат экспертизы',
					filter: 'string',
					filterable: true,
					width: 250,
					hidden: false
        },
        {
					orderIndex: 7,
					field: 'malfunctionReason',
					title: 'Причины неисправности',
					filter: 'string',
					filterable: true,
					width: 250,
					hidden: false
        },
        {
          orderIndex: 8,
          field: 'deviceType',
          title: 'Тип устройства',
          filter: 'string',
          filterable: true,
          width: 250,
          hidden: false
        },
        {
          orderIndex: 9,
          field: 'sn',
          title: 'Серийный номер',
          filter: 'string',
          filterable: true,
          width: 250,
          hidden: false
        }
      ]
    }

    constructor(appService: AppService) {
        super();

	    appService.pageTitle = 'Экспорт по проведённым экспертизам';
    }
   
    processListItems(items: Expertise[]): void {
        this.updateData(items);
    }
}
