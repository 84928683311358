import { Component, Input, OnInit } from "@angular/core";
import { FilterService } from '@progress/kendo-angular-grid';
import { MultiSelectLookupFilterComponent } from '../multiselect-dropdown-filter/multiselect-dropdown-filter.component';
import { AppService } from '../../../../../app.service';

@Component({
	selector: 'notification-multiselect-filter',
	templateUrl: './notification-multiselect-filter.component.html',
	styleUrls: ['./notification-multiselect-filter.scss']
})
export class NotificationMultiSelectFilterComponent extends MultiSelectLookupFilterComponent implements OnInit {

	constructor(
		filterService: FilterService,
		protected appService: AppService) {
			super(filterService, appService);
	}

	ngOnInit(): void {
		
	}

}
