import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { NotificationType } from "../../core/services/notification-type";
import { NotificationService } from "../../core/services/notification.service";
import { CategoryChangeLog } from "../models/category/category-change-log";
import { ActionDataResult } from "../models/core/ActionDataResult";

@Injectable()
export class CategoriesService {

	private controllerVersion = 1;
	private controllerName: string = 'category';

	constructor(
		public http: HttpClient,
		public notificationService: NotificationService
	) {

	}

	public getChangeLogForEntity(categoryId: number, entityId: number): Observable<CategoryChangeLog[]> {
		return this.http.post<ActionDataResult<CategoryChangeLog[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-log`, {
					categoryId: categoryId,
					entityId: entityId
				})
				.pipe(tap(resp => {

					if (!resp.isSuccessful) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}

				}, errorResponse => {

					if (errorResponse.status === 904)
						return;

					this.notificationService.error({
						title: 'Ошибка',
						message: errorResponse.error.errorDescription,
						notificationType: NotificationType.SweetAlert
					});

				}))
				.pipe(filter(x => x.isSuccessful))
				.map(x => x.data)
			;
	}
}
