import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LookupService } from '../../../services/lookup.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { SetActivityReasonForStoppingModel } from './models/SetActivityReasonForStoppingModel';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { RequestStoppingReasonAliasesService } from '../../../services/request-stopping-reason-aliases.service';
import { RequestStoppingReasonKindDto } from '../../../models/request/request-stopping-reason-kind-dto';
import { RequestStoppingReasonKindsService } from '../../../services/request-stopping-reason-kinds.service';
import { RequestStoppingReasonAliasDto } from '../../../models/request/request-stopping-reason-alias-dto';
import { UploadAttachmentsModel, UploadFile } from '../../../models/attachment';
import { AttachmentsService } from '../../../services/attachments.service';

@Component({
  selector: 'app-set-activity-reason-for-stopping',
  templateUrl: './set-activity-reason-for-stopping.component.html',
  styleUrls: ['./set-activity-reason-for-stopping.component.scss']
})
export class SetActivityReasonForStoppingComponent implements OnInit, OnDestroy {
	@Input() requestId: number;
	@Input() activityId: number;
	@Input() stoppingReasons: KeyValueObject[] = [];
	@Input() requestCustomerContragentId?: number = null;
	@Output() onContinueEvent = new EventEmitter<SetActivityReasonForStoppingModel>();

	stoppingReasonFieldName = 'stoppingReason';
	commentTextFieldName = 'commentText';
	approvedStoppedDateFieldName = 'approvedStoppedDate';

	uploadAttachments: UploadFile[] = [];
	requestStoppingReasonKinds: RequestStoppingReasonKindDto[] = [];
	attachmentsRequired: boolean;

	public form: FormGroup;
	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	stoppingReason?: number = null;

	private _destroy$: Subject<boolean> = new Subject<boolean>();

  	constructor(
  		private lookupService: LookupService,
		private attachmentsService: AttachmentsService,
		private requestStoppingReasonKindsService: RequestStoppingReasonKindsService,
		private dialog: DialogRef,
		private requestStoppingReasonAliasesService: RequestStoppingReasonAliasesService) 
	{
		this._buildForm();
	}

  	public ngOnInit() {
	this._loadData();
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public cancel() {
		if(this.dialog)
			this.dialog.close();
	}

	public continue() {
		let model = new SetActivityReasonForStoppingModel;
		model.requestStoppingReasonId = this.form.controls[this.stoppingReasonFieldName].value;
		model.comment = this.form.controls[this.commentTextFieldName].value;
		model.approvedStoppedDate = this.form.controls[this.approvedStoppedDateFieldName].value;

		this.saveAttachments();
		this.onContinueEvent.emit(model);
		this.cancel();
	}

	private _loadData(): void {
		this.lookupService.getData('stopping-reasons', null)
			.pipe(takeUntil(this._destroy$))
			.subscribe(data => this.stoppingReasons = data);

		this.requestStoppingReasonKindsService.list(null)
			.pipe(takeUntil(this._destroy$))
			.subscribe(data => this.requestStoppingReasonKinds = data.data);
	}

	private _buildForm(): void {
		this.form = new FormGroup({
			stoppingReason: new FormControl(null, Validators.required),
			commentText: new FormControl(null),
			approvedStoppedDate: new FormControl(null)
		});
	}

	private saveAttachments() {
		// TODO: Временное решение загружать файлы 2 раза, один раз для активности, второй раз для заявки
		if (!!this.uploadAttachments && this.uploadAttachments.length > 0) {
			this.attachmentsService.uploadWithResumableIfMobile(new UploadAttachmentsModel({
				requestId: this.requestId, activityId: this.activityId, attachments: this.uploadAttachments
			})).subscribe(() => { });
		}
	}

	public changeStoppingReason() {
		let stoppingReasonId = this.form.get(this.stoppingReasonFieldName).value;

		if (stoppingReasonId && stoppingReasonId !== 0) {
			var state: DataSourceRequestState = {
				skip: 0,
				take: null,
				filter: { logic: "and", filters: [
					{ field: "RequestStoppingReasonId", operator: "eq", value: stoppingReasonId },
					{ field: "ContragentId", operator: "eq", value: this.requestCustomerContragentId },
				] }
			};

			var reasonAliases$ = this.requestStoppingReasonAliasesService.list(state);
			var reasonKind$ = this.requestStoppingReasonKindsService.getByReasonId(stoppingReasonId);

			forkJoin([reasonAliases$, reasonKind$])
				.pipe(takeUntil(this._destroy$))
				.subscribe(result => {
					this.validateFields(result[0].data, result[1]);
				});
		}
	}

	private validateFields(reasonAliases: RequestStoppingReasonAliasDto[], reasonKind: RequestStoppingReasonKindDto) {		
		this.attachmentsRequired = reasonKind ? reasonKind.requireAttachment : false;

		let isCommentRequired = reasonAliases.find(x => x.additionalCommentRequired) || 
			(reasonKind && reasonKind.requireComment) ? true : false;

		if (isCommentRequired) {
			this.form.controls[this.commentTextFieldName].setValidators(Validators.required);
		}
		else {
			this.form.controls[this.commentTextFieldName].clearValidators();
		}

		if (reasonKind && reasonKind.requireDateStoppedUntil) {
			this.form.controls[this.approvedStoppedDateFieldName].setValidators(Validators.required);
		}
		else {
			this.form.controls[this.approvedStoppedDateFieldName].clearValidators();
		}

		this.form.controls[this.commentTextFieldName].updateValueAndValidity();
		this.form.controls[this.approvedStoppedDateFieldName].updateValueAndValidity();
	}

	get attachmentsInvalid(): boolean {
		return this.attachmentsRequired && (!this.uploadAttachments || this.uploadAttachments.length == 0);
	}
}
