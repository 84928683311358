import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { SlaCutoff } from '../../../shared/models/sla/sla-cutoff';
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { Agreement } from "../../../shared/models/agreement/agreement";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";

@Component({
	selector: 'sla-border',
	templateUrl: './sla-border.component.html',
	styleUrls: []
})
export class SLABorderForm implements OnInit {

	@Input() entityViewModel: EntityViewModel<Agreement>;
	@Input() canEditForm: boolean;
	@Input() serviceModes: KeyValueObject[] = [];
	@Input() serviceModesForDropdown: KeyValueObject[] = [];
	@Output() onSaveSlaCutoff: EventEmitter<any> = new EventEmitter();
	@Output() onRemoveHandler: EventEmitter<any> = new EventEmitter();

	ngOnInit(): void {
	}

	createNewSlaCutoff(): any {
		return () => {
			const slaCutoff = new SlaCutoff();
			return slaCutoff;
		};
	}

	saveSlaCutoffHandler(event: any): void {
		if (!event.dataItem.serviceModeId) {
			event.dataItem = { ...event.dataItem, serviceModeId: null };
		}
		this.onSaveSlaCutoff.emit(event);
	}

	removeHandler(event: any): void {
		this.onRemoveHandler.emit(event);
	}

	slaCutoffCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {
		if (!this.canEditForm || columnIndex === 2) {
			return;
		}
		sender.editRow(rowIndex);
	}

	getServiceModeName(dataItem: SlaCutoff): string {
		const serviceModes = this.serviceModes.filter(x => x.id === dataItem.serviceModeId);
		return serviceModes.length > 0 ? serviceModes[0].name : 'Для всех режимов';
	}
}
