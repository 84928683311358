import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { NotificationType } from '../../core/services/notification-type';
import { filter, tap } from 'rxjs/operators';
import { DeviceTypeDto } from '../models/device/device-type.dto';
import { KeyValueDataObject } from '../models/core/KeyValueDataObject';

@Injectable()
export class DeviceTypesService extends DataService<DeviceTypeDto, DeviceTypeDto> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'device-types';
	}

	public getAllDeviceTypesNoCategory(): Observable<DeviceTypeDto[]> {
		var url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-all-device-types-no-category`;
	
		return this.http.get<ActionDataResult<DeviceTypeDto[]>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;

	}

	public getDeviceTypesWithKinds(): Observable<KeyValueDataObject<number>[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-device-types-with-kinds`);
	}
}
