import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { WorkflowTransition } from "../../../models/request/workflow-transition";
import { SetCommentModel } from "../set-comment/set-comment.model";

@Component({
    selector: 'transition-confirmation-request',
    templateUrl: './transition-confirmation-request.component.html',
    styleUrls: ['../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class TransitionConfirmationRequestComponent implements OnInit {

    @Output()
    onContinueEvent = new EventEmitter<SetCommentModel>();

    @Input()
	workflowTransition: WorkflowTransition;

    @Input()
	setCommentModel: SetCommentModel;

    dataSaving: boolean = false;
    textArray: string[] = [];

    constructor(
        public dialog: DialogRef
    ) {

    }

    get cardHeight(): number {
        return window.innerHeight - 800;
    }

    getTextArray(): void {
        if (!!!this.workflowTransition.warningText) {
            return;
        }

        this.textArray = this.workflowTransition.warningText.split("<br>");
    }

    ngOnInit() {
        this.getTextArray();
    }

    closeDialog() {
        if (this.dialog)
            this.dialog.close();
    }

    cancel() {
        this.closeDialog();
    }

    continue() {
        this.dataSaving = true;

        if (this.onContinueEvent) {
            this.onContinueEvent.emit(this.setCommentModel);
        }

        this.dataSaving = false;
        this.closeDialog();
    }
}