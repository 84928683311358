import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Contragent } from '../../shared/models/contragent/Contragent';
import { ContragentLookupModel } from '../../shared/models/contragent/contragent-lookup-model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import {pluck, tap} from 'rxjs/operators';
import {NotificationType} from "../../core/services/notification-type";
import {ContragentPolygon} from "../../shared/models/contragent/contragent-polygon";
import {ContragentScUserGroup} from "../../shared/models/contragent/contragent-sc-user-group";
import {ContragentListItem} from "../../shared/models/contragent/contragent-list-item";
import {DataSourceRequestState, toDataSourceRequestString} from "@progress/kendo-data-query";
import { ContragentName } from '../../shared/models/contragent/contragent-name';
import { ActionResult } from '../../shared/models/core/ActionResult';

@Injectable()
export class ContragentsService extends DataService<ContragentListItem, Contragent> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
      this.controllerName = 'contragents';
	}

	public LookupExtended(): Observable<ContragentLookupModel[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/lookup-extended`);
	}

	public lookupAllList(contragentTypeIds: number[] = null): Observable<KeyValueObject[]> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/lookup-all-list`;
		return  this.http.post<ActionDataResult<KeyValueObject[]>>(url, {contragentTypeIds : contragentTypeIds}).pipe(pluck('data'));
	}

	public contragentsAcquirers(): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/acquirers`);
	}

	public getByTypes(typeIds: number[]): Observable<ActionDataResult<KeyValueObject[]>> {
		return this.postQuery<KeyValueObject[]>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/by-types`, {typeIds: typeIds});
	}

	public getFullReport(state: DataSourceRequestState): Observable<any>{
		var queryStr = `${toDataSourceRequestString(state)}`;

		//хак, это ошибка компонента грид
		queryStr = queryStr.replace(/T\d\d-00-0/g, 'T00-00-00');

		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/full-report?${queryStr}`);
	}

	public uploadPolygons(contragentId: number, serviceCenterId: number, polygonsFile: File): Observable<ActionDataResult<ContragentPolygon[]>> {
		const formData: FormData = new FormData();
		if (contragentId !== null){
			formData.append('contragentId', contragentId.toString());
		}

		if (serviceCenterId !== null){
			formData.append('serviceCenterId', serviceCenterId.toString());
		}

		if (polygonsFile !== null){
			formData.append('polygonsFile', polygonsFile, polygonsFile.name);
		}

		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/upload-polygons`;
		return this.http.post<ActionDataResult<ContragentPolygon[]>>(url, formData)
			.pipe(tap(resp => {

					if (!resp.isSuccessful) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}

				},
				errorResponse => {

					if (errorResponse.status === 904)
						return;

					this.notificationService.error({
						title: 'Ошибка',
						message: errorResponse.error.errorDescription,
						notificationType: NotificationType.SweetAlert
					});

				}));

	}

	public getPolygonsBySc(serviceCenterId: number): Observable<ActionDataResult<ContragentPolygon[]>>{
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-polygons/${serviceCenterId}`;
		return this.http.get<ActionDataResult<ContragentPolygon[]>>(url);
	}

	public addUserGroupForContragent(contragentId: number, userGroup: ContragentScUserGroup): Observable<ActionDataResult<number>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/add-usergroup`;
		return this.http.post<ActionDataResult<number>>(url, {contragentId: contragentId, ...userGroup})
			.pipe(tap(resp => {

					if (!resp.isSuccessful) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}

				},
				errorResponse => {

					if (errorResponse.status === 904)
						return;

					this.notificationService.error({
						title: 'Ошибка',
						message: errorResponse.error.errorDescription,
						notificationType: NotificationType.SweetAlert
					});

				}));
	}

	public updateUserGroupForContragent(contragentId: number, userGroup: ContragentScUserGroup): Observable<any> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/update-usergroup`;
		return this.http.post<ActionDataResult<number>>(url, {contragentId: contragentId, ...userGroup})
			.pipe(tap(resp => {

					if (!resp.isSuccessful) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}

				},
				errorResponse => {

					if (errorResponse.status === 904)
						return;

					this.notificationService.error({
						title: 'Ошибка',
						message: errorResponse.error.errorDescription,
						notificationType: NotificationType.SweetAlert
					});

				}));
	}

	public removeUserGroupForContragent(contragentId: number, userGroup: ContragentScUserGroup): Observable<any> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/remove-usergroup`;
		return this.http.post<ActionDataResult<ContragentPolygon[]>>(url, {contragentId: contragentId, userGroup: userGroup})
			.pipe(tap(resp => {

					if (!resp.isSuccessful) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}

				},
				errorResponse => {

					if (errorResponse.status === 904)
						return;

					this.notificationService.error({
						title: 'Ошибка',
						message: errorResponse.error.errorDescription,
						notificationType: NotificationType.SweetAlert
					});

				}));
	}

	public getContragnetsByLinkedAgreements(contragentId: number): Observable<KeyValueObject[]> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-contragents-by-linked-agreements/${contragentId}`;
		return this.getQuery(url);
	}

	public getContragentNames(ids: number[]): Observable<ActionDataResult<ContragentName[]>> {
		return this.postQuery<ContragentName[]>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-contragent-names`, {ids: ids});
	}

	public getContragnetsNotAllowedEmptyDescriprion(): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-contragents-not-allowed-empty-description`);
	}

	public addChangeLog(serviceCenterIntegraCode: string, values: string, comment: string, properyName: string): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/add-changelog`, 
			{
				serviceCenterIntegraCode: serviceCenterIntegraCode,
				values: values,
				comment: comment,
				properyName: properyName
			});
	}
}