import { Component, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { ContragentsService } from '../contragents.service';
import { Contragent } from '../../../shared/models/contragent/Contragent';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { GridSettings } from '../../../shared/models/grid/grid-settings';
import { saveAs } from 'file-saver';
import {ContragentListItem} from "../../../shared/models/contragent/contragent-list-item";
import {ColumnSettings} from "../../../shared/models/grid/column-settings";
import {ContragentDetail} from "../../../shared/models/contragent/contragent-detail";
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';

@Component({
	selector: 'categories-contragents-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ContragentsListPage extends ListPageBase<ContragentListItem> {

	@ViewChild('filterAllInput') filterAllInput: ElementRef;

	contragentTypes: KeyValueObject[] = [];
	contragents: KeyValueObject[] = [];
	projects: KeyValueObject[] = [];
	legalTypes: KeyValueObject[] = [];

	constructor(
		appService: AppService,
		router: Router,
		protected dataService: ContragentsService,
		protected persistingService: StatePersistingService,
		private contragentService: ContragentsService,
		private lookupService: LookupService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Контрагенты';
	}

	public enableSaveGridSettings: boolean = true;

	gridSettingsStorageKey: string = 'contragents-grid-settings';

	public gridSettings: any = {
		clearFilter: true,
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'name', dir: 'asc' }],
			filter: { logic: 'and', filters: [{ field: 'contragentTypeName', operator: 'neq', value: 'Мерчант (ТСП)' }] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'name',
				title: 'Наименование',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'contragentTypeName',
				title: 'Тип',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false
			},
			// {
			// 	orderIndex: 2,
			// 	field: 'projectName',
			// 	title: 'Проект',
			// 	filter: 'lookup',
			// 	filterable: true,
			// 	width: 300,
			// 	hidden: false,
			// },
			{
				orderIndex: 3,
				field: 'integraCode',
				title: 'Интегра код',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'executorContragentName',
				title: 'Контрагент исполнитель',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'contragentAcquirerName',
				title: 'Банк эквайер',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'details.legalName',
				title: 'Юр. наименование',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},

			{
				orderIndex: 6,
				field: 'details.inn',
				title: 'ИНН',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'details.kpp',
				title: 'КПП',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'details.legalAddress',
				title: 'Юр. Адрес',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 9,
				field: 'details.actualAdderss',
				title: 'Физический Адрес',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 10,
				field: 'details.mailAddress',
				title: 'Почтовый Адрес',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 11,
				field: 'details.paymentAccount',
				title: 'Расчетный счет',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 12,
				field: 'details.bankName',
				title: 'Наименование банка',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 13,
				field: 'details.bankBIK',
				title: 'БИК',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 14,
				field: 'details.corrAccount',
				title: 'Корреспондентский счет',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 15,
				field: 'details.ceoName',
				title: 'Генеральный директор',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 16,
				field: 'details.authorityBase',
				title: 'Основание полномочий',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 17,
				field: 'details.legalTypeName',
				title: 'Форма собственности',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 18,
				field: 'details.dateStart',
				title: 'Дата начала',
				filter: 'date',
				filterable: true,
				width: 300,
				hidden: false,
				format: 'dd.MM.yyyy',
			},
			{
				orderIndex: 19,
				field: 'details.dateEnd',
				title: 'Дата окончания',
				filter: 'date',
				filterable: true,
				width: 300,
				hidden: false,
				format: 'dd.MM.yyyy',
			},
			{
				orderIndex: 20,
				field: 'details.ogrn',
				title: 'ОГРН',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
		]
	};

	onListPageInit(): void {
		this.lookupService.getData("projects", null).subscribe(data => this.projects = data);
		this.lookupService.getData("contragents", null).subscribe(data => this.contragents = data);
		this.lookupService.getData("contragentTypes", null).subscribe(data => {
			this.contragentTypes = data.filter(x => x.name && !x.name.includes('Мерчант'));
		});
		this.lookupService.getData("legal-types", null).subscribe(data => this.legalTypes = data);

		if (!this.gridSettings.state.filter.filters.some(x => x.field === 'contragentTypeName' && x.operator === 'isnotnull')) {
			this.gridSettings.state.filter.filters.push({ field: 'contragentTypeName', operator: 'isnotnull' });
		}
	}

	ngAfterViewInit(): void {
		fromEvent(this.filterAllInput.nativeElement, 'keyup')
		.pipe(
			map((evt: any) => evt.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe((text: string) => this.onFilter(text));
	}

	processListItems(items: ContragentListItem[]): void {
		items.map(x => {
			if (!x.details) {
				x.details = new ContragentDetail();
			} else {
				x.details.dateStart = this.appService.parseDate(x.details.dateStart);
				if (x.details.dateEnd){
					x.details.dateEnd = this.appService.parseDate(x.details.dateEnd);
				}
			}
		});
	}

	public cellClick({ dataItem }: any): void {
		window.open(`/categories/contragent/${dataItem.contragentId}`, '_blank');
	}

	public onFilter(inputValue: string): void {
		// var customState: DataSourceRequestState = {
		// 	skip: 0,
		// 	take: this.gridSettings.state.take,
		// 	sort: this.gridSettings.state.sort,
		// 	filter: {
		// 		logic: "or",
		// 		filters: [
		// 			{
		// 				field: 'name',
		// 				operator: 'contains',
		// 				value: inputValue
		// 			},
		// 			{
		// 				field: 'projectName',
		// 				operator: 'contains',
		// 				value: inputValue
		// 			}
		// 		],
		// 	}
		// };
		//
		// this.loadData(customState);
	}

	public downloadReport(): void {
		this.loading = true;
		this.contragentService.getFullReport({...this.gridSettings.state, skip: 0, take: null})
			.subscribe(result => {
				const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result.report}`;
				saveAs(dataURI, result.fileName);
				this.loading = false;
			})
	};

	getDetailsValue(dataItem: ContragentListItem, col: ColumnSettings) {
		const detailsFieldName = col.field.split('.')[1];
		return dataItem.details[detailsFieldName];
	}
}
