import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "../../core/services/data.service";
import { NotificationService } from "../../core/services/notification.service";
import { NomenclatureListItemDto } from "../models/nomenclature/nomenclatureListItemDto";
import { NomenclatureDto } from "../models/nomenclature/nomenclatureDto";
import { ActionDataResult } from "../models/core/ActionDataResult";
import { EntityViewModel } from "../models/core/EntityViewModel";
import { Observable } from "rxjs";
import { NotificationType } from "../../core/services/notification-type";
import { filter, take, tap } from "rxjs/operators";
import { KeyValueObject } from "../models/core/KeyValueObject";

@Injectable()
export class NomenclatureService extends DataService<NomenclatureListItemDto, NomenclatureDto> {

	constructor(
		protected http: HttpClient,
		protected notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'nomenclatures';
	}

	public getPartnumberByNomenclature(id: number | string): Observable<NomenclatureDto> {
		return this.http.get<ActionDataResult<NomenclatureDto>>(`${this.baseUrl()}/partnumber-by-nomenclature/${id}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;
				else if (errorResponse.status == 2) {
					var result = new EntityViewModel<NomenclatureDto>();
					result.isNew = true;
					return Observable.of(result);
				}

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error ? errorResponse.error.errorDescription : errorResponse.message,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public getPartnumberByName(name: string): Observable<NomenclatureDto[]> {
		return this.http.get<ActionDataResult<NomenclatureDto[]>>(`${this.baseUrl()}/partnumber-by-name/${name}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;
				else if (errorResponse.status == 2) {
					var result = new EntityViewModel<NomenclatureDto>();
					result.isNew = true;
					return Observable.of(result);
				}

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error ? errorResponse.error.errorDescription : errorResponse.message,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public createNomenclatureOrAddPartnumber(nomenclatureId: number, nomenclatureName: string, partnumber: string): Observable<ActionDataResult<number | string>> {
		return this.postQuery(`${this.baseUrl()}/`, {
			nomenclatureId: nomenclatureId,
			nomenclatureName: nomenclatureName,
			partnumber: partnumber
		});
	}
}