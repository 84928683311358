import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Device } from '../../shared/models/device/Device';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { ListViewModel } from '../../shared/models/core/ListViewModel';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { NotificationType } from '../../core/services/notification-type';
import { filter, tap } from 'rxjs/operators';
import { SelectSn } from '../models/device/select-sn/select-sn.model';
import { ActionResult } from '../models/core/ActionResult';
import { DeviceItem } from '../models/device/device-item';
import { DeviceName } from '../models/device/device-name';
import { SearchDeviceData } from '../models/device/search-device-data';
import { DeviceInfo } from '../models/device/device-info';
import { InstallationComplete } from '../models/device/installation-complete.model';

@Injectable()
export class DevicesService extends DataService<Device, DeviceItem> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'devices';
	}

	public selectSn(selectSn: SelectSn): Observable<ActionResult> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/select-sn`, selectSn);
	}

	public getCommentByRequestDeviceWarehouseActionId(requestDeviceWarehouseActionId: number): Observable<string> {
		return this.getQuery<string>(`${this.baseUrl()}/device-comment-by-requestdevicewarehouseactionid?requestDeviceWarehouseActionId=${requestDeviceWarehouseActionId}`);
	}

	public linkedDevices(state: DataSourceRequestState, requestId: number, activityId: number, excludeCancellatedReserve: boolean = false): Observable<ListViewModel<Device>> {
		const queryStr = `${toDataSourceRequestString(state)}`;

		var url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestId}/linked-devices?`;

		url += `excludeCancellatedReserve=${excludeCancellatedReserve}&`;
		if (activityId != null) {
			url += `activityId=${activityId}&`;
		}

		return this.http.get<ActionDataResult<ListViewModel<Device>>>(`${url}${queryStr}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;

	}

	public checkWarehouseActionsBy1c(requestId: number): Observable<ActionResult> {
		return this.postQuery<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/check-warehouse-actions-by-1c`, {requestId: requestId})
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка проверки артикула для складской заявки через 1С',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка проверки артикула для складской заявки через 1С',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}

	public updateWarehouseActionArticle(requestDeviceWarehouseRefillId: number
			, isDevicePart: boolean
			, articleId: number): Observable<ActionResult> {
		return this.postQuery<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/update-warehouse-action-article`,
		{
			requestDeviceWarehouseRefillId: requestDeviceWarehouseRefillId,
			isDevicePart: isDevicePart,
			articleId: articleId
		})
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка обновления артикула у устройства или комплектующего складской заявки',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			} else {
				this.notificationService.success({
					title: 'Успех',
					message: 'Изменения сохранены',
					notificationType: NotificationType.Toast
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка обновления артикула у устройства или комплектующего складской заявки',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}

	public deleteWarehouseActionArticle(requestDeviceWarehouseRefillId: number
		, isDevicePart: boolean): Observable<ActionResult> {
		return this.postQuery<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/delete-warehouse-action-article`,
		{
			requestDeviceWarehouseRefillId: requestDeviceWarehouseRefillId,
			isDevicePart: isDevicePart
		})
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка удаления устройства или комплектующего складской заявки',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка удаления устройства или комплектующего складской заявки',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}

	public getDeviceModelNames(ids: number[]): Observable<ActionDataResult<DeviceName[]>> {
		return this.postQuery<DeviceName[]>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-device-model-names`, {ids: ids});
	}

	public getDeviceTypeNames(ids: number[]): Observable<ActionDataResult<DeviceName[]>> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-device-type-names`, {ids: ids});
	}

	public getDeviceVendorNames(ids: number[]): Observable<ActionDataResult<DeviceName[]>> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-device-vendor-names`, {ids: ids});
	}

	public isReserveSerialNumberAvailable(requestId: number): Observable<boolean> {
		return this.getQuery<boolean>(`${this.baseUrl()}/${requestId}/is-reserve-serial-number-available`);
	}

	public reservedEqualUsed(requestId: number, devices: DeviceInfo[]): Observable<ActionDataResult<boolean>> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/reserved-equal-used`, 
		{
			requestId: requestId, 
			devices: devices
		});
	}

	public isRemainsDevices(requestId: number, serialNumber: string, nomenclature: string): Observable<SearchDeviceData> {
		var queryString = `/remains-devices?requestId=${requestId}&serialNumber=${serialNumber}&nomenclature=${nomenclature}`;
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}${queryString}`);
	}

	public getNomenclatures(deviceName: string): Observable<string[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/nomenclature?deviceName=${deviceName}`);
	}

	public installationCompleteSuccessfull(requestId: number, devices: DeviceInfo[], activityId: number, hasUninstallDevices: boolean = false): Observable<ActionDataResult<boolean>> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/installation-complete-successfull`,
		{
			requestId: requestId,
			devices: devices,
			activityId: activityId,
			hasUninstallDevices: hasUninstallDevices
		});
	}

	public installationComplete(request: InstallationComplete): Observable<ActionDataResult<boolean>> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/installation-complete`,
		request, 
		"Оборудование успешно установлено", 
		true, 
		NotificationType.SweetAlert);
	}
}
