export enum CustomerEnum {
    /**ВТБ */
    vtb = 1055,
    /**Альфа-банк */
    alpha = 4,
    /**АЛЬЯНС-АЛЬФАБАНК */
    alphaAlliance = 11222,
    /**ПАО «Промсвязьбанк» */
    psb = 12919,
    /**ООО "РТК ЦТ" */
    rtk = 136572,
    /**АЛЬЯНС-Зенит */
    atmAllianceZenit = 163333,
    /**Лантер */
    lanter = 2,
} 