import { Component, Injector } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { EngubatorCommonSettingsKeyEnum } from '../../shared/enums/engubator-common-settings-key.enum';
import { VeerouteRequestPlanningDto } from '../../shared/models/engubator/veeroute-request-planning-dto';
import { VeerouteCommonSettingsService } from '../../shared/services/engubator/engubator.common-settings.service';
import { EngubatorPlanningService } from '../../shared/services/engubator/engubator.planning.service';
@Component({
	selector: 'veeroute-calendar',
	templateUrl: './veeroute-calendar.component.html',
	styles: [':host { display: block; }']
})

export class VeerouteCalendarComponent {
	constructor(
		private engubatorPlanningService: EngubatorPlanningService,
		private veerouteCommonSettingsService: VeerouteCommonSettingsService,
		private injector: Injector) { }
	loading: boolean;
	loaded: boolean;
	success: boolean;
	error104: boolean = false;
	public min: Date = new Date();
	public value: Date;
	public errorText: string;
	public requestIds: number[];
	public cancel(): void {
		this.close();
	}
	public async saveData(): Promise<void> {
		this.loading = true;
		var veerouteRequestPlanningDto = new VeerouteRequestPlanningDto();
		veerouteRequestPlanningDto.date = this.value;
		veerouteRequestPlanningDto.requests = this.requestIds;
		this.engubatorPlanningService.planVeeroute(veerouteRequestPlanningDto).subscribe(result => {
			this.loaded = true;
			if (!result.isSuccessful) {
				this.error104 = result.code === 104;
				this.errorText = result.errorDescription;
				this.success = false;
				this.engubatorPlanningService.getResultVeeroutePlanForRequests(result.data);
				return;
			}
			else {
				this.success = true;
				this.error104 = false;
				this.veerouteCommonSettingsService.getById(EngubatorCommonSettingsKeyEnum.VeerouteUrl).subscribe(
					result => {
							window.open(result.entity.value, "_blank");
						}
				);
				return;
			}
		});
	}

	private close(resetCheckboxes: boolean = false): void {
		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}
}
