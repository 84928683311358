export enum ActivityStatusEnum {
	activityCreated = 1, // Наряд создан
	withoutVisit = 2, // Без выезда
	falseVisit = 3, // Холостой выезд
	workStarted = 4, // Начало работы
	workEnded = 5, // Окончание работ
	visit = 6, // Прибытие на объект
	activityEndUnsuccessfully = 7, // Окончание работ: неуспешно
	visitConfirmed = 8, // Выезд согласован
	contactPersonNotRespond = 9, // Контактное лицо не отвечает
	tspRefused = 10, // Отказ ТСП от обслуживания
	tspNotReady = 11, // ТСП не готово к обслуживанию
	visitWarehouse = 12, // Прибыл на склад
	devicesReceived = 13, // Оборудование получено
	devicesReady = 14, // Оборудование готово
	visitTsp = 15, // Прибыл на ТСП
	scanDevices = 16, // Сканирование оборудования
	workCompleted = 17, // Работы выполнены
	devicesBroken = 18, // Оборудование неисправно
	returnToWarehouse = 19, // Вернулся на склад
	devicesReturned = 20, // Оборудование возвращено
	expertiseInProgress = 21, // Выполнение экспертизы
	requestCompleted = 22, // Заявка завершена
	equipmentIsReadyForDelivery = 23, // Оборудование готово к выдаче
	equipmentIssuedFromWarehouse = 24, // Оборудование выдано со склада
	awaitingDeliveryOfEquipment = 25, // Ожидание выдачи оборудования
	engineerGaveDevices = 26, // Инженер сдал оборудование
	engineerReceivedDevices = 27, // Инженер принял оборудование
	warehouseGaveDevices = 28, // Склад сдал оборудование
	warehouseReceivedDevices = 29, // Склад принял оборудование
	// Отменена
	cancelled = 30,
	// Работы завершены удаленно
	worksCompletedRemotely = 31,
	// Ожидает сбора для перемещения
	awaitingCollectionForMoving = 32,
	// Сбор для перемещения
	collectionForMoving = 33,
	// Собрано для перемещения
	assembledToMove = 34,
	// Ожидает приема на склад
	awaitingWarehouseAcceptance = 35,
	// Прием на складе получателя
	acceptionAtTheRecipientWarehouse = 36,
	// Принято на складе получателя
	acceptedAtTheRecipientWarehouse = 37,
	//Прием невозможен
	receptionIsNotPossible = 38,
	//Готово к отправке в ТК
	readyToShipToCarrier = 39,
	//Отправлено в ТК
	sentToCarrier = 40,

	// Начать экспертизу
	startExpertise = 43,

	//Ожидает возврата на склад
	awaitingReturnToWarehouse = 44,
	//Возврат принят
	devicesAcceptedByWarehouse = 45,
	//Автоматический прием на складе получателя
	automaticAcceptanceAtConsigneesWarehouse = 46,
	//Возврат неиспользованного оборудования
	returnOfNotWorkingEquipment = 47,
	//Прием возврата на склад
	acceptanceOfReturnToWarehouse = 48,
	/** Замена выданных комплектующих */
	issuedPartsReplacement = 55
}
