export enum CategoryType {
	// Заявки
	requests = 3,

	// ТСП
	merchants = 4,

	// Объекты ТСП
	customerObjects = 5,

	// Контрагенты
	contragents = 6,

	// Терминалы
	tids = 7,

	// Оборудование
	devices = 8,

	// Склады
	warehouse= 9,
	
	// Комментарии к заявке
	requestComments = 11,

	// Услуги
	services = 14,

	// Категории обслуживания
	serviceCategories = 15,

	// Режимы обслуживания
	serviceModes = 16,

	// Типы обслуживания
	serviceTypes = 17,

	// Договоры
	agreements = 18,

	// Настройка цветовых схем
	colorSchemeSettings = 21,

	/** Активности */
	activities = 27,
}