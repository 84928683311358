import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { WarehouseArea } from '../models/warehouse/warehouse-area';
import { WarehouseAreaLookupModel } from '../models/warehouse/warehouse-area-lookup-model';

@Injectable()
export class WarehouseAreasService extends DataService<WarehouseArea, WarehouseArea> {
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'warehouse-areas';
	}

	getByWarehouse(warehouseId: number): Observable<WarehouseArea[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/warehouse/${warehouseId}`);
	}

	getWithWarehouse(warehouseKindIds: number[]): Observable<WarehouseAreaLookupModel[]> {
		let params = new HttpParams();
		warehouseKindIds.forEach(id => {
		  params = params.append('ids', id.toString());
		});
		return this.getQueryWithParams(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/warehouse-areas-by-warehouse`, params);
	}
}