import { Component, OnInit } from "@angular/core";
import { ComponentPageBase } from "../../../shared/components/component-page-base/component-page-base";
import { RequestStoppingReasonDto } from "../../../shared/models/request/request-stopping-reason-dto";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { NotificationService } from "../../../core/services/notification.service";
import { LookupService } from "../../../shared/services/lookup.service";
import { DialogService } from "@progress/kendo-angular-dialog";
import { RequestStoppingReasonsService } from "../../../shared/services/request-stopping-reasons.service";
import { RequestStoppingReasonAliasDto } from "../../../shared/models/request/request-stopping-reason-alias-dto";
import { RequestStoppingReasonAliasesService } from "../../../shared/services/request-stopping-reason-aliases.service";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { ContragentType } from "../../../shared/enums";

@Component({
	selector: 'request-stopping-reason',
	templateUrl: './request-stopping-reason.page.html',
	styleUrls: [
		'./request-stopping-reason.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class RequestStoppingReasonPage extends ComponentPageBase<RequestStoppingReasonDto> implements OnInit {
    
	gridHeight: number;

    aliases: RequestStoppingReasonAliasDto[] = [];
    contragents: KeyValueObject[] = [];

    constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: RequestStoppingReasonsService,
		protected dialogService: DialogService,
        private lookupService: LookupService,
        private reasonAliasesService: RequestStoppingReasonAliasesService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/request-stopping-reasons';
		this.appService.pageTitle = 'Причина остановки заявки';
	}

    ngOnInit(): void {
        this.initContragentsLookup();

        this.entityId = this.route.snapshot.paramMap.get('requestStoppingReasonId');
		this.gridHeight = window.innerHeight / 2;

        if (this.entityId != null) {
            this.loadData();
        }
    }

	loadData() {
		this.dataService.getById(+this.entityId).subscribe(vm => {
			this.entityName = vm.entity.text;
			this.entityViewModel = vm;
		});

		var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "RequestStoppingReasonId", operator: "eq", value: +this.entityId }] }
		};
		this.reasonAliasesService.list(state).subscribe(response => {
			this.aliases = response.data;
		});
	}

    initContragentsLookup() {
        var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: ContragentType.bankEquer }] }
		};
        this.lookupService.getData("contragents", state).subscribe(data => {
            this.contragents = data;
        });
    }

    getContragent(dataItem: RequestStoppingReasonAliasDto): string {
        var contragent = this.contragents.find(x => x.id == dataItem.contragentId);
        return contragent ? contragent.name : '';
    }

    cellClick({ sender, columnIndex, rowIndex }: any): void {
		if (columnIndex > 2)
			return;

		sender.editRow(rowIndex);
	}

	createNewAlias() {
		var reason = new RequestStoppingReasonAliasDto();
		return reason;
	}

	saveHandler({ dataItem }) {
        dataItem.requestStoppingReasonId = this.entityId;
        
		let operation = !dataItem.requestStoppingReasonAliasId
			? this.reasonAliasesService.create(dataItem)
			: this.reasonAliasesService.update(dataItem, "Изменение синонима");

		operation.subscribe(() => { this.loadData() });
	}

	removeHandler({ dataItem }) {
		this.reasonAliasesService.remove(dataItem.requestStoppingReasonAliasId, null, null, true)
			.subscribe(() => {});
	}
}