export class ChangePwfStatusToastModel {
    showSuccess: boolean = false;
    showFailure: boolean = false;
    successTitle: string = null;
    successMessage: string = null;
    failureTitle: string = null;
    failureMessage: string = null;

    constructor(
        showSuccess: boolean,
        showFailure: boolean,
        successTitle: string,
        successMessage: string,
        failureTitle: string,
        failureMessage: string
    ) {
        this.showSuccess = showSuccess;
        this.showFailure = showFailure;
        this.successTitle = successTitle;
        this.successMessage = successMessage;
        this.failureTitle = failureTitle;
        this.failureMessage = failureMessage;
    }
}