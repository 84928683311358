import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";

import { groupBy } from '@progress/kendo-data-query';
import { RequestConfigurationService } from "../../../services/request-configuration.service";
import { TmsGateDataService } from "../../../services/tmsgate.data.service";
import { Request } from '../../../../shared/models/request/request';
import { NewRequestDevice } from "../../../models/request/new-request/new-request-device";
import { LookupService } from "../../../services/lookup.service";
import { AddOrUpdateConfigurationModel } from "./add-or-update-configuration.model";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { SelectedCommentsFilter } from "../../../models/request/comments/selected-comment-filter";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { TmsParamsModel } from "./tms-params.model";
import { TidsService } from "../../../services/tids.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { DeviceTypesService } from "../../../services/device-types.service";
import { HierarchyLookupModel } from "../../../models/core/HierarchyLookupModel";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { NgForm } from "@angular/forms";
import { AppService } from "../../../../app.service";
import { RequestType } from "../../../enums/request-type.enum";

@Component({
	selector: 'configuration-preparing',
	templateUrl: './configuration-preparing.component.html',
	styleUrls: ['../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class ConfigurationPreparing implements OnInit {
	@ViewChild('configurationPreparingForm') public entityForm: NgForm;
	@Output()
	onContinueEvent = new EventEmitter<AddOrUpdateConfigurationModel>();

	@Input()
	request: Request;

	@Input()
	requestDevice: NewRequestDevice;

	@Input()
	stoppingReasons: KeyValueObject[] = [];

	selectedCommentsFilter: SelectedCommentsFilter = { selectedTab: "all", showSystemComments: false };

	dataSaving: boolean = false;

	deviceConnectionType: string = null;

	tmsList: KeyValueObject[] = [];
	templateList: KeyValueObject[] = [];
	regions: string[] = [];
	templateListLoaded: boolean = false;

	tmsParams: TmsParamsModel[] = [];
	paramToAdd: string;

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	get commentHeight(): number {
		return window.innerHeight / 2;
	}

	deviceTypes: KeyValueObject[] = [];
	deviceConnectionTypes: KeyValueObject[] = [];
	deviceConnectionTypesGrouped: any[] = [];
	deviceConnectionTypesGroupedDropdown: any[] = [];
	supplierDevices: HierarchyLookupModel[] = [];
	deviceSuppliers: KeyValueObject[] = [];
	deviceModels: HierarchyLookupModel[] = [];

	timeZoneList: KeyValueObject[] = [
		new KeyValueObject(2, "+2 UTC (Калининград)"),
		new KeyValueObject(3, "+3 UTC (Москва, Санкт-Петербург, Казань)"),
		new KeyValueObject(4, "+4 UTC (Самара, Волгоград)"),
		new KeyValueObject(5, "+5 UTC (Екатеринбург, Уфа)"),
		new KeyValueObject(6, "+6 UTC (Омск)"),
		new KeyValueObject(7, "+7 UTC (Новосибирск, Красноярск)"),
		new KeyValueObject(8, "+8 UTC (Иркутск)"),
		new KeyValueObject(9, "+9 UTC (Чита, Якутск)"),
		new KeyValueObject(10, "+10 UTC (Хабаровск, Владивосток)"),
		new KeyValueObject(11, "+11 UTC (Магадан)"),
		new KeyValueObject(12, "+12 UTC (Петропавловск-Камчатский)"),
	]

	private optionalLegalNameParam: string = "TerminalNameOnReceipt";

	selectedTms: KeyValueObject;
	ikr: boolean;
	selectedTemplate: KeyValueObject;
	device: string = '';
	legalName: string;
	address: string;
	selectedTimeZone: number;
	params: TmsParamsModel[] = [];
	selectedRegion: string;

	constructor(public dialog: DialogRef,
		private requestConfigurationService: RequestConfigurationService,
		private tmsGateDataService: TmsGateDataService,
		private tidsService: TidsService,
		protected appService: AppService,
		private notificationService: NotificationService,
		private cdr: ChangeDetectorRef,
		private lookupService: LookupService) {
	}

	get cardHeight(): number {
		return window.innerHeight - 300;
	}

	ngOnInit() {
		this.getConfiguration();

		this.tmsGateDataService.getRegionsFromTms(this.request.merchantRegionName).subscribe(regionsResp => {
			this.regions = regionsResp.regions
			if (!!regionsResp.prefilledRegion) {
				this.selectedRegion = regionsResp.prefilledRegion;
			}
		});

		this.setDevice();
		if (this.requestDevice.isPinPadRequired == undefined) {
			this.requestDevice.isPinPadRequired = false;
		}
		this.address = this.request.locationAddress;
		this.selectedTimeZone = this.request.utcTimezoneShift;

		const deviceTypes$ = this.lookupService.getData('device-types', null);
		const deviceModels$ = this.lookupService.getHierarchyData('device-models', null);
		const deviceConnectionTypes$ = this.lookupService.getData('device-connection-types', {
			skip: 0,
			take: null,
			filter: { logic: 'and', filters: [{ field: 'ParentDeviceConnectionTypeId', operator: 'isnotnull' }] }
		});
		forkJoin([deviceTypes$, deviceConnectionTypes$, deviceModels$])
			.subscribe(results => {
				this.deviceTypes = results[0];
				this.deviceConnectionTypes = results[1];
				this.deviceConnectionTypesGrouped = groupBy(results[1], [{ field: 'groupName' }]);
				this.deviceConnectionTypesGroupedDropdown = this.deviceConnectionTypesGrouped;
				this.deviceModels = results[2];
				this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null).map(x => new KeyValueObject(x.id, x.name));
				this.fillSupplierDevices(this.requestDevice.deviceSupplierId);
			});
	}

	private setDevice() {
		this.device = "";
		this.lookupService.getData("device-connection-types", {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ParentDeviceConnectionTypeId", operator: "isnotnull" }] }
		}).subscribe(data => {
			this.device += !!this.requestDevice.deviceTypeName ? this.requestDevice.deviceTypeName : '';
			this.device += !!this.requestDevice.deviceSupplierName ? ' ' + this.requestDevice.deviceSupplierName : '';
			this.deviceConnectionType = !!this.requestDevice.deviceConnectionTypeId
				? data.some(ct => ct.id === this.requestDevice.deviceConnectionTypeId)
					? data.find(ct => ct.id === this.requestDevice.deviceConnectionTypeId).name
					: null
				: null;
			this.device += !!this.deviceConnectionType ? ' ' + this.deviceConnectionType : '';
			this.device += !!this.requestDevice.deviceModelName ? ' ' + this.requestDevice.deviceModelName : '';
		});
	}


	public shouldBeDisabled(): boolean {
		// здесь может быть сложная логика, включая проверку на true, false, undefined, etc.
		return !(this.requestDevice.requestDeviceId == null || this.requestDevice.requestDeviceId == undefined);
	}
	private getConfiguration() {
		this.tmsGateDataService.getTmsListFromTms(this.request.customerContragentId, null, this.requestDevice.deviceSupplierName).subscribe(tmsListResp => {
			this.tmsList = tmsListResp.tms;
			if (!!tmsListResp.prefilledTMSId) {
				this.selectedTms = this.tmsList.find(tms => tms.id === tmsListResp.prefilledTMSId);
			}
			this.fillTemplatesAndParams();
		});
	}

	public deviceSupplierChange(deviceSupplierId: number, dataItem: NewRequestDevice): void {
		dataItem.deviceModelId = null;
		dataItem.deviceModelName = null;
		const selectedSupplier = this.deviceSuppliers.find(supplier => supplier.id === deviceSupplierId);
		dataItem.deviceSupplierName = selectedSupplier ? selectedSupplier.name : null;
		this.getConfiguration();
		this.fillSupplierDevices(deviceSupplierId);
		this.setDevice();
	}

	public deviceModelChange(deviceModelId: number, dataItem: NewRequestDevice): void {
		const selectedSupplier = this.supplierDevices.find(supplier => supplier.id === deviceModelId);
		dataItem.deviceModelName = selectedSupplier ? selectedSupplier.name : null;
		this.setDevice();
	}

	public fillTemplatesAndParams() {
		this.loadTemplates();

		this.tmsGateDataService.getParamsFromTms(this.selectedTms.id).subscribe(tmsParamsResp => {
			this.tmsParams = [];
			tmsParamsResp.forEach(x => this.tmsParams.push(x));

			if (this.request.requestTypeId == RequestType.service && this.request.tidNumbers.length > 0) {
				this.requestConfigurationService.getRequestConfigurationByTid(this.request.tidNumbers[0]).subscribe(sdParamsResp => {
					this.setParams(sdParamsResp);
					if (this.params.length == 0) {
						this.requestConfigurationService.getRequestConfiguration(this.request.requestId).subscribe(sdParamsResp => {
							this.setParams(sdParamsResp);
						});
					}
				});
			}
			else {
				this.requestConfigurationService.getRequestConfiguration(this.request.requestId).subscribe(sdParamsResp => {
					this.setParams(sdParamsResp);
				});
			}
		});
	}

	private setParams(sdParamsResp: any) {
		this.params = [];
		let obj = Object.entries(sdParamsResp);
		for (const [key, value] of obj) {
			if (key.toLowerCase() == this.optionalLegalNameParam.toLowerCase())
				this.legalName = !!value ? value as string : this.request.merchantContragentName;
			if (this.tmsParams.some(tp => tp.name === key)) {
				let tmsParam = this.tmsParams.find(tp => tp.name === key);
				if (value != null) {
					tmsParam.value = (value as string).toLowerCase() === 'true';
				} else {
					tmsParam.value = false;
				}
				this.params.push(tmsParam);
			}
		}
		this.cdr.detectChanges();
	}

	public ikrChanged() {
		this.selectedTemplate = null;
		this.loadTemplates();
	}

	loadTemplates() {
		this.templateList = [];
		if (this.ikr == true) {
			this.tmsGateDataService.getTemplatesFromTms(this.selectedTms.id).subscribe(tmsTemplatesResp => {
				this.templateList = tmsTemplatesResp.map(template => {
					return new KeyValueObject(template.templateId, template.templateName);
				});
				// TODO: этот if - Хардкод LTER-3974 чтобы обойти маппинг
				if (this.selectedTms.id == 3) {
					this.templateList = this.templateList.filter(t => t.name.toLowerCase().startsWith('0v37'));
				}
				else {
					this.templateList = this.templateList.filter(t => t.name.toLowerCase().includes(this.requestDevice.deviceModelName.toLowerCase()));
				}
				this.templateListLoaded = true;
			});
		}
	}

	cancel() {
		this.closeDialog();
	}

	continue() {
		this.appService.MarkFormDirty(this.entityForm);
		if (this.entityForm.valid) {
			this.dataSaving = true;
			this.tidsService.getTerminalsInDatabaseFromTms(this.request.tidNumbers[0]).subscribe(result => {
				if (result.error) {
					this.notificationService.confirmation({
						title: 'Ошибка подготовки конфигурации',
						message: result.message,
						confirmButtonText: 'Ок',
						showCancelButton: false
					});
					this.dataSaving = false;
				}
				else {
					let params: any = {};
					this.params.forEach(p => {
						params[p.name] = p.value;
					})

					let tid = !!this.request.acceptedTidNumbers
						? this.request.acceptedTidNumbers.length > 0
							? !!this.request.acceptedTidNumbers[0]
								? this.request.acceptedTidNumbers[0].name
								: null
							: null
						: null;

					let selectedTms = this.selectedTms.name;

					let templateName = !!this.selectedTemplate ? !!this.selectedTemplate.name ? this.selectedTemplate.name : null : null;
					let templateId = !!this.selectedTemplate ? !!this.selectedTemplate.id ? this.selectedTemplate.id : null : null;

					let model: AddOrUpdateConfigurationModel = {
						tmsId: this.selectedTms.id,
						isPinPadRequired: this.requestDevice.isPinPadRequired,
						contragent: this.request.customerContragentName,
						requestId: this.request.requestId,
						requestType: this.request.requestTypeId,
						legalName: this.legalName,
						spotAddress: this.address,
						region: this.selectedRegion,
						model: this.requestDevice.deviceModelName,
						deviceType: this.requestDevice.deviceTypeId,
						tid: tid,
						mid: this.request.mid,
						vendor: this.requestDevice.deviceSupplierName,
						connectionType: this.deviceConnectionType,
						ikr: this.ikr,
						targetTMS: selectedTms,
						template: templateName,
						templateId: templateId,
						timeZone: this.selectedTimeZone,
						bankAcquirerId: this.request.customerContragentId,
						params: params
					}

					this.onContinueEvent.emit(model);

					this.dataSaving = false;
					this.closeDialog();
				}
			});
		}
	}

	closeDialog() {
		if (this.dialog)
			this.dialog.close();
	}

	public handleConnectionTypeFilter(value: any) {
		let filtered = this.deviceConnectionTypes.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
		this.deviceConnectionTypesGroupedDropdown = groupBy(filtered, [{ field: 'groupName' }]);
	}

	fillSupplierDevices(deviceSupplierId: number) {
		this.supplierDevices = deviceSupplierId == null ? [] : this.deviceModels.filter(x => x.parentId === deviceSupplierId);
	}
}
