import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { ContragentsService } from '../contragents.service';
import { Contragent } from '../../../shared/models/contragent/Contragent';
import {CategoryType} from "../../../shared/enums/category-type.enum";
import {LookupService} from "../../../shared/services/lookup.service";
import {KeyValueObject} from "../../../shared/models/core/KeyValueObject";
import {CellClickEvent, RowArgs} from "@progress/kendo-angular-grid";
import {ContragentSc} from "../../../shared/models/contragent/contragent-sc";
import {UserGroup} from "../../../shared/models/user-group/user-group";
import {ContragentScUserGroup} from "../../../shared/models/contragent/contragent-sc-user-group";
import {UserGroupsService} from "../../../shared/services/user-groups.service";
import {NotificationType} from "../../../core/services/notification-type";
import {ContragentContact} from "../../../shared/models/contragent/contragent-contact";
import {ContragentContactsService} from "../../../shared/services/contragent-contacts.service";
import {ContragentPolygonComponent} from "./components/polygon/contragent-polygon.component";
import {getDate} from "@progress/kendo-date-math";
import {EntityViewModel} from "../../../shared/models/core/EntityViewModel";
import { ServiceCentersService } from '../../../shared/services/service-centers.service';
import { UpdateServiceCenterCustomCommand } from '../../../shared/models/request/update-service-center-custom/update-service-center-custom-command';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'categories-contragent',
  templateUrl: './contragent.page.html',
	styleUrls: [
    './contragent.page.scss',
    '../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
  ]
})
export class ContragentPage extends ComponentPageBase<Contragent> implements OnInit {

	@ViewChild("contragentPolygon") contragentPolygon: ContragentPolygonComponent;

  	headerName = "Основное";
	activeIdString: any = "main-tab";
	categoryTypeId = <number>CategoryType.contragents;

	isNew: boolean = false;
	canEditMainInfo: boolean = false;
	canEditUserGroups: boolean = false;
	canEditContacts: boolean = false;
	userGroupsDataLoading: boolean = false;
	contactsDataLoading: boolean = false;
	isUsedUserGroupsList: boolean = false;

	public dropDownVirtualSettings: any = {
		itemHeight: 28,
	};

	contragentTypeId: number;
	projects: KeyValueObject[] = [];
	contragentTypes: KeyValueObject[] = [];
	acquirers: KeyValueObject[] = [];
	userGroups: UserGroup[] = [];
	responsibilityAreas: KeyValueObject[] = [];
	serviceCenters: KeyValueObject[] = [];

	scUpdateInProgress: number[] = [];

	choosenServiceCenter: ContragentSc = new ContragentSc();
	visibleContacts: ContragentContact[] = [];
	selectedServiceCenter: { id?: number, name: string } = { name: 'Контрагент', id: null };

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected appService: AppService,
    protected notificationService: NotificationService,
    protected dataService: ContragentsService,
	protected serviceCentersService: ServiceCentersService,
		private userGroupService: UserGroupsService,
		private contragentContactsService: ContragentContactsService,
		private lookupService: LookupService
  ) {
    super(route, router, appService, notificationService, dataService);
    this.listPageRoutePath = 'categories/contragents';
  }

  ngOnInit() {
    this.entityId = this.route.snapshot.paramMap.get('contragentId');
		this.lookupService.getData("projects", null).subscribe(data => this.projects = data);
		this.lookupService.getData("contragents", {
			skip: 0,
			take: null,
			sort: null,
			filter: {
				logic: 'and',
				filters: [{
					field: 'contragentTypeId',
					value: 1,
					operator: 'eq'
				}]
			}
		}).subscribe(data => this.acquirers = data);
		this.lookupService.getData("contragentTypes", null).subscribe(data => {
			this.contragentTypes = data.filter(x => !x.name || (x.name && !x.name.includes('Мерчант')));
		});
		this.lookupService.getData("responsibility-areas", null).subscribe(data => this.responsibilityAreas = data);
		this.lookupService.getData('service-centers', {
			filter:{
				logic: 'or',
				filters: [{ field: 'contragentId', operator: 'eq', value: +this.entityId }]
			}
		 }).subscribe(data => this.serviceCenters = data);

		this.isNew = this.entityId === null;
		if (!this.isNew) {
			this.loadContragent();
			this.userGroupService.getAll().subscribe(data => this.userGroups = data);
    	} else {
			this.canEditMainInfo = true;
			this.entityViewModel = new EntityViewModel<Contragent>();
			this.entityViewModel.entity = new Contragent();
		}
  }

	public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

	createNewUserGroup() {
		return () => {
			return new ContragentScUserGroup();
		};
	}

	createNewContact() {
		return () => {
			return new ContragentContact();
		};
	}

	cellClickUserGroups(event: CellClickEvent) {
		if (!this.canEditUserGroups) {
			return;
		}

		if (event.columnIndex === 3)
			return;

		event.sender.editRow(event.rowIndex);

		this.isUsedUserGroupsList = false;
	}

	cellClickContacts(event: CellClickEvent) {
		if (!this.canEditContacts) {
			return;
		}

		if (event.columnIndex === 5)
			return;

		event.sender.editRow(event.rowIndex);
	}

	afterSaveChanges() {
		this.canEditMainInfo = false;
		super.afterSaveChanges();
		this.loadContragent();
	}

	afterSaveChangesChild() {
		if (this.isNew) {
			this.router.navigate([this.listPageRoutePath]);
		}
	}

	loadContragent() {
		const contragentId = this.entityId ? this.entityId : this.entityViewModel.entity.contragentId;
		this.dataService.getById(+contragentId).subscribe(vm => {
			this.entityName = `${vm.entity.name}`;
			this.dataBind(vm);

			if (vm.entity.serviceCenters.length > 0){
				this.setCoosenServiceCenter(vm.entity.serviceCenters[0]);
				this.updatePolygon();
			}

			this.visibleContacts = vm.entity.contacts.filter(x => x.serviceCenterId == null || (this.choosenServiceCenter != null && x.serviceCenterId == this.choosenServiceCenter.serviceCenterId));

			this.entityViewModel.entity.contragentDetails.map(details => {
				details.dateStart = new Date(details.dateStart);
				details.dateTimeStart = getDate(details.dateStart);
				if (details.dateEnd){
					details.dateEnd = new Date(details.dateEnd);
					details.dateTimeEnd = getDate(details.dateEnd);
				}
			});
		});
	}

	onClickChangeMainInfo(): void {
		this.canEditMainInfo = !this.canEditMainInfo;
	}

	onClickChangeUserGroups(): void {
		this.canEditUserGroups = !this.canEditUserGroups;
	}

	onClickChangeContacts(): void {
		this.canEditContacts = !this.canEditContacts;
	}

	onChangeIsActualSc(): void {
		let updateServiceCenterCustomCommand = new UpdateServiceCenterCustomCommand;
		updateServiceCenterCustomCommand.serviceCenterId = this.choosenServiceCenter.serviceCenterId;
		updateServiceCenterCustomCommand.updateIsActual = true;
		updateServiceCenterCustomCommand.isActual = !this.choosenServiceCenter.isActual;

		this.serviceCentersService.updateServiceCenterCustom(updateServiceCenterCustomCommand).subscribe();
	}

	onChangeOneRoleSC(serviceCenter : ContragentSc): void {
		let updateServiceCenterCustomCommand = new UpdateServiceCenterCustomCommand;
		updateServiceCenterCustomCommand.serviceCenterId = serviceCenter.serviceCenterId;
		updateServiceCenterCustomCommand.isOneRoleSC = serviceCenter.isOneRoleSC;

		this.switchOnSCUpdatingFlag(serviceCenter.serviceCenterId);
		this.serviceCentersService.updateServiceCenterCustom(updateServiceCenterCustomCommand).subscribe(() => 
		{
			this.switchOffSCUpdatingFlag(serviceCenter.serviceCenterId);
		});
	}

	onClickServiceCenter(event: CellClickEvent) {
		if (this.choosenServiceCenter != event.dataItem as ContragentSc) {
			this.setCoosenServiceCenter(event.dataItem);
			this.visibleContacts = this.entityViewModel.entity.contacts.filter(x => x.serviceCenterId == null || x.serviceCenterId == this.choosenServiceCenter.serviceCenterId);
			this.updatePolygon();
		}
	}

	onAddNewGroupRow(): void {
		this.isUsedUserGroupsList = false;
	}

	onUserGroupsFormFocus(event: any): void {
		if (this.isUsedUserGroupsList) {
			event.target.focus();
		} else {
			event.target.blur();
			this.isUsedUserGroupsList = true;
		}
	}

	onUserGroupChange(userGroupId: number, dataItem: ContragentScUserGroup) {
		const userGroups = this.userGroups.filter(x => x.id === userGroupId);
		if (userGroups.length > 0) {
			dataItem.userGroup.responsibleEmail = userGroups[0].responsibleEmail;
			dataItem.userGroup.users = userGroups[0].users;

			if (userGroups[0].users.some(x => x.id === userGroups[0].defaultPerformerUserId))
				dataItem.userGroup.defaultPerformerUserId = userGroups[0].defaultPerformerUserId;
			else
				dataItem.userGroup.defaultPerformerUserId = null;
		}
	}

	onCancelEditUserGroup(dataItem): void {
		const userGroup = this.userGroups.filter(x => x.id === dataItem.userGroup.id)[0];
		dataItem.userGroupId = userGroup.id;
		dataItem.userGroup = userGroup;
	}

	onChangeUserGroupEmail(event: any, dataItem: any) {
		dataItem.userGroup.responsibleEmail = event.target.value;
	}

	onClickAddUserGroups() {
		this.canEditUserGroups = true;
	}

	onClickAddContacts() {
		this.canEditContacts = true;
	}

	getGroupName(scUserGroup: ContragentScUserGroup): string {
		return scUserGroup.userGroup.name;
	}

	getPerformerUserName(userGroup: UserGroup): string {
		const users = userGroup.users.filter(x => x.id === userGroup.defaultPerformerUserId);
		return users.length > 0 ? users[0].name : '';
	}

	getEmailModel(dataItem: any): string {
		if (!dataItem.userGroup)
			dataItem['userGroup'] = new UserGroup();

		return dataItem.userGroup.responsibleEmail;
	}

	getResponsibilityAreaName(dataItem: ContragentContact): string {
		const responsibilityAreas = this.responsibilityAreas.filter(x => x.id === dataItem.responsibilityAreaId);
		return responsibilityAreas.length > 0 ? responsibilityAreas[0].name : '';
	}

	getServiceCenterName(dataItem: ContragentContact): string {

		if (dataItem.serviceCenterId == null)
			return "Контрагент";

		const serviceCenter = this.serviceCenters.filter(x => x.id === dataItem.serviceCenterId);
		return serviceCenter.length > 0 ? serviceCenter[0].name : '';
	}

	addOrUpdateUserGroup(dataItem: ContragentScUserGroup) {
		dataItem.serviceCenterId = this.choosenServiceCenter.serviceCenterId;
		this.userGroupsDataLoading = true;
		if (!dataItem.serviceCenterPerformerGroupId){
			dataItem.userGroup.id = dataItem.userGroupId;
			this.dataService.addUserGroupForContragent(this.entityViewModel.entity.contragentId, dataItem)
				.subscribe(result => {
					dataItem.serviceCenterPerformerGroupId = result.data;
					this.notificationService.success({
						title: 'Создание элемента',
						message: 'Группа ответственного создана успешно!',
						notificationType: NotificationType.Toast
					});
					this.userGroupsDataLoading = false;
				}, error => this.userGroupsDataLoading = false);
		}else{
			this.dataService.updateUserGroupForContragent(this.entityViewModel.entity.contragentId, dataItem)
				.subscribe(result => {
					this.notificationService.success({
						title: 'Обновление элемента',
						message: 'Группа ответственного обновлена успешно!',
						notificationType: NotificationType.Toast
					});
					this.userGroupsDataLoading = false;
				}, error => this.userGroupsDataLoading = false);
		}
	}

	removeUserGroup(dataItem: ContragentScUserGroup) {
		if (dataItem.serviceCenterPerformerGroupId){
			this.userGroupsDataLoading = true;
			this.dataService.removeUserGroupForContragent(this.entityViewModel.entity.contragentId, dataItem)
				.subscribe(result => {
					this.notificationService.success({
						title: 'Удаление элемента',
						message: 'Группа ответственного удалена успешно!',
						notificationType: NotificationType.Toast
					});
					this.userGroupsDataLoading = false;
				}, error => this.userGroupsDataLoading = false);
		}
	}

	isScRowSelected = (row: RowArgs) => {
		if (!this.choosenServiceCenter)
			return false;
		return row.dataItem.name === this.choosenServiceCenter.name;
	}

	removeContact(dataItem: ContragentContact) {
		this.contactsDataLoading = true;
		this.contragentContactsService.removeForContragent(dataItem.contactId.toString(), dataItem.contragentId, 'Удалено', 'Контакт удалён успешно')
			.subscribe(result => {
				this.contactsDataLoading = false;
				this.entityViewModel.entity.contacts = this.entityViewModel.entity.contacts.filter(val => val != dataItem);
			}, error => {
				this.contactsDataLoading = false;
			});
	}

	addOrUpdateContact(dataItem: ContragentContact) {
		this.contactsDataLoading = true;
		dataItem.serviceCenterId = this.selectedServiceCenter.id;
		dataItem.contragentId = this.entityViewModel.entity.contragentId;
		if (dataItem.contactId){
			this.contragentContactsService.update(dataItem, 'Обновлено успешно', false)
				.subscribe(result => {
					this.contactsDataLoading = false;
				}, error => {
					this.contactsDataLoading = false;
				});
		} else {
			this.contragentContactsService.create(dataItem)
				.subscribe(result => {
					dataItem.contactId = result.data;
					this.entityViewModel.entity.contacts.push(dataItem);
					this.contactsDataLoading = false;
				}, error => {
					this.contactsDataLoading = false;
				});
		}
	}

	private updatePolygon(): void {
		if (this.choosenServiceCenter && this.choosenServiceCenter.serviceCenterId && this.contragentPolygon) {
			this.contragentPolygon.choosenServiceCenter = new KeyValueObject(this.choosenServiceCenter.serviceCenterId, this.choosenServiceCenter.name);
			this.contragentPolygon.onServiceCenterChange(this.contragentPolygon.choosenServiceCenter);
		}
	}
	public setCoosenServiceCenter(sc: ContragentSc): void {
		this.choosenServiceCenter = sc;
		this.selectedServiceCenter = this.serviceCenters.filter(val => val.id == sc.serviceCenterId).map(val => { return { id: val.id, name: val.name } })[0];
	}

	switchOnSCUpdatingFlag(scId: number) {
		this.scUpdateInProgress.push(scId);
	}

	switchOffSCUpdatingFlag(scId: number) {
		const index = this.scUpdateInProgress.indexOf(scId, 0);
		if (index > -1) {
			this.scUpdateInProgress.splice(index, 1);
		}
	}
}