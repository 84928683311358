import { BaseObject } from "../core/BaseObject";
import { AttachmentTypeConfigurationAttachmentTypeDto } from "./attachment-type-configuration-attachment-type-dto";

export class AttachmentTypeConfigurationDto extends BaseObject {
    attachmentTypeConfigurationId: number;
    name: string;
    attachmentTypeConfigurationTypeId: number;
    requestTypeIds: number[] = [];
    clientIds: number[] = [];
    agreementIds: number[] = [];
    attachmentTypes: AttachmentTypeConfigurationAttachmentTypeDto[] = [];
    finalStateIds: number[] = [];
}
