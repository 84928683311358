import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { NotificationService } from "../../../core/services/notification.service";
import { ActionDataResult } from "../../models/core/ActionDataResult";
import { KeyValueObject } from "../../models/core/KeyValueObject";
import { DeliveryTransportCompanyDto } from "../../../requests/request-components/request-delivery/classes/delivery-transport-company-dto";

@Injectable()
export class TransportCompanyService {

    protected controllerName = 'tc';
    protected controllerVersion = 1;

    constructor(protected http: HttpClient,
        protected notificationService: NotificationService) {
    }

    protected baseUrl(): string {
        return `${environment.dogAddress}v${this.controllerVersion}/${this.controllerName}`;
    }

    public list(): Observable<DeliveryTransportCompanyDto[]> {
		return this.http.get<ActionDataResult<DeliveryTransportCompanyDto[]>>(`${this.baseUrl()}/list`)
			.pipe(tap(resp => {
				if (!resp.isSuccessful) {
                    this.notificationService.error({ title: 'Сервис доставки', message: resp.errorDescription });
				}
			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

                this.notificationService.error({ title: 'Сервис доставки', message: errorResponse.errorDescription });
			}))
            .map(m => { return m.data });
    }
}
