import { Component, Input, EventEmitter, Output, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UsersService } from '../../../admin/users/users.service';
import { AppService } from '../../../app.service';
import { NotificationType } from '../../../core/services/notification-type';
import { NotificationService } from '../../../core/services/notification.service';
import { Attachment, UploadAttachmentsModel, UploadFile } from '../../models/attachment';
import { KeyValueObject } from '../../models/core/KeyValueObject';
import { CustomerContact } from '../../models/request/customer-contact';
import { NewRequestDevice } from '../../models/request/new-request/new-request-device';
import { Request } from '../../models/request/request';
import { AttachmentsService } from '../../services/attachments.service';
import { LookupService } from '../../services/lookup.service';
import { RequestsService } from '../../services/requests.service';
import { saveAs } from 'file-saver';
import { Activity } from '../../models/activity/activity';
import { v4 as uuidv4 } from 'uuid';
import { ActivitiesService } from '../../services/activities.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ActionResult } from '../../models/core/ActionResult';
import { CustomerEnum } from '../../enums/customer.enum';
import { ActivityWorkflowTransition } from '../../models/activity-workflow/activity-workflow-transition';
import { tap, switchMap, exhaustMap } from 'rxjs/operators';
import { CommentKindEnum } from '../../enums/comment-kind.enum';
import { ActivityStatusEnum, DeviceStatus, RequestInitTypeEnum, RequestStatus, RequestType } from '../../enums';
import { WorkflowTransition } from '../../models/request/workflow-transition';
import { ProjectWorkflowTransitionType } from '../../models/request/workflow-transition-type';
import { ChangeStatusModel } from '../../models/change-status-models/change-status-model';
import { SetSerialNumbersFormDevice } from '../../models/device/set-serial-numbers-form-device';
import { ReserveResult } from '../../enums/reserve-result.enum';
import { SecurityService } from '../../../core/services/security.service';
import { TidsService } from '../../services/tids.service';
import { TidDevice } from '../../models/request/new-request/tid-device';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
	selector: 'mobile-request-card',
	templateUrl: './mobile-request-card.component.html',
	styleUrls: [
		'./mobile-request-card.component.scss'
	],
    encapsulation: ViewEncapsulation.None
})
export class MobileRequestCardComponent implements OnInit {

	@Input() request: Request = null;
	@Input() activity: Activity = null;
	@Input() workflowTransitions: ActivityWorkflowTransition[] = [];
	@Output() click = new EventEmitter<any>();

	descriptionIsHide = true;
	devicesIsHide = true;
	devicesForToIsHide = true;
	showDevicesForTo = false;
	showDevices = true;
	uninstallDevicesIsHide = true;
	workMapIsHide = true;
	documentsIsHide = true;

	showMobileDialog = false;

	requestDevices: NewRequestDevice[] = [];
	requestDevicesForTsp: TidDevice[] = [];
	requestUninstallDevices: NewRequestDevice[] = [];
	deviceConnectionTypes: KeyValueObject[] = [];
	uploadAttachments: UploadFile[] = [];

	isUploading = false;
	uploadPercentages = 0;
	selectedWorkflow: number = null;
	uploadInProgress = false;
	commentsIsHide = true;
	instructionsIsHide = true;
	
    commentsTemplate!: TemplateRef<any>;
	transition: WorkflowTransition = null;
	deviceToWarehouseAction = ProjectWorkflowTransitionType.deviceToWarehouseAction;
	serialNumbersFormDevices: SetSerialNumbersFormDevice[] = [];
	device: NewRequestDevice = null;
	devices: NewRequestDevice[] = [];
	shortDescription: string = "";

	hasDeviceFromDifferentWarehouse = false;
	differentWarehouseName;

	workflowTransitionsInProgress: number[] = [];

	etalonWorkflow = [
		{id: 1, text: 'Наряд создан'},
		{id: 25, text: 'Ожидание выдачи оборудования'},
		{id: 23, text: 'Оборудование выдано со склада'},
		{id: 24, text: 'Оборудование получено'},
		{id: 6, text: 'Прибытие на объект'},
		{id: 4, text: 'Начало работы'},
		{id: 5, text: 'Окончание работы успешно'},
	];

	constructor(
		private sanitizer: DomSanitizer,
		private usersService: UsersService,
		private notificationService: NotificationService,
		private requestService: RequestsService,
		private tisService: TidsService,
		private lookupService: LookupService,
		private appService: AppService,
		private attachmentService: AttachmentsService,
		private activityService: ActivitiesService,
		private securityService: SecurityService,
		private intl: IntlService,) { }

	get isReplace(): boolean {
		return this.request.requestTypeId == <number>RequestType.changeConfig;
	}

	ngOnInit(): void {
		switch (this.request.requestTypeId) {
			case <number>RequestType.changeConfig:
				this.requestService.getRequestDevices(this.request.requestId).subscribe(devices => {
					this.requestDevices = devices.filter(d => d.reserveResultId != ReserveResult.reserveCanceled && d.reserveResultId != ReserveResult.requestForReserveCancelling);
				});

				this.requestService.getRequestUninstallDevices(this.request.requestId).subscribe(result => {
					result = result
						.filter(x => (result.filter(z => z.serialNumber === x.serialNumber).length == 1 &&
							!result.some(z => z.serialNumber === x.serialNumber && x.deviceStatusId === <number>DeviceStatus.deInstalled)) ||
							(result.filter(z => z.serialNumber === x.serialNumber).length > 1 &&
							result.filter(z => z.serialNumber === x.serialNumber).every(z => x.deviceStatusId !== <number>DeviceStatus.deInstalled)));

					if (result.length > 0) {
						const serialNumbersFormDevices = result.map(device => new SetSerialNumbersFormDevice(device));
						const serialNumberFormDevice = serialNumbersFormDevices
							.filter(x => !x.processed
								&& x.device.deviceStatusId === <number>DeviceStatus.inRequest
								&& !serialNumbersFormDevices
									.some((z => z.device.serialNumber === x.device.serialNumber && z.device.deviceStatusId === <number>DeviceStatus.deInstalled) as any));

						this.requestUninstallDevices = serialNumberFormDevice.map(m => m.device);

						this.requestUninstallDevices.forEach(f => {
							if (!!!f.nomenclature) {
								f.nomenclature = `${f.deviceSupplierName} ${f.deviceModelName}`
							}
						});
					}
				});
				break;
			case <number>RequestType.uninstallOld:
			case <number>RequestType.uninstall:
					this.requestService.getRequestUninstallDevices(this.request.requestId).subscribe(result => {
						result = result
							.filter(x => (result.filter(z => z.serialNumber === x.serialNumber).length == 1 &&
								!result.some(z => z.serialNumber === x.serialNumber && x.deviceStatusId === <number>DeviceStatus.deInstalled)) ||
								(result.filter(z => z.serialNumber === x.serialNumber).length > 1 &&
								result.filter(z => z.serialNumber === x.serialNumber).every(z => x.deviceStatusId !== <number>DeviceStatus.deInstalled)));

						if (result.length > 0) {
							const serialNumbersFormDevices = result.map(device => new SetSerialNumbersFormDevice(device));
							const serialNumberFormDevice = serialNumbersFormDevices
								.filter(x => !x.processed
									&& x.device.deviceStatusId === <number>DeviceStatus.inRequest
									&& !serialNumbersFormDevices
										.some((z => z.device.serialNumber === x.device.serialNumber && z.device.deviceStatusId === <number>DeviceStatus.deInstalled) as any));

							this.requestDevices = serialNumberFormDevice.map(m => m.device);

							this.requestDevices.forEach(f => {
								if (!!!f.nomenclature) {
									f.nomenclature = `${f.deviceSupplierName} ${f.deviceModelName}`
								}
							});
						}
					});
				break;
		
			default:
				this.requestService.getRequestDevices(this.request.requestId).subscribe(devices => {
					this.requestDevices = devices.filter(d => 
						d.reserveResultId != ReserveResult.reserveCanceled && 
						d.reserveResultId != ReserveResult.requestForReserveCancelling &&
						d.reserveResultId != ReserveResult.returnedToWarehouse);
				});
				break;
		}

		switch (this.request.requestTypeId) {
			case <number>RequestType.changeConfig:
			case <number>RequestType.uninstallOld:
			case <number>RequestType.uninstall:
			case <number>RequestType.changeConfigCRE:
				this.tisService.getTidInstalledDevices(this.request.tidNumbers[0], this.request.merchantId).subscribe(devices => {
					this.requestDevicesForTsp = devices;
					if (devices.length > 0) {
						this.showDevicesForTo = true;
					}
				});
				break;
		}
		switch (this.request.requestTypeId) {
			case <number>RequestType.uninstallOld:
			case <number>RequestType.uninstall:
				this.showDevices = false;
				break;
		}
		this.lookupService.getData('device-connection-types', {
			skip: 0,
			take: null,
			filter: { logic: 'and', filters: [{ field: 'ParentDeviceConnectionTypeId', operator: 'isnotnull' }] }
		}).subscribe(d => {
			this.deviceConnectionTypes = d;
		});
		this.request.customerContacts.forEach(v => {
			if (v.contactPhone) {
				let len = v.contactPhone.length;
				v.maskedPhone = v.contactPhone.substring(0, len - 10) + '*******' + v.contactPhone.substring(len - 3);
			}
		});

		if (this.request.description && this.request.description.length > 120){
			this.shortDescription = this.request.description.slice(0, 120) + "...";
		} else {
			this.shortDescription = this.request.description;
		}

		setTimeout(() => {
			switch (this.request.requestTypeId) {
				case <number>RequestType.install:
				case <number>RequestType.installCRE:
				case <number>RequestType.service:
				case <number>RequestType.changeConfig:
				case <number>RequestType.changeConfigCRE:
				case <number>RequestType.uninstallOld:
				case <number>RequestType.uninstall:
				case <number>RequestType.changeConfigCRE:
					var reservedResults = [
						<number>ReserveResult.pendingIssueToEngineer,
						<number>ReserveResult.reserveRequest,
						<number>ReserveResult.booked
					];
					var diffWhDevice = this.requestDevices.find(x => x.warehouseMoveFromId 
						&& x.warehouseMoveFromId != this.request.defaultScWarehouseId 
						&& reservedResults.includes(x.reserveResultId));
					if (diffWhDevice){
							this.hasDeviceFromDifferentWarehouse = true;
							this.differentWarehouseName = diffWhDevice.warehouseMoveFromName;
					}
			}
		}, 2500);	
	}

	onclick() {
		this.click.emit();
	}

	public get customerContact(): CustomerContact {
		if (this.request && this.request.customerContacts && this.request.customerContacts.length > 0) {
			if (this.request.customerContacts.length > 0) {
				return this.request.customerContacts[this.request.customerContacts.length - 1];
			} else {
				return this.request.customerContacts[0];
			}
		}
		return null;
	}

	public get customerContacts(): CustomerContact[] {
		return this.request.customerContacts;
	}

	public columnColor(statusColorCode: string, slaColorCode: string): SafeStyle {
		if (slaColorCode) {
			return this.sanitizer.bypassSecurityTrustStyle(slaColorCode);
		}

		return this.sanitizer.bypassSecurityTrustStyle(statusColorCode);
	}

	public isVtb() {
		return this.request.customerContragentId == CustomerEnum.vtb;
	}

	public isAlpha() {
		return this.request.customerContragentId == CustomerEnum.alpha ||
		this.request.customerContragentId == CustomerEnum.alphaAlliance;
	}

	get onMobileNewActionWorkflowTransitions(): WorkflowTransition[] {
		return this.request.workflowTransitions.filter(x => x.requestStatusToId === <number>RequestStatus.acceptanceDeviceByStorekeeper);
	}

	public slaTime() {
		return this.request.hoursToDeadlineString;
	}


	public slaType() {
		var dateSla = new Date(this.request.slaDueDateLocal).getTime();
		var dateNow = new Date().getTime();

		var hours = (dateSla - dateNow) / 36e5;

		if(hours <= 0) return 'black';
		else if(hours <= 2) return 'red';
		else if(hours <= 4) return 'yellow';
		else return 'green';
	}

	getNameById(data: KeyValueObject[], id: number): string {
		return this.appService.getNameById(data, id);
	}

	callToTsp(сustomerContact: CustomerContact){

		this.usersService.callToTsp(сustomerContact).subscribe(x => {

			if (x.isSuccessful) {
				
				this.notificationService.success({
					title: '',
					message: `Устанавливается соединение... Ожидайте входящего звонка`,
					notificationType: NotificationType.SweetAlert
				});

			}
			
		});

	}

	
	downloadFile(attachment: Attachment) {
		this.attachmentService.getAttachmentById(attachment.attachmentId).subscribe(blob => {
			saveAs(blob, attachment.attachmentName, { type: 'application/octet-stream' });
		});
	}

	onAttachmentsAdded(uploadFiles: UploadFile[]) {
		this.uploadAttachments = uploadFiles;
	}

	// public mapsUrl() {
	// 	if(this.request.customerObjectLatitude && this.request.customerObjectLongitude)
	// 		return `https://yandex.ru/maps/?text=${this.request.customerObjectLatitude},${this.request.customerObjectLongitude}`;
	// 	else 
	// 		return null;
	// }

	handleFileInput(files: FileList) {
		for(let i = 0; i < files.length; i++) {
			let file = files[0]; 
			const reader = new FileReader();
			const self = this;

			reader.onload = function (readerEvt: any) {
				const binaryString = <string>readerEvt.target.result;
				const uid = uuidv4();
				const attachmentFile = new UploadFile();
				attachmentFile.uid = uid;
				attachmentFile.fileName = file.name;
				attachmentFile.uid = uid;
				attachmentFile.fileData = btoa(binaryString);

				self.uploadAttachments.push(attachmentFile);

				var fileData = `data:image/png;base64,${attachmentFile.fileData}`;

				// self.images.push({
				// 	title: attachmentFile.fileName,
				// 	altText: uid,
				// 	url: fileData,
				// 	thumbnailUrl: fileData,
				// });
			};

			reader.readAsBinaryString(file);
		}
	}

	cancel() {
		this.uploadAttachments = [];
	}

	upload() {
		this.isUploading = true;
		this.requestService.addComment({
			requestId: this.request.requestId,
			activityId: this.activity.activityId,
			text: "Добавил файл",
			uploadAttachments: this.uploadAttachments,
			commentKind: CommentKindEnum.internal,
			notifyCustomer: false,
			notifyEngineer: false,
			engineerNotifyWays: []
		}).subscribe(x => {
			if (x instanceof HttpResponse && x.type === HttpEventType.Response) {
				var result = <ActionResult>x.body;

			
				if (!result.isSuccessful) {
					this.isUploading = false;
					return;
				}

				this.activityService.getById(this.activity.activityId).subscribe(d => {
					this.activity = d.entity;
					this.isUploading = false;
					this.uploadAttachments = [];
				})
			}
		});
	}

	
	public mapsUrl() {
		if(this.request.customerObjectLatitude && this.request.customerObjectLongitude)
			return `https://yandex.ru/maps/?text=${this.request.customerObjectLatitude},${this.request.customerObjectLongitude}`;
		else 
			return null;
	}

	copyToClp(txt, message = ""){
		var m = document;
		txt = m.createTextNode(txt);
		var w = window;
		var b = m.body;
		b.appendChild(txt);
		var d = m.createRange();
		var g = w.getSelection;
		d.selectNodeContents(txt);
		g().removeAllRanges();
		g().addRange(d);
		m.execCommand('copy');
		g().removeAllRanges();
		txt.remove();

		this.notificationService.success({
			title: 'Успех',
			message: message,
			notificationType: NotificationType.Toast
		});
	}

	public futures(from: number) {
		var index = this.etalonWorkflow.map(p => p.id).indexOf(from);

		return this.etalonWorkflow.slice(index + 1);
	}

	public get isEtalon() {
		return this.etalonWorkflow.map(p => p.id).includes(this.activity.statusId);
	}

	public onSelectWorkflow() {
		
	}
	
	public onActivityAttachmentsAdded(attachments: UploadFile[]) {
		const uploadModel = new UploadAttachmentsModel({ requestId: this.request.requestId, activityId: this.activity.activityId, attachments });

		this.attachmentService.uploadWithResumableIfMobile(uploadModel)
			.pipe(tap(x => {
				switch (x.type) {
					case HttpEventType.UploadProgress:
						this.uploadPercentages = Math.round(x.loaded / (x.total != null ? x.total : 0) * 100);
	
						if (this.uploadPercentages == 100)
							this.uploadPercentages = null;
				}
			}))
			.pipe(exhaustMap(() => this.activityService.getById(this.activity.activityId)))
			.subscribe(x => {
				this.activity = x.entity;
				this.uploadInProgress = false;
			});
	}

	switchOnTranslationFlag(transitionId: number) {
		this.workflowTransitionsInProgress.push(transitionId);
	}

	switchOffTranslationFlag(transitionId: number) {
		const index = this.workflowTransitionsInProgress.indexOf(transitionId, 0);
		if (index > -1) {
			this.workflowTransitionsInProgress.splice(index, 1);
		}
	}

	public workflowTransitionClick(transition: WorkflowTransition) {
		switch (transition.projectWorkflowTransitionType) {
			case ProjectWorkflowTransitionType.deviceToWarehouseAction:
				this.requestService.getRequestUninstallDevices(this.request.requestId)
				.subscribe(result => {
					this.serialNumbersFormDevices = result
						.filter(x => x.deviceStatusId === <number>DeviceStatus.deInstalled)
						.map(device => new SetSerialNumbersFormDevice(device));

					const serialNumberFormDevice = this.serialNumbersFormDevices.filter(x => !x.processed);
					this.device = serialNumberFormDevice.length > 0 ? serialNumberFormDevice[0].device : null;
					this.devices = serialNumberFormDevice.length > 0 ? serialNumberFormDevice.map(m => m.device) : [];
					
					this.transition = transition;
				});
				break;
		}
	}

	changeStatusCoreMobile() {
		
		this.switchOnTranslationFlag(this.transition.transitionId);

		let model = new ChangeStatusModel(this.request.requestId, this.transition.requestStatusToId);
		model.transitionId = this.transition.transitionId;

		this.requestService
			.changeStatus(model)
			.subscribe(x => {

				this.switchOffTranslationFlag(this.transition.transitionId);

				if (x.isSuccessful) {
					this.requestService.getById(this.request.requestId).subscribe(vm => {
						this.request = vm.entity;
						this.transition = null;
					});
					
				}

			}, () => {
				this.switchOffTranslationFlag(this.transition.transitionId);
			});
	}

	get showWarehouseForm(): boolean {
		return !!this.transition 
			&& this.transition.projectWorkflowTransitionType === ProjectWorkflowTransitionType.receivingDeviceAtWarehouseAction && this.request.initTypeId !== <number>RequestInitTypeEnum.blockChain;
	}

	get isUninstallWorkSucceeded(): boolean {
		return (this.request.requestTypeId == RequestType.uninstall && this.activity.statusId == ActivityStatusEnum.workEnded)
			|| (this.request.requestTypeId == RequestType.uninstallOld && this.activity.statusId == ActivityStatusEnum.workEnded)
			|| (this.request.requestTypeId == RequestType.changeConfig && this.activity.statusId == ActivityStatusEnum.workEnded)
			|| (this.request.requestTypeId == RequestType.service && this.activity.statusId == ActivityStatusEnum.workEnded);
	}

	get isPhoneVisibileInMobileVersion(): boolean {
		return this.securityService.isPhoneVisibileInMobileVersion();
	}

	openMobileDialog(){
		this.showMobileDialog = true;
	}
	closeMobileDialog(){
		this.showMobileDialog = false;
	}

	
	parseMultiTimezoneDate(dateTime: any) {

		if (dateTime == null) {
			return '';
		}

		var parsedDate = new Date();

		if (dateTime instanceof Date) {
			parsedDate = dateTime;
		} else if (typeof dateTime === 'string') {
			if (dateTime.startsWith("1753")) {
				return '';
			}

			parsedDate = this.intl.parseDate(dateTime);
		}

		return `${this.intl.formatDate(parsedDate, 'dd.MM.yyyy HH:mm')} ${this.request.utcTimezoneShiftString}`;
	}
}
function MobileRequestCardTemplateDirective(MobileRequestCardTemplateDirective: any) {
	throw new Error('Function not implemented.');
}

