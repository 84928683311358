import { OnInit } from "@angular/core";
import { addDays } from '@progress/kendo-date-math';

export class ReportPageBase implements OnInit {

	public range = { start: new Date(), end: new Date() };
	public dataGenerating = false;

	constructor() {

	}

	ngOnInit(): void {
		
	}

	protected generateReportByDateRange(getReport: (startDate: string, endDate: string) => void) {

		this.dataGenerating = true;

		let startDate, endDate;
		if (this.range.start) {
			this.range.start.setHours(0, 0, 0, 0);
			startDate = this.getDate(this.range.start);
		}

		if (this.range.end) {
			this.range.end.setHours(0, 0, 0, 0);

			let dateEnd = new Date(this.range.end);
			dateEnd = addDays(dateEnd, 1);
			
			endDate = this.getDate(dateEnd);
		}

		getReport(startDate, endDate);
	}

	protected getDate(date) {
		return new Date(date).toJSON();
	}

}
