export enum AttachmentSpot {
    /**Указание ТК для отправки по перемещению МК */
    approvalRsShiped = 1,

    /**Указание с/н на получение по перемещению МК */
    rsReceived = 2,

    /**Принятие оборудования при пополнении */
    warehouseRefillAcceptance = 3,
}
