import { Component, Input, ElementRef, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppService } from '../../../app.service';
import { RequestsService } from '../../services/requests.service';
import { CompositeFilterDescriptor, DataSourceRequestState } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { Call } from '../../models/call/call';
import { ListPageBase } from '../list-page-base/list-page-base';
import { GridSettings } from '../../models/grid/grid-settings';
import { StatePersistingService } from '../../services/state-persisting.service';
import { CallsService } from '../../services/calls.service';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { KeyValueObject } from '../../models/core/KeyValueObject';
import { LookupService } from '../../services/lookup.service';
import { ReportsService } from '../../services/reports.service';
import { ExportExcelFromGridService } from '../../services/export-excel-from-grid.service';
import { CallStatus } from '../../enums/call-status.enum';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
    selector: 'calls',
    templateUrl: './calls.component.html',
    styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./calls.component.scss'
	]
})
export class CallsComponent extends ListPageBase<Call> implements AfterViewInit {

	@Input()
	requestId?: number;

	@Input()
	showToolBar: boolean = false;
	
	borderCallsAmount = 100000;

	public exportInProgress: boolean = false;

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'callDate', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'callDate',
				title: 'Дата',
				excelType: 'string',
				filterable: true,
				width: 250,
				hidden: false,
			},
			{
				orderIndex: 1,
				field: 'callerPhoneNumber',
				title: 'Инициатор',
				excelType: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'callerUserName',
				title: 'Фамилия инициатора',
				excelType: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'calleePhoneNumber',
				title: 'Адресат',
				excelType: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'calleeUserName',
				title: 'Фамилия адресата',
				excelType: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'requestIdStr',
				title: '# заявки',
				excelType: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'requestExternalId',
				title: '# заявки заказчика',
				excelType: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'requestTypeId',
				title: 'Тип заявки',
				excelType: 'numeric',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'status',
				title: 'Статус звонка',
				excelType: 'numeric',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 9,
				field: 'sessionDuration',
				title: 'Длительность сессии (сек)',
				excelType: 'numeric',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 10,
				field: 'callDuration',
				title: 'Длительность разговора (сек)',
				excelType: 'numeric',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 11,
				field: 'requestTidNumbers',
				title: 'Tid',
				excelType: 'string',
				filterable: true,
				width: 250,
				hidden: false
			}
		]
	}

	@ViewChild('filterAllInput') filterAllInput: ElementRef;

	loadRecordsInProgress: number[] = [];
	playingRecords: number[] = [];
	recordToPlayUri: string;
	recordToPlay: any;
	requestTypes: KeyValueObject[] = [];
	callStatuses: KeyValueObject[] = [
		new KeyValueObject(8, "Успешно"),
		new KeyValueObject(-8, "Неуспешно")
	];

	constructor(
		appService: AppService,
		router: Router,
		private sanitizer: DomSanitizer,
		private lookupService: LookupService,
		protected dataService: CallsService,
		private requestsService: RequestsService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService,
		protected notificationService: NotificationService,)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
	}

	onListPageInit(): void {

		this.lookupService.getData("request-types", null).subscribe(data => this.requestTypes = data);

		this.gridHeight = window.innerHeight - 108;		
	}

	public loadData(state: DataSourceRequestState = this.gridSettings.state): void {
		this.loading = true;

		var listSubscription = this.requestId == null
								? this.dataService.list(state)
								: this.requestsService.getRequestCalls(this.requestId, state);

		listSubscription.subscribe(lvm => {
			this.processListItems(lvm.data);
			this.listViewModel = lvm;
			this.loading = false;
		});
	}

	ngAfterViewInit(): void {

		// setTimeout(function(){
		// 	fromEvent(this.filterAllInput.nativeElement, 'keyup')
		// 	.pipe(
		// 		map((evt: any) => evt.target.value),
		// 		debounceTime(500),
		// 		distinctUntilChanged(),
		// 	).subscribe((text: string) => this.onFilter(text));
		// }, 1500);
		
	}

	processListItems(items: Call[]): void { }

	public onFilter(inputValue: string): void {

		var customState: DataSourceRequestState = {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: "or",
				filters: [
					{
						field: 'callerPhoneNumber',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'calleePhoneNumber',
						operator: 'contains',
						value: inputValue
					}
				],
			}
		};

		this.loadData(customState);
	}

	getDateCellText(value: Date): string {
		return this.appService.getDateCellText(value, false);
	}

	loadRecord(call: Call){

		this.playingRecords = [];
		this.loadRecordsInProgress.push(call.callId);

		this.dataService.loadRecord(call.callId).subscribe(data => {
			const dataURI = `data:audio/mp3;base64,${data.audioBase64}`;
			//saveAs(dataURI, "record.wav");

			this.recordToPlayUri = dataURI;
			this.recordToPlay = this.sanitizer.bypassSecurityTrustUrl(dataURI);
			this.playingRecords.push(call.callId);

			const index = this.loadRecordsInProgress.indexOf(call.callId, 0);
			if (index > -1) {
				this.loadRecordsInProgress.splice(index, 1);
			}
		});

	}

	public generateReport(): void {
		if(this.listViewModel.total > this.borderCallsAmount){
			this.notificationService.confirmation({
				title: "Большое количество записей",
				message: `Вы уверены что хотите загрузить ${this.listViewModel.total} записей?`,
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: true
			}, () => {
				this.exportInProgress = true;
				this.getXlsx('calls', `Звонки.xlsx`,
				(c) => {},
				() => {
					this.exportInProgress = false;
				}, true);
			});
		} else {
			this.exportInProgress = true;
			this.getXlsx('calls', `Звонки.xlsx`,
			(c) => {},
			() => {
				this.exportInProgress = false;
			}, true);
		}
	}

	get disableGenerateReport(){
		return !this.listViewModel.total || this.loading;
	}

	downloadRecord(){
		saveAs(this.recordToPlayUri, "record.mp3");
	}

	isSuccessCall(status: number): boolean {
		return status === CallStatus.Answered;
	}
}
