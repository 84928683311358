import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { SerialNumber } from '../../../shared/models/device/serial-numbers-1C/serial-number';
import { SerialNumbersDevice } from '../../../shared/models/device/serial-numbers-1C/serial-numbers-device';
import { SerialNumbersFormDevice } from '../../../shared/models/device/serial-numbers-1C/serial-numbers-form-device';
import { SerialNumbersFormPart } from '../../../shared/models/device/serial-numbers-1C/serial-numbers-form-part';
import { SerialNumbersStoreData } from '../../../shared/models/device/serial-numbers-1C/serial-numbers-store-data';
import { ReturnedDevice } from '../../../shared/models/device/returned-device';
import { RequestInitTypeEnum } from '../../../shared/enums';

@Component({
	selector: 'serial-numbers-validation-form',
	templateUrl: './serial-numbers-validation-form.html',
	styleUrls: [
		'./serial-numbers-validation-form.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class SerialNumbersValidationForm implements OnInit {

	@Input()
	possibleWarehouseCells: string[] = [];

	@Input()
	formDevices: SerialNumbersFormDevice[] = [];

	@Input()
	formParts: SerialNumbersFormPart[] = [];

	@Input()
	initTypeId: number;

	@Output()
	onIssueValidatedDevices = new EventEmitter<any>();

	showWarehouseCells: boolean = false;
	showWarehouseCellsForDeviceReturn: boolean = false;
	loading: boolean = false;

	onAcceptBtnContent: string;

	choosenCells: ReturnedDevice[] = [];

	constructor(
		private dialog: DialogRef
	) {

	}

	ngOnInit(): void {

	}

	validateDeviceSerialNumber(formDevice: SerialNumbersFormDevice, event: any): void {
		formDevice.inputValue = event.target.value;
		formDevice.isValidSerialNumber = formDevice.device.serialNumbers.some((x => x.sn === formDevice.inputValue) as any);
		formDevice.errorMessage = formDevice.isValidSerialNumber ? null : 'Не валидный серийный номер';
	}

	onChangeCellForReturnedDevice(cellCode: any, formDevice: SerialNumbersFormDevice, formPart: SerialNumbersFormPart): void {
		this.validateCellCode(cellCode, formDevice, formPart);

		const deviceId = formDevice
			? formDevice.device.requestDeviceId
			: formPart
				? formPart.part.requestDeviceId
				: null;

		if (deviceId) {
			const devices = this.choosenCells.filter(x => x.requestDeviceId === deviceId);
			if (devices.length > 0) {
				devices[0].warehouseCell = cellCode;
			} else {
				this.choosenCells.push({
					requestDeviceId: deviceId,
					warehouseCell: cellCode,
					isPart: formPart ? true : false
				} as ReturnedDevice);
			}
		}

	}

	validateCellCode(cellCode: any, formDevice: SerialNumbersFormDevice, formPart: SerialNumbersFormPart) {
		if (this.initTypeId != <number>RequestInitTypeEnum.blockChain) {
			if (cellCode === "Выберите ячейку" || cellCode === "") {
				cellCode = null;
			}

			if (formDevice) {
				formDevice.cellErrorMessage = cellCode ? null : 'Ячейка не выбрана';
				formDevice.isValidCellCode = cellCode !== null;
			} else if (formPart) {
				formPart.cellErrorMessage = cellCode ? null : 'Ячейка не выбрана';
				formPart.isValidCellCode = cellCode !== null;
			}
		}
	}

	onCancel(): void {
		this.dialog.close();
	}

	onIssueDevices(): void {
		if (this.isValidForm()) {
			this.loading = true;
			if (this.onIssueValidatedDevices) {
				this.onIssueValidatedDevices.emit({
					loading: this.loading,
					formDevices: this.formDevices,
					choosenCells: this.choosenCells
				});
			}
		}
	}

	isDevicePartForm(device: SerialNumbersDevice): boolean {
		return device.deviceTypeId && device.deviceTypeId === 10;
	}

	getDeviceCellsDropdownData(serialNumbers: SerialNumber[]): any {
		const dropdownData = [];

		serialNumbers.map(serialNumber => {
			if (!dropdownData.some((cell => cell.warehouseCell === serialNumber.warehouseCell) as any)) {
				dropdownData.push({ warehouseCell: serialNumber.warehouseCell });
			}
		});

		return dropdownData;
	}

	getDeviceCellsDropdownDefaultItem(serialNumbers: SerialNumber[]): any {
		const warehouseCell = serialNumbers && serialNumbers.length > 0 ? serialNumbers[0].warehouseCell : '';
		return { warehouseCell: warehouseCell }
	}

	getPartCellsDropdownData(storeData: SerialNumbersStoreData[]): any {
		return storeData.map((data, _) => {
			return { name: `${data.warehouseCell} (${data.amount}шт.)` };
		});
	}

	getPartCellsDropdownDefaultItem(storeData: SerialNumbersStoreData[]): any {
		const warehouseCell = storeData && storeData.length > 0 ? storeData[0].warehouseCell : '';
		return { name: warehouseCell };
	}

	isValidForm(): boolean {
		let snAndCell: boolean = this.formDevices.filter(x => !this.isDevicePartForm(x.device)).every(x => x.isValidSerialNumber)
		&& this.formDevices.every(x => x.isValidCellCode);

		let sn: boolean = this.formDevices.filter(x => !this.isDevicePartForm(x.device)).every(x => x.isValidSerialNumber);

		if (this.initTypeId != <number>RequestInitTypeEnum.blockChain) {
			let check = this.showWarehouseCellsForDeviceReturn ? snAndCell : sn;
			return check;
		} else {
			return sn;
		}
	}
}
