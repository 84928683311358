import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { ServiceCategoryListItem } from "../../../shared/models/services/service-category-list-item";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";
import { ReportsService } from "../../../shared/services/reports.service";
import { ServiceCategoriesService } from "../../../shared/services/service-categories.service";
import { StatePersistingService } from "../../../shared/services/state-persisting.service";

@Component({
	selector: 'services-categories-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class ServiceCategoriesListPage extends ListPageBase<ServiceCategoryListItem> {

	constructor(
		protected appService: AppService,
		protected router: Router,
		protected dataService: ServiceCategoriesService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Категории обслуживания';
	}

	onListPageInit(): void {

	}

	processListItems(items: ServiceCategoryListItem[]): void {

	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/categories/service-category', dataItem.id]);
	}

}
