import { Component } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
	selector: 'calls-list-page',
	templateUrl: './list.page.html',
	styleUrls: [
		'./list.page.scss'
	]
})
export class CallsListPage {

	constructor(appService: AppService) {
		appService.pageTitle = 'Звонки';
	}

}