import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-kendo-dropdown-list',
  templateUrl: './kendo-dropdown-list.component.html',
  styleUrls: ['./kendo-dropdown-list.component.scss']
})
export class KendoDropdownListComponent implements OnInit {

  @Input() public label: string;
	@Input() public data: any;
	@Input() public control: FormControl;
  @Input() public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
  @Output() onChangeEvent = new EventEmitter<any>();
  @Output() onFilterEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  onChange(event: any)
  {
    this.onChangeEvent.emit(event);
  }

  handleFilter(event: any)
  {
    this.onFilterEvent.emit(event);
  }
}