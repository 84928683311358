import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { NotificationService } from "../../../core/services/notification.service";
import { ApprovalDog } from "../../../requests/request-components/request-delivery/classes/delivery-approval-dog";
import { Cost } from "../../../requests/request-components/request-delivery/classes/delivery-cost";
import { DeliveryResultDog } from "../../../requests/request-components/request-delivery/classes/delivery-result-dog";
import { DeliveryData } from "../../../requests/request-components/request-delivery/classes/delivery-data";
import { DeliveryContact, DeliveryDataDog, Package } from "../../../requests/request-components/request-delivery/classes/delivery-data-dog";
import { DeliveryTrackDog } from "../../../requests/request-components/request-delivery/classes/delivery-track-dog";
import { ActionDataResult } from "../../models/core/ActionDataResult";
import { DeliveryItem } from "../../../requests/request-components/request-delivery/classes/delivery-item";

@Injectable()
export class DeliveryService {

    protected controllerName = 'deliveries';
    protected controllerVersion = 1;

    constructor(protected http: HttpClient,
        protected notificationService: NotificationService) {
    }

    protected baseUrl(): string {
        return `${environment.dogAddress}v${this.controllerVersion}/${this.controllerName}`;
    }

    public getDelivery(externalId: string): Observable<DeliveryDataDog> {
		return this.http.get<ActionDataResult<DeliveryDataDog>>(`${this.baseUrl()}/${externalId}`)
			.pipe(tap(resp => {
				if (!resp.isSuccessful) {
                    this.notificationService.warning({ title: 'Сервис доставки', message: resp.errorDescription });
				}
			}, errorResponse => {
                this.notificationService.warning({ title: 'Сервис доставки', message: errorResponse.errorDescription });
			}))
            .map(m => { return m.data });
    }

    public calculateAndCreateIfNotExist(deliveryData: DeliveryData): Observable<Cost[]> {
        let body = this.getDeliveryDataDog(deliveryData);
        return this.http.post<ActionDataResult<Cost[]>>(`${this.baseUrl()}/get-costs/${deliveryData.currentTransportCompanyId}`, body)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: 'Сервис доставки', message: resp.errorDescription });
            }
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис доставки', message: errorResponse.errorDescription });
        }))
        .map(m => { return m.data });
    }

    public trackAndCreateIfNotExist(deliveryData: DeliveryData): Observable<DeliveryResultDog> {
        let body = this.getDeliveryDataDog(deliveryData);
        return this.http.post<ActionDataResult<DeliveryResultDog>>(`${this.baseUrl()}/user-track`, body)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: 'Сервис доставки', message: resp.errorDescription });
            }
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис доставки', message: errorResponse.errorDescription });
        }))
        .map(m => { return m.data });;
    }

    public updateDelivery(deliveryData: DeliveryData): Observable<ActionDataResult<any>> {
        let body = this.getDeliveryDataDog(deliveryData);
        return this.http.put<ActionDataResult<any>>(`${this.baseUrl()}`, body)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: 'Сервис доставки', message: resp.errorDescription });
            }
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис доставки', message: errorResponse.errorDescription });
        }));
    }

    public createDelivery(deliveryData: DeliveryData): Observable<ActionDataResult<any>> {
        let body = this.getDeliveryDataDog(deliveryData);
        return this.http.post<ActionDataResult<any>>(`${this.baseUrl()}`, body)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: 'Сервис доставки', message: resp.errorDescription });
            }
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис доставки', message: errorResponse.errorDescription });
        }));
    }

    private getDeliveryDataDog(deliveryData: DeliveryData): DeliveryDataDog {
        let sender: DeliveryContact = {
            phone: deliveryData.senderPhone,
            contactPerson: deliveryData.senderPerson,
            company: deliveryData.senderOwner ? deliveryData.senderOwner.name : null,
            fullAddress: deliveryData.senderAddress ? deliveryData.senderAddress.fullAddress : null,
            city: deliveryData.senderAddress ? deliveryData.senderAddress.city : null,
            street: deliveryData.senderAddress ? deliveryData.senderAddress.street : null,
            house: deliveryData.senderAddress ? deliveryData.senderAddress.house : null,
            latitude: deliveryData.senderAddress ? deliveryData.senderAddress.latitude : null,
            longitude: deliveryData.senderAddress ? deliveryData.senderAddress.longitude : null,
            integraCode: null,
            transportCompanyLocationCode: deliveryData.senderTransportCompanyLocationCode ? deliveryData.senderTransportCompanyLocationCode : null,
        }

        let recipient: DeliveryContact = {
            phone: deliveryData.recipientPhone,
            contactPerson: deliveryData.recipientPerson,
            company: deliveryData.recipientOwner ? deliveryData.recipientOwner.name : null,
            fullAddress: deliveryData.recipientAddress ? deliveryData.recipientAddress.fullAddress : null,
            city: deliveryData.recipientAddress ? deliveryData.recipientAddress.city : null,
            street: deliveryData.recipientAddress ? deliveryData.recipientAddress.street : null,
            house: deliveryData.recipientAddress ? deliveryData.recipientAddress.house : null,
            latitude: deliveryData.recipientAddress ? deliveryData.recipientAddress.latitude : null,
            longitude: deliveryData.recipientAddress ? deliveryData.recipientAddress.longitude : null,
            integraCode: null,
            transportCompanyLocationCode: deliveryData.recipientTransportCompanyLocationCode ? deliveryData.recipientTransportCompanyLocationCode : null,
        }

        let track: DeliveryTrackDog = {
            publicTrackNumber: deliveryData.track.publicTrackNumber,
            internalTrackNumber: deliveryData.internalTrackNumber,
            transportCompanyId: deliveryData.track.transportCompanyId,
            history: null
        }

        let body: DeliveryDataDog = {
            externalId: deliveryData.externalId,
            externalSystemTypeId: 1,
            sender: sender,
            recipient: recipient,
            packages: deliveryData.packages,
            tracks: [track],
            costs: deliveryData.costs,
            deliveryItems: deliveryData.deliveryItems,
            takingTimeFrom: deliveryData.takingTimeFrom
        };

        return body;
    }

    public setApprovalStatus(statusId: any, deliveryData: DeliveryData): Observable<ActionDataResult<any>> {
        let body: ApprovalDog = {
            status: statusId,
            coefficient: deliveryData.coefficient,
            totalCost: deliveryData.totalSum
        }

        return this.http.put<ActionDataResult<any>>(`${this.baseUrl()}/${deliveryData.currentCost.deliveryCostId}/approval-status`, body)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: 'Сервис доставки', message: resp.errorDescription });
            }
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис доставки', message: errorResponse.errorDescription });
        }));
    }

    public orderDelivery(deliveryCostId: number): Observable<DeliveryResultDog> {
        return this.http.post<ActionDataResult<DeliveryResultDog>>(`${this.baseUrl()}/create-in-tc/${deliveryCostId}`, null)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: 'Сервис доставки', message: resp.errorDescription });
            }
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис доставки', message: errorResponse.errorDescription });
        }))
        .map(m => { return m.data });
    }

    public getDeliveryHistory(trackNumber: string): Observable<DeliveryResultDog> {
        return this.http.get<ActionDataResult<DeliveryResultDog>>(`${this.baseUrl()}/status?trackNumber=${trackNumber}`)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: 'Сервис доставки', message: resp.errorDescription });
            }
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис доставки', message: errorResponse.errorDescription });
        }))
        .map(m => { return m.data });
    }

    public deleteOrder(transportCompanyId: number, externalId: string): Observable<ActionDataResult<any>> {
        return this.http.delete<ActionDataResult<any>>(`${this.baseUrl()}/delete-in-tc/${transportCompanyId}/${externalId}`)
        .pipe(tap(resp => {
            if (!resp.isSuccessful) {
                this.notificationService.warning({ title: 'Сервис доставки', message: resp.errorDescription });
            }
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис доставки', message: errorResponse.errorDescription });
        }));
    }
}
