import { Component, Input, HostBinding } from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '../../core/services/security.service';
import { MatBottomSheet } from '@angular/material';
import { CallContactsComponent } from '../../shared/components/call-contacts/call-contacts.component';
import { DialogService } from '@progress/kendo-angular-dialog';

@Component({
	selector: 'app-layout-navbar',
	templateUrl: './layout-navbar.component.html',
	styleUrls: ['./layout-navbar.component.scss']
})
export class LayoutNavbarComponent {
	contragentContactsButtomSheetVisibility: boolean = false;
	isExpanded = false;
	isRTL: boolean;

	get userName(): string {
		return this.securityService.currentUser.userName;
	}

	@Input() sidenavToggle = true;

	@HostBinding('class.layout-navbar') private hostClassMain = true;

	constructor(
		private appService: AppService,
		public layoutService: LayoutService,
		private securityService: SecurityService,
		private bottomSheet: MatBottomSheet,
		private router: Router,
		protected dialogService: DialogService,
		protected route: ActivatedRoute) {
			this.isRTL = appService.isRTL;
	}

	currentBg() {
		return `bg-${this.appService.layoutNavbarBg}`;
	}

	toggleSidenav() {
		this.layoutService.toggleCollapsed();
	}

	get windowWidth(): number{
		return window.innerWidth;
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	public get isEngineer() {
		return this.securityService.isEngineer();
	}

	logout(){
		this.securityService.logout();
		this.router.navigateByUrl('login');
	}

	openContragentContactsButtomSheet() {
		this.contragentContactsButtomSheetVisibility = true;

		const dialogRef = this.dialogService.open({ content: CallContactsComponent, width: '100%', height: '75%' });
		const bottomSheetRef = <CallContactsComponent>dialogRef.content.instance;

		// let kek = this.route.snapshot.paramMap.get('requestId');
		// const bottomSheetRef = this.bottomSheet.open(CallContactsComponent, {
		// 	// data: { isFromRequest: true,
		// 	// 	requestIdStr: this.route.snapshot.paramMap.get('requestId')
		// 	// },
		//   });

		// bottomSheetRef.afterOpened().subscribe(() => {
		// 	this.contragentContactsButtomSheetVisibility = true;
		// });

		// bottomSheetRef.afterDismissed().subscribe(() => {
		// 	this.contragentContactsButtomSheetVisibility = false;
		// });
	}
}
