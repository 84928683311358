import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityService } from './services/security.service';
import { HttpInterceptorModule } from './modules/http-interceptor.module';
import { HttpUploadInterceptorModule } from './modules/upload.module';
import { DataService } from './services/data.service';
import swal from 'sweetalert2';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { NotificationService } from './services/notification.service';
import { KendoDataSourceHelper } from './helpers/kendo-data-source-helper';
import { TemplateEngineService } from './services/template-engine.service';

@NgModule({
	imports: [
		CommonModule,
		//HttpInterceptorModule,
		HttpUploadInterceptorModule,
		SweetAlert2Module,
	],
	declarations: [

	],
	providers: [
		SecurityService,
		DataService,
		NotificationService,
		KendoDataSourceHelper,
		TemplateEngineService,
		{ provide: 'sweetAlert', useValue: swal },
	],
	exports: [HttpInterceptorModule, HttpUploadInterceptorModule]
})
export class CoreModule { }
