import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[noEmptyStrings]'
})
export class NoEmptyStringsDirective {
    private _noEmptyStrings: boolean = true;

    @Input()
    get noEmptyStrings(): boolean {
        return this._noEmptyStrings;
    }
    set noEmptyStrings(value: boolean) {
        this._noEmptyStrings = "" + value !== "false";
    }

    constructor(private _elementRef: ElementRef, private _ctrl: NgControl) { }

    @HostListener('keyup.enter')
    public onEnterUp(): void {
        if (this.noEmptyStrings && this._elementRef.nativeElement.tagName=='TEXTAREA') {
            this._ctrl.control.setValue(<string>(this._ctrl.control.value).replace(/^[\r\n]/gm, ""));
        }
    }
}