import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DeviceTypeEnum } from "../../../../../shared/enums/device-type.enum";
import { KeyValueObject } from "../../../../../shared/models/core/KeyValueObject";
import { ExpertiseActDevice } from "../../../../../shared/models/expertise-act/expertise-act-device";
import { ExpertiseActSetting } from "../../../../../shared/models/expertise-act/expertise-act-setting";

@Component({
	selector: 'expertise-form-multiselect',
	templateUrl: './expertise-form-multiselect-list.component.html',
	styleUrls: []
})
export class ExpertiseFormMultiselectList implements  OnInit {

	@Input() tabName: string;
	@Input() formData: ExpertiseActSetting;
	@Input() device: ExpertiseActDevice;
	@Input() selectFormFilledData: any;

	@Output() onChange = new EventEmitter<any>();

	selectedItems = [];
	listData: KeyValueObject[] = [];

	costructor() {

	}

	ngOnInit(): void {
		this.listData = this.formData.options
			.map(option => { return { id: option.optionId, name: option.value } as KeyValueObject; });

		this.selectedItems = this.selectFormFilledData[this.tabName][this.formData.name];
		if (this.selectedItems) {
			if (!isNaN(this.selectedItems as any) && !Array.isArray(this.selectedItems)) {
				this.selectedItems = [this.selectedItems];
			}

			return;
		} else {
			this.selectedItems = [];
		}

		const filledForms = this.device.filledForms.filter(x => x.option.settingId === this.formData.settingId);
		if (filledForms.length > 0) {
			this.selectedItems.push(filledForms[0].optionId);
		}

		this.selectedItems = this.selectedItems.filter((value, index, self) => self.indexOf(value) === index);
	}

	onChangeSelect(value: any): void {
		if (this.onChange) {
			this.onChange.emit(({tabName: this.tabName, formData: this.formData, value: value}) as any);
		}
	}

	showMultiselect(): boolean {
		if (this.device.deviceTypeId === <number>DeviceTypeEnum.pinPad && this.formData.name === 'Тип связи') {
			return false;
		}

		return true;
	}

}
