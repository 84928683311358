import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';
import { AppService } from '../../../../app.service';
import { EntityViewModel } from '../../../../shared/models/core/EntityViewModel';
import { ComponentPageBase } from '../../../../shared/components/component-page-base/component-page-base';
import { LookupService } from '../../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../../shared/models/core/KeyValueObject';
import { ContragentForm } from '../../../contragents/contragent/contragent-form/contragent.form';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CustomerObject } from '../../../../shared/models/customerObject/CustomerObject';
import { Contragent } from '../../../../shared/models/contragent/Contragent';
import { Tid } from '../../../../shared/models/tid/tid';
import { MerchantFull } from '../../../../shared/models/merchant/MerchantFull';
import { MerchantsFullService } from '../../merchant.full.service';
import { Request } from '../../../../shared/models/request/request';
import { TidsService } from '../../../../shared/services/tids.service';
import { ServiceCentersService } from '../../../../shared/services/service-centers.service';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { DaDataAddressComponent } from '../../../../shared/components/dadata-address/dadata-address.component';
import swal from 'sweetalert2';
import { NotificationType } from '../../../../core/services/notification-type';
import { ContragentsService } from '../../../contragents/contragents.service';
import { AddressSuggestion } from '../../../../shared/models/dadata-address/address-suggestion';
import { AddressService } from '../../../../shared/services/address.service';
import { ServiceCategoriesService } from '../../../../shared/services/service-categories.service';
import { SecurityService } from '../../../../core/services/security.service';

const flatten = filter => {
	const filters = filter.filters;
	if (filters) {
		return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
	}
	return [];
};


@Component({
	selector: 'categories-merchant-form-full',
	templateUrl: './merchant.form.full.html',
	styleUrls: [
		'./merchant.form.full.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class MerchantFormFull extends ComponentPageBase<MerchantFull> implements OnInit {

	@ViewChild("daDataAddressControl") public daDataAddressControl: DaDataAddressComponent;

	headerAcquirerName = "Новый банк эквайер";

	@Input() header: string;
	@Input() showCancelButton: boolean;
	@Input() request: Request;
	@Input() isUpdateRequest: boolean = false;
	@Input() clientId: number;
	@Input() clientAgreementId: number;
	@Output() afterSaveChangesEvent = new EventEmitter<KeyValueObject>();
	@Output() afterCancelEvent = new EventEmitter();

	contragentsAcquirers: KeyValueObject[] = [];
	customerObjects: KeyValueObject[] = [];
	serviceCenters: KeyValueObject[] = [];
	existingTid: KeyValueObject[] = [];
	serviceCategories: KeyValueObject[] = [];

	acquirerContragentId: number;

	public isNewObjectButtonEnabled: boolean;
	public isTidDuplicate: boolean;

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: MerchantsFullService,
		private lookupService: LookupService,
		private tidsService: TidsService,
		private addressService: AddressService,
		private contragentsService: ContragentsService,
		private serviceCentersService: ServiceCentersService,
		private securityService: SecurityService,
		private injector: Injector,
		protected dialogService: DialogService,
		protected serviceCategoriesService: ServiceCategoriesService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/merchants';
		this.appService.pageTitle = 'Новое ТСП';
		this.isNewObjectButtonEnabled = true;
	}

	ngOnInit() {
		this.entityId = this.route.snapshot.paramMap.get('merchantId');

		this.initLookups();

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId).subscribe(vm => {
				this.entityName = `${vm.entity.mid} ${vm.entity.name}`;
				this.dataBind(vm);
			});

		} else {
			this.entityViewModel = new EntityViewModel<MerchantFull>();
			this.entityViewModel.entity = new MerchantFull();
			this.entityViewModel.entity.createdDate = new Date();
			this.entityViewModel.entity.clientAgreementId = this.clientAgreementId;

			if (this.request !== undefined) {
				this.entityViewModel.entity.requestId = this.request.requestId;
				this.entityViewModel.entity.name = this.request.actualName;
				this.entityViewModel.entity.mid = this.request.mid;
				this.entityViewModel.entity.contragentName = this.request.legalName;
				this.entityViewModel.entity.customerObjectAddress = this.request.workplace;
				this.entityViewModel.entity.acquirerId = this.request.acquirerId;

				setTimeout(() => {
					if (this.daDataAddressControl) {
						this.daDataAddressControl.initValue(this.entityViewModel.entity.customerObjectAddress);
					}
				}, 1000);

				this.entityViewModel.entity.contragentAddress = this.request.address;

				if (this.request.tidNumbers.length === 1) {
					this.entityViewModel.entity.tidNumber = this.request.tidNumbers[0];
				}
			}

			this.canEditForm = true;
			this.isNew = true;
		}
		this.SetAcquirerId();
	}

	private SetServiceCenterId(address: string) {
		var that = this;
		this.serviceCentersService.getServiceCenterByRegion(address, this.securityService._currentUser.contragentId)
			.subscribe(s => that.entityViewModel.entity.serviceCenterId = s);
	}

	private SetAcquirerId() {
		if (this.isServiceBoundariesFilter) {
			var that = this;
			this.contragentsService.getById(this.securityService._currentUser.contragentId).subscribe(s => that.entityViewModel.entity.acquirerId = s.entity.acquirerContragentId);
		}
	}

	initLookups() {
		this.contragentsService.contragentsAcquirers().subscribe(data => this.contragentsAcquirers = data);
		this.lookupService.getData("customer-objects", null).subscribe(data => this.customerObjects = data);

		const scState: DataSourceRequestState = {
			filter: {
				logic: 'and', filters: [
					{ field: 'IsActual', operator: 'eq', value: true },
				]
			}
		};

		this.lookupService.getData("service-centers", scState).subscribe(data => this.serviceCenters = data);
		this.loadServiceCategories();
	}

	onAddressChange(value: AddressSuggestion) {
		this.entityViewModel.entity.customerObjectAddress = value.address;
		if (value.latitude != undefined) {
			this.SetServiceCenterId(this.entityViewModel.entity.customerObjectAddress);
		}
		if (!!value) {
			this.appService.MarkFormDirty(this.entityForm);
		} else {
			this.appService.MarkFormPristine(this.entityForm);
		}

	}

	get isAddressValid(): boolean {
		if (!!this.daDataAddressControl) {
			return this.daDataAddressControl.isValid();
		} else {
			return true;
		}
	}

	get isServiceBoundariesFilter(): boolean {
		return this.securityService.isServiceBoundariesFilter();
	}

	get isServiceBoundariesFilterAndAcquirerId(): boolean {
		return (this.securityService.isServiceBoundariesFilter() && this.entityViewModel.entity.acquirerId != null && this.entityViewModel.entity.acquirerId != undefined);
	}
	public showWindowAcquirer() {

		const dialogRef = this.dialogService.open({ content: ContragentForm, width: '100%' });

		const contragentForm = <ContragentForm>dialogRef.content.instance;
		contragentForm.contragentTypeId = 1;
		contragentForm.header = this.headerAcquirerName;
		contragentForm.showCancelButton = true;
		contragentForm.afterCancelEvent.subscribe(() => this.afterCloseObjectChild());
		contragentForm.afterSaveChangesEvent.subscribe((value) => this.afterSaveChangesAcquirerChild(value));
	}

	public enableDataSaving(val: boolean) {
		this.dataSaving = val
	}

	public closeDialog() {
		this.appService.MarkFormPristine(this.entityForm);
		this.canEditForm = false;
		this.dataSaving = false;
		swal.close();
		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}

	afterSaveChanges() {
		if (this.entityId !== null) {
			var val = new KeyValueObject(Number(this.entityId), this.entityViewModel.entity.tidNumber);
			this.afterSaveChangesEvent.emit(val);
		}

	}

	afterCancel() {
		this.afterCancelEvent.emit();
		this.closeDialog();
	}


	afterSaveChangesCustomerObjectChild(value: KeyValueObject) {
		this.isNewObjectButtonEnabled = true;
		this.customerObjects.push(value);
		this.entityViewModel.entity.customerObjectId = value.id;
		this.entityForm.controls["customerObject"].markAsDirty();
	}

	afterSaveChangesContragentChild(value: KeyValueObject) {
		this.isNewObjectButtonEnabled = true;
		this.contragentsAcquirers.push(value);
		this.entityViewModel.entity.contragentId = value.id;
		this.entityForm.controls["contragent"].markAsDirty();
	}

	afterSaveChangesAcquirerChild(value: KeyValueObject) {
		this.isNewObjectButtonEnabled = true;
		this.contragentsAcquirers.push(value);
		this.entityViewModel.entity.acquirerId = value.id;
		this.entityForm.controls["acquirer"].markAsDirty();
	}

	afterCloseObjectChild() {
		this.isNewObjectButtonEnabled = true;
	}

	showAddAcquirerObject(): void {
		this.showWindowAcquirer();
		this.isNewObjectButtonEnabled = false;
	}

	beforeSaveChanges() {
		this.entityViewModel.entity.customerObjectCreate = new CustomerObject();
		this.entityViewModel.entity.contragentCreate = new Contragent();
		this.entityViewModel.entity.tidCreate = new Tid()

		var currDate = new Date();

		this.entityViewModel.entity.customerObjectCreate.createdDate = currDate;
		this.entityViewModel.entity.customerObjectCreate.name = this.entityViewModel.entity.name;
		this.entityViewModel.entity.customerObjectCreate.address = this.entityViewModel.entity.customerObjectAddress;

		this.entityViewModel.entity.contragentCreate.createdDate = currDate;
		this.entityViewModel.entity.contragentCreate.name = this.entityViewModel.entity.contragentName;
		this.entityViewModel.entity.contragentCreate.address = this.entityViewModel.entity.contragentAddress;
		this.entityViewModel.entity.contragentCreate.projectId = 1;

		this.entityViewModel.entity.tidCreate.tidNumber = this.entityViewModel.entity.tidNumber;
		this.entityViewModel.entity.tidCreate.startDate = currDate;
		this.entityViewModel.entity.tidCreate.customerContragentId = this.clientId;
		this.entityViewModel.entity.tidCreate.agreementId = this.clientAgreementId;
		this.entityViewModel.entity.tidCreate.serviceCategoryId = this.entityViewModel.entity.tidServiceCategoryId;
	}

	clearCheckTidDuplicate(): void {
		this.isTidDuplicate = false;
	}

	saveChanges(name: string = this.entityName): void {
		this.dataSaving = true;

		let saveChangesLogic = () => {
			this.entityViewModel.entity.isUpdateRequest = this.isUpdateRequest;

			this.notificationService.info(
				{
					title: "Внимание!",
					message: "Сохранение... Не закрывайте страницу!",
					showConfirmButton: false,
					showCloseButton: false,
					notificationType: NotificationType.SweetAlert
				});

			this.tidsService.getCheckTidExists(
				this.entityViewModel.entity.tidNumber, 
				null, 
				this.entityViewModel.entity.acquirerId,
				this.clientAgreementId
			).subscribe(x => {
				this.isTidDuplicate = x.length > 0;
				if (this.isTidDuplicate) {
					swal.close();
					this.dataSaving = false;
					return;
				}

				this.beforeSaveChanges();

				const action = this.isNew
					? this.dataService.create(this.entityViewModel.entity)
					: this.dataService.update(this.entityViewModel.entity, this.entityName);

				action.subscribe(val => {

					this.entityId = val.data;
					this.afterSaveChanges();

				}, x => this.dataSaving = false);
			});
		};

		this.addressService.addAddressOrCoordinatesByName(this.entityViewModel.entity.customerObjectAddress,
			null,
			null,
			null,
			"Поиск адреса при создании нового ТИДа",
			3,
			true)
			.subscribe(addressRes => {
				if (addressRes.isSuccessful) {
					if (!!addressRes.data) {
						saveChangesLogic();
					} else {
						this.notificationService.confirmation({
							title: this.entityName,
							message: `Указанный адрес ТСП не распознан.
							Невозможно определить регион, исполнителя, вычислить удаленность.
							Заявка с таким ТСП не сможет быть назначена на инженера в автоматическом режиме`,
							type: 'question',
							confirmButtonText: 'Сохранить',
							cancelButtonText: 'Отмена',
							showCloseButton: false,
						}, () => {
							this.entityViewModel.entity.parseAddressDadata = false;
							saveChangesLogic();
						}, () => {
							this.dataSaving = false
						});
					}
				} else {
					this.notificationService.confirmation({
						title: this.entityName,
						message: addressRes.errorDescription,
						type: 'question',
						confirmButtonText: 'Сохранить',
						cancelButtonText: 'Отмена',
						showCloseButton: false,
					}, () => {
						this.entityViewModel.entity.parseAddressDadata = false;
						saveChangesLogic();
					}, () => {
						this.dataSaving = false
					});
				}
			}, errorResponse => {
				this.notificationService.confirmation({
					title: this.entityName,
					message: errorResponse.errorDescription,
					type: 'question',
					confirmButtonText: 'Сохранить',
					cancelButtonText: 'Отмена',
					showCloseButton: false,
				}, () => {
					this.entityViewModel.entity.parseAddressDadata = false;
					saveChangesLogic();
				}, () => {
					this.dataSaving = false
				});
			});
	}

	loadServiceCategories(): void {
		if (!!this.clientAgreementId) {
			this.serviceCategoriesService.getByAgreementId(this.clientAgreementId).subscribe(result => this.serviceCategories = result.data);
		}
		else {
			this.serviceCategories = [];
		}
	}
}
