import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { AnalyzeInstallDeviceAttachmentRequest } from '../models/device-attachments/analyze-install-device-attachment-request';
import { AnalyzeInstallDeviceAttachmentResponse } from '../models/device-attachments/analyze-install-device-attachment-response';
import { ActionResult } from '../models/core/ActionResult';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { AnalyzeInstallSimcardAttachmentsRequest } from '../models/device-attachments/analyze-install-simcard-attachments-request';
import { AnalyzeInstallSimcardAttachmentsResponse } from '../models/device-attachments/analyze-install-simcard-attachments-response';
import { AnalyzeRequiredAttachmentsRequest } from '../models/device-attachments/analyze-required-attachments-request';
import { AnalyzeRequiredAttachmentsResponse } from '../models/device-attachments/analyze-required-attachments-response';
import { AnalyzeUninstallDeviceAttachmentResponse } from '../models/device-attachments/analyze-uninstall-device-attachments-response';
import { AnalyzeUninstallSimcardAttachmentResponse } from '../models/device-attachments/analyze-uninstall-simcard-attachment-response';

@Injectable()
export class DeviceAttachmentsService 
    extends DataService<AnalyzeInstallDeviceAttachmentRequest, AnalyzeInstallDeviceAttachmentResponse> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'device-attachments';
	}

	public analyzeInstallAttachments(request: AnalyzeInstallDeviceAttachmentRequest): Observable<ActionDataResult<AnalyzeInstallDeviceAttachmentResponse[]>> {
        const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/analyze-install-attachments`;
		return this.postQuery<AnalyzeInstallDeviceAttachmentResponse[]>(url, request);
	}

	public analyzeInstallSimcardAttachments(request: AnalyzeInstallSimcardAttachmentsRequest): Observable<ActionDataResult<AnalyzeInstallSimcardAttachmentsResponse[]>> {
        const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/analyze-install-simcard-attachments`;
		return this.postQuery<AnalyzeInstallSimcardAttachmentsResponse[]>(url, request);
	}

	public analyzeRequiredAttachments(request: AnalyzeRequiredAttachmentsRequest): Observable<ActionDataResult<AnalyzeRequiredAttachmentsResponse>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/analyze-required-attachments`;
		return this.postQuery<AnalyzeRequiredAttachmentsResponse>(url, request);
	}

	public analyzeUninstallAttachments(attachmentIds: number[] = []): Observable<ActionDataResult<AnalyzeUninstallDeviceAttachmentResponse>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/analyze-uninstall-attachments`;
		return this.postQuery<AnalyzeUninstallDeviceAttachmentResponse>(url, { attachmentIds: attachmentIds });
	}

	public analyzeUninstallSimcardAttachments(attachmentIds: number[] = []): Observable<ActionDataResult<AnalyzeUninstallSimcardAttachmentResponse>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/analyze-uninstall-simcard-attachments`;
		return this.postQuery<AnalyzeUninstallSimcardAttachmentResponse>(url, { attachmentIds: attachmentIds });
	}
}