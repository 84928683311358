import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { environment } from '../../../environments/environment';
import { RequestPublicationSettingListItemDto } from '../../admin/automatic-publication-requests/models/request-publication-setting-list-item-dto';
import { RequestPublicationSettingDto } from '../../admin/automatic-publication-requests/models/request-publication-setting-dto';
import { Observable } from 'rxjs';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { filter, take, tap } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { RequestPublicationSettingChangeLogDto } from '../../admin/automatic-publication-requests/models/request-publication-setting-changelog-dto';


@Injectable()
export class RequestPublicationSettingsService extends DataService<RequestPublicationSettingListItemDto, RequestPublicationSettingDto> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'request-auto-publish-settings';
	}

	protected baseUrl(): string {
		return `${environment.engubatorAddress}v${this.controllerVersion}/${this.controllerName}`;
	}

	public settingById(id: number): Observable<RequestPublicationSettingDto> {
		return this.http.get<ActionDataResult<RequestPublicationSettingDto>>(`${this.baseUrl()}/${id}`)
			.pipe(tap(resp => {
				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}
			}, errorResponse => {
				let mes = errorResponse.status === 403 ? "Нет доступа" :  errorResponse.message;
				this.notificationService.error({
					title: 'Ошибка',
					message: mes,
					notificationType: NotificationType.SweetAlert
				});
			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data);
	}

	public addSetting(setting: RequestPublicationSettingDto, errorNotificationType: NotificationType = NotificationType.Toast, dontShowCustomError: boolean = false): Observable<any> {
		return this.http.post<ActionDataResult<number | string>>(this.baseUrl(), setting)
			.pipe(tap(resp => {
				if (resp.isSuccessful) {
					this.notificationService.success({
						title: 'Создание нового элемента',
						message: 'Элемент создан успешно!',
						notificationType: NotificationType.Toast
					});
				}
				else {
					if (!dontShowCustomError) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: errorNotificationType
						});
					}
				}
			}, errorResponse => {
				let mes = errorResponse.status === 403 ? "Нет доступа" :  errorResponse.message;
				this.notificationService.error({
					title: 'Ошибка при создании элемента!',
					message: mes,
					notificationType: NotificationType.Toast
				});
			}))
			.pipe(take(1));
	}

	public editSetting(setting: RequestPublicationSettingDto, successMessageTitle: string = 'Настройка обновлена', dontShowCustomError: boolean = false, dontShowCustomSuccess: boolean = false): Observable<any> {
		return this.http.put<ActionDataResult<any>>(this.baseUrl(), setting)
			.pipe(tap(resp => {

				if (resp.isSuccessful) {
					if (!dontShowCustomSuccess) {
						this.notificationService.success({
							title: successMessageTitle,
							message: 'Изменения сохранены успешно!',
							notificationType: NotificationType.Toast
						});
					}
				}
				else {
					if (!dontShowCustomError) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.Toast
						});
					}
				}

			}, errorResponse => {
				let mes = errorResponse.status === 403 ? "Нет доступа" :  errorResponse.message;
				this.notificationService.error({
					title: 'Ошибка при сохранении изменений!',
					message: mes,
					notificationType: NotificationType.Toast
				});

			}))
			.pipe(take(1));
	}

	public deleteSetting(id: number, successMessageTitle: string, successMessageText: string): Observable<any> {
		return this.http.delete<ActionDataResult<any>>(`${this.baseUrl()}/${id}`)
			.pipe(tap(resp => {

				if (resp.isSuccessful) {

					this.notificationService.success({
						title: successMessageTitle,
						message: successMessageText,
						notificationType: NotificationType.SweetAlert
					});

				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {
				let mes = errorResponse.status === 403 ? "Нет доступа" :  errorResponse.message;
				this.notificationService.error({
					title: 'Ошибка',
					message: mes,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1));

	}

	public allSettings(): Observable<RequestPublicationSettingListItemDto[]> {

		return this.http.get<ActionDataResult<RequestPublicationSettingListItemDto[]>>(`${this.baseUrl()}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {
				let mes = errorResponse.status === 403 ? "Нет доступа" :  errorResponse.message;
				this.notificationService.error({
					title: 'Ошибка',
					message: mes,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;

	}

	public settingChangeLogs(id: number): Observable<RequestPublicationSettingChangeLogDto[]> {

		return this.http.get<ActionDataResult<RequestPublicationSettingChangeLogDto[]>>(`${this.baseUrl()}/change-logs/${id}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {
				let mes = errorResponse.status === 403 ? "Нет доступа" :  errorResponse.message;
				this.notificationService.error({
					title: 'Ошибка',
					message: mes,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;

	}

	public check(model: any): Observable<ActionDataResult<boolean>> {
		return this.http.post<ActionDataResult<boolean>>(`${this.baseUrl()}/check`, model);
	}
}