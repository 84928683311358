import { Pipe, PipeTransform } from '@angular/core';
import { DataResult } from '@progress/kendo-data-query';
import {NewRequestDevice} from '../../models/request/new-request/new-request-device';

@Pipe({
	name: 'requestDeviceFilter'
})
export class RequestDeviceFilterPipe implements PipeTransform {

	transform(dataResult: DataResult, deviceStatusIds: number[]): NewRequestDevice[] {
		if(!dataResult || !dataResult.data)
			return [];
		
			return dataResult.data.filter((item: NewRequestDevice) => deviceStatusIds.length === 0 || deviceStatusIds.indexOf(item.deviceStatusId) !== -1);
	}

}
