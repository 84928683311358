import { Injectable } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { BehaviorSubject } from 'rxjs';
import { MapRequestDto } from '../models/request/map-request';

@Injectable()
export class MapYaDataService {

	private sourceRequests = new BehaviorSubject([] as MapRequestDto[]);
	observableRequests = this.sourceRequests.asObservable();

	private sourceFilters = new BehaviorSubject({} as State);
	observableFilters = this.sourceFilters.asObservable();

	constructor() {

	}

	changeRequests(requests: MapRequestDto[]) {
		this.sourceRequests.next(requests);
	}

	changeFilters(filters: State) {
		this.sourceFilters.next(filters);
	}
}
