import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";
import { StatePersistingService } from "../../../shared/services/state-persisting.service";
import { NomenclatureService } from "../../../shared/services/nomenclature.service";
import { NomenclatureListItemDto } from "../../../shared/models/nomenclature/nomenclatureListItemDto";
import { DialogService } from "@progress/kendo-angular-dialog";
import { NomenclaturePage } from "../nomenclature.page";
import { takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { RowArgs } from "@progress/kendo-angular-grid";
import { NotificationService } from "../../../core/services/notification.service";

@Component({
	selector: 'nomenclature-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss',
	'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',]
})
export class NomenclatureListPage extends ListPageBase<NomenclatureListItemDto> implements OnDestroy {
	constructor(
		private dialogService: DialogService,
		protected appService: AppService,
		protected router: Router,
		protected dataService: NomenclatureService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService,
		private notificationService: NotificationService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Номенклатура';
	}

	dataSavingList :number[] = [];
	
	private _destroy$ = new Subject<boolean>();

	public gridSettings: any = {
		clearFilter: true,
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'nomenclatureName', dir: 'asc' }]
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'nomenclatureName',
				title: 'Номенклатура',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'partnumberNames',
				title: 'Парт-номера',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			}
		]
	};

	onListPageInit(): void {

	}

	processListItems(items: NomenclatureListItemDto[]): void {

	}

	public add(){
		const installDialogRef = this.dialogService.open({
			title: "Новая номенклатура",
			content: NomenclaturePage,
			width: '400px'
		});

		const dialog = <NomenclaturePage>installDialogRef.content.instance
		dialog.onContinueEvent
		.subscribe(() => {	
			this.loadData();
		});
	}

	public edit(item: NomenclatureListItemDto){
		const installDialogRef = this.dialogService.open({
			title: "Редактирование номенклатуры",
			content: NomenclaturePage,
			width: '400px'
		});

		const dialog = <NomenclaturePage>installDialogRef.content.instance;
		dialog.nomenclatureId = item.nomenclatureId;
		dialog.nomenclatureName = item.nomenclatureName;
		dialog.onContinueEvent
		.subscribe(() => {	
			this.loadData();
		});
	}

	public cellClick({ type, dataItem, column }: any): void {

		var item = (<NomenclatureListItemDto>dataItem);

		if (column.field === 'nomenclatureName') {
			this.edit(item)
		}
	}

	removeHandler({ dataItem }): void {
		this.dataSavingList.push(dataItem.nomenclatureId);
		this.notificationService.confirmation({
			title: 'Удаление номенклатуры',
			message: 'Вы уверены что хотите удалить номенклатуру?',
			type: 'question',
			confirmButtonText: 'Да',
			cancelButtonText: 'Нет',
			showCloseButton: true
		}, () => {
			this.dataService.remove(dataItem.nomenclatureId.toString(), 'Удаление номенклатуры', 'Удаление номенклатуры успешно')
			.pipe(
				tap(() => this.removeFromDataSavingList(dataItem)),
				takeUntil(this._destroy$))
			.subscribe(x =>
				{
					this.loadData();
				});
		}, () => {this.removeFromDataSavingList(dataItem);});
    }

	removeFromDataSavingList(dataItem: any){
		this.dataSavingList = this.dataSavingList.filter(element => element != dataItem.nomenclatureId)
	}

	ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}
}