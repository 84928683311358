import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { RequestStoppingReasonAliasDto } from '../models/request/request-stopping-reason-alias-dto';

@Injectable()
export class RequestStoppingReasonAliasesService extends 
    DataService<RequestStoppingReasonAliasDto, RequestStoppingReasonAliasDto> 
{
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'stopping-reason-aliases';
	}
}