export class SelectSnData {
    requestDeviceId: number;
    selectedArticle: string;
    deviceStatusId: number;
    isPart: boolean;
    serialNumber: string;
    selectedCount: number;
    recipientCellNumber: string;
    senderCellNumber: string;
    notFound: boolean;

    nomenclature: string;
    isAdded: boolean;
    articleId?: number;
    deviceTypeId?: number;
    deviceSupplierId?: number;
    deviceModelId?: number;

    vendor: string;
    model: string;
    type: string;

    constructor(requestDeviceId: number
        , selectedArticle: string
        , deviceStatusId: number
        , isPart: boolean
        , serialNumber: string
        , selectedCount: number
        , recipientCellNumber: string = null
        , senderCellNumber: string = null
        , notFound: boolean = false
        , nomenclature: string = null
        , isAdded: boolean = false
        , articleId: number = null
        , deviceTypeId: number = null
        , deviceSupplierId: number = null
        , deviceModelId: number = null
        , vendor: string = null
        , model: string = null
        , type: string = null) {
        this.requestDeviceId = requestDeviceId;
        this.selectedArticle = selectedArticle;
        this.deviceStatusId = deviceStatusId;
        this.isPart = isPart;
        this.serialNumber = serialNumber;
        this.recipientCellNumber = recipientCellNumber;
        this.senderCellNumber = senderCellNumber;
        this.selectedCount = selectedCount;
        this.notFound = notFound;

        this.nomenclature = nomenclature;
        this.isAdded = isAdded;
        this.articleId = articleId;
        this.deviceTypeId = deviceTypeId;
        this.deviceSupplierId = deviceSupplierId;
        this.deviceModelId = deviceModelId;

        this.vendor = vendor;
        this.model = model;
        this.type = type;
    }
}