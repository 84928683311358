﻿import { Injectable } from "@angular/core";
import { DataService } from "../../core/services/data.service";
import { ContragentContact } from "../models/contragent/contragent-contact";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "../../core/services/notification.service";
import {Observable} from "rxjs";
import {ActionDataResult} from "../models/core/ActionDataResult";
import {environment} from "../../../environments/environment";
import {take, tap} from "rxjs/operators";
import {NotificationType} from "../../core/services/notification-type";
import {ActionResult} from "../models/core/ActionResult";

@Injectable()
export class ContragentContactsService extends DataService<ContragentContact, ContragentContact> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'contragent-contacts';
	}

	public getByContragent(contragentId: number, contactTypeId?: number): Observable<ActionDataResult<ContragentContact[]>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/by-contragent/${contragentId}?contactTypeId=${contactTypeId}`;
		return this.http.get<ActionDataResult<ContragentContact[]>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при запросе контактов контрагента',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}
			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при запросе контактов контрагента',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});
			}));
	}

	public removeForContragent(contactId: string, contragentId: number, successMessageTitle: string, successMessageText: string): Observable<ActionDataResult<any>> {
		return this.http.delete<ActionDataResult<object>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/delete/${contragentId}/${contactId}`)
			.pipe(tap(resp => {

				if (resp.isSuccessful) {

					this.notificationService.success({
						title: successMessageTitle,
						message: successMessageText,
						notificationType: NotificationType.SweetAlert
					});

				} else {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1));

	}

}
