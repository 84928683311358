import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { ServiceTypeItem } from "../../../shared/models/services/service-type-item";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";
import { ServiceTypesService } from "../../../shared/services/service-types.service";
import { StatePersistingService } from "../../../shared/services/state-persisting.service";

@Component({
	selector: 'service-types-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class ServiceTypeListPage extends ListPageBase<ServiceTypeItem> {

	constructor(
		protected appService: AppService,
		protected router: Router,
		protected dataService: ServiceTypesService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Типы обслуживания';
	}

	onListPageInit(): void {

	}

	processListItems(items: ServiceTypeItem[]): void {

	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/categories/service-type', dataItem.id]);
	}

}
