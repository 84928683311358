import { KeyValue } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { addMonths } from "@progress/kendo-date-math";
import { ContragentsService } from "../../categories/contragents/contragents.service";
import { NotificationService } from "../../core/services/notification.service";
import { KeyValueObject } from "../../shared/models/core/KeyValueObject";
import { LookupService } from "../../shared/services/lookup.service";
import { ReportsService } from "../../shared/services/reports.service";
import { ReportPageBase } from "../components/report-page-base";
import { saveAs } from 'file-saver';
import { NotificationType } from "../../core/services/notification-type";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { AppService } from "../../app.service";

@Component({
	selector: 'time-fund-report-page',
	templateUrl: './time-fund-report.page.html',
	styleUrls: [
		'./time-fund-report.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class TimeFundReportPage extends ReportPageBase implements OnInit {
    isCompletion: boolean = true;
    clients: KeyValueObject[] = [];
    clientIds: number[] = [];
    requestTypes: KeyValueObject[] = [];
    requestTypeIds: number[] = [];
    requestStatuses: KeyValueObject[] = [];
    requestStatusIds: number[] = [];

    constructor(
		private reportsService: ReportsService,
        private lookupService: LookupService,
        private contragentsService: ContragentsService,
		private notificationService: NotificationService,
		private appService: AppService
    ) {
        super();
		appService.pageTitle = 'Отчет по временным фондам';
    }

    ngOnInit() {
        this.range.end.setHours(0, 0, 0, 0);
        this.range.start = addMonths(this.range.end, -1);
		let contragentsState: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: 1 }] }
		};
		this.lookupService.getData("contragents", contragentsState).subscribe(data => this.clients = data);
        this.lookupService.getData('request-types', null).subscribe(data => this.requestTypes = data);
        this.lookupService.getData('request-statuses', null).subscribe(data => this.requestStatuses = data);
    }

	generateReport() {
		this.generateReportByDateRange((startDate, endDate) => {
			this.reportsService.getTimeFund(startDate, endDate, this.isCompletion, this.clientIds, this.requestTypeIds, this.requestStatusIds)
				.subscribe(x => {
					if (x.isSuccessful) {
						const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${x.data}`;
						const localDate = new Date().toLocaleString().replace(',', '');
						const startPeriod = this.range.start.toLocaleDateString().replace(',', '');
						const endPeriod = this.range.end.toLocaleDateString().replace(',', '');
						saveAs(dataURI, `Отчет_по_временным_фондам_${this.isCompletion ? 'завершение' : 'поступление'}_с_${startPeriod}_по_${endPeriod}_создан_${localDate}.xlsx`);
					} else {
						this.notificationService.error({
							title: 'Ошибка при построении отчета',
							message: x.errorDescription,
							notificationType: NotificationType.Toast
						});
					}
					this.dataGenerating = false;
				});
		});
	}
}
