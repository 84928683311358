export class EngubatorRegion {
    fullString: string;
    regionFiasId: string;
    segments: EngubatorRegionSegment[] = [];
}

export class EngubatorRegionSegment {
    levelCode: string;
    levelName: string;
    name: string;
    regionFiasId: string;
    serviceCenterId: number;
    regionSegments: EngubatorRegionSegment[] = [];
}