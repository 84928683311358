import { CommentKindEnum } from "../../../../enums/comment-kind.enum";

export class SetPlannedDateModel {

    constructor(requestId?: number, plannedDate?: Date, commentKind?: CommentKindEnum, comment?: string){
        this.requestId = requestId;
        this.plannedDate = plannedDate;
        this.commentKind = commentKind;
        this.comment = comment;
    }

    requestId?: number;
    plannedDate: Date;
    comment: string;
    commentKind: CommentKindEnum;
    forceSet: boolean = false;
}