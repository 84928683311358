import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { State } from '@progress/kendo-data-query';
import { ILoadEvent } from 'angular8-yandex-maps';
import { NotificationType } from '../../core/services/notification-type';
import { NotificationService } from '../../core/services/notification.service';
import { MultipleOperationErrorResultComponent } from '../../shared/components/multiple-operations/multiple-operation-error-result/multiple-operation-error-result.component';
import { MultipleOperationsComponent } from '../../shared/components/multiple-operations/multiple-operations.component';
import { RequestStatus, RequestType } from '../../shared/enums';
import { ServiceCenterEngineers } from '../../shared/models/engineers/service-center-engineers';
import { ServiceCenterEngineersData } from '../../shared/models/engineers/service-center-engineers-data';
import { CreateUpdateActivityMultiple } from '../../shared/models/request/create-update-activity-multiple';
import { MapRequestDto } from '../../shared/models/request/map-request';
import { RequestListItem } from '../../shared/models/request/request-list-item';
import { ActivitiesService } from '../../shared/services/activities.service';
import { MapYaDataService } from '../../shared/services/map-ya-data.service';
import { RequestsService } from '../../shared/services/requests.service';
import { ServiceCentersService } from '../../shared/services/service-centers.service';
import { MapYaPolygonComponent } from '../map-ya-components/map-ya-polygon/map-ya-polygon.component';

import '@progress/kendo-ui';

declare var kendo: any;

kendo.cultures["ru-RU"] = {
    name: "ru-RU",
    calendars: {
        standard: {
            days: {
                names: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                namesAbbr: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
                namesShort: [ "Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб" ]
            },
            months: {
                names: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                namesAbbr: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"]
            },
            AM: [ "AM", "am", "AM" ],
            PM: [ "PM", "pm", "PM" ],
            patterns: {
                d: "M/d/yyyy",
                D: "dddd, MMMM dd, yyyy",
                F: "dddd, MMMM dd, yyyy h:mm:ss tt",
                g: "M/d/yyyy h:mm tt",
                G: "M/d/yyyy h:mm:ss tt",
                m: "MMMM dd",
                M: "MMMM dd",
                s: "yyyy'-'MM'-'ddTHH':'mm':'ss",
                t: "h:mm tt",
                T: "h:mm:ss tt",
                u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                y: "MMMM, yyyy",
                Y: "MMMM, yyyy"
            },
            firstDay: 1,
        },
			cancel: 'Отменить'
    }
};

@Component({
	selector: 'map-ya',
	templateUrl: './map-ya.component.html',
	styleUrls: [
		"map-ya.component.scss"
	],
	encapsulation: ViewEncapsulation.None
})
export class MapYaComponent implements OnInit {

	@Input()
	requests = [];

	@ViewChild('yaMapPolygon') yaMapPolygon: MapYaPolygonComponent;

	private iconClassesByRequestTypes = [
		{ requestTypeId: RequestType.install, value: 'far fa-flag' },
		{ requestTypeId: RequestType.installWithDismantle, value: 'far fa-flag' },
		{ requestTypeId: RequestType.service, value: 'fas fa-wrench' },
		{ requestTypeId: RequestType.changeConfig, value: 'fas fa-recycle' },
		{ requestTypeId: RequestType.uninstall, value: 'far fa-trash-alt' }
	];

	private markerTypesByColor = [
		{ colorCode: '#FFCCCC', type: 'islands#redIcon', priority: 1 },
		{ colorCode: '#FFCC99', type: 'islands#orangeIcon', priority: 2 },
		{ colorCode: '#FFFFCC', type: 'islands#yellowIcon', priority: 3 },
		{ colorCode: '#CCFFCC', type: 'islands#greenIcon', priority: 4 },
		{ colorCode: '#B9E496', type: 'islands#darkGreenIcon', priority: 5 }
	];

	private clusterTypesByColor = [
		{ type: 'islands#redClusterIcons', priority: 1 },
		{ type: 'islands#orangeClusterIcons', priority: 2 },
		{ type: 'islands#yellowClusterIcons', priority: 3 },
		{ type: 'islands#greenClusterIcons', priority: 4 },
		{ type: 'islands#darkGreenClusterIcons', priority: 5 },
	];

	private requestHeaders = [
		{ key: 'customerContragentName', value: 'Клиент (заказчик)' },
		{ key: 'externalId', value: 'Номер заявки заказчика' },
		{ key: 'typeName', value: 'Тип' },
		{ key: 'description', value: 'Описание' },
		{ key: 'slaDueDate', value: 'Дата/время окончания SLA' },
		{ key: 'tidNumbers', value: 'TID' },
		{ key: 'tspName', value: 'ЮЛ ТСП' },
		{ key: 'tspAddress', value: 'Фактическое местонахождение ТСП' },
		{ key: 'deviceModels', value: 'Модель оборудования' },
		{ key: 'stoppedUntil', value: 'Остановлено ДО' },
		{ key: 'reasonForStopping', value: 'Комментарий причины ожидания' },
		{ key: 'serviceCenterEngineerName', value: 'Инженер (СЦ)' }
	];

	private yaMap;
	private ymaps;

	private clusterer;
	private placemarks = [];

	private openedClusterId = null;
	private openedClusterRequestId = null;
	private userChangedDefaultZoom: boolean = false;

	private requestFilters: State = {};
	private requestIdWithOpenedBalloon: number = null;

	private planStartDate;
	private assignToEngineerId;
	private assignToEngineerName;
	private savingEngineer: boolean = false;

	clustererOptions = {
		minClusterSize: 2,
		gridSize: 64
	};

	constructor(
		private requestService: RequestsService,
		private mapYaDataService: MapYaDataService,
		private dataService: MapYaDataService,
		private dialogService: DialogService,
		private notificationService: NotificationService,
		private serviceCentersService: ServiceCentersService,
		private activitiesService: ActivitiesService,
	) {
		
	}

	ngOnInit() {
		this.dataService.observableRequests.subscribe(requests => this.requests = requests);
		this.dataService.observableFilters.subscribe(filters => this.requestFilters = filters);

		if (kendo.ui.DateTimePicker) {
			kendo.ui.DateTimePicker.prototype.options.messages =
			kendo.jQuery.extend(true, kendo.ui.DateTimePicker.prototype.options.messages, {
				"cancel": "Отменить",
				"date": "Дата",
				"hour": "Часы",
				"millisecond": "Милисекунды",
				"minute": "Минуты",
				"now": "Сейчас",
				"second": "Секунды",
				"set": "Выбрать дату",
				"time": "Время",
				"today": "Сегодня",
			});
		}
	}

	onLoadMap(loadEvent: ILoadEvent) {
		this.yaMap = loadEvent.instance;
		this.ymaps = loadEvent.ymaps;

		this.initMapData();

		this.yaMapPolygon.addDrawingPolygonControls(loadEvent, polygon => this.onCompletedPolygon(polygon, this));
	}

	public initMapData() {
		this.requestService.getMapRequests(this.requestFilters.filter)
			.subscribe(result => {
				const requests = result.data.map(request => {
					if (!request.latitude && !request.longitude) {
						request.latitude = 55.75143;
						request.longitude = 37.61889;
					}

					return request;
				});

				this.mapYaDataService.changeRequests(requests);

				this.createMapData();
			});
	}

	public createMapData() {
		this.createClusteredPlacemarks();

		this.yaMap.events.add('boundschange', event => {
			this.setClusterColor();
		});

		this.yaMap.events.add('wheel', event => {
			this.userChangedDefaultZoom = true;
		});
	}

	public createClusteredPlacemarks(): void {
		if (!this.ymaps || !this.yaMap) {
			throw 'map is not initialized';
		}

		this.clearClusterer();
		this.clearPlacemarks();

		let isNewClusterer = false;
		if (!this.clusterer) {
			this.clustererOptions['clusterBalloonItemContentLayout'] = this.ymaps.templateLayoutFactory.createClass('{{ properties.balloonContentBody|raw }}');
			this.clusterer = new this.ymaps.Clusterer(this.clustererOptions);

			isNewClusterer = true;
		}

		this.requests.forEach(request => {
			if (!this.requestIdWithOpenedBalloon || this.requestIdWithOpenedBalloon !== request.requestId) {
				const placemark = this.createPlacemark(request);
				this.placemarks.push(placemark);
				this.clusterer.add(placemark);
			}
		});

		if (isNewClusterer) {
			this.yaMap.geoObjects.add(this.clusterer);
		}

		if (!this.userChangedDefaultZoom) {
			// При инициализации отобразятся все маркеры на экране
			this.yaMap.setBounds(this.clusterer.getBounds(), {
				checkZoomRange: true,
				zoomMargin: 25
			});
		}

		this.setClusterColor();
		this.setClusterBallonEvents();
	}

	private createPlacemark(request: MapRequestDto) {
		const placemark = new this.ymaps.Placemark(
			[request.latitude, request.longitude],
			this.createPlacemarkProperties(request),
			this.createPlacemarkOptions(request));

		placemark.geometry.options.setName('placemark');

		placemark.events.add('balloonopen', event => this.setBalloonEngineersData(request, placemark));
		placemark.events.add('balloonclose', event => this.removeEngineerData());

		return placemark;
	}

	private setBalloonEngineersData(request: MapRequestDto, placemark: any): void {
		// Engineers select form
		let engineersMultipleScModel = new ServiceCenterEngineers([new ServiceCenterEngineersData(request.requestId, request.serviceCenterId, new Date())], true);
		this.serviceCentersService.engineersMultipleSc(engineersMultipleScModel)
			.subscribe(result => {
				if (result.isSuccessful) {
					const engineerHtmlSelect = document.getElementById(`request-engineer-list-${request.requestId}`);

					engineerHtmlSelect.removeAttribute('disabled');

					result.data.map(engineer => {
						var option = document.createElement('option');
						option.appendChild(document.createTextNode(engineer.name));
						option.value = engineer.engineerUserId.toString(); 
						engineerHtmlSelect.appendChild(option); 
					});

					engineerHtmlSelect.addEventListener('change', event => {
						const target = event.target as any;
						this.assignToEngineerId = target.value;
						this.assignToEngineerName = target.selectedOptions[0].text;
					});
				}
			});

		// Date picker form
		const assignToEngineerDateInput = document.getElementById(`assign-engineer-date-${request.requestId}`);
		const datepicker = kendo.jQuery(assignToEngineerDateInput).kendoDateTimePicker({
			culture: "ru-RU",
			componentType: "modern",
			timeFormat: "HH:mm",
			format: "dd.MM.yyyy HH:mm",
			open: event => {
				kendo.jQuery('.k-today').first().text('Сегодня');
			},
			change: event => {
				this.planStartDate = event.sender._value;
			}
		}).data("kendoDateTimePicker");

		// Assign to engineer button
		const assignToEngineerButton = document.getElementById(`request-assign-engineer-${request.requestId}`);
		assignToEngineerButton.addEventListener('click', event => {
			if (!this.assignToEngineerId || !this.planStartDate) {
				this.notificationService.info({
					title: 'Ошибка валидации',
					message: 'Выберите инженера и дату!',
					notificationType: NotificationType.SweetAlert
				});
				return;
			}

			if (this.savingEngineer)
				return;

			this.requestAssignToEngineer(request, placemark, assignToEngineerButton);
		});
			
	}

	private createPlacemarkProperties(request: MapRequestDto) {
		return {
			iconContent: this.getIconByRequestType(request),
			clusterCaption: `Заявка номер <strong>${request.requestId}</strong>`,
			balloonContentBody: this.getBalloonContent(request),
		};
	}

	private createPlacemarkOptions(request: MapRequestDto) {
		return {
			preset: this.getMarkerType(request),
			hasBalloon: true,
			request: request,
			clusterBalloonEngineersLoaded: false,
		};
	}

	private getIconByRequestType(request: MapRequestDto) {
		const iconClasses = this.iconClassesByRequestTypes.filter(x => x.requestTypeId === request.requestTypeId);
		return iconClasses.length > 0 ? `<i class="${iconClasses[0].value}" style="font-size: 10px; padding-left:1px; vertical-align: middle;"></i>` : '';
	}

	private getMarkerType(request: MapRequestDto): string {
		const markerTypes = this.markerTypesByColor.filter(x => x.colorCode === this.requestColor(request));
		if (markerTypes.length > 0) {
			return markerTypes[0].type;
		} else {
			return 'islands#grayIcon';
		}

		return '';
	}

	private getBalloonContent(request: MapRequestDto): string {
		const balloonContentArr = Object.keys(request)
			.filter(field => this.requestHeaders.some((header => header.key === field) as any))
			.map((fieldName, _) => {
				const header = this.requestHeaders.filter(x => x.key === fieldName)[0].value;
				const value = request[fieldName];
				if (fieldName === 'serviceCenterEngineerName' && (!request.hasEngineer || request.isEngineerActivitiesUnsuccessful)) {
					return '';
				}

				return `<b>${header}</b>: ${value ? value : '-'}<br/>`;
			});

		const balloonContent = balloonContentArr.join('');

		const assignToEngineerForm = this.getAssignToEngineerForm(request);

		return `<a href="/requests/${request.requestId}" target="_blank" rel="noopener noreferrer"><h5>Заявка номер <strong>${request.requestId}</strong></h5></a><div style="display: contents; font-size: 15px;">${balloonContent}${assignToEngineerForm}</div>`;
	}

	private requestColor(request: MapRequestDto): string {
		return request.slaColorCode ? request.slaColorCode : request.statusColorCode;
	}

	private setClusterColor(): void {
		this.yaMap.geoObjects.each(geoObject => {
			if (geoObject.getClusters) {
				const clusters = geoObject.getClusters();
				clusters.map(cluster => {
					const geoObjects = cluster.getGeoObjects();

					const presets = [];
					geoObjects.map(obj => {
						const options = obj.options.getAll();
						if (options.preset) {
							const markerTypes = this.markerTypesByColor.filter(x => x.type === options.preset);
							if (markerTypes.length > 0) {
								presets.push({
									preset: options.preset,
									request: options.request,
									priority: markerTypes[0].priority,
								});
							}
						}
					});

					const clustererPreset = presets.length > 0 ? this.getHighestPriorityPreset(presets) : 'islands#grayClusterIcons';
					cluster.options.set('preset', clustererPreset);
				});
			}
		});
	}

	private setClusterBallonEvents(): void {
		this.clusterer.balloon.events.add('open', event => {
			const cluster = event.get('cluster');
			const activeObject = cluster.state.get('activeObject');
			const options = activeObject.options.getAll();

			this.setBalloonEngineersData(options.request, activeObject);

			// get opened cluster ballon id key
			const key = Object.keys(cluster).filter(x => x.toLowerCase().includes('id'))[0];
			this.openedClusterId = cluster[key];

			options.clusterBalloonEngineersLoaded = true;
		});

		this.clusterer.balloon.events.add('click', event => {
			const clusters = this.clusterer.getClusters();
			clusters.map(cluster => {
				const key = Object.keys(cluster).filter(x => x.toLowerCase().includes('id'))[0];
				if (cluster[key] === this.openedClusterId) {
					const activeObject = cluster.state.get('activeObject');
					const options = activeObject.options.getAll();

					if (this.openedClusterRequestId !== options.request.requestId)
						this.removeEngineerData();

					this.openedClusterRequestId = options.request.requestId;

					this.setBalloonEngineersData(options.request, activeObject);
					options.clusterBalloonEngineersLoaded = true;
				}
			});
		});

		this.clusterer.balloon.events.add('close', event => {
			this.openedClusterRequestId = null;
			this.removeEngineerData();
		});
	}

	private removeEngineerData(): void {
		this.planStartDate = null;
		this.assignToEngineerId = null;
		this.assignToEngineerName = null;
	}

	private getHighestPriorityPreset(presets: any): string {
		const maxPriority = presets.reduce((max, preset) => preset.priority < max ? preset.priority : max, presets[0].priority);
		return this.clusterTypesByColor.filter(x => x.priority === maxPriority)[0].type;
	}

	private onCompletedPolygon(polygon: any, context: MapYaComponent): void {
		const assignToEngineerRequests = this.getSelectedRequests(polygon);
		if (assignToEngineerRequests.length > 0) {
			const dialogRef = this.dialogService.open({ content: MultipleOperationsComponent, width: '70%', height: '70%' });

			const multipleOperationsComponent = <MultipleOperationsComponent>dialogRef.content.instance;
			multipleOperationsComponent.onSuccessAssignToEngineer = (selectedRequests) => context.onSuccessAssignToEngineer(selectedRequests, context);
			multipleOperationsComponent.requestIds = assignToEngineerRequests.map(m => m.requestId);
			multipleOperationsComponent.gridSelectedRequests = assignToEngineerRequests;
			multipleOperationsComponent.tabName = 'Назначить на инженера';
			multipleOperationsComponent.showChangeStatus = false;
			multipleOperationsComponent.useOwnCheckboxes = true;
			multipleOperationsComponent.heightPercent = 48;

			multipleOperationsComponent.applyEventOnClose.subscribe(() => {
				this.yaMapPolygon.deselectGetRequestButton();
			});
		} else {
			context.notificationService.info({
				title: 'Массовое назначение на инженера',
				message: 'Не выбрано ни одной заявки!',
				notificationType: NotificationType.SweetAlert
			});
		}
	}

	private getSelectedRequests(polygon: any): RequestListItem[] {
		return this.requests
			.filter(request => polygon.geometry.contains([request.latitude, request.longitude]))
			.map((value, _) => {
				return {
					requestId: value.requestId,
					customerContragentName: value.customerContragentName,
					externalId: value.externalId,
					requestTypeName: value.typeName,
					requestTypeId: value.requestTypeId,
					initTypeId: value.initTypeId,
					serviceCenterId: value.serviceCenterId,
					description: value.description,
					statusColor: value.statusColorCode,
					slaColorCode: value.slaColorCode,
					slaDueDate: value.slaDueDate,
					tidNumbers: value.tidNumbers,
					tspName: value.tspName,
					tspAddress: value.tspAddress,
					deviceModels: value.deviceModels,
					stoppedUntil: value.stoppedUntil,
					reasonForStopping: value.reasonForStopping,

					customerObjectLatitude: value.latitude,
					customerObjectLongitude: value.longitude,

					customerObjectDistrictName: null,
					planStartDate: null,

					utcTimezoneShift: value.utcTimezoneShift,
					isGroup: value.isGroup,
					isInGroup: value.isInGroup,
					isInGroupExt: value.isInGroupExt
				} as unknown as RequestListItem;
			});
	}
		
	private onSuccessAssignToEngineer(selectedRequests: RequestListItem[], context: MapYaComponent): void {
		if (selectedRequests.length > 0) {
			this.yaMapPolygon.clear(context.yaMap);
		}

		this.userChangedDefaultZoom = true;

		context.initMapData();
	}

	private clearClusterer(): void {
		if (this.clusterer) {
			const geoObjects = this.clusterer.getGeoObjects();
			geoObjects.forEach(geoObject => {
				if (!geoObject.balloon.isOpen()) {
					this.clusterer.remove(geoObject);
				}
			});
		}
	}

	private clearPlacemarks(): void {
		if (this.placemarks) {
			this.placemarks.forEach(placemark => {
				if (!placemark.balloon.isOpen()) {
					this.yaMap.geoObjects.remove(placemark);
				} else {
					const placemarkOptions = placemark.options.getAll();
					this.requestIdWithOpenedBalloon = placemarkOptions.request.requestId;
				}
			});
		}
	}

	private getAssignToEngineerForm(request: MapRequestDto): string {
		return `
			<hr class="mt-2 mb-2 w-100"/>
			<div class="assign-engineer-form-wrap form-group mb-1">
				<div class="d-block">
					<div class="mb-2">
						<input id="assign-engineer-date-${request.requestId}" class="form-control" placeholder="Выберите дату" style="font-size: 15px;"/>
					</div>
					<div>
						<select id="request-engineer-list-${request.requestId}" class="form-control" style="font-size: 16px;" disabled>
							<option>Выберите инженера</option>
						</select>
					</div>
				</div>
				<div class="pt-2">
					<button id="request-assign-engineer-${request.requestId}" class="btn btn-primary">Назначить на инженера</button>
				</div>
			</div>
		`;
	}

	private requestAssignToEngineer(request: MapRequestDto, placemark: any, assignToEngineerButton: HTMLElement) {
		assignToEngineerButton.setAttribute('disabled', 'true');

		this.savingEngineer = true;
		this.activitiesService
			.createUpdateActivityMultiple(
				[new CreateUpdateActivityMultiple(request.requestId, this.assignToEngineerId, true, this.planStartDate)])
			.subscribe(result => {
				if (result.isSuccessful) {
					let errors = result.data;

					if (errors && errors.length > 0) {
						const dialogRef = this.dialogService.open({
							content: MultipleOperationErrorResultComponent,
							width: '50%',
							height: '50%'
						});

						const multipleOperationErrorResultComponent = <MultipleOperationErrorResultComponent>dialogRef.content.instance;
						multipleOperationErrorResultComponent.errors = errors;
						multipleOperationErrorResultComponent.heightPercent = 50;

						assignToEngineerButton.removeAttribute('disabled');

					} else {
						this.notificationService.success({
							title: 'Назначение на инженера',
							message: `Инженер назначен по заявке ${request.requestId}`,
							notificationType: NotificationType.SweetAlert
						});

						request.statusId = <number>RequestStatus.AssignedToEngineer;
						request.serviceCenterEngineerName = this.assignToEngineerName;

						placemark.balloon.close();

						this.initMapData();
					}

					this.savingEngineer = false;
				}
			});
	}
}
