export class ServiceCenterEngineersData {
    serviceCenterId: number;
    requestId: number;
    planStartDate: Date;
    mustBeStorekeeper: boolean;

    constructor( requestId: number, serviceCenterId: number, planStartDate: Date, mustBeStorekeeper: boolean = false) {
        this.serviceCenterId = serviceCenterId;
        this.requestId = requestId;
        this.planStartDate = planStartDate;
        this.mustBeStorekeeper = mustBeStorekeeper;
    }
}
