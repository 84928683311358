import { Component, OnInit, EventEmitter, Output, Injector, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
	selector: 'popup-comment',
	templateUrl: './popup-comment.component.html',
	styleUrls: ['./popup-comment.component.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class PopupComment implements OnInit {
	
	@Input()
	commentObject: any;

	constructor(
		public dialog: DialogRef,
	) {

	}

	get cardHeight(): number {
		return window.innerHeight - 300;
	}

	ngOnInit() {
		
	}

	cancel() {
		if(this.dialog)
			this.dialog.close();
	}
}
