export class StoppingReasonTypeRuleDto {
    stoppingReasonTypeRuleId: number;
	contragentId: number;
	requestTypeId: number;
	stoppingReasonKindId: number;
	allowedRetryTimespan: number;
	allowedStoppingQuantity: number;
	notifyCoordination: boolean;
	sendCommentToCustomer: number;
	performerGroupId: number;
	stoppedForATime: number;
	changeBackActivityStatusOnResume: boolean;
	comment: string;
	isEnabled: boolean;
	description: string;
}