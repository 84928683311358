import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { EntityViewModel } from '../../../shared/models/core/EntityViewModel';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { LookupService } from '../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { ContragentsService } from '../../contragents/contragents.service';
import { UploadEvent, SelectEvent, FileRestrictions } from '@progress/kendo-angular-upload';
import { NotificationType } from '../../../core/services/notification-type';
import { UploadFile } from '../../../shared/models/attachment';
import { TidImportModel } from '../../../shared/models/tid/tid-import-model';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { TidsService } from '../../../shared/services/tids.service';
import { saveAs } from '@progress/kendo-file-saver';

@Component({
	selector: 'tid-import',
	templateUrl: './tid-import.page.html',
	styleUrls: [
		'./tid-import.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class TidImportPage extends ComponentPageBase<TidImportModel> implements OnInit {

	contragents: KeyValueObject[] = [];
	agreements: KeyValueObject[] = [];
	acquirers: KeyValueObject[] = [];

	canUpload: boolean;
    fileUploading: boolean;
	agreementsLoading: boolean;
	
	public dropDownVirtualSettings: any = {
		itemHeight: 28
	};

	reportFileRestrictions: FileRestrictions = {
		allowedExtensions: [".csv", ".xls", ".xlsx"],
	};

	public isNewObjectButtonEnabled: boolean;
	public isTidDuplicate: boolean;
	public loadingMerchants: boolean;

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		private lookupService: LookupService,
		private contragentsService: ContragentsService,
		private tidsService: TidsService,
	) {
		super(route, router, appService, notificationService, null);
		this.listPageRoutePath = 'categories/tids';
		this.appService.pageTitle = 'Импорт объектов';
		this.isNewObjectButtonEnabled = true;
	}

	ngOnInit() {

		this.entityViewModel = new EntityViewModel<TidImportModel>();
		this.entityViewModel.entity = new TidImportModel();
		this.canEditForm = true;
		this.isNew = true;

		this.initLookups();
	}

	initLookups() {
		this.lookupService.getData("contragents", {
			filter:{
				logic: "and",
				filters: [{field: 'contragentTypeId', operator: 'eq', value: 1}]
			}
		}).subscribe(data => this.contragents = data);
		this.contragentsService.contragentsAcquirers().subscribe(data => this.acquirers = data);
	}

	clientChange(value: number): void {
		this.entityViewModel.entity.agreementId = null;
		this.loadAgreementsByClient(value);
	}

	loadAgreementsByClient(clientId: number) {

		if (!clientId)
			return;

		var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentId", operator: "eq", value: clientId }] }
		};

		this.agreementsLoading = true;

		this.lookupService.getData("agreements", state).subscribe(data => {
			this.agreements = data;
			this.agreementsLoading = false;
		});
	}

	uploadEventHandler(e: UploadEvent) {
		const files = e.files;

		files.forEach((file) => {
			if (file) {
				const reader = new FileReader();

				const self = this;

				reader.onload = function (readerEvt: any) {
					const binaryString = <string>readerEvt.target.result;

					self.entityViewModel.entity.fileData = btoa(binaryString);
					self.entityViewModel.entity.fileName = file.name;
				};

				reader.readAsBinaryString(file.rawFile);
			}
		});
	}

	successUploadedEventHandler(e: any) {
		this.canUpload = true;
	}

	public selectEventHandler(e: SelectEvent): void {
		if (e.files.length > 1) {
            this.notificationService.error({
                title: 'Доступна загрузка лишь одного файла',
                message: "",
                notificationType: NotificationType.SweetAlert
            });
            e.preventDefault();
		}
	}

    importTerminals(){

        this.fileUploading = true;

        this.tidsService.importTids(this.entityViewModel.entity).subscribe(x => {

            if (x.isSuccessful) {

				if (x.data.fileName === ''){

					this.notificationService.success({
						title: 'Импорт объектов',
						message: 'Терминалы успешно прогружены',
						notificationType: NotificationType.Toast
					});

				} else {
					
					const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${x.data.fileData}`;
					saveAs(dataURI, x.data.fileName);
	
					this.notificationService.error({
						title: 'Импорт объектов',
						message: 'Подробности в скачанном файле',
						notificationType: NotificationType.Toast
					});
				}

                

            } else {
                this.notificationService.error({
                    title: 'Ошибка при построении отчета',
                    message: x.errorDescription,
                    notificationType: NotificationType.Toast
                });
            }

            this.fileUploading = false;
        });

    }
}