export enum ProjectWorkflowTransitionType {
  NoActionTransition = 1,
  SetPerformerGroupTransition = 2,
  SetPerformerUserAndGroupTransition = 3,
  SetReasonForStopping = 4,
  setDeviceSerialNumbers = 5,
  deviceToWarehouseAction = 6, // Переход при сдаче оборудования на склад
  receivingDeviceAtWarehouseAction = 7, // Переход при приёме оборудования на склад
  transitionConfirmationRequest = 8, //Запрос подтверждения перехода
  /**Показать сообщение (тост) */
  showToast = 9,
  /**Выбор транспортной компании и указание трек-номера */
  choosingTransportCompanyAndSpecifyingTrackNumber = 10,
  /**Подготовка конфигурации для передачи в TMS Gate */
  configurationPreparingForTransferToTMSGate = 11,

  /**Редактирование параметров демонт. оборудования */
  editingDismantledDevices = 12,

  /**Редактирование параметров демонт. оборудования */
  planned = 13
}
