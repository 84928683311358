import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EngubatorRequest } from '../../models/engubator/engubator.request';
import { NotificationService } from '../../../core/services/notification.service';
import 'rxjs/add/observable/empty'
import { EngubatorDataService } from './engubator.data.service';
import { ActionDataResult } from '../../models/core/ActionDataResult';
import { ActionResult } from '../../models/core/ActionResult';
import { Observable } from 'rxjs';

@Injectable()
export class EngubatorRequestsService extends EngubatorDataService<EngubatorRequest, EngubatorRequest> {
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'requests';
	}

	protected getNewTItem(): EngubatorRequest {
		return new EngubatorRequest();
	}

	protected onGetByIdResponse(response: ActionDataResult<EngubatorRequest>): void {
		if (!response.isSuccessful) {
			if (response.code === 102) { //заявка не найдена
				this.notificationService.warning({ title: '', message: 'Данная заявка отсутствует в сервисе подбора инженеров' });
			}
			else {
				this.notificationService.warning({ title: 'Сервис подбора инженеров', message: response.errorDescription });
			}
		}
	}

	public checkPrice(requestId: number, price: number): Observable<ActionResult> {
		let queryString = `/${requestId}/check?price=${price}`;
		return this.http.get<ActionResult>(`${this.baseUrl()}${queryString}`);
	}
}
