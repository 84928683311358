import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { BaseLookupService } from "../../../core/services/base.lookup.service";
import { NotificationService } from "../../../core/services/notification.service";

@Injectable()
export class EngubatorLookupService extends BaseLookupService {

    protected unauthorizedCode = 401;

	constructor(
		public http: HttpClient,
		public notificationService: NotificationService
	) { super(http, notificationService) }

    protected getBaseUrl(controllerName: string): string {
        return `${environment.engubatorAddress}v${this.controllerVersion}/${controllerName}`;
    }
}