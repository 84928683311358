export const environment = {
	baseUrl: window["env"]["baseUrl"],
	requestListRefreshTime: window["env"]["requestListRefreshTime"],
	idleTimeMinutes: window["env"]["idleTimeMinutes"],

	apiUrl: window["env"]["baseUrl"] + window["env"]["apiUrl"],
	engubatorAddress: (window["env"]["baseUrl"] + window["env"]["engubatorAddress"]),
	dogAddress: (window["env"]["baseUrl"] + window["env"]["dogAddress"]),
	geoServiceApiAddress: window["env"]["geoServiceApiAddress"],
	tmsgateAddress: (window["env"]["baseUrl"] + window["env"]["tmsgateAddress"]),
	finesAddress: (window["env"]["baseUrl"] + window["env"]["finesAddress"]),
	reportsApiUrl: (window["env"]["baseUrl"] + window["env"]["reportsApiUrl"]),
	instructionsAddress: (window["env"]["baseUrl"] + window["env"]["instructionsAddress"]),
	erpAddress: (window["env"]["baseUrl"] + window["env"]["erpAddress"]),
};
