import { Observable, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { DismantledSeriesImagesData } from "./dismantled-series-images-data";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AttachmentsService } from "../../../../services/attachments.service";
import { UploadFileData } from "../../../../models/attachment/upload-file-data";
import { UploadFile } from "../../../../models/attachment";
import { AttachmentType } from "../../../../enums";

@Component({
    selector: 'dismantled-series-images',
    templateUrl: './dismantled-series-images.component.html',
    styleUrls: ['./dismantled-series-images.component.scss']
})
export class DismantledSeriesImagesComponent
    extends BaseFlowComponent<DismantledSeriesImagesData>
{
    constructor(
        public attachmentsService: AttachmentsService
    ) {
        super();
    }

    @Input() uploadOnClick: boolean =false;
    @Input() uploading = false;

    @Output() onUploadFilesAdded: EventEmitter<UploadFile[]> = new EventEmitter<UploadFile[]>();
    @Output() onFileRemoved: EventEmitter<UploadFile> = new EventEmitter<UploadFile>();
    initData() {
    }

    saveChanges(): Observable<any> {
        if (this.hasSavedAttachments){
            this.onContinueEvent.emit(false);
        } else {
            this.onContinueEvent.emit(true);
        }
        return of({});
    }

    removeRequestDeviceImage(attachment: UploadFileData) {
        if (this.uploadOnClick){
            this.onFileRemoved.emit(attachment);
        } else {
            this.data.requestDeviceImages = this.removeAttachment(this.data.requestDeviceImages, attachment.uid);
        }
    }
    
    removeRequestDeviceSerialNumberImages(attachment: UploadFileData) {
        if (this.uploadOnClick){
            this.onFileRemoved.emit(attachment);
        } else {
            this.data.requestDeviceSerialNumberImages = this.removeAttachment(this.data.requestDeviceSerialNumberImages, attachment.uid);
        }
    }

    private removeAttachment(attachments: UploadFileData[] = [], removedAttachmentUid: string): UploadFileData[] {
        let attachment = attachments.find(x => x.uid == removedAttachmentUid);
        if (attachment.isUploadedOnServer) {
            attachment.isRemoved = true;
        }
        else {
            attachments = attachments.filter(x => x.uid != removedAttachmentUid);
        }

        return attachments;
    }

    uploadRequestDeviceImages(files: UploadFileData[]) {
        files
        .filter(x => !x.isUploadedOnServer)
        .forEach(x => {
            x.attachmentTypeId = AttachmentType.requestDeviceImage;
            x.isTemporary = true;
        });
		this.onUploadFilesAdded.emit(files);
	}

    uploadRequestDeviceSerialNumberImages(files: UploadFileData[]) {
        files
        .filter(x => !x.isUploadedOnServer)
        .forEach(x => {
            x.attachmentTypeId = AttachmentType.installedDevicesFotos;
            x.isTemporary = true;
        });
		this.onUploadFilesAdded.emit(files);
	}

    get hasSavedAttachments() {
        return this.data.requestDeviceImages.filter(x => x.isRemoved != true).length > 0 &&
            this.data.requestDeviceSerialNumberImages.filter(x => x.isRemoved != true).length > 0;
    }
}