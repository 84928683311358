import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';
import { AppService } from '../../../../app.service';
import { EntityViewModel } from '../../../../shared/models/core/EntityViewModel';
import { ComponentPageBase } from '../../../../shared/components/component-page-base/component-page-base';
import { MerchantsService } from '../../merchants.service';
import { Merchant } from '../../../../shared/models/merchant/Merchant';
import { LookupService } from '../../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../../shared/models/core/KeyValueObject';
import { CustomerObjectForm } from '../../../customer-objects/customer-object/customer-object-form/customer-object.form';
import { ContragentForm } from '../../../contragents/contragent/contragent-form/contragent.form';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ContragentsService } from '../../../contragents/contragents.service';
import { DataSourceRequestState } from '@progress/kendo-data-query';

const flatten = filter => {
	const filters = filter.filters;
	if (filters) {
		return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
	}
	return [];
};


@Component({
	selector: 'categories-merchant-form',
	templateUrl: './merchant.form.html',
	styleUrls: [
		'./merchant.form.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class MerchantForm extends ComponentPageBase<Merchant> implements OnInit {

	headerCustomerObjectName = "Новый объект ТСП";
	headerContragentName = "Новый контрагент";
	headerAcquirerName = "Новый банк эквайер";
	headerClientName = "Новый клиент";

	@Input() header: string;
	@Input() showCancelButton: boolean;
	@Output() afterSaveChangesEvent = new EventEmitter<KeyValueObject>();
	@Output() afterCancelEvent = new EventEmitter();

	acquirers: KeyValueObject[] = [];
	customerObjects: KeyValueObject[] = [];
	clientAgreements: KeyValueObject[] = [];
	clients: KeyValueObject[] = [];

	public isNewObjectButtonEnabled: boolean;

	public dropDownVirtualSettings: any = {
		itemHeight: 28
	};

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: MerchantsService,
		private lookupService: LookupService,
		private contragentsService: ContragentsService,
		private injector: Injector,
		protected dialogService: DialogService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/merchants';
		this.appService.pageTitle = 'Новое ТСП';
		this.isNewObjectButtonEnabled = true;
	}

	ngOnInit() {
		this.entityId = this.route.snapshot.paramMap.get('merchantId');

		this.initLookups();

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId).subscribe(vm => {
				this.entityName = `${vm.entity.mid} ${vm.entity.name}`;
				this.dataBind(vm);
			}, null, () => {
				this.loadAgreementsByClient(this.entityViewModel.entity.clientId);
			});

		} else {

			this.entityViewModel = new EntityViewModel<Merchant>();
			this.entityViewModel.entity = new Merchant();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;
		}
	}

	initLookups() {
		this.lookupService.getData("customer-objects", null).subscribe(data => this.customerObjects = data);
		this.contragentsService.contragentsAcquirers().subscribe(data => this.acquirers = data);
		var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: 1 }] }
		};
		this.lookupService.getData("contragents", state).subscribe(data => this.clients = data);
	}

	closeDialog() {
		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}

	afterSaveChanges() {
		if (this.entityViewModel.entity.contragentId) {
			this.contragentsService.getById(this.entityViewModel.entity.contragentId)
				.subscribe(s => this.entityViewModel.entity.contragentAddress = s.entity.address);
		}
		var val = new KeyValueObject(Number(this.entityId), this.entityViewModel.entity.name);
		this.afterSaveChangesEvent.emit(val);
		this.closeDialog();
	}

	afterCancel() {
		this.afterCancelEvent.emit();
		this.closeDialog();
	}

	afterCloseObjectChild() {
		this.isNewObjectButtonEnabled = true;
	}

	clientChange(clientId: number): void {
		this.entityViewModel.entity.clientAgreementId = null;
		this.loadAgreementsByClient(clientId);
	}

	loadAgreementsByClient(clientId: number): void {

		if (!clientId)
			return;

		var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentId", operator: "eq", value: clientId }] }
		};

		this.lookupService.getData("agreements", state).subscribe(data => {
			this.clientAgreements = data;
		});
	}
}
