import {ActivatedRoute, Router} from "@angular/router";
import {AppService} from "../../../../../app.service";
import {NotificationService} from "../../../../../core/services/notification.service";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ContragentDetail} from "../../../../../shared/models/contragent/contragent-detail";
import {ContragentBaseComponent} from "../contragent-base.component";
import {DataStateChangeEvent} from "@progress/kendo-angular-grid";
import {process} from "@progress/kendo-data-query";

@Component({
	selector: 'contragent-details',
	templateUrl: './contragent-details.component.html',
	styleUrls: [
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ContragentDetailsComponent extends ContragentBaseComponent<ContragentDetail> implements OnInit {

	@Input()
	public contragentId;

	@Input()
	public saveOnAddOrRemove: boolean = true;

	@Input()
	public details: ContragentDetail[] = [];

	@Output()
	public afterSaveChanges = new EventEmitter<any>();

	isNew: boolean = false;
	canEdit: boolean = false;
	choosenDetails: ContragentDetail;

	public gridState: DataStateChangeEvent = {
		skip: 0,
		take: 10,
		sort: [],
		filter: { logic: 'and', filters: [] }
	};

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
	) {
		super(appService);
	}

	ngOnInit(): void {
		this.gridData = this.details;
	}

	cellClick({ dataItem }: any): void {
		this.isNew = false;
		this.canEdit = false;
		this.choosenDetails = dataItem;
	}

	addNewDetails() {
		this.isNew = true;
		this.canEdit = true;
		this.choosenDetails = new ContragentDetail();
	}

	afterSave(result: ContragentDetail): void {
		if (this.afterSaveChanges)
			this.afterSaveChanges.emit();

		if (result && !this.details.includes(result)) {
			this.details.push(result);
		}

		this.isNew = false;
		this.choosenDetails = null;

		this.details = this.details.sort((a,b) => b.dateStart.getTime() - a.dateStart.getTime());
	}

	afterUpdate(): void {
		this.isNew = false;
		this.choosenDetails = null;

		this.details = this.details.sort((a,b) => b.dateStart.getTime() - a.dateStart.getTime());
	}

	afterRemove(result: ContragentDetail): void {
		if (result) {
			const details = this.details.filter(x => x.contragentDetailId === result.contragentDetailId)[0];
			this.details.splice(this.details.indexOf(details), 1);

			this.gridData = this.details;

			this.isNew = false;
			this.choosenDetails = null;
		}
	}

	dataStateChange(state: DataStateChangeEvent): void {
		const result = process(this.details, state);
		this.gridData = result.data;
		this.gridState = state;
	}

}
