import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../../core/services/data.service";
import { NotificationService } from "../../core/services/notification.service";
import { AttachmentTypeConfigurationDto } from "../models/attachment/attachment-type-configuration-dto";
import { AttachmentTypeConfigurationListItemDto } from "../models/attachment/attachment-type-configuration-list-item-dto";
import { KeyValueObject } from "../models/core/KeyValueObject";
import { ListViewModel } from "../models/core/ListViewModel";
import { ActionDataResult } from "../models/core/ActionDataResult";

@Injectable()
export class AttachmentTypeConfigurationsService extends DataService<AttachmentTypeConfigurationListItemDto, AttachmentTypeConfigurationDto> {

	constructor(
		protected http: HttpClient,
		protected notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'attachment-type-configurations';
	}

	getActivityStatuses(workFlowStepTypeId: number, requestTypeIds: number[]): Observable<KeyValueObject[]> {
		return this.postQuery<KeyValueObject[]>(`${this.baseUrl()}/by-request-types`, { workFlowStepTypeId, requestTypeIds }).map(x => x.data);
	}

	getRequiredAttachmentTypes(requestId: number, finalStateId: number): Observable<ActionDataResult<ListViewModel<AttachmentTypeConfigurationDto>>> {
		return this.http.post<ActionDataResult<ListViewModel<AttachmentTypeConfigurationDto>>>(`${this.baseUrl()}/get-required-attachment-types`, {
			requestId: requestId,
			finalStateId: finalStateId
		});
	}
}
