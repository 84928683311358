import { BaseObject } from '../core/BaseObject';
import { CustomerObject } from '../customerObject/CustomerObject';
import { Contragent } from '../contragent/Contragent';
import { Tid } from '../tid/tid';

export class MerchantFull extends BaseObject {
	merchantId: number;
	mid: string;
	name: string;
	customerObjectId: number;
	contragentId: number;
	customerObjectName: string;
	contragentName: string;
	acquirerId: number;
	acquirerName: string;
	requestId: number;
	distanceFromCityKm: number;

	tidNumber: string;
	customerObjectAddress: string;
	contragentAddress: string;
	contragentRequisites: string;
	contragentIntegraCode: string;
	serviceCenterId: number;

	customerObjectCreate: CustomerObject;
	contragentCreate: Contragent;
	tidCreate: Tid;

	isUpdateRequest: boolean = false;
	clientAgreementId: number;
	parseAddressDadata: boolean = true;
	tidServiceCategoryId: number;
}
