import { Component, Input } from "@angular/core";
import { BaseFilterCellComponent, FilterService } from "@progress/kendo-angular-grid";
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import {Align} from '@progress/kendo-angular-popup';

@Component({
    selector: 'date-range-filter-cell',
    styles: [`

        kendo-daterange {
            white-space: normal;
        }

        kendo-daterange > kendo-dateinput.range-filter {
            display: inline-block;
        }
        .k-button {
            margin-left: 5px;
        }
    ` ],
    template: `
        <kendo-daterange>

						<kendo-dateinput
							class="range-filter"
							kendoDateRangeStartInput
							[value]="start"
							(valueChange)="filterRange($event, end)"
							style="width:120px">
						</kendo-dateinput>
						-
						<kendo-dateinput
                            class="range-filter"
                            kendoDateRangeEndInput
                            [value]="end"
                            (valueChange)="filterRange(start, $event)"
                            style="width:120px">
						</kendo-dateinput>
					<div #anchor></div>
            <kendo-daterange-popup [anchor]="anchor" [popupAlign]="popupAlign">
                <ng-template kendoDateRangePopupTemplate>
                    <kendo-multiviewcalendar kendoDateRangeSelection>
                        <kendo-multiviewcalendar-messages today="Сегодня">
                        </kendo-multiviewcalendar-messages>
                    </kendo-multiviewcalendar>
                </ng-template>
            </kendo-daterange-popup>

        </kendo-daterange>
        <button
            *ngIf="hasFilter"
            class="k-button k-button-icon"
            title="Очистить"
            (click)="clearFilter()">
            <span class="k-icon k-i-filter-clear"></span>
        </button>
    `
})
export class DateRangeFilterCellComponent extends BaseFilterCellComponent {
    @Input()
    public filter: CompositeFilterDescriptor;

    @Input()
    public field: string;

    constructor(filterService: FilterService) {
        super(filterService);
    }

		public popupAlign: Align = { horizontal: 'left', vertical: 'top' };
    public get start(): Date {
        const first = this.findByOperator("gte");

        return (first || <FilterDescriptor>{}).value;
    }

    public get end(): Date {
        const end = this.findByOperator("lte");
        return (end || <FilterDescriptor>{}).value;
    }

    public get hasFilter(): boolean {
        return this.filtersByField(this.field).length > 0;
    }

    public clearFilter(): void {
        this.filterService.filter(
            this.removeFilter(this.field)
        );
    }

    public filterRange(start: Date, end: Date): void {
        if ((start && start.getFullYear() < 2000) || (end && end.getFullYear() < 2000)) {
            return;
        }

        this.filter = this.removeFilter(this.field);

        const filters = [];

        if (start) {
            filters.push({
                field: this.field,
                operator: "gte",
                value: start
            });
        }

        if (end) {
            filters.push({
                field: this.field,
                operator: "lte",
                value: end
            });
        }

        const root = this.filter || {
            logic: "and",
            filters: []
        };

        if (filters.length) {
            root.filters.push(...filters);
        }

        this.filterService.filter(root);
    }

    private findByOperator(op: string): FilterDescriptor {
        return this.filtersByField(this.field)
            .filter(({ operator }) => operator === op)[0];
    }
}
