export enum DeviceTypeEnum {
	/** Стационарный терминал */
	terminal = 2,

	/** Мобильный терминал */
	mobileTerminal = 3,

	/** Пин пад */
	pinPad = 4,

	/** SIM-карта */
	simCard = 7,

	/** Расходные материалы */
	consumables = 10,

	/** Интеллектуальный PIN-PAD */
	intelligentPinPad = 11,

	/** POS-терминал с фискальным накопителем */
	pos = 14
}
