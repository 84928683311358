import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { DaDataAddressComponent } from '../../../shared/components/dadata-address/dadata-address.component';
import { EntityViewModel } from '../../../shared/models/core/EntityViewModel';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { WarehouseArea } from '../../../shared/models/warehouse/warehouse-area';
import { WarehouseAreaCell } from '../../../shared/models/warehouse/warehouse-area-cell';
import { WarehouseItem } from '../../../shared/models/warehouse/warehouse-item';
import { LookupService } from '../../../shared/services/lookup.service';
import { WarehouseAreasService } from '../../../shared/services/warehouse-areas.service';
import { WarehouseCellsService } from '../../../shared/services/warehouse-cells.service';
import { WarehousesService } from '../../../shared/services/warehouses.service';
import { CategoryType } from "../../../shared/enums/category-type.enum";
import { AddressSuggestion } from '../../../shared/models/dadata-address/address-suggestion';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap/tabset/tabset.module';
import { WarehouseContact } from '../../../shared/models/warehouse/warehouse-contact';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NgForm } from '@angular/forms';
import { ServiceModeForm } from '../../service-modes/service-mode/service-mode-form/service-mode.form';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DataSourceRequestState } from '@progress/kendo-data-query';

@Component({
	selector: 'categories-warehouse',
	templateUrl: './warehouse.page.html',
	styleUrls: [
		'./warehouse.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class WarehousePage extends ComponentPageBase<WarehouseItem> implements OnInit {
	@ViewChild("daDataAddressControl") public daDataAddressControl: DaDataAddressComponent;
	@ViewChild("contactForm") contactForm: NgForm;
	@ViewChild("serviceModeForm") serviceModeForm: ServiceModeForm;

	contragents: KeyValueObject[] = [];
	departments: KeyValueObject[] = [];
	areaTypes: KeyValueObject[] = [];
	cellTypes: KeyValueObject[] = [];
	currentAreas: KeyValueObject[] = [];
	serviceModes: KeyValueObject[] = [];

	selectedWarehouseAreas: number[] = [];

	get visibleWarehouseCells(): WarehouseAreaCell[] {

		if (this.entityViewModel == null || this.entityViewModel.entity == null)
			return [];

		if (this.selectedWarehouseAreas.length == 0)
			return this.entityViewModel.entity.warehouseCells;

		return this.entityViewModel.entity.warehouseCells.filter(x => this.selectedWarehouseAreas.includes(x.warehouseAreaId));
	};

	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.warehouse;
	canEditAreaRow = false;

	contactsMainDataTemp: WarehouseContact[] = [];
	contactsSmzDataTemp: WarehouseContact[] = [];
	isContactsTablesChanged: boolean = false;
	isContactsTablesEditing: boolean = false;
	serviceModeChanged: boolean = false;

	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: WarehousesService,
		private warehouseAreasService: WarehouseAreasService,
		private warehouseCellsService: WarehouseCellsService,
		private lookupService: LookupService,
		protected dialogService: DialogService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/warehouses';
		this.appService.pageTitle = 'Новый склад';
	}

	ngOnInit(): void {
		this.entityId = this.route.snapshot.paramMap.get('warehouseId');

		if (this.entityId != null) {
			this.dataService.getById(+this.entityId).subscribe(vm => {
				this.entityName = vm.entity.name;
				this.dataBind(vm);
				this.contactsMainDataTemp = [...vm.entity.warehouseContactsNewMain];
				this.contactsSmzDataTemp = [...vm.entity.warehouseContactsNewSmz];
				this.initLookups(vm.entity.contragentOwnerId);
			});
		} else {
			this.entityViewModel = new EntityViewModel<WarehouseItem>();
			this.entityViewModel.entity = new WarehouseItem();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;
			this.initLookups(null);
		}


	}

	saveChangesCustom(): void {
		this.isContactsTablesChanged = false;
		this.serviceModeChanged = false;
		this.saveChanges();
	}

	dataBind(userViewModel: EntityViewModel<WarehouseItem>) {
		this.currentAreas = userViewModel.entity.warehouseAreas.map(v => new KeyValueObject(v.warehouseAreaId, v.name));

		super.dataBind(userViewModel);
		setTimeout(() => {
			if (this.daDataAddressControl) {
				this.daDataAddressControl.initValue(this.entityViewModel.entity.fullAddress);
			}
		}, 1000);
	}

	initLookups(contragentOwnerId: number) {
		if (!!contragentOwnerId) {
			var contragentsState: DataSourceRequestState = {
				skip: 0,
				take: null,
				filter: { logic: "and", filters: [{ field: "ContragentId", operator: "eq", value: contragentOwnerId }] }
			};
			this.lookupService.getData("contragents", contragentsState).subscribe(data => this.contragents = data);
		}
		else {
			this.lookupService.getData("contragents", null).subscribe(data => this.contragents = data);
		}

		this.lookupService.getData('warehouse-area-types', null).subscribe(data => this.areaTypes = data);
		this.lookupService.getData('warehouse-cell-types', null).subscribe(data => this.cellTypes = data);
		this.lookupService.getData('service-modes', null).subscribe(data => this.serviceModes = data);
	}

	onAddressChange(value: AddressSuggestion) {
		this.entityViewModel.entity.fullAddress = value.address;
		this.appService.MarkFormDirty(this.entityForm);
	}

	get isAddressValid(): boolean {
		if (!!this.daDataAddressControl) {
			return this.daDataAddressControl.isValid();
		} else {
			return true;
		}
	}

	loadWarehouseAreas(warehouseId: number) {
		this.warehouseAreasService.getByWarehouse(warehouseId).subscribe(data => this.entityViewModel.entity.warehouseAreas = data);
	}

	warehouseAreaSaveHandler(e: any) {
		const dataItem = e.dataItem as WarehouseArea;
		dataItem.warehouseId = this.entityViewModel.entity.warehouseId;
		const isNew = dataItem.warehouseAreaId === 0;
		const observable = isNew
			? this.warehouseAreasService.create(dataItem)
			: this.warehouseAreasService.update(dataItem, 'Помещение обновлено');
		observable.subscribe(x => {
			if (x.isSuccessful) {
				this.loadWarehouseAreas(this.entityViewModel.entity.warehouseId);
			}
		});
	}

	warehouseAreaRemoveHandler(dataItem: WarehouseArea) {
		this.warehouseAreasService.remove(`${dataItem.warehouseAreaId}`, 'Удаление помещения', 'Помещение успешно удалено')
			.subscribe(x => {
				this.loadWarehouseAreas(this.entityViewModel.entity.warehouseId);
			});
	}

	loadWarehouseCells(warehouseId: number) {
		this.warehouseCellsService.getByWarehouse(warehouseId).subscribe(data => this.entityViewModel.entity.warehouseCells = data);
	}

	warehouseCellSaveHandler(e: any) {
		const dataItem = e.dataItem as WarehouseAreaCell;
		const isNew = dataItem.warehouseCellId === 0;
		const observable = isNew
			? this.warehouseCellsService.create(dataItem)
			: this.warehouseCellsService.update(dataItem, 'Ячейка обновлена');
		observable.subscribe(x => {
			if (x.isSuccessful) {
				this.loadWarehouseCells(this.entityViewModel.entity.warehouseId);
			}
		});
	}

	warehouseCellRemoveHandler(dataItem: WarehouseAreaCell) {
		this.warehouseAreasService.remove(`${dataItem.warehouseCellId}`, 'Удаление ячейки', 'Ячейка успешно удалена')
			.subscribe(x => {
				this.loadWarehouseCells(this.entityViewModel.entity.warehouseId);
			});
	}

	tabChanged(e: NgbTabChangeEvent) {
		if (e.nextId === "main-tab") {
			setTimeout(() => {
				if (this.daDataAddressControl) {
					this.daDataAddressControl.initValue(this.entityViewModel.entity.fullAddress);
				}
			}, 1000);
		}

		this.activeIdString = e.nextId;
		this.isContactsTablesEditing = false;
	}

	warehouseContactSaveHandler(e: any) {
		if (this.contactsMainDataTemp !== this.entityViewModel.entity.warehouseContactsNewMain ||
			this.contactsSmzDataTemp !== this.entityViewModel.entity.warehouseContactsNewSmz) {
			this.isContactsTablesChanged = true;
		}

		this.isContactsTablesEditing = false;
	}

	warehouseContactRemoveHandler(e: any) {
		if (this.contactsMainDataTemp !== this.entityViewModel.entity.warehouseContactsNewMain ||
			this.contactsSmzDataTemp !== this.entityViewModel.entity.warehouseContactsNewSmz)
			this.isContactsTablesChanged = true;
	}

	warehouseContactAddHandler(e: any) {
		this.isContactsTablesEditing = true;
	}

	warehouseContactEditHandler(e: any) {
		this.isContactsTablesEditing = true;
	}

	warehouseContactCancelHandler(e: any) {
		this.isContactsTablesEditing = false;
	}

	get isSaveButtonDisabled(): boolean {
		return ((!this.entityForm || this.entityForm.pristine) && !this.isContactsTablesChanged && !this.serviceModeChanged) ||
			(!this.entityForm.valid && !this.contactForm.valid) ||
			this.isContactsTablesEditing;
	}

	get createNewContactMain(): any {
		let self = this;
		return function () {
			const contact = new WarehouseContact();
			contact.forSmz = false;
			contact.contragentId = self.entityViewModel.entity.contragentOwnerId;
			contact.warehouseId = self.entityViewModel.entity.warehouseId;
			contact.typeId = 52;
			return contact;
		};
	}

	get createNewContactSmz(): any {
		let self = this;
		return function () {
			const contact = new WarehouseContact();
			contact.forSmz = true;
			contact.contragentId = self.entityViewModel.entity.contragentOwnerId;
			contact.warehouseId = self.entityViewModel.entity.warehouseId;
			contact.typeId = 52;
			return contact;
		};
	}

	public modeChanged() {
		setTimeout(() => {
			this.serviceModeForm.extRefresh(this.entityViewModel.entity.modeId);
			this.serviceModeChanged = true;
		}, 100);
	}

	public openAddModeDialog() {
		const dialogRef = this.dialogService.open({ content: ServiceModeForm, width: '80%', height: '60%' });
		const serviceModeFormDialogRef = <ServiceModeForm>dialogRef.content.instance;
		serviceModeFormDialogRef.showCancelButton = true;
		serviceModeFormDialogRef.afterSaveChangesEvent.subscribe(value => this.afterModeSave(value));
	}

	public afterModeSave(data: KeyValueObject) {
		this.lookupService.getData('service-modes', null).subscribe(sm => {
			this.serviceModes = sm;
			this.entityViewModel.entity.modeId = data.id;
			this.modeChanged();
		});
	}
}
