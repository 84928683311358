import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {pluck, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SelectableSettings} from '@progress/kendo-angular-grid';

import {AttachmentsService} from '../../services/attachments.service';

import {Attachment, UploadFile, UploadAttachmentsModel } from '../../models/attachment';
import {DataSourceRequestState} from '@progress/kendo-data-query';
import {AttachmentType, CustomClaim} from '../../enums';
import {DialogRef} from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-device-attachments',
  templateUrl: './device-attachments.component.html',
  styleUrls: ['./device-attachments.component.scss']
})
export class DeviceAttachmentsComponent implements OnInit, OnDestroy {

	public customClaims = CustomClaim;

	@Input() public requestDeviceId: number;
	@Input() public requestId: number;
	public  attachExtension = ['.jpg', '.jpeg', '.tif', 'tiff', '.png'];
	public loading = false;
	public selectableSettings: SelectableSettings = { checkboxOnly: false, mode: 'single' };
	public attachments: Attachment[];
	public currentAttachmentId = 0;
	@Output() public uploadedHandler: EventEmitter<unknown> = new EventEmitter<unknown>();

	private isAttachmentChanged = false;
	private _destroy$ = new Subject<boolean>();

  constructor(private _attachmentService: AttachmentsService, public dialog: DialogRef) { }

  public ngOnInit() {
  	this._loadData();
  }

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public onSelectedAttachmentChange (attachmentIds: number[]) {
  	if (attachmentIds && attachmentIds.length >= 1)	{	this.currentAttachmentId = attachmentIds[0]; }
	}

	public onAttachmentRemoveHandler({ dataItem }: any) {
		this.loading = true;
		this._attachmentService.remove(`${(<Attachment>dataItem).attachmentId}`, 'Файл удален', '')
			.pipe(
				tap(() => this.loading = false),
				takeUntil(this._destroy$))
			.subscribe(x => {
				if (x.isSuccessful) {
					this.isAttachmentChanged = true;
					this._loadData();
				}
			});
	}

	public onAttachmentsUpload(attachments: UploadFile[]) {
		const uploadModel = new UploadAttachmentsModel({
			requestId: this.requestId,
			requestUninstallDeviceId: this.requestDeviceId,
			attachments: attachments,
			attachmentTypeId: AttachmentType.requestDeviceImage
		});
		const state = this._getState();
		this.loading = true;
		this._attachmentService.upload(uploadModel)
			.pipe(
				switchMap(() =>
					this._attachmentService.list(state)
					.pipe(
						tap(() => this.loading = false),
						pluck('data'))),
				takeUntil(this._destroy$))
				.subscribe(data => {
					this.attachments = data;
					this.isAttachmentChanged = true;
				});
	}

	public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

	cancel() {
  	this.isAttachmentChanged && this.uploadedHandler.emit();
		this.dialog.close();
	}

	private _loadData(): void {
  	this.loading = true;
		const state = this._getState();
		this._attachmentService.list(state)
			.pipe(
				tap(() => this.loading = false),
				pluck('data'),
				takeUntil(this._destroy$))
			.subscribe(data => this.attachments = data);
	}

	private _getState(): DataSourceRequestState {
		const state: DataSourceRequestState = {
			skip: 0,
			filter: {
				logic: 'or',
				filters: [
					{
						field: 'requestUninstallDeviceId',
						operator: 'eq',
						value: this.requestDeviceId
					}
				],
			}
		};
		return state;
	}

}
