import { Component, OnInit } from "@angular/core";
import { NotificationType } from "../../core/services/notification-type";
import { NotificationService } from "../../core/services/notification.service";
import { ReportsService } from "../../shared/services/reports.service";
import { ReportPageBase } from "../components/report-page-base";
import { saveAs } from 'file-saver';
import { addDays } from '@progress/kendo-date-math';
import { AppService } from "../../app.service";

@Component({
	selector: 'psb-report-page',
	templateUrl: './psb-report.page.html',
	styleUrls: [
		'./psb-report.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class PsbReportPage extends ReportPageBase implements OnInit {

	constructor(
		private reportsService: ReportsService,
		private notificationService: NotificationService,
		private appService: AppService
	) {
		super();
		appService.pageTitle = 'Отчет по заявкам ПСБ'
	}

	ngOnInit(): void {
		
	}

	generateReport() {

		this.generateReportByDateRange((startDate, endDate) => {

			this.reportsService.getPsb(startDate, endDate)
				.subscribe(blob => {
					if (blob.type === 'application/octet-stream') {
						const localDate = new Date().toLocaleString().replace(',', '');
						const trueEndDate = addDays(new Date(endDate), -1);
						saveAs(blob, `Отчет_по_выполненным_заявкам_от_${new Date(startDate).toLocaleDateString()}_по_${trueEndDate.toLocaleDateString()}_создан_${localDate}.xlsx`);
						this.dataGenerating = false;

					} else if (blob.type === 'application/json') {
						blob
							.text()
							.then(result => {
								const response = JSON.parse(result);
								if (!response.isSuccessful) {
									this.notificationService.error({
										title: 'Ошибка',
										message: response.errorDescription as string,
										notificationType: NotificationType.SweetAlert
									});
								}

								this.dataGenerating = false;
							});
					}
				});

		});
		
	}

}
