import { Component, Input, OnInit, Injector, ViewChild, AfterViewInit, AfterContentInit, DoCheck, AfterViewChecked, ChangeDetectorRef, Output, EventEmitter, OnDestroy, ViewChildren } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { RequestListItem } from '../../models/request/request-list-item';
import { RequestsService } from '../../services/requests.service';
import { LookupService } from '../../services/lookup.service';
import { KeyValueObject } from '../../models/core/KeyValueObject';
import { NotificationService } from '../../../core/services/notification.service';
import { ChangeStatusModel } from '../../models/change-status-models/change-status-model';
import { NotificationType } from '../../../core/services/notification-type';
import { MultipleOperationErrorResultComponent } from './multiple-operation-error-result/multiple-operation-error-result.component';
import { ChangePerformerUser } from '../../models/request/change-performer-user';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivitiesService } from '../../services/activities.service';
import { CreateUpdateActivityMultiple } from '../../models/request/create-update-activity-multiple';
import { ServiceCentersService } from '../../services/service-centers.service';
import { ServiceCenterEngineers } from '../../models/engineers/service-center-engineers';
import { ServiceCenterEngineersData } from '../../models/engineers/service-center-engineers-data';
import { UserGroupsService } from '../../services/user-groups.service';
import { RequestInitTypeEnum, RequestStatus, RequestType } from '../../enums';
import { EngineerLookupModel } from '../../models/engineers/engineer-lookup-model';
import { groupBy, orderBy, GroupResult, DataSourceRequestState } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { ActionDataResult } from '../../models/core/ActionDataResult';
import { MultipleError } from '../../models/request/multiple-error';
import { RequestGroupDto } from '../../models/request/request-group-dto';
import { PerformerGroupModel } from '../../models/change-status-models/performer-group-model';
import { CommentKindEnum } from '../../enums/comment-kind.enum';

@Component({
	selector: 'multiple-operations',
	templateUrl: './multiple-operations.component.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./multiple-operations.component.scss'
	]
})

export class MultipleOperationsComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() requestIds: number[] = [];
	@Input() tabName: string;
	@Input() heightPercent: number;
	@Input() useOwnCheckboxes: boolean = false;
	@Input() showChangeStatus: boolean = true;
	@Input() showChangeUserGroup: boolean = true;

	private plannedDateSubject: Subject<any> = new Subject();
	private engineerFilterSubject: Subject<string> = new Subject();

	@Output() applyEventOnResetCheckboxes = new EventEmitter();
	@Output() applyEventOnClose = new EventEmitter();

	@ViewChild('muTabSet') muTabSet;
	@ViewChild('activityGrid') activityGrid: GridComponent;
	minDate: Date = new Date(new Date().getTime() + 5 * 60000);
	requests: RequestListItem[] = [];
	requestsStatuses: KeyValueObject[] = [];
	performerUsers: KeyValueObject[] = [];
	engineers: EngineerLookupModel[] = [];
	stoppingReasons: KeyValueObject[] = [];
	engineersGrouped: any[] = [];
	usersByGroup: KeyValueObject[];

	stoppedUntil?: Date;
	comment: string;
    commentKind: CommentKindEnum = CommentKindEnum.general;
	stoppingReason?: number = null;

	requestStatusId: number;
	performerUserId: number;
	engineerId: number;
	performerUserGroupId: number;
	userByGroupId: number;

	loading: boolean = false;
	loadingEngineers: boolean = false;
	isForce: boolean = false;
	isSendLastComment: boolean = false;
	activityGridHeight: number;
	stateGridHeight: number;
	editedRowIndex: number;
	isChangeActivity: boolean;
	emptyEngineersMessage: string = "Не найдено инженеров";
	saveByGroupDisabled: boolean = false;

	private deselectedRequests: RequestListItem[] = [];

	public showChangeStatusAdditionalControls: boolean = false;
	public showComment: boolean = false;

	public engineersDropDownVirtualSettings: any = { itemHeight: 56 };

	private _destroy$: Subject<boolean> = new Subject<boolean>();

	gridSelectedRequests: RequestListItem[] = [];
	allRequests: RequestListItem[] = [];
	gridSelectedRequestsCallback = (args) => args.dataItem;

	onSuccessAssignToEngineer: (selectedRequests: RequestListItem[]) => void;

	constructor(
		private injector: Injector,
		protected requestsService: RequestsService,
		private lookupService: LookupService,
		protected dialogService: DialogService,
		private cdRef: ChangeDetectorRef,
		private userGroupsService: UserGroupsService,
		private activitiesService: ActivitiesService,
		private serviceCentersService: ServiceCentersService,
		protected notificationService: NotificationService) {

	}

	ngOnInit(): void {
		this.activityGridHeight = window.innerHeight * (this.heightPercent / 100) - 100;
		this.stateGridHeight = window.innerHeight * (this.heightPercent / 100) - 150;
		this.loading = true;
		var visibleColumnNames: string[] = ["requestIdStr", "serviceCenterName", "customerObjectDistrictName", "slaDueDateMoscow", "externalId"];
		var state: DataSourceRequestState = {
			filter: {
				logic: "or",
				filters: this.requestIds.map(requestId => ({
					field: 'requestId',
					operator: 'eq',
					value: requestId
				})),
			}
		};

		this.lookupService.getData('stopping-reasons', null)
			.pipe(takeUntil(this._destroy$))
			.subscribe(data => this.stoppingReasons = data);

		this.requestsService.requestList(state, visibleColumnNames).subscribe(val => {
			this.requests = val.data;
			this.allRequests = this.requests.map(a => Object.assign({}, a));

			let now = new Date();
			let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

			this.requests.forEach(e => {
				e.planStartDateLocal = new Date(utc.getTime() + (e.utcTimezoneShift * 60 * 60 * 1000));
			});

			this.loadEngineers();

			this.plannedDateSubject.pipe(delay(1000)).subscribe(() => {
				this.loadEngineers();
			});

			this.engineerFilterSubject.pipe(delay(1000)).subscribe(query => {

				this.loadingEngineers = true;
				const predicate = (item: EngineerLookupModel) => item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
				this.engineersGrouped = orderBy(<GroupResult[]>groupBy(this.engineers.filter(predicate), [{ field: "region" }]), [{ field: "value", dir: "asc" }]);
				this.loadingEngineers = false;

			});

			this.loading = false;
		});

		this.lookupService.getData("request-statuses", null).subscribe(data => this.requestsStatuses = data);

	}

	loadEngineers() {

		this.engineersGrouped = [];

		if (this.requests.filter(x => x.requestTypeId !== <number>RequestType.warehouseRefill
			&& x.requestTypeId !== <number>RequestType.warehouseTransfer
			&& x.requestTypeId !== <number>RequestType.foreighWarehouseTransfer
			&& x.requestTypeId !== <number>RequestType.internalWarehouseTransfer
			&& x.requestTypeId !== <number>RequestType.warehousePosting).every(x => x.serviceCenterId == null)) {
			this.emptyEngineersMessage = 'Выбраны заявки с отсутствующими сервисными центрами';
			return;
		}

		var requestsGroupedByServiceCenter = <GroupResult[]>groupBy(this.requests.filter(x => x.requestTypeId !== <number>RequestType.warehouseRefill
			&& x.requestTypeId !== <number>RequestType.warehouseTransfer
			&& x.requestTypeId !== <number>RequestType.foreighWarehouseTransfer
			&& x.requestTypeId !== <number>RequestType.internalWarehouseTransfer
			&& x.requestTypeId !== <number>RequestType.warehousePosting), [{ field: "serviceCenterId" }]);

		if (requestsGroupedByServiceCenter.length > 1) {
			this.emptyEngineersMessage = 'Выбраны заявки с разными сервисными центрами';
			return;
		}

		if (!this.requests.filter(x =>
			x.initTypeId === <number>RequestInitTypeEnum.blockChain &&
			x.requestTypeId !== <number>RequestType.warehouseRefill &&
			x.requestTypeId !== <number>RequestType.foreighWarehouseTransfer &&
			x.requestTypeId !== <number>RequestType.internalWarehouseTransfer &&
			x.requestTypeId !== <number>RequestType.issueFromWarehouse &&
			x.requestTypeId !== <number>RequestType.warehouseTransfer &&
			x.requestTypeId !== <number>RequestType.warehousePosting &&
			x.requestTypeId !== <number>RequestType.receiveToWarehouse &&
			x.requestTypeId !== <number>RequestType.issueAndReceiveToWarehouse &&
			(x.commonData === null ||
				!!!x.commonData.isUnknownDistanceRequest) &&
			x.lastActivityStatusId === null
		).every(x => this.isChangeActivity ? x.partnerStatusAlias !== 'takenToWorkSP' : x.partnerStatusAlias ==='takenToWorkSP')) {
			var wrongStateTaskIds = this.requests.filter(x => x.initTypeId === <number>RequestInitTypeEnum.blockChain
														&& (this.isChangeActivity ? x.partnerStatusAlias === 'takenToWorkSP' : x.partnerStatusAlias !== 'takenToWorkSP'))
				.map(x => x.externalId);
			this.emptyEngineersMessage = `В заявках '${wrongStateTaskIds.join(', ')}' указан статус в системе партнера ${this.isChangeActivity ? '' : 'отличный от'} 'takenToWorkSP'`;
			return;
		}

		if (!this.requests.filter(x =>
			x.initTypeId === <number>RequestInitTypeEnum.blockChain &&
			x.requestTypeId !== <number>RequestType.warehouseRefill &&
			x.requestTypeId !== <number>RequestType.foreighWarehouseTransfer &&
			x.requestTypeId !== <number>RequestType.internalWarehouseTransfer &&
			x.requestTypeId !== <number>RequestType.issueFromWarehouse &&
			x.requestTypeId !== <number>RequestType.warehouseTransfer &&
			x.requestTypeId !== <number>RequestType.warehousePosting &&
			x.requestTypeId !== <number>RequestType.receiveToWarehouse &&
			x.requestTypeId !== <number>RequestType.issueAndReceiveToWarehouse &&
			x.commonData !== null &&
			!!x.commonData.isUnknownDistanceRequest &&
			x.lastActivityStatusId === null
		).every(x => this.isChangeActivity ? x.partnerStatusAlias !== 'stopedSLA' : x.partnerStatusAlias ==='stopedSLA')) {
			var wrongStateTaskIds = this.requests.filter(x => x.initTypeId === <number>RequestInitTypeEnum.blockChain
														&& (this.isChangeActivity ? x.partnerStatusAlias === 'stopedSLA' : x.partnerStatusAlias !== 'stopedSLA'))
				.map(x => x.externalId);
			this.emptyEngineersMessage = `В заявках '${wrongStateTaskIds.join(', ')}' указан статус в системе партнера ${this.isChangeActivity ? '' : 'отличный от'} 'stopedSLA'`;
			return;
		}

		let engineersMultipleSDateModel = this.requests.map(m => new ServiceCenterEngineersData(m.requestId, m.serviceCenterId, m.planStartDateLocal));

		let engineersMultipleScModel = new ServiceCenterEngineers(engineersMultipleSDateModel);

		this.loadingEngineers = true;
		this.serviceCentersService.engineersMultipleSc(engineersMultipleScModel).subscribe(x => {
			if (x.isSuccessful) {
				this.engineers = x.data;
				this.engineersGrouped = orderBy(<GroupResult[]>groupBy(this.engineers, [{ field: "region" }]), [{ field: "value", dir: "asc" }]);
			}

			this.loadingEngineers = false;
		});
	}

	ngAfterViewInit() {
		this.muTabSet.activeId = this.tabName;

		if (this.muTabSet.tabs.length === 1
			&& this.muTabSet.tabs.first.id === "Назначить на инженера") {
			this.checkGroupRequests(this.muTabSet.tabs.first.id);
		}

		this.cdRef.detectChanges();
	}

	public isEngineerDisabled(itemArgs: { dataItem: EngineerLookupModel, index: number }) {
		return !itemArgs.dataItem.isAvailable;
	}

	public handleEngineersFilter(query: string): void {
		this.engineerFilterSubject.next(query);
	}

	public engineersFilterCore(query: string): void {

	}

	public plannedDateChange(value: Date): void {
		this.plannedDateSubject.next(null);
	}

	private close(resetCheckboxes: boolean = false): void {
		if (resetCheckboxes && this.applyEventOnResetCheckboxes) {
			this.applyEventOnResetCheckboxes.emit();
		}

		if (this.applyEventOnClose) {
			this.applyEventOnClose.emit();
		}

		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}

	public saveDataDisabled(): boolean {
		switch (this.muTabSet.activeId) {
			case "Изменить статус":
				switch (this.requestStatusId) {
					case RequestStatus.Stopped:
						return !this.requestStatusId || !this.stoppedUntil || !this.comment || this.stoppedUntil < this.minDate || !this.stoppingReason;
					case RequestStatus.Cancelled:
						return !this.requestStatusId || !this.comment;
					default:
						return !this.requestStatusId;
				}
			case "Изменить группу ответственного":
				return !this.performerUserGroupId;
			case "Назначить на исполнителя":
				return !this.performerUserId;
			case "Назначить на инженера":
				if (this.activityGrid) {
					//return !this.engineerId || this.grid.isEditing();
					return !this.engineerId || this.saveByGroupDisabled;
				}

				return !this.engineerId || this.saveByGroupDisabled;
			default:
				return true;
		}
	}

	private changeStatusMultiple(changeStatusModels: ChangeStatusModel[]): void {
		this.requestsService.changeStatusMultiple(changeStatusModels).subscribe(x => {
			if (x.isSuccessful) {
				let errors = x.data;
				this.close(true);
				this.loading = false;
				if (errors && errors.length > 0) {
					const dialogRef = this.dialogService.open({ content: MultipleOperationErrorResultComponent, width: '50%', height: '50%' });

					const multipleOperationErrorResultComponent = <MultipleOperationErrorResultComponent>dialogRef.content.instance;
					multipleOperationErrorResultComponent.errors = errors;
					multipleOperationErrorResultComponent.heightPercent = 50;
				} else {
					this.notificationService.success({
						title: 'Массовое изменение состояний заявок',
						message: 'Состояния всех заявок изменены успешно!',
						notificationType: NotificationType.SweetAlert
					});
				}

			}
		}, () => {
			this.loading = false;
		});
	}

	private changeUserGroupMultiple(changeUserGroupModels: PerformerGroupModel[]): void {
		this.requestsService.changePerformerUserGroupMultiple(changeUserGroupModels).subscribe(x => {
			if (x.isSuccessful) {
				let errors = x.data;
				this.close(true);
				this.loading = false;
				if (errors && errors.length > 0) {
					const dialogRef = this.dialogService.open({ content: MultipleOperationErrorResultComponent, width: '50%', height: '50%' });

					const multipleOperationErrorResultComponent = <MultipleOperationErrorResultComponent>dialogRef.content.instance;
					multipleOperationErrorResultComponent.errors = errors;
					multipleOperationErrorResultComponent.heightPercent = 50;
					multipleOperationErrorResultComponent.headerText = "Ошибки массового изменения группы ответственного"
				} else {
					this.notificationService.success({
						title: 'Массовое изменение группы ответственного',
						message: 'Группы ответственного всех заявок изменены успешно!',
						notificationType: NotificationType.SweetAlert
					});
				}

			}
		}, () => {
			this.loading = false;
		});
	}

	public saveData(): void {
		switch (this.muTabSet.activeId) {
			case "Изменить статус":

				this.loading = true;
				let changeStatusModels: ChangeStatusModel[] = [];

				//Хардкод для статуса "Остановлено"
				switch (this.requestStatusId) {
					case RequestStatus.Stopped:

						changeStatusModels = this.requests.map(m => new ChangeStatusModel(m.requestId
							, this.requestStatusId
							, this.performerUserGroupId
							, this.userByGroupId
							, this.GetUtcDate(new Date(this.stoppedUntil), m.utcTimezoneShift)
							, this.comment
							, this.isForce
							, this.isSendLastComment
							, this.commentKind
							, null
							, this.stoppingReason));

						this.changeStatusMultiple(changeStatusModels);

						break;

					//Хардкод для статуса "Отменена"
					case RequestStatus.Cancelled:
						changeStatusModels = this.requests.map(m => new ChangeStatusModel(
							m.requestId
							, this.requestStatusId
							, this.performerUserGroupId
							, this.userByGroupId
							, null
							, this.comment
							, this.isForce
							, this.isSendLastComment));

						this.changeStatusMultiple(changeStatusModels);

						break;

					default:
						changeStatusModels = this.requests.map(m => new ChangeStatusModel(
							m.requestId
							, this.requestStatusId
							, this.performerUserGroupId
							, this.userByGroupId
							, null
							, null
							, this.isForce
							, this.isSendLastComment));

						this.changeStatusMultiple(changeStatusModels);

						break;
				}

				break;
			case "Изменить группу ответственного":
				this.loading = true;
				let changeUserGroupModels: PerformerGroupModel[] = [];
				changeUserGroupModels = this.requests.map(m => new PerformerGroupModel(m.requestId,
					this.requestStatusId,
					this.performerUserGroupId,
					null,
					null));
				this.changeUserGroupMultiple(changeUserGroupModels);
				break;
			case "Назначить на исполнителя":
				let changePerformerUserModels = this.requests.map(m => new ChangePerformerUser(m.requestId, this.performerUserId));
				this.loading = true;

				this.requestsService.changePerformerUserMultiple(changePerformerUserModels).subscribe(x => {
					if (x.isSuccessful) {
						let errors = x.data;
						this.close(true);
						this.loading = false;
						if (errors && errors.length > 0) {
							const dialogRef = this.dialogService.open({ content: MultipleOperationErrorResultComponent, width: '50%', height: '50%' });

							const multipleOperationErrorResultComponent = <MultipleOperationErrorResultComponent>dialogRef.content.instance;
							multipleOperationErrorResultComponent.errors = errors;
							multipleOperationErrorResultComponent.heightPercent = 50;
						} else {
							this.notificationService.success({
								title: 'Массовое изменение исполнителей заявок',
								message: 'Исполнители всех заявок изменены успешно!',
								notificationType: NotificationType.SweetAlert
							});
						}
					}
				}, () => {
					this.loading = false;
				});
				break;
			case "Назначить на инженера":
				let changeEngineerModels = this.requests.map(m => new CreateUpdateActivityMultiple(m.requestId, this.engineerId, this.isChangeActivity, m.planStartDateLocal));
				this.loading = true;

				let groupRequests = this.allRequests.filter(f => f.isGroup);
				if (!!groupRequests && groupRequests.length > 0) {
					let requestsHierarchi: string[] = [];
					groupRequests.forEach((group, groupIndex) => {
						this.requestsService.getRequestGroup(group.requestId).subscribe(res => {
							res[0].childs.forEach((child, childIndex) => {
								if (!requestsHierarchi.includes(`<span style="font-weight:bold">${group.requestId.toString()}(${groupIndex + 1}):</span><br>`)) {
									requestsHierarchi.push(`<span style="font-weight:bold">${group.requestId.toString()}(${groupIndex + 1}):</span><br>`);
								}
								if (!this.allRequests.map(m => m.requestId.toString()).includes(child.requestId.toString())) {
									requestsHierarchi.push(`&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<span style="color:red">${child.requestId.toString()}(${groupIndex + 1}.${childIndex + 1})</span><br>`);
								} else {
									requestsHierarchi.push(`&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp${child.requestId.toString()}(${groupIndex + 1}.${childIndex + 1})<br>`);
								}
							});

							if (groupIndex === groupRequests.length - 1) {
								if (this.getAbsentChildsCount(requestsHierarchi) > 0) {
									this.notificationService.confirmation({
										title: 'Внимание!',
										message: `В списке присутствуют групповые заявки ${groupRequests.map(m => m.requestId).join(', ')}
                                    Назначение инженера применится ко всем заявкам, включенным в группу:<br>${requestsHierarchi.join('')}`,
										enableHtml: true,
										confirmButtonText: 'Применить',
										cancelButtonText: 'Отмена',
										showCloseButton: false,

									}, () => {
										this.createUpdateActivityMultiply(changeEngineerModels);
									}, () => {
										this.loading = false;
									});
								} else {
									this.createUpdateActivityMultiply(changeEngineerModels);
								}
							}
						});
					});


				} else {
					this.createUpdateActivityMultiply(changeEngineerModels);
				}

				break;
			default:
				break;
		}
	}

	private GetUtcDate(dateTime: Date, utcTimezoneShift: number) {
		dateTime.setHours(dateTime.getHours() - utcTimezoneShift)
		return dateTime;
	}
	
	private getAbsentChildsCount(requestsHierarchi: string[]): number {
		let count: number = 0;
		requestsHierarchi.forEach(f => {
			if (f.includes("style=\"color:red\"")) {
				count++;
			}
		});

		return count;
	}

	public createUpdateActivityMultiply(changeEngineerModels: CreateUpdateActivityMultiple[]): void {
		this.activitiesService.createUpdateActivityMultiple(changeEngineerModels)
			.pipe(result => {
				this.close(true);
				return result;
			})
			.subscribe(x => {
				if (x.isSuccessful) {
					let errors = x.data;

					this.close(true);

					if (errors && errors.length > 0) {
						const dialogRef = this.dialogService.open({
							content: MultipleOperationErrorResultComponent,
							width: '50%',
							height: '50%'
						});

						const multipleOperationErrorResultComponent = <MultipleOperationErrorResultComponent>dialogRef.content.instance;
						multipleOperationErrorResultComponent.errors = errors;
						multipleOperationErrorResultComponent.heightPercent = 50;

						multipleOperationErrorResultComponent.onCancel.subscribe(() => {
							this.applyOnSuccessAssignToEngineer(x);
						});
					} else {
						this.notificationService.success({
							title: 'Массовое назначение инженеров',
							message: 'Все инженеры назначены успешно!',
							notificationType: NotificationType.SweetAlert
						});

						this.applyOnSuccessAssignToEngineer(x);
					}

				}
			}), () => {
				this.loading = false;
			};
	}

	public cellClick({ dataItem }: any): void {
		var requestId = (<RequestListItem>dataItem).requestId;
		var win = window.open(`/requests/${requestId}`, '_blank');
	}

	public editHandler({ sender, rowIndex, dataItem }) {
		this.closeEditor(sender);

		this.editedRowIndex = rowIndex;

		sender.editRow(rowIndex);
	}

	public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
		sender.closeRow(rowIndex);
	}

	private closeEditor(grid, rowIndex = this.editedRowIndex) {
		grid.closeRow(rowIndex);
		this.editedRowIndex = undefined;
	}

	public engineersChange(value: any): void {

	}

	public requestsStatusChange(value: any): void {
		this.stoppedUntil = null;
		this.comment = null;

		switch (value) {
			case RequestStatus.Stopped:
				this.showChangeStatusAdditionalControls = true;
				break;
			case RequestStatus.Cancelled:
				this.showChangeStatusAdditionalControls = false;
				this.showComment = true;
				break;
			default:
				this.showChangeStatusAdditionalControls = false;
				this.showComment = false;
				break;
		}
	}

	public performerUserGroupChange(value: any): void {
		this.userGroupsService.getUsersByGroup(value).subscribe(res => {
			this.usersByGroup = res;
		});

		if (!value) {
			this.userByGroupId = null;
		}
	}

	tabChanged(e: NgbTabChangeEvent) {
		this.requestStatusId = null;
		this.engineerId = null;
		this.performerUserGroupId = null;
		this.stoppedUntil = null;
		this.comment = null;
		this.userByGroupId = null;
		this.checkGroupRequests(e.nextId);
	}

	private checkGroupRequests(tabId: string) {
		this.saveByGroupDisabled = false;
		switch (tabId) {
			case "Назначить на инженера":
				let inGroupRequests = this.allRequests.filter(f => f.isInGroupExt);
				let groupRequest = this.allRequests.filter(f => f.requestTypeId === <number>RequestType.groupRequest);
				if ((!!!groupRequest || groupRequest.length === 0)
					&& (!!inGroupRequests && inGroupRequests.length > 0)) {
					inGroupRequests.forEach(f => {
						this.requestsService.getRequestGroup(f.requestId).subscribe(res => {
							if (!this.allRequests.map(m => m.requestId).includes(res[0].requestId)) {
								this.saveByGroupDisabled = true;
								this.notificationService.info({
									title: 'Внимание!',
									message: `В списке присутствуют заявки, объединенные в группу, при этом групповая заявка в списке отсутствует. Операция назначения инженера по таким заявкам производится только из групповой заявки. Действие применится ко всем заявкам, включенных в группу. Добавьте в список групповую заявку ${res[0].requestId}, после чего завершите операцию назначения инженера на всю группу заявок.`,
									notificationType: NotificationType.SweetAlert
								});
							}
						});
					});
				}
				break;
		}
	}

	public cancel(): void {
		this.close();
	}

	ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();

		if (this.plannedDateSubject != null)
			this.plannedDateSubject.unsubscribe();

		if (this.engineerFilterSubject != null)
			this.engineerFilterSubject.unsubscribe();

	}

	private applyOnSuccessAssignToEngineer(result: ActionDataResult<MultipleError[]>): void {
		if (this.onSuccessAssignToEngineer) {
			this.onSuccessAssignToEngineer(this.gridSelectedRequests.filter(
				request => !result.data.some(error => error.requestId === request.requestId)));
		}
	}

	activityGridCheckboxChanged(dataItem: RequestListItem): void {
		if (dataItem.isGroup) {
			this.requestsService.getRequestGroup(dataItem.requestId).subscribe(res => {
				let isChecked = this.gridSelectedRequests.map(m => m.requestId.toString()).includes(dataItem.requestId.toString());
				if (isChecked) {
					res[0].childs.forEach(f => {
						let child = this.requests.filter(flt => flt.requestId.toString() == f.requestId.toString())[0];
						if (!!!child) {
							child = this.deselectedRequests.filter(flt => flt.requestId.toString() == f.requestId.toString())[0];
						}
						if (!!child) {
							this.gridSelectedRequests.push(child);
						}
					});
				} else {
					res[0].childs.forEach(f => {
						let child = this.requests.filter(flt => flt.requestId.toString() == f.requestId.toString())[0];
						if (!!child) {
							if (!this.deselectedRequests.map(m => m.requestId.toString()).includes(child.requestId.toString())) {
								this.deselectedRequests.push(child);
							}
							if (this.gridSelectedRequests.map(m => m.requestId.toString()).includes(child.requestId.toString())) {
								this.gridSelectedRequests.splice(this.gridSelectedRequests.indexOf(child), 1);
							}
						}
					});
				}
			});
		}
	}

	public isChangeActivityChanged(e: Event): void {
		this.loadEngineers();
	}
}
