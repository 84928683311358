import { Component, Input } from '@angular/core';
import { BaseObject } from '../../models/core/BaseObject';

@Component({
	selector: 'entity-info',
	templateUrl: './entity-info.component.html',
	styleUrls: ['./entity-info.component.scss']
})
export class EntityInfoComponent {

	constructor() { }

	@Input()
	entity: BaseObject;

	addHours(value: string, increment: number): Date {
		if (!value) {
			return null;
		}
		let date = new Date(Date.parse(value));
		date.setHours(date.getHours() + increment);
		return date;
	}
}
