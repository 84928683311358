export class GeneralEquipmentReportQuery {
    modelId: number;
    warehouseCity: string[] = [];
    typeOfEquipment: number[] = [];
    quality: string[] = [];
    bankOwner: string[] = [];
    compartment: string[] = [];
    status: string[] = [];
    warehouse: string[] = [];
}
