import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { KeyValueObject } from '../../../../models/core/KeyValueObject';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { AppService } from '../../../../../app.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'multiselect-lookup-filter',
	templateUrl: './multiselect-dropdown-filter.component.html',
})
export class MultiSelectLookupFilterComponent extends BaseFilterCellComponent implements OnInit, OnDestroy {

	public filterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	public virtual: any = { itemHeight: 28 };
	public emptyItem: KeyValueObject = new KeyValueObject(-1, "(не указан)");

	@Input()
	public addFilters: boolean = true;

	@Input()
	public filter: CompositeFilterDescriptor;

	@Input()
	public supressEmptyValue: boolean;

	private _data: KeyValueObject[] = [];

	public get data(): KeyValueObject[] {
		return this._data;
	}

	@Input()
	public set data(value: KeyValueObject[]) {

		if (!this.supressEmptyValue && !value.some(x => x.id === this.emptyItem.id)) {
			value.unshift(this.emptyItem);
		}

		this._data = value;
	}

	@Input()
	public fieldName: string;

	@Input()
	public filterByValue: boolean = false;

	@Output()
	onSelectedItem: EventEmitter<any> = new EventEmitter();

	selectedValues: number[] = [];

	clearAllGridFilters: Subscription;
	eqMode: boolean = true;

	refreshSelectedValues() {

		var existedFilters = this.filter.filters.filter(x => (<CompositeFilterDescriptor>x).filters && (<CompositeFilterDescriptor>x).filters.some(y => (<FilterDescriptor>y).field === this.fieldName));

		if (existedFilters && existedFilters.length > 0) {
			var fieldFilter = <CompositeFilterDescriptor>existedFilters[0];
			this.selectedValues = fieldFilter.filters.map(x => (<FilterDescriptor>x).value);
			this.eqMode = fieldFilter.filters.every(x => (<FilterDescriptor>x).operator == 'eq');
		} else{
			this.selectedValues = [];
		}

	}

	constructor(filterService: FilterService,
				protected appService: AppService
	) {
		super(filterService);
	}

	ngOnInit(): void {

		this.refreshSelectedValues();

		this.clearAllGridFilters = this.appService.clearAllGridFilters.subscribe(() => {
			this.selectedValues = [];
			this.eqMode = true;
		});
	}

	public onChange(ids: number[]): void {

		if (!this.addFilters) {
			if (this.onSelectedItem) {
				this.onSelectedItem.emit(ids);
			}
			return;
		}

		if (!ids || ids.length === 0) {

			this.applyFilter(this.removeFilter(this.fieldName));

		} else {

			var allFilters = this.filter.filters;

			var currentFilter = this.filter.filters.filter(x => (<CompositeFilterDescriptor>x).filters && (<CompositeFilterDescriptor>x).filters.some(y => (<FilterDescriptor>y).field === this.fieldName));

			if (currentFilter.length > 0) {

				var fieldFilter = <CompositeFilterDescriptor>currentFilter[0];

				fieldFilter.filters = ids.map(value => this.processSelectedValue(value));

				fieldFilter.logic = this.eqMode ? 'or' : 'and';

			} else {

				allFilters.push({
					filters: ids.map(value => this.processSelectedValue(value)),
					logic: this.eqMode ? 'or' : 'and',
				});
			}

			this.filterService.filter({
				filters: allFilters,
				logic: 'and'
			});

		}

		if (this.onSelectedItem) {
			this.onSelectedItem.emit(ids);
		}
	}

	processSelectedValue(value: number): FilterDescriptor {

		if (value === this.emptyItem.id) {
			return {
				field: this.fieldName,
				operator: this.eqMode ? 'isnull' : 'isnotnull',
				value: this.emptyItem.id
			}
		}

		if (this.filterByValue){
			let item = this.data.find(x => x.id === value);
			return {
				field: this.fieldName,
				operator: this.eqMode ? 'eq' : 'neq',
				value: item.name
			}
		}

		return {
			field: this.fieldName,
			operator: this.eqMode ? 'eq' : 'neq',
			value
		}
	}

	changeFilterOperatorClick(this){
		this.eqMode = !this.eqMode;
		this.onChange(this.selectedValues);
	}

	ngOnDestroy(): void {
		if (this.clearAllGridFilters){
			this.clearAllGridFilters.unsubscribe();
		}
	}
}
