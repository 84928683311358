import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const options = {
  preserveWhitespaces: false
};

const isLocal: boolean = /localhost/.test(document.location.host);
!isLocal && enableProdMode();

options.preserveWhitespaces = !isLocal;

platformBrowserDynamic().bootstrapModule(AppModule, options).catch(err => console.log(err));
