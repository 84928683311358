import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { DialogService } from "@progress/kendo-angular-dialog";
import { EquipmentSearchMode } from "../equipment-search-mode/equipment-search-mode";
import { WarehouseWorkplaceSearchItem } from "../../shared/models/warehouse-workplace-search/warehouse-workplace-search-item";
import { RowClassArgs } from "@progress/kendo-angular-grid";
import { GridSettings } from "../../shared/models/grid/grid-settings";
import { GetCellBySnFrom1CQuery } from "../../shared/models/for-1c/get-warehouse-and-celll-by-sn/get-cell-by-sn-from-1c-query";
import { Guid } from "guid-typescript";
import { For1CService } from "../../shared/services/for-1c.service";
import { NotificationService } from "../../core/services/notification.service";
import { NotificationType } from "../../core/services/notification-type";
import { EquipmentMoveMode } from "../equipment-move-mode/equipment-move-mode";
import { EquipmentMoveModel } from "../equipment-move-mode/equipment-move.model";
import { SetServiceCellBySnFrom1CQuery } from "../../shared/models/for-1c/set-service-warehouse-and-celll-by-sn/set-service-cell-by-sn-from-1c-query";
import { DeviceFrom1C } from "../../shared/models/for-1c/responses/device-from-1c";

@Component({
	selector: "search-by-sn-list",
	templateUrl: "./search-by-sn-list.html",
	styleUrls: ["./search-by-sn-list.scss"],
    encapsulation: ViewEncapsulation.None
})
export class SearchBySNList implements OnInit {

    gridHeight: Number;
    loading: boolean;

    @Input()
    searchItems: WarehouseWorkplaceSearchItem[] = [];

    constructor(
        protected dialogService: DialogService,
        protected for1CService: For1CService,
        protected notificationService: NotificationService
    ) { }

    public ngOnInit(): void {
        this.gridHeight = window.innerHeight - 160;
    }

    public searchBySN(): void {
        const dialog = this.dialogService.open({
			title: 'Поиск по С/Н',
			content: EquipmentSearchMode,
			width: 400
		});

        const equipmentSearchModeDialog = <EquipmentSearchMode>dialog.content.instance;
		equipmentSearchModeDialog.onContinueEvent.subscribe((serialNumbers: string[]) => {
            var repeatableSerialNumbers: string[] = [];
            if (serialNumbers != null && serialNumbers.length > 0) {
                serialNumbers.map((sn) => {
                    if (this.searchItems.some(x => x.serialNumber == sn))
                        repeatableSerialNumbers.push(sn);
                    else {
                        var listItem = new WarehouseWorkplaceSearchItem;
                        listItem.serialNumber = sn;
                        this.searchItems.push(listItem);
                    }
                });
            }
            if (repeatableSerialNumbers.length > 0) {
                var notificationMessage = repeatableSerialNumbers.length == 1
                    ? `Серийный номер (${repeatableSerialNumbers[0]}) уже указан`
                    : `Серийные номера (${repeatableSerialNumbers.join(', ')}) уже указаны`;

                this.notificationService.info({ 
                    title: "",
                    message: notificationMessage,
                    notificationType: NotificationType.Toast
                });
            }
		});
    }

	public getWarehouseAndCells(): void {
		if (this.searchItems != null && this.searchItems.length > 0) {
			this.loading = true;
			const selectedSerialNumbers = this.searchItems.map(x => x.serialNumber);

			var query = new GetCellBySnFrom1CQuery;
			query.httpQueryId = Guid.create().toString();
			query.serialNumbers = selectedSerialNumbers;

			this.for1CService.getWarehouseAndCellBySN(query).subscribe((result) => {
				if (result.data != null && result.data.devices != null && result.data.devices.length > 0) {
					this.searchItems.forEach((item) => {
						var device = result.data.devices.find(x => x.sn == item.serialNumber);
						if (device != null) {
							item.cell = device.cell;
							if (device.warehouse != null) item.warehouse = device.warehouse.name;
							if (device.warehouseArea != null) item.warehouseArea = device.warehouseArea.name;
						}
					});
				}
				else {
					this.notificationService.info({ 
						title: "",
						message: "ТО для указанных С/Н не может быть найдено в 1C",
						notificationType: NotificationType.Toast
					});
				}

				this.loading = false;
			});
		}
		else {
			this.notificationService.info({ 
				title: "",
				message: "Укажите хотя бы один С/Н",
				notificationType: NotificationType.Toast
			});
		}
	}

	public moveEquipment(): void {
		const dialog = this.dialogService.open({
			title: 'Режим перемещения оборудования',
			content: EquipmentMoveMode,
			width: 400
		});

		const equipmentMoveModeDialog = <EquipmentMoveMode>dialog.content.instance;
		equipmentMoveModeDialog.onContinueEvent.subscribe((moveModel: EquipmentMoveModel) => {
			this.loading = true;
			
			var query = new SetServiceCellBySnFrom1CQuery;
			query.httpQueryId = Guid.create().toString();
			query.devices = moveModel.serialNumbers.map(sn => {
				var device = new DeviceFrom1C;
				device.sn = sn;
				device.cell = moveModel.cellNumber;
				return device;
			});

			this.for1CService.setServiceWarehouseAndCellBySN(query).subscribe((resp) => {
				this.loading = false;
                if (resp.isSuccessful) {
                    this.notificationService.success({ 
                        title: "",
                        message: "Оборудование успешно перемещено",
                        notificationType: NotificationType.Toast
                    });
                }
			});
		});
	}

	public rowClass(context: RowClassArgs) {
		if (context.dataItem.hoursToDeadline) {

			let splitHours: number = 0;
			let hours: number = 0;
			let minutes: number = 0;
			let splitDays: number = 0;
			let days: number = 0;

			if (context.dataItem.hoursToDeadline.includes(".")) {
				splitDays = context.dataItem.hoursToDeadline.split(".");
				days = parseInt(splitDays[0]);
			} else {
				splitHours = context.dataItem.hoursToDeadline.split(":");
				hours = parseInt(splitHours[0]);
				minutes = parseInt(splitHours[1]);
			}

			if ((days < 0) || (hours < -3 || (hours === -3 && minutes > 0))) {
				return {
					'cursor-pointer': true,
					'red-text': true
				};
			} else {
				return {
					'cursor-pointer': true
				};
			}

		} else {
			return {
				'cursor-pointer': true
			};
		}
	}

	public gridSettings: GridSettings = {
		state: { 
            take: 25,
        },
		columnsConfig: [
            {
				orderIndex: 1,
				field: 'serialNumber',
				title: 'С/Н в запросе',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'warehouseArea',
				title: 'Помещение',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
            {
				orderIndex: 3,
				field: 'warehouse',
				title: 'Склад',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'cell',
				title: 'Ячейка',
				filter: 'string',
				filterable: false,
				width: 300,
				hidden: false
			}
		]
	};

}