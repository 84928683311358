import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CellClickEvent, GridComponent, RemoveEvent, SaveEvent } from "@progress/kendo-angular-grid";
import { NotificationType } from "../../../../core/services/notification-type";
import { NotificationService } from "../../../../core/services/notification.service";
import { ExtendedHierarchyLookupModel } from "../../../../shared/models/core/ExtendedHierarchyLookupModel";
import { EngubatorEngineerRegion } from "../../../../shared/models/engubator/engubator.engineer.region";
import { EngubatorRegionSegment } from "../../../../shared/models/engubator/engubator.region";
import { EngubatorRegionSegmentsService } from "../../../../shared/services/engubator/engubator.region-segments.service";

@Component({
	selector: 'engineers-regions',
	templateUrl: './regions.component.html',
	styleUrls: [
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	],
	changeDetection: ChangeDetectionStrategy.Default
})
export class EngineersRegionsComponent implements OnInit {
	@Input() regions: EngubatorEngineerRegion[] = [];
	@Input() canEditForm: boolean;
	@Output() saveHandlerEvent = new EventEmitter<SaveEvent>();
	@Output() removeHandlerEvent = new EventEmitter<RemoveEvent>();


	public engineerRegionsHierarchyLookupModels: ExtendedHierarchyLookupModel<EngubatorRegionSegment>[] = [];

	constructor(
		private engubatorRegionSegmentsService: EngubatorRegionSegmentsService,
		protected notificationService: NotificationService
	) { }

	ngOnInit(): void {
		this.engubatorRegionSegmentsService.getHierarchyData().subscribe(data => this.engineerRegionsHierarchyLookupModels = data);
	}

	public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

	editedRegionsGridRowIndex: number;

	get createNewRegion(): any {

		return () => {
			const region = new EngubatorEngineerRegion();

			region.isDefault = this.regions
				.every(x => !x.isDefault);

			return region;
		};
	}

	onChangeDefaultUserRegion(region: EngubatorEngineerRegion, isNew: boolean): void {
		if (!isNew)
			this.regions.filter(f => f.isDefault && f.regionFiasId != region.regionFiasId).forEach(e => e.isDefault = false);
	}

	public onChangedRegionSegment(id: number, segment: EngubatorRegionSegment): void {
		if (id)
			segment = this.engineerRegionsHierarchyLookupModels.find(f => f.id == id).entity;
	}

	public regionsGridCellClick(event: CellClickEvent): void {

		if (!this.canEditForm) {
			return;
		}

		this.closeCurrentRegionsGridRow(event.sender);
		this.editedRegionsGridRowIndex = event.rowIndex;
		event.sender.editRow(event.rowIndex);
	}

	private closeCurrentRegionsGridRow(grid: GridComponent): void {
		if (this.editedRegionsGridRowIndex + 1)
			grid.closeRow(this.editedRegionsGridRowIndex);
	}

	public saveHandler(event: SaveEvent) {
		this.saveHandlerEvent.emit(event);

		if (event.dataItem.regionId) {
			var segment = this.engineerRegionsHierarchyLookupModels.find(f => f.id == event.dataItem.regionId).entity;
			if (this.regions.some(s => s.regionFiasId == segment.regionFiasId)) {
				event.dataItem = null;
				this.notificationService.warning({ title: 'Повтор', message: 'Данный регион уже выбран', notificationType: NotificationType.Toast });
				return;
			}
			if (event.dataItem.isDefault)
				this.regions.filter(f => f.isDefault).forEach(e => e.isDefault = false);
			event.dataItem.regionFiasId = segment.regionFiasId;
			event.dataItem.fullString = segment.name;
			event.dataItem.segments = [segment];
			delete event.dataItem.regionId;
		}
	}

	public removeHandler(event: RemoveEvent) {
		this.removeHandlerEvent.emit(event);
	}

	onFocus(event: FocusEvent): void {
		if (event.relatedTarget)
			(event.target as HTMLElement).blur();
	}
}
