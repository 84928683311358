export class UpdateRequestCustomCommand {
    requestId: number;
    recipientWarehouseId?: number;
    updateRecipientWarehouseId: boolean;

    /**
     * Обновляет в заявке RecipientWarehouseAreaId по WarehouseCellId
     */
    recipientWarehouseCellId: number;
    /**
     * Если нужно обновить RecipientWarehouseAreaId по WarehouseCellId
     */
    updateRecipientWarehouseCellId: boolean;
    /**
     * Обновляет в заявке RecipientWarehouseAreaId
     */
    recipientWarehouseAreaId: number;
    /**
     * Если нужно обновить в заявке RecipientWarehouseAreaId
     */
    updateRecipientWarehouseAreaId: boolean;

    constructor(requestId: number) {
        this.requestId = requestId;
    }
}