export enum WarehouseCellType {
	// Неопределенный
	undefined = 1,

	// Хранение
	storage = 2,

	// Отгрузка
	shipment = 3,

	// Приемка
	receive = 4,
}
