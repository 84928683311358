export enum RequestType {
	// Монтаж
	install = 1,
	// Сервис
	service = 2,
	// Замена
	changeConfig = 3,
	// Демонтаж
	uninstall = 4,
	// Пополнение склада
	warehouseRefill = 5,
	// Экспертиза
	expertise = 6,
	// Перемещение между складами
    warehouseTransfer = 7,
    // Выдача со склада
	issueFromWarehouse = 8,
	// Сервис + Замена
	serviceOrReplace = 9,
	// Оприходование
	warehousePosting = 10,
	// Экспресс-экспертиза
	expressExpertise = 11,
	// Демонтаж старый
	uninstallOld = 12,
	// Групповая заявка
	groupRequest = 13,
	// Монтаж ККТ (cash register equipment)
	installCRE = 15,
	// Замена ККТ (cash register equipment)
	changeConfigCRE = 16,
	// Прием на склад
	receiveToWarehouse = 17,
	// Выдача/Прием со склада
	issueAndReceiveToWarehouse = 18,
	// Согласование перемещения
	confirmRepair = 19,
	// Коллизии данных оборудования
	collision = 20,
	// Перемещение на внешний склад
	foreighWarehouseTransfer = 21,
	// Регистрация
	registration = 22,
	//Перемещение внутри склада
	internalWarehouseTransfer = 23,

	/**Монтаж (удаленно) */
	remoteInstall = 24, //Этот id указан в конфиге appsettings.json в Agreements > RequestTypes с AgreementId = 7, если он меняется тут, надо его поменять и там

	/**Монтаж с забором */
	installWithDismantle = 25
}
