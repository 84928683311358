import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IItemNode} from '../interfaces';
import {HierarchyLookupModel} from '../../shared/models/core/HierarchyLookupModel';

@Injectable()
export class TreeSourceService {

	treeData: IItemNode[];
	dataChange = new BehaviorSubject<IItemNode[]>([]);

	constructor() {}

	public initialize(data: any[]) {
		this._prepareFlatList(data);
		this.treeData = this.listToTree(data);
		this.dataChange.next(this.treeData);
	}

	public reInitialize(): void {
		this.dataChange.next(this.treeData);
	}

	public filter(filterText: string) {
		let filteredTreeData;
		if (filterText) {
			// Filter the tree
			const filter = (array, text) => {

				const getChildren = (result, object) => {
					if (object.name.toLowerCase().includes(text.toLowerCase()) ) {
						result.push(object);
						return result;
					}
					if (Array.isArray(object.children)) {
						const children = object.children.reduce(getChildren, []);
						if (children.length) { result.push({ ...object, children }); }
					}
					return result;
				};

				return array.reduce(getChildren, []);
			};

			filteredTreeData = filter(this.treeData, filterText);
		} else {
			// Return the initial tree
			filteredTreeData = this.treeData;
		}

		// Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
		// file node as children.
		const data = filteredTreeData;
		// Notify the change.
		this.dataChange.next(data);
	}

	public listToTree(data: HierarchyLookupModel[], options = null): IItemNode[] {
		options = options || {};
		const ID_KEY = options.idKey || 'id';
		const PARENT_KEY = options.parentKey || 'parentId';
		const CHILDREN_KEY = options.childrenKey || 'children';

		const tree: IItemNode[] = [],
			childrenOf = {};
		let item: IItemNode, id, parentId;

		for (let i = 0, length = data.length; i < length; i++) {
			item = data[i];
			id = item[ID_KEY];
			parentId = item[PARENT_KEY] || null;
			// every item may have children
			childrenOf[id] = childrenOf[id] || [];
			// init its children
			item[CHILDREN_KEY] = childrenOf[id];
			if (parentId) {
				// init its parent's children object
				childrenOf[parentId] = childrenOf[parentId] || [];
				// push it into its parent's children object
				childrenOf[parentId].push(item);
			} else {
				tree.push(item);
			}
		}
		return tree;
	}

	// для ситуации, когда ParentId указано, но в линейном списке отсутсвует Id === ParentId
	private _prepareFlatList(data: HierarchyLookupModel[]): void {
		data.forEach(item => {
			if (item.parentId && data.every(x => x.id !== item.parentId)) {
				item.parentId = null;
			}
		});
	}
}
