import { BaseObject } from "../../../shared/models/core/BaseObject";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { RequestAutoPublishSettingElementDto } from "./request-publication-setting-element-dto";

export class RequestPublicationSettingDto extends BaseObject {
    id: number;
    userId: number;
    serviceCenterId: number;
    serviceCenterName: string;
    serviceCenterIntegraCode: string;
    name: string;
    isActive: boolean;
    createdDate: Date;
    updatedDate: Date;
    requestAutoPublishSettingElements: RequestAutoPublishSettingElementDto[] = [];

    serviceCenter: KeyValueObject;
}