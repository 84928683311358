import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { AttachmentType, RequestInitTypeEnum, RequestType, ActivityStatusEnum } from "../../../enums";
import { AttachmentSpot } from "../../../enums/attachment-spot.enum";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { RequestDeviceWarehouseRefillDto } from "../../../models/request/request-device-warehouse-refills";
import { SendStateWithLogic } from "../../../models/request/send-state-with-logic";
import { CarriersService } from "../../../services/carriers.service";
import { LookupService } from "../../../services/lookup.service";
import { RequestsService } from "../../../services/requests.service";
import { CarrierCompanyTrackModel } from "./carrier-company-track.model";


@Component({
	selector: 'set-carrier',
	templateUrl: './set-carrier.html',
	styleUrls: ['./set-carrier.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})

export class SetCarrier implements OnInit {
	@Input()
	activityId: number
	
	@Input()
	requestId: number;

	@Input()
	isOnlyAttchmentMode: boolean = false;

	@Output()
	onRefreshActivityStatus = new EventEmitter();

	@Output()
	onchangeActivityStatus = new EventEmitter();

	loading: boolean;
	dataSaving: boolean;

	initTypeId: number;
	requestDeviceWarehouseRefills: RequestDeviceWarehouseRefillDto[] = [];
	requestTypeId: number;

	carriers: KeyValueObject[] = [];
	carrierId: number;
	mustBeTrackNumber: boolean;
	companyName: string;
	trackNumber: string;
	uploadInProgress: boolean = false;
	uploadFilesPercentages?: number;
	visibleAttachmentTypes: number[] = [<number>AttachmentType.transferAcceptanceCertificate, <number>AttachmentType.transferCertificateForRepair];
	spotId: number = <number>AttachmentSpot.approvalRsShiped;
	isActLoaded: boolean = false;
	estimatedDeliveryTime?: Date;
	minDate: Date = new Date();
	utcTimezoneShift: number;

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	constructor(
		public dialog: DialogRef,
		private lookupService: LookupService,
		private carriersService: CarriersService,
		private requestsService: RequestsService,
	) {

	}

	get cardHeight(): number {
		return window.innerHeight - 250;
	}

	get isMkInitType(): boolean {
		return this.initTypeId === <number>RequestInitTypeEnum.blockChain;
	}

	get isByNomenclature(): boolean {
		return !this.isMkInitType && this.requestDeviceWarehouseRefills.some(s => !!s.nomenclature);
	}

	get isMkRemoteInstall(): boolean {
		return this.isMkInitType && this.requestTypeId === <number>RequestType.remoteInstall;
	}

	ngOnInit(): void {
		if (this.isOnlyAttchmentMode) {
			this.spotId = <number>AttachmentSpot.rsReceived;
			this.visibleAttachmentTypes = [<number>AttachmentType.transferAcceptanceCertificate];
		}

		this.lookupService.getData("carriers", null).subscribe(data => this.carriers = data);


		this.requestsService.getById(this.requestId).subscribe(res => {
			this.requestDeviceWarehouseRefills = res.entity.requestDeviceWarehouseRefills;
			this.initTypeId = res.entity.initTypeId;
			this.requestTypeId = res.entity.requestTypeId;
			this.utcTimezoneShift = res.entity.utcTimezoneShift;

			let carriersState: DataSourceRequestState = null;

			if (this.isMkRemoteInstall) {
				carriersState = {
					skip: 0,
					take: null,
					filter: { logic: "and", filters: [{ field: "MspCode", operator: "isnotnull" }] }
				};	
			}
			
			this.lookupService.getData("carriers", carriersState).subscribe(data => this.carriers = data);
		});
	}

	carrierValueChange(): void {
		this.carriersService.findById(this.carrierId).subscribe(carrier => {
			this.mustBeTrackNumber = carrier.entity.mustBeTrackNumber
			this.companyName = carrier.entity.name;
		});
	}

	cancel(): void {
		if (this.dialog)
			this.dialog.close();
	}

	apply(): void {
		this.dataSaving = true;

		if (this.isMkRemoteInstall) {
			if (!!this.estimatedDeliveryTime) {
				this.estimatedDeliveryTime.setHours(this.estimatedDeliveryTime.getHours() - this.utcTimezoneShift);
			}

			if (!this.isActLoaded) {
				this.dataSaving = false;
				this.refreshActivityStatus();
			} else {
				let carrierCompanyTrackModel = new CarrierCompanyTrackModel(this.carrierId,
					this.companyName,
					this.trackNumber,
					this.estimatedDeliveryTime);
	
				this.requestsService.sendStateWithLogic(
					new SendStateWithLogic(this.requestId, "equipmentTransfer_fromUi", carrierCompanyTrackModel))
					.subscribe(() => {
						this.refreshActivityStatus();
						this.dataSaving = false;
						this.cancel();
					});
			}
		} else {
			if (!this.isActLoaded) {
				this.dataSaving = false;
				this.refreshActivityStatus();
			} else {
				if (!this.isOnlyAttchmentMode) {
					let carrierCompanyTrackModel = new CarrierCompanyTrackModel(this.carrierId, this.companyName, this.trackNumber);

					this.requestsService.sendStateWithLogic(
						new SendStateWithLogic(this.requestId, "approvalRsShipped_fromUi", carrierCompanyTrackModel))
						.subscribe(() => {
							this.refreshActivityStatus();
							this.dataSaving = false;
							this.cancel();
						});
				} else {
					this.requestsService.sendStateWithLogic(
						new SendStateWithLogic(this.requestId, "rsReceived_auto"))
						.subscribe(res => {
							if (res.isSuccessful) {
								this.changeActivityStatus();
								this.dataSaving = false;
								this.cancel();
							}

							this.dataSaving = false;
						});
				}
			}
		}
	}

	filesLoaded(uploadedFilesCount: number): void {
		this.isActLoaded = uploadedFilesCount > 0;
	}

	changeActivityStatus(): void {
		if (this.onchangeActivityStatus) {
			this.onchangeActivityStatus.emit();
		}
	}

	refreshActivityStatus(): void {
		if (this.onRefreshActivityStatus) {
			if (this.isByNomenclature) {
				this.onRefreshActivityStatus.emit();
			}
		}
	}
}
