import { IFreeNomenclature } from '../../../interfaces';
import { BaseObject } from '../../core/BaseObject';
import { KeyValueObject } from '../../core/KeyValueObject';

export class NewRequestDevice extends BaseObject {
  requestDeviceId: number;
  requestId: number;
  tidId?: number;
  tidNumber: string;
  deviceTypeId: number;
  deviceConnectionTypeId: number;
  deviceModelId: number;
  deviceSupplierId: number;
  isPinPadRequired: boolean;
  pinpadId?: number;
  deviceId?: number;
  activityId?: number;

  name: string;
  serialNumber: string;

  nomenclature: string;
  nomenclatures: IFreeNomenclature[] = [];

  quality: string;
  nomenclatureWarehouseIntegraCode: string;

  isHasAttachments: boolean;
  isNotDefinedSn: boolean;
  isDeviceBroken: boolean;
  isSnConfirmed: boolean;
  brokenDescription: string;
  deviceStatusId: number;
  matchedDeviceId: number;

  manufacturer: string;
  model: string;
  warehouseAreaName: string;
  warehouseCell: string;
  reserveSuccess?: boolean;
  reserveStatus: string;
  reserveResult: string;
  requestForInstruction: boolean;

  deviceTypeName: string;
  deviceSupplierName: string;
  deviceModelName: string;
  reserveResultId: number;

  serialNumbers: string[] = [];

  inputSerialNumber: string;
  isInputSnError: boolean = false;

  warehouseCellId: number;
  warehouseCells: KeyValueObject[] = [];

  warehouseAreaId: number;
  warehouseAreas: KeyValueObject[] = [];

  warehouseId: number;
  warehouseName: string;

  manuallyAdded: boolean;
  articleName: string;

  deviceServiceTypeName: string;
  deviceServiceTypeId: number;
  deviceServiceTypeNameHeader?: string;
  warehouseAreaMoveFromIntegraCode: string;
  warehouseAreaMoveFromName: string;

  warehouseAreaMoveFromId?: number;

  //Номенклатура, выбранная в UI
  selectedNomenclature: IFreeNomenclature;

  //Признак того, что это номенклатура с другого склада, проставляется на фронте
  isOtherWarehouseAreaFrom: boolean = false;

  isTargeted: boolean;
  returnWarehouseCellId: number;
  /**
   * Была выдача оборудования
   */
  issuedSuccess?: boolean;

  /** Вид оборудования */
  deviceKindId?: number;
  
  deviceConditionId: number;
  deviceConditionName: string;
  deviceConditions: KeyValueObject[] = [];

  hasCryptoKey: boolean = false;
  completeKit: boolean = true;

  cryptoKey: string;
  subscriberNumber: string;
  isWrittenOff?: boolean;

  iccid: string;
  partNumber: string;

  isSelected: boolean = false;

  defaultScWarehouseName: string;
  shouldBeReturnedToWarehouse: boolean = true;

  warehouseMoveFromId: number;
  warehouseMoveFromName: string;
}