import { Component, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { LayoutService } from '../../../layout/layout.service';

@Component({
	selector: 'navbar-header-left-actions',
	templateUrl: './navbar-header-left-actions.component.html'
})
export class NavbarHeaderLeftActionsComponent implements AfterViewInit {

	@ViewChild('navbarHeaderLeftActionsTemplate') private navbarHeaderLeftActionsTemplate: TemplateRef<any>;

	constructor(
		private layoutService: LayoutService
	) { }

	ngAfterViewInit(): void {
		this.layoutService.navbarHeaderLeftActionsTemplate = this.navbarHeaderLeftActionsTemplate;
	}
}