import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationType } from '../../../core/services/notification-type';
import { NotificationService } from "../../../core/services/notification.service";
import { EngubatorEngineer } from '../../models/engubator/engubator.engineer';
import { EngubatorSkill } from '../../models/engubator/engubator.skill';
import { EngubatorDataService } from './engubator.data.service';
import { tap, filter, take } from 'rxjs/operators';
import { ActionDataResult } from '../../models/core/ActionDataResult';
import { BlockAssigning } from '../../models/engubator/engubator.engineer.blockAssigning';

@Injectable()
export class EngubatorEngineersService extends EngubatorDataService<EngubatorEngineer, EngubatorEngineer>{
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'engineers';
	}

	protected getNewTItem(): EngubatorEngineer {
		return new EngubatorEngineer();
	}

	public update(obj: EngubatorEngineer, successMessageTitle: string, dontShowCustomError?: boolean, dontShowCustomSuccess?: boolean, queryString?: string): Observable<any> {
		this.reduceSkillsProperties(obj.skills);
		return super.update(obj, successMessageTitle, dontShowCustomError, dontShowCustomSuccess, queryString);
	}

	public blockAssigning(id: number | string, obj: BlockAssigning): Observable<ActionDataResult<boolean | string>> {
		return this.http.post<ActionDataResult<boolean | string>>(`${this.baseUrl()}/${id}/block-assigning`, obj)
			.pipe(tap(resp => {
				if (resp.isSuccessful) {
					this.notificationService.success({
						title: 'Обновление информации в сервисе подбора инженеров о блокировке назначения заявок',
						message: 'Успешно изменено',
						notificationType: NotificationType.Toast
					});
				}
				else {
					this.notificationService.error({
						title: 'Ошибка при обновлении информации в сервисе подбора инженеров о блокировке назначения заявок',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}
			},
				errorResponse => {
					if (errorResponse.status === this.unauthorizedCode)
						return;

					this.notificationService.error({
						title: 'Ошибка при создании элемента в сервисе подбора инженеров',
						message: errorResponse.message ? errorResponse.message : errorResponse.error.errorDescription,
						notificationType: NotificationType.Toast
					});

				}
			))
			.pipe(take(1));
	}

	public create(obj: EngubatorEngineer, errorNotificationType?: NotificationType, dontShowCustomError?: boolean): Observable<any> {
		this.reduceSkillsProperties(obj.skills);
		return super.create(obj, errorNotificationType, dontShowCustomError);
	}

	private reduceSkillsProperties(skills: EngubatorSkill[]): void {
		skills.forEach(s => {
			Object.keys(s).forEach(key => {
				if (key != 'id')
					delete s[key];
			});
		});
	}
}
