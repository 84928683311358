import { Component, Input, Output, EventEmitter, OnInit, Injector, ViewChild, AfterContentInit, AfterViewInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { RequestListItem } from '../../../models/request/request-list-item';
import { RequestsService } from '../../../services/requests.service';
import { LookupService } from '../../../services/lookup.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { MultipleError } from '../../../models/request/multiple-error';
import { ListViewModel } from '../../../models/core/ListViewModel';

@Component({
    selector: 'multiple-operation-error-result',
    templateUrl: './multiple-operation-error-result.component.html'
})

export class MultipleOperationErrorResultComponent implements OnInit {
    @Input() errors: MultipleError[] = [];

    @Input() heightPercent: number;

    @Input() headerText: string;

    @Output() selectedEvent = new EventEmitter<string>();
    @Output() onCancel = new EventEmitter();

    gridHeight: number;
    data: ListViewModel<MultipleError> = new ListViewModel<MultipleError>();

    constructor(
        private injector: Injector,
        protected requestsService: RequestsService,
        private lookupService: LookupService,
        protected notificationService: NotificationService) {

    }

    ngOnInit(): void {
        this.gridHeight = window.innerHeight * ((100 - this.heightPercent) / 100) - 100;
    }

    public cellClick({ dataItem }: any): void {
        var requestId = (<RequestListItem>dataItem).requestId;
        if (requestId) {
            var win = window.open(`/requests/${requestId}`, '_blank');
        }
    }

    private close(): void {
        var dialog = this.injector.get(DialogRef);
        dialog.close();
    }
    public cancel(): void {
        this.close();
        if (this.onCancel) {
            this.onCancel.emit();
        }
    }
}
