import { Component, OnInit, ViewChild } from '@angular/core';
import { CompositeFilterDescriptor, DataSourceRequestState, groupBy } from '@progress/kendo-data-query';

import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { User } from '../../../shared/models/User/User';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { UserRole } from '../../../shared/models/User/UserRole';
import { UserGroup } from '../../../shared/models/User/user-group';
import { EntityViewModel } from '../../../shared/models/core/EntityViewModel';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { UsersService } from '../users.service';
import * as textMaskAddons from 'text-mask-addons/dist/textMaskAddons';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { LookupService } from '../../../shared/services/lookup.service';
import { SecurityService } from '../../../core/services/security.service';
import { EthereumGateService } from '../../../shared/services/ethereum-gate.service';
import { NotificationType } from '../../../core/services/notification-type';
import { EthereumGateComponent } from '../../../shared/components/ethereum-gate-users/ethereum-gate-users.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DaDataAddressComponent } from '../../../shared/components/dadata-address/dadata-address.component';
import { UserGroupsService } from '../../../shared/services/user-groups.service';
import { ContragentType, RoleEnum } from '../../../shared/enums';
import { ContragentsService } from '../../../categories/contragents/contragents.service';
import { WarehouseDto } from '../../../shared/models/warehouse/warehouse-dto';
import { WarehousesService } from '../../../shared/services/warehouses.service';
import { TreeDropdownListComponent } from '../../../tree-dropdown-list/components/tree-dropdownlist/tree-dropdown-list.component';
import { HierarchyLookupModel } from '../../../shared/models/core/HierarchyLookupModel';
import { PasswordOptions } from '../../../shared/models/User/password-options';
import { UserServiceCenter } from '../../../shared/models/User/user-service-center';
import { ServiceBoundary } from '../../../shared/models/User/ServiceBoundary';
import { RegionsService } from '../../../shared/services/regions.service';
import { AddressSuggestion } from '../../../shared/models/dadata-address/address-suggestion';
import { EngubatorRegionSegmentsService } from '../../../shared/services/engubator/engubator.region-segments.service';
import { RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { EngubatorEngineer } from '../../../shared/models/engubator/engubator.engineer';
import { EngubatorEngineersService } from '../../../shared/services/engubator/engubator.engineers.service';
import { EngubatorLookupService } from '../../../shared/services/engubator/engubator.lookup.service';
import { finalize } from 'rxjs/operators';
import { EngubatorSkill } from '../../../shared/models/engubator/engubator.skill';
import { EngubatorSkillsService } from '../../../shared/services/engubator/engubator.skills.service';
import { UserImage } from '../../../shared/models/User/userImage';
import { BlockAssigning } from '../../../shared/models/engubator/engubator.engineer.blockAssigning';

const flatten = filter => {
	const filters = filter.filters;
	if (filters) {
		return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
	}
	return [];
};


@Component({
	selector: 'admin-user',
	templateUrl: './user.page.html',
	styleUrls: [
		'./user.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class UserPage extends ComponentPageBase<User> implements OnInit {
	@ViewChild("warehousesGrid") public warehousesGrid: any;
	@ViewChild("daDataAddressControl") public daDataAddressControl: DaDataAddressComponent;
	@ViewChild("userGroupsTreeDropdownList") public userGroupsTreeDropdownList: TreeDropdownListComponent;

	private readonly userGroupsControllerName: string = 'user-groups';

	private isUsedUserGroupsList: boolean = false;

	public engubatorViewModel?: EntityViewModel<EngubatorEngineer>;

	emailMaskOptions = {
		mask: textMaskAddons.emailMask
	};

	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};
	serviceCenters: KeyValueObject[] = [];
	contragents: KeyValueObject[] = [];
	districts: KeyValueObject[] = [];
	districtsGrouped: any[] = [];
	warehouses: WarehouseDto[] = [];
	formWarehouses: WarehouseDto[] = [];
	userWarehouses: WarehouseDto[] = [];
	clients: KeyValueObject[] = [];
	agreements: KeyValueObject[] = [];
	regions: KeyValueObject[] = [];
	filteredRegions: KeyValueObject[] = [];

	chooseAllServiceCenters: boolean = false;
	chooseAllWarehouses: boolean = false;
	chooseAllClients: boolean = false;
	loading: boolean = false;
	isAssigningBlockedProcessing: boolean = false;
	isAssigningBlocked: BlockAssigning = new BlockAssigning();
	passswordOptions: PasswordOptions = new PasswordOptions();
	engineerRoles = [RoleEnum.engineer, RoleEnum.partnerServiceCenterEngineer, RoleEnum.selfEmployedEngineer];

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		private lookupService: LookupService,
		private contragentsService: ContragentsService,
		private securityService: SecurityService,
		protected dataService: UsersService,
		private ethereumGateService: EthereumGateService,
		protected dialogService: DialogService,
		private userGroupService: UserGroupsService,
		private warehousesService: WarehousesService,
		private regionsService: RegionsService,
		private engubatorUserService: EngubatorEngineersService,
		protected engubatorLookupService: EngubatorLookupService,
		protected engubatorRegionSegmentsService: EngubatorRegionSegmentsService,
		private engubatorSkillsService: EngubatorSkillsService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'admin/users';
		this.appService.pageTitle = 'Новый пользователь';
	}

	get createNewRole(): any {
		return function () {
			const userRole = new UserRole();
			return userRole;
		};
	}

	get createNewGroup(): any {
		return () => {
			const userGroup = new UserGroup();

			userGroup.isDefaultGroup = this.entityViewModel.entity.userGroups
				.filter(x => x.isDefaultGroup).length === 0;

			return userGroup;
		};
	}

	get createNewServiceCenter(): any {
		return () => {
			const userServiceCenter = new UserServiceCenter();

			userServiceCenter.isDefault = !this.entityViewModel.entity.userServiceCenters.some(x => x.isDefault);

			return userServiceCenter;
		};
	}

	get createNewServiceBoundary(): any {
		return function () {
			return new ServiceBoundary();
		};
	}

	get passwordOptionsText(): string {
		let text = '';
		if (this.passswordOptions.requireUppercase) {
			text += ', {A-Z}';
		}
		if (this.passswordOptions.requireLowercase) {
			text += ', {a-z}';
		}
		if (this.passswordOptions.requireDigit) {
			text += ', {0-9}';
		}
		if (this.passswordOptions.requireNonAlphanumeric) {
			text += ', {!@#$%^&*()_+:><~}';
		}
		if (text.length > 0) {
			text = ' и содержать минимум 1 символ из каждой группы:' + text.substring(2);
		}
		return `Пароль должен быть не менее ${this.passswordOptions.requiredLength} символов${text}.`;
	}

	get createNewWarehouse(): any {
		return () => new WarehouseDto();
	}

	private roleFilterValueSubject: Subject<string> = new Subject();
	public rolesToAddDefaultItem: { name: string, roleId: number } = { name: 'Выберите роль...', roleId: null };
	public roleToAdd: { name: string, roleId: number } = this.rolesToAddDefaultItem;
	public roles: KeyValueObject[] = [];

	private lastNewGroup: UserGroup = null;
	public userGroupsHierarchyLookupModels: HierarchyLookupModel[] = [];

	public dropDownVirtualSettings: any = {
		itemHeight: 28
	};

	public rolesState: DataSourceRequestState = {
		skip: 0,
		take: 100000,
		sort: [{ field: 'name', dir: 'asc' }],
		filter: {
			logic: 'and',
			filters: [
			]
		}
	};

	ngOnInit() {

		this.entityId = this.route.snapshot.paramMap.get('userId');

		const contrAgentTypeIds = [ContragentType.bankEquer, ContragentType.serviceCenter, ContragentType.selfCompany];
		this.contragentsService.lookupAllList(contrAgentTypeIds).subscribe(data => {
			this.contragents = data;

			if (this.contragents.length > 0 && this.isNew && !this.entityViewModel.entity.contragentId) {
				const contragentId = this.contragents[0].id;
				this.loadUserGroupsBySelectedContragent(contragentId);
				this.loadServiceCentersBySelectedContragent(contragentId);
			}
		});

		this.dataService.getPasswordOptions().subscribe(d => {
			this.passswordOptions = d;
		});

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId).subscribe(vm => {
				vm.entity.userGroups = vm.entity.userGroups.sort((a, b) => a.name.localeCompare(b.name));

				this.entityName = `${vm.entity.surname} ${vm.entity.name} ${vm.entity.middleName}`;
				this.dataBind(vm);
				if (vm.entity.userImage == undefined) {
					vm.entity.userImage = new UserImage();
				}
				if (this.entityViewModel.entity.isEngineer) {
					this.loadEngubatorData(vm.entity.integraCode);
				}

				this.loadClients();

				this.loadUserGroupsBySelectedContragent(vm.entity.contragentId);
				this.loadServiceCentersBySelectedContragent(vm.entity.contragentId);

				this.loadWarehouses();
				this.loadClients();
				if (this.clients.length == 0) {
					this.chooseAllClients = true;
				}
				setTimeout(() => {
					if (this.daDataAddressControl) {
						this.daDataAddressControl.initValue(this.entityViewModel.entity.fullAddress);
					}
				}, 1000);
			});
			if (this.clients.length == 0) {
				this.chooseAllClients = true;
			}

		} else {

			this.entityViewModel = new EntityViewModel<User>();
			this.entityViewModel.entity = new User();
			this.entityViewModel.entity.createdDate = new Date();
			if (this.entityViewModel.entity.contragentId !== this.securityService.currentUser.contragentId) {
				this.entityViewModel.entity.contragentId = this.securityService.currentUser.contragentId;
				this.loadUserGroupsBySelectedContragent(this.entityViewModel.entity.contragentId);
				this.loadServiceCentersBySelectedContragent(this.entityViewModel.entity.contragentId);
			}
			this.canEditForm = true;
			this.isNew = true;

			this.loadWarehouses();
		}

		this.roleFilter('');

		this.roleFilterValueSubject.pipe(debounceTime(400)).subscribe(value => {
			this.roleFilter(value);
		});
		this.loadRegions();
	}

	loadUserGroupsBySelectedContragent(contragentId: number): void {
		const requestState: DataSourceRequestState = {
			filter: {
				logic: 'and',
				filters: [
					{
						logic: 'or',
						filters: [
							{ field: 'ContragentId', operator: 'eq', value: contragentId },
							{ field: 'ContragentId', operator: 'isnull' }
						]
					},
					{ field: 'IsVisible', operator: 'eq', value: true },
				]
			}
		};
		this.userGroupsHierarchyLookupModels = [];
		this.lookupService.getHierarchyData(this.userGroupsControllerName, requestState)
			.subscribe(data => this.userGroupsHierarchyLookupModels = data);
	}

	loadServiceCentersBySelectedContragent(contragentId: number, callback: () => void = null): void {
		this.loadDataBySelectedContragent(contragentId, state => {
			this.lookupService.getData("service-centers", state)
				.subscribe(data => {
					this.serviceCenters = data;

					if (callback) {
						callback();
					}

				});
		});
	}

	handleFilter(value) {
		var filtered = this.districts.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
		this.districtsGrouped = groupBy(filtered, [{ field: "groupName" }]);
	}

	onAddressChange(value: AddressSuggestion) {
		this.entityViewModel.entity.fullAddress = value.address;

		// if (!!value) {
		// 	this.appService.MarkFormDirty(this.entityForm);
		// } else {
		// 	this.appService.MarkFormPristine(this.entityForm);
		// }

	}
	get isAddressValid(): boolean {
		if (!!this.daDataAddressControl) {
			return this.daDataAddressControl.isValid();
		} else {
			return true;
		}
	}

	onContragentChange(contragentId: number): void {
		this.loadUserGroupsBySelectedContragent(contragentId);
		this.loadServiceCentersBySelectedContragent(contragentId, () => {
			this.userWarehouses = [];
			this.entityViewModel.entity.warehouseIds = [];
			this.entityViewModel.entity.userServiceCenters = [];
			this.entityViewModel.entity.userGroups = [];
		});
	}

	onChangeDefaultUserGroup(userGroup: UserGroup): void {
		const userGroups = this.entityViewModel.entity.userGroups;
		if (!userGroup.userGroupId && !userGroups.includes(userGroup)) {
			this.lastNewGroup = userGroup;
		}

		this.entityViewModel.entity.userGroups.forEach(group => {
			if (group !== userGroup) {
				group.isDefaultGroup = false;
			}
		});

		if (this.lastNewGroup !== userGroup) {
			this.lastNewGroup.isDefaultGroup = false;
		}
	}

	onChangeIsBlocked(isBlocked: boolean): void {
		if (isBlocked) {
			this.isAssigningBlocked.IsAssigningBlocked = isBlocked;
			this.SetAssigningBlocked(isBlocked);
		}
	}

	onChangeIsAssigningBlocked(isBlocked: boolean): void {
		this.SetAssigningBlocked(isBlocked);
	}

	SetAssigningBlocked(isBlocked: boolean): void {
		this.isAssigningBlockedProcessing = true;
		if (this.engubatorViewModel != undefined && this.engubatorViewModel.entity != null) {
			var assigningBlocked = new BlockAssigning();
			assigningBlocked.IsAssigningBlocked = isBlocked;
			this.engubatorUserService.blockAssigning(this.entityViewModel.entity.integraCode, assigningBlocked).subscribe(vm => {
				if (!vm.isSuccessful) this.revertIsAssigningBlocked();
				this.isAssigningBlockedProcessing = false;
			}, () => this.revertIsAssigningBlocked());
		}
		else {
			this.revertIsAssigningBlocked();
			this.notificationService.error({
				title: 'Ошибка обновления информации в сервисе подбора инженеров о блокировке назначения заявок',
				message: 'Инженер не найден в системе',
				notificationType: NotificationType.Toast
			});
		}
	}

	private revertIsAssigningBlocked() {
		var localThis = this;
		setTimeout(function () {
			localThis.isAssigningBlocked.IsAssigningBlocked = !localThis.isAssigningBlocked.IsAssigningBlocked;
			localThis.isAssigningBlockedProcessing = false;
		}, 500);
	}

	onChangeDefaultUserServiceCenter(userServiceCenter: UserServiceCenter): void {
		this.entityViewModel.entity.userServiceCenters.forEach(serviceCenter => {
			serviceCenter.isDefault = serviceCenter === userServiceCenter;
		});
	}

	onCancelEditingUserGroup(dataItem: UserGroup): void {
		dataItem.isDefaultUserForGroup = dataItem.isDefaultUserForGroupPrevious;
	}

	onChangedUserGroupId(userGroupId: number, userGroup: UserGroup): void {
		if (!userGroupId) {
			userGroup.newUserGroupId = null;
		}

		if (this.isDuplicateGroup(userGroup)) {
			userGroup.newUserGroupId = userGroup.previousGroupId;
			return;
		}

		userGroup.isDefaultUserForGroupPrevious = userGroup.isDefaultUserForGroup;
		if (userGroupId && userGroup.previousGroupId !== userGroup.newUserGroupId) {
			userGroup.isDefaultUserForGroup = false;
		}

		userGroup.previousGroupId = userGroup.newUserGroupId;
	}

	onAddNewGroupRow(): void {
		this.isUsedUserGroupsList = false;
	}

	onSaveServiceCenter(dataItem: UserServiceCenter): void {
		if (!dataItem.serviceCenterName) {
			dataItem.serviceCenterName = this.getServiceCenterName(dataItem);
		}

		if (!dataItem.integraCode) {
			let sc = this.serviceCenters.find(sc => sc.id === dataItem.serviceCenterId)
			if (!!sc)
				dataItem.integraCode = sc.integraCode
		}
	}

	onUserGroupsFormFocus(event: any): void {
		if (this.isUsedUserGroupsList) {
			event.target.focus();
		} else {
			event.target.blur();
			this.isUsedUserGroupsList = true;
		}
	}

	isStorekeeper(): boolean {
		return this.entityViewModel.entity.userRoles.some(x => x.roleId === <number>RoleEnum.storekeeper);
	}

	handleRoleFilter(value) {
		this.roleFilterValueSubject.next(value);
	}

	roleFilter(value: string) {

		this.rolesState.filter.filters = [];

		if (value !== '') {
			this.rolesState.filter.filters.push({
				field: 'Name',
				operator: 'contains',
				value: value,
				ignoreCase: true
			});
		}

		this.lookupService.getData('roles', this.rolesState)
			.subscribe(data => this.roles = data);
	}

	switchUser(): void {
		const user = this.entityViewModel.entity;
		this.securityService.login({ login: user.login, password: null, switchRequest: true })
			.subscribe(res => {
				this.loading = false;
				window.open('/requests', '_self');
			}, x => {
				this.loading = false;
			});
	}

	public cellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || columnIndex > 0) {
			return;
		}

		sender.editRow(rowIndex);

	}

	public saveHandler(event: SaveEvent) {
		this.appService.MarkFormDirty(this.entityForm);

		if (event.dataItem.serviceCenterId) {
			if (this.entityViewModel.entity.userServiceCenters.some(s => s.serviceCenterId == event.dataItem.serviceCenterId)) {
				event.dataItem = null;
				this.notificationService.warning({ title: 'Повтор', message: 'Данный СЦ уже выбран', notificationType: NotificationType.Toast });
				return;
			}
			const userServiceCenterIds = this.entityViewModel.entity.userServiceCenters.map(x => x.serviceCenterId);

			userServiceCenterIds.push(event.dataItem.serviceCenterId);

			this.updateUserWarehousesByServiceCenters(userServiceCenterIds);
			this.updateFormWarehousesByServiceCenters(userServiceCenterIds);
		}

		if (event.dataItem.agreementId) {
			event.dataItem.agreementNumber = this.agreements.find(val => val.id == event.dataItem.agreementId).name;
			this.agreements = [];
		}

		if (event.dataItem.roleId) {
			if (this.entityViewModel.entity.userRoles.some(s => s.roleId == event.dataItem.roleId)) {
				event.dataItem = null;
				this.notificationService.warning({ title: 'Повтор', message: 'Данная роль уже выбрана', notificationType: NotificationType.Toast });
				return;
			}
			if (this.engineerRoles.includes(event.dataItem.roleId)) {
				this.loadEngubatorData();
			}
			if (event.dataItem.roleId == RoleEnum.selfEmployedEngineer) {
				this.entityViewModel.entity.isSelfEmployed = true;
			}
		}
	}

	public removeHandler(event: RemoveEvent) {
		this.appService.MarkFormDirty(this.entityForm);

		if (event.dataItem.serviceCenterId) {
			const userServiceCenterIds = this.entityViewModel.entity.userServiceCenters
				.filter(x => x.serviceCenterId !== event.dataItem.serviceCenterId)
				.map(x => x.serviceCenterId);

			const userWarehouses = this.userWarehouses.map(x => x.warehouseId);
			this.entityViewModel.entity.warehouseIds = this.warehouses
				.filter(x => x.serviceCenterIds.some(serviceCenterId => userServiceCenterIds.includes(serviceCenterId)) && userWarehouses.includes(x.warehouseId))
				.map(x => x.warehouseId);

			this.getUserWarehouses();

			this.updateFormWarehousesByServiceCenters(userServiceCenterIds);
		}
		if (event.dataItem.contragentId) {
			this.chooseAllClients = false;
		}
		if (event.dataItem.roleId) {
			if (this.engineerRoles.includes(event.dataItem.roleId) && !this.entityViewModel.entity.userRoles.filter(f => f.roleId != event.dataItem.roleId).some(s => this.engineerRoles.includes(s.roleId))) {
				this.entityViewModel.entity.isEngineer = false;
			}
			if (event.dataItem.roleId == RoleEnum.selfEmployedEngineer) {
				this.entityViewModel.entity.isSelfEmployed = false;
			}
		}
	}

	public saveWarehouseHandler({ dataItem }): void {
		this.appService.MarkFormDirty(this.entityForm);
		this.entityViewModel.entity.warehouseIds.push(dataItem.warehouseId);

		this.loadWarehouses();

		const userWarehouseIds = this.userWarehouses.map(x => x.warehouseId);
		const userServiceCenterIds = this.entityViewModel.entity.userServiceCenters.map(x => x.serviceCenterId);
		this.formWarehouses = this.warehouses
			.filter(x => x.serviceCenterIds.some(serviceCenterId => userServiceCenterIds.includes(serviceCenterId)) && !userWarehouseIds.includes(x.warehouseId) && x.warehouseId !== dataItem.warehouseId);
	}

	public removeWarehouseHandler({ dataItem }): void {
		this.appService.MarkFormDirty(this.entityForm);
		this.entityViewModel.entity.warehouseIds = this.entityViewModel.entity.warehouseIds.filter(v => v !== dataItem.warehouseId);

		if (this.warehousesGrid && this.entityViewModel.entity.userRoles.filter(x => x.roleId === <number>RoleEnum.storekeeper).length > 0) {
			this.chooseAllWarehouses = false;
		}

		const userWarehouseIds = this.userWarehouses.map(x => x.warehouseId);
		const userServiceCenterIds = this.entityViewModel.entity.userServiceCenters.map(x => x.serviceCenterId);
		this.formWarehouses = this.warehouses
			.filter(x => x.serviceCenterIds.some(serviceCenterId => userServiceCenterIds.includes(serviceCenterId)) && !userWarehouseIds.includes(x.warehouseId) || x.warehouseId === dataItem.warehouseId);
	}

	getRoleName(roleId: number) {
		const filtered = this.roles.filter(x => x.id === roleId);

		if (filtered.length === 0) {
			return '';
		}

		return filtered[0].name;
	}

	getGroupName(userGroup: UserGroup): string {
		const userGroupId = userGroup.newUserGroupId ? userGroup.newUserGroupId : userGroup.userGroupId;
		const userGroups = this.userGroupsHierarchyLookupModels.filter(x => x.id === userGroupId);
		if (userGroups.length > 0 && !userGroups[0].name) {
			const allGroups = userGroups.filter(x => x.id === userGroupId);
			return allGroups.length === 0 ? '' : allGroups[0].name;
		}

		return userGroups.length === 0 ? userGroup.name : userGroups[0].name;
	}

	getWarehouseName(id: number): string {
		const warehouse = this.warehouses.filter(x => x.warehouseId === id);
		return warehouse.length === 1 ? warehouse[0].name : '';
	}

	getWarehouseAddress(id: number): string {
		const warehouse = this.warehouses.filter(x => x.warehouseId === id);
		return warehouse.length === 1 ? warehouse[0].fullAddress : '';
	}

	getUserWarehouses(): WarehouseDto[] {
		this.userWarehouses = this.entityViewModel.entity.warehouseIds.map(warehouseId => {
			const userWarehouse = {
				warehouseId: warehouseId,
				name: this.getWarehouseName(warehouseId),
				fullAddress: this.getWarehouseAddress(warehouseId)
			} as WarehouseDto;

			return userWarehouse;
		});

		return this.userWarehouses;
	}

	updateFormWarehousesByServiceCenters(userServiceCenterIds: number[]): void {
		const userWarehouseIds = this.entityViewModel.entity.warehouseIds;
		this.formWarehouses = this.warehouses
			.filter(x => x.serviceCenterIds.some(serviceCenterId => userServiceCenterIds.includes(serviceCenterId)) && !userWarehouseIds.includes(x.warehouseId));
	}

	private updateUserWarehousesByServiceCenters(userServiceCenterIds: number[]): void {
		this.entityViewModel.entity.warehouseIds = this.warehouses
			.filter(x => x.serviceCenterIds.some(serviceCenterId => userServiceCenterIds.includes(serviceCenterId)))
			.map(x => x.warehouseId);

		this.getUserWarehouses();
	}

	onChangeUserWarehouse(listItem: WarehouseDto, dataItem: WarehouseDto): void {
		if (listItem.warehouseId !== dataItem.warehouseId && this.userWarehouses.some(x => x.warehouseId === listItem.warehouseId)) {
			this.notificationService.error({
				title: 'Ошибка валидации',
				message: 'Выбранный склад уже есть в вашем списке.',
				notificationType: NotificationType.SweetAlert
			});
		} else {
			dataItem.warehouseId = listItem.warehouseId;
			dataItem.name = listItem.name;
			dataItem.fullAddress = listItem.fullAddress;
		}
	}

	isDuplicateWarehouse(dataItem: WarehouseDto): boolean {
		const warehouses = this.userWarehouses.filter(x => x.warehouseId === dataItem.warehouseId);
		return warehouses.length > 1;
	}

	getServiceCenterName(dataItem: UserServiceCenter): string {
		const filteredServiceCenters = this.serviceCenters.filter(x => x.id === dataItem.serviceCenterId);
		return filteredServiceCenters[0].name;
	}

	getBlockchainAddress(): void {
		this.loading = true;
		this.ethereumGateService.getUsers(null, this.entityViewModel.entity.email
			, `${this.entityViewModel.entity.surname} ${this.entityViewModel.entity.name} ${this.entityViewModel.entity.middleName}`).subscribe(res => {

				if (!res.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при запросе пользователя из блокчейна',
						message: res.errorDescription,
						notificationType: NotificationType.SweetAlert
					});

				} else {
					var users = res.data;

					if (users.length == 1) {
						this.entityViewModel.entity.blockchainAddress = users[0].publicKey;

						this.notificationService.success({
							title: 'Успех',
							message: 'Блокчейн-адрес получен',
							notificationType: NotificationType.SweetAlert
						});

						this.appService.MarkFormDirty(this.entityForm);

					} else {
						const dialogRef = this.dialogService.open({ content: EthereumGateComponent, width: '80%', height: '60%' });

						const ethereumGateComponent = <EthereumGateComponent>dialogRef.content.instance;
						ethereumGateComponent.ethUsers = users;
						ethereumGateComponent.heightPercent = 60;

						ethereumGateComponent.selectedEvent.subscribe(key => {
							this.entityViewModel.entity.blockchainAddress = key;

							this.notificationService.success({
								title: 'Успех',
								message: 'Блокчейн-адрес получен',
								notificationType: NotificationType.SweetAlert
							});

							this.appService.MarkFormDirty(this.entityForm);

						});
					}
				}

				this.loading = false;
			});
	}

	public onClickChooseAllServiceCenters(): void {

		this.chooseAllServiceCenters = !this.chooseAllServiceCenters;

		if (this.chooseAllServiceCenters) {
			this.entityViewModel.entity.userServiceCenters = this.serviceCenters.map(x => {
				const userServiceCenter = { serviceCenterId: x.id, serviceCenterName: x.name } as UserServiceCenter;
				return userServiceCenter;
			});
		} else {
			this.entityViewModel.entity.userServiceCenters = [];
		}

		if (this.entityViewModel.entity.userRoles.some(x => x.roleId === <number>RoleEnum.storekeeper)) {
			this.onClickChooseAllWarehouses();
		}

	}

	public onClickChooseAllWarehouses(): void {
		this.chooseAllWarehouses = !this.chooseAllWarehouses;

		const userServiceCenterIds = this.entityViewModel.entity.userServiceCenters.map(x => x.serviceCenterId)
		this.userWarehouses = this.chooseAllWarehouses
			? this.warehouses
				.filter(x => x.serviceCenterIds.some(serviceCenterId => userServiceCenterIds.includes(serviceCenterId)))
			: [];

		if (this.chooseAllWarehouses) {
			this.formWarehouses = [];
		} else {
			this.formWarehouses = this.warehouses
				.filter(x => x.serviceCenterIds.some(serviceCenterId => userServiceCenterIds.includes(serviceCenterId)));
		}
	}

	askSetPerformerUserGroup(userGroup: UserGroup) {
		if (!userGroup.newUserGroupId) {
			userGroup.isDefaultUserForGroup = false;
			return;
		}

		if (userGroup.isDefaultUserForGroup) {
			return;
		}

		const currentUserName = this.entityViewModel.entity.displayName;
		const currentUserId = this.entityViewModel.entity.userId;
		this.userGroupService.getPerformerUserByGroup(userGroup.newUserGroupId).subscribe(res => {
			if (res.hasDefaultUser) {
				this.notificationService.confirmation({
					title: this.entityName,
					message: `Для группы ${res.groupName} уже имеется ответственный сотрудник: ${res.userName}.
					Хотите заменить его на ${currentUserName ? currentUserName : ''} (${currentUserId ? currentUserId : 'текущий'})?`,
					type: 'question',
					confirmButtonText: 'Да',
					cancelButtonText: 'Отмена',
					showCloseButton: true
				}, () => {
					userGroup.isDefaultUserForGroup = true;
				}, () => {
					userGroup.isDefaultUserForGroup = false;
				});
			}
		});
	}

	public saveUserChanges(): void {
		if (this.isStorekeeper()) {
			this.entityViewModel.entity.warehouseIds = this.userWarehouses.map(userWarehouse => userWarehouse.warehouseId);
		}

		this.saveChanges(this.entityName);
	}

	private loadWarehouses(): void {
		this.warehousesService.getAll()
			.subscribe(result => {
				this.warehouses = result;
				this.getUserWarehouses();

				const userServiceCenterIds = this.entityViewModel.entity.userServiceCenters.map(x => x.serviceCenterId);

				this.updateFormWarehousesByServiceCenters(userServiceCenterIds);
			});
	}

	isDuplicateGroup(userGroup: UserGroup): boolean {
		if (!userGroup.newUserGroupId) {
			return false;
		}

		const isExist = this.entityViewModel.entity.userGroups
			.some(((group, index, _) => group !== userGroup &&
				(group.userGroupId === userGroup.newUserGroupId || group.newUserGroupId === userGroup.newUserGroupId)) as any);
		if (isExist) {
			this.notificationService.error({
				title: 'Ошибка валидации',
				message: 'Выбранная группа уже есть в вашем списке.',
				notificationType: NotificationType.SweetAlert
			});
		}

		return isExist;
	}

	private loadDataBySelectedContragent(contragentId: number, loadData: (requestState: DataSourceRequestState) => void): void {
		const requestState: DataSourceRequestState = {
			filter: {
				logic: 'or',
				filters: [
					{ field: 'ContragentId', operator: 'eq', value: contragentId }
				]
			}
		};

		loadData(requestState);
	}

	public agreementsDefaultItem: KeyValueObject = new KeyValueObject(null, "-");
	public regionsDefaultItem: KeyValueObject = new KeyValueObject(null, "-");

	private async loadClients(): Promise<void> {
		const response = await this.contragentsService.lookupAllList([1]).toPromise();
		if (this.entityViewModel.entity != null && this.entityViewModel.entity.serviceBoundaries != null
			&& this.entityViewModel.entity.serviceBoundaries.length > 0) {
			var addedClientIds = this.entityViewModel.entity.serviceBoundaries
				.filter(val => val.agreementId == null && val.regionId == null).map(val => val.contragentId);
			this.clients = response.filter(val => !addedClientIds.includes(val.id));
		}
		else {
			this.clients = response;
		}
	}

	public addServiceBoundaryRow(): void {
		this.loadClients();
		this.agreements = [];
		this.filteredRegions = this.regions;
	}

	private loadRegions(): void {
		this.regionsService.regionsSingleLookup().subscribe(result => this.regions = result);
	}

	private clientsDDL_valueChange(event): void {
		if (event == null) {
			this.agreements = [];
		} else {
			this.lookupService.getData('agreements', {
				skip: 0,
				take: null,
				filter: {
					logic: 'and',
					filters: [{
						field: 'contragentId',
						operator: 'eq',
						value: event
					}]
				}
			}).subscribe(result => {
				var addedAgreementsIds = this.entityViewModel.entity.serviceBoundaries
					.filter(val => val.regionId == null && val.agreementId != null).map(val => val.agreementId);
				this.agreements = result.filter(val => !addedAgreementsIds.includes(val.id));
			})
		}
	}

	private getContragentName(serviceBoundary: ServiceBoundary): string {
		if (serviceBoundary.contragentName) {
			return serviceBoundary.contragentName;
		}
		const filtered = this.contragents.filter(x => x.id === serviceBoundary.contragentId);

		if (filtered.length === 0) {
			return '';
		}

		return filtered[0].name;
	}

	private getAgreementNumber(serviceBoundary: ServiceBoundary): string {
		if (serviceBoundary.agreementNumber) {
			return serviceBoundary.agreementNumber
		}
		const filtered = this.agreements.filter(x => x.id === serviceBoundary.agreementId);

		if (filtered.length === 0) {
			return '';
		}

		return filtered[0].name;
	}

	private getRegionName(serviceBoundary: ServiceBoundary): string {
		if (serviceBoundary.regionName) {
			return serviceBoundary.regionName;
		}
		const filtered = this.regions.filter(x => x.id === serviceBoundary.regionId);

		if (filtered.length === 0) {
			return '';
		}

		return filtered[0].name;
	}

	public async onClickChooseAllClients(): Promise<void> {
		this.chooseAllClients = !this.chooseAllClients;
		this.entityViewModel.entity.serviceBoundaries = [];
		if (this.chooseAllClients) {
			await this.loadClients();
			this.entityViewModel.entity.serviceBoundaries = this.clients.map(val => {
				const sb = { contragentId: val.id } as ServiceBoundary;
				return sb;
			});
		}
	}

	public regionsDDL_handleFilter(value: string) {
		this.filteredRegions = this.regions.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
	}

	private loadEngubatorData(integraCode?: string): void {
		this.loadingSkills = true;
		if (integraCode)
			this.engubatorUserService.getById(integraCode).subscribe(vm => {
				this.engubatorViewModel = vm;
				this.isAssigningBlocked.IsAssigningBlocked = this.engubatorViewModel.entity.isAssigningBlocked;
			})
		else {
			this.engubatorViewModel = new EntityViewModel<EngubatorEngineer>();
			this.engubatorViewModel.entity = new EngubatorEngineer();
			this.entityViewModel.entity.isEngineer = true;
			this.engubatorViewModel.isNew = true;
			this.isAssigningBlocked.IsAssigningBlocked = false;
		}

		this.engubatorSkillsService.list('includeComposition=true')
			.pipe(finalize(() => this.loadingSkills = false))
			.subscribe(result => this.skills = result.data);
		this.lookupService.getData("request-types", null).subscribe(data => this.requestTypesLookup = data);
		this.lookupService.getData("device-models", null).subscribe(data => this.deviceModelsLookup = data);
	}

	afterSaveChanges() {
		if (this.entityViewModel.entity.isEngineer) {
			this.dataSaving = true;

			if (this.entityViewModel.entity.integraCode) {
				this.saveEngubatorData();
			} else {
				this.dataService.getById(this.entityId).subscribe(result => {
					this.dataBind(result);
					this.saveEngubatorData();
				});
			}
		}
		super.afterSaveChanges();
	}

	private saveEngubatorData(): void {
		if (!!this.engubatorViewModel) {
			this.engubatorViewModel.entity.integraCode = this.entityViewModel.entity.integraCode;
			this.engubatorViewModel.entity.name = this.entityViewModel.entity.displayName;
			this.engubatorViewModel.entity.address = this.entityViewModel.entity.fullAddress;

			// до LTER-2687 у добавленных сервисных центров через UI не проставляся интегра код, теперь проставляется
			// для сервисных центров, добавленных до LTER-2687 через UI проставляем интегра код (если его нет) перед отправкой запроса в енгубатор
			this.entityViewModel.entity.userServiceCenters.forEach(usc => {
				if (!usc.integraCode) {
					let sc = this.serviceCenters.find(sc => sc.id === usc.serviceCenterId);
					if (!!sc)
						usc.integraCode = sc.integraCode
				}
			});

			this.engubatorViewModel.entity.serviceCenters = this.entityViewModel.entity.userServiceCenters.map(m => { return m.integraCode });
			const action = this.engubatorViewModel.isNew
				? this.engubatorUserService.create(this.engubatorViewModel.entity)
				: this.engubatorUserService.update(this.engubatorViewModel.entity, this.entityName, undefined, undefined, `/${this.engubatorViewModel.entity.integraCode}`);

			action.subscribe(res => {
				this.dataSaving = false;

				if (!res.isSuccessful)
					return;

				if (this.engubatorViewModel.isNew && res.isSuccessful)
					this.engubatorViewModel.isNew = false;

			}, x => this.dataSaving = false);
		} else {
			console.log("engubatorViewModel не существует");
		}
	}

	//>>skillsCard
	skills: EngubatorSkill[] = [];
	loadingSkills: boolean;
	requestTypesLookup: KeyValueObject[] = [];
	deviceModelsLookup: KeyValueObject[] = [];

	public requestTypes(): string[] {
		const compositionRequestTypes = this.engubatorViewModel.entity.skills
			.filter(x => x.composition)
			.map(x => x.composition)
			.reduce((a, b) => a.concat(b), [])
			.map(x => x.requestTypeId);

		return this.requestTypesLookup.filter(
			f => compositionRequestTypes.includes(f.id)
		).map(m => m.name);
	}

	public deviceModels(): string[] {
		const compositionDeviceModels = this.engubatorViewModel.entity.skills
			.filter(x => x.composition)
			.map(x => x.composition)
			.reduce((a, b) => a.concat(b), [])
			.map(x => x.deviceModelId);

		return this.deviceModelsLookup.filter(
			f => compositionDeviceModels.includes(f.id)
		).map(m => m.name);
	}

	public saveChanges(name: string = this.entityName): void {
		if (this.entityViewModel.entity.userRoles.some(r => this.engineerRoles.includes(r.roleId))) {
			if (this.engubatorViewModel && !this.engubatorViewModel.entity.regions.length) {
				this.notificationService.error({
					title: 'Ошибка',
					message: 'Необходимо выбрать регион инженера.',
					notificationType: NotificationType.Toast
				});

				return;
			}
		}

		super.saveChanges(name);
	}
	//<<skillsCard
}
