import { Injectable, Input, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject, fromEvent, merge, timer } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../core/services/notification.service';


@Injectable()
  export class IdleService implements OnDestroy {
    private _idleThreshold;
    private userActivity$ = new Subject<void>();
    private idleTimer$: BehaviorSubject<boolean>;
    private isIdle: boolean = false;
    private popupOpened: boolean = false;

    @Input() idleMessage: string = 'Вы долго не проявляли активность.';
  
    constructor(private zone: NgZone,
      private notificationService: NotificationService) {
        this.zone.runOutsideAngular(() => {
          this._idleThreshold = this._getTime(environment.idleTimeMinutes);
          this.initializeIdleTimer();
        });
    }
  
    private initializeIdleTimer() {
      const userActive$ = merge(
        fromEvent(document, 'mousemove').pipe(map(() => true)),
        fromEvent(document, 'keydown').pipe(map(() => true))
      ).pipe(debounceTime(250));
  
      this.idleTimer$ = new BehaviorSubject<boolean>(false);
  
      userActive$.subscribe(() => {
        if(!this.popupOpened){
          if(this.isIdle){
            this.idleTimer$.next(false);
          }
          this.userActivity$.next();
          this.isIdle = false;  
        }      
      });
  
      this.userActivity$.pipe(
        switchMap(() => {
          if (!this.popupOpened) {
            return timer(this._idleThreshold).pipe(takeUntil(this.userActivity$));
          } else {
            return EMPTY;
          }
        }))
        .subscribe(() => {
          this.idleTimer$.next(true);
          this.isIdle = true;
          
          this.showPopup();
      });
  
      this.resetIdleTimer();
    }

    private resetIdleTimer() {
      this.userActivity$.next();
    }

    private showPopup(){
      this.popupOpened = true;
      this.notificationService.confirmation({
        title: '',
        message: this.idleMessage,
        confirmButtonText: 'Продолжить работу',
        showCancelButton: false,
        imageUrl: "../../assets/images/snooze.svg",
        showIcon: false,
        imageHeight: 130,
        imageWidth: 130
      }, 
      () => this.popupOpened = false, 
      () => this.popupOpened = false, 
      () => this.popupOpened = false);  
    }
  
    getIdleStatus(): Observable<boolean> {
      return this.zone.runOutsideAngular(() => {
        return this.idleTimer$;
      });
    }

    ngOnDestroy() {
      this.userActivity$.complete();
      this.idleTimer$.complete();
    }

    private _getTime(minutes: number): number {
      return minutes * 1000 * 60;
    }
  }