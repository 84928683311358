export class UploadFile {
    uid: string;
    resumableUid: string;
    fileName: string;
    fileExtension: string;
    fileData: string;
    fileSize: number;
    attachmentTypeIds: number[] = [];
    attachmentSpotId?: number;
    isTemporary: boolean;

    get attachmentTypeId(): number {
        if (this.attachmentTypeIds.length > 0) {
            return this.attachmentTypeIds[0];
        } else {
            return null;
        }
	}

    set attachmentTypeId(value: number) {
        if (!!value) {
            this.attachmentTypeIds = [value];
        } else {
            this.attachmentTypeIds = [];
        }
    }
}
