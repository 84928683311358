import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import {RequestAttribute} from "../models/request-attribute/request-attribute";

@Injectable()
export class RequestAttributesService extends DataService<RequestAttribute, RequestAttribute> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'request-attributes';
	}
}
