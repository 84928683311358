import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { DataService } from "../../core/services/data.service";
import { NotificationService } from "../../core/services/notification.service";
import { ContragentProject } from "../models/contragent/contragent-project";
import { KeyValueObject } from "../models/core/KeyValueObject";

@Injectable()
export class ContragentProjectsService extends DataService<ContragentProject, ContragentProject> {

	constructor(
		protected http: HttpClient,
		protected notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'contragent-projects';
	}

	public getAgreementsLookup(): Observable<KeyValueObject[]> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/agreements`;
		return this.getQuery(url);
	}

}