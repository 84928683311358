import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoleSpecialRight } from '../../../shared/models';

@Component({
  selector: 'role-access-tab',
  templateUrl: './role-access-tab.component.html',
  styleUrls: ['./role-access-tab.component.scss']
})

export class RoleAccessTab implements OnInit {

  @Input() roleSpecialRights: RoleSpecialRight[] = [];
  @Input() canEditForm: boolean;
  @Input() specialRightsGroupId: number;
  @Output() changeAction: EventEmitter<void> = new EventEmitter();

  roleSpecialRightsFiltered: RoleSpecialRight[] = [];

  constructor() { }

  public ngOnInit() {
    this.roleSpecialRightsFiltered = this.roleSpecialRights.filter(f => f.specialRightsGroupId === this.specialRightsGroupId);
  }

  onChange() {
    this.changeAction.emit();
  }
}

