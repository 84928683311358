import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { NotificationService } from "../../../core/services/notification.service";
import { EngubatorDataService } from "./engubator.data.service";
import { EngubatorCommonSetting } from "../../models/engubator/engubator.setting";

@Injectable()
export class VeerouteCommonSettingsService extends EngubatorDataService<EngubatorCommonSetting, EngubatorCommonSetting>{
    constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
        this.controllerName='settings/common';
	}

	protected getNewTItem(): EngubatorCommonSetting {
		return new EngubatorCommonSetting();
	}
}
