import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { EntityViewModel } from '../../../shared/models/core/EntityViewModel';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { TidsService } from '../../../shared/services/tids.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { Tid } from '../../../shared/models/tid/tid';
import { CustomerObjectForm } from '../../customer-objects/customer-object/customer-object-form/customer-object.form';
import { MerchantForm } from '../../merchants/merchant/merchant-form/merchant.form';
import { DialogService } from '@progress/kendo-angular-dialog';
import { MerchantsService } from '../../merchants/merchants.service';
import { DaDataAddressComponent } from '../../../shared/components/dadata-address/dadata-address.component';
import { CustomerObjectLookupModel } from '../../../shared/models/customerObject/customer-object-lookup-model';
import { ContragentsService } from '../../contragents/contragents.service';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { ContragentType, DayOfWeek } from '../../../shared/enums';
import { Observable } from 'rxjs';
import { CategoryType } from '../../../shared/enums/category-type.enum';
import { CustomerObjectWorkingHoursComponent } from '../../../shared/components/customer-object/customer-object-working-hours/customer-object-working-hours.component';
import { AddressSuggestion } from '../../../shared/models/dadata-address/address-suggestion';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap/tabset/tabset.module';
import { ServiceCategoriesService } from '../../../shared/services/service-categories.service';
import { SecurityService } from '../../../core/services/security.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { formatDate } from '@angular/common';
import { CustomerServiceRequest } from '../../../shared/models/tid/customer-service-request';
import { TidDevicesDto } from '../../../shared/models/tid/TidDevicesDto';

const flatten = filter => {
	const filters = filter.filters;
	if (filters) {
		return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
	}
	return [];
};


@Component({
	selector: 'categories-tid',
	templateUrl: './tid.page.html',
	styleUrls: [
		'./tid.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class TidPage extends ComponentPageBase<Tid> implements OnInit {
	@ViewChild("daDataAddressControl") public daDataAddressControl: DaDataAddressComponent;
	@ViewChild("customerObjectWorkingHoursControl") public customerObjectWorkingHoursControl: CustomerObjectWorkingHoursComponent;

	headerCustomerObjectName = "Новый объект ТСП";
	headerContragentName = "Новый контрагент";
	headerMerchantName = "Новое ТСП";
	//activeIdString: any = "main";
	//categoryTypeId = <number>CategoryType.tids;

	activeIdString: any = "main";
	categoryTypeId = <number>CategoryType.tids;

	serviceCenters: KeyValueObject[] = [];
	clients: KeyValueObject[] = [];
	agreements: KeyValueObject[] = [];
	customerObjects: CustomerObjectLookupModel[] = [];
	merchants: KeyValueObject[] = [];
	acquirers: KeyValueObject[] = [];
	serviceCategories: KeyValueObject[] = [];
	customerServiceStartRequests: KeyValueObject[] = [];
	customerServiceEndRequests: KeyValueObject[] = [];
	tidDevices: TidDevicesDto[] = [];

	isStartRequestsOpen: boolean = false;
	isEndRequestsOpen: boolean = false;
	startRequestValue: KeyValueObject = new KeyValueObject(0, "Не указан");
	endRequestValue: KeyValueObject = new KeyValueObject(0, "Не указан");

	public dropDownVirtualSettings: any = {
		itemHeight: 28,
	};

	public isNewObjectButtonEnabled: boolean;
	public isTidDuplicate: boolean;
	public loadingMerchants: boolean;
	public loadingAgreements: boolean = false;

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: TidsService,
		private lookupService: LookupService,
		private securityService: SecurityService,
		private contragentsService: ContragentsService,
		protected dialogService: DialogService,
		protected merchantsService: MerchantsService,
		protected serviceCategoriesService: ServiceCategoriesService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/tids';
		this.appService.pageTitle = 'Новый терминал';
		this.isNewObjectButtonEnabled = true;
	}

	public gridSettings: any = {
		clearFilter: true,
		state: {
			skip: 0,
			take: 5
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'deviceSupplierName',
				title: 'Производитель устройства',
				filter: 'string',
				filterable: false,
				width: 150,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'deviceModelName',
				title: 'Модель устройства',
				filter: 'string',
				filterable: false,
				width: 150,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'serialNumber',
				title: 'С/Н',
				filter: 'string',
				filterable: false,
				width: 150,
				hidden: false
			}
		]
	}

	ngOnInit() {

		this.entityId = this.route.snapshot.paramMap.get('tidId');

		this.initLookups();

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId).subscribe(vm => {

				const scState: DataSourceRequestState = {
					filter: { logic: 'and', filters: [
						{ field: 'IsActual', operator: 'eq', value: true },
					] }
				};
		
				this.lookupService.getData("service-centers", scState).subscribe(data => {
					if (!!vm 
						&&!!vm.entity
						&&!!vm.entity.serviceCenterId
						&& !data.some(s => s.id === vm.entity.serviceCenterId)) {

						const scState2: DataSourceRequestState = {
							filter: {
								logic: 'and', filters: [
									{ field: 'ServiceCenterId', operator: 'eq', value: vm.entity.serviceCenterId },
								]
							}
						};

						this.lookupService.getData("service-centers", scState2).subscribe(data2 => {
							let disElem = data2.find(f => f.id === vm.entity.serviceCenterId);

							if (!!disElem) {
								disElem.disabled = true;
								this.serviceCenters = data;
								this.serviceCenters.push(disElem);
							}
						});
					} else {
						this.serviceCenters = data;
					}
				});


				this.entityName = `${vm.entity.tidNumber}`;
				vm.entity.startDate = new Date(vm.entity.startDate);
				if (vm.entity.endDate) {
					vm.entity.endDate = new Date(vm.entity.endDate);
				}

				if (vm.entity.customerServiceDateEnd) {
					vm.entity.customerServiceDateEnd = new Date(vm.entity.customerServiceDateEnd);
				}

				if (vm.entity.customerServiceDateStart) {
					vm.entity.customerServiceDateStart = new Date(vm.entity.customerServiceDateStart);
				}

				if (vm.entity.merchantId) {
					this.loadCustomerObjects(vm.entity.merchantId);
				}

				this.merchants.push(new KeyValueObject(vm.entity.merchantId, vm.entity.merchantLegalName));

				this.dataBind(vm);

				
				this.loadServiceRequestHistory();

				this.loadServiceCategories();

				this.loadTidDevices(vm.entity.tidNumber);

				this.queryAgreements(this.entityViewModel.entity.customerContragentId).subscribe(data => this.agreements = data);
				setTimeout(() => {


					if (this.daDataAddressControl) {
						this.daDataAddressControl.initValue(this.entityViewModel.entity.customerObjectAddress);
					}
				}, 1000);
			});

		} else {
			this.entityViewModel = new EntityViewModel<Tid>();
			this.entityViewModel.entity = new Tid();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;
		}
	}

	itemDisabledSc(itemArgs: { dataItem: KeyValueObject; index: number }) {
		return itemArgs.dataItem.disabled;
	}

	initLookups() {
		this.contragentsService.contragentsAcquirers().subscribe(data => this.acquirers = data);
		this.contragentsService.getByTypes([ContragentType.bankEquer]).subscribe(data => this.clients = data.data);
	}

	public customerContragentChange(clientId: number) {
		this.entityViewModel.entity.agreementId = null;
		this.loadAgreements(clientId);
	}

	queryAgreements(clientId: number): Observable<KeyValueObject[]> {
		let state = <DataSourceRequestState>{
			filter: { logic: "or", filters: [{ field: "ContragentId", operator: "eq", value: clientId }] }
		};
		return this.lookupService.getData("agreements", clientId ? state : null);
	}

	public loadAgreements(clientId: number) {
		this.loadingAgreements = true;
		this.queryAgreements(clientId).subscribe(data => {
			this.agreements = data;
			if (!this.entityViewModel.entity.agreementId) {
				if (this.agreements.length === 1) {
					this.entityViewModel.entity.agreementId = this.agreements[0].id;
					this.agreementChanged();
				} else {
					this.entityViewModel.entity.agreementId = null;
				}
			}
			this.loadingAgreements = false;
		});
	}

	public loadTidDevices(tidNumber: string) {
		this.dataService.getTidDevices(tidNumber).subscribe(data => {
			this.tidDevices = data;
		});
	}

	async merchantLoad(text: string) {
		if (text !== null && text.length >= 5) {
			await new Promise(resolve => setTimeout(resolve, 1000));
			this.merchantsService.getLegalNames(text).subscribe(data => this.merchants = data);
		}
	}

	public merchantChange(merchantId: number) {
		this.entityViewModel.entity.customerObjectName = null;
		this.entityViewModel.entity.customerObjectId = null;
		this.entityViewModel.entity.customerObjectDistanceFromCityKm = null;
		this.entityViewModel.entity.customerObjectAddress = null;
		this.entityViewModel.entity.serviceCenterId = null;
		this.entityViewModel.entity.workingHours = [];
		this.loadCustomerObjects(merchantId, this.entityViewModel.entity.customerObjectId);
		this.merchantsService.getById(merchantId).subscribe(data => {
			this.entityViewModel.entity.legalAddress = data.entity.contragentAddress;
			this.entityViewModel.entity.mid = data.entity.mid;
			this.entityViewModel.entity.acquirerId = data.entity.acquirerId;
			this.entityViewModel.entity.acquirerName = data.entity.acquirerName;
			if (data.entity.clientId) {
				this.entityViewModel.entity.customerContragentId = data.entity.clientId;
				this.entityViewModel.entity.agreementId = data.entity.clientAgreementId;
				this.agreementChanged();
				this.loadAgreements(this.entityViewModel.entity.customerContragentId);
			}
		});
	}

	public customerObjectChange(customerObjectId: number) {
		var customerObjectFiltered = this.customerObjects.filter(x => x.id == customerObjectId);
		if (customerObjectFiltered.length === 0)
			return;

		this.entityViewModel.entity.customerObjectName = customerObjectFiltered[0].name;
		this.entityViewModel.entity.customerObjectAddress = customerObjectFiltered[0].address;
		this.entityViewModel.entity.customerObjectDistanceFromCityKm = customerObjectFiltered[0].distanceFromCityKm;
		this.entityViewModel.entity.serviceCenterId = customerObjectFiltered[0].serviceCenterId;
		this.entityViewModel.entity.workingHours = customerObjectFiltered[0].workingHours;
		setTimeout(() => {
			if (this.daDataAddressControl) {
				this.daDataAddressControl.initValue(this.entityViewModel.entity.customerObjectAddress);
			}
		}, 1000);
	}

	public loadCustomerObjects(merchantId: number, customerObjectId: number = null) {
		this.loadingMerchants = true;

		this.merchantsService.getCustomerObjects(merchantId, customerObjectId).subscribe(data => {
			this.customerObjects = data;
			if (customerObjectId) {
				this.entityViewModel.entity.customerObjectId = customerObjectId;
				this.customerObjectChange(customerObjectId);
			}
			if (this.entityViewModel.entity.customerObjectId == null && data.length == 1) {
				let item = data[0];
				this.entityViewModel.entity.customerObjectName = item.name;
				this.entityViewModel.entity.customerObjectId = item.id;
				this.entityViewModel.entity.customerObjectDistanceFromCityKm = item.distanceFromCityKm;
				this.entityViewModel.entity.customerObjectAddress = item.address;
				this.entityViewModel.entity.serviceCenterId = item.serviceCenterId;
				this.entityViewModel.entity.workingHours = item.workingHours;
			}
			setTimeout(() => {
				if (this.daDataAddressControl) {
					this.daDataAddressControl.initValue(this.entityViewModel.entity.customerObjectAddress);
				}
			}, 1000);
			this.loadingMerchants = false;
		});
	}

	public showWindowCustomerObject() {

		const dialogRef = this.dialogService.open({ content: CustomerObjectForm, width: '100%' });

		const customerObjectForm = <CustomerObjectForm>dialogRef.content.instance;
		customerObjectForm.header = this.headerCustomerObjectName;
		customerObjectForm.showCancelButton = true;
		customerObjectForm.merchantId = this.entityViewModel.entity.merchantId;
		customerObjectForm.afterCancelEvent.subscribe(() => this.afterCloseObjectChild());
		customerObjectForm.afterSaveChangesEvent.subscribe((value) => this.afterSaveChangesCustomerObjectChild(value));
	}

	public showWindowMerchant() {

		const dialogRef = this.dialogService.open({ content: MerchantForm, width: '100%' });

		const merchantForm = <MerchantForm>dialogRef.content.instance;
		merchantForm.header = this.headerMerchantName;
		merchantForm.showCancelButton = true;
		merchantForm.afterCancelEvent.subscribe(() => this.afterCloseObjectChild());
		merchantForm.afterSaveChangesEvent.subscribe((value) => this.afterSaveChangesmerchantChild(value));
	}

	showAddCustomerObject(): void {
		this.showWindowCustomerObject();
		this.isNewObjectButtonEnabled = false;
	}

	showAddMerchantObject(): void {
		this.showWindowMerchant();
		this.isNewObjectButtonEnabled = false;
	}

	afterCloseObjectChild() {
		this.isNewObjectButtonEnabled = true;
	}

	afterSaveChangesCustomerObjectChild(value: KeyValueObject) {
		this.isNewObjectButtonEnabled = true;
		this.entityForm.controls["customerObject"].markAsDirty();
		this.loadCustomerObjects(this.entityViewModel.entity.merchantId, value.id);
	}

	afterSaveChangesmerchantChild(value: KeyValueObject) {
		this.isNewObjectButtonEnabled = true;
		this.merchants.push(value);
		this.entityViewModel.entity.merchantId = value.id;
		this.entityForm.controls["merchant"].markAsDirty();
		this.merchantChange(this.entityViewModel.entity.merchantId);
	}

	clearCheckTidDuplicate(): void {
		this.isTidDuplicate = false;
	}

	saveChanges(name: string = this.entityName): void {
		this.dataService.getCheckTidExists(
			this.entityViewModel.entity.tidNumber, 
			this.entityViewModel.entity.tidId, 
			this.entityViewModel.entity.acquirerId,
			this.entityViewModel.entity.agreementId
		).subscribe(x => {
			this.isTidDuplicate = x.length > 0;
			if (this.isTidDuplicate) {
				return;
			}

			super.saveChanges();
		});
	}

	onAddressChange(value: AddressSuggestion) {
		this.entityViewModel.entity.customerObjectAddress = value.address;

		if (!!value) {
			this.appService.MarkFormDirty(this.entityForm);
		} else {
			this.appService.MarkFormPristine(this.entityForm);
		}
	}

	get isAddressValid(): boolean {
		if (!!this.daDataAddressControl) {
			return this.daDataAddressControl.isValid();
		} else {
			return true;
		}
	}

	beforeSaveChanges() {
		this.entityViewModel.entity.workingHours = this.customerObjectWorkingHoursControl.workingHours;
	}

	tabChanged(e: NgbTabChangeEvent) {
		if (e.nextId === "main-tab") {
			setTimeout(() => {
				if (this.daDataAddressControl) {
					this.daDataAddressControl.initValue(this.entityViewModel.entity.customerObjectAddress);
				}
			}, 1000);
		}

		this.activeIdString = e.nextId;
	}

	agreementChanged(): void {
		this.entityViewModel.entity.serviceCategoryId = null;
		this.loadServiceCategories();
	}

	private get isServiceCategoriesAccess() {
		return this.securityService.hasClaim("cat-servicecategories-read") || this.securityService.hasClaim("cat-servicecategories-edit");
	}

	loadServiceCategories(): void {
		if (!!this.entityViewModel.entity.agreementId && this.isServiceCategoriesAccess) {
			this.serviceCategoriesService.getByAgreementId(this.entityViewModel.entity.agreementId).subscribe(result => this.serviceCategories = result.data);
		}
		else {
			this.serviceCategories = [];
		}
	}

	loadServiceRequestHistory() {
		this.dataService.getServiceRequestHistory(this.entityId).subscribe(data => {
			this.customerServiceStartRequests = data.customerServiceStartRequests.map(item => 
				new KeyValueObject(item.requestId, this.formatCustomerServiceRequest(item)));
			this.customerServiceEndRequests = data.customerServiceEndRequests.map(item => 
				new KeyValueObject(item.requestId, this.formatCustomerServiceRequest(item)));
				
			if (this.customerServiceStartRequests.length > 0 && this.entityViewModel.entity.customerServiceDateStart != null)
				this.startRequestValue = this.customerServiceStartRequests[0];
			if (this.customerServiceEndRequests.length > 0 && this.entityViewModel.entity.customerServiceDateEnd != null)
				this.endRequestValue = this.customerServiceEndRequests[0];
		});
	}

	get startRequestsIsSingle(): boolean {
		return this.customerServiceStartRequests.length <= 1 &&
			   this.entityViewModel.entity.customerServiceDateStart != null;
	}

	get endRequestsIsSingle(): boolean {
		return this.customerServiceEndRequests.length <= 1 && 
			   this.entityViewModel.entity.customerServiceDateEnd != null;
	}

	private formatCustomerServiceRequest(item: CustomerServiceRequest) {
		var actionStringDate = item.serviceDate !== null
			? formatDate(item.serviceDate, 'dd.MM.yyyy', 'ru_RU') 
			: "";

		return item.requestId !== null	
			? `${item.requestId} ${actionStringDate}`
			: `${actionStringDate}`;
	}

	customerServiceStartRequestsClick(useCurrentItem = false) {
		if (this.startRequestsIsSingle || useCurrentItem) {
			this.navigateToRequestPage(this.startRequestValue);
		}
	}

	customerServiceEndRequestsClick(useCurrentItem = false) {
		if (this.endRequestsIsSingle || useCurrentItem) {
			this.navigateToRequestPage(this.endRequestValue);
		}
	}

	navigateToRequestPage(dataItem: KeyValueObject) {
		if (dataItem.id !== null && dataItem.id !== 0) {
			window.open(`/requests/${dataItem.id}`, '_blank');
		}
	}
}
