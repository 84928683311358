﻿import { Attachment } from "../attachment/attachment";

export class RequestChangeLog {
	requestId: number;
	requestChangeLogId: number;
	userName: string;
	changeDate: Date;
	changeDateMoscow: Date;
	changeDateLocal: Date;
	propertyName: string;
	oldValue: string;
	newValue: string;
	comment: string;
	attachment: Attachment;
	constructor(obj: Partial<RequestChangeLog>) {
		Object.assign(this, obj);
	}
}
