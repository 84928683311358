import { BaseObject } from '../core/BaseObject';
import { RequestPersonalDataRegion } from '../request/request-personal-data-region';

export class SelfEmployedData extends BaseObject {
	surname: string;
	name: string;
	patronymic: string;
	phone: bigint;
	email: string;
	telegramId: string;
	passportNumber: string;
	inn: string;
	taxRegistrationCertNumber: string;
	registrationDate: Date;
	deRegistrationDate: Date;
	passportDate: Date;
	telegramName: string;
	deRegistrationReason: string;
	typeOfActivity: string;
	oktmoRegion: string;
	bik: bigint;
	accountNumber: bigint;
	lastUpdateDate: Date;
	nationalityCountryCode: string;
	regionServiceCenter: RequestPersonalDataRegion[];
	regionServiceCenterString: string;
	fullName: string;
	phonenumber: string;
}
