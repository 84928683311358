import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// *******************************************************************************
// Layouts

import { LayoutComponent } from './layout/layout/layout.component';
import { PageNotFoundPage } from './layout/errors/PageNotFound/PageNotFound.page';

// *******************************************************************************
// Pages

import { AuthGuard } from './core/guards/auth.guard';
import { CoreModule } from './core/core.module';
import { loginRoutes } from './login/login-routing.module';
import { homeRoutes } from './home/home-routing.module';
import { adminRoutes } from './admin/admin-routing.module';
import { categoriesRoutes } from './categories/categories-routing.module';
import { requestsRoutes } from './requests/requests-routing.module';
import { activitiesRoutes } from './activities/activities-routing.module';
import { warehouseWorkplaceRequestsRoutes } from './warehouse-workplace-requests/warehouse-workplace-requests-routing.module';
import { reportsRoutes } from './reports/reports-routing.module';


// *******************************************************************************
// Routes

const routes: Routes = [
  { path: 'login', children: loginRoutes },
  {
    path: '',
    component: LayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: '', children: homeRoutes, canActivate: [AuthGuard] },
      { path: 'admin', children: adminRoutes, canActivate: [AuthGuard] },
      { path: 'categories', children: categoriesRoutes, canActivate: [AuthGuard] },
      { path: 'requests', children: requestsRoutes, canActivate: [AuthGuard] },
      { path: 'activities', children: activitiesRoutes, canActivate: [AuthGuard] },
      { path: 'warehouse-workplace-requests', children: warehouseWorkplaceRequestsRoutes, canActivate: [AuthGuard] },
      { path: 'reports', children: reportsRoutes, canActivate: [AuthGuard] },
      { path: '**', component: PageNotFoundPage, canActivate: [AuthGuard] }
    ],

  }
];

@NgModule({
  imports: [
    CoreModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
