import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Attachment } from '../models/attachment/attachment';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { switchMap, take, tap } from "rxjs/operators";
import { UploadAttachmentsModel } from '../models/attachment/upload-attachments-model';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { CompressedAttachment } from '../models/attachment/compressed-attachment';
import { NotificationType } from '../../core/services/notification-type';
import { SendAttachmentsToCustomerModel } from '../models/attachment/send-attachments-to-customer-model';
import { UploadFile } from '../models/attachment';
import { UploadInChunksService } from './upload-in-chunks.service';
import { AppService } from '../../app.service';

@Injectable()
export class AttachmentsService extends DataService<Attachment, Attachment> {

	imageExtensions: string[] = ['.png', '.jpg', '.jpeg'];

	constructor(http: HttpClient, 
		notificationService: NotificationService,
		public chunkService: UploadInChunksService,
		public appService: AppService,) {
		super(http, notificationService);
		this.controllerName = 'attachments';
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	public getAttachmentById(attachmentId: number): Observable<any> {
		const apiDownloadUrl = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/download/${attachmentId}`;
		return this.http.get(apiDownloadUrl, { responseType: 'blob' });
	}

	public getAgreementAttachmentById(attachmentId: number): Observable<any> {
		const apiDownloadUrl = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/download/agreement/${attachmentId}`;
		return this.http.get(apiDownloadUrl, { responseType: 'blob' });
	}

	public upload(uploadModel: UploadAttachmentsModel): Observable<any> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/upload`;
		return this.http.post(url, uploadModel, {
			reportProgress: true,
			observe: "events"
		});
	}

	public uploadWithResumableIfMobile(data: UploadAttachmentsModel): Observable<any> {
		if (this.isMobileDevice){
			return this.chunkService.uploadFiles(data.attachments).pipe(
				switchMap((result: any[]) => {
					let attachments = data.attachments.map((file) => {
						let chunkResult = result.find(x => x.resultObj.fileUid === file.uid);
						let newFile = new UploadFile();
						newFile.uid = file.uid;
						newFile.resumableUid = chunkResult.resultObj.resumableUid;
						newFile.fileName = file.fileName;
						newFile.fileExtension = file.fileExtension;
						newFile.fileSize = file.fileSize;
						newFile.attachmentTypeIds = file.attachmentTypeIds;
						newFile.attachmentSpotId = file.attachmentSpotId;
						newFile.fileData = null;
						return newFile;
					});
					let uploadModel = new UploadAttachmentsModel({ 
							requestId: data.requestId, 
							activityId: data.activityId,
							requestUninstallDeviceId: data.requestUninstallDeviceId,
							needToRestoreChunks: true,
							chunkSize: this.chunkService.getChunkSize(),
							attachments: attachments
						});
					return this.upload(uploadModel);
				})
			);
		} else {
			return this.upload(data);
		}	
	}


	public compressAttachment(fileName: string, fileData: string): Observable<ActionDataResult<CompressedAttachment>> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/compress`, {
			fileName: fileName,
			fileData: fileData
		});
	}

	public sendToCustomer(sendModel: SendAttachmentsToCustomerModel): Observable<any> {
		return this.postQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/send-to-customer`, sendModel);
	}

	public download(attachmentPath: string): Observable<ActionDataResult<any>> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/download-from-path?attachmentPath=${attachmentPath}`;
		return this.http.get<ActionDataResult<any>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при скачивании файла',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при скачивании файла',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}

	isImageExtension(extension: string): boolean {

		if (extension == null)
			return false;


		extension = extension.toLowerCase();

		return this.imageExtensions.some(ext => extension === ext);
	}
}
