import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

	constructor(private router: Router,
		private securityService: SecurityService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler):
		Observable<HttpEvent<any>> {

		if (req.url === 'saveUrl' || req.url === 'removeUrl')
			return next.handle(req);

		var authUser = localStorage.getItem("authUser");

		if (authUser) {

			var newReq = req.clone({
				headers: req.headers.set('Authorization', `Bearer ${JSON.parse(authUser).bearerToken}`)
			});

			return next.handle(newReq)
				.do((event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {

						// var body = <ActionResult>event.body;

						// if (body.code === 904 && this.securityService.currentUser.isAuthenticated) {
						// 	this.securityService.logout();
						// 	this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url, tokenHasExpired: true } });
						// }
					}
				}, (err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 401) {
							this.redirect();
						}
					}
				});
		}
		else {
			return next.handle(req).pipe(tap(() => { },
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status !== 401) {
							return;
						}
						this.redirect();
					}
				}));
		}
	}

	redirect() {
		this.securityService.logout()
		this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url, tokenHasExpired: true } });
	}
};

@NgModule({
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true
		}
	]
})
export class HttpInterceptorModule { }
