import { Injectable, NgModule } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { delay } from 'rxjs/operators/delay';

@Injectable()
export class HttpUploadInterceptor implements HttpInterceptor {

	constructor() { }

	intercept(req: HttpRequest<any>, next: HttpHandler):
		Observable<HttpEvent<any>> {

			if (req.url === 'saveUrl') {
				return of(new HttpResponse({ status: 200 }));
				// const events: Observable<HttpEvent<any>>[] = [0, 30, 60, 100].map((x) => of(<HttpProgressEvent>{
				// 	type: HttpEventType.UploadProgress,
				// 	loaded: x,
				// 	total: 100
				// }).pipe(delay(1000)));
	
				// const success = of(new HttpResponse({ status: 200 })).pipe(delay(1000));
				// events.push(success);
	
				// return concat(...events);
			}
	
			if (req.url === 'removeUrl') {
				return of(new HttpResponse({ status: 200 }));
			}
	
			return next.handle(req);
	}
};

@NgModule({
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpUploadInterceptor,
			multi: true
		}
	]
})
export class HttpUploadInterceptorModule { }