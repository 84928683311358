import { BaseObject } from "../core/BaseObject";
import { WarehouseArea } from './warehouse-area';
import { WarehouseAreaCell } from './warehouse-area-cell';
import { WarehouseContact } from "./warehouse-contact";

export class WarehouseItem extends BaseObject {
    warehouseId: number;
    name: string;
    description: string;
    integraCode: string;
    partnerCode: string;
    addressId: number;
    fullAddress: string;
    contragentOwnerId: number;
    contragentDepartmentId: number;
    phone: string;
    serviceCentersNames: string[] = [];
    warehouseAreas: WarehouseArea[] = [];
    warehouseCells: WarehouseAreaCell[] = [];
    warehouseContactsNewMain: WarehouseContact[] = [];
    warehouseContactsNewSmz: WarehouseContact[] = [];
    modeId: number;
    modeDescription: string;
    postCode: string;
	cityName: string;
	street: string;
	house: string;
    phoneNumber: string;
	fullName: string;
}
