import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { DeviceConditionDto } from '../models/device/device-condition.dto';

@Injectable()
export class DeviceConditionsService extends DataService<DeviceConditionDto, DeviceConditionDto> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'device-conditions';
	}

	public getConditionsByContragent(contragentId: number): Observable<DeviceConditionDto[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-conditions-by-contragent/${contragentId}`);
	}

	public getConditionsByContragentsConditionType(contragentId: number, equipmentIsBroken: boolean): Observable<DeviceConditionDto[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/get-conditions-by-contragent-condition-type?contragentId=${contragentId}&equipmentIsBroken=${equipmentIsBroken}`);
	}
}
