export class GetRequestsForCheckQuery {
    constructor(
        filterId: number,
        dateFrom: string,
        dateTo: string
    ) {
        this.filterId = filterId;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
    }

    filterId: number;
    dateFrom: string;
    dateTo: string;
}