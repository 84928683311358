import { UploadFile } from './upload-file';

export class UploadAttachmentsModel {

	public requestId: number;
	public commentId: number;
	public activityId: number;
	public requestUninstallDeviceId: number;
	public attachmentTypeId: number;
	public attachments: UploadFile[];
	public needToRestoreChunks: boolean = false;
	public chunkSize : number;

	constructor(obj: Partial<UploadAttachmentsModel>) {
		Object.assign(this, obj);
	}
}
