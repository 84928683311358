import { NewRequestDevice } from "../../../../models/request/new-request/new-request-device";
import { DismantledSeriesGeneralPropertiesData } from "../dismantled-series-general-properties/dismantled-series-general-properties-data";
import { DismantledSeriesImagesData } from "../dismantled-series-images/dismantled-series-images-data";
import { DismantledSeriesSerialPartNumbersData } from "../dismantled-series-serial-part-numbers/dismantled-series-serial-part-numbers-data";

export class CompleteActivityDismantledSeriesData {
    
    requestId: number;
    activityId: number;
    requestUninstallDeviceId: number;
    
    imagesData: DismantledSeriesImagesData = null;
    serialAndPartNumbersData: DismantledSeriesSerialPartNumbersData = null;
    generalPropertiesData: DismantledSeriesGeneralPropertiesData = null;

    constructor(
        requestId: number,
        activityId: number,
        needSecurityKeyForReserve: boolean,
        contragentId: number,
        requestUninstallDevice: NewRequestDevice = null
    ) {
        this.requestId = requestId;
        this.activityId = activityId;

        this.imagesData = new DismantledSeriesImagesData;;
        this.serialAndPartNumbersData = new DismantledSeriesSerialPartNumbersData;
        this.generalPropertiesData = new DismantledSeriesGeneralPropertiesData(needSecurityKeyForReserve, contragentId);
    
        if (requestUninstallDevice != null) {
            this.requestUninstallDeviceId = requestUninstallDevice.requestDeviceId;
            this.serialAndPartNumbersData.serialNumber = requestUninstallDevice.serialNumber;
            this.serialAndPartNumbersData.partNumber = requestUninstallDevice.partNumber;
            this.serialAndPartNumbersData.nomenclature = requestUninstallDevice.nomenclature;
            this.generalPropertiesData.deviceConditionId = requestUninstallDevice.deviceConditionId;
            this.generalPropertiesData.isDeviceBroken = requestUninstallDevice.isDeviceBroken;
            this.generalPropertiesData.brokenDescription = requestUninstallDevice.brokenDescription;
            this.generalPropertiesData.completeKit = requestUninstallDevice.completeKit;
            this.generalPropertiesData.hasCryptoKey = requestUninstallDevice.hasCryptoKey;
            this.generalPropertiesData.cryptoKey = requestUninstallDevice.cryptoKey;
        }
    }
}