import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule as KendoSharedModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LaddaModule } from 'angular2-ladda';
import { LayoutModule } from '../layout/layout.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DirectoriesModule } from '../categories/categories.module';
import { ReportsRoutingModule } from './reports-routing.module';
import { ExpertisesForm } from './expertises/expertises-form/expertises.form';
import { SharedModule } from '../shared/shared.module';
import { GeneralEquipmentReport} from '../reports/general-equipment-report/general-equipment-report.page'


@NgModule({
  declarations: [
    ReportsRoutingModule.components,
    ExpertisesForm,
    GeneralEquipmentReport
  ],
  imports: [
    CommonModule,
    SharedModule,
    KendoSharedModule, 
    DateInputsModule,
		FormsModule,
		RouterModule,
		LaddaModule,
		ExcelModule,
		LayoutModule,
		PerfectScrollbarModule,
		DirectoriesModule,
		NgbModule,
    ReactiveFormsModule
  ]
})
export class ReportsModule { }
