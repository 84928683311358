import { ExpertiseActDevice } from "./expertise-act-device";
import { ExpertiseActSetting } from "./expertise-act-setting";

export class ExpertiseActFormList {
	requestId: number;
	externalId: string;

	devices: ExpertiseActDevice[] = [];

	allForms: ExpertiseActSetting[] = [];
}
