import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { NotificationService } from "../../core/services/notification.service";
import { SegmentDto } from "../models/oSM/segment-dto";
import { LinkServiceCenterRegionsRequest } from "../models/oSM/list-service-regions-request";
import { ServiceCenterResponse } from "../models/oSM/service-center-response";

@Injectable()
export class OSMService {

    protected controllerName = 'osm';
    protected controllerVersion = 1;

    constructor(protected http: HttpClient,
        protected notificationService: NotificationService) {
    }

    protected baseUrl(): string {
        return `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}`;
    }

    public getRegions(segmentName: string, segmentValue: string = null, hierarchy: boolean = true): Observable<SegmentDto> {
		return this.http.get<SegmentDto>(`${this.baseUrl()}/regions?segmentName=${segmentName}&segmentName=${segmentValue}&hierarchy=${hierarchy}`)
			.pipe(tap(resp => {
			}, errorResponse => {
                this.notificationService.warning({ title: 'Сервис OSM', message: errorResponse.errorDescription });
			}))
            .map(m => { return m });
    }

    public linkServiceCenterRegions(serviceCenterIntegraCode: string, request: LinkServiceCenterRegionsRequest): Observable<ServiceCenterResponse> {
        return this.http.post<ServiceCenterResponse>(`${this.baseUrl()}/${serviceCenterIntegraCode}`, request)
        .pipe(tap(resp => {
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис OSM', message: errorResponse.errorDescription });
        }))
        .map(m => { return m });
    }

    public getServiceCenterRegions(serviceCenterIntegraCode: string): Observable<ServiceCenterResponse> {
        return this.http.get<ServiceCenterResponse>(`${this.baseUrl()}/${serviceCenterIntegraCode}`)
        .pipe(tap(resp => {
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис OSM', message: errorResponse.errorDescription });
        }))
        .map(m => { return m });;
    }

    public deleteServiceCenterRegion(serviceCenterRegionId: number): Observable<ServiceCenterResponse> {
        return this.http.delete<ServiceCenterResponse>(`${this.baseUrl()}/${serviceCenterRegionId}`)
        .pipe(tap(resp => {
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис OSM', message: errorResponse.errorDescription });
        }));
    }

    public deleteAllServiceCenterRegions(serviceCenterIntegraCode: string): Observable<ServiceCenterResponse> {
        return this.http.delete<ServiceCenterResponse>(`${this.baseUrl()}/all/${serviceCenterIntegraCode}`)
        .pipe(tap(resp => {
        }, errorResponse => {
            this.notificationService.warning({ title: 'Сервис OSM', message: errorResponse.errorDescription });
        }));
    }
}