import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ActionDataResult } from "../../shared/models/core/ActionDataResult";
import { KeyValueObject } from "../../shared/models/core/KeyValueObject";
import { NotificationType } from "./notification-type";
import { NotificationService } from "./notification.service";
import { tap, filter, take } from 'rxjs/operators';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

export class BaseLookupService {

    protected controllerVersion = 1;
    protected unauthorizedCode: number;

    constructor(
        public http: HttpClient,
        public notificationService: NotificationService
    ) { }

    protected getBaseUrl(controllerName: string): string {
        return `${environment.apiUrl}v${this.controllerVersion}/${controllerName}`;
    }

    public getDataByUrl(url: string, options: { notificationType?: NotificationType, isError?: boolean } = { notificationType: NotificationType.SweetAlert, isError: true }): Observable<KeyValueObject[]> {
        return this.http.get<ActionDataResult<KeyValueObject[]>>(url)
            .pipe(tap(resp => {

                if (!resp.isSuccessful) {
                    this.initNotification(resp.errorDescription, options);
                }

            }, errorResponse => {

                if (errorResponse.status === this.unauthorizedCode)
                    return;

                this.initNotification(errorResponse.error.errorDescription, options);

            }))
            .pipe(take(1))
            .pipe(filter(x => x.isSuccessful))
            .map(x => x.data)
            ;
    }

    protected getDataByUrlExtBase(url: string, options: { notificationType?: NotificationType, isError?: boolean } = { notificationType: NotificationType.SweetAlert, isError: true }): Observable<KeyValueObject[]> {
        return this.http.get<ActionDataResult<KeyValueObject[]>>(url)
            .pipe(tap(_ => {

            }, errorResponse => {

                if (errorResponse.status === this.unauthorizedCode)
                    return;

                this.initNotification(errorResponse.error.errorDescription, options);

            }))
            .pipe(take(1))
            .map(x => x.data)
            ;
    }

    public getData(controllerName: string, state?: DataSourceRequestState,
        options: { notificationType?: NotificationType, isError?: boolean } = { notificationType: NotificationType.SweetAlert, isError: true }): Observable<KeyValueObject[]> {
        return this.getDataByUrl(`${this.getBaseUrl(controllerName)}${this.getRequestQueryString(state)}`, options);
    }

    protected initNotification(message: string, options: { notificationType?: NotificationType, isError?: boolean }) {
        if (options.isError)
            this.notificationService.error({
                title: 'Ошибка',
                message: message,
                notificationType: options.notificationType
            });
        else
            this.notificationService.warning({
                title: '',
                message: message,
                notificationType: options.notificationType
            });
    }

    protected getRequestQueryString(state: DataSourceRequestState, symbol: string = "?"): string {
        return state ? `${symbol}${toDataSourceRequestString(state)}` : '';
    }
}