import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Attachment} from '../../models/attachment';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnChanges {
	@Input() public attachments: Attachment[] = [];
	@Input() public currentAttachmentId: number;

	@Input() public width = '100%';
	@Input() public height = '600px';

	public activeIndex = 0;

	public items: Attachment[] = [];

	constructor() { }

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.attachments && changes.attachments.currentValue) {
			this.items = changes.attachments.currentValue;
		}
		if (changes.currentAttachmentId && changes.currentAttachmentId.currentValue) {
			this.activeIndex = this.items.findIndex(x => x.attachmentId === changes.currentAttachmentId.currentValue);
		}
	}
}
