import { BaseObject } from "../core/BaseObject";

export class AgreementListItem extends BaseObject {
    agreementId: number;
    agreementDate: Date;
		agreementDateTime;
    endDate?: Date;
		endDateTime?: Date;
		createdDateTime: Date;
		number: string;
		integraCode: string;
		possibleProlongation: boolean;
		description: string;

		agreementTypeId: number;
		agreementTypeName: string;

		contragentId: number;
		contragentName: string;

		stateId: number;
		stateName: string;

		executorId: number;
		executorName: string;

		slaCustomerDate: boolean;
		slaByFactEndDate: boolean;

		parentAgreementId?: number = null;
}
