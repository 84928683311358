import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { NotificationService } from '../../../core/services/notification.service';
import { EntityViewModel } from '../../models/core/EntityViewModel';
import { DataService } from '../../../core/services/data.service';
import { ViewChild, DebugElement } from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, NgForm} from '@angular/forms';
import { TrackChangesComponent } from '../../../core/interfaces/component-can-deactivate';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

export class ComponentPageBase<T> implements TrackChangesComponent {

	@ViewChild('entityForm') public entityForm: NgForm;

	hasChanges(): boolean {
		return !!this.entityForm && this._countControls(this.entityForm.form) > 1 && !this.entityForm.pristine;
	}

	beforeSaveChanges() {

	}

	afterSaveChanges() {
		if (this.isNew) {
			this.router.navigate([this.listPageRoutePath]);
		}
	}

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	public entityViewModel: EntityViewModel<T>;

	private _entityId: string;

	protected get entityId(): string {
        return this._entityId;
    }

	protected set entityId(newEntityId: string) {
		this._entityId = newEntityId;

		if (!!newEntityId) {
			this.entityIdConst = newEntityId;
		}
    }

	protected entityIdConst: string;
	protected entityName: string;

	protected listPageRoutePath: string;

	protected dataSaving: boolean = false;
	public canEditForm: boolean;
	public isNew: boolean;

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: DataService<any, T>
	) { }

	saveChanges(name: string = this.entityName): void {
		this.dataSaving = true;

		this.beforeSaveChanges();
		
		const action = this.isNew
			? this.dataService.create(this.entityViewModel.entity)
			: this.dataService.update(this.entityViewModel.entity, this.entityName);

		action.subscribe(res => {
			this.dataSaving = false;

			if (!res.isSuccessful)
				return;

			this.appService.MarkFormPristine(this.entityForm);
			this.canEditForm = false;
			this.entityId = res.data;
			this.afterSaveChanges();

		}, x => this.dataSaving = false);
	}

	cancelEdit(): void {
		this.canEditForm = false;
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}
	
	askRemove(successMessageText: string) {

		this.notificationService.confirmation({
			title: this.entityName,
			message: 'Вы уверены что хотите удалить элемент?',
			type: 'question',
			confirmButtonText: 'Да',
			cancelButtonText: 'Нет',
			showCloseButton: true
		}, () => {

			let eid: string = this.entityId;

			if (!!!eid) {
				eid = this.entityIdConst;
			}

			this.dataService.remove(eid, this.entityName, successMessageText).subscribe(() => {
				this.router.navigate([this.listPageRoutePath]);
			});

		});
	}

	initializeNewEntity(entity = this.entityViewModel.entity) {
		this.canEditForm = true;
		this.isNew = true;
	}

	dataBind(userViewModel: EntityViewModel<any>) {
		this.entityViewModel = userViewModel;
		if (this.entityViewModel) {
			this.appService.pageTitle = this.entityName;
		}
	}

	toggleFormEdit(): void {
		this.canEditForm = !this.canEditForm;
	}

	public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

	private _countControls(control: AbstractControl): number  {
		if (control instanceof FormControl) {
			return 1;
		}

		if (control instanceof FormArray) {
			return control.controls.reduce((acc, curr) => acc + this._countControls(curr), 1);
		}

		if (control instanceof FormGroup) {
			return Object.keys(control.controls)
				.map(key => control.controls[key])
				.reduce((acc, curr) => acc + this._countControls(curr), 1);
		}
	}
}
