import { Component, OnInit, ViewChild, Input, EventEmitter, Output, Injector, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';
import { AppService } from '../../../../app.service';
import { EntityViewModel } from '../../../../shared/models/core/EntityViewModel';
import { ComponentPageBase } from '../../../../shared/components/component-page-base/component-page-base';
import { LookupService } from '../../../../shared/services/lookup.service';
import { KeyValueObject } from '../../../../shared/models/core/KeyValueObject';
import { CustomerObject } from '../../../../shared/models/customerObject/CustomerObject';
import { CustomerObjectsService } from '../../customer-objects.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ListViewModel } from '../../../../shared/models/core/ListViewModel';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { DaDataAddressComponent } from '../../../../shared/components/dadata-address/dadata-address.component';
import { CustomerObjectWorkingHoursComponent } from '../../../../shared/components/customer-object/customer-object-working-hours/customer-object-working-hours.component';
import { AddressSuggestion } from '../../../../shared/models/dadata-address/address-suggestion';
import { AddressService } from '../../../../shared/services/address.service';

const flatten = filter => {
	const filters = filter.filters;
	if (filters) {
		return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
	}
	return [];
};

export class ServiceCentersList extends KeyValueObject {

	constructor(id: number, name: string, isSelected: boolean, isDefault: boolean) {
		super(id, name);
		this.isSelected = isSelected;
		this.isDefault = isDefault;
	}

	isSelected: boolean;
	isDefault: boolean;
}


@Component({
	selector: 'categories-customer-object-form',
	templateUrl: './customer-object.form.html',
	styleUrls: [
		'./customer-object.form.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class CustomerObjectForm extends ComponentPageBase<CustomerObject> implements OnInit {

	@ViewChild("daDataAddressControl") public daDataAddressControl: DaDataAddressComponent;
	@ViewChild("customerObjectWorkingHoursControl") public customerObjectWorkingHoursControl: CustomerObjectWorkingHoursComponent;

	@Input() header: string;
	@Input() showCancelButton: boolean;
	@Input() merchantId: number;
	@Output() afterSaveChangesEvent = new EventEmitter<KeyValueObject>();
	@Output() afterCancelEvent = new EventEmitter();

	parentCustomerObjects: KeyValueObject[] = [];
	parentCustomerObjectsDefaultItem: KeyValueObject;
	serviceCenterDefaultItem: KeyValueObject;

	serviceCenters: ListViewModel<ServiceCentersList> = new ListViewModel<ServiceCentersList>();
	serviceCentersDropdownItems: KeyValueObject[] = [];

	public state: DataSourceRequestState = {
		skip: 0,
		take: 100,
		sort: [{ field: 'createdDate', dir: 'desc' }],
		filter: { logic: 'and', filters: [] }
	};

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: CustomerObjectsService,
		private lookupService: LookupService,
		private addressService: AddressService,
		private injector: Injector
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/customer-objects';
	}


	ngOnInit() {

		this.entityId = this.route.snapshot.paramMap.get('customerObjectId');

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId).subscribe(vm => {
				this.entityName = `${vm.entity.name}`;
				this.dataBind(vm);
				this.initLookups();

				setTimeout(() => {
					if (this.daDataAddressControl) {
						this.daDataAddressControl.initValue(this.entityViewModel.entity.address);
					}
				}, 1000);

			});

		} else {

			this.entityViewModel = new EntityViewModel<CustomerObject>();
			this.entityViewModel.entity = new CustomerObject();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;
			this.initLookups();
		}
	}

	initLookups() {
		this.lookupService.getData("customer-objects", null).subscribe(data => this.parentCustomerObjects = data);
		this.parentCustomerObjectsDefaultItem = new KeyValueObject(null, "(нет)");
		this.serviceCenterDefaultItem = new KeyValueObject(null, "(нет)");

		const scState: DataSourceRequestState = {
			filter: { logic: 'and', filters: [
				{ field: 'IsActual', operator: 'eq', value: true },
			] }
		};

		this.lookupService.getData("service-centers", scState).subscribe(data => {
			if (!!this.entityViewModel 
				&&!!this.entityViewModel.entity
				&&!!this.entityViewModel.entity.serviceCenterId
				&& !data.some(s => s.id === this.entityViewModel.entity.serviceCenterId)) {

				const scState2: DataSourceRequestState = {
					filter: {
						logic: 'and', filters: [
							{ field: 'ServiceCenterId', operator: 'eq', value: this.entityViewModel.entity.serviceCenterId },
						]
					}
				};

				this.lookupService.getData("service-centers", scState2).subscribe(data2 => {
					let disElem = data2.find(f => f.id === this.entityViewModel.entity.serviceCenterId);

					if (!!disElem) {
						disElem.disabled = true;
						var scList = data.map(x => new ServiceCentersList(x.id, x.name, false, false));
						this.serviceCenters.data = scList;
						this.serviceCenters.total = data.length;
			
						this.serviceCentersDropdownItems = data;
						this.serviceCentersDropdownItems.push(disElem);
					}
				});
			} else {
				var scList = data.map(x => new ServiceCentersList(x.id, x.name, false, false));
				this.serviceCenters.data = scList;
				this.serviceCenters.total = data.length;
	
				this.serviceCentersDropdownItems = data;
			}
		});
	}

	itemDisabledSc(itemArgs: { dataItem: KeyValueObject; index: number }) {
		return itemArgs.dataItem.disabled;
	}

	onAddressChange(value: AddressSuggestion) {
		this.entityViewModel.entity.address = value.address;
		this.entityViewModel.entity.latitude = value.latitude;
		this.entityViewModel.entity.longitude = value.longitude
		if (!!value){
			this.appService.MarkFormDirty(this.entityForm);
		} else {
			this.appService.MarkFormPristine(this.entityForm);
		}
		
	}

	get isAddressValid() : boolean {
		if (!!this.daDataAddressControl)
		{
			return this.daDataAddressControl.isValid();
		} else {
			return true;
		}
	}

	closeDialog() {

		if (!this.showCancelButton)
			return;

		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}


	saveChanges(name: string = this.entityName): void {
		let beforeSaveChangesLogic = () => {
			this.entityViewModel.entity.workingHours = this.customerObjectWorkingHoursControl.workingHours;
			if (this.isNew) {
				this.entityViewModel.entity.merchantId = this.merchantId;
				this.entityViewModel.entity.workingHours.filter(v => !v.isClosed).forEach(v => {
					if (v.startHour && v.startHour.indexOf(':') < 0) {
						v.startHour += ':00';
					}
					if (v.endHour && v.endHour.indexOf(':') < 0) {
						v.endHour += ':00';
					}
					if (v.lunchTimeStart && v.lunchTimeStart.indexOf(':') < 0) {
						v.lunchTimeStart += ':00';
					}
					if (v.lunchTimeEnd && v.lunchTimeEnd.indexOf(':') < 0) {
						v.lunchTimeEnd += ':00';
					}
				});
			}
		};

		this.addressService.addAddressOrCoordinatesByName(this.entityViewModel.entity.address,
			null,
			this.entityViewModel.entity.latitude,
			this.entityViewModel.entity.longitude,
			"Поиск адреса при создании/изменении объекта ТСП через раздел Объекты обслуживания")
			.subscribe(addressRes => {
				if (addressRes.isSuccessful) {
					if (!!addressRes.data) {
						beforeSaveChangesLogic();
						super.saveChanges();
					} else {
						this.notificationService.confirmation({
							title: this.entityName,
							message: `Указанный адрес ТСП не распознан.
							Невозможно определить регион, исполнителя, вычислить удаленность.
							Заявка с таким ТСП не сможет быть назначена на инженера в автоматическом режиме`,
							type: 'question',
							confirmButtonText: 'Сохранить',
							cancelButtonText: 'Отмена',
							showCloseButton: false,
						}, () => {
							this.entityViewModel.entity.parseAddressDadata = false;
							beforeSaveChangesLogic();
							super.saveChanges();
						}, () => {
							this.dataSaving = false
						});
					}
				} else {
					this.notificationService.confirmation({
						title: this.entityName,
						message: addressRes.errorDescription,
						type: 'question',
						confirmButtonText: 'Сохранить',
						cancelButtonText: 'Отмена',
						showCloseButton: false,
					}, () => {
						this.entityViewModel.entity.parseAddressDadata = false;
						beforeSaveChangesLogic();
						super.saveChanges();
					}, () => {
						this.dataSaving = false
					});
				}
			}, errorResponse => {
				this.notificationService.confirmation({
					title: this.entityName,
					message: errorResponse.errorDescription,
					type: 'question',
					confirmButtonText: 'Сохранить',
					cancelButtonText: 'Отмена',
					showCloseButton: false,
				}, () => {
					this.entityViewModel.entity.parseAddressDadata = false;
					beforeSaveChangesLogic();
					super.saveChanges();
				}, () => {
					this.dataSaving = false
				});
			});
	}

	afterSaveChanges() {
		const val = new KeyValueObject(Number(this.entityId), this.entityViewModel.entity.name);

		const callAfterSaveChangesEvent = () => {
			if (this.afterSaveChangesEvent) {
				this.afterSaveChangesEvent.emit(val);
				this.closeDialog();
			}
		}

		if (!this.isNew) {
			this.dataService.getById(this.entityViewModel.entity.customerObjectId).subscribe(vm => {
				this.entityName = `${vm.entity.name}`;
				this.dataBind(vm);

				callAfterSaveChangesEvent();
			});

		}
		else {
			callAfterSaveChangesEvent();
		}
		
	}

	afterCancel() {
		this.afterCancelEvent.emit();
		this.closeDialog();
	}
}
