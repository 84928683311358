export class DismantledSeriesSerialPartNumbersData {

    serialNumber: string;
    partNumber: string;
    nomenclature: string;

    analyzeSuccessful: boolean = true;
    serialNumberRecognized: boolean = true;
	serialNumberAvailable: boolean = true;
	partNumberRecognized: boolean = true;
	partNumberAvailable: boolean = true;
    requestDeviceAttachmentCorrect: boolean;
}