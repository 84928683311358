import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestRegistrationFilterComponentTypeEnum } from '../../enums/request-registration-filter-component-type.enum';
import { RequestRegistrationFilterElementOperatorEnum } from '../../enums/request-registration-filter-element-operator.enum';
import { RequestRegistrationFilterElementTypeEnum } from '../../enums/request-registration-filter-element-type.enum';
import { KeyValueObject } from '../../models/core/KeyValueObject';
import { RequestRegistrationFilterElementDto } from '../../models/request-registration-filter/request-registration-filter-element-dto';
import { RequestRegistrationFilterElementValueDto } from '../../models/request-registration-filter/request-registration-filter-element-value-dto';
import { LookupService } from '../../services/lookup.service';
import { RegionsService } from '../../services/regions.service';
import { RequestRegistrationFiltersService } from '../../services/request-registration-filters.service';
import { ServiceCentersService } from '../../services/service-centers.service';
import { WarehousesService } from '../../services/warehouses.service';

@Component({
	selector: 'request-registration-filter',
	templateUrl: './request-registration-filter.component.html',
	styleUrls: [
		'./request-registration-filter.component.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class RequestRegistrationFilterComponent implements OnInit {
	@Input()
	filterElement: RequestRegistrationFilterElementDto;

	@Input()
	selectedFilterElements: RequestRegistrationFilterElementDto[] = [];

	@Input()
	canEdit: boolean = true;

	@Output()
	removeAction: EventEmitter<number> = new EventEmitter();

	@Output()
	addDisableAction: EventEmitter<boolean> = new EventEmitter();

	@Output()
	markDirtyAction: EventEmitter<void> = new EventEmitter();

	filterComponentTypeId: number = 0;

	intArrayData: KeyValueObject[] = [];
	stringArrayData: KeyValueObject[] = [];
	operatorTypes: KeyValueObject[] = [];
	operatorTypesFull: KeyValueObject[] = [];
	elementTypesFull: KeyValueObject[] = [];

	intArrayIds: number[] = [];
	stringArrayComplex: KeyValueObject[] = [];
	decimalValue: number;
	elementTypesCountFull: number = 0;

	arrayOperators: number[] = [
		<number>RequestRegistrationFilterElementOperatorEnum.IncludedIn,
		<number>RequestRegistrationFilterElementOperatorEnum.NotIncludedIn,
	];

	singleOperators: number[] = [
		<number>RequestRegistrationFilterElementOperatorEnum.Equal,
		<number>RequestRegistrationFilterElementOperatorEnum.Less,
		<number>RequestRegistrationFilterElementOperatorEnum.LessOrEqual,
		<number>RequestRegistrationFilterElementOperatorEnum.More,
		<number>RequestRegistrationFilterElementOperatorEnum.MoreOrEqual,
		<number>RequestRegistrationFilterElementOperatorEnum.NotEqual,
	];

	excludedRequestRegistrationFilterElementTypes: number[] = [
		RequestRegistrationFilterElementTypeEnum.warehouseRegion,
	];

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	constructor(
		private filterService: RequestRegistrationFiltersService,
		private lookupService: LookupService,
		private warehousesService: WarehousesService,
		private regionsService: RegionsService,
		private serviceCentersService: ServiceCentersService
	) {

	}

	ngOnInit(): void {
		this.loadData();

	}

	validateInt(evt) {
		var theEvent = evt || window.event;

		// Handle paste
		if (theEvent.type === 'paste') {
			key = theEvent.clipboardData.getData('text/plain');
		} else {
			// Handle key press
			var key = theEvent.keyCode || theEvent.which;
			key = String.fromCharCode(key);
		}
		var regex = /[0-9]/;
		if (!regex.test(key)) {
			theEvent.returnValue = false;
			if (theEvent.preventDefault) theEvent.preventDefault();
		}
	}

	get isExcludedFilterElementType(): boolean {
		return this.excludedRequestRegistrationFilterElementTypes.includes(this.filterElement.requestRegistrationFilterElementTypeId);
	}

	private loadData(): void {
		this.filterService.operatorTypesLookup().subscribe(data => {
			this.operatorTypesFull = data;

			this.filterService.elementTypesLookup().subscribe(data => {
				let selectedelementTypes = this.selectedFilterElements.map(m => m.requestRegistrationFilterElementTypeId);
				selectedelementTypes = selectedelementTypes.filter(f => f !== this.filterElement.requestRegistrationFilterElementTypeId);
				this.elementTypesFull = data;
				this.elementTypesCountFull = data.length;

				if (this.isExcludedFilterElementType) {
					this.filterElement.elementTypes = data.filter(f => !selectedelementTypes.includes(f.id));
				} else {
					this.filterElement.elementTypes = data.filter(f => !selectedelementTypes.includes(f.id))
						.filter(f => !this.excludedRequestRegistrationFilterElementTypes.includes(f.id));
				}

				this.setMode(this.filterElement.requestRegistrationFilterElementTypeId);
				this.addDisableAction.emit(this.selectedFilterElements.length >= this.elementTypesCountFull);
			});
		});

		if (this.filterElement.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.warehouseId) {
			this.warehousesService.warehousesPartnerCodesLookup(null, null).subscribe(data => {
				this.stringArrayData = this.addNullNotNull(data);
				this.stringArrayComplex = this.filterElement.requestRegistrationFilterElementValues
					.map(m => {
						let item = this.stringArrayData.find(v => v.integraCode === m.value);
						if (!item) {
							item = new KeyValueObject(null, m.value);
							item.integraCode = m.value;
							this.stringArrayData.push(item);
						}
						return item;
					});
			});
		} else {
			this.intArrayIds = this.filterElement.requestRegistrationFilterElementValues
				.map(m => Number.parseInt(m.value));
		}

		if (this.filterElement.requestRegistrationFilterElementValues.length > 0) {
			this.decimalValue = parseFloat(this.filterElement.requestRegistrationFilterElementValues[0].value);
		}
	}

	private setMode(modeId: number): void {
		switch (modeId) {
			case <number>RequestRegistrationFilterElementTypeEnum.warehouseRegion:
				this.operatorTypes = this.operatorTypesFull.filter(f => this.arrayOperators.includes(f.id));
				//this.warehousesService.warehousesRegionsLookup().subscribe(data => this.intArrayData = data);
				this.regionsService.regionsSingleLookup().subscribe(data => this.intArrayData = data);
				this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.intArray;
				break;
			case <number>RequestRegistrationFilterElementTypeEnum.serviceCenterCity:
				this.operatorTypes = this.operatorTypesFull.filter(f => this.arrayOperators.includes(f.id));
				this.serviceCentersService.serviceCentersCitiesLookup().subscribe(data => this.intArrayData = data);
				this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.intArray;
				break;
			case <number>RequestRegistrationFilterElementTypeEnum.requestType:
				this.operatorTypes = this.operatorTypesFull.filter(f => this.arrayOperators.includes(f.id));
				this.lookupService.getData("request-types", null).subscribe(data => this.intArrayData = data);
				this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.intArray;
				break;
			case <number>RequestRegistrationFilterElementTypeEnum.requestService:
				this.operatorTypes = this.operatorTypesFull.filter(f => this.arrayOperators.includes(f.id));
				this.lookupService.getData("services", null).subscribe(data => this.intArrayData = this.addNullNotNull(data));
				this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.intArray;
				break;
			case <number>RequestRegistrationFilterElementTypeEnum.warehouseId:
				{
					this.operatorTypes = this.operatorTypesFull.filter(f => this.arrayOperators.includes(f.id));
					let cityIds: number[] = [];
					let regionIds: number[] = [];
					const cityFilter = this.selectedFilterElements.find(v => v.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.merchantCity);
					if (cityFilter && cityFilter.requestRegistrationFilterElementValues.length > 0) {
						cityIds = cityFilter.requestRegistrationFilterElementValues.map(v => parseInt(v.value));
					}
					const regionFilter = this.selectedFilterElements.find(v => v.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.requestRegion);
					if (regionFilter && regionFilter.requestRegistrationFilterElementValues.length > 0) {
						regionIds = regionFilter.requestRegistrationFilterElementValues.map(v => parseInt(v.value));
					}
					this.warehousesService.warehousesPartnerCodesLookup(cityIds, regionIds).subscribe(data => this.stringArrayData = this.addNullNotNull(data));
					this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.stringArray;
				}
				break;
			case <number>RequestRegistrationFilterElementTypeEnum.distanceBetweenObjectsAndWarehouse:
			case <number>RequestRegistrationFilterElementTypeEnum.requestInitialCost:
			case <number>RequestRegistrationFilterElementTypeEnum.currentPrice:
				this.operatorTypes = this.operatorTypesFull.filter(f => this.singleOperators.includes(f.id));
				this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.decimal;
				break;
			case <number>RequestRegistrationFilterElementTypeEnum.timeOnExchangeAfterRequestCanBeTakenSec:
			case <number>RequestRegistrationFilterElementTypeEnum.currentPriceRaizeCounter:
				this.operatorTypes = this.operatorTypesFull.filter(f => this.singleOperators.includes(f.id));
				this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.int;
				break;
			case <number>RequestRegistrationFilterElementTypeEnum.requestRegion:
				this.operatorTypes = this.operatorTypesFull.filter(f => this.arrayOperators.includes(f.id));
				let cityIds: number[] = [];
				let warehousePartnerCodes: string[] = [];
				const cityFilter = this.selectedFilterElements.find(v => v.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.merchantCity);
				if (cityFilter && cityFilter.requestRegistrationFilterElementValues.length > 0) {
					cityIds = cityFilter.requestRegistrationFilterElementValues.map(v => parseInt(v.value));
				} else {
					const warehouseFilter = this.selectedFilterElements.find(v => v.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.warehouseId);
					if (warehouseFilter && warehouseFilter.requestRegistrationFilterElementValues.length > 0) {
						warehousePartnerCodes = warehouseFilter.requestRegistrationFilterElementValues.map(v => v.value);
					}
				}
				this.regionsService.regionsSingleLookup(cityIds, warehousePartnerCodes).subscribe(data => this.intArrayData = data);
				this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.intArray;
				break;
			case <number>RequestRegistrationFilterElementTypeEnum.merchantCity:
				this.operatorTypes = this.operatorTypesFull.filter(f => this.arrayOperators.includes(f.id));
				const warehouseFilter = this.selectedFilterElements.find(v => v.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.warehouseId);
				if (warehouseFilter && warehouseFilter.requestRegistrationFilterElementValues.length > 0) {
					const warehouseCodes = warehouseFilter.requestRegistrationFilterElementValues.map(v => v.value);
					this.warehousesService.warehousesCitiesLookup(warehouseCodes).subscribe(data => this.intArrayData = data);
				} else {
					const regionFilter = this.selectedFilterElements.find(v => v.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.requestRegion);
					if (regionFilter && regionFilter.requestRegistrationFilterElementValues.length > 0) {
						const state: DataSourceRequestState = {
							filter: {
								logic: 'and',
								filters: [
									{ field: 'regionsFilter', operator: 'contains', value: regionFilter.requestRegistrationFilterElementValues.map(v => parseInt(v.value)) }
								]
							}
						}
						this.lookupService.getData("cities", state).subscribe(data => this.intArrayData = data);
					} else {
						this.lookupService.getData("cities", {}).subscribe(data => this.intArrayData = data);
					}
				}
				this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.intArray;
				break;
			case <number>RequestRegistrationFilterElementTypeEnum.warehouseOwner:
				this.operatorTypes = this.operatorTypesFull.filter(f => this.arrayOperators.includes(f.id));
				this.warehousesService.warehousesOwnersLookup().subscribe(data => this.intArrayData = data);
				this.filterComponentTypeId = <number>RequestRegistrationFilterComponentTypeEnum.intArray;
				break;
			default:
				break;
		}
	}

	private addNullNotNull(source: KeyValueObject[]): KeyValueObject[] {
		const nulls = [
			new KeyValueObject(-2, '(Пусто)'),
			new KeyValueObject(-1, '(Не пусто)')
		];
		nulls[0].integraCode = '-2';
		nulls[1].integraCode = '-1';
		return nulls.concat(source);
	}

	onMultiselectOpen(e: any) {
		if (this.filterElement.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.warehouseId
			|| this.filterElement.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.merchantCity
			|| this.filterElement.requestRegistrationFilterElementTypeId === <number>RequestRegistrationFilterElementTypeEnum.requestRegion) {
			this.setMode(this.filterElement.requestRegistrationFilterElementTypeId);
		}
	}

	elementTypeValueChange(value: number): void {
		this.setMode(value);
		this.decimalValue = null;
		this.intArrayIds = [];
		this.stringArrayComplex = [];
		this.filterElement.requestRegistrationFilterElementOperatorId = null;
		this.addDisableAction.emit(this.selectedFilterElements.length >= this.elementTypesCountFull);
		this.markDirtyAction.emit();
		this.filtElementTypes();
	}

	private filtElementTypes() {
		this.selectedFilterElements.forEach(f => {
			let selectedelementTypes = this.selectedFilterElements.map(m => m.requestRegistrationFilterElementTypeId);
			selectedelementTypes = selectedelementTypes.filter(flt => flt !== f.requestRegistrationFilterElementTypeId);
			if (this.isExcludedFilterElementType) {
				f.elementTypes = this.elementTypesFull.filter(f => !selectedelementTypes.includes(f.id));
			} else {
				f.elementTypes = this.elementTypesFull.filter(f => !selectedelementTypes.includes(f.id))
					.filter(f => !this.excludedRequestRegistrationFilterElementTypes.includes(f.id));
			}
		});
	}

	removeElement() {
		this.removeAction.emit(this.filterElement.requestRegistrationFilterElementId);
		this.addDisableAction.emit(this.selectedFilterElements.length >= this.elementTypesCountFull);
		this.filtElementTypes();
		this.markDirtyAction.emit();
	}

	intArrayChange(ids: number[]): void {
		ids.forEach((f, i) => {
			if (i < this.filterElement.requestRegistrationFilterElementValues.length) {
				this.filterElement.requestRegistrationFilterElementValues[i].value = f.toString();
			} else {
				this.filterElement.requestRegistrationFilterElementValues.push(
					new RequestRegistrationFilterElementValueDto(this.filterElement.requestRegistrationFilterElementId, f.toString())
				);
			}
		});

		let range = this.filterElement.requestRegistrationFilterElementValues.length - ids.length;
		if (range > 0) {
			this.filterElement.requestRegistrationFilterElementValues.splice(ids.length, range);
		}

		// this.filterElement.requestRegistrationFilterElementValues = ids
		// 	.map(m => new RequestRegistrationFilterElementValueDto(m.toString()));
		this.addDisableAction.emit(this.selectedFilterElements.length >= this.elementTypesCountFull);
		this.markDirtyAction.emit();
	}

	stringArrayComplexChange(ids: KeyValueObject[]): void {
		ids.forEach((f, i) => {
			if (i < this.filterElement.requestRegistrationFilterElementValues.length) {
				this.filterElement.requestRegistrationFilterElementValues[i].value = f.integraCode;
			} else {
				this.filterElement.requestRegistrationFilterElementValues.push(
					new RequestRegistrationFilterElementValueDto(this.filterElement.requestRegistrationFilterElementId, f.integraCode)
				);
			}
		});

		let range = this.filterElement.requestRegistrationFilterElementValues.length - ids.length;
		if (range > 0) {
			this.filterElement.requestRegistrationFilterElementValues.splice(ids.length, range);
		}

		// this.filterElement.requestRegistrationFilterElementValues = ids
		// 	.map(m => new RequestRegistrationFilterElementValueDto(m.toString()));
		this.addDisableAction.emit(this.selectedFilterElements.length >= this.elementTypesCountFull);
		this.markDirtyAction.emit();
	}

	public stringToKeyValueObject = (text$: Observable<string>) =>
		text$.pipe(
			map((text: string) => {
				let item = this.stringArrayData.find(v => v.name.toLowerCase() === text.toLowerCase());
				if (!item) {
					item = new KeyValueObject(null, text);
					item.integraCode = text;
				}
				return item;
			})
		);

	decimalValueChange(val: number): void {
		let requestRegistrationFilterElementValueDto: RequestRegistrationFilterElementValueDto = new RequestRegistrationFilterElementValueDto(this.filterElement.requestRegistrationFilterElementId, val.toString());
		this.filterElement.requestRegistrationFilterElementValues = [requestRegistrationFilterElementValueDto];
		this.addDisableAction.emit(this.selectedFilterElements.length >= this.elementTypesCountFull);
		this.markDirtyAction.emit();
	}

	operatorValueChange(value: number): void {
		this.addDisableAction.emit(this.selectedFilterElements.length >= this.elementTypesCountFull);
		this.markDirtyAction.emit();
	}
}
