import { RequestTid } from './../models/tid/request-tid';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { Tid } from '../models/tid/tid';
import { Observable } from 'rxjs';
import { KeyValueObject } from '../models/core/KeyValueObject';
import { environment } from '../../../environments/environment';
import { UploadFile } from '../models/attachment';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { TidImportModel } from '../models/tid/tid-import-model';
import { TidExampleFileDataDto } from '../models/tid/tid-example-file-data.dto';
import { tap } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { TidServiceRequestHistoryDto } from '../models/tid/tid-service-request-history.dto';
import { TidDevice } from '../models/request/new-request/tid-device';
import { TidDevicesDto } from '../models/tid/TidDevicesDto';
import { TmsTerminalExistInDatabaseDto } from '../models/tid/TmsTerminalExistInDatabaseDto';

@Injectable()
export class TidsService extends DataService<Tid, Tid> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'tids';
	}

	public getCheckTidExists(tidNumber: string, tidId: number, acquirerId: number, agreementId: number = null): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${tidNumber}/check-exists?tidId=${tidId}&acquirerId=${acquirerId}&agreementId=${agreementId}`);
	}

	public getTidByTidNumber(tidNumber: string): Observable<Tid> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${tidNumber}/getTid`);
	}

	public getTidsByContragent(contragentId: number): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/lookup-by-contragent?contragentId=${contragentId}`);
	}

	importTids(TidImportModel: TidImportModel): Observable<ActionDataResult<UploadFile>> {
		return this.postQuery<UploadFile>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/import-tids`,
			TidImportModel,
			null,
			false
		);
	}

	public getServiceRequestHistory(tidId: string): Observable<TidServiceRequestHistoryDto> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${tidId}/service-request-history`);
	}

	public getTidInstalledDevices(tidNumber: string, acquirerId: number): Observable<TidDevice[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/tid-installed-devices?acquirerId=${acquirerId}&tidNumber=${tidNumber}`);
	}

	public getTidDevices(tidNumber: string): Observable<TidDevicesDto[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/tid-devices/${tidNumber}`);
	}
	public getTerminalsInDatabaseFromTms(tidNumber: string): Observable<TmsTerminalExistInDatabaseDto> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/terminalsFromTms/${tidNumber}`);
	}

	public downloadTidExportFileExample(): Observable<ActionDataResult<TidExampleFileDataDto>> {
		return this.http.get<ActionDataResult<TidExampleFileDataDto>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/download-tid-export-file-example`)
		.pipe(tap(resp => {

			if (!resp.isSuccessful) {
				this.notificationService.error({
					title: 'Ошибка при скачивании файла',
					message: resp.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}

		}, errorResponse => {

			if (errorResponse.status === 904)
				return;

			this.notificationService.error({
				title: 'Ошибка при скачивании файла',
				message: errorResponse.error.errorDescription,
				notificationType: NotificationType.SweetAlert
			});

		}));
	}
}
