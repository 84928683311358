import { Observable } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { Component } from "@angular/core";
import { CompleteActivityResultPropertiesData } from "./complete-activity-result-properties-data";
import { ActivitiesService } from "../../../../services/activities.service";
import { PopupSettings } from "@progress/kendo-angular-dropdowns";

@Component({
    selector: 'complete-activity-result-properties',
    templateUrl: './complete-activity-result-properties.component.html',
    styleUrls: ['./complete-activity-result-properties.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivityResultPropertiesComponent extends 
    BaseFlowComponent<CompleteActivityResultPropertiesData> {

    constructor(
        private activitiesService: ActivitiesService
    ) {
        super();
    }

    popupSettings: PopupSettings = {
		popupClass: 'custom-popup'
	};

    initData() {
        if (this.data.activityResults.length == 1) {
            this.data.activityResultId = this.data.activityResults[0].id;
        }
        if (this.data.completedDateTime == null) {
            this.data.completedDateTime = new Date();
        }
    }
    
    saveChanges(): Observable<CompleteActivityResultPropertiesData> {
        return this.activitiesService.update(this.data.activity, null, false, true);
    }

    get formCompleted(): boolean {
        return this.data.activityResultId && this.data.completedDateTime && 
            this.data.activity.finishActivityComment && this.data.activity.finishActivityComment.trim().length > 0;
    }
}