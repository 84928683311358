import { UploadFileData } from "../../../../models/attachment/upload-file-data";
import { KeyValueObject } from "../../../../models/core/KeyValueObject";
import { NewRequestDevice } from "../../../../models/request/new-request/new-request-device";

export class CompleteActivityDismantledSimcardData {

    mobileNomenclatures: KeyValueObject[] = [];
    requestId: number;
    activityId: number;

    fillSubsriberNumber: boolean = false;

    nomenclatureId: number;
    attachments: UploadFileData[] = [];
    requestUninstallDevice: NewRequestDevice = new NewRequestDevice;
}