import { Guid } from "guid-typescript";
import { UploadFileData } from "../../../../../models/attachment/upload-file-data";
import { NewRequestDevice } from "../../../../../models/request/new-request/new-request-device";

export class InstallFlowSimcardData {
    requestId: number;
    activityId: number;

    id: Guid = Guid.create();

    nomenclatureId: number;
    attachments: UploadFileData[] = [];
    requestDevices: NewRequestDevice[] = [];
}