import { Observable, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { CompleteActivitySummaryData } from "./complete-activity-summary-data";
import { Component } from "@angular/core";

@Component({
    selector: 'complete-activity-summary',
    templateUrl: './complete-activity-summary.component.html',
    styleUrls: ['./complete-activity-summary.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivitySummaryComponent extends BaseFlowComponent<CompleteActivitySummaryData> {

    constructor() {
        super();
    }

    initData() {
    }

    saveChanges(): Observable<any> {
        return of({});
    }
}