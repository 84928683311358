import { Pipe, PipeTransform } from '@angular/core';
import * as StringMask from 'string-mask';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {

	constructor(
	) { }

	transform(value: string): string {

		if (!value)
			return "";

		if (!value.startsWith("+"))
			return value;

		var numberPattern: RegExp = /\D+/g;

		value = `+${value.replace(numberPattern, '')}`;

		var formatter = new StringMask("+0 (000) 000 00 00");
		return formatter.apply(value.replace("+", ""));
	}
}