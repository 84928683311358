import { BaseObject } from "../core/BaseObject";
import { KeyValueObject } from "../core/KeyValueObject";
import { ContragentContact } from "./contragent-contact";
import { ContragentDetail } from "./contragent-detail";
import { AgreementListItem } from "../agreement/agreement-list-item";
import { ContragentSc } from "./contragent-sc";
import { ContragentProject } from "./contragent-project";

export class Contragent extends BaseObject {
	contragentId: number;
	name: string;
	address: string;
	projectId: number;
	projectName: number;
	requisites: string;
	integraCode: string;

	// Contragent details for main tab
	lastActiveContragentDetailId?: number;
	lastActiveContragentDetailInn: string;
	lastActiveContragentDetailKpp: string;
	lastActiveContragentDetailLegalAddress: string;
	lastActiveContragentDetailActualAddress: string;

	contragentTypeId: number;
	contragentTypeName: string;
	acquirerContragentId?: number;
	executorContragentId?: number;
	contragentDetails: ContragentDetail[] = [];
	agreements: AgreementListItem[] = [];
	projects: ContragentProject[] = [];
	serviceCenters: ContragentSc[] = [];
	contacts: ContragentContact[] = [];
  legalAddress: string;
  inn: string;
}
