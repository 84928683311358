import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { toDataSourceRequestString, DataSourceRequestState } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { ListViewModel } from '../../shared/models/core/ListViewModel';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import { EntityViewModel } from '../../shared/models/core/EntityViewModel';
import { tap, filter, take } from 'rxjs/operators';
import { NotificationType } from './notification-type';
import { NotificationService } from './notification.service';

@Injectable()
export class DataService<TListItem, TItem> {

	protected controllerName = '';
	protected controllerVersion = 1;

	constructor(protected http: HttpClient,
		protected notificationService: NotificationService) { }

	protected baseUrl(): string {
		return `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}`;
	}

	public getQuery<T>(url: string): Observable<T> {

		return this.http.get<ActionDataResult<T>>(url)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;
				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public getQueryWithParams<T>(url: string, params: HttpParams): Observable<T> {

		return this.http.get<ActionDataResult<T>>(url,{ params: params} )
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;
				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public postQuery<T>(url: string, body: any, successMessage: string = null, showNotificationOnError: boolean = true, notificationType: NotificationType = NotificationType.SweetAlert): Observable<ActionDataResult<T>> {

		return this.http.post<ActionDataResult<T>>(url, body)
			.pipe(tap(resp => {

				if (showNotificationOnError) {
					if (!resp.isSuccessful) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: resp.code === 965 ? NotificationType.SweetAlert : notificationType
						});
					} else {

						if (successMessage != null) {
							this.notificationService.success({
								title: '',
								message: successMessage,
								notificationType: NotificationType.Toast
							});
						}

					}
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при отправке комментария',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}))
			.pipe(take(1));

	}

	public putQuery<T>(url: string, body: any): Observable<ActionDataResult<T>> {

		return this.http.put<ActionDataResult<T>>(url, body)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка при отправке комментария',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при отправке комментария',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}))
			.pipe(take(1));

	}

	public list(state?: DataSourceRequestState): Observable<ListViewModel<TListItem>> {
		if (!state) {
			state = {
				skip: 0,
				take: null,
				filter: { logic: "and", filters: [] }
			}
		}
		var queryStr = `${toDataSourceRequestString(state)}`;

		//хак, это ошибка компонента грид
		queryStr = queryStr.replace(/T\d\d-00-0/g, 'T00-00-00');

		return this.http.get<ActionDataResult<ListViewModel<TListItem>>>(`${this.baseUrl()}?${queryStr}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;

	}

	public getById(id: number | string): Observable<EntityViewModel<TItem>> {
		return this.http.get<ActionDataResult<EntityViewModel<TItem>>>(`${this.baseUrl()}/${id}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;
				else if (errorResponse.status == 2) {
					var result = new EntityViewModel<TItem>();
					result.isNew = true;
					return Observable.of(result);
				}

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error ? errorResponse.error.errorDescription : errorResponse.message,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}

	public new(): Observable<EntityViewModel<TItem>> {
		return this.http.get<EntityViewModel<TItem>>(`${this.baseUrl()}/new`);
	}

	public create(obj: TItem, errorNotificationType: NotificationType = NotificationType.Toast, dontShowCustomError: boolean = false, dontShowCustomSuccess: boolean = false): Observable<any> {
		return this.http.post<ActionDataResult<number | string>>(this.baseUrl(), obj)
			.pipe(tap(resp => {

				if (resp.isSuccessful) {
					if (!dontShowCustomSuccess) {
						this.notificationService.success({
							title: 'Создание нового элемента',
							message: 'Элемент создан успешно!',
							notificationType: NotificationType.Toast
						});
					}
				}
				else {
					if (!dontShowCustomError) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: errorNotificationType
						});
					}
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при создании элемента!',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}))
			.pipe(take(1))
			//.pipe(filter(x => x.isSuccessful))
			//.map(x => x.data)
			;
	}

	public update(obj: TItem, successMessageTitle: string, dontShowCustomError: boolean = false, dontShowCustomSuccess: boolean = false): Observable<any> {
		return this.http.put<ActionDataResult<any>>(this.baseUrl(), obj)
			.pipe(tap(resp => {

				if (resp.isSuccessful) {
					if (!dontShowCustomSuccess) {
						this.notificationService.success({
							title: successMessageTitle,
							message: 'Изменения сохранены успешно!',
							notificationType: NotificationType.Toast
						});
					}
				}
				else {
					if (!dontShowCustomError) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.Toast
						});
					}
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка при сохранении изменений!',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}))
			.pipe(take(1))
			//.pipe(filter(x => x.isSuccessful))
			//.map(x => x.data)
			;
	}

	public remove(id: string, successMessageTitle: string, successMessageText: string, dontShowMessages: boolean = false): Observable<ActionDataResult<any>> {
		return this.http.delete<ActionDataResult<object>>(`${this.baseUrl()}/${id}`)
			.pipe(tap(resp => {

				if (!dontShowMessages) {
					if (resp.isSuccessful) {

						this.notificationService.success({
							title: successMessageTitle,
							message: successMessageText,
							notificationType: NotificationType.SweetAlert
						});
	
					} else {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1));

	}
}
