import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '../../../core/services/notification.service';
import { EngubatorRegion } from '../../models/engubator/engubator.region';
import { EngubatorDataService } from './engubator.data.service';

@Injectable()
export class EngubatorRegionsService extends EngubatorDataService<EngubatorRegion, EngubatorRegion>{
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'regions';
	}

	protected getNewTItem(): EngubatorRegion {
		return new EngubatorRegion();
	}
}