﻿export class ContragentDetail {
	contragentDetailId: number;
	contragentId: number;
	legalName: string;
	inn: string;
	kpp: string;
	bankBIK: string;
	paymentAccount: string;
	legalAddress: string;
	actualAdderss: string;
	dateStart?: Date;
	dateEnd?: Date;
	dateTimeStart?: Date;
	dateTimeEnd?: Date;

	mailAddress: string;
	bankName: string;
	corrAccount: string;
	ceoName: string;
	authorityBase: string;
	legalTypeId: number;
	legalTypeName: string;
	ogrn: string;
}
