import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator
} from '@angular/forms';

// libs
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

// services
import { TreeSourceService } from '../../services';
import { LookupService } from '../../../shared/services/lookup.service';

import { IItemFlatNode, IItemNode } from '../../interfaces';
import { MatAutocompleteTrigger, MatInput } from '@angular/material';
import { HierarchyLookupModel } from '../../../shared/models/core/HierarchyLookupModel';

import { DataSourceRequestState } from '@progress/kendo-data-query';

@Component({
	selector: 'app-tree-dropdown-list',
	templateUrl: './tree-dropdown-list.component.html',
	styleUrls: ['./tree-dropdown-list.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TreeDropdownListComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => TreeDropdownListComponent),
			multi: true,
		},
	]
})
export class TreeDropdownListComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
	/** Map from flat node to nested node. This helps us finding the nested node to be modified */
	flatNodeMap = new Map<IItemFlatNode, IItemNode>();

	/** Map from nested node to flattened node. This helps us to keep the same object for selection */
	nestedNodeMap = new Map<IItemNode, IItemFlatNode>();

	treeControl: FlatTreeControl<IItemFlatNode>;

	treeFlattener: MatTreeFlattener<IItemNode, IItemFlatNode>;

	dataSource: MatTreeFlatDataSource<IItemNode, IItemFlatNode>;

	/** The selection for checklist */
	checklistSelection = new SelectionModel<IItemFlatNode>(false);

	@Input() public isRequired: boolean;
	@Input() public isDisabled: boolean;
	@Input() public placeholder = 'Не выбрано';
	@Input() public sourceLookupUrl;
	@Input() public showOnlyIsActive: boolean = false;
	@Input() public label;
	@Input() public nullNodeName: string = null;

	@Input() public usePreloadedData: boolean = false;
	@Input() public preloadedData: HierarchyLookupModel[] = [];
	@Input() public requestId: number = null;

	@Output() public valueChange: EventEmitter<number> = new EventEmitter<number>();
	@Output() public onFocus: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('htmlInputElement') htmlInputElement: ElementRef<MatInput>;
	@ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

	private _value: any;
	private _destroy$ = new Subject<boolean>();

	_currentId: number;
	disElemId: number = null;

	constructor(private _treeSourceService: TreeSourceService, private _lookupService: LookupService) { }

	public ngOnInit(): void {
		this.treeFlattener = new MatTreeFlattener(
			this.transformer,
			this.getLevel,
			this.isExpandable,
			this.getChildren
		);

		this.treeControl = new FlatTreeControl<IItemFlatNode>(
			this.getLevel,
			this.isExpandable
		);

		this.dataSource = new MatTreeFlatDataSource(
			this.treeControl,
			this.treeFlattener
		);

		this._treeSourceService.dataChange.subscribe(data => {
			this.dataSource.data = data;
			this._setCurrentItem(this._value);
		});
	}

	public onFormFocus(event: any) {
		if (this.onFocus) {
			this.onFocus.emit(event);
		}
	}

	public ngOnChanges(changes: SimpleChanges, state: DataSourceRequestState = null): void {
		if (this.usePreloadedData) {
			this.checklistSelection = new SelectionModel<IItemFlatNode>(false);

			this._initData(this.preloadedData);
			this.valueChange.emit(null);

			return;
		}

		if (changes.sourceLookupUrl && changes.sourceLookupUrl.currentValue) {
			this._loadData(changes.sourceLookupUrl.currentValue, state);
		}
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	getLevel = (node: IItemFlatNode) => node.level;

	isExpandable = (node: IItemFlatNode) => node.expandable;

	getChildren = (node: IItemNode): IItemNode[] => node.children;

	hasChild = (_: number, _nodeData: IItemFlatNode) => _nodeData.expandable;

	/**
	 * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
	 */
	transformer = (node: IItemNode, level: number) => {
		const existingNode = this.nestedNodeMap.get(node);
		const flatNode: IItemFlatNode =
			existingNode && node && existingNode.id === node.id
				? existingNode
				: {} as IItemFlatNode;
		flatNode.id = node.id;
		flatNode.name = node.name;
		flatNode.level = level;
		flatNode.expandable = !!node.children;
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	}

	/** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
	todoLeafItemSelectionToggle(node: IItemFlatNode): void {
		if (!!this.disElemId && node.id === this.disElemId) {
			return;
		}

		this.checklistSelection.toggle(node);
		// this.checkAllParentsSelection(node);
		if (this.checklistSelection.selected.length > 0) {
			const selectedNode = this.checklistSelection.selected[0] as IItemNode;
			this.writeValue(selectedNode.id);
			this.valueChange.emit(selectedNode.id);
		} else {
			this.writeValue(null);
			this.valueChange.emit(null);
		}
		this.onTouched();
		this._treeSourceService.reInitialize();
		this.htmlInputElement.nativeElement.value = null;
		this.autocomplete.closePanel();
	}

	/* Checks all the parents when a leaf node is selected/unselected */
	checkAllParentsSelection(node: IItemFlatNode): void {
		let parent: IItemFlatNode | null = this.getParentNode(node);
		while (parent) {
			this.checkRootNodeSelection(parent);
			parent = this.getParentNode(parent);
		}
	}

	/** Check root node checked state and change it accordingly */
	checkRootNodeSelection(node: IItemFlatNode): void {
		const nodeSelected = this.checklistSelection.isSelected(node);
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected = descendants.every(child =>
			this.checklistSelection.isSelected(child)
		);
		if (nodeSelected && !descAllSelected) {
			this.checklistSelection.deselect(node);
		} else if (!nodeSelected && descAllSelected) {
			this.checklistSelection.select(node);
		}
	}

	/* Get the parent node of a node */
	getParentNode(node: IItemFlatNode): IItemFlatNode | null {

		const currentLevel = this.getLevel(node);

		if (currentLevel < 1) {
			return null;
		}

		const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

		for (let i = startIndex; i >= 0; i--) {
			const currentNode = this.treeControl.dataNodes[i];

			if (this.getLevel(currentNode) < currentLevel) {
				return currentNode;
			}
		}
		return null;
	}

	getSelectedItems(): string {
		if (!this.checklistSelection.selected.length) { return null; }
		return this.checklistSelection.selected.map(s => s.name).join(',');
	}

	filterChanged(filterText: string) {
		this._treeSourceService.filter(filterText);
		if (filterText) {
			this.treeControl.expandAll();
		} else {
			this.treeControl.collapseAll();
		}

		if (!filterText) {
			this._value = null;
			this.onChange(null);
		}
	}

	public onChange(_: any) { }
	public onTouched = () => { };

	public registerOnChange(fn: any): void { this.onChange = fn; }
	public registerOnTouched(fn: any): void { this.onTouched = fn; }
	public setDisabledState(isDisabled: boolean): void { this.isDisabled = isDisabled; }

	validate(control: AbstractControl): ValidationErrors | null {
		if (this._value) {
			return null;
		}

		if (this.isRequired && !this._value) {
			return of({ 'required': true });
		}

		return undefined;
	}

	public writeValue(value: any): void {
		this._value = value;
		if (value) {
			this._setCurrentItem(value);
			this.onChange(value);
		} else {
			this.onChange(null);
		}
	}

	/** По переданному текущему значению, нахожу нужный node и делаем его выбранным */
	private _setCurrentItem(currentId: number): void {
		if (currentId) {
			this._currentId = currentId;
			this.treeControl.dataNodes.forEach(node => this._setSelection(node, currentId));
		}
	}

	private _setSelection(parentNode: IItemFlatNode, currentId: number) {
		if (parentNode.id === currentId && !this.checklistSelection.isSelected(parentNode)) {
			this.checklistSelection.toggle(parentNode);
			return;
		} else {
			const children = this.treeControl.getDescendants(parentNode);
			children.forEach(node => this._setSelection(node, currentId));
		}
	}

	private _loadData(sourceUrl: string, state: DataSourceRequestState = null) {
		if (!this.usePreloadedData) {
			this._lookupService.getHierarchyData(sourceUrl, state, this.showOnlyIsActive, this.requestId)
				.pipe(takeUntil(this._destroy$))
				.subscribe(data => {
					if (!!this._currentId && !data.some(s => s.id === this._currentId)) {
						const scState: DataSourceRequestState = {
							filter: { logic: 'and', filters: [
								{ field: 'UserGroupId', operator: 'eq', value: this._currentId },
							] }
						};

						this._lookupService.getHierarchyData(sourceUrl, scState, false, this.requestId)
							.pipe(takeUntil(this._destroy$))
							.subscribe(data2 => {
								let disElem = data2.find(f => f.id === this._currentId);
	
								if (!!disElem) {
									this.disElemId = disElem.id;
									data.push(disElem);
									this._initData(data);
								}
							})
					} else {
						this._initData(data);
					}
				});
		}
	}

	private _initData(data: HierarchyLookupModel[]): void {

		if (this.nullNodeName !== null && this.nullNodeName !== "") {
			let nullNode: IItemNode = new HierarchyLookupModel();
			nullNode.id = null;
			nullNode.name = this.nullNodeName;
			
			data.unshift(nullNode);
		}

		this._treeSourceService.initialize(data);
	}

	private isNodeDisabled(node: IItemFlatNode): boolean {
		return node.id === this.disElemId;
	}
}
