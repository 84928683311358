import { Component, OnInit } from '@angular/core';
import { RolesService } from '../roles.service';
import { Role } from '../../../shared/models/Role/Role';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { CategoryType } from '../../../shared/enums/category-type.enum';
import { RoleCategory } from '../../../shared/models/Role/RoleCategory';
import { SpecialRightsGroupsService } from '../special-rigths-group/special-rights-groups.service';
import { SpecialRightsGroup } from '../../../shared/models';

@Component({
	selector: 'admin-role',
	templateUrl: './role.page.html',
	styleUrls: [	
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./role.page.scss'
	],
})
export class RolePage extends ComponentPageBase<Role> implements OnInit {

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: RolesService,
		private specialRightsGroupsService: SpecialRightsGroupsService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'admin/roles';
		this.appService.pageTitle = 'Новая роль';
	}

	specialRightsGroups: SpecialRightsGroup[] = [];

	ngOnInit() {
		
		this.entityId = this.route.snapshot.paramMap.get('roleId');
		this.specialRightsGroupsService.getAllSpecialRightsGroups().subscribe(res => {
			this.specialRightsGroups = res;
		});

		if (this.entityId != null) {

			this.dataService.getById(+this.entityId).subscribe(vm => {
				this.entityName = vm.entity.name;
				this.dataBind(vm);
			});

		} else {
			this.dataService.new().subscribe(x => this.entityViewModel = x);
			this.canEditForm = true;
			this.isNew = true;
		}
	}

	onChangeReadRight(event: any, category: RoleCategory): void {
		this.uncheckCatalog(event, category);
	}

	onChangeAddRight(event: any, category: RoleCategory): void {
		this.uncheckCatalog(event, category);
	}

	onChangeEditRight(event: any, category: RoleCategory): void {
		this.uncheckCatalog(event, category);
	}

	canChoseCatalogRight(category: RoleCategory): boolean {
		if (category.categoryId !== <number>CategoryType.activities && this.uncheckedAllRights(category)) {
			return false;
		}

		return category.categoryId !== <number>CategoryType.requests &&
			category.categoryId !== <number>CategoryType.requestComments;
	}

	private uncheckCatalog(event: any, category: RoleCategory): void {
		if (!event.target.checked && this.uncheckedAllRights(category)) {
			category.canReadCatalog = false;
		}
	}

	private uncheckedAllRights(category: RoleCategory): boolean {
		return !category.canRead && !category.canAdd && !category.canEdit;
	}
}
