import { Observable, forkJoin, of } from "rxjs";
import { BaseFlowComponent } from "../../base-flow-component/base-flow-component";
import { InstallFlowSimcardData } from "./install-flow-simcard-data";
import { Component } from "@angular/core";
import { UploadAttachmentsModel } from "../../../../../models/attachment";
import { AttachmentsService } from "../../../../../services/attachments.service";
import { RequestUninstallDevicesService } from "../../../../../services/request-uninstall-devices.service";
import { tap } from "rxjs/operators";
import { UploadFileData } from "../../../../../models/attachment/upload-file-data";
import { AttachmentType } from "../../../../../enums";
import { DeviceInfo } from "../../../../../models/device/device-info";
import { DevicesService } from "../../../../../services/devices.service";
import { SearchDeviceData } from "../../../../../models/device/search-device-data";
import { NotificationService } from "../../../../../../core/services/notification.service";
import { NotificationType } from "../../../../../../core/services/notification-type";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DeviceAttachmentsService } from "../../../../../services/device-attachments.service";
import { AnalyzeInstallSimcardAttachmentsRequest } from "../../../../../models/device-attachments/analyze-install-simcard-attachments-request";
import { NewRequestDevice } from "../../../../../models/request/new-request/new-request-device";

@Component({
    selector: 'install-flow-simcard',
    templateUrl: './install-flow-simcard.component.html',
    styleUrls: ['./install-flow-simcard.component.scss',
        '../../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class InstallFlowSimcardComponent 
    extends BaseFlowComponent<InstallFlowSimcardData>
{   
    public uploadedAttachmentIds: number[] = [];
    public iccidRecognized: boolean = true;
    public iccidIsInWarehouse: boolean = true;
    public formRequestDevice: NewRequestDevice = new NewRequestDevice;

    form: FormGroup;
    constructor(
        public requestUninstallDevicesService: RequestUninstallDevicesService,
        public attachmentsService: AttachmentsService,
        private deviceService: DevicesService,
        private notificationService: NotificationService,
        private deviceAttachmentsService: DeviceAttachmentsService,
        private fb: FormBuilder
    ) {
        super();
        this.form = this.fb.group({
            numericInput: ['', Validators.required]
        });

        this.form.get('numericInput').valueChanges.subscribe(value => {
            if (value){
                this.form.get('numericInput').setValue(value.trim(), { emitEvent: false });
            }
        });
    }

    initData() { 
    }

    saveChanges(): Observable<any> { 
        if (!this.useImageAnalyze) {
            return this.uploadAttachmentsRequest();
        }

        return of({ });
    }

    validateInputs() {
        if (!this.fieldsEmpty) {
            this.validateSerialNumber();
        }
        else {
            this.goToNext();
        }
    }

    validateSerialNumber() {
        this.deviceService.isRemainsDevices(this.data.requestId, this.formRequestDevice.iccid, "").subscribe((resp: SearchDeviceData) => {
            var deviceData = resp;

            if (deviceData.isInEngineerWarehouse || deviceData.isInMainWarehouse) {
                this.formRequestDevice.nomenclature = deviceData.nomenclature;
                this.data.requestDevices.push(this.formRequestDevice);
                this.goToNext();
            } else {
                this.notificationService.error({
                    title: 'Ошибка',
                    message: 'По серийному номеру не найдены остатки в 1с. Исправьте номер и попробуйте еще раз, или свяжитесь с координатором',
                    notificationType: NotificationType.Toast
                });
            }
        });
    }

    uploadAttachments() {
        if (!this.useImageAnalyze) {
            return;
        }

        this.dataSaving = true;
        this.uploadAttachmentsRequest().subscribe(() => {
            if (this.uploadedAttachmentIds.length > 0) {
                let request = {
                    requestId: this.data.requestId,
                    attachmentIds: this.uploadedAttachmentIds
                } as AnalyzeInstallSimcardAttachmentsRequest;
                this.deviceAttachmentsService.analyzeInstallSimcardAttachments(request).subscribe((resp) => {    
                    if (resp.data.length == 1) {
                        let deviceData = resp.data[0];
                        this.formRequestDevice.iccid = deviceData.number;
                        if (deviceData.isSuccessful) {
                            this.data.requestDevices.push({
                                iccid: deviceData.number,
                                nomenclature: deviceData.nomenclature
                            } as NewRequestDevice);
                            this.goToNext();
                        }
                        else {
                            this.iccidIsInWarehouse = deviceData.isInWarehouse;
                            this.iccidRecognized = deviceData.recognized;
                        }
                    }
                    else if (resp.data.length > 1 && resp.data.every(x => !x.isSuccessful)) {
                        // todo: не очень валидный кейс, т.к. обращаем внимаение только на первый объект
                        let deviceData = resp.data[0];
                        this.iccidIsInWarehouse = deviceData.isInWarehouse;
                        this.iccidRecognized = deviceData.recognized;
                    }
                    else if (resp.data.length > 1) {
                        resp.data.filter(x => x.isSuccessful).forEach(x => {
                            this.data.requestDevices.push({
                                iccid: x.number,
                                nomenclature: x.nomenclature
                            } as NewRequestDevice);
                        });
                        this.goToNext();
                    }
                    this.dataSaving = false;
                });
            }
            else {
                this.dataSaving = false;
            }
        });
    }
    
    removeAttachment(uploadFileData: UploadFileData) {
        let attachment = this.data.attachments.find(x => x.uid == uploadFileData.uid);
        if (attachment.isUploadedOnServer) {
            attachment.isRemoved = true;
        }
        else {
            this.data.attachments = this.data.attachments.filter(x => x.uid != uploadFileData.uid);
        }

        this.uploadAttachmentsRequest().subscribe(() => {});
    }

    uploadAttachmentsRequest(): Observable<any> {
        this.data.attachments.forEach(x => {
            x.attachmentTypeId = AttachmentType.installedSimFoto;
            x.isTemporary = true;
        });
        
        let attachmentsToUpload = this.data.attachments.filter(x => !x.isUploadedOnServer && !x.isRemoved);
        let attachmentsToRemove = this.data.attachments.filter(x => x.isUploadedOnServer && x.isRemoved);

        const uploadModel = new UploadAttachmentsModel({ 
            requestId: this.data.requestId, 
            activityId: this.data.activityId,
            attachments: attachmentsToUpload
        });

        let attachmentObservables$: Observable<any>[] = [];

        if (attachmentsToRemove.length > 0) {
            attachmentObservables$ = attachmentsToRemove.map((x) => 
                this.attachmentsService.remove(x.uid, null, null, true)
                    .pipe(tap(() => {
                        this.data.attachments = this.data.attachments.filter(a => a.uid != x.uid);
                    })));
        }

        if (attachmentsToUpload.length > 0) {
            attachmentObservables$.push(
                this.attachmentsService.uploadWithResumableIfMobile(uploadModel)
                .pipe(tap((resp) => {
                    if (resp && resp.body && resp.body.data) {
                        this.uploadedAttachmentIds = resp.body.data;
                    }
                    attachmentsToUpload.forEach(x => x.isUploadedOnServer = true);
                }))
            );
        }

        return attachmentObservables$.length > 0 
            ? forkJoin(attachmentObservables$) 
            : of({}); 
    }

    get hasSavedAttachments() {
        return this.data.attachments.filter(x => x.isRemoved != true).length > 0;
    }

    get fieldsCompleted(): boolean {
        return this.hasSavedAttachments &&
               this.simCardICCIDValid
    }

    get fieldsEmpty(): boolean {
        return !this.hasSavedAttachments &&
               !this.simCardICCIDValid
    }

    get simCardICCIDValid(): boolean {
        return this.formRequestDevice.iccid != null && this.formRequestDevice.iccid != '';
    }
}