import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DayOfWeek } from '../../../../shared/enums/day-of-week.enum';
import { CustomerObjectWorkingHours } from '../../../models/customerObject/customer-object-working-hours';

@Component({
	selector: '[customer-object-working-hours]',
	templateUrl: './customer-object-working-hours.component.html',
	styleUrls: [
		'./customer-object-working-hours.component.scss'
	],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CustomerObjectWorkingHoursComponent {
	@Input() workingHours: CustomerObjectWorkingHours[];
	@Input() canEditForm: boolean;
	@Output() changeWorkingHours = new EventEmitter();

	dayNames: string[] = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
	workingHoursTemplate: string[] = [
		'00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
		'12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
	];

	constructor() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.workingHours && changes.workingHours.currentValue != changes.workingHours.previousValue) {
			this.workingHours = this.normalizeWorkingHours(this.workingHours);
		}
	}

	public onWorkingHoursOpen(e: any) {
		e.preventDefault();
	}

	public closedChanged(item: CustomerObjectWorkingHours) {
		if (item.isClosed) {
			item.startHour = null;
			item.endHour = null;
			item.lunchTimeStart = null;
			item.lunchTimeEnd = null;
		}
	}

	private normalizeWorkingHours(source: CustomerObjectWorkingHours[]): CustomerObjectWorkingHours[] {
		let result = [];
		for (let day = DayOfWeek.Monday; day <= DayOfWeek.Sunday; day++) {
			let item = source.find(v => v.day === day);
			if (item) {
				item.startHour = this.formatHours(item.startHour);
				item.endHour = this.formatHours(item.endHour);
				item.lunchTimeStart = this.formatHours(item.lunchTimeStart);
				item.lunchTimeEnd = this.formatHours(item.lunchTimeEnd);
			} else {
				item = new CustomerObjectWorkingHours();
				item.day = day;
			}
			result.push(item);
		}
		return result;
	}

	private formatHours(value: string): string {
		if (value) {
			let i = value.indexOf(':', 3);
			if (i > 0) {
				value = value.substring(0, i);
			}
		}
		return value;
	}
}
