import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { filter, tap } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { ActionResult } from '../models/core/ActionResult';
import { AddOrUpdateConfigurationModel } from '../components/workflow-transitions/configuration-preparing/add-or-update-configuration.model';

@Injectable()
export class TmsConfigurationService {

    constructor(public http: HttpClient,
		public notificationService: NotificationService) {
	}

    private controllerName: string = 'tms-configuration';
    private controllerVersion = 1;

    public addOrUpdateConfiguration(body: AddOrUpdateConfigurationModel): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}`, body)
            .pipe(tap(resp => {

                if (!resp.isSuccessful) {
                    this.notificationService.error({
                        title: 'Ошибка',
                        message: resp.errorDescription,
                        notificationType: NotificationType.SweetAlert
                    });
                }

            }, errorResponse => {

                if (errorResponse.status === 904)
                    return;

                this.notificationService.error({
                    title: 'Ошибка',
                    message: errorResponse.error.errorDescription,
                    notificationType: NotificationType.SweetAlert
                });

            }))
            .pipe(filter(x => x.isSuccessful));
    }
}