export enum DeviceServiceType {
    /**KTO */
    KTO = 1,
    /**KTO MSP */
    KTO_MSP = 2,
    /**Разовые заявки */
    SINGLE_REQUEST = 3,
    /**КИБ */
    KIB = 4,
    /**СМБ */
    SMB = 5,
}
