﻿import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ContragentDetail} from "../../../../../shared/models/contragent/contragent-detail";
import {KeyValueObject} from "../../../../../shared/models/core/KeyValueObject";
import {LookupService} from "../../../../../shared/services/lookup.service";
import {ContragentDetailsService} from "../../../../../shared/services/contragent-details.service";
import {NotificationType} from "../../../../../core/services/notification-type";
import {NotificationService} from "../../../../../core/services/notification.service";

@Component({
	selector: 'contragent-details-form',
	templateUrl: './details-form.component.html',
	styleUrls: [
		'../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ContragentDetailsFormComponent implements OnInit {

	@Input()
	public contragentId: number;

	@Input()
	public isNew: boolean;

	@Input()
	canEdit: boolean = false;

	@Input()
	public saveOnAddOrRemove: boolean = true;

	@Input()
	public details: ContragentDetail;

	@Output()
	public afterSaveChanges = new EventEmitter<any>();

	@Output()
	public afterUpdateDetails = new EventEmitter<any>();

	@Output()
	public afterRemoveDetails = new EventEmitter<any>();

	dataSaving: boolean = false;

	legalTypes: KeyValueObject[] = [];

	constructor(
		private contragentDetailsService: ContragentDetailsService,
		private lookupService: LookupService,
		private notificationService: NotificationService,
	) {

	}

	ngOnInit(): void {
		this.afterSaveChanges.subscribe(result => this.dataSaving = false);

		this.lookupService.getData("legal-types", null).subscribe(data => this.legalTypes = data);

		this.canEdit = this.isNew;
		if (this.isNew)
			this.details = new ContragentDetail();
	}

	addOrUpdateDetails() {
		if (!this.saveOnAddOrRemove) {
			if (this.afterSaveChanges)
				this.afterSaveChanges.emit(this.details);
			return;
		}

		this.dataSaving = true;
		this.details.contragentId = this.contragentId;
		if (this.details.contragentDetailId){
			this.contragentDetailsService.update(this.details, 'Обновлено успешно', false)
				.subscribe(result => {

					if (this.afterUpdateDetails)
						this.afterUpdateDetails.emit();

					this.canEdit = false;

				}, error => {
					this.dataSaving = false
				});
		} else {
			this.contragentDetailsService.create(this.details)
				.subscribe(result => {

					this.details.contragentDetailId = result.data;

					if (this.afterSaveChanges)
						this.afterSaveChanges.emit(this.details);

				}, error => {
					this.dataSaving = false
				});
		}
	}

	contactRemoveHandler(): void {
		if (!this.saveOnAddOrRemove) {
			if (this.afterRemoveDetails)
				this.afterRemoveDetails.emit(this.details);
			return;
		}

		this.dataSaving = true;
		this.contragentDetailsService.removeForContragent(this.details.contragentDetailId.toString(), this.details.contragentId)
			.subscribe(result => {

				if (this.afterRemoveDetails)
					this.afterRemoveDetails.emit(this.details);

				this.notificationService.success({
					title: 'Удалено',
					message: 'Реквизиты удалены успешно',
					notificationType: NotificationType.Toast
				});

				this.dataSaving = false;
			}, error => {
				this.dataSaving = false
			});
	}

	onClickChangeDetails() {
		this.canEdit = !this.canEdit;
	}
}
