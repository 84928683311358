import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LookupService } from '../../../services/lookup.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { KeyValueObject } from '../../../models/core/KeyValueObject';
import { DataSourceRequestState } from '@progress/kendo-data-query';

@Component({
  selector: 'app-set-activity-reason',
  templateUrl: './set-activity-reason.component.html',
  styleUrls: ['./set-activity-reason.component.scss']
})
export class SetActivityReasonComponent implements OnInit, OnDestroy {

	@Input() activityStatusId: number;
	@Output() onContinueEvent = new EventEmitter<{activityReasonId: number, commentText: string}>();

	public form: FormGroup;
	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };
	public activityStatusReasons: KeyValueObject[] = [];

	private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
  	private lookupService: LookupService,
		private dialog: DialogRef) {
		this._buildForm();
	}

  public ngOnInit() {
	this._loadData();
	}

	public ngOnDestroy(): void {
		this._destroy$.next(true);
		this._destroy$.complete();
	}

	public cancel() {
		if(this.dialog)
			this.dialog.close();
	}

	public continue() {
		this.onContinueEvent.emit(this.form.value);
		this.cancel();
	}

	private _loadData(): void {
  	const state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: 'and', filters: [{ field: 'activityStatusId', operator: 'eq', value: this.activityStatusId }] }
		};
		this.lookupService.getData('activity-reasons', state)
			.pipe(takeUntil(this._destroy$))
			.subscribe(result => this.activityStatusReasons = result);
	}

	private _buildForm(): void {
		this.form = new FormGroup({
			activityReasonId: new FormControl(false, Validators.required),
			commentText: new FormControl(null)
		});
	}
}
