import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { ServiceCategoryWithModeLookup } from '../../../shared/models/services/service-category-with-mode-lookup';
import { AgreementService } from '../../../shared/models/agreement/agreement-service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CheckboxKeyValueObject } from '../../../shared/models/checkbox-key-value-object';

@Component({
	selector: 'add-bulk-agreement-services',
	templateUrl: './add-bulk-agreement-services.component.html',
	styleUrls: []
})
export class AddBulkAgreementServicesForm implements OnInit {

	@Input()
	public services: KeyValueObject[] = [];

	@Input()
	public serviceTypes: KeyValueObject[] = [];

	@Input()
	public serviceCategories: ServiceCategoryWithModeLookup[] = [];

	@Output()
	public onAdd = new EventEmitter<AgreementService[]>();

	public chosenServices: CheckboxKeyValueObject[] = [];
	public chosenServiceCategories: CheckboxKeyValueObject[] = [];
	public chosenServiceTypes: CheckboxKeyValueObject[] = [];

	constructor(
		private injector: Injector,
	) {

	}

	ngOnInit(): void {
		this.chosenServices = this.services.map(service => {
			return { ...service, chosen: false };
		});

		this.chosenServiceCategories = this.serviceCategories.map(serviceCategory => {
			return { ...serviceCategory, chosen: false };
		});

		this.chosenServiceTypes = this.serviceTypes.map(serviceType => {
			return { ...serviceType, chosen: false };
		});
	}

	onChooseAllServices(event: any): void {
		if (event.target.checked) {
			this.chosenServices.map(service => service.chosen = true);
		} else {
			this.chosenServices.map(service => service.chosen = false);
		}
	}

	onChooseAllServiceCategories(event: any): void {
		if (event.target.checked) {
			this.chosenServiceCategories.map(serviceCategory => serviceCategory.chosen = true);
		} else {
			this.chosenServiceCategories.map(serviceCategory => serviceCategory.chosen = false);
		}
	}

	onChooseAllServiceTypes(event: any): void {
		if (event.target.checked) {
			this.chosenServiceTypes.map(serviceType => serviceType.chosen = true);
		} else {
			this.chosenServiceTypes.map(serviceType => serviceType.chosen = false);
		}
	}

	onAddServices(): void {
		if (this.onAdd) {
			const agreementServices = this.createAgreementServices();
			this.onAdd.emit(agreementServices);
		}

		this.closeDialog();
	}

	onCancel(): void {
		this.closeDialog();
	}

	isValidForm(): boolean {
		return this.chosenServices.some((x => x.chosen) as any) &&
			this.chosenServiceCategories.some((x => x.chosen) as any) &&
			this.chosenServiceTypes.some((x => x.chosen) as any);
	}

	private closeDialog(): void {
		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}

	private createAgreementServices(): AgreementService[] {
		const agreementServices: AgreementService[] = [];

		const chosenServices = this.chosenServices.filter(x => x.chosen);
		const chosenServiceCategories = this.chosenServiceCategories.filter(x => x.chosen);
		const chosenServiceTypes = this.chosenServiceTypes.filter(x => x.chosen);

		chosenServices.map(service => {
			chosenServiceCategories.map(serviceCategory => {
				chosenServiceTypes.map(serviceType => {

					const agreementService = new AgreementService();

					agreementService.serviceId = service.id;
					agreementService.serviceCategoryId = serviceCategory.id;
					agreementService.serviceTypeId = serviceType.id;

					agreementServices.push(agreementService);
				});
			});
		});

		return agreementServices;
	}

}
