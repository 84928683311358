import { CommentKindEnum } from "../../../enums/comment-kind.enum";

export class SetPerformerUserGroupModel {

    constructor(userGroupId?: number, userId?: number, comment?: string){
        this.userGroupId = userGroupId;
        this.userId = userId;
        this.comment = comment;
    }

    userId?: number;
    userGroupId?: number;
    comment: string;
    commentKind: CommentKindEnum;
    requestStoppingReasonId?: number;
}