import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation, EventEmitter, Output, ElementRef } from '@angular/core';
import { AppService } from '../../../app.service';
import { RequestsService } from '../../services/requests.service';
import { RequestDevicesService } from '../../services/request-devices.service';
import { NewRequestDevice } from '../../models/request/new-request/new-request-device';
import { DevicesListPage } from '../devices/list/list.page';
import { Device } from '../../models/device/Device';
import { DialogService } from '@progress/kendo-angular-dialog';
import { KeyValueObject } from '../../models/core/KeyValueObject';
import { LookupService } from '../../services/lookup.service';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { HierarchyLookupModel } from '../../models/core/HierarchyLookupModel';
import { DataResult, process, State, DataSourceRequestState, groupBy, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { IFreeNomenclature } from '../../interfaces';
import { SecurityService } from '../../../core/services/security.service';
import { IRequestDeviceNomenclatureQuery1c } from '../../models/request/request-device-nomenclature-query-1c.interface';
import {BehaviorSubject, forkJoin, Subject} from 'rxjs';
import {finalize, share, map, switchMap, tap} from 'rxjs/operators';
import {CustomClaim, DeviceStatus, RequestInitTypeEnum, RequestStatus, RequestType} from '../../enums';
import {RequestUninstallDevicesService} from '../../services/request-uninstall-devices.service';
import {AddEvent, ColumnBase, ColumnComponent, GridComponent, RowClassArgs} from '@progress/kendo-angular-grid';
import {DeviceAttachmentsComponent} from '../device-attachments/device-attachments.component';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import { SetCancelReserveReason } from './set-cancel-reserve-reason/set-cancel-reserve-reason.component';
import { Request } from '../../models/request/request';
import { ReserveResult } from '../../enums/reserve-result.enum';
import { DeviceTypeEnum } from '../../enums/device-type.enum';
import { NewRequestsService } from '../../services/new-requests.service';
import { DeviceTypesService } from '../../services/device-types.service';
import { DeviceTypeDto } from '../../models/device/device-type.dto';
import { DeviceKindEnum } from '../../enums/device-kind.enum';
import { SetComment } from '../workflow-transitions/set-comment/set-comment';
import { RequestDeviceWarehouseRefillDto } from '../../models/request/request-device-warehouse-refills';
import { DevicesService } from '../../services/devices.service';
import { PopupComment } from '../grid/popupComment/popup-comment.component';
import { GridSettings } from '../../models/grid/grid-settings';
import { ColumnSettings } from '../../models/grid/column-settings';
import { StatePersistingService } from '../../services/state-persisting.service';
import { ServiceCentersService } from '../../services/service-centers.service';
import { For1CService } from '../../services/for-1c.service';
import { RequestStatusKindEnum } from '../../enums/request-status-kind.enum';
import { DeviceConditionEnum } from '../../enums/device-conditions.enum';
import { GetNomenclaturesQuery } from '../../models/for-1c/get-nomenclatures/get-nomenclatures-query';
import { CustomerEnum } from '../../enums/customer.enum';
import { DeviceConditionDto } from '../../models/device/device-condition.dto';
import { DeviceConditionsService } from '../../services/device-conditions.service';
import { WarehousesService } from '../../services/warehouses.service';
import { MapWarehouseAreasCommand } from '../../models/warehouseAreas/map-warehouse-areas-command';
import { WarehouseAreaLookupModel } from '../../models/warehouse/warehouse-area-lookup-model';
import { WarehouseAreasService } from '../../services/warehouse-areas.service';
import { RequestDeviceWarehouseRefillsService } from '../../services/request-device-warehouse-refills.service';
import { data } from 'jquery';
import { NgModel } from '@angular/forms';
import { WarehouseKindEnum } from '../../enums/warehouse-kind.enum';

@Component({
	selector: 'request-devices',
	templateUrl: './request-devices.component.html',
	styleUrls: ['./request-devices.component.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RequestDevicesComponent implements OnInit, OnChanges {

	public reservedStatus: string = 'Забронирован';
	public withdrawReservedStatus: string = 'Запрос на снятие резерва';
	public reserveCancelled: string = 'Резерв аннулирован';
	public awaitingDeliveryOfEquipment: string = 'Ожидает выдачи инженеру';
	public engineerReceivedDevices: string = 'Оборудование получено инженером';

	public reserveRequested: boolean = false;

	public customClaims = CustomClaim;
	public requestTypes = RequestType;
	public deviceStatus = DeviceStatus;
	public initTypes = RequestInitTypeEnum;

	public requestWarehouseTypes = [RequestType.warehouseTransfer, RequestType.foreighWarehouseTransfer, RequestType.internalWarehouseTransfer];

	@ViewChild('categoriesDevicesList') categoriesDevicesList: DevicesListPage;

	@Input() request: Request;
	@Input() requestId: number;
	@Input() requestTypeId: number;
	@Input() initTypeId: number;
	@Input() activityId: number;
	@Input() showMobileFooter: boolean;
	@Input() enabled = true;
	@Input() isServiceLogic = false;

	@Input()
	canEditForm: boolean = false;

	@Input()
	canEditRow: boolean = true;

	@Input()
	canRemoveRow: boolean = true;

	@Input()
	addButtonEnabled: boolean = true;

	@Input()
	serialEnough: boolean = false;

	public enableAddDevice = false;
	public enableRequestReserve = false;
	public gridEditing = false;
	lastEditRowIndex: number = null;
	lastEditSerialNumber: string = null;

	isCheckDeviceAvailableToMoveProgress: boolean = false;
	nomenclatureLoading: boolean = false;
	serialLoading: boolean = false;
	
	public warehouseRefillRemoveConfirmationSubject: Subject<boolean> = new Subject<boolean>();
	public warehouseRefillItemToRemove: any;

	reParseRequestDeviceArticlesInProgress: number[] = [];
	warehouses: KeyValueObject[] = [];

	articlesFromDb: KeyValueObject[] = [];

	gridLoading: boolean = false;

	isAllArticlesEditing: boolean = false;

	requestDeviceWarehouseRefills: RequestDeviceWarehouseRefillDto[] = [];

	public currentRequestTypeId: RequestType;
	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	@ViewChild("warehouseRefill") warehouseRefillGrid: GridComponent;
	@ViewChild("warehouseRefill", { read: ElementRef }) warehouseRefillGridRef: ElementRef;

	public articlesFromDbDefaultItem: { id: number, name: string } = { id: null, name: 'Выберите артикул...'};

	otherNomenclatureWarehouseArea: boolean = false;
	requestDevicesWithOtherWarehouseArea: NewRequestDevice[] = [];

	requestDevices: any[] = [];
	requestUninstallDevices: any[] = [];

	private requestDevicesCount: number;

	deviceModels: HierarchyLookupModel[] = [];
	supplierDevices: HierarchyLookupModel[] = [];
	dropDownDeviceModels: HierarchyLookupModel[] = [];

	deviceTypesFull: DeviceTypeDto[] = [];
	deviceSuppliers: KeyValueObject[] = [];
	deviceTypes: KeyValueObject[] = [];
	deviceConnectionTypes: KeyValueObject[] = [];
    deviceConnectionTypesGrouped: any[] = [];
	deviceConnectionTypesGroupedDropdown: any[] = [];
	deviceStatuses: KeyValueObject[] = [];
	public deviceNomenclatures: IFreeNomenclature[] = [];
	public isShowReserveBtn = false;
	mapWarehouseAreasCommand: MapWarehouseAreasCommand = null;

	warehouseAreasWithWarehouse: WarehouseAreaLookupModel[] = [];
	warehouseAreasWithWarehouseRecipientDropdown: WarehouseAreaLookupModel[] = [];
	warehouseAreasWithWarehouseSenderDropdown: WarehouseAreaLookupModel[] = [];
	warehousesIsLoaded: boolean = false;

	senderWarehouseAreaId: number;
	recipientWarehouseAreaId: number;

	isReserveSerialNumberAvailable: boolean = false;

	get isRequestEmptySenderWarehouseArea(): boolean {
		return !!!this.targetRequestWarehouseAreaIntegraCode;
	}

	get targetRequestWarehouseAreaIntegraCode(): string {
		return !!this.request.senderWarehouseAreaIntegraCode ? this.request.senderWarehouseAreaIntegraCode : this.request.serviceCenterWarehouseAreaIntegraCode;
	}

	get targetRequestWarehouseAreaId(): number {
		return !!this.request.senderWarehouseAreaId ? this.request.senderWarehouseAreaId : this.request.serviceCenterWarehouseAreaId;
	}

	get isWarehouseLogic(): boolean {
		return this.requestTypeId === <number>RequestType.warehouseRefill;
	}

	get isUninstall(): boolean {
		return this.currentRequestTypeId === this.requestTypes.uninstall 
			||  this.currentRequestTypeId === this.requestTypes.uninstallOld;
	}

	get isReplacementMk(): boolean {
		return this.isMkInitType && this.currentRequestTypeId === this.requestTypes.changeConfig;
	}

	get isExpertiseMk(): boolean {
		return this.isMkInitType && this.currentRequestTypeId === this.requestTypes.expertise;
	}
	
	get isReceiveToWarehouseMk(): boolean {
		return this.isMkInitType && this.currentRequestTypeId === this.requestTypes.receiveToWarehouse;
	}

	get isIssueAndReceiveToWarehouseMk(): boolean {
		return this.isMkInitType && this.currentRequestTypeId === this.requestTypes.issueAndReceiveToWarehouse;
	}

	get isServiceOrReplaceMk(): boolean {
		return this.isMkInitType && this.currentRequestTypeId === this.requestTypes.serviceOrReplace;
	}

	get isServiceWithReplacePinPad(): boolean {
		return this.isMkInitType && this.currentRequestTypeId === this.requestTypes.service
			&& this.request.replacePinPad;
	}

	get kendoGridAddCommandDisabled(): boolean {
		return this.reserveRequested || this.gridEditing;
	}

	get kendoGridAddCommandDisabledUninstall(): boolean {
		return this.reserveRequested || this.gridEditing || !this.isCanEditRequestUninstallDevicesGrid;
	}

	get kendoGridEditCommandDisabled(): boolean {
		return this.gridEditing;
	}

	get isReplacementNotMk(): boolean {
		return !this.isMkInitType && 
			(this.currentRequestTypeId === this.requestTypes.changeConfig ||
			this.currentRequestTypeId === this.requestTypes.service);
	}

	get isOtherDevices(): boolean {
		return !this.isUninstall 
			&& !this.isReplacementMk 
			&& !this.isExpertiseMk
			&& !this.isReceiveToWarehouseMk 
			&& !this.isIssueAndReceiveToWarehouseMk
			&& !this.isServiceOrReplaceMk
			&& !this.isServiceWithReplacePinPad
			&& !this.isReplacementNotMk;
	}

	constructor(
		private dialogService: DialogService,
		private appService: AppService,
		private lookupService: LookupService,
		private requestsService: RequestsService,
		private devicesService: DevicesService,
		private requestDevicesService: RequestDevicesService,
		private requestUninstallDevicesService: RequestUninstallDevicesService,
		private newRequestsService: NewRequestsService,
		private notificationService: NotificationService,
		private persistingService: StatePersistingService,
		private deviceTypesService: DeviceTypesService,
		private securityService: SecurityService,
		private serviceCentersSerivce: ServiceCentersService,
		private for1CService: For1CService,
		private deviceConditionsService: DeviceConditionsService,
		private warehousesService: WarehousesService,
		private warehouseAreasService: WarehouseAreasService,
		private requestDeviceWarehouseRefillsService: RequestDeviceWarehouseRefillsService) {

	}

	public ngOnInit(): void {
		this.devicesService.isReserveSerialNumberAvailable(this.requestId).subscribe(r => this.isReserveSerialNumberAvailable = r);

		const deviceTypes$ = this.lookupService.getData('device-types', null);
		const deviceConnectionTypes$ = this.lookupService.getData('device-connection-types', {
			skip: 0,
			take: null,
			filter: { logic: 'and', filters: [{ field: 'ParentDeviceConnectionTypeId', operator: 'isnotnull' }] }
		});
		const deviceStatuses$ = this.lookupService.getData('device-statuses', null);
		const deviceModels$ = this.lookupService.getHierarchyData('device-models', null);

		const articlesState: DataSourceRequestState = {
			filter: {
				logic: 'and', filters: [
					{ field: 'ContragentId', operator: 'eq', value: this.request.customerContragentId },
				]
			}
		};

		this.lookupService.getData("articles", articlesState).subscribe(data => this.articlesFromDb = data);
		this.lookupService.getData("warehouses", null).subscribe(data => this.warehouses = data);

		forkJoin([deviceTypes$, deviceConnectionTypes$, deviceStatuses$, deviceModels$])
			.subscribe(results => {
					this.deviceTypes = results[0];
					this.deviceConnectionTypes = results[1];
					this.deviceConnectionTypesGrouped = groupBy(results[1], [{field: 'groupName'}]);
					this.deviceConnectionTypesGroupedDropdown = this.deviceConnectionTypesGrouped;
					this.deviceStatuses = results[2];
					this.deviceModels = results[3];
					this.dropDownDeviceModels = this.deviceModels;
					this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null).map(x => new KeyValueObject(x.id, x.name));
				});

		this.loadGridData();

		this.deviceTypesService.getAllDeviceTypesNoCategory().subscribe(res => {
			this.deviceTypesFull = res;
		});

		this.loadWarehousesForUninstallGrid();
		this.loadWarehouseAreasForUninstallGrid();
		this.loadWarehouseAreaCellsForUninstallGrid();

		this.senderWarehouseAreaId = this.request.senderWarehouseAreaId;
		this.recipientWarehouseAreaId = this.request.recipientWarehouseAreaId;

		if (this.warehousesIsLoaded) {
			this.warehouseAreasWithWarehouseSenderDropdown = this.warehouseAreasWithWarehouse;
			this.warehouseAreasWithWarehouseRecipientDropdown = this.warehouseAreasWithWarehouse;			
		}
		else {
			let warehouseKindIds = [
				<number>WarehouseKindEnum.OwnEmployee,
				<number>WarehouseKindEnum.ThirdPartyEmployee,
				<number>WarehouseKindEnum.ThirdPartySelfEmployed,
				<number>WarehouseKindEnum.TransportCompanyWarehouse,
			];
			this.warehouseAreasService.getWithWarehouse(warehouseKindIds).subscribe(response => {
				this.warehouseAreasWithWarehouse = response.map(x => {
					return {
						warehouseAreaId: x.warehouseAreaId,
						warehouseAreaName: `${x.warehouseName} ${x.warehouseAreaName}`,
						warehouseId: x.warehouseId,
						warehouseName: x.warehouseName,
						warehouseIntegraCode: x.warehouseIntegraCode
					}
				});
				this.warehouseAreasWithWarehouseSenderDropdown = this.warehouseAreasWithWarehouse;
				this.warehouseAreasWithWarehouseRecipientDropdown = this.warehouseAreasWithWarehouse;
			});	
			this.warehousesIsLoaded = true;
		}

		this.mapWarehouseAreasCommand = new MapWarehouseAreasCommand();
		this.mapWarehouseAreasCommand.requestId = this.requestId;

		this.mapWarehouseAreasCommand.recipientBankBranchName = this.request.commonData !== null ? this.request.commonData.recipientBankBranchName : null;
		this.mapWarehouseAreasCommand.recipientWarehouseAddress = this.request.commonData !== null ? this.request.commonData.recipientWarehouseAddress : null;
		this.mapWarehouseAreasCommand.recipientWarehouseAreaPartnerCode = this.request.commonData !== null ? this.request.commonData.recipientWarehouseAreaPartnerCode : null;

		this.mapWarehouseAreasCommand.senderBankBranchName = this.request.commonData !== null ? this.request.commonData.senderBankBranchName : null;
		this.mapWarehouseAreasCommand.senderWarehouseAddress = this.request.commonData !== null ? this.request.commonData.senderWarehouseAddress : null;
		this.mapWarehouseAreasCommand.senderWarehouseAreaPartnerCode = this.request.commonData !== null ? this.request.commonData.senderWarehouseAreaPartnerCode : null;
	}
	
	//#region UninstallGridEditing

	public requestDeviceEditHandler(obj: any) {
		const dataItem = obj.dataItem as NewRequestDevice;
		if (!!dataItem) {
			if (this.warehousesForUninstallGrid.some(w => w.id === dataItem.warehouseId)) {
				this.warehouseChangeOnUninstallGrid(dataItem);
			}
			let selectedNomenclature: any  = {nomenclature: dataItem.nomenclature}
			dataItem.selectedNomenclature = selectedNomenclature;
			this.loadNomenclaturesFrom1C(dataItem);
		}
	}

	public warehousesForUninstallGrid: KeyValueObject[] = [];
	public warehouseAreasForUninstallGrid: KeyValueObject[] = [];
	public warehouseAreaCellsForUninstallGrid: KeyValueObject[] = [];
	public deviceConditionsSource: DeviceConditionDto[] = [];

	private loadWarehousesForUninstallGrid(): void {
		this.serviceCentersSerivce.getWarehouses(this.request.serviceCenterId).subscribe(data => this.warehousesForUninstallGrid = data);
	}

	private loadWarehouseAreasForUninstallGrid(): void {
			this.lookupService.getData('warehouse-areas', null).subscribe(data => this.warehouseAreasForUninstallGrid = data);
	}

	private loadWarehouseAreaCellsForUninstallGrid(): void {
		this.lookupService.getData('warehouse-cells', null).subscribe(data => this.warehouseAreaCellsForUninstallGrid = data);
	}

	public warehouseChangeOnUninstallGrid(dataItem: NewRequestDevice): void {
		if (!!dataItem.warehouseId) {
			var state: DataSourceRequestState = {
				filter: {
					logic: "and",
					filters: [{
						operator: "eq",
						field: "WarehouseId",
						value: dataItem.warehouseId
					}]
				}
			}
			this.lookupService.getData('warehouse-areas', state).subscribe(data => {
				dataItem.warehouseAreas = data;
				if (!dataItem.warehouseAreas.some(wc => wc.id === dataItem.warehouseAreaId))
					dataItem.warehouseAreaId = null;

				if (!!dataItem.warehouseAreaId && dataItem.warehouseAreas.some(wa => wa.id === dataItem.warehouseAreaId)) {
					this.warehouseAreaChangeOnUninstallGrid(dataItem);
				}
			});	
		}
	}

	public warehouseAreaChangeOnUninstallGrid(dataItem: NewRequestDevice): void {
		if (!!dataItem.warehouseAreaId) {
			var state: DataSourceRequestState = {
				filter: {
					logic: "and",
					filters: [{
						operator: "eq",
						field: "WarehouseAreaId",
						value: dataItem.warehouseAreaId
					},
					{
						operator: "eq",
						field: "WarehouseCellTypeId",
						value: 2
					}]
				}
			}
			this.lookupService.getData('warehouse-cells', state).subscribe(data => {
				dataItem.warehouseCells = data;
				if (!dataItem.warehouseCells.some(wc => wc.id === dataItem.warehouseCellId))
					dataItem.warehouseCellId = null;
			});	
		}
	}

	private loadDeviceConditions(): void {
		this.deviceConditionsService.getConditionsByContragent(this.request.customerContragentId).subscribe(data => {
			this.deviceConditionsSource = data
			this.requestUninstallDevicesGridData.data.forEach(x => x.deviceConditions = this.getDeviceConditionsForUninstallGrid(x));
		});
	}

	getDeviceConditionsForUninstallGrid(dataItem: NewRequestDevice): KeyValueObject[] {
		return !!this.deviceConditionsSource
			? dataItem.deviceConditions = this.deviceConditionsSource
				.filter(x => x.deviceConditionTypeId == <number>DeviceConditionEnum.broken
					|| x.deviceConditionTypeId == <number>DeviceConditionEnum.used)
				.map(x => new KeyValueObject(x.deviceConditionId, x.name))
			: [];
	}

	private loadNomenclaturesFrom1C(dataItem: NewRequestDevice): void {
		let body = new GetNomenclaturesQuery;
		body.deviceTypeId = dataItem.deviceTypeId;
		body.deviceModelName = dataItem.deviceModelName;
		body.deviceSupplierName = dataItem.deviceSupplierName;
		body.deviceConnectionTypeId = dataItem.deviceConnectionTypeId;
		this.for1CService.getNomenclatures(body).subscribe(r => {
				if (r.isSuccessful) {
					let arr: any[] = [];
					r.data.forEach(n => arr.push({nomenclature: n}))
					dataItem.nomenclatures = arr;
					if (!dataItem.nomenclatures.some(n => n.nomenclature == dataItem.nomenclature)) {
						dataItem.nomenclature = null;
						dataItem.selectedNomenclature.nomenclature = null;
					}
				} else {
					dataItem.nomenclatures = [];
				}
			});
	}

	public serialNumberNotDefinedChanged(dataItem: NewRequestDevice): void {
		if (dataItem.isNotDefinedSn == true)
			dataItem.serialNumber = null;
	}

	public isDeviceBrokenChanged(dataItem: NewRequestDevice): void {
		dataItem.deviceConditions = this.getDeviceConditionsForUninstallGrid(dataItem);

		if (!dataItem.isDeviceBroken) {
			dataItem.deviceConditionId = dataItem.deviceConditions.filter(
				x => x.id != DeviceConditionEnum.broken)[0].id;
			dataItem.brokenDescription = null;
		}
		else {
			dataItem.deviceConditionId = DeviceConditionEnum.broken;
		}
	}

	public isConditionChanged(dataItem: NewRequestDevice): void {
		dataItem.isDeviceBroken = dataItem.deviceConditionId == DeviceConditionEnum.broken;

		if (!dataItem.isDeviceBroken) {
			dataItem.brokenDescription = null;
		}
	}

	public isDeviceMainParameterChanged(dataItem: NewRequestDevice, isInWork: boolean): void {
		if (isInWork) {
			dataItem.nomenclature = null;
			let supplier = this.deviceSuppliers.find(s => s.id === dataItem.deviceSupplierId);
			if (!!supplier)
				dataItem.deviceSupplierName = supplier.name

			let model = this.deviceModels.find(m => m.id === dataItem.deviceModelId);
			if (!!model)
				dataItem.deviceModelName = model.name

			this.loadNomenclaturesFrom1C(dataItem);
		}
	}

	public deviceSupplierChangeUninstallGrid(deviceSupplierId: number, dataItem: NewRequestDevice, isInWork: boolean): void {
		if (isInWork) {
			dataItem.deviceModelId = null;
			this.fillSupplierDevices(deviceSupplierId);
			this.isDeviceMainParameterChanged(dataItem, isInWork);
		} else {
			this.deviceSupplierChange(deviceSupplierId, dataItem);
		}
	}

	public kendoGridSaveCommandDisabled(dataItem: NewRequestDevice): boolean {
		if (!!dataItem.deviceTypeId &&
			!!dataItem.deviceSupplierId &&
			!!dataItem.deviceConditionId &&
			!!dataItem.selectedNomenclature && !!dataItem.selectedNomenclature.nomenclature &&
			!!dataItem.warehouseCellId &&
			!!dataItem.deviceConditionId)
			return false;
		
		return true;
	}

	//#endregion

	private loadGridData(): void {
		this.gridLoading = true;

		this.requestsService.getById(this.requestId).subscribe(vm => {

			if (!!vm.entity.requestDeviceWarehouseRefills
				&& vm.entity.requestDeviceWarehouseRefills.length > 0) {

				vm.entity.requestDeviceWarehouseRefills.forEach(f => {
					if (!!f.comment) {
						f.commentObject = this.getCommentObject(f.comment);
					}

					f.isMkInitType = vm.entity.initTypeId === <number>RequestInitTypeEnum.blockChain;
				});

				this.requestDeviceWarehouseRefills = vm.entity.requestDeviceWarehouseRefills;
			}

			this.loadRequestCollisionDevicesGridSettings();
			this.loadRequestConfirmRepairDevicesGridSettings();
			this.loadRequestWarehouseTransferDevicesGridSettings();
			this.loadRequestWarehouseTransferDevicesByNomenclatureGridSettings();
			this.loadRequestWarehouseRefillDevicesGridSettings();
			this.loadRequestWarehousePostingDevicesGridSettings();

			this.request = vm.entity;

			this.gridLoading = false;
		}, () => this.gridLoading = false);

		this.loadRequestDevices();
	}

	getCommentObject(json: string): any {
		return JSON.parse(json);
	}

	private CheckAllDevicesReservedForService(devices: NewRequestDevice[]) {
		if (this.isServiceLogic 
			&& devices.every(e => !!e.reserveSuccess)
			&& devices.every(e => !e.manuallyAdded)) {
				this.canEditRow = false;
				this.canRemoveRow = false;
				this.addButtonEnabled = false;
				this.enabled = false;
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.enabled && changes.enabled.currentValue) {
			this.enableAddDevice = changes.enabled.currentValue;
			this.enableRequestReserve = changes.enabled.currentValue && this.requestTypeId !== RequestType.service && this.requestTypeId !== RequestType.uninstall;
		}

		if (changes.requestTypeId && changes.requestTypeId.currentValue) {
			this.currentRequestTypeId = changes.requestTypeId.currentValue;
		}
	}

	public loadRequestDevices() {
		this.reserveRequested = true;
		if (this.loadUninstall) {
			this.loadRequestUninstallDevices();
		} else if (this.loadInstallAndUninstall) {
			this.loadRequestDevicesCore();
			this.loadRequestUninstallDevices();
		} else {
			this.loadRequestDevicesCore();
		}
	}

	public showComment(requestDeviceWarehouseRefillId: number, element: HTMLElement) {
		if (!element.title) {
			var commentRequest = this.devicesService.getCommentByRequestDeviceWarehouseActionId(requestDeviceWarehouseRefillId);
			commentRequest.subscribe(comment => {
				if (comment) {
					setTimeout(() => {
						element.title = comment;
					});
				}
			});
		}
	}

	get loadUninstall(): boolean {
		return this.isUninstall
		|| this.isReceiveToWarehouseMk;
	}

	get loadInstallAndUninstall(): boolean {
		return this.isReplacementMk 
		|| this.isExpertiseMk
		|| this.isIssueAndReceiveToWarehouseMk
		|| this.isServiceOrReplaceMk
		|| this.isServiceWithReplacePinPad
		|| this.isReplacementNotMk;
	}

	get excludeCancellatedReserveFromSelectedDevices(): boolean { 
		return this.requestTypeId == RequestType.install || this.requestTypeId == RequestType.changeConfig;
	}

	loadRequestDevicesCore() {
		if (RequestType.uninstall === this.currentRequestTypeId) {
			
			this.requestsService.getRequestUninstallDevices(this.requestId)
				.subscribe(items => {
					this.requestUninstallDevices = items;
					this.reserveRequested = false;

					this.loadRequestUninstallDevicesGridSettings();
					this.gridLoading = false;
				}, () => this.gridLoading = false);

		} else {

			this.requestsService.getRequestDevices(this.requestId)
				.subscribe(items => {

					let itemsFiltered = items.filter(f => f.reserveResultId !== <number>ReserveResult.reserveCanceled);

					this.requestDevicesCount = itemsFiltered.length;
					this.isShowReserveBtn = itemsFiltered.length != 0 && itemsFiltered.every(x => (this.request.initTypeId == RequestInitTypeEnum.blockChain && x.deviceTypeId === <number>DeviceTypeEnum.consumables) || !!x.serialNumber || !!x.nomenclature);

					this.requestDevicesWithOtherWarehouseArea = itemsFiltered
						.filter(f => f.warehouseAreaMoveFromIntegraCode !== this.targetRequestWarehouseAreaIntegraCode
							&& this.filterDevicesMoveFrom(f));

					items.forEach(f => {
						if (!!f.warehouseAreaMoveFromId) {
							f.isOtherWarehouseAreaFrom = f.warehouseAreaMoveFromId !== this.targetRequestWarehouseAreaId;
						}
					});

					this.requestDevices = items;
					this.reserveRequested = false;

					this.loadRequestDevicesGridSettings();
					this.gridLoading = false;
				}, () => this.gridLoading = false);
		}
	}

	loadRequestWarehouseTransferDevicesByNomenclature(){	
		this.gridLoading = true;
		return this.requestDeviceWarehouseRefillsService.getRequestDeviceWarehouseRefillsWithParts(this.requestId)
			.subscribe(items => {
				this.requestDeviceWarehouseRefills = items.data;
				this.requestWarehouseTransferDevicesGridData = process(this.requestDeviceWarehouseRefills, this.requestWarehouseTransferDevicesGridSettings.state);
				
				this.loadRequestWarehouseTransferDevicesByNomenclatureGridSettings();

				this.gridLoading = false;
			}, () => this.gridLoading = false);
	}

	loadRequestUninstallDevices(){	
		return this.requestsService.getRequestUninstallDevices(this.requestId)
			.subscribe(items => {
				this.requestUninstallDevices = items;
				this.reserveRequested = false;

				this.loadRequestUninstallDevicesGridSettings();
				this.gridLoading = false;
			}, () => this.gridLoading = false);
	}

	public createNewUninstallDevice(isInWork: boolean): any {

		const self = this;

		return function () {
			const device = new NewRequestDevice();
			device.requestId = self.requestId;
			if (!isInWork && (
				self.isReplacementMk
				|| self.isServiceOrReplaceMk
				|| self.isServiceWithReplacePinPad
				|| self.isReplacementNotMk
				|| self.isUninstall)) {
				device.deviceStatusId = DeviceStatus.inRequest;
			}

			if (isInWork &&
				(self.currentRequestTypeId === self.requestTypes.uninstall ||
				self.currentRequestTypeId === self.requestTypes.changeConfig ||
				self.currentRequestTypeId === self.requestTypes.service)) {
				device.deviceStatusId = DeviceStatus.receivedAtWarehouse;

				if (self.request.customerContragentId !== <number>CustomerEnum.rtk)
					device.deviceConditionId = DeviceConditionEnum.used;
			}

			if (device.isDeviceBroken)
				device.deviceConditionId = DeviceConditionEnum.broken;

			device.deviceConditions = !!self.deviceConditionsSource
				? self.deviceConditionsSource
					.filter(x => x.deviceConditionTypeId == <number>DeviceConditionEnum.broken
						|| x.deviceConditionTypeId == <number>DeviceConditionEnum.used)
					.map(x => new KeyValueObject(x.deviceConditionId, x.name))
				: [];

			return device;
		};
	}

	get createNewDevice(): any {

		const self = this;

		return function () {
			const device = new NewRequestDevice();
			device.requestId = self.requestId;
			if (self.isUninstall) {
				device.deviceStatusId = DeviceStatus.inRequest;
			}
			return device;
		};
	}

	public editHandler({ sender, rowIndex, dataItem }) {
		if (this.lastEditRowIndex !== rowIndex) {
			this.lastEditRowIndex = rowIndex;
			this.gridEditing = false;

			if (!!this.lastEditRowIndex) {
				sender.closeRow(this.lastEditRowIndex);
			}
		}

		if (!this.gridEditing) {
			this.gridEditing = true;
			const rd: NewRequestDevice = dataItem as NewRequestDevice;
			this.fillSupplierDevices(rd.deviceSupplierId);
			sender.editRow(rowIndex);
			this.editLogic(rd);
		}
	}

	editLogic(dataItem): void {
		const rd: NewRequestDevice = dataItem as NewRequestDevice;
		if (!rd.serialNumber || this.requestTypeId === <number>RequestType.installCRE || this.requestTypeId === <number>RequestType.changeConfigCRE) {
			this._refreshDeviceNomenclatures(rd, rd);
		} else if (this.isServiceLogic && this.enabled) {
			if (!this.isMkInitType) {
				this._refreshDeviceNomenclatures(dataItem, dataItem);
			}
		} else if (!this.isServiceLogic) {
			if (!this.isMkInitType && !this.isWarehouseLogic) {
				this._refreshDeviceNomenclatures(dataItem, dataItem);
			}
		}

		if (dataItem.nomenclature) {
			this.onChangeNomenclature(dataItem.nomenclature, dataItem, false);
		}
	}

	public cancelHandler({ sender, rowIndex }) {
        sender.closeRow(rowIndex);
		if (this.lastEditRowIndex === rowIndex) {
			this.lastEditRowIndex = null;
		}
		this.gridEditing = false;
    }

	public addHandler(args: AddEvent): void {

		var reservedResults = [
			<number>ReserveResult.pendingIssueToEngineer,
			<number>ReserveResult.reserveRequest,
			<number>ReserveResult.booked
		];
		if (this.requestDevices.find(x => x.warehouseMoveFromId 
				&& x.warehouseMoveFromId != this.request.defaultScWarehouseId 
				&& reservedResults.includes(x.reserveResultId))){
				this.notificationService.error({
					title: 'Ошибка добавления устройства',
					message: 'В заявке есть резерв по старому СЦ, добавления нового резерва невозможно',
					notificationType: NotificationType.SweetAlert
				});
			return;
		}
		this.gridEditing = true;
		if (!!this.lastEditRowIndex) {
			args.sender.closeRow(this.lastEditRowIndex);
			this.lastEditRowIndex = null;
		}

		this.lastEditRowIndex = args.rowIndex;

		const device = new NewRequestDevice();
		device.requestId = this.requestId;
		if (this.isUninstall) {
			device.deviceStatusId = DeviceStatus.inRequest;
		}

		args.sender.addRow(device);
	}

	public cellClick({ sender, columnIndex, rowIndex, dataItem, column }: any): void {
		const rd: NewRequestDevice = dataItem as NewRequestDevice;
		if (this.requestTypeId !== RequestType.uninstall) {
			if (columnIndex > 6) {
				return;
			}
		}

		if (!this.gridEditing
			&& this.canEditRow
			&& this.canEditDeviceRow(dataItem)) {

			this.gridEditing = true;
			sender.editRow(rowIndex);
			this.editLogic(dataItem);
		}
	}

	warehouseRefillsCellClick({ sender, columnIndex, rowIndex, dataItem }: any): void {
		if (!this.gridEditing && this.canEditRow) {

		}
	}

	snInputValueChanged(dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto): void {
		if (this.lastEditSerialNumber !== dataItem.serialNumber) {

			if (this.requestWarehouseTypes.includes(this.request.requestTypeId) && (!dataItem || !dataItem.serialNumber || dataItem.serialNumber.trim() == '')) {
				return;
			}

			this.lastEditSerialNumber = dataItem.serialNumber
			dataItem.nomenclature = null;

			let refillDataItem = dataItem as RequestDeviceWarehouseRefillDto;
			if (refillDataItem) {
				if (!dataItem || !dataItem.serialNumber || dataItem.serialNumber.trim() == '') {
					return;
				}
				const warehouseDataItem = dataItem as RequestDeviceWarehouseRefillDto;
				if (warehouseDataItem.requestDeviceWarehouseRefillId && warehouseDataItem.requestDeviceWarehouseRefillId != 0) {
					dataItem.requestDeviceId = warehouseDataItem.requestDeviceWarehouseRefillId;
				}
			}

			this._refreshDeviceNomenclatures(dataItem, dataItem, true);
		}
	}

	canEditDeviceRow(dataItem) {
		return this.canEditRow &&
			dataItem.reserveResultId !== <number>ReserveResult.pendingIssueToEngineer &&
			dataItem.reserveResultId !== <number>ReserveResult.equipmentReceivedByEngineer &&
			dataItem.reserveResultId !== <number>ReserveResult.booked &&
			dataItem.reserveResultId !== <number>ReserveResult.requestForReserveCancelling &&
			dataItem.reserveResultId !== <number>ReserveResult.reserveCanceled &&
			dataItem.reserveResultId !== <number>ReserveResult.returnedToWarehouse;
	}

	reserveDeviceRowCallback(context: RowClassArgs) {
		return context.dataItem.reserveResultId == ReserveResult.requestForReserveCancelling ||
			context.dataItem.reserveResultId == ReserveResult.reserveCanceled ||
			context.dataItem.reserveResultId == ReserveResult.returnedToWarehouse
			? 'greyRow' : {};
	}

	public requestDeviceSaveHandler(obj: any) {
		this.saveGridChanges(obj, this.isUninstall);
	}

	requestUninstallDeviceSaveHandler(obj: any) {
		this.saveGridChanges(obj, true);
	}

	saveGridChanges(obj: any, isUninstall: boolean) {
		this.reserveRequested = true;
		const dataItem = obj.dataItem as NewRequestDevice;

		dataItem.deviceModelId = dataItem.deviceModelId === 0 ? null : dataItem.deviceModelId;
		dataItem.deviceTypeId = dataItem.deviceTypeId === 0 ? null : dataItem.deviceTypeId;
		
		const isNew = !!dataItem.requestDeviceId;

		if (!!dataItem.selectedNomenclature && dataItem.selectedNomenclature.nomenclature !== null) {
			dataItem.quality = dataItem.selectedNomenclature.deviceQuality;
			dataItem.nomenclatureWarehouseIntegraCode = dataItem.selectedNomenclature.warehouseIntegraCode;
			dataItem.nomenclature = dataItem.selectedNomenclature.nomenclature;
		} else if (!!!dataItem.selectedNomenclature || dataItem.selectedNomenclature.nomenclature === null) {
			dataItem.quality = null;
			dataItem.nomenclatureWarehouseIntegraCode = null;
			dataItem.nomenclature = null;
		}

		let service = !isUninstall ? this.requestDevicesService : this.requestUninstallDevicesService;

		const observable = isNew
			? service.update(dataItem, 'Устройство обновлено')
			: service.create(dataItem);

		observable.pipe(finalize(() => {
			this.reserveRequested = false;
			this.gridEditing = false;
		}))
			.subscribe(x => {
			if (x.isSuccessful) {
				this.loadRequestDevices();
			}
		});

		obj.dataItem = null;
		obj.sender.closeRow(obj.rowIndex);
		this.deviceNomenclatures = [];
	}

	onChangeNomenclature(event: any, dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto, callForAvaliableSerialNumbersByNomenclature = true): void {		
		if (this.isReserveSerialNumberAvailable &&
			callForAvaliableSerialNumbersByNomenclature &&
			!!dataItem.selectedNomenclature &&
			!!dataItem.selectedNomenclature.nomenclature) {

			this.serialLoading = true;
			this.requestDevicesService.getAvailableSerialNumbers(this.request.requestId, [], [dataItem.selectedNomenclature.nomenclature]).pipe(map(viewModels => {
				return viewModels;
			})).subscribe(res => {
				if (!!res && !!res.data) {				
					let availableSns = res.data.availableSerialNumbers.filter(f => f.nomenclature === dataItem.selectedNomenclature.nomenclature &&
						f.warehouseIntegraCode === dataItem.selectedNomenclature.warehouseIntegraCode &&
						f.deviceQuality == dataItem.selectedNomenclature.deviceQuality);
					if (availableSns.length > 0) {
						dataItem.serialNumber = availableSns.some(s => s.serialNumber === dataItem.serialNumber)
							? dataItem.serialNumber
							: availableSns[0].serialNumber;
					} else {
						dataItem.serialNumber = null;
						this.notificationService.error({
							title: 'Ошибка при получении серийного номера',
							message: 'Для выбранной номенклатура и ее склада нет доступных серийных номеров',
							notificationType: NotificationType.Toast
						});
					}
					this.snInputValueChanged(dataItem);
				}
			}, () => {this.serialLoading = false;}, () => {this.serialLoading = false;});
		}

		if (!!dataItem.selectedNomenclature && !!dataItem.selectedNomenclature.nomenclature) {
			dataItem.deviceTypeId = dataItem.selectedNomenclature.deviceType;

			dataItem.deviceConnectionTypeId = dataItem.selectedNomenclature.connectionType.length > 0
				? dataItem.deviceConnectionTypeId = dataItem.selectedNomenclature.connectionType.length == 1
					? dataItem.selectedNomenclature.connectionType[0]
					: dataItem.selectedNomenclature.connectionType.some(ct => ct === dataItem.deviceConnectionTypeId)
						? dataItem.deviceConnectionTypeId
						: null
				: null;

			let deviceSupplier = this.deviceSuppliers.find(m => m.name.toLowerCase() === dataItem.selectedNomenclature.vendor.toLowerCase())
			dataItem.deviceSupplierId = !!deviceSupplier ? deviceSupplier.id : null;
			
			this.deviceSupplierChangeWithoutRefreshNomenclatures(dataItem.deviceSupplierId, dataItem);

			this.handleDeviceModelDropdownOpen(dataItem.deviceSupplierId);

			let deviceModel = this.deviceModels.find(m => m.name.toLowerCase() === dataItem.selectedNomenclature.model.toLowerCase())
			dataItem.deviceModelId = !!deviceModel ? deviceModel.id : null;
		}
	}

	warehouseRefillSelectNomenclature(dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto) {
		if (!!dataItem.selectedNomenclature && !!dataItem.selectedNomenclature.nomenclature) {
			dataItem.deviceTypeId = dataItem.selectedNomenclature.deviceType;

			dataItem.deviceConnectionTypeId = dataItem.selectedNomenclature.connectionType.length > 0
				? dataItem.deviceConnectionTypeId = dataItem.selectedNomenclature.connectionType.length == 1
					? dataItem.selectedNomenclature.connectionType[0]
					: dataItem.selectedNomenclature.connectionType.some(ct => ct === dataItem.deviceConnectionTypeId)
						? dataItem.deviceConnectionTypeId
						: null
				: null;

			let deviceSupplier = this.deviceSuppliers.find(m => m.name.toLowerCase() === dataItem.selectedNomenclature.vendor.toLowerCase())
			dataItem.deviceSupplierId = !!deviceSupplier ? deviceSupplier.id : null;
			
			this.deviceSupplierChangeWithoutRefreshNomenclatures(dataItem.deviceSupplierId, dataItem);

			this.handleDeviceModelDropdownOpen(dataItem.deviceSupplierId);

			let deviceModel = this.deviceModels.find(m => m.name.toLowerCase() === dataItem.selectedNomenclature.model.toLowerCase())
			dataItem.deviceModelId = !!deviceModel ? deviceModel.id : null;
		}
	}

	public isConnectionTypeDisabled(dataItem: NewRequestDevice): boolean {
		return !!dataItem.serialNumber &&
			!!dataItem.selectedNomenclature &&
			!!dataItem.selectedNomenclature.connectionType &&
			dataItem.selectedNomenclature.connectionType.length === 1
			
	}

	nomenclatureItemDisabled(itemArgs: { dataItem: IFreeNomenclature; index: number }) {
		return itemArgs.dataItem.nomenclature !== null && !itemArgs.dataItem.isWarehouseFoundedInSd;
	}

	showNomenclatureDropdown(dataItem: NewRequestDevice, isNew: boolean): boolean {
		// if (this.requestTypeId === <number>RequestType.installCRE || this.requestTypeId === <number>RequestType.changeConfigCRE) {
		// 	if (!dataItem.reserveResult)
		// 		return true;
		// }

		// return !dataItem.serialNumber;
		if (!this.isMkInitType) {
			return this.filterDevicesMoveFrom(dataItem);
		}
	}

	filterDevicesMoveFrom(dataItem: NewRequestDevice): boolean {
		let reserveResults = [
			<number>ReserveResult.notFound,
			<number>ReserveResult.notBooked,
		];

		if (!!!dataItem.reserveSuccess
			&& (!!!dataItem.reserveResultId || reserveResults.includes(dataItem.reserveResultId))) {
			return true;
		}
	}

	changeWarehouseDeviceType(deviceTypeId: number, dataItem: RequestDeviceWarehouseRefillDto) {
		if (!this.isIdPart(deviceTypeId)) {
			dataItem.quantity = 1;
		}
	}

	invalidNomenclatureBySn(dataItem: NewRequestDevice): boolean {
		return !!dataItem.serialNumber && (!dataItem.selectedNomenclature || !!!dataItem.selectedNomenclature.nomenclature) && !!!dataItem.nomenclature;
	}

	snInputDisabled(dataItem: NewRequestDevice): boolean {
		let deviceType = this.deviceTypesFull.find(f => f.deviceTypeId == dataItem.deviceTypeId);
		let isPart = !!deviceType && deviceType.deviceKindId === <number>DeviceKindEnum.component;
		return !!dataItem.reserveResult || this.idPart(dataItem);
	}

	idPart(dataItem: NewRequestDevice): boolean {
		return this.isIdPart(dataItem.deviceTypeId);
	}

	isIdPart(deviceTypeId: number) {
		let deviceType = this.deviceTypesFull.find(f => f.deviceTypeId == deviceTypeId);
		return !!deviceType && deviceType.deviceKindId === <number>DeviceKindEnum.component;
	}

	isIdPartWarehouseRefill(dataItem: RequestDeviceWarehouseRefillDto) {
		return ((dataItem.deviceTypeId == null || dataItem.deviceTypeId == 0) && dataItem.requestDeviceWarehouseRefillId != null && dataItem.requestDeviceWarehouseRefillId != 0) 
			|| this.isIdPart(dataItem.deviceTypeId);
	}

	isReserveCancelled(dataItem: NewRequestDevice): boolean {
		return dataItem.reserveResultId == ReserveResult.reserveCanceled ||
			dataItem.reserveResultId == ReserveResult.requestForReserveCancelling ||
			dataItem.reserveResultId == ReserveResult.returnedToWarehouse;
	}

	get isBySerialNumbers(): boolean {
		return this.requestDevices.filter(f => !this.idPart(f)).some(s => !!s.serialNumber);
	}

	removeOrWithdrawReservedRequestDevice(dataItem: NewRequestDevice): void {
		if (dataItem.reserveResult === this.reservedStatus || dataItem.reserveResult === this.awaitingDeliveryOfEquipment || dataItem.reserveResult === this.engineerReceivedDevices) {
			this.withdrawReservedRequestDevice(dataItem);
		} else {
			this.removeRequestDevice(dataItem);
		}
	}

	removeRequestDevice(dataItem: NewRequestDevice): void {
		this.requestDevicesService.remove(`${(dataItem).requestDeviceId}`, 'Устройство удалено из заявки', '')
			.subscribe(x => {
				if (x.isSuccessful) {
					this.loadRequestDevices();
				}
			});
	}

	withdrawReservedRequestDevice(dataItem: NewRequestDevice): void {
		const dialogRef = this.dialogService.open({
			title: 'Запрос на снятие с резерва',
			content: SetCancelReserveReason,
			width: 400
		});

		const setCancelReserveReasonForm = <SetCancelReserveReason>dialogRef.content.instance;
		setCancelReserveReasonForm.onContinueEvent.subscribe(
			data => {
					this.requestDevicesService.withdrawResrvedDevice(dataItem.requestId, dataItem.requestDeviceId, data.commentText)
						.subscribe(result => {
							if (result.isSuccessful) {
								this.reserveRequested = false;
								dataItem.reserveResult = this.withdrawReservedStatus;
							}
						});
			});
	}

	public isNotInRequest(statusIds: number[]): boolean {
		return statusIds
			.some((statusId => statusId === <number>DeviceStatus.installed ||
						statusId === <number>DeviceStatus.deInstalled ||
						statusId === <number>DeviceStatus.deliveredToWarehouse ||
						statusId === <number>DeviceStatus.receivedAtWarehouse) as any);
	}

	getNameById(data: KeyValueObject[], id: number): string {
		return this.appService.getNameById(data, id);
	}

	getDeviceModelName(id: number): string {
		const deviceModel = this.deviceModels.find(x => x.id == id);
		return deviceModel ? deviceModel.name : null;
	}

	getWarehouseInfo(area: string, cell: string): string {
		if (!area || area === "") {
			return "";
		}
		if (!cell || cell === "") {
			return `${area}`;
		}
		return `${area}, ${cell}`;
	}

	pickDevice(requestDevice: NewRequestDevice) {

		const dialogRef = this.dialogService.open({
			title: 'Выберите устройство',
			content: DevicesListPage,
			width: '100%'
		});

		const deviceListForm = <DevicesListPage>dialogRef.content.instance;
		deviceListForm.showHeader = false;

		deviceListForm.deviceTypeId = requestDevice.deviceTypeId;
		deviceListForm.deviceManufacturerId = requestDevice.deviceSupplierId;
		deviceListForm.deviceModelId = requestDevice.deviceModelId;

		deviceListForm.onCellClick.subscribe((device: Device) => {
			this.requestsService.linkDevice(requestDevice.requestDeviceId, device.deviceId).subscribe(() => {
				this.loadRequestDevices();
			});
		});
	}

	refreshDevicesList() {
		if (this.categoriesDevicesList) {
			this.categoriesDevicesList.loadData();
		}

		this.loadRequestDevices();
	}

	requestDevicesReserve(): void {

		if (this.requestDevicesCount === 0) {
			this.notificationService.success({
				title: 'Заказ резерва',
				message: 'В заявке отсутствуют данные об оборудовании',
				notificationType: NotificationType.SweetAlert
			});

			return;
		}

		let reserve = () => {
			this.reserveRequested = true;
			this.requestDevicesService.reserveRequestDevices(this.requestId).subscribe((resp) => {
				// получили ответ о резервировании от 1С - обновляем список устройств
				if (resp.data) {
					this.loadRequestDevices();
				}

				this.reserveRequested = false;
			}, () => this.reserveRequested = false);
		};

		if (this.isMkInitType) {
			reserve();
			return;
		}

		let devicesToCheck = this.requestDevices.filter(f => !this.idPart(f) && !this.isReserveCancelled(f));

		if (this.isBySerialNumbers) {
			if (!devicesToCheck.every(s => !s.serialNumber) && devicesToCheck.some(s => !s.serialNumber)) {
				
				this.notificationService.error({
					title: 'Ошибка валидации',
					message: 'У всех устройств должен быть заполнен или отсутствовать серийный номер',
					notificationType: NotificationType.SweetAlert
				});

				return;
			}
		}

		if (this.requestDevices.filter(f => !this.isReserveCancelled(f)).some(s => !!!s.nomenclature)) {
			
			this.notificationService.error({
				title: 'Ошибка валидации',
				message: 'У всех устройств должена быть заполнена номенклатура',
				notificationType: NotificationType.SweetAlert
			});

			return;
		}

		let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
		let snDuplicates = findDuplicates(devicesToCheck.filter(f => !!f.serialNumber).map(m => m.serialNumber));
		
		if (snDuplicates.length > 0) {
			let snDuplicatesMsg = snDuplicates.join('; ');
			this.notificationService.error({
				title: 'Ошибка валидации',
				message: `Обнаружены дубликаты серийных номеров: ${snDuplicatesMsg}`,
				notificationType: NotificationType.SweetAlert
			});

			return;
		}

		let requestDevicesWithOtherWarehouseAreaExistingWarehouses = this.requestDevicesWithOtherWarehouseArea
			.filter(f => !!f.warehouseAreaMoveFromName);

		if (requestDevicesWithOtherWarehouseAreaExistingWarehouses.length > 0) {

			let msgArray: string[] = [];

			requestDevicesWithOtherWarehouseAreaExistingWarehouses.forEach(fe => {
				msgArray.push(`${fe.nomenclature} со склада ${fe.warehouseAreaMoveFromName}`);
			});

			let msg = msgArray.join('; ');

			this.notificationService.confirmation({
				title: 'Заказ резерва',
				message: `Часть товара отсутствует на локальном складе СЦ. В случае продолжения процесса будут созданы перемещения следующих товаров: ${msg}`,
				type: 'question',
				confirmButtonText: 'Создать перемещение',
				cancelButtonText: 'Отмена',
				showCloseButton: false
			}, () => {

				this.reserveRequested = true;

				this.newRequestsService.createLinkedWarehouseTransferRequest(this.request.requestId)
					.subscribe(res => {
						if (res.isSuccessful) {

							this.notificationService.success({
								title: 'Заказ резерва',
								message: 'Заявки на перемещение успешно созданы',
								notificationType: NotificationType.SweetAlert
							});

							reserve();
						}
					}, () => this.reserveRequested = false);

			}, () => {});
		} else {
			reserve();
		}
		
	}

	getDeviceModels(supplierId: number): any[] {
		if (this.deviceModels == null || !supplierId) {
			return [];
		}
		let result = this
			.deviceModels
			.filter(x => x.parentId === supplierId);

		return result;
	}

	public deviceSupplierChangeWithoutRefreshNomenclatures(deviceSupplierId: number, dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto): void {
		dataItem.deviceModelId = null;
		this.fillSupplierDevices(deviceSupplierId);
	}

	public deviceSupplierChange(deviceSupplierId: number, dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto): void {
		dataItem.deviceModelId = null;
		this.fillSupplierDevices(deviceSupplierId);
		this.refreshDeviceNomenclatureActions(dataItem);
	}

	public deviceTypeChange(deviceTypeId: number, dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto): void {
		this.refreshDeviceNomenclatureActions(dataItem);
	}
	public deviceConnectionTypeChange(deviceConnectionTypeId: number, dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto): void {
		this.refreshDeviceNomenclatureActions(dataItem);
	}
	public deviceModelChange(deviceSupplierId: number, dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto): void {
		this.refreshDeviceNomenclatureActions(dataItem);
	}
	private refreshDeviceNomenclatureActions(dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto): void {
			dataItem.nomenclature = null;
			if (dataItem instanceof NewRequestDevice) {
				dataItem.quality = null;
				dataItem.nomenclatureWarehouseIntegraCode = null;
			}
			else {
				if (dataItem.requestDeviceWarehouseRefillId && dataItem.requestDeviceWarehouseRefillId != 0) {
					dataItem.requestDeviceId = dataItem.requestDeviceWarehouseRefillId;
				}
			}

			this._refreshDeviceNomenclatures(dataItem, dataItem);
	}

	nomenclaturesError: string;

	nomenclatureForReserveFieldOpenEvent(): void {
		if (!!this.nomenclaturesError)
		{
			this.notificationService.info({
				title: 'Сообщение из 1С',
				message: `${this.nomenclaturesError}`,
				notificationType: NotificationType.SweetAlert, showConfirmButton: true
			});

			this.nomenclaturesError = null;
		}
	}

	private _refreshDeviceNomenclatures(requestDeviceQuery: IRequestDeviceNomenclatureQuery1c, dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto,
		serialNumberChanged: boolean = false): void {
		this.nomenclatureLoading = true;

		if (!!dataItem && !!dataItem.serialNumber) {
			this.requestDevicesService.getAvailableSerialNumbers(this.requestId
				, [dataItem.serialNumber]
				, []).pipe(map(viewModels => {

					viewModels.data.availableSerialNumbers.forEach(x => {
						let deviceQuality = !!x.deviceQuality ? x.deviceQuality : "Качество не указано";

						if (x.warehouseIntegraCode === this.targetRequestWarehouseAreaIntegraCode) {
							x.fullName = `${x.nomenclature} [${x.availableForReserve} шт., ${deviceQuality}]`;
						} else {
							x.fullName = `${x.nomenclature} [${x.availableForReserve} шт., ${deviceQuality}] (${x.warehouseAreaName})`;
						}
					});

					return viewModels;
				})).subscribe(res => {

				if (serialNumberChanged && res.data.unavailableSerialNumbers.length > 0) {
					this.notificationService.info({
						title: 'Информация',
						message: `С/н ${res.data.unavailableSerialNumbers[0].serialNumber} не может быть зарезервирован.<br>Статус: ${res.data.unavailableSerialNumbers[0].status}`,
						notificationType: NotificationType.SweetAlert, enableHtml: true, showConfirmButton: true
					});
				}

				if (!!res && !!res.data) {
					
					this.otherNomenclatureWarehouseArea = false;
					let requestWarehouseAreaDevices = res.data.availableSerialNumbers.filter(flt => flt.serialNumber === dataItem.serialNumber
						&& flt.warehouseIntegraCode === this.targetRequestWarehouseAreaIntegraCode);

					if (requestWarehouseAreaDevices.length === 0) {
						requestWarehouseAreaDevices = res.data.availableSerialNumbers.filter(flt => flt.serialNumber === dataItem.serialNumber
							&& flt.warehouseIntegraCode !== this.targetRequestWarehouseAreaIntegraCode);

						requestWarehouseAreaDevices.length === 0 ? this.otherNomenclatureWarehouseArea = false : this.otherNomenclatureWarehouseArea = true;
					}
					
					let availableSn = res.data.availableSerialNumbers.find(f => f.serialNumber === dataItem.serialNumber);
					
					if (!!availableSn) {
						this.deviceNomenclatures = [availableSn];
						if (!this.requestWarehouseTypes.includes(this.request.requestTypeId)) {
							this.deviceNomenclatures.forEach(x => x.disabled = x.warehouseIntegraCode != this.senderWarehouseIntegraCode);
						}

						if (this.requestWarehouseTypes.includes(this.request.requestTypeId)) {
							this.deviceNomenclatures = this.deviceNomenclatures.filter(x => x.warehouseIntegraCode.toLowerCase() == this.request.senderWarehouseAreaIntegraCode.toLowerCase());
						}

						if (!this.otherNomenclatureWarehouseArea && availableSn.warehouseIntegraCode == this.senderWarehouseIntegraCode) {
							dataItem.nomenclature = availableSn.nomenclature;
						}
					} else {
						this.deviceNomenclatures = [];
					}

					this.trySetNomenclature(dataItem);						
				}
			}, () => {this.nomenclatureLoading = false;}, () => {this.nomenclatureLoading = false;});
		} else {
			this.requestDevicesService.getFreeNomenclatures(requestDeviceQuery)
				.pipe(map(viewModels => {

				viewModels.availableNomenclatures.forEach(viewModel => {
					viewModel.nomenclatures.forEach(x=>{
						x.warehouseIntegraCode = viewModel.warehouseIntegraCode;
						let deviceQuality = !!x.deviceQuality ? x.deviceQuality : "Качество не указано";

						if (viewModel.warehouseIntegraCode === this.targetRequestWarehouseAreaIntegraCode) {
							x.fullName = `${x.nomenclature} [${x.availableForReserve} шт., ${deviceQuality}]`;
						} else {
							x.fullName = `${x.nomenclature} [${x.availableForReserve} шт., ${deviceQuality}] (${viewModel.warehouseAreaName})`;
						}
					})
				});
			
				return viewModels;
			})).subscribe(response => {
				if (response.errorCode == 800)
					this.nomenclaturesError = response.errorDescription;

				this.otherNomenclatureWarehouseArea = false;
				let requestWarehouseAreaDevices = response.availableNomenclatures.filter(flt => flt.warehouseIntegraCode === this.targetRequestWarehouseAreaIntegraCode);

				if (requestWarehouseAreaDevices.length === 0) {
					requestWarehouseAreaDevices = response.availableNomenclatures.filter(flt => flt.warehouseIntegraCode !== this.targetRequestWarehouseAreaIntegraCode);

						requestWarehouseAreaDevices.length === 0 ? this.otherNomenclatureWarehouseArea = false : this.otherNomenclatureWarehouseArea = true;
					}

					this.deviceNomenclatures = requestWarehouseAreaDevices.map(p => p.nomenclatures)
						.reduce((a, b) => a.concat(b), []);
					
					if (!this.requestWarehouseTypes.includes(this.request.requestTypeId)) {
						this.deviceNomenclatures.forEach(x => x.disabled = x.warehouseIntegraCode != this.senderWarehouseIntegraCode);
					}

					if (this.requestWarehouseTypes.includes(this.request.requestTypeId)) {
						this.deviceNomenclatures = this.deviceNomenclatures.filter(x => x.warehouseIntegraCode == this.request.senderWarehouseAreaIntegraCode);
					}
					
					this.trySetNomenclature(dataItem);
				}, () => {this.nomenclatureLoading = false;}, () => {this.nomenclatureLoading = false;});
		}
	}

	private trySetNomenclature(dataItem: NewRequestDevice | RequestDeviceWarehouseRefillDto) {
		if (!!dataItem) {
			let freeNomenclature = !!dataItem.selectedNomenclature
				? this.deviceNomenclatures.find(dn => dn.nomenclature == dataItem.selectedNomenclature.nomenclature)
				: null;
			
			if (!!!freeNomenclature) {
				if (this.deviceNomenclatures.length === 1 && !this.deviceNomenclatures[0].disabled) {
					dataItem.selectedNomenclature = this.deviceNomenclatures[0];
					if (this.requestWarehouseTypes.includes(this.request.requestTypeId)) {
						this.warehouseRefillSelectNomenclature(dataItem);
						return;
					}
					this.onChangeNomenclature(this.deviceNomenclatures[0].nomenclature, dataItem);
				} else
					dataItem.selectedNomenclature = null;
			} else {
				dataItem.selectedNomenclature = freeNomenclature;
				if (this.requestWarehouseTypes.includes(this.request.requestTypeId)) {
					this.warehouseRefillSelectNomenclature(dataItem);
					return;
				}
				this.onChangeNomenclature(freeNomenclature.nomenclature, dataItem);
			}
		}
	}


	fillSupplierDevices(deviceSupplierId: number){
		this.supplierDevices = deviceSupplierId == null ? [] : this.deviceModels.filter(x => x.parentId === deviceSupplierId);
	}
	public unInstallDeviceRowClass({ dataItem }: RowClassArgs) {
		const requestDevice = <NewRequestDevice>dataItem;
		return {
			'text-success': (requestDevice.deviceStatusId === DeviceStatus.installed || requestDevice.deviceStatusId === DeviceStatus.inRequest)  && requestDevice.matchedDeviceId,
			'text-danger': requestDevice.deviceStatusId === DeviceStatus.inRequest && !requestDevice.matchedDeviceId
		};
	}

	public getInstallDeviceClass(requestDevice: NewRequestDevice): any {
		return {
			'text-success': (requestDevice.deviceStatusId === DeviceStatus.installed || requestDevice.deviceStatusId === DeviceStatus.inRequest)  && requestDevice.matchedDeviceId,
			'text-danger': requestDevice.deviceStatusId === DeviceStatus.inRequest && !requestDevice.matchedDeviceId
		};
	}
	public addAttachments(dataItem: NewRequestDevice) {
		const dialogRef1 = this.dialogService.open({
			content: DeviceAttachmentsComponent,
			width: '90%'
		});

		const deviceAttachmentsComponent = <DeviceAttachmentsComponent>dialogRef1.content.instance;
		deviceAttachmentsComponent.requestDeviceId = dataItem.requestDeviceId;
		deviceAttachmentsComponent.requestId = this.requestId;
		deviceAttachmentsComponent.uploadedHandler.subscribe(() => this.loadRequestUninstallDevices());
	}


	get isCanEditRequestDevices() {
		return this.securityService.isCanEditRequestDevices() || this.securityService.isAdmin();
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	public get isEngineer() {
		return this.securityService.isEngineer();
	}

	public get canSeeOtherDevices() {
		return !this.isMobileDevice ||
		(this.isMobileDevice && this.isEngineer
			&& (this.currentRequestTypeId === this.requestTypes.uninstall || this.currentRequestTypeId === this.requestTypes.changeConfig));
	}

	public isConnectionTypeRequired(dataItem: NewRequestDevice): boolean {
		return this.requestTypeId !== 4
			&& this.requestTypeId !== 2
			&& (dataItem.deviceTypeId === 2 || dataItem.deviceTypeId === 3 || dataItem.deviceTypeId === 14)
			&& (!this.serialEnough || (!dataItem.serialNumber && !dataItem.nomenclature));
	}

	//#region Кнопка "Удалить документы 1С"

	get is1CDeleteButtonVisible() {
		return this.securityService.isCanEditRequestDevices() || this.securityService.isAdmin();
	}

	get is1CDeleteButtonDisabled() {
		let correctTypes: RequestType[] = [
			RequestType.install,
			RequestType.installWithDismantle,
			RequestType.changeConfig,
			RequestType.issueFromWarehouse,
			RequestType.serviceOrReplace,
			RequestType.installCRE,
			RequestType.changeConfigCRE,
			RequestType.issueAndReceiveToWarehouse,
			RequestType.expertise,
			RequestType.expressExpertise,
		];

		if (!correctTypes.includes(this.request.requestTypeId)) {
			return true;
		}

		let wrongStatuses: RequestStatus[] = [
			RequestStatus.Completed,
			RequestStatus.Cancelled,
			RequestStatus.CancelledBackToWareHouse
		];

		if (wrongStatuses.includes(this.request.statusId)) {
			return true;
		}

		if (!this.requestDevices.some(device => device.reserveResultId != ReserveResult.reserveCanceled)) {
			return true;
		}
		
		return false;
	}

	deleteDocumentsRequest(): void {
		const dialogRef = this.dialogService.open({
			title: 'Удаление документов из 1С ERP',
			content: SetComment,
			width: 400
		});

		const setDeleteDocumentsReasonForm = <SetComment>dialogRef.content.instance;
		setDeleteDocumentsReasonForm.hideCommentKind = true;
		setDeleteDocumentsReasonForm.onContinueEvent.subscribe(
			data => {
					this.requestDevicesService.deleteDocuments(this.requestId, data.commentText)
						.subscribe(result => {
							if (result.isSuccessful) {
								this.loadRequestDevicesCore();
							}
						});
			});
	}

	//#endregion

	get isWarehouseTransfer(): boolean {
		return [RequestType.warehouseTransfer,
			RequestType.foreighWarehouseTransfer,
			RequestType.internalWarehouseTransfer].includes(this.requestTypeId);
	}

	get isWarehouseRefillNotMk(): boolean {
		return !this.isMkInitType && [RequestType.warehouseRefill].includes(this.requestTypeId);
	}

	public get isMkInitType(): boolean {
		return this.initTypeId === <number>RequestInitTypeEnum.blockChain;
	}

	reParseArticle(requestDeviceWarehouseRefill: RequestDeviceWarehouseRefillDto) {
		var requestDeviceId = null;
		var requestDevicePartId = null;

		if (requestDeviceWarehouseRefill.isDevicePart) {
			requestDevicePartId = requestDeviceWarehouseRefill.requestDeviceWarehouseRefillId;
		} else {
			requestDeviceId = requestDeviceWarehouseRefill.requestDeviceWarehouseRefillId;
		}

		this.reParseRequestDeviceArticlesInProgress.push(requestDeviceWarehouseRefill.requestDeviceWarehouseRefillId);

		this.requestDevicesService.reParseRequestDevices(
			this.requestId,
			requestDeviceId,
			requestDevicePartId
		).subscribe(x => {
			this.requestDeviceWarehouseRefills = x.data;

			const index = this.reParseRequestDeviceArticlesInProgress.indexOf(requestDeviceWarehouseRefill.requestDeviceWarehouseRefillId, 0);
			if (index > -1) {
				this.reParseRequestDeviceArticlesInProgress.splice(index, 1);
			}

		});

	}

	public warehouseRefillRowClass({ dataItem }: any) {

		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;

		return {
			"bg-danger": requestDevice.isMkInitType && requestDevice.isArticleMappedCorrect === false,
			"bg-warning": requestDevice.isMkInitType && requestDevice.hasAcceptedIn1C === false,
			"bg-success": requestDevice.isMkInitType && requestDevice.hasAcceptedIn1C === true,
		};
	}

	get createRequestDeviceWarehouseRefillDto(): any {

		return function () {
			var contact = new RequestDeviceWarehouseRefillDto();
			contact.quantity = 1;
			return contact;
		};
	}

	
	public warehouseRefillSaveHandler(obj: any) {
		var dataItem = <RequestDeviceWarehouseRefillDto>obj.dataItem;

		this.devicesService.updateWarehouseActionArticle(dataItem.requestDeviceWarehouseRefillId
			, dataItem.isDevicePart
			, dataItem.articleId).subscribe(x => {

				if (x.isSuccessful) {
					this.loadGridData();
				}
			});

		obj.dataItem = null;
	}

	warehouseRefillConfirmRemove(dataItem: RequestDeviceWarehouseRefillDto): void {
		this.notificationService
			.confirmation({
				message: 'Вы уверены, что хотите удалить это оборудование из заявки?"',
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: false
			}, () => {
				this.warehouseRefillRemoveConfirmationSubject.next(true);

				this.devicesService.deleteWarehouseActionArticle(dataItem.requestDeviceWarehouseRefillId
					, dataItem.isDevicePart).subscribe(x => {

						if (x.isSuccessful) {
							this.loadGridData();
						}
						this.warehouseRefillItemToRemove = null;
					});

			}, () => {
				this.warehouseRefillRemoveConfirmationSubject.next(false);
				this.warehouseRefillItemToRemove = null;
			});
	}

	public warehouseRefillRemoveConfirmation(dataItem): Subject<boolean> {
		this.warehouseRefillItemToRemove = dataItem;

		return this.warehouseRefillRemoveConfirmationSubject;
	}

	checkArticlesBy1C(): void {
			this.warehousesService.mapWarehouseAreas(this.mapWarehouseAreasCommand).subscribe(mw => {
			if (mw.isSuccessful) {
				
				this.devicesService.checkWarehouseActionsBy1c(this.requestId).subscribe(x => {
					if (x.isSuccessful) {

						this.loadGridData();

						this.notificationService.success({
							title: 'Прверка артикулов',
							message: 'Успех!',
							notificationType: NotificationType.SweetAlert
						});
					}
				});
			}
		});
	}

	public get isByNomenclature(): boolean {
		return !this.isMkInitType && this.requestDeviceWarehouseRefills.some(s => !!s.nomenclature);
	}

	editAllArticles(): void {
		if (this.isAllArticlesEditing) {
			this.isAllArticlesEditing = false;
			this.warehouseRefillGrid.data["data"].forEach((_, i) => {
				this.warehouseRefillGrid.closeRow(i);
			});
		} else {
			this.isAllArticlesEditing = true;
			this.warehouseRefillGrid.data["data"].forEach((_, i) => {
				this.warehouseRefillGrid.editRow(i);
			});
		}
	}

	getOneOfTheEntityFields(field1: string, field2: string): any {
		let entity = this.request;
		return entity[field1] ? entity[field1] : entity[field2];
	}

	public get isCheckDeviceAvailableToMoveVisible(): boolean {
		switch (this.requestTypeId) {
			case <number>RequestType.confirmRepair:
				return this.request.statusId === <number>RequestStatus.pendingApproval
					|| this.request.statusId === <number>RequestStatus.awaitingConfirmationFromEth;
			case <number>RequestType.warehouseTransfer:
			case <number>RequestType.foreighWarehouseTransfer:
			case <number>RequestType.internalWarehouseTransfer:
				return this.request.statusId === <number>RequestStatus.AssignedToWarehouse
					|| this.request.statusId === <number>RequestStatus.awaitingCollectionForMoving
					|| this.request.statusId === <number>RequestStatus.collectionForMoving;
			default:
				break;
		}
	}

	checkDeviceAvailableToMove(): void {
		this.isCheckDeviceAvailableToMoveProgress = true;

		this.warehousesService.mapWarehouseAreas(this.mapWarehouseAreasCommand).subscribe(mw => {
			if (mw.isSuccessful) {
				this.requestDevicesService.checkDeviceAvailableToMove(this.requestId).subscribe(res => {
					if (res.isSuccessful) {
						this.loadGridData();
						this.notificationService.success({
							title: 'Обновление статуса',
							message: 'Статус успешно обновлен',
							notificationType: NotificationType.SweetAlert
						});
						this.isCheckDeviceAvailableToMoveProgress = false;
					} else {
						this.notificationService.error({
							title: 'Ошибка обновления статуса',
							message: res.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
						this.isCheckDeviceAvailableToMoveProgress = false;
					}
				}, () => {
					this.isCheckDeviceAvailableToMoveProgress = false;
				});
			} else {
				this.isCheckDeviceAvailableToMoveProgress = false;
			}
		}, () => {
			this.isCheckDeviceAvailableToMoveProgress = false;
		});
	}

	get warehouseTransferRequestSerialNumberExists(): boolean {
		return this.request !== null
			&& this.requestDeviceWarehouseRefills !== null
			&& this.requestDeviceWarehouseRefills
				.some(s => s.requestSerialNumber !== null && s.requestSerialNumber.trim() !== "");
	}

	notFound(dataItem: any): boolean {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;
		return requestDevice.isArticleMappedCorrect === true
			&& (requestDevice.quantityAvailable === 0 || requestDevice.deviceStatusId === <number>DeviceStatus.notConfirmedInEth);
	}

	availableToMove(dataItem: any): boolean {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;
		return requestDevice.isArticleMappedCorrect === true
			&& requestDevice.quantityAvailable > 0
			&& requestDevice.quantityAvailable >= requestDevice.quantity;
	}

	partiallyMoveable(dataItem: any): boolean {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;
		return requestDevice.isArticleMappedCorrect === true
			&& requestDevice.quantityAvailable > 0
			&& requestDevice.quantityAvailable < requestDevice.quantity;
	}

	partiallyMoveableByNomenclature(dataItem: any): boolean {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;
		return requestDevice.quantityAvailable > 0
			&& requestDevice.quantityAvailable < requestDevice.quantity;
	}

	public confirmRepairRowClass({ dataItem }: any) {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;

		return {
			"bg-danger": requestDevice.isArticleMappedCorrect === false
		};
	}

	notFoundByNomenclature(dataItem: any): boolean {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;
		return requestDevice.quantityAvailable === 0;
	}

	availableToMoveByNomenclature(dataItem: any): boolean {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;
		return requestDevice.quantityAvailable > 0
			&& requestDevice.quantityAvailable >= requestDevice.quantity;
	}

	refillAccepted(dataItem: any): boolean {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;
		return requestDevice.quantity > 0
			&& requestDevice.quantityReal >= requestDevice.quantity;
	}

	refillNotAccepted(dataItem: any): boolean {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;
		return requestDevice.quantity > 0
			&& requestDevice.quantityReal === 0
			&& requestDevice.quantityAvailable === 0;
	}

	refillAdded(dataItem: any): boolean {
		var requestDevice = <RequestDeviceWarehouseRefillDto>dataItem;
		return requestDevice.quantity === 0
			&& requestDevice.quantityReal > 0
			&& requestDevice.quantityAvailable === 0;
	}

	showCommentPopup(commentObject: any): void {
		const dialogRef = this.dialogService.open({
			content: PopupComment,
			width: this.isMobileDevice ? '90%' : '70%'
		});

		const setSnForm = <PopupComment>dialogRef.content.instance;

		setSnForm.commentObject = commentObject;
	}

	getCommentObjectPreview(commentObject: any, len: number = 26) {
		if (!!commentObject && !!commentObject.Artikul) {
			if (commentObject.Artikul.length >= len) {
				return commentObject.Artikul.substring(0, len - 1);
			} else {
				return commentObject.Artikul.substring(0, commentObject.Artikul.length - 1);
			}
		} else {
			return "";
		}
	}

	public requestDevicesGridData: DataResult;
	public requestUninstallDevicesGridData: DataResult;
	public requestCollisionDevicesGridData: DataResult;
	public requestConfirmRepairDevicesGridData: DataResult;
	public requestWarehouseTransferDevicesGridData: DataResult;
	public requestWarehouseTransferDevicesByNomenclatureGridData: DataResult;
	public requestWarehouseRefillDevicesGridData: DataResult;
	public requestWarehousePostingDevicesGridData: DataResult;

	
	public requestDevicesGridGridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 1000,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "deviceTypeId",
				title: "Тип устройства",
				filter: "lookup",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceConnectionTypeId",
				title: "Тип соединения устройства",
				filter: "lookup",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceSupplierId",
				title: "Производитель устройства",
				filter: "lookup",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceModelId",
				title: "Модель устройства",
				filter: "lookup",
				width: 200,
				filterable: true,
			},
			{
				field: "isPinPadRequired",
				title: "Требуется Pinpad",
				filter: "boolean",
				width: 200,
				filterable: true,
			},
			{
				field: "serialNumber",
				title: "С/Н в запросе",
				width: 200,
				filterable: true,
			},
			{
				field: "deviceServiceTypeName",
				title: "Сегмент",
				width: 150,
				filterable: true,
			},
			{
				field: "nomenclature",
				title: "Номенклатура для резерва",
				width: 220,
				filterable: true,
			},
			{
				field: "requestedModel",
				title: "Наименование в запросе",
				width: 200,
				filterable: true,
			},
			{
				field: "reserveResult",
				title: "Статус резерва",
				width: 150,
				filterable: true,
			},
			{
				field: "warehouseAreaMoveFromName",
				title: "Склад",
				width: 150,
				filterable: true,
			},
			{
				field: "warehouseAreaName",
				title: "Помещение, ячейка",
				width: 150,
				filterable: true,
			},
		]
	};

	public requestUninstallDevicesGridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 1000,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "deviceTypeId",
				title: "Тип устройства",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceSupplierId",
				title: "Производитель устройства",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceModelId",
				title: "Модель устройства",
				width: 200,
				filterable: true,
			},
			{
				field: "deviceConnectionTypeId",
				title: "Тип соединения устройства",
				filterable: true,
				width: 200,
			},
			{
				field: "isHasAttachments",
				title: "Фото",
				filterable: true,
				width: 150,
			},

			{
				field: "serialNumber",
				title: "С/Н",
				width: 200,
				filterable: true,
			},
			{
				field: "deviceServiceTypeName",
				title: "Сегмент",
				width: 150,
				filterable: true,
			},
			{
				field: "articleName",
				title: "Артикул",
				width: 220,
				filterable: true,
			},
			
			{
				field: "isNotDefinedSn",
				title: "С/Н неопознан",
				width: 200,
				filterable: true,
			},
			{
				field: "isDeviceBroken",
				title: "Оборудование неисправно",
				width: 200,
				filterable: true,
			},
			{
				field: "brokenDescription",
				title: "Причина неисправности",
				width: 200,
				filterable: true,
			},
			{
				field: "deviceConditionId",
				title: "Состояние",
				width: 200,
				filterable: true,
			},
			{
				field: "nomenclature",
				title: "Номенклатура",
				width: 200,
				filterable: true,
			},
			{
				field: "warehouseId",
				title: "Склад получателя",
				width: 200,
				filterable: true,
			},
			{
				field: "warehouseAreaId",
				title: "Помещение",
				width: 200,
				filterable: true,
			},
			{
				field: "warehouseCellId",
				title: "Ячейка",
				width: 200,
				filterable: true,
			},
			{
				field: "isTargeted",
				title: "Целевое",
				width: 200,
				filterable: true,
			},

			{
				field: "deviceStatusId",
				title: "Статус",
				width: 150,
				filterable: true,
			},
			{
				field: "updatedUserDisplayName",
				title: "Обновил",
				width: 150,
				filterable: true,
			},
			{
				field: "updatedDate",
				title: "Обновлено",
				width: 150,
				filterable: true,
			},
		]
	};

	public requestCollisionDevicesGridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 1000,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "serialNumber",
				title: "Серийный номер",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "articleNumber",
				title: "Артикул",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "manufacturer",
				title: "Производитель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "model",
				title: "Модель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceServiceTypeName",
				title: "Сегмент",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "quantity",
				title: "Количество",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "commentObject",
				title: "Статус обработки коллизии",
				filter: "string",
				filterable: true,
				width: 200,
			},

		]
	};

	public requestConfirmRepairDevicesGridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 1000,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "status",
				title: "Статус",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "serialNumber",
				title: "Серийный номер",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "articleNumber",
				title: "Артикул",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceTypeName",
				title: "Тип устройства",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "manufacturer",
				title: "Производитель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			
			{
				field: "model",
				title: "Модель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "descriptionOfFailure",
				title: "Описание неисправности",
				filter: "string",
				filterable: true,
				width: 200,
			},
		]
	};

	public requestWarehouseTransferDevicesGridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 1000,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "deviceTypeName",
				title: "Тип устройства",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "manufacturer",
				title: "Производитель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "model",
				title: "Модель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "quantity",
				title: "Количество",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "serialNumber",
				title: "Серийный номер",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "nomenclature",
				title: "Номенклатура",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "requestSerialNumber",
				title: "Серийный номер в запросе",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "requestManufacturer",
				title: "Производитель в запросе",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "requestModel",
				title: "Модель в запросе",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceServiceTypeName",
				title: "Сегмент",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "quantityReal",
				title: "Количество собранных/принятых",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "quantityAvailable",
				title: "Кол-во доступно",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "status",
				title: "Статус",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "descriptionOfFailure",
				title: "Описание неисправности",
				filter: "string",
				filterable: true,
				width: 200,
			}
		]
	};

	public requestWarehouseTransferDevicesByNomenclatureGridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 1000,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "deviceTypeName",
				title: "Тип устройства",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "manufacturer",
				title: "Производитель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "model",
				title: "Модель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "quantity",
				title: "Количество",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "nomenclature",
				title: "Номенклатура",
				filter: "string",
				filterable: true,
				width: 200,
			}
		]
	};


	public requestWarehouseRefillDevicesGridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 1000,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "serialNumber",
				title: "Серийный номер",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "articleNumber",
				title: "Артикул",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "existsInErp",
				title: "Проверка артикула",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "requestManufacturer",
				title: "Производитель в запросе",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "manufacturer",
				title: "Производитель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "requestModel",
				title: "Модель в запросе",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "model",
				title: "Модель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceServiceTypeName",
				title: "Сегмент",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "quantity",
				title: "Количество",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "quantityReal",
				title: "Количество собранных/принятых",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "status",
				title: "Статус",
				filter: "string",
				filterable: true,
				width: 200,
			},
		]
	};

	public requestWarehousePostingDevicesGridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 1000,

			// Initial filter descriptor
			filter: {
				logic: "and",
				filters: [],
			},
		},
		columnsConfig: [
			{
				field: "serialNumber",
				title: "Серийный номер",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "articleNumber",
				title: "Артикул",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "existsInErp",
				title: "Проверка артикула",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "requestManufacturer",
				title: "Производитель в запросе",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "manufacturer",
				title: "Производитель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "requestModel",
				title: "Модель в запросе",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "model",
				title: "Модель",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "deviceServiceTypeName",
				title: "Сегмент",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "quantity",
				title: "Количество",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "quantityReal",
				title: "Количество собранных/принятых",
				filter: "string",
				filterable: true,
				width: 200,
			},
			{
				field: "status",
				title: "Статус",
				filter: "string",
				filterable: true,
				width: 200,
			},
		]
	};


	loadRequestDevicesGridSettings() {
		const gridSettings: GridSettings = this.persistingService.get("devices-settings");

		if (gridSettings !== null)
			this.requestDevicesGridGridSettings = this.mapGridSettings(gridSettings);

		this.requestDevicesGridData = process(this.requestDevices, this.requestDevicesGridGridSettings.state);
	}

	loadRequestUninstallDevicesGridSettings() {
		const gridSettings: GridSettings = this.persistingService.get("uninstall-devices-settings");

		if (gridSettings !== null)
			this.requestUninstallDevicesGridSettings = this.mapGridSettings(gridSettings);

		this.requestUninstallDevicesGridData = process(this.requestUninstallDevices, this.requestUninstallDevicesGridSettings.state);

		this.loadDeviceConditions();
	}

	loadRequestCollisionDevicesGridSettings() {
		const gridSettings: GridSettings = this.persistingService.get("collision-devices-settings");

		if (gridSettings !== null)
			this.requestCollisionDevicesGridSettings = this.mapGridSettings(gridSettings);

		this.requestCollisionDevicesGridData = process(this.requestDeviceWarehouseRefills, this.requestCollisionDevicesGridSettings.state);
	}

	loadRequestConfirmRepairDevicesGridSettings() {
		const gridSettings: GridSettings = this.persistingService.get("confirm-repair-devices-settings");

		if (gridSettings !== null)
			this.requestConfirmRepairDevicesGridSettings = this.mapGridSettings(gridSettings);

		this.requestConfirmRepairDevicesGridData = process(this.requestDeviceWarehouseRefills, this.requestConfirmRepairDevicesGridSettings.state);
	}

	loadRequestWarehouseTransferDevicesGridSettings() {
		const gridSettings: GridSettings = this.persistingService.get("warehouse-transfer-devices-settings");

		if (gridSettings !== null)
			this.requestWarehouseTransferDevicesGridSettings = this.mapGridSettings(gridSettings);

		this.requestWarehouseTransferDevicesGridData = process(this.requestDeviceWarehouseRefills, this.requestWarehouseTransferDevicesGridSettings.state);
	}

	loadRequestWarehouseTransferDevicesByNomenclatureGridSettings() {
		const gridSettings: GridSettings = this.persistingService.get("warehouse-transfer-devices-by-nomenclature-settings");

		if (gridSettings !== null)
			this.requestWarehouseTransferDevicesByNomenclatureGridSettings = this.mapGridSettings(gridSettings);

		let devices = this.getRequestWarehouseTransferDevicesByNomenclature();
		this.requestWarehouseTransferDevicesByNomenclatureGridData = process(devices, this.requestWarehouseTransferDevicesByNomenclatureGridSettings.state);
	}

	loadRequestWarehouseRefillDevicesGridSettings() {
		const gridSettings: GridSettings = this.persistingService.get("warehouse-refill-devices-settings");

		if (gridSettings !== null)
			this.requestWarehouseRefillDevicesGridSettings = this.mapGridSettings(gridSettings);

		this.requestWarehouseRefillDevicesGridData = process(this.requestDeviceWarehouseRefills, this.requestWarehouseRefillDevicesGridSettings.state);
	}

	loadRequestWarehousePostingDevicesGridSettings() {
		const gridSettings: GridSettings = this.persistingService.get("warehouse-posting-devices-settings");

		if (gridSettings !== null)
			this.requestWarehousePostingDevicesGridSettings = this.mapGridSettings(gridSettings);

		this.requestWarehousePostingDevicesGridData = process(this.requestDeviceWarehouseRefills, this.requestWarehousePostingDevicesGridSettings.state);
	}

	public requestDevicesDataStateChange(state: State): void {
		this.requestDevicesGridGridSettings.state = state;
		this.requestDevicesGridData = process(this.requestDevices, state);
		this.saveGrid("devices-settings", this.requestDevicesGridGridSettings);
	}

	public requestUninstallDevicesDataStateChange(state: State): void {
		this.requestUninstallDevicesGridSettings.state = state;
		this.requestUninstallDevicesGridData = process(this.requestUninstallDevices, state);
		this.saveGrid("uninstall-devices-settings", this.requestUninstallDevicesGridSettings);
	}

	public requestCollisionDevicesDataStateChange(state: State): void {
		this.requestCollisionDevicesGridSettings.state = state;
		this.requestCollisionDevicesGridData = process(this.requestDeviceWarehouseRefills, state);
		this.saveGrid("collision-devices-settings", this.requestCollisionDevicesGridSettings);
	}

	public requestConfirmRepairDevicesDataStateChange(state: State): void {
		this.requestConfirmRepairDevicesGridSettings.state = state;
		this.requestConfirmRepairDevicesGridData = process(this.requestDeviceWarehouseRefills, state);
		this.saveGrid("confirm-repair-devices-settings", this.requestConfirmRepairDevicesGridSettings);
	}

	public requestWarehouseTransferDataStateChange(state: State): void {
		this.requestWarehouseTransferDevicesGridSettings.state = state;
		this.requestWarehouseTransferDevicesGridData = process(this.requestDeviceWarehouseRefills, state);
		this.saveGrid("warehouse-transfer-devices-settings", this.requestWarehouseTransferDevicesGridSettings);
	}

	public requestWarehouseTransferByNomenclatureDataStateChange(state: State): void {
		this.requestWarehouseTransferDevicesByNomenclatureGridSettings.state = state;
		let devices = this.getRequestWarehouseTransferDevicesByNomenclature();
		this.requestWarehouseTransferDevicesByNomenclatureGridData = process(devices, state);
		this.saveGrid("warehouse-transfer-devices-by-nomenclature-settings", this.requestWarehouseTransferDevicesByNomenclatureGridSettings);
	}


	public requestWarehouseRefillDataStateChange(state: State): void {
		this.requestWarehouseRefillDevicesGridSettings.state = state;
		this.requestWarehouseRefillDevicesGridData = process(this.requestDeviceWarehouseRefills, state);
		this.saveGrid("warehouse-refill-devices-settings", this.requestWarehouseRefillDevicesGridSettings);
	}

	public requestWarehousePostingDataStateChange(state: State): void {
		this.requestWarehousePostingDevicesGridSettings.state = state;
		this.requestWarehousePostingDevicesGridData = process(this.requestDeviceWarehouseRefills, state);
		this.saveGrid("warehouse-posting-devices-settings", this.requestWarehousePostingDevicesGridSettings);
	}


	public onReorder(tokenName: string, gridSettings: GridSettings, e: any, newIndexOffset: number): void {

		if (e.oldIndex === e.newIndex) {
			return;
		}

		const oldIndex = gridSettings.columnsConfig.findIndex(col => col.field === e.column.field);
		const newIndex = e.newIndex - newIndexOffset;

		const columnSetting = gridSettings.columnsConfig[oldIndex];
		gridSettings.columnsConfig.splice(oldIndex, 1);
		gridSettings.columnsConfig.splice(newIndex, 0, columnSetting);

		this.saveGrid(tokenName, gridSettings);
	}

	public onResize(tokenName: string, gridSettings: GridSettings, e: any): void {
		e.forEach((item) => {
			gridSettings.columnsConfig.find(
				(col) => col.field === item.column.field
			).width = item.newWidth;
		});

		this.saveGrid(tokenName, gridSettings);
	}

	public onVisibilityChange(tokenName: string, gridSettings: GridSettings, e: any, stateChangeEvent: Function): void {
		e.columns.forEach((column) => {
			gridSettings.columnsConfig.find(
				(col) => col.field === column.field
			).hidden = column.hidden;
		});

		this.actualizeHiddenColumnsFilteringAndSorting(gridSettings, e.columns);

		if (stateChangeEvent != null) {
			stateChangeEvent.call(this, gridSettings.state);
		}

		this.saveGrid(tokenName, gridSettings);
	}

	public mapGridSettings(gridSettings: GridSettings): GridSettings {
		const state = gridSettings.state;
		this.mapDateFilter(state.filter);

		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
		};
	}

	private saveGrid(tokenName: string, gridSettings: GridSettings): void {
		const gridConfig = {
			columnsConfig: gridSettings.columnsConfig,
			state: gridSettings.state,
		};

		this.persistingService.set(tokenName, gridConfig);
	}

	private mapDateFilter = (descriptor: any) => {
		const filters = descriptor.filters || [];

		filters.forEach((filter) => {
			if (filter.filters) {
				this.mapDateFilter(filter);
			} else if (typeof filter.field === "string" && filter.field.includes("date") && filter.value) {
				filter.value = new Date(filter.value);
			}
		});
	};

	protected actualizeHiddenColumnsFilteringAndSorting(gridSettings: GridSettings, columns: ColumnBase[]): void {
		var fieldNames = columns.filter(column => column.hidden).map((col: ColumnComponent) => ({
			field: col.field,
			filterField: gridSettings.columnsConfig.find(cs => cs.field === col.field).filterField
		}));

		if (fieldNames.length > 0) {

			if (!!gridSettings.state.sort) {
				gridSettings.state.sort = gridSettings.state.sort.filter(sd =>
					!fieldNames.map(fn => fn.field).includes(sd.field));
			}

			gridSettings.state.filter = this.removeHiddenColumnsFilters(gridSettings.state.filter, fieldNames.map(
				fn => fn.filterField ? fn.filterField : fn.field));

		}
	}

	private removeHiddenColumnsFilters(filters: CompositeFilterDescriptor, filterFieldNames: Array<string>): CompositeFilterDescriptor {
		filters.filters.filter(fd => !("field" in fd)).forEach((cfd: CompositeFilterDescriptor) => {
			cfd = this.removeHiddenColumnsFilters(cfd, filterFieldNames);
		});
		filters.filters = filters.filters.filter(fd =>
			("field" in fd
				&& typeof (fd as FilterDescriptor).field === "string"
				&& !filterFieldNames.includes((fd as FilterDescriptor).field.toString()))
			|| (!("field" in fd)
				&& (fd as CompositeFilterDescriptor).filters.length > 0
			)
		);

		return filters;
	}

	public removeRequestUninstallDevice(dataItem: NewRequestDevice) {
		this.requestUninstallDevicesService.remove(`${(dataItem).requestDeviceId}`, 'Устройство удалено из заявки', '')
		.subscribe(x => {
			if (x.isSuccessful) {
				this.loadRequestDevices();
			}
		});
	}

	public onSerialNumberChanged(dataItem: NewRequestDevice): void {
		if (!!dataItem && !!dataItem.serialNumber) {
			this.requestDevicesService.getAvailableSerialNumbers(this.requestId
				, [dataItem.serialNumber]
				, []).pipe(map(viewModels => {
					return viewModels;
				})).subscribe(res => {

					if (res.data.unavailableSerialNumbers.length > 0) {
						this.notificationService.info({
							title: 'Информация',
							message: `С/н ${res.data.unavailableSerialNumbers[0].serialNumber} не может быть зарезервирован.<br>Статус: ${res.data.unavailableSerialNumbers[0].status}`,
							notificationType: NotificationType.SweetAlert, enableHtml: true, showConfirmButton: true
						});
					}
			}, () => {this.nomenclatureLoading = false;}, () => {this.nomenclatureLoading = false;});
		}
	}

	get isCanEditRequestUninstallDevicesGrid(): boolean {
		return this.request.statusId == RequestStatus.DevicePostingExpecting &&
			this.securityService.canEditingEquipmentInProgressInRequest() &&
			!this.isMkInitType;
	}

	public isCanEditRequestUninstallDevicesRow(device: Device): boolean {
		return device.deviceStatusId == DeviceStatus.receivedAtWarehouse &&
			!this.isMkInitType;;
	}

	isColumnHidden(col: ColumnSettings, isInWork: boolean): boolean {

		if (col.field === 'deviceConditionId' && (this.isMkInitType || !isInWork))
			return true;

		if (col.field === 'nomenclature' && (this.isMkInitType || !isInWork))
			return true;

		if (col.field === 'warehouseId' && (this.isMkInitType || !isInWork))
			return true;

		if (col.field === 'warehouseAreaId' && (this.isMkInitType || !isInWork))
			return true;

		if (col.field === 'warehouseCellId' && (this.isMkInitType || !isInWork))
			return true;

		return col.hidden;
	}

	private appendTransportationInfo(res: string): string {
		let tranportationInfo = this.tranportationInfo;

		if(!!tranportationInfo) {
			if (res === "") {
				res = this.tranportationInfo;
			} else {
				res = `${res}, ${this.tranportationInfo}`;
			}
		}

		return res;
	}

	get senderWarehouseTransfer(): string {
		let res = "";
		res = this.getOneOfTheEntityFields('senderWarehouseNameAndAddress', 'senderWarehouseName');

		res = this.appendTransportationInfo(res);

		return res;
	}

	get senderWarehouseRefill(): string {
		let res = "";
		res = this.request.senderWarehouseNameAndAddress ? this.request.senderWarehouseNameAndAddress : '(Не указан)';

		res = this.appendTransportationInfo(res);

		return res;
	}

	get recipientWarehouseRefill(): string {
		const adress = this.request.recipientWarehouseNameAndAddress ? this.request.recipientWarehouseNameAndAddress : '(Склад получателѝ не указан)';
		const code = this.request.commonData && this.request.commonData.storeRecipientCode ? this.request.commonData.storeRecipientCode : '(Код ѝклада получателѝ не указан)';
		return `${adress}, ${code}`;
	}
	
	get supplier(): string {
		let res = "";
		res = this.request.supplierContragentName ? this.request.supplierContragentName : '(Не указан)'

		res = this.appendTransportationInfo(res);

		return res;
	}

	get tranportationInfo(): string {
		let res = "";

		if (!!this.request.commonData) {
			if (!!this.request.commonData.postingCompanyName) {
				res = `${this.request.commonData.postingCompanyName}`;
			}

			if (!!this.request.commonData.postingTrackNumber) {
				if (res === "") {
					res = `тн. ${this.request.commonData.postingTrackNumber}`;
				} else {
					res = `${res}, тн. ${this.request.commonData.postingTrackNumber}`;
				}
			}
		}

		return res;
	}

	public handleDeviceModelFilter(value: string, deviceSupplierId: number) {
		let models = this.getDeviceModels(deviceSupplierId);
		this.dropDownDeviceModels = models.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
	}

	public handleDeviceModelDropdownOpen(deviceSupplierId: number) {
		this.dropDownDeviceModels = this.getDeviceModels(deviceSupplierId);
	}

	public handleConnectionTypeFilter(value: any) {
		let filtered = this.deviceConnectionTypes.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
		this.deviceConnectionTypesGroupedDropdown = groupBy(filtered, [{field: 'groupName'}]);	
	}

	//#region WarehouseRefil */

	warehouseEditing: boolean = false;
	warehouseSaving: boolean = false;

	public createWarehouseTransferDevice(): any {
		const self = this;

		return function() {
			const device = new RequestDeviceWarehouseRefillDto();
			device.quantity = 1;
			device.requestId = self.requestId;	
			return device;
		}
	}

	changeWarehouseEditing() {
		this.request.senderWarehouseAreaId = this.senderWarehouseAreaId;
		this.request.recipientWarehouseAreaId = this.recipientWarehouseAreaId;

		this.warehouseEditing = !this.warehouseEditing;
	}

	warehouseRefillItemsDisabled(itemArgs: { dataItem: IFreeNomenclature; index: number }) {
		return itemArgs.dataItem.availableForReserve < 1 || itemArgs.dataItem.disabled;
	}

	get senderWarehouseIntegraCode(): string {
		let currentWarehouse = this.warehouseAreasWithWarehouseSenderDropdown.find(x => x.warehouseAreaId == this.request.senderWarehouseAreaId);
		return currentWarehouse ? currentWarehouse.warehouseIntegraCode : null;
	}

	saveWarehouses() {
		this.requestsService.update(this.request, null).subscribe(() => {
			this.senderWarehouseAreaId = this.request.senderWarehouseAreaId;
			this.recipientWarehouseAreaId = this.request.recipientWarehouseAreaId;
			this.changeWarehouseEditing();
			window.location.reload();
		});
	}

	saveRequestDeviceWarehouseRefillHandler({ sender, rowIndex, dataItem }) {
		const device = <RequestDeviceWarehouseRefillDto>dataItem;
		device.nomenclature = device.selectedNomenclature.nomenclature;
		device.deviceStatusId = DeviceStatus.inRequest;

		if (dataItem.serialNumber && this.requestWarehouseTransferDevicesGridData.data.some(x => 
				x.requestDeviceWarehouseRefillId != device.requestDeviceWarehouseRefillId && x.serialNumber == dataItem.serialNumber)) {
			this.notificationService.error({
				title: 'Добавление серийного устройства',
				message: 'Устройство с таким серийным номером уже добавлено',
				notificationType: NotificationType.SweetAlert
			});
		}
		else {
			if (device.deviceTypeId == null || this.isIdPart(device.deviceTypeId)) {
				device.deviceTypeId = 0;
				device.deviceModelId = 0;
				device.deviceSupplierId = 0;
			}
	
			if (device.requestDeviceWarehouseRefillId) {
				this.requestDeviceWarehouseRefillsService.update(device, null).subscribe(() => {
					this.deviceNomenclatures = [];
					this.loadGridData();
				});
			}
			else {
				this.requestDeviceWarehouseRefillsService.create(device).subscribe(() => {
					this.deviceNomenclatures = [];
					this.loadGridData();
				});
			}

			sender.closeRow(rowIndex);
		}
	}

	saveTransferDeviceByNomenclatureHandler({ sender, rowIndex, dataItem }) {
		const data = <RequestDeviceWarehouseRefillDto>dataItem;
		data.nomenclature = data.selectedNomenclature.nomenclature;

		this.requestDeviceWarehouseRefillsService.createRequestDeviceWarehouseRefillByNomenclature(data).subscribe(() => 
			this.loadRequestWarehouseTransferDevicesByNomenclature()
		);
		
		sender.closeRow(rowIndex);
	}

	deleteTransferDeviceByNomenclatureHandler(dataItem) {
		const data = <RequestDeviceWarehouseRefillDto>dataItem;
		data.nomenclature = data.nomenclature ? data.nomenclature : data.selectedNomenclature.nomenclature;

		this.requestDeviceWarehouseRefillsService.deleteRequestDeviceWarehouseRefillByNomenclature(data).subscribe(() => 
			this.loadRequestWarehouseTransferDevicesByNomenclature());
	}

	onTabChange(event: any){
		this.gridLoading = true;
		return this.requestDeviceWarehouseRefillsService.getRequestDeviceWarehouseRefillsWithParts(this.requestId)
			.subscribe(items => {
				this.requestDeviceWarehouseRefills = items.data;

				this.loadRequestWarehouseTransferDevicesByNomenclatureGridSettings();
		this.loadRequestWarehouseTransferDevicesGridSettings();
				this.gridLoading = false;
			}, () => this.gridLoading = false);
	}

	getRequestWarehouseTransferDevicesByNomenclature() : RequestDeviceWarehouseRefillDto[]{
		let devices = this.requestDeviceWarehouseRefills.reduce((acc: RequestDeviceWarehouseRefillDto[], curr: RequestDeviceWarehouseRefillDto) => {
			const currClone = { ...curr };

			const existingItem = acc.find(item => item.nomenclature === currClone.nomenclature
				&& item.deviceTypeId === currClone.deviceTypeId
				&& item.deviceSupplierId === currClone.deviceSupplierId
				&& item.deviceModelId === currClone.deviceModelId
			);
		
			if (existingItem) {
				existingItem.quantity += currClone.quantity;
			} else {
				acc.push(currClone);
			}
		
			return acc;
		}, []);
		return devices;
	}

	public warehouseRefillEditHandler(obj: any) {
		const dataItem = obj.dataItem as RequestDeviceWarehouseRefillDto;
		if (!!dataItem) {
			let selectedNomenclature: any  = {
				nomenclature: dataItem.nomenclature,
				fullName: dataItem.nomenclature};
			dataItem.selectedNomenclature = selectedNomenclature;
			
			dataItem.nomenclatures = [selectedNomenclature];
			this.deviceNomenclatures = [selectedNomenclature];

			if (!this.isIdPartWarehouseRefill(dataItem)) {
				let body = new GetNomenclaturesQuery;
				body.deviceTypeId = dataItem.deviceTypeId;
				body.deviceModelName = this.getDeviceModelName(dataItem.deviceModelId);
				body.deviceSupplierName = this.getNameById(this.deviceSuppliers, dataItem.deviceSupplierId);;
				this.for1CService.getNomenclatures(body).subscribe(r => {
						if (r.isSuccessful) {
							let arr: any[] = [];
							r.data.forEach(n => arr.push({nomenclature: n, fullName: n}))
							dataItem.nomenclatures = arr;
							if (!dataItem.nomenclatures.some(n => n.nomenclature == dataItem.nomenclature)) {
								dataItem.nomenclature = null;
								dataItem.selectedNomenclature.nomenclature = null;
							}
						} else {
							dataItem.nomenclatures = [selectedNomenclature];
						}
					});
			}
		}
	}

	isQuantityValid(item: IFreeNomenclature, quantity: number): boolean {
		if (!quantity || quantity <= 0){
			return false;
		}
		return this.isQuantityAvaliable(item, quantity);
	}

	isQuantityAvaliable(item: IFreeNomenclature, quantity: number): boolean {
		if (!item){
			return true;
		}
		if (quantity > item.availableForReserve) {
		  	return false;
		} else {
			return true;
		}
	}

	getWarehouseName(item: NewRequestDevice): string{		
		return item.warehouseMoveFromName ? item.warehouseMoveFromName : item.defaultScWarehouseName;
	}

	get hasDeviceWithDifferentWarehouse(): boolean{		
		return this.requestDevices.find(x => x.isOtherWarehouseAreaFrom);
	}
}
