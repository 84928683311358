import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Merchant } from '../../shared/models/merchant/Merchant';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { MerchantLookupItem } from '../../shared/models/merchant/merchant-lookup-item';
import { MerchantFull } from '../../shared/models/merchant/MerchantFull';

@Injectable()
export class MerchantsFullService extends DataService<MerchantFull, MerchantFull> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'merchants-full';
	}
}
