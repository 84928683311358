import { Component, Input, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CustomerEnum } from '../../enums/customer.enum';
import { RequestListItem } from '../../models/request/request-list-item';
import { RequestStatus } from '../../enums';

@Component({
	selector: 'mobile-requests-card',
	templateUrl: './mobile-requests-card.component.html',
	styleUrls: [
		'./mobile-requests-card.component.scss'
	]
})
export class MobileRequestsCardComponent {

	@Input() request: RequestListItem = null;

	@Output() click = new EventEmitter<any>();

	constructor(
		private sanitizer: DomSanitizer) { }

	onclick() {
		this.click.emit();
	}

	public columnColor(statusColorCode: string, slaColorCode: string): SafeStyle {
		if (slaColorCode) {
			return this.sanitizer.bypassSecurityTrustStyle(slaColorCode);
		}

		return this.sanitizer.bypassSecurityTrustStyle(statusColorCode);
	}

	public isVtb() {
		return this.request.customerContragentId == CustomerEnum.vtb;
	}

	public isAlpha() {
		return this.request.customerContragentId == CustomerEnum.alpha ||
		this.request.customerContragentId == CustomerEnum.alphaAlliance;
	}

	public slaTime() {
		return this.request.hoursToDeadlineString;
	}

	public get isCompleteRequests(): boolean {
		return this.request.statusId === RequestStatus.Completed
			|| this.request.lastEngineerActivityStatusIsFinal;
	} 

	public slaType() {
		var dateSla = new Date(this.request.slaDueDateLocal).getTime();
		var dateNow = new Date().getTime();

		var hours = (dateSla - dateNow) / 36e5;

		if(hours <= 0) return 'black';
		else if(hours <= 2) return 'red';
		else if(hours <= 4) return 'yellow';
		else return 'green';
	}

	public mapsUrl() {
		if(this.request.customerObjectLatitude && this.request.customerObjectLongitude)
			return `https://yandex.ru/maps/?text=${this.request.customerObjectLatitude},${this.request.customerObjectLongitude}`;
		else 
			return null;
	}
}
