import { BrowserModule, Title } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import moment from 'moment'
import * as $ from 'jquery';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// *******************************************************************************

import '@progress/kendo-angular-intl/locales/ru/all';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';

registerLocaleData(localeRu, localeRuExtra);

// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
// import 'hammerjs';

//Features

import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { UploadModule } from '@progress/kendo-angular-upload';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminModule } from './admin/admin.module';
import { DirectoriesModule } from './categories/categories.module';
import { RequestsModule } from './requests/requests.module';
import { ActivitiesModule } from './activities/activities.module';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { ReportsModule } from './reports/reports.module';
import { WarehouseWorkplaceRequestsModule } from './warehouse-workplace-requests/warehouse-workplace-requests.module';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    ToastrModule.forRoot(),

    //Features
    LoginModule,
    HomeModule,
    AdminModule,
    DirectoriesModule,
    RequestsModule,
    ActivitiesModule,
    WarehouseWorkplaceRequestsModule,
    ReportsModule,

    // App
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    UploadModule,
    HttpClientModule,
    DialogsModule,
		ScrollViewModule,
		InfiniteScrollModule,
  ],

  providers: [
    Title,
    AppService,
    { provide: LOCALE_ID, useValue: 'ru-RU' }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    this.overrideDate();
  }

  overrideDate() {
    Date.prototype.toJSON = function () {

      if (!this)
        return null;

      return moment(this).format('YYYY-MM-DDTHH:mm:ss');

      //return this.toLocaleDateString() + ' ' + this.toLocaleTimeString();
      //return this.intl.formatDate(this, 'yyyy-MM-ddTHH:mm:ss')
    }
  }

}
