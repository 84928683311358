import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ExpertiseActDevice } from "../../../../../shared/models/expertise-act/expertise-act-device";
import { ExpertiseActSetting } from "../../../../../shared/models/expertise-act/expertise-act-setting";

@Component({
	selector: 'expertise-form-checkbox',
	templateUrl: './expertise-act-checkbox.component.html',
	styleUrls: []	
})
export class ExpertiseActCheckbox implements OnInit {

	@Input() tabName: string;
	@Input() contragentId: number;
	@Input() formData: ExpertiseActSetting;
	@Input() device: ExpertiseActDevice;
	@Input() formFilledData: any;

	@Output() onChange = new EventEmitter<any>();

	isRequired: boolean;
	selectedItem: boolean;

	constructor() {

	}

	ngOnInit(): void {
		this.isRequired = this.getIsRequiredValue();
		this.updateSelectedItem();

		if (this.formData.name === 'Работоспособно' || this.formData.name === 'Тип процессинга') {
			this.onChangeEvent({ target: { checked: this.selectedItem } });
		}

		const optionValue = this.selectedItem ? 'Да' : 'Нет';
		const option = this.formData.options.filter(x => x.value === optionValue)[0];
		this.formFilledData[this.tabName][this.formData.name] = option.optionId;
	}

	onChangeEvent(event: any): void {
		if (this.onChange) {
			this.onChange.emit({event: event, formData: this.formData, device: this.device});
		}
	}

	public updateSelectedItem(): void {
		this.selectedItem = this.getSelectedItem();
	}

	private getIsRequiredValue(): boolean {
		if (this.formData.name === 'ТО включается') {
			return false;
		}

		if (this.formData.settingByDeviceTypes.length > 0 && this.device.deviceTypeId) {
			const settingsByDeviceType = this.formData.settingByDeviceTypes.filter(x => x.deviceTypeId === this.device.deviceTypeId);
			if (settingsByDeviceType.length === 1) {
				return settingsByDeviceType[0].isRequired;
			}
		}

		return this.formData.isRequired;
	}

	private getSelectedItem(): boolean {
		if (this.contragentId === 1055 && this.formData.name === 'Тип процессинга') {
			return true;
		}

		if (this.formData.name === 'Целевое') {
			return true;
		}

		const filledId = this.formFilledData[this.tabName][this.formData.name];
		if (filledId) {
			const option = this.formData.options.filter(x => x.optionId === filledId)[0];
			return option.value === 'Да';
		}

		const filledForms = this.device.filledForms.filter(x => x.option.settingId === this.formData.settingId);
		return filledForms.length > 0 && filledForms[0].option.value === 'Да';
	}

}
