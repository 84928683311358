import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { AppService } from "../../../app.service";
import { NotificationService } from "../../../core/services/notification.service";
import { ComponentPageBase } from "../../../shared/components/component-page-base/component-page-base";
import { AttachmentTypeConfigurationTypeEnum } from "../../../shared/enums/attachment-type-configuration-type.enum";
import { CategoryType } from "../../../shared/enums/category-type.enum";
import { RequestStatusKindEnum } from "../../../shared/enums/request-status-kind.enum";
import { WorkFlowStepTypeEnum } from "../../../shared/enums/workflow-step-type.enum";
import { AttachmentTypeConfigurationDto } from "../../../shared/models/attachment/attachment-type-configuration-dto";
import { EntityViewModel } from "../../../shared/models/core/EntityViewModel";
import { KeyValueObject } from "../../../shared/models/core/KeyValueObject";
import { AttachmentTypeConfigurationsService } from "../../../shared/services/attachment-type-configurations.service";
import { LookupService } from "../../../shared/services/lookup.service";
import { AttachmentTypeConfigurationAttachmentTypeDto } from "../../../shared/models/attachment/attachment-type-configuration-attachment-type-dto";

class AttachmentTypeListItem {
	id: number;
	name: string;
	sendToCustomer: boolean;
}

@Component({
	selector: 'attachment-type-configurations-page',
	templateUrl: './attachment-type-configuration.page.html',
	styleUrls: [
		'./attachment-type-configuration.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class AttachmentTypeConfigurationPage extends ComponentPageBase<AttachmentTypeConfigurationDto> implements OnInit {

	public activeIdString: any = "main";
	public categoryTypeId = <number>CategoryType.serviceModes;

	public attachmentTypeConfigurationId;

	public attachmentTypeConfigurationTypes: KeyValueObject[] = [];
	public requestTypes: KeyValueObject[] = [];
	public clients: KeyValueObject[] = [];
	public agreements: KeyValueObject[] = [];
	public attachmentTypes: KeyValueObject[] = [];
	public finalStates: KeyValueObject[] = [];

	public selectedAttachmentTypes: AttachmentTypeListItem[] = [];
	public selectedAttachmentTypeIds: number[] = [];

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: AttachmentTypeConfigurationsService,
		private lookupService: LookupService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'categories/attachment-type-configurations';
		this.appService.pageTitle = 'Новая конфигурация для типов вложений';
	}

	ngOnInit(): void {
		this.attachmentTypeConfigurationId = this.route.snapshot.paramMap.get('attachmentTypeConfigurationId');

		if (this.attachmentTypeConfigurationId != null) {
			this.dataService.getById(+this.attachmentTypeConfigurationId)
				.subscribe(vm => {
					this.selectedAttachmentTypeIds = vm.entity.attachmentTypes.map(x => x.attachmentTypeId);
					this.entityId = this.attachmentTypeConfigurationId;
					this.entityName = `${vm.entity.name}`;

					this.dataBind(vm);
					this.loadAttachmentTypes();
					this.loadAgreementsByClient(this.entityViewModel.entity.clientIds);
					this.loadFinalStates(this.entityViewModel.entity.attachmentTypeConfigurationTypeId);
				});
		} 
		else {
			this.entityViewModel = new EntityViewModel<AttachmentTypeConfigurationDto>();
			this.entityViewModel.entity = new AttachmentTypeConfigurationDto();
			this.entityViewModel.entity.createdDate = new Date();
			this.canEditForm = true;
			this.isNew = true;
			this.loadAttachmentTypes();
		}

		var contragentsState: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: 1 }] }
		};

		this.lookupService.getData("attachment-type-configuration-types", null).subscribe(data => this.attachmentTypeConfigurationTypes = data);
		this.lookupService.getData("request-types", null).subscribe(data => this.requestTypes = data);
		this.lookupService.getData("contragents", contragentsState).subscribe(data => this.clients = data);
	}

	loadFinalStates(attachmentTypeConfigurationTypeId: number): void {
		switch (attachmentTypeConfigurationTypeId) {
			case <number>AttachmentTypeConfigurationTypeEnum.activity:
				this.dataService.getActivityStatuses(WorkFlowStepTypeEnum.final, this.entityViewModel.entity.requestTypeIds).subscribe(data => {
					this.finalStates = data;
				});
				break;
			case <number>AttachmentTypeConfigurationTypeEnum.request:
				var requestStatusesStateFinal: DataSourceRequestState = {
					skip: 0,
					take: null,
					filter: { logic: "and", filters: [{ field: "StatusKindId", operator: "eq", value: RequestStatusKindEnum.final }] }
				};
				this.lookupService.getData("request-statuses", requestStatusesStateFinal).subscribe(data => {
					this.finalStates = data;
				});
				break;
			default:
				break;
		}
	}

	afterSaveChangesChild(event: any): void {
		if (this.isNew) {
			this.router.navigate([this.listPageRoutePath]);
		}
	}

	loadAttachmentTypes() {
		this.lookupService.getData("attachment-types", null).subscribe(data => {
			this.attachmentTypes = data;
			this.selectedAttachmentTypes = data.map(x => {
				let attachmentTypeData = this.entityViewModel.entity.attachmentTypes.find(a => a.attachmentTypeId == x.id);
				let attachmentType = new AttachmentTypeListItem;
				attachmentType.id = x.id;
				attachmentType.name = x.name;
				attachmentType.sendToCustomer = attachmentTypeData != null 
					? attachmentTypeData.sendToCustomer 
					: false;
				return attachmentType;
			});
		});
	}
	
	loadAgreementsByClient(clientIds: number[]) {

		if (!clientIds || clientIds.length === 0) {
			this.agreements = [];
			this.entityViewModel.entity.agreementIds = [];
			return;
		}

		let filters: any[] = [];

		clientIds.forEach(clientId => {
			filters.push({ field: "ContragentId", operator: "eq", value: clientId });
		});

		var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "or", filters: filters }
		};

		this.lookupService.getData("agreements", state).subscribe(data => {
			this.agreements = data;
			if (data === null || data.length === 0) {
				this.entityViewModel.entity.agreementIds = [];
			} else {
				this.entityViewModel.entity.agreementIds = this.entityViewModel.entity.agreementIds.filter(flt => data.map(m => m.id).includes(flt));
			}
		});
	}

	beforeSaveChanges() {
		this.entityViewModel.entity.attachmentTypes = this.selectedAttachmentTypes
			.filter(x => this.selectedAttachmentTypeIds.includes(x.id))
			.map(x => {
				let data: AttachmentTypeConfigurationAttachmentTypeDto = {
					attachmentTypeId: x.id,
					sendToCustomer: x.sendToCustomer
				};
				return data;
			});
	}

	function(arr, val) {
		return arr.filter(function (x) { return x !== val; });
	};

	clientsGridSelectedKeysChange(ids: number[]): void {
		this.loadAgreementsByClient(ids);
		this.appService.MarkFormDirty(this.entityForm);
	}

	requestTypesGridSelectedKeysChange(ids: number[]): void {
		this.appService.MarkFormDirty(this.entityForm);
		if (this.entityViewModel.entity.attachmentTypeConfigurationTypeId === <number>AttachmentTypeConfigurationTypeEnum.activity) {
			this.loadFinalStates(this.entityViewModel.entity.attachmentTypeConfigurationTypeId);
		}
	}

	attachmentTypesGridSelectedKeysChange(ids: number[]): void {
		this.appService.MarkFormDirty(this.entityForm);
	}

	agreementsGridSelectedKeysChange(ids: number[]): void {
		this.appService.MarkFormDirty(this.entityForm);
	}

	finalStatesGridSelectedKeysChange(ids: number[]): void {
		this.appService.MarkFormDirty(this.entityForm);
	}

	public clientsGridGridSelectedCallback = (args) => args.dataItem.id;
	public requestTypesGridGridSelectedCallback = (args) => args.dataItem.id;
	public attachmentTypesGridGridSelectedCallback = (args) => args.dataItem.id;
	public agreementsGridGridSelectedCallback = (args) => args.dataItem.id;
	public finalStatesGridGridSelectedCallback = (args) => args.dataItem.id;

	attachmentTypeConfigurationTypeModelChanged(val: number): void {
		this.loadFinalStates(val);
		this.entityViewModel.entity.finalStateIds = [];
	}
}
