import { Guid } from "guid-typescript";
import { NewRequestDevice } from "../../../../../models/request/new-request/new-request-device";
import { DismantledSeriesGeneralPropertiesData } from "../../dismantled-series-general-properties/dismantled-series-general-properties-data";
import { DismantledSeriesImagesData } from "../../dismantled-series-images/dismantled-series-images-data";
import { DismantledSeriesSerialPartNumbersData } from "../../dismantled-series-serial-part-numbers/dismantled-series-serial-part-numbers-data";
import { InstallFlowDeviceSerialData } from "./install-flow-device-serial/install-flow-device-serial-data";

export class InstallFlowDeviceData {
    
    requestId: number;
    activityId: number;
    
    serialsData: InstallFlowDeviceSerialData[] = [];

    imagesData: DismantledSeriesImagesData = null;
    serialAndPartNumbersData: DismantledSeriesSerialPartNumbersData = null;
    generalPropertiesData: DismantledSeriesGeneralPropertiesData = null;

    disableImagesStep: boolean = false;

    id: Guid;

    constructor(
        requestId: number,
        activityId: number,
        needSecurityKeyForReserve: boolean,
        contragentId: number,
        requestDevice: NewRequestDevice = null
    ) {
        this.requestId = requestId;
        this.activityId = activityId;
        this.id = Guid.create();

        this.imagesData = new DismantledSeriesImagesData;;
        this.serialAndPartNumbersData = new DismantledSeriesSerialPartNumbersData;
        this.generalPropertiesData = new DismantledSeriesGeneralPropertiesData(needSecurityKeyForReserve, contragentId);
    
        if (requestDevice != null) {
            this.serialAndPartNumbersData.serialNumber = requestDevice.serialNumber;
            this.serialAndPartNumbersData.nomenclature = requestDevice.nomenclature;
        }
    }
}