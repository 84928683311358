import { Component, Input, Output, EventEmitter, OnInit, Injector } from '@angular/core';
import { EthUser } from '../../models/ethereum-gate/eth-user';
import { ListViewModel } from '../../models/core/ListViewModel';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
    selector: 'ethereum-gate-users',
    templateUrl: './ethereum-gate-users.component.html',
    styleUrls: ['./ethereum-gate-users.component.scss']
})
export class EthereumGateComponent implements OnInit {
    @Input() ethUsers: EthUser[] = [];
    @Input() heightPercent: number;

    @Output() selectedEvent = new EventEmitter<string>();

    gridHeight: number;
    data: ListViewModel<EthUser> = new ListViewModel<EthUser>();

    constructor(
        private injector: Injector) {

    }

    ngOnInit(): void {
        this.gridHeight = window.innerHeight * (this.heightPercent / 100) - 100;
        this.data.data = this.ethUsers;
        this.data.total = this.ethUsers.length;
    }

    private Close(): void {
        var dialog = this.injector.get(DialogRef);
        dialog.close();
    }

    public cellClick({ dataItem }: any): void {
        this.selectedEvent.emit(dataItem.publicKey);
        this.Close();
    }

    public cancel(): void {
        this.Close();
    }
}
