export class CustomerContact {
    contactId: number;
    requestId: number;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    maskedPhone: string;
    contactType: string;

	constructor(obj?: Partial<CustomerContact>) {
		Object.assign(this, obj);
	}
}
