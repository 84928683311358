import { Component, Input, AfterViewInit, Injector, Output, EventEmitter } from '@angular/core';
import { DialogRef } from "@progress/kendo-angular-dialog";

@Component({
	selector: 'image-popup',
	templateUrl: './image-popup.component.html',
	styleUrls: [
		'./image-popup.component.scss',
		'../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss'
	]
})
export class ImagePopup implements AfterViewInit {
	
	@Input() 
	src: string;

	@Input() 
	includeAuth: boolean = false;

	@Input() 
	showCloseButton: boolean = true;

	@Input() 
	showRemoveButton: boolean = false;

	@Input()
	showFileInfo: boolean = false;

	@Input()
	fileName: string;

	@Input()
	fileSize: number;

	@Input()
	showAsIcon: boolean = false;

	@Input()
	iconClass: string;

	@Output()
	removeEvent: EventEmitter<void> = new EventEmitter;
	
	constructor(private injector: Injector,
	) {
	}

	ngAfterViewInit(): void {
	}

	public onCancel(): void {
		this.closeDialog();
	}

	public onRemove(): void {
		this.removeEvent.emit();
		this.closeDialog();
	}

	private closeDialog(): void {
		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}
}