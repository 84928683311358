import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { StoppingReasonTypeRuleListItem } from '../models/stopping-reason-type-rules/stopping-reason-type-rule-list-item';
import { StoppingReasonTypeRuleDto } from '../models/stopping-reason-type-rules/stopping-reason-type-rule.dto';
import { ChangeEnableStoppingReasonTypeRuleModel } from '../models/stopping-reason-type-rules/change-enable-stopping-reason-type-rule.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KeyValueObject } from '../models/core/KeyValueObject';

@Injectable()
export class StoppingReasonTypeRulesService extends 
    DataService<StoppingReasonTypeRuleListItem, StoppingReasonTypeRuleDto> 
{
	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'stopping-reason-type-rules';
	}

	public changeEnable(model: ChangeEnableStoppingReasonTypeRuleModel) : Observable<any> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/change-enable`;
		return this.postQuery(url, model);
	}

	public getCustomerStoppingRuleOptions(): Observable<KeyValueObject[]> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/customer-stopping-rule-options`;
		return this.getQuery<KeyValueObject[]>(url);
	}
}