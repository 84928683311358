import { RequestCommonData } from './request-common-data';
import { RequestNotification } from './request-notification';

export class RequestListItem {
  requestId: number;
  createdDate: Date;
  createdDateGroupValue: string;
  createdDateMoscow: Date;
  createdDateLocal: Date;
  externalId: string;

  initTypeId: number;
  initTypeName: string;

  requestTypeId: number;
  requestTypeName: string;

  statusId: number;
  statusName: string;
  statusColor: string;

  partnerStatusAlias: string;
  partnerStatus: string;

  hoursToDeadline: string;
  hoursToDeadlineString: string;

  spentHours: string;
  spentHoursString: string;

  slaColorCode: string;

  priorityId: number;
  priorityName: string;
  planStartDateLocal: Date;

  performerUserId: number;
  performerUserName: string;

  performerUserGroupId: number;
  performerUserGroupName: string;

  utcTimezoneShift: number;
  utcTimezoneShiftString: string;

  authorUserId: number;
  authorUserName: string;

  authorUserGroupId: number;
  authorUserGroupName: string;

  customerContragentId: number;
  customerContragentName: string;

  description: string;

  sla: number;
  slaStartDate: Date;
  slaStartDateMoscow: Date;
  slaStartDateLocal: Date;

  slaDueDate: Date;
  slaDueDateMoscow: Date;
  slaDueDateLocal: Date;

  tspId: number;
  tspName: string;
  tspLegalEntityId: number;
  tspLegalEntityName: string;
  tspAddress: string;
  tidNumbers: string;

  deviceModels: string;

  lastActivityStatusId: number;
  lastActivityStatusName: string;

  serviceCenterEngineerId: number;
  serviceCenterEngineerName: string;

  serviceCenterId: number;
  serviceCenterName: string;
  finishDate: Date;
  finishDateMoscow: Date;
  finishDateLocal: Date;
  finishDateFactLocal: Date;
  
  reasonForStopping: string;
  reasonForStoppingId: number;

  stoppedUntil: Date;
  stoppedUntilMoscow: Date;
  stoppedUntilLocal: Date;

  requestCustomerObjectLegalName: string;

  engineerDistricts: string;
  customerObjectDistrictName: string;

  customerObjectLatitude?: number;
  customerObjectLongitude?: number;

  attributeString: string;
  requestAttributeId: number;

  endOfSlaDate: Date;
  endOfSlaDateMoscow: Date;
  endOfSlaDateLocal: Date;

  stateUpdateDate: Date;
  stateUpdateDateMoscow: Date;
  stateUpdateDateLocal: Date;


  lastCriticalNotificationTypeId: number;
  lastCriticalNotificationPriorityId: number;
  lastCriticalNotificationName: string;

  unreadNotificationMaxPriorityId: number;
  requestNotifications: RequestNotification[];

  acquirerId?: number;
  acquirerName: string;

  externalProjectNameAndDate: string;
  externalProjectName: string;
  slaAsTimeSpan: string;
  slaAsTimeSpanString: string;
  reconciliationCompleted: boolean;
  reconciliationCompletedString: string;

  customerCreatedDate?: Date;
  customerCreatedDateLocal?: Date;

  parentExternalId: string;
  childCount?: number;
  isGroup: boolean;
  isInGroupExt: boolean;
  isInGroup: boolean;

  attachmentTypesString: string;
  attachmentTypesCount: number;

  merchantRegionId?: number;
	merchantRegionName: string;

  lastRequestComment: string;
  lastActivityComment: string;
  lastCustomerComment: string;

  hasActivities: boolean;

  /** Удаленность по договору, км */
  distanceFromCityKm?: number;

  /** Фактическая удаленность, км */
  customerObjectDistanceFromCityKm?: number;

  /** Услуги */
  serviceNames: string;

  /** Категории услуг */
  serviceCategoryNames: string;

  /** Типы сервисных услуг */
	serviceTypeNames: string;

  /** Режимы сервисных услуг */
  serviceModeNames: string;

  /** Сервисные услуги выполнены */
  serviceIsDoneNames: string;
  /** Стоимость заявки */
  startPrice: number;

  deliveryStatus: string;

  /** Роль инженера */
  engineerRolesNames: string;

  /**Заявка присутствует в РМС для текущего пользователя */
  isInRms: boolean;

  commonData: RequestCommonData;

  /**Тип статуса последней инженерной активности */
  lastEngineerActivityStatusIsFinal: boolean;

  /**Наименование статуса последней инженерной активности */
  lastEngineerActivityStatusName: string;

  /**Статус последней инженерной активности */
  lastEngineerActivityStatusId: number;

  /**Установленные/демонтированные девайсы */
  completedSerialNumbers: string;

  plannedDateLocal: Date;
}