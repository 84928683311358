import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { tap, filter, take } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { HierarchyLookupModel } from '../../shared/models/core/HierarchyLookupModel';
import { BaseLookupService } from '../../core/services/base.lookup.service';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../core/services/notification.service';

@Injectable()
export class LookupService extends BaseLookupService {

	protected unauthorizedCode = 904;

	constructor(
		public http: HttpClient,
		public notificationService: NotificationService
	) { super(http, notificationService) }

	public getData(controllerName: string, state: DataSourceRequestState,
		options: { notificationType?: NotificationType, isError?: boolean } = { notificationType: NotificationType.SweetAlert, isError: true }): Observable<KeyValueObject[]> {

		return this.getDataByUrl(`${this.getBaseUrl(controllerName)}/lookup${this.getRequestQueryString(state)}`, options);
	}

	public getDataByUrlExt(url: string, state: DataSourceRequestState,
		options: { notificationType?: NotificationType, isError?: boolean } = { notificationType: NotificationType.SweetAlert, isError: true }): Observable<KeyValueObject[]> {

		return this.getDataByUrlExtBase(`${url}${this.getRequestQueryString(state)}`, options);
	}

	public getHierarchyData(controllerName: string, state: DataSourceRequestState, showOnlyIsActiveSc: boolean = false, requestId: number = null): Observable<HierarchyLookupModel[]> {

		return this.http.get<ActionDataResult<HierarchyLookupModel[]>>(`${this.getBaseUrl(controllerName)}/hierarchy-lookup?showOnlyIsActiveSc=${showOnlyIsActiveSc}&requestId=${requestId}${this.getRequestQueryString(state, "&")}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === this.unauthorizedCode)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data)
			;
	}
}
