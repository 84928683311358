import { UploadFile } from '../../attachment/upload-file';
import { CommentKindEnum } from '../../../enums/comment-kind.enum';
import { CommentTypeEnum } from '../../../enums/comment-type.enum';

export class NewCommentModel {
    requestId: number;
    activityId?: number;
    text: string;
    commentKind: CommentKindEnum = CommentKindEnum.internal;
    commentType?: CommentTypeEnum = null;
    uploadAttachments: UploadFile[] = [];
    notifyCustomer: boolean = false;
    notifyEngineer: boolean = false;
    engineerNotifyWays: string[] = [];
}