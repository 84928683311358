import { KeyValueObject } from "../core/KeyValueObject";
import { RequestRegistrationFilterElementValueDto } from "./request-registration-filter-element-value-dto";

export class RequestRegistrationFilterElementDto {
    requestRegistrationFilterElementId: number;
    requestRegistrationFilterId: number;
    requestRegistrationFilterElementTypeId: number;
    requestRegistrationFilterElementOperatorId: number;
    requestRegistrationFilterElementValues: RequestRegistrationFilterElementValueDto[] = [];

    elementTypes: KeyValueObject[] = [];
}