import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../../app.service";
import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { ServiceItem } from "../../../shared/models/services/service-item";
import { CategoryServicesService } from "../../../shared/services/category-services.service";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";
import { StatePersistingService } from "../../../shared/services/state-persisting.service";

@Component({
	selector: 'services-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class ServicesListPage extends ListPageBase<ServiceItem> {

	constructor(
		protected appService: AppService,
		protected router: Router,
		protected dataService: CategoryServicesService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
	    appService.pageTitle = 'Услуги';
  }

	onListPageInit(): void {

	}

	processListItems(items: ServiceItem[]): void {

	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/categories/service', dataItem.id]);
	}

}
