export class ArticleDto {
    id: number;
    articleId: number
    name: string;

    deviceKindValue: string;
    deviceKindId?: number;

    deviceTypeValue: string;
    deviceTypeId?: number;
    deviceTypeName: string;

    vendorName: string;
    vendorId?: number;
    deviceSupplierName: string;

    modelName: string;
    modelId?: number;
    deviceModelName: string;

    version: string;

    hasContactlessReader: boolean;
    hasSecureLink: boolean;

    voltage: number;

    contragentId?: number;

    deviceConnectionTypeName: string;
}