import { Component, Input, OnInit } from '@angular/core';
import { aggregateBy } from '@progress/kendo-data-query';
import { RequestDeviceWarehouseTransferSummaryDto } from '../../../shared/models/request/request-device-warehouse-transfer-summary-dto';
import { RequestsService } from '../../../shared/services/requests.service';

@Component({
	selector: 'warehouse-transfer-summary',
	templateUrl: './warehouse-transfer-summary.component.html',
	styleUrls: ['./warehouse-transfer-summary.component.scss']
})
export class WarehouseTransferSummaryComponent implements OnInit {
	@Input()
	requestId: number;

	fileName: string;

	requestDeviceWarehouseTransferSummary: RequestDeviceWarehouseTransferSummaryDto[] = [];

	public aggregates: any[] = [
		{ field: "quantity", aggregate: "sum" },
	];

	public total: any;

	constructor(private requestsService: RequestsService) {

	}

	ngOnInit(): void {
		this.fileName = `Оборудование_${this.requestId}.xlsx`;
		this.requestsService.getRequestDeviceWarehouseTransferSummary(this.requestId).subscribe(res => {
			this.requestDeviceWarehouseTransferSummary = res;
			this.total = aggregateBy(this.requestDeviceWarehouseTransferSummary, this.aggregates);
		});
	}
}
