import { BaseObject } from "../core/BaseObject";

export class ServiceModeItem extends BaseObject {
	id: number;
	name: string;
	schedule: string;
	serviceModeHoursFrom: number;
	serviceModeHoursTo: number;
	countOfHours: number;
	countOfHoursString: string;
	serviceModeHoursFromDate: Date;
	serviceModeHoursToDate: Date;
	serviceModeHoursFromString: string;
	serviceModeHoursToString: string;
}
