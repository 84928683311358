import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { KendoDataSourceHelper } from '../../core/helpers/kendo-data-source-helper';
import { RequestRegistrationFilterListItemDto } from '../models/request-registration-filter/request-registration-filter-list-item-dto';
import { RequestRegistrationFilterDto } from '../models/request-registration-filter/request-registration-filter-dto';
import { Observable } from 'rxjs';
import { KeyValueObject } from '../models/core/KeyValueObject';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { GetRequestsForCheckQuery } from '../models/request-registration-filter/get-requests-for-check-query';
import { RequestsForCheckDto } from '../models/request-registration-filter/requests-for-check-dto';
import { SendNewRequestsFromGateToRabbitCommand } from '../models/request-registration-filter/send-new-requests-from-gate-to-rabbit-command';
import { RequestRegistrationFilterChangeLogDto } from '../models/request-registration-filter/request-registration-filter-change-log-dto';


@Injectable()
export class RequestRegistrationFiltersService extends DataService<RequestRegistrationFilterListItemDto, RequestRegistrationFilterDto> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService,
		private kendoDataSourceHelper: KendoDataSourceHelper) {
		super(http, notificationService);
		this.controllerName = 'request-registration-filters';
	}

	public requestRegistrationFilterChangeLog(requestRegistrationFilterId: number): Observable<RequestRegistrationFilterChangeLogDto[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${requestRegistrationFilterId}/change-log`);
	}

	public elementTypesLookup(): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/lookup-element-types`);
	}

	public operatorTypesLookup(): Observable<KeyValueObject[]> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/lookup-operator-types`);
	}

	public requestsForCheck(getRequestsForCheckQuery: GetRequestsForCheckQuery): Observable<ActionDataResult<RequestsForCheckDto[]>> {
		return this.http.post<ActionDataResult<RequestsForCheckDto[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/requests-for-check`, getRequestsForCheckQuery)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка получения заявок для проверки',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка получения заявок для проверки',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}

	public sendNewRequestsFromGateToRabbit(sendNewRequestsFromGateToRabbitCommand: SendNewRequestsFromGateToRabbitCommand): Observable<ActionDataResult<any>> {
		return this.http.post<ActionDataResult<any>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/send-new-requests-from-gate-to-rabbit`, sendNewRequestsFromGateToRabbitCommand)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка регистрации отправки задания на регистрация заявок',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка регистрации отправки задания на регистрация заявок',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}));
	}
}