import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';
import { AppService } from '../../../app.service';
import { ComponentPageBase } from '../../../shared/components/component-page-base/component-page-base';
import { RequestPublicationSettingsService } from '../../../shared/services/request-publication-settings.service';
import { RequestPublicationSettingDto } from '../models/request-publication-setting-dto';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { LookupService } from '../../../shared/services/lookup.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { DataSourceRequestState, State } from '@progress/kendo-data-query';
import { ContragentType } from '../../../shared/enums';
import { RequestAutoPublishSettingElementDto } from '../models/request-publication-setting-element-dto';
import { SecurityService } from '../../../core/services/security.service';
import { RequestPublicationSettingChangeLogDto } from '../models/request-publication-setting-changelog-dto';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ServiceCategoriesService } from '../../../shared/services/service-categories.service';

export enum AutoPublisParams {
	contragent = 1,
	requestType = 2,
	slaLeft = 3,
	deviceType = 4,
	vendor = 5,
	agreenmentDistance = 6,
	factDistance = 7,
	description = 8,
	serviceCategory = 9
}

@Component({
	selector: 'automatic-publication-request',
	templateUrl: './automatic-publication-request.page.html',
	styleUrls: [
		'./automatic-publication-request.page.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class AutomaticPublicationRequestPage extends ComponentPageBase<RequestPublicationSettingDto> implements OnInit {

	setting: RequestPublicationSettingDto;
	changeLogs: RequestPublicationSettingChangeLogDto[] = [];

	serviceCenters: KeyValueObject[] = [];

	contragents: KeyValueObject[] = [];
	requestTypes: KeyValueObject[] = [];
	serviceCategories: KeyValueObject[] = [];
	deviceTypes: KeyValueObject[] = [];
	vendors: KeyValueObject[] = [];

	parameters: KeyValueObject[] = [
		new KeyValueObject(AutoPublisParams.contragent, "Клиент"),
		new KeyValueObject(AutoPublisParams.requestType, "Тип заявки"),
		new KeyValueObject(AutoPublisParams.slaLeft, "Остаток SLA, мин"),
		new KeyValueObject(AutoPublisParams.deviceType, "Тип устройства"),
		new KeyValueObject(AutoPublisParams.vendor, "Вендор"),
		new KeyValueObject(AutoPublisParams.agreenmentDistance, "Удаленность по договору, км"),
		new KeyValueObject(AutoPublisParams.factDistance, "Фактическая удаленность, км"),
		new KeyValueObject(AutoPublisParams.description, "Описание"),
		new KeyValueObject(AutoPublisParams.serviceCategory, "Категория обслуживания")
	]

	itemIndex: number = 0;

	availableParameters: KeyValueObject[] = [];

	getAvailableParameters(): void {
		this.availableParameters = this.parameters.filter(x => !this.setting.requestAutoPublishSettingElements.map(x => !!x.type ? x.type.id : null).includes(x.id));
	}

	isDict(typeId: number): boolean {
		return [AutoPublisParams.contragent,
			AutoPublisParams.requestType,
			AutoPublisParams.deviceType,
			AutoPublisParams.vendor,
			AutoPublisParams.serviceCategory]
			.includes(typeId);
	}

	isInt(typeId: number): boolean {
		return [AutoPublisParams.slaLeft,
			AutoPublisParams.agreenmentDistance,
			AutoPublisParams.factDistance]
			.includes(typeId);
	}

	isStr(typeId: number): boolean {
		return [AutoPublisParams.description]
			.includes(typeId);
	}

	elementTypeValueChange(element: RequestAutoPublishSettingElementDto): void {
		this.getAvailableParameters();
		element.values = [];

		if (this.isInt(element.type.id) || this.isStr(element.type.id))
			element.values.push(new KeyValueObject(null, null));

		this.getOperators(element.type.id, element);
		this.getData(element);
	}

	operators: KeyValueObject[] = [
		new KeyValueObject(1, "Входит в"),
		new KeyValueObject(2, "Не входит в"),
		new KeyValueObject(3, "Больше"),
		new KeyValueObject(4, "Меньше")
	]

	getOperators(typeId: number, element: RequestAutoPublishSettingElementDto): void {
		if (this.isDict(typeId) || this.isStr(typeId)) {
			element.operators = this.operators.filter(x => [1,2].includes(x.id));
			element.operator = !!element.operator && element.operators.map(x => x.id).includes(element.operator.id) ? element.operator : this.operators.find(x => x.id === 1);
		} else if (this.isInt(typeId)) {
			element.operators = this.operators.filter(x => [3,4].includes(x.id));
			element.operator = !!element.operator && element.operators.map(x => x.id).includes(element.operator.id) ? element.operator : this.operators.find(x => x.id === 3);
		} else {
			element.operators = [];
		}
	}

	onMultiselectOpen(element: RequestAutoPublishSettingElementDto): void {
		this.getData(element);
	}

	elementValueChanged(element: RequestAutoPublishSettingElementDto): void {
		let serviceCategoriesElement = this.setting.requestAutoPublishSettingElements.find(x => !!x.type ? x.type.id == AutoPublisParams.serviceCategory : false)
		if (element.type.id === AutoPublisParams.contragent && !!serviceCategoriesElement) {
			if (element.values.length > 0) {
				this.serviceCategoriesService.getByContragentsIds(element.values.map(x => x.id))
					.subscribe(resp => {
						this.serviceCategories = resp.data;
						serviceCategoriesElement.data = this.serviceCategories;

						let valuesToDelete: number[] = [];
						serviceCategoriesElement.values.forEach(x => {
							if (!serviceCategoriesElement.data.map(x => x.id).includes(x.id)) {
								valuesToDelete.push(x.id);
							}
						});
						serviceCategoriesElement.values = serviceCategoriesElement.values.filter(x => !valuesToDelete.includes(x.id));
					});
			} else {
				this.serviceCategories = [];
				serviceCategoriesElement.data = [];
				serviceCategoriesElement.values = [];
			}
		}
	}

	getData(element: RequestAutoPublishSettingElementDto): void {
		switch (element.type.id) {
			case 1:
				if (this.contragents.length === 0)  {
					var clientsState: DataSourceRequestState = { skip: 0, take: null, filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: ContragentType.bankEquer }] }};
					this.lookupService.getData("contragents", clientsState).subscribe(data => {
						this.contragents = data
						element.data = this.contragents;
					});
				} else {
					element.data = this.contragents;
				}
				break;
			case 2:
				if (this.requestTypes.length === 0)  {
					this.lookupService.getData("request-types", null).subscribe(data => {
						this.requestTypes = data;
						element.data = this.requestTypes;
					});
				} else {
					element.data = this.requestTypes;
				}
				break;
			case 4:
				if (this.deviceTypes.length === 0)  {
					this.lookupService.getData("device-types", null).subscribe(data => {
						this.deviceTypes = data;
						element.data = this.deviceTypes;
					});
				} else {
					element.data = this.deviceTypes;
				}
				break;
			case 5:
				if (this.vendors.length === 0)  {
					this.lookupService.getHierarchyData("device-models", null).subscribe(data => {
						this.vendors = data.filter(x => x.parentId == null).map(x => new KeyValueObject(x.id, x.name));
						element.data = this.vendors;
					});
				} else {
					element.data = this.vendors;
				}
				break;

			case 9:
				let contragentElement  = this.setting.requestAutoPublishSettingElements.find(x => !!x.type ? x.type.id === AutoPublisParams.contragent : false);
				if (!!contragentElement && contragentElement.values.length > 0)
				{
					if (this.serviceCategories.length === 0)  {
						this.serviceCategoriesService.getByContragentsIds(contragentElement.values.map(x => x.id))
							.subscribe(resp => {
								this.serviceCategories = resp.data;
								element.data = this.serviceCategories;
							});
					} else {
						element.data = this.serviceCategories;
					}
				} else {
					this.serviceCategories = [];
					element.values = [];
					element.data = [];
				}
				
				break;
		}
	}

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	public historyState: State = { sort: [{ field: 'changeDate', dir: 'desc' }] };
	
	elementCount: number = 0;

	diasbleAddButton: boolean = false;
	
	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: RequestPublicationSettingsService,
		private lookupService: LookupService,
		private securityService: SecurityService,
		private serviceCategoriesService: ServiceCategoriesService,
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'admin/automatic-publication-requests';
		this.appService.pageTitle = 'Новая настройка';
	}

	ngOnInit() {
		this.changeLogs = [];
		this.entityId = this.route.snapshot.paramMap.get('requestPublicationSettingId');

		if (this.entityId != null) {

			this.dataService.settingById(+this.entityId).subscribe(r => {
				this.setting = r;

				this.setting.serviceCenter = new KeyValueObject(this.setting.serviceCenterId, this.setting.serviceCenterName);
				this.setting.serviceCenter.integraCode = this.setting.serviceCenterIntegraCode;

				this.setting.requestAutoPublishSettingElements.forEach(e => {
					e.type = new KeyValueObject(e.typeId, this.parameters.find(x => x.id === e.typeId).name);

					e.operator = new KeyValueObject(e.operatorId, this.operators.find(x => x.id === e.operatorId).name)
					this.getOperators(e.typeId, e);

					e.values = [];
					e.requestAutoPublishSettingValues.forEach(v => {
						e.values.push(!!v.valueId
							? { id: v.valueId, name: v.value, integraCode: v.integraCode } as KeyValueObject
							: { id: null, name: v.value, integraCode: null } as KeyValueObject);
					});
				});

				this.getAvailableParameters();
			});
		} else {

			this.setting = new RequestPublicationSettingDto();
			this.canEditForm = true;
			this.isNew = true;
		}

		this.lookupService.getData("service-centers", null).subscribe(data => this.serviceCenters = data);
	}

	saveChanges() {
		this.dataSaving = true;
		
		this.dtoPreparation();

		const action = this.isNew
			? this.dataService.addSetting(this.setting)
			: this.dataService.editSetting(this.setting);

		action.subscribe(res => {
			this.dataSaving = false;

			if (!res.isSuccessful)
				return;

			this.appService.MarkFormPristine(this.entityForm);
			this.canEditForm = false;
			this.entityId = res.data.id;
			this.router.navigate(['/admin/automatic-publication-request/', this.entityId]);

			if (!this.isNew) {
				this.ngOnInit();
			}

		}, () => this.dataSaving = false);
	}

	private dtoPreparation(): void {

		this.setting.userId = this.securityService._currentUser.userId;

		this.setting.serviceCenterId = this.setting.serviceCenter.id;
		this.setting.serviceCenterIntegraCode = this.setting.serviceCenter.integraCode;

		this.setting.requestAutoPublishSettingElements.forEach(e => {
			e.typeId = e.type.id;
			e.operatorId = e.operator.id;

			e.requestAutoPublishSettingValues = [];
			e.values.forEach(v => {
				e.requestAutoPublishSettingValues.push(!!v.id
					? { valueId: v.id, value: null, integraCode: v.integraCode}
					: { valueId: null, value: v.name, integraCode: null})
			});
		});
	}

	public addElement() {
		let newElement = { id: this.itemIndex} as RequestAutoPublishSettingElementDto;
		this.setting.requestAutoPublishSettingElements.push(newElement);
		this.itemIndex++;
		this.getAvailableParameters();
	}

	public removeElement(elementId: number) {
		let serviceCategoryElement = this.setting.requestAutoPublishSettingElements.find(x => !!x.type ? x.type.id == AutoPublisParams.serviceCategory : false);
		let element = this.setting.requestAutoPublishSettingElements.find(x => x.id === elementId);
		if ((!!element.type ? element.type.id === AutoPublisParams.contragent : false) && !!serviceCategoryElement) {
			this.serviceCategories = [];
			serviceCategoryElement.data = [];
			serviceCategoryElement.values = [];
		}

		this.setting.requestAutoPublishSettingElements = this.setting.requestAutoPublishSettingElements.filter(x => x.id !== elementId);
		this.appService.MarkFormDirty(this.entityForm);
		this.getAvailableParameters();
	}

	trackByFn(index, item) {
		if (!!item) {
			if (!!item.id) {
				return item.id;
			} else {
				item.id = Math.random();
				return item.id;
			}
		}

		return null;
	}

	askRemove() {

		this.notificationService.confirmation({
			title: this.entityName,
			message: 'Вы уверены что хотите удалить элемент?',
			type: 'question',
			confirmButtonText: 'Да',
			cancelButtonText: 'Нет',
			showCloseButton: true
		}, () => {

			this.dataService.deleteSetting(+this.entityId, `Настройка для автопубликации [${this.setting.name}]`, 'Элемент успешно удален').subscribe(r => {
				if (r.isSuccessful)
					this.router.navigate(['/admin/automatic-publication-requests/']);
			});

		});
	}

	tabChanged(e: NgbTabChangeEvent) {
		if (e.nextId == 'history' && this.changeLogs.length === 0) {
			this.dataService.settingChangeLogs(+this.entityId).subscribe(r => {
				this.changeLogs = r;
			});
		}
	}
}

