import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { NotificationService } from "../../../core/services/notification.service";
import { EngubatorPlanningZone as EngubatorPlanningZone } from "../../models/engubator/engubator.planning-zone";
import { EngubatorDataService } from "./engubator.data.service";

@Injectable()
export class EngubatorPlanningZonesService extends EngubatorDataService<EngubatorPlanningZone, EngubatorPlanningZone>{
    constructor(http: HttpClient, notificationService: NotificationService) {
        super(http, notificationService);
        this.controllerName = 'planning-zones';
				this.controllerVersion = 2;
    }

    protected getNewTItem(): EngubatorPlanningZone {
        return new EngubatorPlanningZone();
    }
}
