import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../core/services/notification.service";
import { ReportsService } from "../../shared/services/reports.service";
import { ReportPageBase } from "../components/report-page-base";
import { ExpertizeByPeriodItem } from "../../shared/models/reports/expertiseperioditem";
import { ExcelExportComponent, ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { saveAs } from '@progress/kendo-file-saver';
import { AppService } from "../../app.service";

@Component({
	selector: 'expertiseperiod-page',
	templateUrl: './expertiseperiod.page.html',
	styleUrls: [
		'./expertiseperiod.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ExpertisePeriodReport extends ReportPageBase implements OnInit {

	pageSize: number = 100;
	loading: boolean = false;
	exportInProgress: boolean = false;

	constructor(
		private reportsService: ReportsService,
		private notificationService: NotificationService,
		private appService: AppService
	) {
		super();
		this.allData = this.allData.bind(this);
		this.generateReport = this.generateReport.bind(this);
		appService.pageTitle = 'Отчёт по экспертизам за период';
	}

	ngOnInit(): void {

	}
	data: ExpertizeByPeriodItem[]

	generateReport() {
		this.dataGenerating = true;
		this.generateReportByDateRange((startDate, endDate) => {

			this.reportsService.getExpertisesPeriod(startDate, endDate)
				.subscribe(result => {
					result.data.map((item, _) => {
						item.isTargetedStr = item.isTargeted ? 'Да' : 'Нет'
					});

					this.dataGenerating = false;
					this.data = result.data;
				});

		});

	}

	exportToExcel(excelExport: ExcelExportComponent) {
		this.exportInProgress = true;
		
		excelExport.toDataURL().then((dataURL: string) => {
			saveAs(dataURL, excelExport.fileName);
			this.exportInProgress = false;
		});
	}

	public allData(): ExcelExportData {
		const result: ExcelExportData = {
			data: process(this.data, {
				sort: [{ field: "sutsRequestNum", dir: "desc" }],
			}).data
		};
		return result;
	}
	public dateCells = {
		format: 'dd.MM.yyyy HH:mm'
	}

}
