import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RequestNotification } from '../../../shared/models/request/request-notification';
import { RequestsService } from '../../../shared/services/requests.service';

@Component({
	selector: 'request-notifications',
	templateUrl: './request-notifications.component.html',
	styleUrls: [
		'./request-notifications.scss',
		'../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss'
	]
})
export class RequestNotificationsComponent implements OnInit {

	@Input()
	public requestId: number;

	public hasNotReadNotification: boolean = false;
	public notifications: RequestNotification[] = [];

	constructor(
		private requestsService: RequestsService
	) {

	}

	ngOnInit(): void {
		this.loadNotifications();
	}

	loadNotifications(): void {
		this.requestsService.getNotifications(this.requestId)
			.subscribe(data => {
				if (!!data) {
					this.notifications = data;
					this.hasNotReadNotification = data.filter(x => !x.isRead).length > 0;
				}
			});
	}
}
