import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { filter, tap } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { GetTmsModel } from '../components/workflow-transitions/configuration-preparing/get-tms.model';
import { GetRegionsModel } from '../components/workflow-transitions/configuration-preparing/get-regions.model';
import { TmsParamsModel } from '../components/workflow-transitions/configuration-preparing/tms-params.model';
import { TemplateEntity } from '../components/workflow-transitions/configuration-preparing/TemplateEntity';

@Injectable()
export class TmsGateDataService {

    constructor(public http: HttpClient,
        public notificationService: NotificationService) {
    }

	private controllerVersion = 1;

	public getTmsListFromTms(contragentId: number, region: string = null, vendor: string = null, model: string = null): Observable<GetTmsModel> {
		let paramsStr = `?contragentId=${contragentId}`;
		if (!!region)
			paramsStr += `&region=${region}`
		if (!!vendor)
			paramsStr += `&vendor=${vendor}`
		if (!!model)
			paramsStr += `&model=${model}`

		return this.http.get<ActionDataResult<GetTmsModel>>(`${environment.tmsgateAddress}v${this.controllerVersion}/tms${paramsStr}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data);
	}

	public getTemplatesFromTms(tmsId: number): Observable<TemplateEntity[]> {
		return this.http.get<ActionDataResult<TemplateEntity[]>>(`${environment.tmsgateAddress}v${this.controllerVersion}/templates?tmsId=${tmsId}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data);
	}

    public getTerminalsInDatabaseFromTms(tmsId: number): Observable<TemplateEntity[]> {
        return this.http.get<ActionDataResult<TemplateEntity[]>>(`${environment.tmsgateAddress}v${this.controllerVersion}/templates?tmsId=${tmsId}`)
            .pipe(tap(resp => {

                if (!resp.isSuccessful) {
                    this.notificationService.error({
                        title: 'Ошибка',
                        message: resp.errorDescription,
                        notificationType: NotificationType.SweetAlert
                    });
                }

            }, errorResponse => {

                if (errorResponse.status === 904)
                    return;

                this.notificationService.error({
                    title: 'Ошибка',
                    message: errorResponse.error.errorDescription,
                    notificationType: NotificationType.SweetAlert
                });

            }))
            .pipe(filter(x => x.isSuccessful))
            .map(x => x.data);
    }

    public getParamsFromTms(tmsId: number): Observable<TmsParamsModel[]> {
        return this.http.get<ActionDataResult<TmsParamsModel[]>>(`${environment.tmsgateAddress}v${this.controllerVersion}/params?tmsId=${tmsId}`)
            .pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(filter(x => x.isSuccessful))
			.map(x => x.data);
	}

	public getRegionsFromTms(regionName: string): Observable<GetRegionsModel> {
		return this.http.get<ActionDataResult<GetRegionsModel>>(`${environment.tmsgateAddress}v${this.controllerVersion}/regions?regionName=${regionName}`)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

            }))
            .pipe(filter(x => x.isSuccessful))
            .map(x => x.data);
    }
}
