import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// external libs
import {pluck, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

// services
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';

// models
import {ActionDataResult} from '../models/core/ActionDataResult';
import {NotificationType} from '../../core/services/notification-type';
import {DeviceValidation} from '../models';
import { NewRequestDevice } from '../models/request/new-request/new-request-device';

import {environment} from '../../../environments/environment';
import { ActionResult } from '../models/core/ActionResult';

@Injectable()
export class RequestUninstallDevicesService extends DataService<NewRequestDevice, NewRequestDevice> {

  constructor(http: HttpClient, notificationService: NotificationService) {
    super(http, notificationService);
    this.controllerName = 'request-uninstall-devices';
  }

	public validateSnPostQuery(body: any): Observable<DeviceValidation> {
  	const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/validate-devices`;
		return this.http.post<ActionDataResult<DeviceValidation>>(url, body)
			.pipe(tap(resp => {
				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}
			}, errorResponse => {
				if (errorResponse.status === 904) {
					return;
				}
				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});
			}),
				pluck('data'));
	}

	public hasAttachmentCount(requestId: number): Observable<boolean> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/has-attachment-count/${requestId}`;
		return this.getQuery<boolean>(url);
	}

	public validateDeviceToWarehouseAction(body: any): Observable<DeviceValidation> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/validate-device-to-warehouse`;
		return this.postQuery<DeviceValidation>(url, body).pipe(pluck('data'));
	}

	public validateReceivingDeviceAtWarehouseAction(body: any): Observable<DeviceValidation> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/validate-receiving-device-at-warehouse`;
		return this.postQuery<DeviceValidation>(url, body).pipe(pluck('data'));
	}

	public getUndefinedDevice(requestId: number, deviceStatusId: number): Observable<NewRequestDevice> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/undefined-device/${requestId}/${deviceStatusId}`;
		return this.getQuery<NewRequestDevice>(url);
	}

	public сreateRequestUninstallDeviceFromEth(body: any): Observable<ActionResult> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/create-request-uninstall-device-from-eth`;
		return this.http.post<ActionResult>(url, body)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка создания демонтируемого оборудования',
						message: resp.errorDescription,
						notificationType: NotificationType.Toast
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка создания демонтируемого оборудования',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.Toast
				});

			}));
	}
}
