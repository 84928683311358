import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { LaddaModule } from 'angular2-ladda';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { MerchantsListPage } from './merchants/list/list.page';
import { MerchantPage } from './merchants/merchant/merchant.page';
import { MerchantsService } from './merchants/merchants.service';

import { TidsListPage } from './tids/list/list.page';
import { TidPage } from './tids/tid/tid.page';
import { TidImportPage } from './tids/import/tid-import.page';

import { TidsService } from '../shared/services/tids.service';

import { CustomerObjectsListPage } from './customer-objects/list/list.page';
import { CustomerObjectPage } from './customer-objects/customer-object/customer-object.page';
import { CustomerObjectsService } from './customer-objects/customer-objects.service';

import { CustomerObjectForm } from './customer-objects/customer-object/customer-object-form/customer-object.form';

import { ExcelModule } from '@progress/kendo-angular-grid';

import { MerchantForm } from './merchants/merchant/merchant-form/merchant.form';
import { MerchantFormFull } from './merchants/merchant/merchant-form-full/merchant.form.full';
import { MerchantsFullService } from './merchants/merchant.full.service';
import { CallsListPage } from './calls/list/list.page';

import { ServicesListPage } from './services/list/list.page';
import { ServicePage } from './services/service/service.page';

import { ServiceModesListPage } from './service-modes/list/list.page';
import { ServiceModePage } from './service-modes/service-mode/service-mode.page';
import { ServiceModeForm } from './service-modes/service-mode/service-mode-form/service-mode.form';

import { ServiceCategoriesListPage } from './service-categories/list/list.page';
import { ServiceCategoryPage } from './service-categories/service-category/service-category.page';

import { ServiceTypeListPage } from './service-types/list/list.page';
import { ServiceTypePage } from './service-types/service-type/service-type.page';

import { AgreementListPage } from './agreements/list/list.page';
import { AgreementPage } from './agreements/agreement/agreement.page';
import { AddBulkAgreementServicesForm } from './agreements/components/add-bulk-agreement-services.component';

import { SLABorderForm } from './agreements/components/sla-border.component';
import { Documents } from './agreements/components/documents.component';
import { CalcDistance } from './agreements/components/calc-distance.component';
import { Services } from './agreements/components/services.component';
import { AttachmentTypeConfigurationPage } from './attachment-type-configurations/attachment-type-configuration/attachment-type-configuration.page';
import { AttachmentTypeConfigurationsListPage } from './attachment-type-configurations/list/list.page';
import { AttachmentTypePage } from './attachment-types/attachment-type/attachment-type.page';
import { AttachmentTypesListPage } from './attachment-types/list/list.page';

import { ColorSchemeSettingsPage } from './color-scheme-settings/color-scheme-settings.page';

import { WarehousesListPage } from './warehouses/list/list.page';
import { WarehousePage } from './warehouses/warehouse/warehouse.page';

import { ContragentsService } from './contragents/contragents.service';
import { ContragentsListPage } from './contragents/list/list.page';
import { ContragentPage } from './contragents/contragent/contragent.page';
import { ContragentForm } from './contragents/contragent/contragent-form/contragent.form';
import { ContragentDetailsComponent } from "./contragents/contragent/components/details/contragent-details.component";

import {ContragentAgreementsComponent} from "./contragents/contragent/components/agreements/contragent-agreements.component";
import {ContragentDetailsFormComponent} from "./contragents/contragent/components/details-form/details-form.component";
import {ContragentProjectsComponent} from "./contragents/contragent/components/projects/contragent-projects.component";
import { RequestStoppingReasonsListPage } from './request-stopping-reasons/list/list.page';
import { RequestStoppingReasonPage } from './request-stopping-reasons/request-stopping-reason/request-stopping-reason.page';
import { StoppingReasonTypeRulesListPage } from './stopping-reason-type-rules/list/list.page';
import { StoppingReasonTypeRulePage } from './stopping-reason-type-rules/stopping-reason-type-rule/stopping-reason-type-rule.page';
import { NomenclaturePage } from './nomenclature/nomenclature.page';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { NomenclatureListPage } from './nomenclature/list/list.page';
import { AddServiceCenterRegionPage } from './contragents/contragent/add-service-center-region/add-service-center-region.page';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { OsmRegionsGridPage } from './contragents/contragent/osm-regions-grid/osm-regions-grid.page';


@NgModule({
	declarations: [
		ContragentsListPage,
		ContragentPage,
		ContragentForm,
		TidsListPage,
		TidPage,
		TidImportPage,
		MerchantsListPage,
		MerchantPage,
		CustomerObjectsListPage,
		CustomerObjectPage,
		CustomerObjectForm,
		MerchantForm,
		MerchantFormFull,
		CallsListPage,
		ServicesListPage,
		ServicePage,
		ServiceModesListPage,
		ServiceModePage,
		ServiceModeForm,
		ServiceCategoriesListPage,
		ServiceCategoryPage,
		ServiceTypeListPage,
		ServiceTypePage,
		AgreementListPage,
		AgreementPage,
		AddBulkAgreementServicesForm,
		SLABorderForm,
		Documents,
		CalcDistance,
		Services,
		ColorSchemeSettingsPage,
		AttachmentTypesListPage,
		AttachmentTypePage,
		AttachmentTypeConfigurationsListPage,
		AttachmentTypeConfigurationPage,
		WarehousesListPage,
		WarehousePage,
		RequestStoppingReasonsListPage,
		RequestStoppingReasonPage,
		ContragentDetailsComponent,
		ContragentAgreementsComponent,
		ContragentProjectsComponent,
		ContragentDetailsFormComponent,
		StoppingReasonTypeRulesListPage,
		StoppingReasonTypeRulePage,
		NomenclatureListPage,
		NomenclaturePage,
		AddServiceCenterRegionPage,
		OsmRegionsGridPage
	],
	entryComponents: [
		CustomerObjectForm,
		ContragentForm,
		MerchantForm,
		MerchantFormFull,
		ServiceModeForm,
		AddBulkAgreementServicesForm,
		SLABorderForm,
		CalcDistance,
		Services,
		Documents,
		ContragentDetailsComponent,
		ContragentAgreementsComponent,
		ContragentProjectsComponent,
		ContragentDetailsFormComponent,
		AddServiceCenterRegionPage,
		OsmRegionsGridPage
	],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		RouterModule,
		PasswordStrengthBarModule,
		LaddaModule,
		ExcelModule,
		PerfectScrollbarModule,
		ReactiveFormsModule,
		ListViewModule,
		TreeViewModule,
		TextBoxModule
	],
	providers: [
		MerchantsService,
		MerchantsFullService,
		TidsService,
		CustomerObjectsService,
		ContragentsService
	],
	exports: [
	]
})
export class DirectoriesModule { }
