import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { RequestFile } from '../models/attachment/request-file';
import { environment } from '../../../environments/environment';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { Observable } from 'rxjs-compat/Observable';

@Injectable()
export class RequestFilesService extends DataService<RequestFile, RequestFile> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'request-files';
	}

	public create(file: RequestFile): Observable<any> {

		return this.http.post(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}`, file, {
				reportProgress: true,
				observe: "events"
			});

	}

	public getFilePreview(fileId: number): Observable<ActionDataResult<string>> {
		return this.getQuery(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/${fileId}/preview`);
	}
}