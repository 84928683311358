import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { ColorSchemeSettings } from '../models/color-scheme-settings/color-scheme-settings';

@Injectable()
export class ColorSchemeSettingsService extends DataService<ColorSchemeSettings, ColorSchemeSettings> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'color-scheme-settings';
	}
}