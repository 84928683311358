import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationService } from "../../../core/services/notification.service";
import { EngubatorDataService } from "./engubator.data.service";
import { ActionDataResult } from "../../models/core/ActionDataResult";
import { VeerouteRequestPlanningDto } from "../../models/engubator/veeroute-request-planning-dto";
import { VeerouteRequestPlanningResult } from "../../models/engubator/veeroute-request-planning-result";
import { Observable } from 'rxjs';
import 'rxjs/Rx';

@Injectable()
export class EngubatorPlanningService extends EngubatorDataService<any, any>{
    constructor(http: HttpClient, notificationService: NotificationService) {
        super(http, notificationService);
			this.controllerName = 'planning';
	}


	public planVeeroute(veerouteRequestPlanningDto: VeerouteRequestPlanningDto): Observable<ActionDataResult<VeerouteRequestPlanningResult>> {

		var result = this.http.post<ActionDataResult<VeerouteRequestPlanningResult>>(this.baseUrl() + "/veeroute", veerouteRequestPlanningDto);

		return result;
	}

	public getResultVeeroutePlanForRequests(veerouteRequestPlanningResult: VeerouteRequestPlanningResult) {
		var headersToAdd = new HttpHeaders();
		headersToAdd.append("accept", "text/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
		this.http.get(this.baseUrl() + "/" + veerouteRequestPlanningResult.sessionId + "/requests", { headers: headersToAdd, responseType: 'blob' as 'json' }).subscribe(
			(response: any) => {
				let dataType = response.type;
				let binaryData = [];
				binaryData.push(response);
				let downloadLink = document.createElement('a');
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
				downloadLink.setAttribute('download', "result.xlsx");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	private downloadFile(data: any) {
		const blob = new Blob([data], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		window.open(url);
	}
	
	protected getNewTItem() {
		return {};
	}
}
