export class RegionModel {
	serviceCenterRegionId: number;
	osmRegionId: number;

	region: string;
	state: string;
	county: string;
	city: string;
	town: string;
	village: string;
    city_district: string;
	suburb: string;
}