import { Observable, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { ConfirmAnalyzedSerialImagesData } from "./confirm-analyzed-images.data";
import { Component, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'confirm-analyzed-images',
    templateUrl: './confirm-analyzed-images.component.html',
    styleUrls: ['./confirm-analyzed-images.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class ConfirmAnalyzedSerialImagesComponent extends BaseFlowComponent<ConfirmAnalyzedSerialImagesData> {
    
    @Output() cancelAnalyzingEvent: EventEmitter<any> = new EventEmitter();
    @Output() manualCorrectEvent: EventEmitter<any> = new EventEmitter();

    initData() { }

    saveChanges(): Observable<any> {
        return of({ });
    }   

    cancelAnalyzing() {
        this.cancelAnalyzingEvent.emit();
    }

    manualCorrect() {
        this.manualCorrectEvent.emit();
    }
}