import { BaseObject } from '../core/BaseObject';
import { UserRole } from './UserRole';
import { UserGroup } from "./user-group";
import { UserServiceCenter } from "./user-service-center";
import { ServiceBoundary } from "./ServiceBoundary"
import { SelfEmployedData } from './selfEmployedData';
import { UserImage } from './userImage';

export class User extends BaseObject {
	userId: number;
	surname: string;
	name: string;
	middleName: string;
	displayName: string;

	fullAddress: string;

	email: string;
	login: string;
	passwordHash: string;
	isBlocked: boolean;
	blockedReason: string;
	contragentId: number;
	serviceCenterId: number;

	performerUserGroupId: number;
	performerUserGroupName: string;
	isDefaultUserForGroup: boolean;
	integraCode: string;

	contragentName: string;
	serviceCenterName: string;

	blockchainAddress: string;

	warehouseIds: number[] = [];

	lastLoginMoscow: Date;
	createdDateMoscow: Date;

	userRoles: UserRole[] = [];
	userGroups: UserGroup[] = [];
	userServiceCenters: UserServiceCenter[] = [];
	serviceBoundaries: ServiceBoundary[] = [];
	hasServiceBoundariesFilter: boolean;

	selfEmployedData: SelfEmployedData;
	userImage: UserImage;
	mkStatus: string;
	isEngineer: boolean;
	isSelfEmployed: boolean;

	telegramUserName: string;
}
