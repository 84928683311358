import { Component, Input } from '@angular/core';
import { AppService } from '../../../app.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { NotificationService } from '../../../core/services/notification.service';
import { Activity } from '../../../shared/models/activity/activity';
import { ActivitiesService } from '../../../shared/services/activities.service';
import { Request } from '../../../shared/models/request/request';
import { UsersService } from '../../../admin/users/users.service';
import { CustomerContact } from '../../../shared/models/request/customer-contact';
import { NotificationType } from '../../../core/services/notification-type';

@Component({
	selector: 'activity-details',
	templateUrl: './activity-details.component.html',
	styleUrls: [
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class ActivityDetailsComponent {

	@Input()
	activity: Activity;

	@Input()
	request: Request;

	constructor(
		private appService: AppService,
		private dialogService: DialogService,
		private notificationService: NotificationService,
		private activitiesService: ActivitiesService,
		private usersService: UsersService,
	) { }

	callToTsp(сustomerContact: CustomerContact){

		this.usersService.callToTsp(сustomerContact).subscribe(x => {

			if (x.isSuccessful) {
				
				this.notificationService.success({
					title: '',
					message: `Устанавливается соединение... Ожидайте входящего звонка`,
					notificationType: NotificationType.SweetAlert
				});

			}
			
		});

	}

	get isMobileDevice(): boolean{
		return this.appService.isMobileDevice;
	}

}