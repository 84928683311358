import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NewRequestService } from '../../models/request/new-request/new-request-service';
import { AppService } from '../../../app.service';
import { KeyValueDataObject } from '../../models/core/KeyValueDataObject';
import { ServiceAgreementInfo } from '../../models/services/service-agreement-info';
import { AgreementsService } from '../../services/agreements.service';
import { KeyValueObject } from '../../models/core/KeyValueObject';
import { RequestsService } from '../../services/requests.service';
import { RequestServicesService } from '../../services/request-services.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { SecurityService } from '../../../core/services/security.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NotificationService } from '../../../core/services/notification.service';
import { NotificationType } from '../../../core/services/notification-type';
import { RequestTypeKind } from '../../enums/request-type-kind.enum';

@Component({
	selector: 'request-services',
	templateUrl: './request-services.component.html',
	styleUrls: [
		'./request-services.component.scss'
	]
})
export class RequestServicesComponent implements OnInit {
	@ViewChild("servicesGrid") public servicesGrid: GridComponent;
	
	@Input()
	requestId: number;

	@Input()
	requestServices: NewRequestService[] = [];

	@Input()
	tidServiceCategoryId?: number;

	@Input()
	isAddButtonVisible: boolean = true;

	@Input()
	isAddButtonDisabled: boolean = false;

	@Input()
	isCompleteColumnVisible: boolean = false;

	@Input()
	isRegimeColumnVisible: boolean = false;

	@Input()
	isRemoveButtonVisible: boolean = true;

	@Input()
	isEditButtonVisible: boolean = true;

	@Input()
	gridHeight: number = 500;

	@Input()
	canEditForm: boolean = false;

	@Input()
	get agreementId(): number {
		return this._agreementId;
	}

	@Input()
	isNewRequest: boolean = false;

	@Input()
	requestTypeKindId: number;

	set agreementId(newAgreementId: number) {
		this._agreementId = newAgreementId;

		if (this.isNewRequest)
			this.loadServicesByAgreement(this._agreementId, this._requestTypeId);
	}

	@Input()
	get requestTypeId(): number {
		return this._requestTypeId;
	}

	set requestTypeId(newRequestTypeId: number) {
		this._requestTypeId = newRequestTypeId;

		if (this.isNewRequest)
			this.loadServicesByAgreement(this._agreementId, this._requestTypeId);
	}

	@Output()
	markFormDirty = new EventEmitter();

	_agreementId: number;
	_requestTypeId: number;


	isSeviceGridEditing: boolean = false;

	dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	agreementServices: KeyValueDataObject<ServiceAgreementInfo[]>[] = [];
	agreementCategories: KeyValueObject[] = [];
	agreementServiceTypes: KeyValueObject[] = [];
	allowedAgreementCategories: KeyValueObject[] = [];
	allowedAgreementServiceTypes: KeyValueObject[] = [];

	constructor(private requestsService: RequestsService,
		private agreementsService: AgreementsService,
		private requestServicesService: RequestServicesService,
		private securityService: SecurityService,
		protected appService: AppService,
		protected notificationService: NotificationService) {

	}

	get createNewService(): any {
		let self = this;
		return function () {
			var service = new NewRequestService();
			if (self.isUserClient) {
				if (self.agreementServices.length === 1) {
					service.serviceId = self.agreementServices[0].id;
					self.serviceChange(service.serviceId, service);
				}
			}
			return service;
		};
	}

	get isUserClient(): boolean {
		return this.securityService.isClient();
	}

	ngOnInit(): void {
		this.loadServicesByAgreement(this._agreementId, this._requestTypeId);
	}

	getNameById(data: KeyValueObject[], id: number): string {
		return this.appService.getNameById(data, id);
	}

	public rowClass() {
		return {
			'cursor-pointer': true
		};
	}

	public cellClick({ sender, column, rowIndex, dataItem }: any): void {

		if (!this.canEditForm || !column.editor || this.isSeviceGridEditing) {
			return;
		}

		this.isSeviceGridEditing = true;

		var selectedService = dataItem as NewRequestService;
		if (!!selectedService) {
			this.serviceChange(selectedService.serviceId, selectedService);
		}

		sender.editRow(rowIndex);

	}

	serviceChange(value: number, dataItem: NewRequestService) {
		let service = null;

		if (!!value && !!dataItem) {
			service = this.agreementServices.find((v, i) => v.id === value);
		}

		if (service && service.data && service.data.length > 0) {
			this.calcAllowedServiceCategories(service);
			this.calcAllowedServiceTypes(service);

			dataItem.serviceCategoryId =
				!!this.tidServiceCategoryId && this.allowedAgreementCategories.some(ac => ac.id == this.tidServiceCategoryId)
					? this.tidServiceCategoryId
					: service.data[0].serviceCategoryId;

			dataItem.serviceTypeId = service.data[0].serviceTypeId;
		} else {
			if (!!dataItem) {
				dataItem.serviceCategoryId = null;
				dataItem.serviceTypeId = null;
			}

			this.allowedAgreementCategories = [];
			this.allowedAgreementServiceTypes = [];
		}
	}

	serviceDataStateChange(): void {
		this.isSeviceGridEditing = false;
	}

	get isExistingRequest(): boolean {
		return !!this.requestId && this.requestId > 0;
	}

	public saveHandler(obj: any) {
		if (this.isExistingRequest) {
			var dataItem = obj.dataItem;

			var isNew = !!dataItem.requestServiceId;

			dataItem.requestId = this.requestId;
			var observable = isNew
				? this.requestServicesService.update(dataItem, 'Услуга обновлена')
				: this.requestServicesService.create(dataItem);

			observable.subscribe(x => {
				if (x.isSuccessful) {
					this.loadRequestServices();
				}
			});

			this.calcDisabledServices(obj.dataItem, true);

			obj.dataItem = null;

			this.isSeviceGridEditing = false;
		} else {
			if (!!this.markFormDirty) {
				this.markFormDirty.emit();
			}

			this.calcDisabledServices(obj.dataItem, true);
			this.isSeviceGridEditing = false;
		}
	}

	public closeRow(): void {
		this.isSeviceGridEditing = false;
		if (!!this.servicesGrid) {
			this.servicesGrid.closeRow();
		}
	}

	addServiceHandler(): void {
		this.isSeviceGridEditing = true;
		this.serviceChange(null, null);
	}

	editServiceHandler({ dataItem }: any): void {
		this.isSeviceGridEditing = true;
		var selectedService = dataItem as NewRequestService;
		if (!!selectedService) {
			this.serviceChange(selectedService.serviceId, selectedService);
		}
	}

	cancelServiceHandler(): void {
		this.isSeviceGridEditing = false;
	}

	public removeHandler({ dataItem }: any) {		
		if (!!this.markFormDirty) {
			this.markFormDirty.emit();
		}

		if (this.isExistingRequest) {
			this.requestServicesService.remove(`${(<NewRequestService>dataItem).requestServiceId}`, 'Услуга удалена из заявки', '').subscribe(x => {

				if (x.isSuccessful) {
					this.loadRequestServices();
				}
			});
		}

		this.calcDisabledServices(dataItem, false);
	}

	calcAllowedServiceCategories(service: KeyValueDataObject<ServiceAgreementInfo[]>) {
		this.allowedAgreementCategories = this.agreementCategories.filter(v => service.data.some(x => x.serviceCategoryId === v.id));
	}

	calcAllowedServiceTypes(service: KeyValueDataObject<ServiceAgreementInfo[]>) {
		this.allowedAgreementServiceTypes = this.agreementServiceTypes.filter(v => service.data.some(x => x.serviceTypeId === v.id));
	}

	serviceItemDisabled(itemArgs: { dataItem: KeyValueDataObject<ServiceAgreementInfo[]>, index: number }) {
		return itemArgs.dataItem.disabled;
	}

	calcDisabledServices(dataItem: NewRequestService, disableState: boolean) {
		this.agreementServices.forEach(v => v.disabled = this.requestServices.some(x => x.serviceId === v.id));
		let item = this.agreementServices.find(v => v.id === dataItem.serviceId);
		if (item) {
			item.disabled = disableState;
		}
	}

	public loadRequestServices() {
		this.requestsService.getRequestServices(this.requestId).subscribe(requestServices => {
			this.requestServices = requestServices;
		});
	}

	loadServicesByAgreement(agreementId: number, requestTypeId: number) {

		if (!agreementId)
			return;

		this.agreementsService.getServices(agreementId, requestTypeId).subscribe(data => {
			this.agreementServices = data;
			this.agreementServices.forEach(v => v.disabled = this.requestServices.some(x => x.serviceId === v.id));

			this.agreementsService.getCategories(agreementId, requestTypeId).subscribe(data => {
				this.agreementCategories = data;

				this.agreementsService.getServiceTypes(agreementId, requestTypeId).subscribe(data => {
					this.agreementServiceTypes = data;

					if (!!!this.isNewRequest && this.requestTypeKindId === <number>RequestTypeKind.Srv) {
						let badServices: string[] = [];
						let badCategories: string[] = [];
						let badTypes: string[] = [];

						let noCategoryAll = this.requestServices.length > 0 && this.requestServices.every(s => !!!s.serviceCategoryId);
						let noCategoryPart = false;

						if (!noCategoryAll) {
							noCategoryPart = this.requestServices.some(s => !!!s.serviceCategoryId);
						}

						this.requestServices.forEach(rs => {
							if (!!rs.serviceId && !this.agreementServices.some(as => as.id === rs.serviceId))
								badServices.push(rs.serviceName);

							if (!!rs.serviceCategoryId && !this.agreementCategories.some(as => as.id === rs.serviceCategoryId))
								badCategories.push(rs.serviceCategoryName);

							if (!!rs.serviceTypeId && !this.agreementServiceTypes.some(as => as.id === rs.serviceTypeId))
								badTypes.push(rs.serviceTypeName);
						});

						let errorMessage = "";

						if (noCategoryAll) {
							errorMessage += `При регистрации заявки, не была указана/передана категория услуг во всех услугах. SLA не может быть рассчитан.`;
						} else if (noCategoryPart) {
							errorMessage += `При регистрации заявки, не была указана/передана категория услуг в одной из услуг.`;
						}

						if (badServices.length > 0) {
							errorMessage += errorMessage != "" ? "<br>" : "";
							errorMessage += `Услуги: [${badServices.join(',')}] не связаны с договором или типом заявки`;
						}

						if (badCategories.length > 0) {
							errorMessage += errorMessage != "" ? "<br>" : "";
							errorMessage += `Категории услуги: [${badCategories.join(',')}] не связаны с договором или типом заявки`;
						}

						if (badTypes.length > 0) {
							errorMessage += errorMessage != "" ? "<br>" : "";
							errorMessage += `Типы услуг: [${badTypes.join(',')}] не связаны с договором или типом заявки`;
						}

						if (errorMessage != "")
							this.notificationService.error({
								title: `Предупреждение при загрузке услуг`,
								message: errorMessage,
								notificationType: NotificationType.SweetAlert,
								enableHtml: true
							});
					}
				});
			});
		});
	}
}