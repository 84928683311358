import { Component, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { CustomerObjectsService } from '../customer-objects.service';
import { CustomerObject } from '../../../shared/models/customerObject/CustomerObject';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import { LookupService } from '../../../shared/services/lookup.service';
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';

@Component({
	selector: 'categories-customer-objects-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CustomerObjectsListPage extends ListPageBase<CustomerObject> implements AfterViewInit {

	@ViewChild('filterAllInput') filterAllInput: ElementRef;

	regions: KeyValueObject[] = [];

	constructor(
		appService: AppService,
		router: Router,
		private lookupService: LookupService,
		protected dataService: CustomerObjectsService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Объекты ТСП';
	}

	onListPageInit(): void { 
		this.lookupService.getData("regions", null).subscribe(data => this.regions = data);
	}

	
	ngAfterViewInit(): void {
		fromEvent(this.filterAllInput.nativeElement, 'keyup')
		.pipe(
			map((evt: any) => evt.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe((text: string) => this.onFilter(text));
	}

	processListItems(items: CustomerObject[]): void { }

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/categories/customer-object', dataItem.customerObjectId]);
	}

	public onFilter(inputValue: string): void {

		var customState: DataSourceRequestState = {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: "or",
				filters: [
					{
						field: 'name',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'parentCustomerObjectName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'address',
						operator: 'contains',
						value: inputValue
					}
				],
			}
		};
		
		this.loadData(customState);
    }
}
