import { Component, OnInit, ViewChild } from '@angular/core';
import { NewRequest } from '../../shared/models/request/new-request/new-request';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../core/services/notification.service';
import { TemplateEngineService } from '../../core/services/template-engine.service';
import { AppService } from '../../app.service';
import { ComponentPageBase } from '../../shared/components/component-page-base/component-page-base';
import { NewRequestsService } from '../../shared/services/new-requests.service';
import { LookupService } from '../../shared/services/lookup.service';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { KeyValueDataObject } from '../../shared/models/core/KeyValueDataObject';
import { ServiceAgreementInfo } from '../../shared/models/services/service-agreement-info';
import { EntityViewModel } from '../../shared/models/core/EntityViewModel';
import { DataSourceRequestState, groupBy } from '@progress/kendo-data-query';
import { NewRequestService } from '../../shared/models/request/new-request/new-request-service';
import { AgreementsService } from '../../shared/services/agreements.service';
import { MerchantsService } from '../../categories/merchants/merchants.service';
import { CustomerObjectsService } from '../../categories/customer-objects/customer-objects.service';
import { ServiceCenterLookupModel } from '../../shared/models/service-center/service-center-lookup';
import { NewRequestDevice } from '../../shared/models/request/new-request/new-request-device';
import { TidFullLookupModel } from '../../shared/models/tid/tid-full';
import { UploadFile } from '../../shared/models/attachment/upload-file';
import { MerchantLookupItem } from '../../shared/models/merchant/merchant-lookup-item';
import { DialogService } from '@progress/kendo-angular-dialog';
import { MerchantFormFull } from '../../categories/merchants/merchant/merchant-form-full/merchant.form.full';
import { HierarchyLookupModel } from '../../shared/models/core/HierarchyLookupModel';
import { SecurityService } from '../../core/services/security.service';
import { TidsService } from '../../shared/services/tids.service';
import { Tid } from '../../shared/models/tid/tid';
import { ContragentsService } from '../../categories/contragents/contragents.service';
import { CustomerContact } from '../../shared/models/request/customer-contact';
import { RequestType } from '../../shared/enums';
import { NotificationType } from '../../core/services/notification-type';
import { GridComponent } from '@progress/kendo-angular-grid';
import { RequestServicesComponent } from '../../shared/components/request-services/request-services.component';
import { environment } from '../../../environments/environment';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { RequestTypeService } from '../../shared/services/request-type.service';
import { RequestTypeHierarchyModel } from '../../shared/models/request/request-type-hierarchy-model';
import { RequestTypeKind } from '../../shared/enums/request-type-kind.enum';
import { WarehouseAreasService } from '../../shared/services/warehouse-areas.service';
import { WarehouseAreaLookupModel } from '../../shared/models/warehouse/warehouse-area-lookup-model';
import { WarehousesService } from '../../shared/services/warehouses.service';
import { WarehouseRequestOptions } from '../../shared/models/warehouse/warehouse-request-options';
import { WarehouseKindEnum } from '../../shared/enums/warehouse-kind.enum';

declare var kendo: any;

@Component({
	selector: 'new-request-page',
	templateUrl: './new-request.page.html',
	styleUrls: [
		'./new-request.page.scss',
		'../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	]
})
export class NewRequestPage extends ComponentPageBase<NewRequest> implements OnInit {
	@ViewChild("requestServicesComponent") public requestServicesComponent: RequestServicesComponent;

	public dropDownFilterSettings: DropDownFilterSettings = { caseSensitive: false, operator: 'contains' };

	requestTypes: RequestTypeHierarchyModel[] = [];
	requestStatuses: KeyValueObject[] = [];
	users: KeyValueObject[] = [];
	agreements: KeyValueObject[] = [];
	contragents: KeyValueObject[] = [];

	agreementServices: KeyValueDataObject<ServiceAgreementInfo[]>[] = [];
	agreementCategories: KeyValueObject[] = [];
	allowedAgreementCategories: KeyValueObject[] = [];
	agreementServiceTypes: KeyValueObject[] = [];
	allowedAgreementServiceTypes: KeyValueObject[] = [];

	merchants: MerchantLookupItem[] = [];
	customerObjects: KeyValueObject[] = [];
	serviceCenters: ServiceCenterLookupModel[] = [];
	deviceTypes: KeyValueObject[] = [];
	deviceConnectionTypes: KeyValueObject[] = [];
	deviceConnectionTypesGrouped: any[] = [];
	deviceConnectionTypesGroupedDropdown: any[] = [];
	dropDownDeviceModels: HierarchyLookupModel[] = [];
	deviceModels: HierarchyLookupModel[] = [];
	deviceSuppliers: KeyValueObject[] = [];
	warehouses: KeyValueObject[] = [];
	tids: KeyValueObject[] = [];
	contragentsNotAllowedEmptyDesctiption: KeyValueObject[] = [];

	warehouseAreasWithWarehouse: WarehouseAreaLookupModel[] = [];
	warehouseAreasWithWarehouseRecipientDropdown: WarehouseAreaLookupModel[] = [];
	warehouseAreasWithWarehouseSenderDropdown: WarehouseAreaLookupModel[] = [];
	warehousesIsLoaded: boolean = false;

	loading: boolean = false;

	public dropDownListVirtualSettings: any = {
		itemHeight: 28
	};
	cellPhoneMask: string = "+7 (000) 000-00-00";
	public rules: { [key: string]: RegExp } = {
		'0': /\d/
	};

	constructor(
		protected route: ActivatedRoute,
		protected router: Router,
		protected appService: AppService,
		protected notificationService: NotificationService,
		protected dataService: NewRequestsService,
		private lookupService: LookupService,
		private agreementsService: AgreementsService,
		private merchantsService: MerchantsService,
		private customerObjectsService: CustomerObjectsService,
		private requestTypesService: RequestTypeService,
		private securityService: SecurityService,
		private tidsService: TidsService,
		private warehouseAreasService: WarehouseAreasService,
		private warehousesService: WarehousesService,
		private contragentsService: ContragentsService,
		private templateEngineService: TemplateEngineService,
		protected dialogService: DialogService
	) {
		super(route, router, appService, notificationService, dataService);
		this.listPageRoutePath = 'requests';
		this.appService.pageTitle = 'Новая заявка';
	}

	ngOnInit() {
		this.initLookups();

		this.canEditForm = true;
		this.isNew = true;
		this.entityViewModel = new EntityViewModel<NewRequest>();
		this.entityViewModel.entity = new NewRequest();
		this.entityViewModel.entity.warehouseId = 0;

		if (this.isUserClient || this.isServiceBoundariesFilter) {
			this.entityViewModel.entity.performerUserGroupId = 3;
			this.entityViewModel.entity.customerContragentId = this.securityService.currentUser.contragentId;
			this.clientChange(this.entityViewModel.entity.customerContragentId);
		}
		this.loadTidsByAgreement(null);
	}

	beforeSaveChanges() {
		this.entityViewModel.entity.authorContragentId = this.securityService.currentUser.contragentId;
		if (this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseRefill
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehouseTransfer
			&& this.entityViewModel.entity.requestTypeId !== <number>RequestType.warehousePosting) {
			this.entityViewModel.entity.warehouseId = null;
		}
	}

	saveChanges(name: string = this.entityName): void {

		if (this.entityViewModel.entity.requestAttachments.length > 0 && this.entityViewModel.entity.requestAttachments.some(x => x.attachmentTypeIds.length === 0)) {
			this.notificationService.error({
				title: 'Ошибка',
				message: "Укажите типы для загружаемых файлов",
				notificationType: NotificationType.SweetAlert
			});

			return;
		}

		super.saveChanges();
	}

	afterSaveChanges() {

		if (!this.entityId)
			return;

		this.router.navigate(['/requests/', this.entityId]);
	}

	initLookups() {
		this.requestTypesService.getHierarchyData().subscribe(response =>
			this.requestTypes = response.filter(r => r.id !== RequestType.uninstallOld));
		this.lookupService.getData("request-statuses", null).subscribe(data => this.requestStatuses = data);
		this.lookupService.getData("users", null).subscribe(data => this.users = data);

		if (this.isUserClient || this.isServiceBoundariesFilter) {
			this.contragentsService.getContragnetsByLinkedAgreements(this.securityService.currentUser.contragentId).subscribe(data => this.contragents = data);
		} else {
			var contragentsState: DataSourceRequestState = {
				skip: 0,
				take: null,
				filter: { logic: "and", filters: [{ field: "ContragentTypeId", operator: "eq", value: 1 }] }
			};

			this.lookupService.getData("contragents", contragentsState).subscribe(data => this.contragents = data);
		}

		this.contragentsService.getContragnetsNotAllowedEmptyDescriprion().subscribe(data => this.contragentsNotAllowedEmptyDesctiption = data);
		this.lookupService.getData("device-types", null).subscribe(data => this.deviceTypes = data);
		this.lookupService.getData("warehouses", null).subscribe(data => this.warehouses = data);

		this.lookupService.getData("device-connection-types", {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ParentDeviceConnectionTypeId", operator: "isnotnull" }] }
		}).subscribe(data => {
			this.deviceConnectionTypes = data;
			this.deviceConnectionTypesGrouped = groupBy(data, [{ field: "groupName" }]);
			this.deviceConnectionTypesGroupedDropdown = this.deviceConnectionTypesGrouped;
		});

		this.lookupService.getHierarchyData("device-models", null).subscribe(data => {
			this.deviceModels = data;
			this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null).map(x => new KeyValueObject(x.id, x.name));
		});

	}

	clientChange(value: number): void {
		this.entityViewModel.entity.agreementId = null;

		this.loadAgreementsByClient(value);
	}

	agreementChange(value: number): void {
		const requestWarehouseTypes = [RequestType.warehouseTransfer, RequestType.foreighWarehouseTransfer, RequestType.internalWarehouseTransfer];
		if (!requestWarehouseTypes.includes(this.entityViewModel.entity.requestTypeId)) {
			this.clearDetails();
		}
		this.loadServicesByAgreement(value, this.entityViewModel.entity.requestTypeId);
		this.loadTidsByAgreement(value);
		if (this.isUserClient) {
			this.loadRequestTypesByAgreement(value);
		}
	}

	loadAgreementsByClient(clientId: number) {

		if (!clientId)
			return;

		var state: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "ContragentId", operator: "eq", value: clientId }, { field: "StateId", operator: "eq", value: 1 }] }
		};

		this.lookupService.getData("agreements", state).subscribe(data => {
			this.agreements = data;
			if (this.isUserClient || this.isServiceBoundariesFilter) {
				if (!this.entityViewModel.entity.agreementId && this.agreements.length > 0 && !this.agreements.some(v => v.id === this.entityViewModel.entity.agreementId)) {
					this.entityViewModel.entity.agreementId = this.agreements.length === 1 ? this.agreements[0].id : null;
				}
				this.agreementChange(this.entityViewModel.entity.agreementId);
			}
		});
	}

	loadTidsByAgreement(agreementId: number) {
		var parentThis = this;
		setTimeout(() => {
			var url = `${environment.apiUrl}v1/agreements/${agreementId}/tids`;
			var authUser = localStorage.getItem("authUser");
			var header;
			if (authUser) {
				header = `Bearer ${JSON.parse(authUser).bearerToken}`
			};

			var multiselect = kendo.jQuery("#tidJs").data("kendoMultiSelect");

			if (multiselect != undefined) {
				var dataSource = new kendo.data.DataSource({
					transport: {
						read: {
							url: url,
							beforeSend: function (req) {
								req.setRequestHeader('Authorization', header);
							},
						}
					},
					schema: {
						type: "json",
						data: "data",
						total: "total"
					},
					pageSize: 50,
					serverPaging: true,
					serverFiltering: true
				});

				multiselect.setDataSource(dataSource);
			} else {
				kendo.jQuery("#tidJs").kendoMultiSelect({
					change: function (e) {
						var value = this.dataItems();
						parentThis.tids = [];
						value.forEach(f => {
							parentThis.tids.push(new KeyValueObject(f.id, f.name));
						});

						parentThis.agreementsService.getTidSingle(agreementId).subscribe(data => {
							if (data !== null) {
								parentThis.tids = [data];
							}

							parentThis.loadTidJs(parentThis.tids);
							parentThis.tidCbChange(parentThis.entityViewModel.entity.selectedTidId);
						});

						parentThis.entityViewModel.entity.selectedTidId = parentThis.tids.length > 0 ? parentThis.tids[0].id : null;

						parentThis.appService.MarkFormDirty(parentThis.entityForm);
						parentThis.tidCbChange(parentThis.entityViewModel.entity.selectedTidId);
					},
					messages: {
						noData: "Выберите договор содержищий Tid, и тип заявки не 'Монтаж'..."
					},
					placeholder: "Выберите Tid...",
					maxSelectedItems: 1,
					dataTextField: "name",
					dataValueField: "id",
					height: 300,
					virtual: {
						itemHeight: 24,
						mapValueTo: "dataItem",
						valueMapper: function (options) {
							var result = [];
							options.value.forEach(f => {
								var index = parentThis.tids.map(function (x) { return x.id; }).indexOf(f);
								if (index >= 0) {
									var data = parentThis.tids[index];
									result.push({ id: data.id, name: data.name });
								}
							});
							options.success(result);
						}
					},
					filter: "contains",
					delay: 1000,
					dataSource: {
						transport: {
							read: {
								url: url,
								beforeSend: function (req) {
									req.setRequestHeader('Authorization', header);
								},
							}
						},
						schema: {
							type: "json",
							data: "data",
							total: "total"
						},
						pageSize: 50,
						serverPaging: true,
						serverFiltering: true
					}
				});
			}

			if ((!parentThis.canEditForm || !parentThis.entityViewModel.entity.customerContragentId || !agreementId) && multiselect != undefined) {
				multiselect.readonly(true);
			}


		}, 1000);
	}

	loadTidJs(tids: KeyValueObject[]) {
		var multiselect = kendo.jQuery("#tidJs").data("kendoMultiSelect");
		var selectedData = [];
		tids.forEach(f => {
			selectedData.push({ id: f.id, name: f.name });
		});
		if (multiselect) {
			multiselect.value(selectedData);
			this.entityViewModel.entity.selectedTidId = selectedData.length > 0 ? selectedData[0].id : null;
		}
	}

	loadRequestTypesByAgreement(agreementId: number) {
		if (!agreementId)
			return;
		this.agreementsService.getRequestTypes(agreementId).subscribe(data => {
			const agreementRequestTypeIds = data.map(x => x.id);
			this.requestTypes = this.requestTypes.filter(x => agreementRequestTypeIds.includes(x.id));
			if (this.requestTypes.length > 0 && !this.requestTypes.find((v, i) => v.id === this.entityViewModel.entity.requestTypeId)) {
				this.entityViewModel.entity.requestTypeId = this.requestTypes[0].id;
			}
			this.loadServicesByAgreement(this.entityViewModel.entity.agreementId, this.entityViewModel.entity.requestTypeId);
		});
	}

	loadServiceCentersByCustomerObject(customerObjectId: number) {

		if (!customerObjectId)
			return;

		this.customerObjectsService.getServiceCenters(customerObjectId).subscribe(data => {
			this.serviceCenters = data;
		});
	}

	get defaultServiceCenterId(): number {
		const filtered = this.serviceCenters.filter(x => x.isDefault);

		if (filtered.length === 0) {
			return null;
		}

		return filtered[0].id;

	}

	getNameById(data: KeyValueObject[], id: number): string {

		const filtered = data.filter(x => x.id == id);

		if (filtered.length === 0) {
			return '';
		}

		return filtered[0].name;

	}

	get createNewDevice(): any {

		return function () {
			var device = new NewRequestDevice();
			return device;
		};
	}


	get createNewContact(): any {

		return function () {
			var contact = new CustomerContact();
			return contact;
		};
	}

	onAttachmentsAdded(attachments: UploadFile[]) {
		this.entityViewModel.entity.requestAttachments = attachments;
	}

	public showNewMerchantWindow() {
		const dialogRef = this.dialogService.open({ content: MerchantFormFull, width: '100%', height: '80%' });

		const merchantForm = <MerchantFormFull>dialogRef.content.instance;
		merchantForm.header = 'Новый ТСП';
		merchantForm.showCancelButton = true;
		merchantForm.clientId = this.entityViewModel.entity.customerContragentId;
		merchantForm.clientAgreementId = this.entityViewModel.entity.agreementId;
		merchantForm.afterSaveChangesEvent.subscribe((newTid: KeyValueObject) => {
			merchantForm.closeDialog();
			this.entityViewModel.entity.selectedTidId = newTid.id;
			this.tids = [newTid];
			this.loadTidJs([newTid]);
			this.loadTidsByAgreement(this.entityViewModel.entity.agreementId);
			this.tidCbChange(newTid.id);
		});
	}

	getDeviceModels(supplierId: number): any[] {

		if (this.deviceModels == null) {
			return [];
		}

		return this
			.deviceModels
			.filter(x => x.parentId === supplierId);

	}

	public deviceSupplierChange(deviceSupplierId: number, dataItem: NewRequestDevice): void {
		dataItem.deviceModelId = null;
	}

	loadServicesByAgreement(agreementId: number, requestTypeId: number) {

		if (!agreementId)
			return;

		this.agreementsService.getServices(agreementId, requestTypeId).subscribe(data => this.agreementServices = data);
	}

	get hasEnabledService(): boolean {
		return this.agreementServices.some(v => !v.disabled);
	}

	clearDetails() {
		this.entityViewModel.entity.requestServices = [];
		this.entityViewModel.entity.selectedTidId = null;
		this.entityViewModel.entity.merchantId = null;
		this.entityViewModel.entity.customerObjectId = null;
		this.entityViewModel.entity.serviceCenterId = null;
		this.entityViewModel.entity.tidServiceCategoryId = null;
		this.entityViewModel.entity.tidServiceCategoryName = null;
		this.serviceCenters = [];
		if (!!this.requestServicesComponent) {
			this.requestServicesComponent.closeRow();
		}

		var multiselect = kendo.jQuery("#tidJs").data("kendoMultiSelect");

		if (multiselect) {
			multiselect.value(null);
		}
	}

	requestTypeChange(value: number): void {
		if (this.requestTypes.find(x => x.id == value).requestTypeKindId == RequestTypeKind.Wrh) {
			if (this.warehousesIsLoaded) {
				this.warehouseAreasWithWarehouseSenderDropdown = this.warehouseAreasWithWarehouse;
				this.warehouseAreasWithWarehouseRecipientDropdown = this.warehouseAreasWithWarehouse;
			}
			else {
				let warehouseKindIds = [
					<number>WarehouseKindEnum.OwnEmployee,
					<number>WarehouseKindEnum.ThirdPartyEmployee,
					<number>WarehouseKindEnum.ThirdPartySelfEmployed,
					<number>WarehouseKindEnum.TransportCompanyWarehouse,
				];
				this.warehouseAreasService.getWithWarehouse(warehouseKindIds).subscribe(response => {
					this.warehouseAreasWithWarehouse = response.map(x => {
						return {
							warehouseAreaId: x.warehouseAreaId,
							warehouseAreaName: `${x.warehouseName} ${x.warehouseAreaName}`,
							warehouseId: x.warehouseId,
							warehouseName: x.warehouseName,
							warehouseIntegraCode: x.warehouseIntegraCode
						}
					});
					this.warehouseAreasWithWarehouseSenderDropdown = this.warehouseAreasWithWarehouse;
					this.warehouseAreasWithWarehouseRecipientDropdown = this.warehouseAreasWithWarehouse;
				});
				this.warehousesIsLoaded = true;
			}
		}
		else {
			this.entityViewModel.entity.senderWarehouseId = null;
			this.entityViewModel.entity.recipientWarehouseId = null;
			this.entityViewModel.entity.serviceCenterId = null;
		}

		var multiselect = kendo.jQuery("#tidJs").data("kendoMultiSelect");
		if (this.isUserClient) {
			if (value === 1) {
				this.clearDetails();
				this.tids = [];
			} else {
				if (this.tids.length === 0 && this.entityViewModel.entity.customerContragentId != null) {
					this.entityViewModel.entity.selectedTidId = null;
					this.entityViewModel.entity.merchantId = null;
					this.entityViewModel.entity.customerObjectId = null;
				}

				if (multiselect != undefined) {
					multiselect.readonly(false);
				}
			}
			this.loadServicesByAgreement(this.entityViewModel.entity.agreementId, value);
			this.loadTidsByAgreement(this.entityViewModel.entity.agreementId)

		} else if (value === 1) {
			this.clearDetails();
			this.tids = [];
			this.loadTidsByAgreement(null);
		} else {
			if (this.tids.length === 0 && this.entityViewModel.entity.customerContragentId != null) {
				this.entityViewModel.entity.selectedTidId = null;
				this.loadTidsByAgreement(this.entityViewModel.entity.agreementId)
			}

			if (multiselect != undefined) {
				multiselect.readonly(false);
			}
		}
		if (value === 5) {
			if (this.entityViewModel.entity.warehouseId === 0 && this.warehouses.length > 0) {
				this.entityViewModel.entity.warehouseId = this.warehouses[0].id;
			}
		} else {
			this.entityViewModel.entity.warehouseId = 0;
		}
	}

	tidCbChange(value: number): void {
		if (value == null) {
			this.entityViewModel.entity.customerObjectId = null;
			this.entityViewModel.entity.merchantId = null;
			this.entityViewModel.entity.serviceCenterId = null;
			this.entityViewModel.entity.tidServiceCategoryId = null;
			this.entityViewModel.entity.tidServiceCategoryName = null;
			this.serviceCenters = [];
			return;
		}
		this.tidsService.getById(value).subscribe(tid => {
			this.customerObjectsService.getById(tid.entity.customerObjectId).subscribe(co => {
				this.customerObjects = [];
				this.customerObjects.push(new KeyValueObject(co.entity.customerObjectId, co.entity.name));
				this.entityViewModel.entity.customerObjectId = co.entity.customerObjectId;
				this.loadServiceCentersByCustomerObject(this.entityViewModel.entity.customerObjectId);
			})
			this.merchantsService.getById(tid.entity.merchantId).subscribe(m => {
				this.merchants = [];
				this.merchants.push(new MerchantLookupItem(m.entity.merchantId, m.entity.name));
				this.entityViewModel.entity.merchantId = m.entity.merchantId;
			})
			this.entityViewModel.entity.serviceCenterId = tid.entity.serviceCenterId;

			if (!!!tid.entity.customerServiceDateEnd || new Date(tid.entity.customerServiceDateEnd) > new Date()) {
				this.entityViewModel.entity.tidServiceCategoryId = tid.entity.serviceCategoryId;
				this.entityViewModel.entity.tidServiceCategoryName = tid.entity.serviceCategoryName;
			}
		});
	}

	warehouseSenderValueChange(value: any) {
		const selectedWarehouseId = this.warehouseAreasWithWarehouseSenderDropdown.find(x =>
			x.warehouseAreaId == value).warehouseId
		this.warehousesService.getWarehouseRequestOptions(selectedWarehouseId).subscribe((data) => {
			this.entityViewModel.entity.performerUserGroupId = data.userGroupId;
			this.entityViewModel.entity.serviceCenterId = data.serviceCenterId;
		});
	}

	warehouseSenderFilter(value: string) {
		this.warehouseAreasWithWarehouseSenderDropdown = this.warehouseAreasWithWarehouse
			.filter(x => x.warehouseName.toLowerCase().includes(value.toLowerCase()));
	}

	warehouseRecipientFilter(value: string) {
		this.warehouseAreasWithWarehouseRecipientDropdown = this.warehouseAreasWithWarehouse
			.filter(x => x.warehouseName.toLowerCase().includes(value.toLowerCase()));
	}

	get isUserClient(): boolean {
		return this.securityService.isClient();
	}

	get isServiceBoundariesFilter(): boolean {
		return this.securityService.isServiceBoundariesFilter();
	}

	markFormDirtyFromRequestSevicesComponent(): void {
		this.appService.MarkFormDirty(this.entityForm);
	}

	public get descriptionIsValid(): boolean {
		if (this.contragentsNotAllowedEmptyDesctiption.find(x => x.id == this.entityViewModel.entity.customerContragentId)) {
			return new RegExp('[а-яА-ЯёЁa-zA-Z]{5,}').test(this.entityViewModel.entity.description);
		}
		return true;
	}

	get isWarehouseRequestType(): boolean {
		if (this.entityViewModel.entity.requestTypeId) {
			const selectedRequestType = this.requestTypes.find(x => x.id == this.entityViewModel.entity.requestTypeId);
			if (selectedRequestType && selectedRequestType.requestTypeKindId == RequestTypeKind.Wrh) {
				return true;
			}
		}
		return false;
	}

	public handleConnectionTypeFilter(value: any) {
		let filtered = this.deviceConnectionTypes.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
		this.deviceConnectionTypesGroupedDropdown = groupBy(filtered, [{ field: 'groupName' }]);
	}

	public handleDeviceModelFilter(value: string, deviceSupplierId: number) {
		let models = this.getDeviceModels(deviceSupplierId);
		this.dropDownDeviceModels = models.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
	}

	public handleDeviceModelDropdownOpen(deviceSupplierId: number) {
		this.dropDownDeviceModels = this.getDeviceModels(deviceSupplierId);
	}
}
