import { Component, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { LayoutService } from '../../../layout/layout.service';

@Component({
	selector: 'navbar-header-text',
	templateUrl: './navbar-header-text.component.html'
})
export class NavbarHeaderTextComponent implements AfterViewInit {

	@ViewChild('navbarHeaderTextTemplate') private navBarHeaderTextTemplate: TemplateRef<any>;

	constructor(
		private layoutService: LayoutService
	) { }

	ngAfterViewInit(): void {
		this.layoutService.navBarHeaderTextTemplate = this.navBarHeaderTextTemplate;
	}
}