import { Component, EventEmitter, Output, Injector, Input, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../../app.service';
import { ListPageBase } from '../../list-page-base/list-page-base';
import { DevicesService } from '../../../services/devices.service';
import { Device } from '../../../models/device/Device';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FilterDescriptor, DataSourceRequestState } from '@progress/kendo-data-query';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StatePersistingService } from '../../../services/state-persisting.service';
import { RequestInitTypeEnum, RequestType } from '../../../enums';
import { ReportsService } from '../../../services/reports.service';
import { GridSettings } from '../../../models/grid/grid-settings';
import { ColumnSettings } from '../../../models/grid/column-settings';
import { ExportExcelFromGridService } from '../../../services/export-excel-from-grid.service';

@Component({
	selector: 'categories-devices-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DevicesListPage extends ListPageBase<Device> implements AfterViewInit  {

	@ViewChild('filterAllInput') filterAllInput: ElementRef;

	@Output()
	onCellClick = new EventEmitter<Device>();

	@Input()
	deviceTypeId?: number = null;

	@Input()
	deviceManufacturerId?: number = null;

	@Input()
	deviceModelId?: number = null;

	@Input()
	requestId?: number = null;

	@Input()
	requestTypeId?: number = null;

	@Input()
	initTypeId?: number = null;

	@Input()
	activityId?: number;

	@Input()
	showHeader: boolean = true;

	@Input()
	showToolbar: boolean = true;

	@Input()
	enableAutoHeight: boolean = true;

	@Input()
	showOnlyLinkedDevices: boolean = false;

	@Input()
	showIsTargeted: boolean = false;

	@Input() 
	excludeCancellatedReserve: boolean = false;

	@Input()
	gridSettingsStorageKey: string = "devices-grid-settings";

	public enableSaveGridSettings: boolean = true;
	private customFields: string[] = ['isTargeted', 'deviceConditionName'];

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDate', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'deviceTypeName',
				title: 'Тип оборудования',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'serialNumber',
				title: 'Серийный номер',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'deviceServiceTypeName',
				title: 'Сегмент',
				filter: 'string',
				filterable: true,
				width: 150,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'articleName',
				title: 'Артикул',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'deviceManufacturerName',
				title: 'Производитель',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'deviceModelName',
				title: 'Модель',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'deviceConnectionTypeNames',
				title: 'Типы связи',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'deviceConditionName',
				title: 'Качество ТО',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'isTargeted',
				title: 'Целевое',
				filter: 'boolean',
				filterable: false,
				width: 150,
				hidden: false
			},
			{
				orderIndex: 9,
				field: 'completeKitString',
				title: 'Комплектность',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 10,
				field: 'clientName',
				title: 'Клиент',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 11,
				field: 'agreementNumber',
				title: 'Договор с клиентом',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 12,
				field: 'lastRequestId',
				title: 'Последняя заявка',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 13,
				field: 'lastTid',
				title: 'Последний TID',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 14,
				field: 'lastServiceCenterName',
				title: 'СЦ',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 15,
				field: 'region',
				title: 'Регион',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 16,
				field: 'lastEvent',
				title: 'Последнее событие',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 17,
				field: 'lastEventDate',
				title: 'Дата последнего события',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 18,
				field: 'nomenclature',
				title: 'Номенклатура',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 19,
				field: 'keyRegion',
				title: 'Филиал ключа',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 20,
				field: 'subscriberNumber',
				title: 'Абонентский номер',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			},
			{
				orderIndex: 21,
				field: 'software',
				title: 'Версия ПО',
				filter: 'string',
				filterable: true,
				width: 200,
				hidden: false
			}
		]
	};

	constructor(
		protected appService: AppService,
		router: Router,
		protected dataService: DevicesService,
		protected persistingService: StatePersistingService,
		private injector: Injector,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Устройства';
	}

	get isMkType(): boolean {
		return this.initTypeId === <number>RequestInitTypeEnum.blockChain;
	}

	get isExpertiseMk(): boolean {
		return this.isMkType && this.requestTypeId === <number>RequestType.expertise;
	}

	public isCustomFields(colField: string): boolean {
		return this.customFields.includes(colField);
	} 

	public loadData(state: DataSourceRequestState = this.gridSettings.state): void {
		this.loading = true;

		if (this.deviceTypeId != null &&
			!state.filter.filters.some(x => (<FilterDescriptor>x).field == 'deviceTypeId')) {
				state.filter.filters.push({ field: "deviceTypeId", operator: "eq", value: this.deviceTypeId });
		}

		if (this.deviceManufacturerId != null &&
			!state.filter.filters.some(x => (<FilterDescriptor>x).field == 'DeviceManufacturerId')) {
				state.filter.filters.push({ field: "DeviceManufacturerId", operator: "eq", value: this.deviceManufacturerId });
		}

		if (this.deviceModelId != null &&
			!state.filter.filters.some(x => (<FilterDescriptor>x).field == 'deviceModelId')) {
				state.filter.filters.push({ field: "deviceModelId", operator: "eq", value: this.deviceModelId });
		}

		const action = this.showOnlyLinkedDevices
			? this.dataService.linkedDevices(state, this.requestId, this.activityId, this.excludeCancellatedReserve)
			: this.dataService.list(state);

		action.subscribe(lvm => {
			this.processListItems(lvm.data);
			this.listViewModel = lvm;
			this.loading = false;
		});
	}

	onListPageInit(): void { }

	ngAfterViewInit(): void {
		if (this.filterAllInput){
			fromEvent(this.filterAllInput.nativeElement, 'keyup')
			.pipe(
				map((evt: any) => evt.target.value),
				debounceTime(500),
				distinctUntilChanged(),
			).subscribe((text: string) => this.onFilter(text));
		}
	}

	processListItems(items: Device[]): void { }

	closeDialog() {
		var dialog = this.injector.get(DialogRef);
		dialog.close();
	}

	public cellClick({ column, dataItem }: any): void {
		if (dataItem.lastRequestId && column.field == 'lastRequestId') {
			window.open(`/requests/${dataItem.lastRequestId}`, '_blank')
		}
	}

	public onFilter(inputValue: string): void {

		var customState: DataSourceRequestState = {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: "or",
				filters: [
					{
						field: 'deviceTypeName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'deviceManufacturerName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'deviceModelName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'serialNumber',
						operator: 'contains',
						value: inputValue
					}
				],
			}
		};
		
		this.loadData(customState);
    }

	isColumnHidden(col: ColumnSettings): boolean {
		if (col.field === 'deviceServiceTypeName' && !this.isMkType)
			return true;

		if (col.field === 'articleName' && !this.isExpertiseMk)
			return true;

		if (col.field === 'deviceConditionName' && !!this.requestId && !this.isExpertiseMk)
			return true;

		if (col.field === 'completeKitString' && !this.isExpertiseMk)
			return true;

		if (col.field === 'isTargeted' && !this.showIsTargeted)
			return true;

		return col.hidden;
	}

	isIncludeInChooser(col: ColumnSettings): boolean {
		if (col.field === 'serialNumber' && !this.isExpertiseMk)
			return false;

		if (col.field === 'deviceServiceTypeName' && !this.isMkType)
			return false;

		if (col.field === 'articleName' && !this.isExpertiseMk)
			return false;

		if (col.field === 'deviceConditionName' && !!this.requestId && !this.isExpertiseMk)
			return false;

		if (col.field === 'completeKitString' && !this.isExpertiseMk)
			return false;

		if (col.field === 'isTargeted' && !this.showIsTargeted)
			return false;

		return true;
	}
}
