import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../core/services/notification.service';
import { Observable } from 'rxjs';
import { tap, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NotificationType } from '../../core/services/notification-type';
import { ActionDataResult } from '../../shared/models/core/ActionDataResult';
import { RequestNotificationType } from '../models/request/request-notification-type';
import {DataService} from "../../core/services/data.service";

@Injectable()
export class RequestNotificationTypeService extends DataService<RequestNotificationType, RequestNotificationType>{
	
	protected controllerVersion = 1;

	constructor(
		public http: HttpClient,
		public notificationService: NotificationService)
	{
		super(http, notificationService);
		this.controllerName = 'request-notification-types';
	}

	public getTypes(): Observable<RequestNotificationType[]> {
		return this.http.get<ActionDataResult<RequestNotificationType[]>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/all`)
				.pipe(tap(resp => {

					if (!resp.isSuccessful) {
						this.notificationService.error({
							title: 'Ошибка',
							message: resp.errorDescription,
							notificationType: NotificationType.SweetAlert
						});
					}

				}, errorResponse => {

					if (errorResponse.status === 904)
						return;

					this.notificationService.error({
						title: 'Ошибка',
						message: errorResponse.error.errorDescription,
						notificationType: NotificationType.SweetAlert
					});

				}))
				.pipe(filter(x => x.isSuccessful))
				.map(x => x.data)
			;
	}
}
