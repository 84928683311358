import { Component, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MerchantsService } from '../merchants.service';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { Merchant } from '../../../shared/models/merchant/Merchant';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { ReportsService } from '../../../shared/services/reports.service';
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';

@Component({
	selector: 'categories-merchants-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MerchantsListPage extends ListPageBase<Merchant> implements AfterViewInit {

	@ViewChild('filterAllInput') filterAllInput: ElementRef;

	constructor(
		appService: AppService,
		router: Router,
		protected dataService: MerchantsService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'ТСП';
	}

	onListPageInit(): void { }

	ngAfterViewInit(): void {
		fromEvent(this.filterAllInput.nativeElement, 'keyup')
		.pipe(
			map((evt: any) => evt.target.value),
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe((text: string) => this.onFilter(text));
	}

	processListItems(items: Merchant[]): void { }

	public cellClick({ dataItem }: any): void {
		window.open(`/categories/merchant/${dataItem.merchantId}`, '_blank');
	}

	public onFilter(inputValue: string): void {

		var customState: DataSourceRequestState = {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: "or",
				filters: [
					{
						field: 'name',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'mid',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'contragentName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'clientName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'clientAgreementNumber',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'acquirerName',
						operator: 'contains',
						value: inputValue
					}
				],
			}
		};
		
		this.loadData(customState);
    }
}
