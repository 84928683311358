import { CommentKindEnum } from "../../../enums/comment-kind.enum";

export class SetCommentModel {

	constructor(commentText: string) {
		this.commentText = commentText;
	}
	activityResultId: number;
	commentText: string;
	commentKind: CommentKindEnum;
}
