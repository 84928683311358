export enum ActivityWorkflowTransitionType {

    /** Обычный переход */
    NoActionTransition = 1,

    /** Указание группы исполнителей */
    ActionWithCommentTransition = 2,

    /** Переход с указанием серийных номеров оборудования */
    ActionWithEquipmentSnInputTransition = 3,

    /** Указание причины остановки и даты */
    setReason = 4,

    /** Указание серийных номеров оборудования */
    setDeviceSerialNumbers = 5,

    /** Выбор транспортной компании и указание трек-номера */
    ChoosingTransportCompanyAndSpecifyingTrackNumber = 6,

    /** Указание серийных номеров для возврата */
    equipmentSnInputForReturn = 7,

    /**Вложение акта*/
    actAttachment = 8,

    /** Переход при сдаче оборудования на склад */
    deviceToWarehouseAction = 9,

    /** Переход при приёме оборудования на склад */
    receivingDeviceAtWarehouseAction = 10,

    /** Переход с отменой забронированных устройств */
    deviceReserveCancellationWithComment = 11,

    /** Переход с редактированием комплектующих */
    componentsEditing = 12,

    /** Переход с указанием результата работы */
    transitionWithWorkResult = 13,

    /** Переход с передачей оборудования */
    transitionWithDeliveryOfEquipment = 14,

    /** Переход с подтверждением оборудования */
    transitionWithApproveOfEquipment = 15,

    /** Переход с указанием причины, комментария и даты */
    transitionWithReasonCommentAndDate = 16,

    /** Переход с указанием результата работы и демонтированного оборудования */
	transitionUninstallWithWorkResult = 17,

	/** Переход с указанием результата работы, монтированного и демонтированного оборудования (обязательно) */
	transitionInstallAndUninstallWithWorkResult = 18,

    /** Переход с успешным выполнением работ без использования оборудования */
    transitionWithSuccessWorkEndWithoutEquipment = 19,

    /** Переход с указанием результата работы, монтированного и демонтированного оборудования (не обязательно) */
	transitionInstallAndUninstallOptionalWithWorkResult = 20,
    
    /** Переход с использованием мастера заполнения данных */
    transitionWithMasterData = 21,

    /** Переход с полным удалением документов в 1С */
    transitionWithFull1CDocumentsDeletion = 22,
    /** Согласованная с клиентом дата визита */
    planned = 23
}