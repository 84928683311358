import { Component, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogRef } from "@progress/kendo-angular-dialog";

@Component({
	selector: 'set-cancel-reserve-reason',
	templateUrl: './set-cancel-reserve-reason.component.html',
	styleUrls: ['./set-cancel-reserve-reason.component.scss'],
})
export class SetCancelReserveReason {

	@Output()
	public onContinueEvent = new EventEmitter<any>();

	public form: FormGroup;

	constructor(
		private dialog: DialogRef
	) {
		this._buildForm();
	}

	public cancelReserve(): void {
		if (this.onContinueEvent) {
			this.onContinueEvent.emit(this.form.value);
		}

		this.cancel();
	}

	public cancel(): void {
		this.dialog.close();
	}

	private _buildForm(): void {
		this.form = new FormGroup({
			activityReasonId: new FormControl(false, Validators.required),
			commentText: new FormControl(null)
		});
	}
}
