import { FormItem } from "../../form-item";
import { SerialNumbersDevice } from "./serial-numbers-device";

export class SerialNumbersFormDevice extends FormItem {
	constructor(device: SerialNumbersDevice) {
		super();

		this.device = device;

		this.isValidSerialNumber = false;
		this.isValidCellCode = false;
		this.inputValue = null;
		this.errorMessage = null;
		this.cellErrorMessage = null;
	}

	device: SerialNumbersDevice;
}
