import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../../environments/environment';
import { tap, filter } from 'rxjs/operators';
import { ActionDataResult } from "../../../shared/models/core/ActionDataResult";
import { SpecialRightsGroup } from "../../../shared/models";
import { NotificationService } from "../../../core/services/notification.service";
import { NotificationType } from "../../../core/services/notification-type";
import { Observable } from "rxjs";

@Injectable()
export class SpecialRightsGroupsService {

    constructor(protected http: HttpClient, protected notificationService: NotificationService) {
    }

    public getAllSpecialRightsGroups() : Observable<SpecialRightsGroup[]> {
        
        var url = `${environment.apiUrl}v1/specialRightsGroups/special-rights-groups`;
        
        return this.http.get<ActionDataResult<SpecialRightsGroup[]>>(url)
            .pipe(tap(resp => {

                if (!resp.isSuccessful) {
                    this.notificationService.error({
                        title: 'Ошибка',
                        message: resp.errorDescription,
                        notificationType: NotificationType.SweetAlert
                    });
                }

            }, errorResponse => {

                if (errorResponse.status === 904)
                    return;

                this.notificationService.error({
                    title: 'Ошибка',
                    message: errorResponse.error.errorDescription,
                    notificationType: NotificationType.SweetAlert
                });

            }))
            .pipe(filter(x => x.isSuccessful))
            .map(x => x.data); 
    }
}