import { ViewContainerRef, Injectable, Inject } from '@angular/core';
import { SweetAlertType } from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NotificationType } from './notification-type';
import { Router } from '@angular/router';
import { ExceptionCode } from '../../shared/enums/exception-code.enum';

@Injectable()
export class NotificationService {

	static get NotificationType() {
		return NotificationType;
	}
	public static DEFAULT_ERROR_TITLE = 'notification.error.default.title';
	public static DEFAULT_ERROR_MESSAGE = 'notification.error.default.message';
	public static DEFAULT_SUCCESS_TIMER = 5000;
	public static DEFAULT_INFO_TITLE = 'notification.info.default.title';
	public static DEFAULT_INFO_MESSAGE = 'notification.info.default.message';

	constructor(
		@Inject('sweetAlert') private sweetAlert: Function,
		private toastr: ToastrService,
		private router: Router) { }


	error({
		message = NotificationService.DEFAULT_ERROR_MESSAGE,
		title = NotificationService.DEFAULT_ERROR_TITLE,
		notificationType = NotificationType.Toast,
		confirmButtonText = 'OK',
		enableHtml = false
	} = {}, options = {}) {
		if (notificationType === NotificationType.Toast) {
			this.toastr.error(message, title, Object.assign(this.toastrOptions(), options));
		} else {
			this.showMessage(Object.assign({ title: title, text: message, showConfirmButton: true, type: "error", confirmButtonText: confirmButtonText, enableHtml: enableHtml }, options));
		}

	}

	getNotificationTypeByExceptionCode(exceptionCode: number): NotificationType {
		switch (exceptionCode) {
			case <number>ExceptionCode.requestAttachmentRequiredException:
			case <number>ExceptionCode.activityAttachmentRequiredException:
			case <number>ExceptionCode.checkOrSetDeviceAvailableToMoveException:
				return NotificationType.SweetAlert;
			default:
				return NotificationType.Toast;
		}
	}

	httpError(status: number, data: any): boolean {
		// FIXME check other https status and make a generic message
		// not-found, unauthorized, forbidden, server error
		if ([500].indexOf(status) > -1) {
			this.error({ message: `notification.http_error.${status}.message` });
			return true; // return true to indicate that the error was already handled
		} else {
			return false;
		}

	}

	success({
		title = 'toast.title.success',
		message = '',
		notificationType = NotificationType.Toast,
		confirmButtonText = 'OK',
		enableHtml = false,
	}, options = {}) {
		if (notificationType === NotificationType.Toast) {
			options = Object.assign({ timeOut: NotificationService.DEFAULT_SUCCESS_TIMER });
			this.toastr.success(message, title, Object.assign(this.toastrOptions(), options)
			);
		} else {
			this.showMessage(Object.assign({ title: title, text: message, timer: NotificationService.DEFAULT_SUCCESS_TIMER, confirmButtonText: confirmButtonText, enableHtml: enableHtml }, options));
		}

	}

	confirmation({ title = '', message = '', showCancelButton = true, showConfirmButton = true, type = "warning", cancelButtonText = "", confirmButtonText = "", showCloseButton = true, enableHtml = false, showIcon = true, imageUrl = null, imageHeight = null, imageWidth = null }, confirmationFunction: Function = null, dismissFunction: Function = null, closeFunction: Function = null) {
		return this.showMessage({ title: title, text: message, showConfirmButton: showConfirmButton, showCancelButton: showCancelButton, type: type, closeOnConfirm: true, cancelButtonText: cancelButtonText, confirmButtonText: confirmButtonText, showCloseButton: showCloseButton, timer: null, enableHtml: enableHtml, showIcon: showIcon, imageUrl: imageUrl, imageHeight: imageHeight, imageWidth: imageWidth }, confirmationFunction, dismissFunction, closeFunction);
	}

	question({ title = '', message = '', showCancelButton = true}, confirmationFunction: Function = null, dismissFunction: Function = null) {
		return this.showMessage({
			title: title,
			text: message,
			showCancelButton: showCancelButton,
			type: "",
			closeOnConfirm: true,
			cancelButtonText: "Пропустить",
			confirmButtonText: "ОК",
			input: "textarea",
			inputPlaceholder: "Введите комментарий",
			showCloseButton: true,
			timer: null
		}, confirmationFunction, dismissFunction);
	}

	newItemInput({ title = '', message = '', inputPlaceholder = 'Введите название нового элемента', showCancelButton = true}, confirmationFunction: Function = null, dismissFunction: Function = null) {
		return this.showMessage({
			title: title,
			text: message,
			showCancelButton: showCancelButton,
			type: "",
			closeOnConfirm: true,
			cancelButtonText: "Отмена",
			confirmButtonText: "ОК",
			input: "text",
			inputPlaceholder: inputPlaceholder,
			showCloseButton: true,
			timer: null
		}, confirmationFunction, dismissFunction);
	}

	info({
		message = NotificationService.DEFAULT_INFO_MESSAGE,
		title = NotificationService.DEFAULT_INFO_TITLE,
		confirmationFunction = null,
		notificationType = NotificationType.Toast,
		showConfirmButton = true,
		showCloseButton = true,
		enableHtml = false,
		timer = null
	} = {}, options = {}) {
		if (notificationType === NotificationType.Toast) {
			this.toastr.info(message, title, Object.assign(this.toastrOptions(), options));
		} else {
			this.showMessage(Object.assign({ title: title, text: message, timer: timer, showConfirmButton: showConfirmButton, confirmButtonText: "ОК", type: "info", showCloseButton: showCloseButton, enableHtml: enableHtml }, options), confirmationFunction);
		}

	}

	warning({
		message = NotificationService.DEFAULT_INFO_MESSAGE,
		title = NotificationService.DEFAULT_INFO_TITLE,
		confirmationFunction = null,
		notificationType = NotificationType.Toast,
		showConfirmButton = true,
		showCloseButton = true
	} = {}, options = {}) {
		if (notificationType === NotificationType.Toast) {
			this.toastr.info(message, title, Object.assign(this.toastrOptions(), options));
		} else {
			this.showMessage(Object.assign({ title: title, text: message, showConfirmButton: showConfirmButton, confirmButtonText: "ОК", type: "warning", showCloseButton: showCloseButton }, options), confirmationFunction);
		}

	}

	private showMessage({ title = '', text = '', type = "success", timer = null,
		showConfirmButton = true, showCancelButton = false, closeOnConfirm = true, cancelButtonText = "", confirmButtonText = "", showCloseButton = true, input = null, inputPlaceholder = null, enableHtml = false, showIcon = true, imageUrl = null, imageHeight = null, imageWidth = null }, confirmationFunction: Function = null,
		dismissFunction: Function = null, closeFunction: Function = null) {

		let promise = this.sweetAlert({
			title: title,
			text: enableHtml ? '' : text,
			html: enableHtml ? text : '',
			type: showIcon ? <SweetAlertType>type : null,
			input: input,
			inputPlaceholder: inputPlaceholder,
			timer: timer,
			showConfirmButton: showConfirmButton,
			showCancelButton: showCancelButton,
			cancelButtonText: cancelButtonText,
			confirmButtonText: confirmButtonText,
			showCloseButton: showCloseButton,
			imageUrl: imageUrl,
			imageHeight: imageHeight,
			imageWidth: imageWidth
		});

		if (confirmationFunction) {
			promise = promise.then(
				(result) => {
					console.log(result)
					if (result.value || result.value === "") {
						return confirmationFunction(result.value);
					} else {
						if (closeFunction && result.dismiss === 'close') {
							return closeFunction(result.value);
						} else {
							if (dismissFunction) {
								return dismissFunction(result.value);
							}
						}
					}
				}
			);
		}
		return promise;
	}

	private toastrOptions(options = {}) {
		return {
			allowHtml: false,
			closeButton: false,
			closeHtml: '<button>&times;</button>',
			extendedTimeOut: 1000,
			iconClasses: {
				error: 'toast-error',
				info: 'toast-info',
				success: 'toast-success',
				warning: 'toast-warning'
			},
			positionClass: window.innerWidth <= 575 ? "toast-center-center" : "toast-bottom-right",
			messageClass: 'toast-message',
			// onHidden: null,
			// onShown: null,
			// onTap: null,
			progressBar: false,
			tapToDismiss: true,
			// templates: {
			// 	toast: 'directives/toast/toast.html',
			// 	progressbar: 'directives/progressbar/progressbar.html'
			// },
			timeOut: 3000,
			titleClass: 'toast-title',
			toastClass: 'toast'
		};
	}



}
