import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { DirtyStateGuard } from '../core/guards/dirty-state.guard';

import { MerchantsListPage } from './merchants/list/list.page';
import { MerchantPage } from './merchants/merchant/merchant.page';

import { TidsListPage } from './tids/list/list.page';
import { TidPage } from './tids/tid/tid.page';
import { TidImportPage } from './tids/import/tid-import.page';

import { CustomerObjectsListPage } from './customer-objects/list/list.page';
import { CustomerObjectPage } from './customer-objects/customer-object/customer-object.page';

import { ContragentsListPage } from './contragents/list/list.page';
import { ContragentPage } from './contragents/contragent/contragent.page';

import { DevicesListPage } from '../shared/components/devices/list/list.page';
import { DevicePage } from '../shared/components/devices/device/device.page';
import { CallsListPage } from './calls/list/list.page';

import { ServicesListPage } from './services/list/list.page';
import { ServicePage } from './services/service/service.page';

import { ServiceModesListPage } from './service-modes/list/list.page';
import { ServiceModePage } from './service-modes/service-mode/service-mode.page';

import { ServiceCategoriesListPage } from './service-categories/list/list.page';
import { ServiceCategoryPage } from './service-categories/service-category/service-category.page';

import { ServiceTypeListPage } from './service-types/list/list.page';
import { ServiceTypePage } from './service-types/service-type/service-type.page';

import { AgreementListPage } from './agreements/list/list.page';
import { AgreementPage } from './agreements/agreement/agreement.page';
import { AttachmentTypesListPage } from './attachment-types/list/list.page';
import { AttachmentTypePage } from './attachment-types/attachment-type/attachment-type.page';
import { AttachmentTypeConfigurationPage } from './attachment-type-configurations/attachment-type-configuration/attachment-type-configuration.page';
import { AttachmentTypeConfigurationsListPage } from './attachment-type-configurations/list/list.page';

import { ColorSchemeSettingsPage } from './color-scheme-settings/color-scheme-settings.page';
import { WarehousesListPage } from './warehouses/list/list.page';
import { WarehousePage } from './warehouses/warehouse/warehouse.page';
import { RequestStoppingReasonsListPage } from './request-stopping-reasons/list/list.page';
import { RequestStoppingReasonPage } from './request-stopping-reasons/request-stopping-reason/request-stopping-reason.page';
import { StoppingReasonTypeRulesListPage } from './stopping-reason-type-rules/list/list.page';
import { StoppingReasonTypeRulePage } from './stopping-reason-type-rules/stopping-reason-type-rule/stopping-reason-type-rule.page';
import { NomenclatureListPage } from './nomenclature/list/list.page';
import { NomenclaturePage } from './nomenclature/nomenclature.page';

export const categoriesRoutes: Routes = [
	{ path: 'devices', component: DevicesListPage, canActivate: [AuthGuard] },
	{ path: 'device/:deviceId', component: DevicePage, canActivate: [AuthGuard] },

	{ path: 'contragents', component: ContragentsListPage, canActivate: [AuthGuard] },
	{ path: 'contragent/new', component: ContragentPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },
	{ path: 'contragent/:contragentId', component: ContragentPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'tids', component: TidsListPage, canActivate: [AuthGuard] },
	{ path: 'tid/new', component: TidPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },
	{ path: 'tid/import', component: TidImportPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },
	{ path: 'tid/:tidId', component: TidPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'merchants', component: MerchantsListPage, canActivate: [AuthGuard] },
	//{ path: 'merchant/new', component: MerchantPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] }, LTER-1085
	{ path: 'merchant/:merchantId', component: MerchantPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'customer-objects', component: CustomerObjectsListPage, canActivate: [AuthGuard] },
	{ path: 'customer-object/new', component: CustomerObjectPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },
	{ path: 'customer-object/:customerObjectId', component: CustomerObjectPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'calls', component: CallsListPage, canActivate: [AuthGuard] },

	{ path: 'services', component: ServicesListPage, canActivate: [AuthGuard] },
	{ path: 'service/new', component: ServicePage, canActivate: [AuthGuard] },
	{ path: 'service/:serviceId', component: ServicePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'service-modes', component: ServiceModesListPage, canActivate: [AuthGuard] },
	{ path: 'service-mode/new', component: ServiceModePage, canActivate: [AuthGuard] },
	{ path: 'service-mode/:serviceModeId', component: ServiceModePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'service-categories', component: ServiceCategoriesListPage, canActivate: [AuthGuard] },
	{ path: 'service-category/new', component: ServiceCategoryPage, canActivate: [AuthGuard] },
	{ path: 'service-category/:serviceCategoryId', component: ServiceCategoryPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'service-types', component: ServiceTypeListPage, canActivate: [AuthGuard] },
	{ path: 'service-type/new', component: ServiceTypePage, canActivate: [AuthGuard] },
	{ path: 'service-type/:serviceTypeId', component: ServiceTypePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'agreements', component: AgreementListPage, canActivate: [AuthGuard] },
	{ path: 'agreement/new', component: AgreementPage, canActivate: [AuthGuard] },
	{ path: 'agreement/:agreementId', component: AgreementPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'color-scheme-settings', component: ColorSchemeSettingsPage, canActivate: [AuthGuard] },

	{ path: 'attachment-types', component: AttachmentTypesListPage, canActivate: [AuthGuard] },
	{ path: 'attachment-type/new', component: AttachmentTypePage, canActivate: [AuthGuard] },
	{ path: 'attachment-type/:attachmentTypeId', component: AttachmentTypePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'attachment-type-configurations', component: AttachmentTypeConfigurationsListPage, canActivate: [AuthGuard] },
	{ path: 'attachment-type-configuration/new', component: AttachmentTypeConfigurationPage, canActivate: [AuthGuard] },
	{ path: 'attachment-type-configuration/:attachmentTypeConfigurationId', component: AttachmentTypeConfigurationPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'warehouses', component: WarehousesListPage, canActivate: [AuthGuard] },
	{ path: 'warehouse/:warehouseId', component: WarehousePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'request-stopping-reasons', component: RequestStoppingReasonsListPage, canActivate: [AuthGuard] },
	{ path: 'request-stopping-reason/:requestStoppingReasonId', component: RequestStoppingReasonPage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },

	{ path: 'stopping-reason-type-rules', component: StoppingReasonTypeRulesListPage, canActivate: [AuthGuard] },
	{ path: 'stopping-reason-type-rule/new', component: StoppingReasonTypeRulePage, canActivate: [AuthGuard] },
	{ path: 'stopping-reason-type-rule/:stoppingReasonTypeRuleId', component: StoppingReasonTypeRulePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },
	{ path: 'stopping-reason-type-rule/copy/:stoppingReasonTypeRuleId', component: StoppingReasonTypeRulePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] },


	{ path: 'nomenclatures', component: NomenclatureListPage, canActivate: [AuthGuard] },
	{ path: 'nomenclatures/:nomenclaturesId', component: NomenclaturePage, canDeactivate: [DirtyStateGuard], canActivate: [AuthGuard] }
];