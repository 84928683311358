import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "../../core/services/data.service";
import { NotificationService } from "../../core/services/notification.service";
import { ServiceTypeItem } from "../models/services/service-type-item";

@Injectable()
export class ServiceTypesService extends DataService<ServiceTypeItem, ServiceTypeItem> {

	constructor(
		protected http: HttpClient,
		protected notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'service-types';
	}

}
