import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import {InitType} from "../models/init-types/init-type";

@Injectable()
export class InitTypesService extends DataService<InitType, InitType> {

	constructor(
		http: HttpClient,
		notificationService: NotificationService) {
		super(http, notificationService);

		this.controllerName = 'init-types';
	}
}
