import { SecurityService } from './../../core/services/security.service';
import { Component, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { ListPageBase } from '../../shared/components/list-page-base/list-page-base';
import { ActivityListItem } from '../../shared/models/activity/activity-list-item';
import { RequestsService } from '../../shared/services/requests.service';
import { LookupService } from '../../shared/services/lookup.service';
import { KeyValueObject } from '../../shared/models/core/KeyValueObject';
import { CompositeFilterDescriptor, DataSourceRequestState, FilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { from, fromEvent, interval, Subject, Subscription } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, tap, filter, takeUntil } from 'rxjs/operators';
import { StatePersistingService } from '../../shared/services/state-persisting.service';
import { GridSettings } from '../../shared/models/grid/grid-settings';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DialogService } from '@progress/kendo-angular-dialog';
import { NotificationService } from '../../core/services/notification.service';
import { CheckboxColumnComponent, ColumnBase, ColumnComponent, ColumnVisibilityChangeEvent, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { UserFiltersService } from '../../shared/services/user-filters.service';
import { UserFilter } from '../../shared/models/user-filter/user-filter';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestNotificationTypeService } from '../../shared/services/request-notification-type.service';
import { RequestNotificationType } from '../../shared/models/request/request-notification-type';
import { ContragentType } from '../../shared/enums';
import { environment } from '../../../environments/environment';
import { ColumnSettings } from '../../shared/models/grid/column-settings';
import { ActivitiesService } from '../../shared/services/activities.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { UserFilterType } from '../../shared/enums/user-filter-type.enum';
import {UsersService} from "../../admin/users/users.service";
import {InitTypesService} from "../../shared/services/init-types.service";
import {RequestStatusesService} from "../../shared/services/request-statuses.service";
import {RequestStatusMspAliasGroupsService} from "../../shared/services/request-status-msp-alias-groups.service";
import {ActivityStatusesService} from "../../shared/services/activity-statuses.service";
import {RequestAttributesService} from "../../shared/services/request-attributes.service";
import {RequestPrioritiesService} from "../../shared/services/request-priorities.service";
import {AttachmentTypesService} from "../../shared/services/attachment-types.service";
import {RegionsService} from "../../shared/services/regions.service";
import {MerchantsService} from "../../categories/merchants/merchants.service";
import {AgreementsService} from "../../shared/services/agreements.service";
import {CategoryServicesService} from "../../shared/services/category-services.service";
import {ServiceCategoriesService} from "../../shared/services/service-categories.service";
import {ServiceTypesService} from "../../shared/services/service-types.service";
import {ServiceModesService} from "../../shared/services/service-modes.service";
import {ServiceCentersService} from "../../shared/services/service-centers.service";
import {ActivityTypesService} from "../../shared/services/activity-types.service";
import {RequestTypeService} from "../../shared/services/request-type.service";
import { ActivityPlannedBy } from '../../shared/enums/activity-planned-by.enum';
import { RolesService } from '../../admin/roles';
import { ExportExcelFromGridService } from '../../shared/services/export-excel-from-grid.service';
import { IdleService } from '../../shared/services/idle.service';

@Component({
	selector: 'activity-list',
	templateUrl: './list.page.html',
	styleUrls: [
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'./list.page.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [IdleService]
})
export class ActivitiesListPage extends ListPageBase<ActivityListItem> implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('filterAllInput') filterAllInput: ElementRef;
	@ViewChild('userFiltersModal') userFiltersModal: any;
	@ViewChild('fileInput') fileInput: any;
	@ViewChild("requestsGrid") requestsGrid: GridComponent;

	activityListSubscription: Subscription = null;

	isIdle = false;

	requestTypes: KeyValueObject[] = [];
	activityResults: KeyValueObject[] = [];
	gridSelectedRequests: ActivityListItem[] = [];
	userFilters: UserFilter[] = [];
	notificationTypes: RequestNotificationType[] = [];

	reconciliations: KeyValueObject[] = new Array(new KeyValueObject(1, "Да"), new KeyValueObject(0, "Нет"));

	isMap: boolean = false;
	isMapSwitcherOn: boolean = false;
	isGroupClick: boolean = false;

	public requestsGridGridSelectedCallback = (args) => args.dataItem;

	lastFilterText = '';
	public dataGenerating = false;
	public loadingFinancialReconciliation: boolean = false;
	public setDefaultFiltersIsInProgress: number[] = [];
	public removeFiltersIsInProgress: number[] = [];
	public plannedBy: KeyValueObject[] = []

	private readonly _refreshTime: number;
	private _lastRefreshTime?: Date = null;

	private _destroy$ = new Subject<boolean>();

	throttle = 300;
	scrollDistance = 1;
	selectedTab = 1;
	selectedRequestStatus = 12;
	selectedDate = 'today';
	selectedOrder = null;
	showTomorrow = false;
	showDays = [];
	public openedSearchModal = false;
	searchRequests: ActivityListItem[] = [];
	searchRequestsSize: number = 0;
	delaySearch = null;

	toggledAccordions: Map<number, boolean> = new Map();
	alwaysToggled = false;

	mobileDeviceVisibleColumnNames: string[] = [
		"requestIdStr",
		"createdDateLocal",
		"requestTypeName",
		"statusName",
		"performerUserGroupName",
		"customerContragentName",
		"tspLegalEntityName",
		"tidNumbers",
		"tspAddress",
		"slaDueDateLocal",
		"serviceCenterName",
		"stoppedUntilLocal",
		"hoursToDeadline",
		"customerObjectLatitude",
		"customerObjectLongitude",
		"customerContragentName",
		"description",
		"tspAddress"
	];

	constructor(
		private sanitizer: DomSanitizer,
		protected appService: AppService,
		router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		protected dataService: ActivitiesService,
		protected requestsService: RequestsService,
		protected persistingService: StatePersistingService,
		private lookupService: LookupService,
		protected dialogService: DialogService,
		protected intl: IntlService,
		protected notificationService: NotificationService,
		protected exportExcelFromGridService: ExportExcelFromGridService,
		private userFiltersService: UserFiltersService,
		private securityService: SecurityService,
		public usersDataService: UsersService,
		public initTypesService: InitTypesService,
		public requestStatusesService: RequestStatusesService,
		public requestStatusMspAliasGroupsService: RequestStatusMspAliasGroupsService,
		public activityStatusesService: ActivityStatusesService,
		public requestAttributesService: RequestAttributesService,
		public requestPrioritiesService: RequestPrioritiesService,
		public attachmentTypesService: AttachmentTypesService,
		public regionsService: RegionsService,
		public merchantsService: MerchantsService,
		public agreementsService: AgreementsService,
		public categoryServicesService: CategoryServicesService,
		public serviceCategoriesService: ServiceCategoriesService,
		public serviceTypesService: ServiceTypesService,
		public serviceModesService: ServiceModesService,
		public serviceCentersService: ServiceCentersService,
		public activityTypesService: ActivityTypesService,
		public requestTypeDataService: RequestTypeService,
		private requestNotificationTypeService: RequestNotificationTypeService,
		protected rolesDataService: RolesService,
		private idleService: IdleService
	) {
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		this.groupedKey = 'createdDateGroupValue';
		appService.pageTitle = 'Активности';
		this._refreshTime = this._getMsTime(environment.requestListRefreshTime);
		this.alwaysToggled = localStorage.getItem('alwaysToggled') === 'true';
	}

	public loadData(state: DataSourceRequestState = this.gridSettings.state): void {
		this.loading = true;

		var visibleColumnNames = []

		if (this.isMobileDevice) {
			visibleColumnNames = this.mobileDeviceVisibleColumnNames;
		}
		else {
			visibleColumnNames = this.gridSettings.columnsConfig.filter(x => !x.hidden).map(x => x.field);
		}

		if (this.activityListSubscription !== null)
			this.activityListSubscription.unsubscribe();

		this.activityListSubscription = this.dataService.activityList(state, visibleColumnNames).subscribe(lvm => {
			this.processListItems(lvm.data);
			this.listViewModel = lvm;
			this.groupedData = this.groupedByKey;
			this.loading = false;
		});
	}

	public columnColor(statusColorCode: string, slaColorCode: string): SafeStyle {
		if (slaColorCode) {
			return this.sanitizer.bypassSecurityTrustStyle(slaColorCode);
		}

		return this.sanitizer.bypassSecurityTrustStyle(statusColorCode);
	}

	public rowClass(context: RowClassArgs) {
		if (context.dataItem.hoursToDeadline) {

			let splitHours: number = 0;
			let hours: number = 0;
			let minutes: number = 0;
			let splitDays: number = 0;
			let days: number = 0;

			if (context.dataItem.hoursToDeadline.includes(".")) {
				splitDays = context.dataItem.hoursToDeadline.split(".");
				days = parseInt(splitDays[0]);
			} else {
				splitHours = context.dataItem.hoursToDeadline.split(":");
				hours = parseInt(splitHours[0]);
				minutes = parseInt(splitHours[1]);
			}

			if ((days < 0) || (hours < -3 || (hours === -3 && minutes > 0))) {
				return {
					'cursor-pointer': true,
					'red-text': true
				};
			} else {
				return {
					'cursor-pointer': true
				};
			}

		} else {
			return {
				'cursor-pointer': true
			};
		}
	}

	filterChange(): void {
		this.gridSelectedRequests = [];
	}

	public enableSaveGridSettings: boolean = true;

	gridSettingsStorageKey: string = 'activity-grid-settings-v3';

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 25,
			sort: [{ field: 'requestIdStr', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'requestIdStr',
				title: '№ заявки',
				filter: 'string',
				filterable: true,
				width: 150,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'requestExternalId',
				title: '№ заявки заказчика',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false,
			},
			{
				orderIndex: 2,
				field: 'activityNumber',
				title: '№ активности',
				filter: 'string',
				filterable: true,
				width: 150,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'requestTidNumbers',
				title: 'Tid заявки',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 4,
				field: 'requestTspAddress',
				title: 'Адрес ТСП',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'createdDateLocal',
				title: 'Дата создания активности',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 280,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'dueDateLocal',
				title: 'Срок выполнения активности',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'planStartDateLocal',
				title: 'Плановая дата активности',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'performerUserName',
				title: 'Ответственный по активности',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false,
				filterField: 'performerUserId'
			},
			{
				orderIndex: 9,
				field: 'activityReason',
				title: 'Причина активности',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 10,
				field: 'statusName',
				title: 'Состояние активности',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false,
				filterField: 'statusId'
			},
			{
				orderIndex: 11,
				field: 'serviceCenterName',
				title: 'СЦ',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false,
				filterField: 'statusId'
			},
			{
				orderIndex: 12,
				field: 'finishDateLocal',
				title: 'Дата завершения активности',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 13,
				field: 'finishActivityComment',
				title: 'Комментарий по закрытию активности',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			{
				orderIndex: 14,
				field: 'requestServiceCenterName',
				title: 'Исполнитель (СЦ)',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false,
				filterField: 'requestServiceCenterId'
			},
			{
				orderIndex: 15,
				field: 'requestCustomerContragentName',
				title: 'Клиент',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false,
				filterField: 'requestCustomerContragentId'
			},
			{
				orderIndex: 16,
				field: 'requestTspName',
				title: 'Название ТСП',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false,
				filterField: 'requestTspId'
			},
			{
				orderIndex: 17,
				field: 'merchantRegionName',
				title: 'Регион ТСП',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'merchantRegionId'
			},
			{
				orderIndex: 18,
				field: 'requestCustomerObjectLegalName',
				title: 'Ю/Л в запросе',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: false
			},
			////////////////Опциональные столбцы////////////////
			{
				orderIndex: 19,
				field: 'parentExternalId',
				title: '№ групповой заявки',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 20,
				field: 'authorUserName',
				title: 'Автор',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'authorUserId'
			},
			{
				orderIndex: 21,
				field: 'acquirerName',
				title: 'Банк-эквайер',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'acquirerId'
			},
			{
				orderIndex: 22,
				field: 'attachmentTypesString',
				title: 'Вложения в активности',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'attachmentTypesCustomFilter'
			},
			{
				orderIndex: 23,
				field: 'missingAttachmentTypeNames',
				title: 'Недостающие вложения в активности',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'hasMissingAttachments'
			},
			{
				orderIndex: 24,
				field: 'authorUserGroupName',
				title: 'Группа автора',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'authorUserGroupId'
			},
			{
				orderIndex: 25,
				field: 'childCount',
				title: 'Группа из',
				filter: 'numeric',
				filterable: true,
				width: 300,
				hidden: true,
			},
			{
				orderIndex: 26,
				field: 'performerUserGroupName',
				title: 'Группа ответственного',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'performerUserGroupId'
			},
			{
				orderIndex: 27,
				field: 'requestFinishDateLocal',
				title: 'Дата завершения',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 28,
				field: 'requestStateUpdateDateLocal',
				title: 'Дата изменения состояния',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 29,
				field: 'requestSlaStartDateLocal',
				title: 'Дата начала SLA',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 30,
				field: 'requestEndOfSlaDateLocal',
				title: 'Дата окончания работ',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 31,
				field: 'customerCreatedDateLocal',
				title: 'Дата регистрации у заказчика',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 32,
				field: 'requestCreatedDateLocal',
				title: 'Дата создания заявки',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 280,
				hidden: true
			},
			{
				orderIndex: 33,
				field: 'finishDateFactLocal',
				title: 'Дата фактического завершения работ (по талону)',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 34,
				field: 'activityTypeName',
				title: 'Тип активности',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'activityTypeId'
			},
			{
				orderIndex: 35,
				field: 'requestInitTypeName',
				title: 'Источник',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'requestInitTypeId'
			},
			{
				orderIndex: 36,
				field: 'sla',
				title: 'Количество часов SLA',
				filter: 'numeric',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 37,
				field: 'description',
				title: 'Описание',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 38,
				field: 'hoursToDeadline',
				title: 'Осталось часов',
				filter: 'date',
				filterable: false,
				width: 180,
				hidden: true
			},
			{
				orderIndex: 39,
				field: 'stoppedUntilLocal',
				title: 'Остановлено до',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 40,
				field: 'requestPerformerUserName',
				title: 'Ответственный',
				filter: 'lookup',
				filterable: true,
				width: 300,
				filterField: 'requestPerformerUserId',
				hidden: true,
			},
			{
				orderIndex: 41,
				field: 'lastCriticalNotificationPriorityId',
				title: 'Последнее критичное уведомление',
				filter: 'lookup',
				filterable: true,
				width: 300,
				filterField: 'lastCriticalNotificationTypeId',
				hidden: true,
			},
			{
				orderIndex: 42,
				field: 'spentHours',
				title: 'Потрачено времени',
				filter: 'date',
				filterable: false,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 43,
				field: 'requestPriorityName',
				title: 'Приоритет',
				filter: 'lookup',
				filterable: true,
				width: 300,
				filterField: 'requestPriorityId',
				hidden: true
			},
			{
				orderIndex: 44,
				field: 'reasonForStopping',
				title: 'Причина остановки',
				filter: 'string',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 45,
				field: 'externalProjectName',
				title: 'Проект',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 46,
				field: 'partnerStatusAliasName',
				title: 'Состояние в ИС партнера',
				filter: 'lookup',
				filterable: true,
				width: 300,
				filterField: 'partnerStatusAliasGroupId',
				hidden: true,
			},
			{
				orderIndex: 47,
				field: 'slaDueDateLocal',
				title: 'Срок SLA',
				filter: 'date',
				format: 'dd.MM.yyyy HH:mm',
				filterable: true,
				width: 300,
				hidden: true
			},
			{
				orderIndex: 48,
				field: 'requestStatusName',
				title: 'Статус заявки',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'requestStatusId'
			},
			{
				orderIndex: 49,
				field: 'attributeString',
				title: 'Тип в БЧ',
				filter: 'lookup',
				filterable: true,
				width: 300,
				filterField: 'requestAttributeId',
				hidden: true,
			},
			{
				orderIndex: 51,
				field: 'unreadNotificationMaxPriorityId',
				title: 'Уведомления',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'requestNotificationsCustomFilter'
			},
			{
				orderIndex: 52,
				field: 'reconciliationCompletedString',
				title: 'Фин. сверка с заказчиком',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'reconciliationCompleted'
			},
			{
				orderIndex: 53,
				field: 'reconciliationContractorCompletedString',
				title: 'Фин. сверка с исполнителем',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'reconciliationContractorCompleted'
			},
			{
				orderIndex: 54,
				field: 'tspLegalEntityName',
				title: 'Ю/л ТСП',
				filter: 'lookup',
				filterable: true,
				width: 300,
				filterField: 'tspLegalEntityId',
				hidden: true,
			},
			{
				orderIndex: 55,
				field: 'requestTypeName',
				title: 'Тип заявки',
				filter: 'lookup',
				filterable: true,
				width: 300,
				filterField: 'requestTypeId',
				hidden: true,
			},
			{
				orderIndex: 56,
				field: 'agreementNumber',
				title: 'Договор',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'agreementId'
			},
			{
				orderIndex: 57,
				field: 'requestDistanceFromCityKm',
				title: 'Удаленность по договору, км',
				filter: 'numeric',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'requestDistanceFromCityKm'
			},
			{
				orderIndex: 58,
				field: 'customerObjectDistanceFromCityKm',
				title: 'Фактическая удаленность, км',
				filter: 'numeric',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'customerObjectDistanceFromCityKm'
			},
			{
				orderIndex: 59,
				field: 'requestServiceNames',
				title: 'Услуги',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'requestServiceId'
			},
			{
				orderIndex: 60,
				field: 'requestServiceCategoryNames',
				title: 'Категории услуг',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'requestServiceCategoryId'
			},
			{
				orderIndex: 61,
				field: 'requestServiceTypeNames',
				title: 'Типы сервисных услуг',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'requestServiceTypeId'
			},
			{
				orderIndex: 62,
				field: 'requestServiceModeNames',
				title: 'Режимы сервисных услуг',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'requestServiceModeId'
			},
			{
				orderIndex: 63,
				field: 'requestServiceIsDoneNames',
				title: 'Сервисные услуги выполнены',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'requestServiceIsDone'
			},
			{
				orderIndex: 64,
				field: 'performerContragentName',
				title: 'Контрагент ответственного',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: false,
				filterField: 'performerContragentId'
			},
			{
				orderIndex: 65,
				field: 'plannedBy',
				title: 'Планирование через',
				filter: 'lookup',
				filterable: true,
				width: 300,
				hidden: true,
				filterField: 'plannedBy'
			},
			{
				orderIndex: 66,
				field: 'rolesNames',
				title: 'Роль',
				filter: 'lookup',
				filterable: true,
				width: 300,
				filterField: 'rolesCustomFilter',
				hidden: true,
			},
			{
				orderIndex: 67,
				field: 'activityResult',
				title: 'Результат активности',
				filter: 'lookup',
				filterable: true,
				filterField: 'activityResultId',
				width: 300,
				hidden: true,
			},
		]
	};

	onListPageInit(): void {

		if (this.isMobileDevice) {
			return;
		}
		this.lookupService.getData("request-types", null).subscribe(data => this.requestTypes = data);
		this.requestNotificationTypeService.getTypes().subscribe(data => this.notificationTypes = data);

		if (this.gridSettings.columnsConfig.some(x => !x.hidden && x.field === 'performerContragentName')) {
			var contragentsState: DataSourceRequestState = {
				skip: 0,
				take: null,
				filter: { logic: "or", filters: [{ field: "ContragentTypeId", operator: "eq", value: ContragentType.serviceCenter }, { field: "ContragentTypeId", operator: "eq", value: ContragentType.selfCompany }] }
			};
		}

		this.dataService.getAllActivityResults().subscribe((data) => {
			this.activityResults = data.map(res => new KeyValueObject(res.activityResultId, res.activityResult));
		});

		this.loadUserFilters(null);

		this.gridHeight = window.innerHeight - 108;
		if (this.gridSettings.columnsConfig.some(x => !x.hidden && x.field === 'plannedBy')) {
			let map = enumToMap(ActivityPlannedBy);
			let objs = Array.from(map.entries()).map(m => (new KeyValueObject(m[1], m[0])));
			this.plannedBy = objs;
		}

		function enumToMap(enumeration: any): Map<string, number> {
			const map = new Map<string, number>();
			for (let key in enumeration) {
				//TypeScript does not allow enum keys to be numeric
				if (!isNaN(Number(key))) continue;

				const val = enumeration[key] as number;

				//TypeScript does not allow enum value to be null or undefined
				if (val !== undefined && val !== null)
					map.set(key, val);
			}

			return map;
		}
	}

	loadUserFilters(selectedUserFilterId?: number) {

		this.loading = true;

		this.userFiltersService.list({
			skip: 0,
			take: null,
			filter: { logic: "and", filters: [{ field: "userFilterTypeId", operator: "eq", value: <number>UserFilterType.Activities }] }
		}).subscribe(x => {
			this.userFilters = x.data;

			if (selectedUserFilterId != null) {
				var filtered = this.userFilters.filter(x => x.userFilterId === selectedUserFilterId);

				if (filtered.length > 0) {
					this.setUserFilter(filtered[0]);
				}
			} else {
				var filtered = this.userFilters.filter(x => x.isDefault);

				if (filtered.length > 0) {
					this.setUserFilter(filtered[0]);
				} else {
					this.setLocalFilter();
				}
			}

		});
	}

	clearFilters(): void {
		this.requestsGrid.filterable = true;

		this.gridSettings.state = {
			skip: 0,
			take: 25,
			sort: [{ field: 'requestIdStr', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		};

		if (this.enableSaveGridSettings) {
			this.saveGridSettings();
		}

		this.appService.clearAllGridFilters.next();

		this.onGridStateChange();

		this.loadData();
	}

	public ngOnInit(): void {

		this.route.queryParams
			.subscribe(params => {
				console.log('requestId', params.requestId);

				var gridSettings: GridSettings = this.persistingService.get(this.gridSettingsStorageKey);

				if (params.requestId) {
					if (gridSettings == null) {
						gridSettings = this.gridSettings;
					}

					if (gridSettings.state.filter == null) {
						gridSettings.state.filter = this.gridSettings.state.filter;
					}

					gridSettings.state.filter.filters = [];
					gridSettings.state.filter.filters.push({ field: "requestIdStr", operator: "eq", value: params.requestId });
				}

				this.setGridSettings(gridSettings);

				this.gridHeight = window.innerHeight - 185;

				this.onListPageInit();

				if (!this.isMobileDevice) {
					this._initUserSearchListening();
				}

				this._initIdleService();
			});
	}

	ngAfterViewInit(): void {

		if (!this.filterAllInput)
			return;

		fromEvent(this.filterAllInput.nativeElement, 'keyup')
			.pipe(
				map((evt: any) => evt.target.value),
				debounceTime(500),
				distinctUntilChanged(),
			).subscribe((text: string) => this.onFilter(text));
	}

	protected onGridStateChange(): void {
		this.selectedUserFilter = null;
	}

	public ngOnDestroy() {
		if (this.activityListSubscription !== null)
			this.activityListSubscription.unsubscribe();

		this._destroy$.next(true);
		this._destroy$.complete();
	}

	processListItems(items: ActivityListItem[]): void {
		this._updateRefreshTime();
	}

	public cellMobileClick(dataItem) {
		var request = (<ActivityListItem>dataItem);
		var requestId = request.requestId;
		var win = window.open(`/requests/${requestId}`, '_blank');
	}

	public cellClick({ type, dataItem, column }: any): void {

		var activity = (<ActivityListItem>dataItem);

		if (column instanceof CheckboxColumnComponent) {

			if (this.gridSelectedRequests.some(x => x.requestId === activity.requestId)) {
				this.gridSelectedRequests = this.gridSelectedRequests.filter(x => x.requestId !== activity.requestId);
			} else {
				this.gridSelectedRequests.push(dataItem);
			}

		} else if (type === "click") {
			let col = <ColumnComponent>column;
			let win = window.open(`/requests/${activity.requestId}?activityId=${activity.activityId}`, '_blank');
		}
	}

	public onContextMenuClick({ item, dataItem }: any): void {

	}

	public onRequestsReport(): void {
		this.dataGenerating = true;

		this.getXlsx('activities', 'Активности.xlsx',
			(c) => {
				c.forEach(f => {
					if (f.field === "sla") {
						f.field = "slaAsTimeSpanString";
						f.filter = "string";
					}
				});
			},
			() => {
				this.dataGenerating = false;
			});
	}

	public onLoadFinancialReconciliation(file: File): void {
		// this.loadingFinancialReconciliation = true;

		// this.dataService.loadFinancialReconciliation(file[0]).subscribe(x => {
		// 	if (x.isSuccessful) {
		//         let errors = x.data;
		//         if (errors && errors.length > 0) {
		//             const dialogRef = this.dialogService.open({ content: MultipleOperationErrorResultComponent, width: '50%', height: '50%' });

		//             const multipleOperationErrorResultComponent = <MultipleOperationErrorResultComponent>dialogRef.content.instance;
		//             multipleOperationErrorResultComponent.errors = errors;
		//             multipleOperationErrorResultComponent.heightPercent = 53;
		// 			multipleOperationErrorResultComponent.headerText = "Все заявки обработаны корректно, кроме:";
		//         } else {
		//             this.notificationService.success({
		//                 title: 'Файл обработан успешно',
		//                 message: 'Сверка проведена успешно!',
		//                 notificationType: NotificationType.SweetAlert
		//             });
		//         }

		//     }

		// 	this.fileInput.nativeElement.value = '';
		// 	this.loadingFinancialReconciliation = false;
		// }, () => {
		// 	this.fileInput.nativeElement.value = '';
		//     this.loadingFinancialReconciliation = false;
		// });
	}

	public onFilter(inputValue: string): void {
		this.lastFilterText = inputValue;

		this.loadData(this.getCustomState(inputValue));
	}

	private getCustomState(inputValue: string): DataSourceRequestState {
		return {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: "or",
				filters: [
					{
						field: 'requestIdStr',
						operator: 'eq',
						value: inputValue
					},
					{
						field: 'requestExternalId',
						operator: 'eq',
						value: inputValue
					},
					{
						field: 'statusName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'requestServiceCenterName',
						operator: 'contains',
						value: inputValue
					},
				],
			}
		};

	}

	private _initUserSearchListening(): void {
		this._updateRefreshTime();

		const interval$ = from(interval(this._refreshTime))
			.pipe(
				filter(x => (new Date().getTime() - this._lastRefreshTime.getTime() >= this._refreshTime) && !this.loading && !this.isIdle),
				takeUntil(this._destroy$)
			);

		interval$.subscribe(() => {
			this.loadData();
		});
	}

	private _initIdleService(){
		this.idleService.idleMessage = 'Вы долго не проявляли активность, поэтому обновление реестра активностей приостановлено.';
		this.idleService.getIdleStatus().subscribe((isIdle) => {
			this.isIdle = isIdle;
		});
	}

	private _updateRefreshTime(): void {
		this._lastRefreshTime = new Date();
	}

	private _getMsTime(seconds: number): number {
		return seconds * 1000;
	}

	get isMobileDevice(): boolean {
		return this.appService.isMobileDevice;
	}

	public initialized: boolean = false;

	setUserFilter(userFilter: UserFilter) {
		this.selectedUserFilter = userFilter;

		var localFilter = localStorage.getItem(this.gridSettingsStorageKey);

		if (!this.isMobileDevice && (localFilter == null || localFilter != userFilter.filterExpression)) {
			this.setGridSettings(JSON.parse(userFilter.filterExpression));
			this.saveGridSettings();
		}

		this.loadData();

		this.initialized = true;
	}

	setLocalFilter() {
		var localFilter = this.persistingService.get<GridSettings>(this.gridSettingsStorageKey);

		if (localFilter != null && !this.isMobileDevice) {
			this.setGridSettings(localFilter);
			this.saveGridSettings();
		}

		this.loadData();
		this.checkTomorrowRequests();

		this.initialized = true;
	}

	newFilter() {
		this.notificationService.newItemInput({ inputPlaceholder: "Введите название для нового фильтра" },
			(newFilterName) => {

				if (!!newFilterName) {
					this.userFiltersService.create({
						name: newFilterName,
						filterExpression: JSON.stringify({
							columnsConfig: this.gridSettings.columnsConfig,
							state: this.gridSettings.state
						}),
						isDefault: this.userFilters.length == 0,
						userFilterTypeId: <number>UserFilterType.Activities,
						userFilterId: null,
						userId: null
					}).subscribe(x => {
						this.loadUserFilters(x.data);
					})
				}
			})
	}

	userFilterSettings() {
		this.modalService.open(this.userFiltersModal, { windowClass: 'modal-top modal-sm' }).result.then((result) => {

		}, (reason) => {

		});
	}

	setUserFilterAsDefault(userFilter: UserFilter) {

		this.switchOnInProgressFlag(this.setDefaultFiltersIsInProgress, userFilter.userFilterId);

		this.userFiltersService.setDefaultUserFilter(userFilter.userFilterId).subscribe(x => {
			this.switchOffInProgressFlag(this.setDefaultFiltersIsInProgress, userFilter.userFilterId);
			this.loadUserFilters();
		});
	}

	removeUserFilter(userFilter: UserFilter) {

		this.switchOnInProgressFlag(this.removeFiltersIsInProgress, userFilter.userFilterId);

		this.userFiltersService.remove(`${userFilter.userFilterId}`, "Пользовательские фильтры", "Фильтр успешно удален").subscribe(x => {
			this.loadUserFilters();

			this.switchOffInProgressFlag(this.removeFiltersIsInProgress, userFilter.userFilterId);
		});
	}

	switchOnInProgressFlag(items: number[], id: number) {
		items.push(id);
	}

	switchMap(): void {
		if (!this.isMap && this.listViewModel.total > 1000) {
			this.notificationService.confirmation({
				title: 'Возможное снижение производительности',
				message: 'Выбрано большое 1000 заявок. Желаете продолжить?',
				type: 'question',
				confirmButtonText: 'Да',
				cancelButtonText: 'Нет',
				showCloseButton: true
			},
				() => {
					this.isMap = !this.isMap;
				},
				() => {
					this.isMapSwitcherOn = false;
				});
		} else {
			this.isMap = !this.isMap;
		}
	}

	switchOffInProgressFlag(items: number[], id: number) {
		const index = items.indexOf(id, 0);

		if (index > -1) {
			items.splice(index, 1);
		}
	}

	onScrollDown() {
		this.loading = true;
		this.gridSettings.state.skip += this.gridSettings.state.take;
		this.dataService.list(this.gridSettings.state).subscribe(lvm => {
			this.listViewModel.data = [...this.listViewModel.data, ...lvm.data];
			this.groupedData = this.groupedByKey;
			this.loading = false;
		});
	}

	public get isEngineer() {
		return this.securityService.isEngineer();
	}

	public get isMobileEngneer() {
		return this.isMobileDevice && this.isEngineer;
	}

	public changeSelectionTab(tab, requestStatus) {
		this.selectedTab = tab;
		this.selectedRequestStatus = requestStatus;
		this.selectedDate = 'today';
		this.filterLoad();
	}

	public isSelectedTab(tab: number) {
		return this.selectedTab === tab;
	}

	public selectDateFilter(type) {
		if (this.selectedDate === type) {
			this.selectedDate = 'all';
		} else {
			this.selectedDate = type;
		}
		this.filterLoad();
	}

	public selectDateOrder(type) {
		if (this.selectedOrder === type) {
			this.selectedOrder = null;
		} else {
			this.selectedOrder = type;
		}
		this.filterLoad();
	}

	private filterLoad() {
		this.gridSettings.state.skip = 0;
		this.gridSettings.state.filter.filters = [this.getStatusKindFilter(this.selectedDate)];
		this.gridSettings.state.sort = this.getSort()

		this.loadData();
		this.checkTomorrowRequests();
	}

	private checkTomorrowRequests() {

		if (this.selectedTab === 1 && this.isMobileDevice) {
			this.requestsService.slaDates().subscribe(lvm => {
				// for test
				// lvm = ["05.06", "06.06", "07.06", "08.06", "09.06", "10.06", "11.06", "12.06", "13.06"]
				var tommorow = new Date(new Date().setHours(24));
				var day = tommorow.getDate();
				var d = day >= 10 ? day.toString() : "0" + day.toString();
				var month = tommorow.getMonth() + 1;
				var m = month >= 10 ? month.toString() : "0" + month.toString();
				this.showTomorrow = lvm.includes(`${d}.${m}-${tommorow.getFullYear()}`);
				this.showDays = lvm.filter(p => p !== `${d}.${m}-${tommorow.getFullYear()}`).map(p => p.split('-')[0]);
			});
		}
	}

	private getSort() {
		const result = [];
		if (this.selectedOrder === 'client') {
			result.push({ field: 'customerContragentId', dir: 'asc' })
		} else if (this.selectedOrder === 'type') {
			result.push({ field: 'requestTypeId', dir: 'asc' })
		} else if (this.selectedOrder === 'address') {
			result.push({ field: 'tspAddress', dir: 'asc' })
		} else {
			result.push({ field: 'requestIdStr', dir: 'desc' });
		}

		return result;
	}

	private getStatusKindFilter(selectedDate: string): CompositeFilterDescriptor {
		const filter: CompositeFilterDescriptor = { logic: 'and', filters: [{ field: 'statusId', operator: 'eq', value: this.selectedRequestStatus }] }
		if (selectedDate === 'today') {
			const today = new Date().toJSON().split('T')
			filter.filters.push({
				logic: 'and', filters: [
					{ field: 'slaDueDate', operator: 'gte~datetime', value: `${today[0]}T00-00-0` },
					{ field: 'slaDueDate', operator: 'lte~datetime', value: `${today[0]}T00-00-0` }
				]
			})
		} else if (selectedDate === 'tomorrow') {
			const today = new Date(new Date().setHours(24)).toJSON().split('T');
			filter.filters.push({
				logic: 'and', filters: [
					{ field: 'slaDueDate', operator: 'gte~datetime', value: `${today[0]}T00-00-0` },
					{ field: 'slaDueDate', operator: 'lte~datetime', value: `${today[0]}T00-00-0` }
				]
			})
		} else if (selectedDate !== 'all') {
			const today = new Date();
			const s = selectedDate.split('.');
			filter.filters.push({
				logic: 'and', filters: [
					{ field: 'slaDueDate', operator: 'gte~datetime', value: `${today.getFullYear()}-${this.getDate(s[1])}-${this.getDate(s[0])}T00-00-0` },
					{ field: 'slaDueDate', operator: 'lte~datetime', value: `${today.getFullYear()}-${this.getDate(s[1])}-${this.getDate(s[0])}T00-00-0` }
				]
			})
		}
		return filter;
	}

	private getDate(s) {
		return s.length < 2 ? "0" + s : s;
	}

	trackByFunction(index, item: ColumnSettings) {

		if (!item)
			return null;

		return item.field;

	}

	public close(status) {
		console.log(`Dialog result: ${status}`);
		this.openedSearchModal = false;
		this.searchRequests = [];
		this.searchRequestsSize = 0;
	}

	public open() {
		this.openedSearchModal = true;
	}

	public get searchRequestsSizeText() {
		return `Поиск. (${this.searchRequestsSize}) записей.`
	}

	public onsearch(event) {
		const value = event.target.value;
		if (value === undefined || value === null || value === '') {
			this.searchRequests = [];
			this.searchRequestsSize = 0;
			return;
		}

		if (this.delaySearch) {
			clearTimeout(this.delaySearch);
		}

		this.delaySearch = setTimeout(() => {
			this.loading = true;
			this.searchRequests = [];
			const searchFilters: CompositeFilterDescriptor = {
				logic: 'or', filters: [
					{ field: 'requestIdStr', operator: 'eq', value: event.target.value },
					{ field: 'customerContragentName', operator: 'contains', value: event.target.value },
					{ field: 'description', operator: 'contains', value: event.target.value },
					{ field: 'externalId', operator: 'contains', value: event.target.value },
					{ field: 'tspAddress', operator: 'contains', value: event.target.value },
					{ field: 'tidNumbers', operator: 'contains', value: event.target.value },
				]
			};

			// if (this.isMobileDevice) {
			// 	const statusFilter = this.getStatusKindFilter(this.selectedDate);
			// 	statusFilter.filters.push(searchFilters);
			// }

			const searchSetting = this.gridSettings;
			searchSetting.state.filter = searchFilters;
			searchSetting.state.skip = 0;
			searchSetting.state.take = 1000;

			this.activityListSubscription = this.dataService.activityList(this.gridSettings.state, this.mobileDeviceVisibleColumnNames).subscribe(lvm => {
				this.searchRequests = lvm.data;
				this.searchRequestsSize = lvm.total;
				this.loading = false;
			});
		}, 800);
	}

	public toggleAccordion(any) {
		if (this.toggledAccordions[any.index] !== undefined && this.toggledAccordions[any.index] !== null) {
			this.toggledAccordions[any.index] = any.active;
		} else {
			this.toggledAccordions.set(any.index, any.active);
		}
	}

	public isActiveAccordion(index: number) {
		return this.alwaysToggled || this.toggledAccordions[index];
	}

	public alwaysToggledChange() {
		this.alwaysToggled = !this.alwaysToggled;
		localStorage.setItem('alwaysToggled', this.alwaysToggled.toString());
	}

	get hasAddRequestClaim(): boolean {
		return this.securityService.hasClaim('cat-requests-add');
	}

	public onVisibilityChange(event: ColumnVisibilityChangeEvent): void {
		if (!this.enableSaveGridSettings) {
			return;
		}

		this.actualizeHiddenColumnsFilteringAndSorting(event.columns);
		super.onVisibilityChange(event);
		this.loadData();
	}

	getDateString(dataItem: ActivityListItem, column: ColumnSettings): string {
		var localDateFieldName = column.field.replace("Moscow", "Local");
		var localDate = dataItem[localDateFieldName];

		if (localDate == null)
			return "";

		var formattedDate = this.intl.formatDate(this.intl.parseDate(localDate), column.format);

		return `${formattedDate} ${dataItem["requestUtcTimezoneShiftString"]}`;
	}
}
