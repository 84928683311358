import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {RequestEmail} from "../../../shared/models/request/request-email";
import {RequestEmailsService} from "../../../shared/services/request-emails.service";
import {FileUtil} from '../../../shared/utils';
import {saveAs} from 'file-saver';
import {NotificationService} from "../../../core/services/notification.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {EmailSourceType} from "../../../shared/enums/email-source-type";
import { AttachmentsService } from "../../../shared/services/attachments.service";
import { SecurityService } from "../../../core/services/security.service";

@Component({
	selector: 'request-email',
	templateUrl: './request-email.component.html',
	styleUrls: [
		'./request-email.component.scss',
		'../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	],
})
export class RequestEmailComponent implements OnInit, AfterViewChecked {

	@ViewChild('emailSection') private emailSection: ElementRef;

	public loading: boolean = false;
	public showResendForm: boolean = false;
	public recepients: string;
	public requestId: number;
	public email: RequestEmail;
	public safeBody: SafeHtml;

	constructor(
		private requestEmailsService: RequestEmailsService,
		private attachmentsService: AttachmentsService,
		private notificationService: NotificationService,
		private sanitizer: DomSanitizer,
		private securityService: SecurityService,
	) {

	}

	ngOnInit(): void {
		this.safeBody = this.sanitizer.bypassSecurityTrustHtml(this.email.body) as any;
	}

	ngAfterViewChecked() {
		if (this.showResendForm) {
			this.emailSection.nativeElement.scrollTop = this.emailSection.nativeElement.scrollHeight;
		}
	}

	public downloadEmail(): void {
		this.loading = true;
		this.requestEmailsService.download(this.email)
			.subscribe(result => {
				if (result.isSuccessful) {
					const bytes = FileUtil.base64ToArrayBuffer(result.data.file);
					const blob = new Blob([bytes], { type: result.data.type });
					saveAs(blob, result.data.fileName);

					if (result.data.errorAttachments.length > 0){
						this.notificationService.error({
							title: 'Вложения не найдены',
							message: `Вложения: ${result.data.errorAttachments.join(', ')} не найдены`,
							confirmButtonText: 'Ok'
						});
					}
				}

				this.loading = false;
			});
	}

	public forwardEmail(): void {
		this.loading = true;
		const recepients = this.recepients.split(' ').map(x => x.trim())
		this.requestEmailsService.forward(this.requestId, this.email.subject, this.email.body, recepients, this.email.attachments)
			.subscribe(result => {
				if (result.isSuccessful){
					this.notificationService.success({
						title: 'Сообщение отправлено',
						message: 'Сообщение отправлено успешно',
					});
				}

				this.loading = false;
			});
	}

	public showHideResendForm(): void {
		this.showResendForm = !this.showResendForm;
		if (!this.showResendForm){
			this.recepients = null;
		}
	}

	public isOutgoingEmail(): boolean {
		return this.email.emailType === EmailSourceType.Rune;
	}

	get isResendVisible(): boolean {
		return this.securityService.isSpecialRightClaimValid("ResendingNotifications")
			|| this.securityService.isAdmin();
	}

	public recepientsContainSender(): boolean {
		const recepients = this.recepients.split(' ').map(x => x.trim());
		return recepients.some(recepient => recepient === this.email.sender);
	}

	public downloadAttachment(attachmentPath: string): void {
		this.loading = true;
		this.attachmentsService.download(attachmentPath)
			.subscribe(result => {
				if (result.isSuccessful) {
					const fileName = this.getAttachmentFileName(attachmentPath);
					const fileExt = fileName.split('.')[1];

					const bytes = FileUtil.base64ToArrayBuffer(result.data);
					const blob = new Blob([bytes], { type: fileExt });
					saveAs(blob, fileName);
				}

				this.loading = false;
			}, error => this.loading = false);
	}

	public getAttachmentFileName(attachmentPath: string): string {
		return attachmentPath.replace(/^.*[\\\/]/, '');
	}
}
