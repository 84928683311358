import { Component, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../users.service';
import { User } from '../../../shared/models/User/User';
import { AppService } from '../../../app.service';
import { ListPageBase } from '../../../shared/components/list-page-base/list-page-base';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StatePersistingService } from '../../../shared/services/state-persisting.service';
import { GridSettings } from '../../../shared/models/grid/grid-settings';
import { LookupService } from '../../../shared/services/lookup.service';
import { ContragentType } from '../../../shared/enums';
import { KeyValueObject } from '../../../shared/models/core/KeyValueObject';
import {UserList} from '../../../shared/models/User';
import { ColumnVisibilityChangeEvent } from '@progress/kendo-angular-grid';
import { formatDate } from '@telerik/kendo-intl';
import { ExportExcelFromGridService } from '../../../shared/services/export-excel-from-grid.service';

@Component({
	selector: 'admin-roles',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss']
})
export class UsersListPage extends ListPageBase<UserList> implements AfterViewInit {

	@ViewChild('filterAllInput') filterAllInput: ElementRef;

	userGroups: KeyValueObject[] = [];
	serviceCenters: KeyValueObject[] = [];
	bankEquerContragents: KeyValueObject[] = [];
	public roles: KeyValueObject[] = [];
	constructor(
		appService: AppService,
		router: Router,
		protected dataService: UsersService,
		private lookupService: LookupService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService) {
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Пользователи';
		this.ignoreMoscowToLocalfixTimeZoneShift = true;
	}

	public enableSaveGridSettings: boolean = true;

	gridSettingsStorageKey: string = 'users-grid-settings';

	public gridColumnOffset: number = 0;

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDateMoscow', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		},
		columnsConfig: [
			{
				orderIndex: 0,
				field: 'surname',
				title: 'Фамилия',
				filter: 'string',
				filterable: true,
				width: 180,
				hidden: false
			},
			{
				orderIndex: 1,
				field: 'name',
				title: 'Имя',
				filter: 'string',
				filterable: true,
				width: 180,
				hidden: false
			},
			{
				orderIndex: 2,
				field: 'middleName',
				title: 'Отчество',
				filter: 'string',
				filterable: true,
				width: 180,
				hidden: false
			},
			{
				orderIndex: 3,
				field: 'contragentName',
				title: 'Контрагент',
				filter: 'lookup',
				filterable: true,
				width: 250,
				hidden: false,
				filterField: 'contragentId'
			},
			{
				orderIndex: 4,
				field: 'performerUserGroupNames',
				title: 'Группы ответственного',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 5,
				field: 'userServiceCenterNames',
				title: 'Исполнитель (СЦ)',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 6,
				field: 'email',
				title: 'E-mail',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 7,
				field: 'login',
				title: 'Логин',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 8,
				field: 'roleNames',
				title: 'Роли',
				filter: 'string',
				filterable: true,
				width: 250,
				hidden: false
			},
			{
				orderIndex: 9,
				field: 'isBlocked',
				title: 'Заблокирован',
				filter: 'boolean',
				filterable: true,
				width: 180,
				hidden: false
			},
			{
				orderIndex: 10,
				field: 'createdDateMoscow',
				title: 'Дата создания',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false,
				format: 'dd.MM.yyyy HH:mm'
			},
			{
				orderIndex: 11,
				field: 'lastLoginMoscow',
				title: 'Дата последнего входа',
				filter: 'date',
				filterable: true,
				width: 200,
				hidden: false,
				format: 'dd.MM.yyyy HH:mm'
		}]
	}

	onListPageInit(): void {
		this.lookupService.getData('user-groups', null).subscribe(data => this.userGroups = data);
		this.lookupService.getData('roles', null).subscribe(data => this.roles = data);

		const bankEquerContragentsState: DataSourceRequestState = {
			filter: { logic: 'or', filters: [
				{ field: 'ContragentTypeId', operator: 'eq', value: ContragentType.serviceCenter },
				{ field: 'ContragentTypeId', operator: 'eq', value: ContragentType.selfCompany }
			] }
		};
		this.lookupService.getData('contragents', bankEquerContragentsState).subscribe(data => this.bankEquerContragents = data);
		this.lookupService.getData('service-centers', null).subscribe(data => this.serviceCenters = data);
	}

	ngAfterViewInit(): void {
		fromEvent(this.filterAllInput.nativeElement, 'keyup')
			.pipe(
				map((evt: any) => evt.target.value),
				debounceTime(500),
				distinctUntilChanged(),
			).subscribe((text: string) => this.onFilter(text));

		
	}

	processListItems(items: UserList[]): void {
		items.map((value, _) => {
			if (value.performerDefaultUserGroupName) {
				value.allPerformerUserGroupNames = value.performerDefaultUserGroupName.trim();
				if (value.performerUserGroupNames) {
					value.allPerformerUserGroupNames = value.allPerformerUserGroupNames + ', ' + value.performerUserGroupNames;
				}
			} else {
				value.allPerformerUserGroupNames = value.performerUserGroupNames;
			}

			if (value.defaultServiceCenterName) {
				value.allServiceCenterNames = value.defaultServiceCenterName.trim();
				if (value.userServiceCenterNames) {
					value.allServiceCenterNames = value.allServiceCenterNames + ', ' + value.userServiceCenterNames;
				}
			} else {
				value.allServiceCenterNames = value.userServiceCenterNames;
			}

			value.isBlockedStr = value.isBlocked ? 'ДА' : 'нет';
			value.createdDateStr = formatDate(new Date(value.createdDateMoscow), 'dd.MM.y HH:mm');
			if (value.lastLoginMoscow) {
				value.lastLoginStr = formatDate(new Date(value.lastLoginMoscow), 'dd.MM.y HH:mm');
			}
		});
	}

	onSelectedContragent(ids: number[]): void {
		let state: DataSourceRequestState = null;
		if (ids && ids.length > 0) {
			state = {
				filter: {
					logic: 'or',
					filters: ids.map(contragentId => {
						return { field: 'ContragentId', operator: 'eq', value: contragentId };
					})
				}
			};
		}

		this.lookupService.getData('user-groups', state).subscribe(data => this.userGroups = data);
	}

	public cellClick({ dataItem }: any): void {
		this.router.navigate(['/admin/user', dataItem.userId]);
	}

	public onFilter(inputValue: string): void {

		var customState: DataSourceRequestState = {
			skip: 0,
			take: this.gridSettings.state.take,
			sort: this.gridSettings.state.sort,
			filter: {
				logic: 'or',
				filters: [
					{
						field: 'surname',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'name',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'middleName',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'email',
						operator: 'contains',
						value: inputValue
					},
					{
						field: 'login',
						operator: 'contains',
						value: inputValue
					}
				],
			}
		};

		this.loadData(customState);
	}

	clearFilters(): void {
		this.gridSettings.state = {
			skip: 0,
			take: 100,
			sort: [{ field: 'createdDateMoscow', dir: 'desc' }],
			filter: { logic: 'and', filters: [] }
		};

		if (this.enableSaveGridSettings) {
			this.saveGridSettings();
		}

		this.appService.clearAllGridFilters.next();

		this.onGridStateChange();

		this.loadData();
	}

	public onVisibilityChange(event: ColumnVisibilityChangeEvent): void {
		if (!this.enableSaveGridSettings) {
			return;
		}
		this.actualizeHiddenColumnsFilteringAndSorting(event.columns);
		super.onVisibilityChange(event);
		this.loadData();
	}

	public dateCells = {
		format: 'dd.mm.yyyy hh:mm'
	}
}
