import { BaseObject } from '../core/BaseObject';

export class DeviceItem extends BaseObject {
    deviceId: number;

    deviceTypeId: number;
    deviceTypeName: string;

    deviceManufacturerId: number;
    deviceManufacturerName: string;

    deviceModelId: number;
    deviceModelName: string;

    partNumber: string;
    serialNumber: string;

    deviceConnectionTypeIds: number[];
    deviceConnectionTypeNames: string[];

    deviceConfigurationId: number;
    processingTypeId: number;
    processingTypeName: string;

    ctls: boolean;

    deviceVersionId: number;
    deviceVersionName: string;

    ownerContrAgentId: number;
    ownerContrAgentName: string;

    deviceStatusId: number;
    deviceStatusName: string;

    deviceConditionId: number;
    deviceConditionName: string;

    serviceCenterId: number;
    serviceCenterName: string;

    name: string;

    nomenclature: string;

    lastTid: string;
    lastRequestId: number;
    lastEvent: string;
}
