import { Component, ViewEncapsulation } from "@angular/core";
import { ListPageBase } from "../../../shared/components/list-page-base/list-page-base";
import { AppService } from "../../../app.service";
import { Router } from "@angular/router";
import { StatePersistingService } from "../../../shared/services/state-persisting.service";
import { ExportExcelFromGridService } from "../../../shared/services/export-excel-from-grid.service";
import { LookupService } from "../../../shared/services/lookup.service";
import { StoppingReasonTypeRuleListItem } from "../../../shared/models/stopping-reason-type-rules/stopping-reason-type-rule-list-item";
import { StoppingReasonTypeRulesService } from "../../../shared/services/stopping-reason-type-rules.service";
import { ChangeEnableStoppingReasonTypeRuleModel } from "../../../shared/models/stopping-reason-type-rules/change-enable-stopping-reason-type-rule.model";

@Component({
	selector: 'stopping-reason-type-rules-list',
	templateUrl: './list.page.html',
	styleUrls: ['./list.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class StoppingReasonTypeRulesListPage extends ListPageBase<StoppingReasonTypeRuleListItem> {

	constructor(
		appService: AppService,
		router: Router,
		protected lookupService: LookupService,
		protected dataService: StoppingReasonTypeRulesService,
		protected persistingService: StatePersistingService,
		protected exportExcelFromGridService: ExportExcelFromGridService)
	{
		super(router, appService, dataService, persistingService, exportExcelFromGridService);
		appService.pageTitle = 'Правила для типов остановки';
	}

    onListPageInit(): void {
        this.gridSettings.state.sort = [];
		this.setGridSettings(this.gridSettings);
    }

    processListItems(items: StoppingReasonTypeRuleListItem[]): void 
    { }

    cellClick({ dataItem, columnIndex }: any): void {
		if (columnIndex > 1)
			return;

		this.router.navigate(['/categories/stopping-reason-type-rule', dataItem.stoppingReasonTypeRuleId]);
	}

	copyClick(stoppingReasonTypeRuleId: number): void {
		this.router.navigate(['/categories/stopping-reason-type-rule/copy', stoppingReasonTypeRuleId]);
	}

	createNewRule(): StoppingReasonTypeRuleListItem {
		var rule = new StoppingReasonTypeRuleListItem();
		return rule;
	}

	saveHandler({ sender, dataItem, rowIndex }): void {
        let model = new ChangeEnableStoppingReasonTypeRuleModel;
        model.stoppingReasonTypeRuleId = dataItem.stoppingReasonTypeRuleId;
        model.description = dataItem.description;
        model.isEnabled = dataItem.isEnabled;

        this.dataService.changeEnable(model).subscribe(() => {
            this.loadData();
            sender.closeRow(rowIndex);
        })
	}

    removeHandler({ dataItem }): void {
        this.dataService.remove(dataItem.stoppingReasonTypeRuleId, null, 'Правило удалено').subscribe(() => {
            this.loadData();
        });
    }
}