import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../core/services/data.service';
import { NotificationService } from '../../core/services/notification.service';
import { RequestListItem } from '../models/request/request-list-item';
import { NewRequest } from '../models/request/new-request/new-request';
import { Observable } from 'rxjs/internal/Observable';
import { ActionDataResult } from '../models/core/ActionDataResult';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { NotificationType } from '../../core/services/notification-type';
import { NewRequestDevice } from '../models/request/new-request/new-request-device';
import { ActionResult } from '../models/core/ActionResult';

@Injectable()
export class NewRequestsService extends DataService<RequestListItem, NewRequest> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'requests';
	}

	public createLinkedWarehouseTransferRequest(parentRequestId: number): Observable<ActionResult> {
		const url = `${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/create-linked-warehouse-transfer-request`;
		return this.postQuery(url, { parentRequestId: parentRequestId }, null)
			.pipe(tap(resp => {

				if (!resp.isSuccessful) {
					this.notificationService.error({
						title: 'Ошибка создания связанных перемещений',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка создания связанных перемещений',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});
			}));
	}
}