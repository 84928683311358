export class RequestRegistrationFilterElementValueDto {
    constructor(
        requestRegistrationFilterElementId: number,
        value: string){
        this.requestRegistrationFilterElementId = requestRegistrationFilterElementId;
        this.value = value;
    }
    requestRegistrationFilterElementValueId: number;
    requestRegistrationFilterElementId: number;
    value: string;
}