import { CommentKindEnum } from "../../enums/comment-kind.enum";

export class ChangeStatusModel {
    requestId: number;
    newStatusId: number;
    forceChangeStatus: boolean = false;
    sendClientLastComment: boolean = false;
    openPublicationStateChecked: boolean = false;
    performerGroupId?: number;
    performerUserId?: number;
    stoppedUntil?: Date;
    comment?: string;
    commentKind: CommentKindEnum;
    transitionId?: number;
    requestStoppingReasonId?: number;

    constructor(requestId: number
        , newStatusId: number
        , performerGroupId?: number
        , performerUserId?: number
        , stoppedUntil?: Date
        , comment?: string
        , forceChangeStatus: boolean = false
        , sendClientLastComment: boolean = false
        , commentKind: CommentKindEnum = CommentKindEnum.internal
        , transitionId?: number
        , requestStoppingReasonId?: number) {
        this.requestId = requestId;
        this.newStatusId = newStatusId;
        this.forceChangeStatus = forceChangeStatus;
        this.sendClientLastComment = sendClientLastComment;
        this.performerGroupId = performerGroupId;
        this.performerUserId = performerUserId;
        this.stoppedUntil = stoppedUntil;
        this.comment = comment;
        this.commentKind = commentKind;
        this.transitionId = transitionId;
        this.requestStoppingReasonId = requestStoppingReasonId;
    }
}