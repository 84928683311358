import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[noWhitespaceAtStart]'
})
export class NoWhitespaceAtStartDirective {
    private _noWhitespaceAtStart: boolean = true;

    @Input()
    get noWhitespaceAtStart(): boolean {
        return this._noWhitespaceAtStart;
    }
    set noWhitespaceAtStart(value: boolean) {
        this._noWhitespaceAtStart = "" + value !== "false";
    }

    allowedTags: string[] = ['INPUT', 'TEXTAREA'];

    constructor(private _elementRef: ElementRef, private _ctrl: NgControl) { }

    @HostListener('input')
    public onInput(): void {
        if (this.noWhitespaceAtStart && this.allowedTags.includes(this._elementRef.nativeElement.tagName) && this._ctrl.control.value[0] == ' ') {
            this._ctrl.control.setValue(this.removeFirstSpace(this._ctrl.control.value));
        }
    }

    private removeFirstSpace(s: string): string {
        if (s[0] == ' ')
            s = this.removeFirstSpace(s.substring(1));
        return s;
    }
}