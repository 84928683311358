import { SnWithServiceTypeModel } from "./sn-with-service-type.model";

export class ArticleMaskSerialNumbersModel {
    articleMask: string;
    serialNumbers: SnWithServiceTypeModel[] = [];
    isPart: boolean;

    constructor(articleMask: string
        , serialNumbers: SnWithServiceTypeModel[]
        , isPart: boolean) {
        this.articleMask = articleMask;
        this.serialNumbers = serialNumbers;
        this.isPart = isPart;
    }
}