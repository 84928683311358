import { RequestPersonalDataRegion } from "./request-personal-data-region";

export class RequestPersonalData {
	surname: string;
	name: string;
	patronymic: string;
	phone: bigint;
	email: string;
	telegramId: string;
	passport: string;
	inn: string;
	taxRegistrationCertNumber: string;
	registrationDate: Date = null;
	deRegistrationDate: Date= null;
	passportDate: Date = null;
	telegramName: string;
	deRegistrationReason: string;
	typesOfActivity: string[];
	oktmoRegion: string;
	bik: bigint;
	accountNumber: bigint;
	lastUpdateDate: Date;
	nationalityCountryCode: string;
	regionServiceCenter: RequestPersonalDataRegion[];
	regionServiceCenterString: string;
}
