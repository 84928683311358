import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnInit, Output, ViewChild } from "@angular/core";
import { KeyValueObject } from "../../../models/core/KeyValueObject";
import { LookupService } from "../../../services/lookup.service";
import { HierarchyLookupModel } from "../../../models/core/HierarchyLookupModel";
import { DeliveryEquipment } from "./delivery-equipment.model";
import { For1CService } from "../../../services/for-1c.service";
import { GetNomenclaturesQuery } from "../../../models/for-1c/get-nomenclatures/get-nomenclatures-query";
import { RequestDevicesService } from "../../../services/request-devices.service";
import { DeliveryDeviceConditions } from "./delivery-device-conditions.enum";
import { Guid } from "guid-typescript";
import { NotificationService } from "../../../../core/services/notification.service";
import { NotificationType } from "../../../../core/services/notification-type";
import { SerialNumbersDevice } from "../../../models/device/serial-numbers-1C/serial-numbers-device";
import { DataSourceRequestState } from "@progress/kendo-data-query";
import { DeviceKindEnum } from "../../../enums/device-kind.enum";
import { RequestsService } from "../../../services/requests.service";
import { NewRequestDevice } from "../../../models/request/new-request/new-request-device";
import { AppService } from "../../../../app.service";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { ReserveResult } from "../../../enums/reserve-result.enum";
import { forkJoin } from "rxjs";

@Component({
	selector: 'delivery-of-equipment',
	templateUrl: './delivery-of-equipment.html',
	styleUrls: ['./delivery-of-equipment.scss',
		'../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class DeliveryOfEquipment implements OnInit, AfterViewInit {

	@ViewChild('creatingSerialNumberInput') creatingSerialNumberInput: ElementRef;
	creatingSerialNumberInputIsActive: boolean = false;

	@Input() requestId: number;
	@Input() activityId: number;
	//Хак, форма не исчезала на мобильной версии при смене ngSwitchCase
	@Input() showForm: boolean = true;

	@Input() defaultScWarehouseName: string;
	@Input() defaultScWarehouseId: number;

	@Output() onContinueEmitter = new EventEmitter();

	reservedEquipments: DeliveryEquipment[] = [];
	deliveryEquipments: DeliveryEquipment[] = [];
	requestDevices: NewRequestDevice[] = [];
	allRequestDevices: NewRequestDevice[] = [];
	cancellatedDevices: NewRequestDevice[] = [];

	requestDeviceIdsToRemove: number[] = [];

	startReservedEquipmentsCount: number;
	startDeliveryEquipmentsCount: number;

	deviceTypes: KeyValueObject[] = [];
	deviceSuppliers: KeyValueObject[] = [];
	deviceModels: HierarchyLookupModel[] = [];
	deviceNomenclatures: KeyValueObject[] = [];
	deviceConditions: KeyValueObject[] = [];
	deviceConnectionTypes: KeyValueObject[] = [];

	creatingSerialNumber: string = null;
	creatingEquipment: DeliveryEquipment = new DeliveryEquipment;

	loadNomenclatures: boolean = false;
	formConfirmingInProgress: boolean = false;
	mobileFormConfirmingInProgress: boolean = false;
	creatingEquipmentInProgress: boolean = false;
	creatingEquipmentBySnInProgress: boolean = false;


	constructor(
		private requestsService: RequestsService,
		private requestDevicesService: RequestDevicesService,
		private for1CService: For1CService,
		private lookupService: LookupService,
		private appService: AppService,
		private notificationService: NotificationService,
		private dialog: DialogRef,
		private ngZone: NgZone
	) { }

	ngOnInit() {
		this.initLookups();
		this.initRequestDevices();
	}

	ngAfterViewInit() {
		this.clearCreatingSerialNumber();
	}

	private initLookups() {
		var deviceTypesState: DataSourceRequestState = {
			skip: 0,
			take: null,
			filter: {
				logic: "and",
				filters: [{ field: "deviceKindId", operator: "eq", value: DeviceKindEnum.component }]
			}
		};

		this.lookupService.getData('device-types', deviceTypesState).subscribe(data => this.deviceTypes = data);
		this.lookupService.getHierarchyData('device-models', null).subscribe(data => {
			this.deviceModels = data;
			this.deviceSuppliers = this.deviceModels.filter(x => x.parentId == null).map(x => new KeyValueObject(x.id, x.name));
		});

		this.deviceConditions = [
			new KeyValueObject(DeliveryDeviceConditions.default, 'Возврат'),
			new KeyValueObject(DeliveryDeviceConditions.broken, 'Неисправно')
		];

		if (this.isMobileDevice)
			this.lookupService.getData("device-connection-types", null).subscribe(data =>
				this.deviceConnectionTypes = data);
	}

	private initRequestDevices() {
		if (!this.isMobileDevice)
			this.requestsService.getRequestDevices(this.requestId).subscribe(data => {
				this.allRequestDevices = data;
				this.reservedEquipments = data
					.filter(device => device.reserveResultId == ReserveResult.booked || device.reserveResultId == ReserveResult.pendingIssueToEngineer)
					.map(device => this.mapNewRequestDeviceToEquipment(device));
				this.deliveryEquipments = data.filter(device => device.reserveResultId === ReserveResult.receivingEngineer).map(device =>
					this.mapNewRequestDeviceToEquipment(device));
				this.startReservedEquipmentsCount = this.reservedEquipments.length;
				this.startDeliveryEquipmentsCount = this.deliveryEquipments.length;
			});
		else {
			this.requestsService.getRequestDevices(this.requestId).subscribe(data => {
				this.allRequestDevices = data;
				this.requestDevices = data.filter(device => device.reserveResultId === ReserveResult.receivingEngineer);
				this.cancellatedDevices = data.filter(device =>
					device.reserveSuccess === true &&
					(device.reserveResultId == ReserveResult.broken ||
						device.reserveResultId == ReserveResult.booked ||
						device.reserveResultId == ReserveResult.pendingIssueToEngineer));
			});
		}
	}

	addComponentEquipment() {
		if (this.hasDeviceFromDifferentWarehouse()){
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Невозможно добавить оборудования, так как СЦ зарезервированного оборудования отличается от текущего. Можно выдать только зарезервированное ранее оборудование',
				notificationType: NotificationType.SweetAlert
			});
			return;
		}
		
		this.creatingEquipmentInProgress = true;

		var getFreeNomenclaturesQuery = new NewRequestDevice;
		getFreeNomenclaturesQuery.requestId = this.requestId;
		getFreeNomenclaturesQuery.requestDeviceId = 0;
		getFreeNomenclaturesQuery.nomenclature = this.creatingEquipment.nomenclature;
		getFreeNomenclaturesQuery.deviceTypeId = this.creatingEquipment.deviceTypeId;
		getFreeNomenclaturesQuery.deviceModelId = this.creatingEquipment.deviceModelId;
		getFreeNomenclaturesQuery.deviceSupplierId = this.creatingEquipment.deviceSupplierId;
		getFreeNomenclaturesQuery.deviceConnectionTypeId = null;
		getFreeNomenclaturesQuery.serialNumber = null;

		this.requestDevicesService.getFreeNomenclatures(getFreeNomenclaturesQuery).subscribe(data => {
			if (data.errorDescription) {
				this.showErrorToast(data.errorDescription);
				return;
			}

			var nomenclatureIsAvailable = data.availableNomenclatures.some(x =>
				x.nomenclatures.some(n => n.nomenclature === this.creatingEquipment.nomenclature));

			if (nomenclatureIsAvailable) {
				var nomenclatureAvailable = data.availableNomenclatures.find(x =>
					x.nomenclatures.some(n => n.nomenclature === this.creatingEquipment.nomenclature));
				var deviceTypeId = nomenclatureAvailable.nomenclatures.find(n =>
					n.nomenclature === this.creatingEquipment.nomenclature).deviceType;
				var warehouseAreaName = nomenclatureAvailable.warehouseAreaName;
				var deviceType = this.deviceTypes.find(x => x.id == deviceTypeId);

				this.creatingEquipment.number = Guid.create();
				this.creatingEquipment.isComponent = true;
				this.creatingEquipment.deviceConditionId = DeliveryDeviceConditions.default;
				this.creatingEquipment.deviceTypeId = deviceTypeId;
				this.creatingEquipment.deviceTypeName = deviceType.name;
				this.creatingEquipment.warehouseMoveFromName = warehouseAreaName;
				this.deliveryEquipments.push(this.creatingEquipment);
				this.creatingEquipment = new DeliveryEquipment;
				this.deviceNomenclatures = [];
			}
			else
				this.showErrorToast("Данная номенклатура недоступна к выдаче");

			this.creatingEquipmentInProgress = false;
		});
	}

	addSerialEquipment() {
		var existingReservedEquipment = this.reservedEquipments.find(x => x.serialNumber == this.creatingSerialNumber);
		var existingDeliveryEquipment = this.deliveryEquipments.find(x => x.serialNumber == this.creatingSerialNumber);

		if (existingReservedEquipment) {
			this.moveToDelivery(existingReservedEquipment);
			this.clearCreatingSerialNumber();
		} else if (existingDeliveryEquipment) {
			this.notificationService.info({
				title: "Ошибка",
				message: "Указанный серийный номер есть в 'Оборудовании к выдаче'",
				notificationType: NotificationType.Toast
			});
		} else if (this.hasDeviceFromDifferentWarehouse()){
			this.notificationService.error({
				title: 'Ошибка',
				message: 'Невозможно добавить оборудования, так как СЦ зарезервированного оборудования отличается от текущего. Можно выдать только зарезервированное ранее оборудование',
				notificationType: NotificationType.SweetAlert
			});
		}
		else {
			this.creatingEquipmentBySnInProgress = true;

			this.requestDevicesService.requestDevicesSerialNumbersFrom1C(this.requestId, this.activityId).subscribe(response => {
				if (!response.isSuccessful) {
					this.showErrorToast(response.errorDescription);
					this.creatingEquipmentBySnInProgress = false;
					return;
				}

				var deviceFrom1C = response.data.devices.find(x =>
					x.serialNumbers.find(s => s.sn === this.creatingSerialNumber) !== null);

				if (deviceFrom1C && deviceFrom1C.serialNumber == this.creatingSerialNumber) {
					existingReservedEquipment = this.reservedEquipments.find(x => x.nomenclature == deviceFrom1C.nomenclature
						&& !x.serialNumber
						&& x.deviceKindId != <number>DeviceKindEnum.component);
					if (existingReservedEquipment) {
						existingReservedEquipment.serialNumber = deviceFrom1C.serialNumber;
						this.moveToDelivery(existingReservedEquipment);
						this.clearCreatingSerialNumber();
					}
					else {
						var newReservedEquipment = this.mapSnDeviceToEquipment(deviceFrom1C);
						this.deliveryEquipments.push(newReservedEquipment);
						this.clearCreatingSerialNumber();
						this.creatingEquipmentBySnInProgress = false;
					}
				}
				else {
					this.requestDevicesService.getAvailableSerialNumbers(
						this.requestId, [this.creatingSerialNumber], [], false).subscribe(response => {
							if (!response.isSuccessful) {
								var unavailableDevice = response.data.unavailableSerialNumbers.find(x => x.serialNumber == this.creatingSerialNumber);
								var errorMessage = unavailableDevice
									? `${unavailableDevice.status} \nВведите другой серийный номер`
									: response.errorDescription;
								this.showErrorToast(errorMessage);
								this.creatingEquipmentBySnInProgress = false;
								return;
							}

							var availableSerialNumber = response.data.availableSerialNumbers.find(x =>
								x.serialNumber === this.creatingSerialNumber);

							if (availableSerialNumber) {
								existingReservedEquipment = this.reservedEquipments.find(x => x.nomenclature == availableSerialNumber.nomenclature
									&& !x.serialNumber
									&& x.deviceKindId != <number>DeviceKindEnum.component);
								if (existingReservedEquipment) {
									existingReservedEquipment.serialNumber = availableSerialNumber.serialNumber;
									this.moveToDelivery(existingReservedEquipment);
									this.clearCreatingSerialNumber();
								}
								else {
									var newDeliveryEquipment = new DeliveryEquipment;
									newDeliveryEquipment.number = Guid.create();
									newDeliveryEquipment.serialNumber = availableSerialNumber.serialNumber;
									newDeliveryEquipment.nomenclature = availableSerialNumber.nomenclature;
									newDeliveryEquipment.deviceConditionId = DeliveryDeviceConditions.default;
									newDeliveryEquipment.deviceTypeId = availableSerialNumber.deviceType;

									let vendor = this.deviceModels.find(x => x.name.trim().toLowerCase() == availableSerialNumber.vendor.trim().toLowerCase());
									let deviceModel = this.deviceModels.find(x => x.name.trim().toLowerCase() == availableSerialNumber.model.trim().toLowerCase());
									let connectionTypeId = availableSerialNumber.connectionType && availableSerialNumber.connectionType.length > 0
										? availableSerialNumber.connectionType[0] : null;

									if (vendor) {
										newDeliveryEquipment.deviceSupplierId = vendor.id;
									}
									if (deviceModel) {
										newDeliveryEquipment.deviceModelId = deviceModel.id;
									}
									if (connectionTypeId) {
										newDeliveryEquipment.connectionTypeId = connectionTypeId;
									}

									this.deliveryEquipments.push(newDeliveryEquipment);
									this.clearCreatingSerialNumber();
								}
							}
							else
								this.showErrorToast("Данный серийный номер недоступен к выдаче");

							this.creatingEquipmentBySnInProgress = false;
						});
				}
			});
		}
	}

	formConfirm() {
		if (this.reservedEquipments.some(x => x.reserveSuccess))
			this.notificationService.confirmation({
				title: "Невыданное оборудование",
				message: "Осталось невыданное зарезервированное оборудование, после подтверждения инженером получения, резерв по оборудованию будет снят",
				confirmButtonText: "Согласен",
				cancelButtonText: "Отмена"
			}, () => this.formConfirmSaving());
		else
			this.formConfirmSaving();
	}

	formConfirmSaving() {
		this.formConfirmingInProgress = true;
		const removeObservables = this.requestDeviceIdsToRemove
			.map(id => this.requestDevicesService.remove(`${id}`, null, null, true));
		const createDeliveryObservables = this.deliveryEquipments
			.filter(x => !x.id || x.id == 0)
			.map(equipment => {
				var newDevice = this.mapEquipmentToNewRequestDevice(equipment);
				newDevice.reserveResultId = equipment.deviceIsBroken ? ReserveResult.broken : ReserveResult.receivingEngineer;
				if (newDevice.reserveResultId == ReserveResult.receivingEngineer) {
					newDevice.issuedSuccess = true;
				}
				return this.requestDevicesService.create(newDevice, null, true);
			});

		const updateDeliveryObservables = this.deliveryEquipments
			.filter(x => x.reserveSuccess)
			.map(equipment => {
				var device = this.mapEquipmentToNewRequestDevice(equipment);
				device.reserveResultId = ReserveResult.receivingEngineer;
				device.issuedSuccess = true;
				return this.requestDevicesService.update(device, null, false, true);
			});

		const createReservedObservables = this.reservedEquipments
			.filter(x => !x.id || x.id == 0)
			.map(equipment => {
				var newDevice = this.mapEquipmentToNewRequestDevice(equipment);
				newDevice.reserveResultId = equipment.deviceIsBroken ? ReserveResult.broken : ReserveResult.receivingEngineer;
				return this.requestDevicesService.create(newDevice, null, true);
			})

		const updateReservedObservables = this.reservedEquipments
			.filter(x => x.id && x.id != 0)
			.map(equipment => {
				var device = this.mapEquipmentToNewRequestDevice(equipment);
				device.reserveResultId = equipment.deviceIsBroken ? ReserveResult.broken : ReserveResult.pendingIssueToEngineer;
				return this.requestDevicesService.update(device, null, false, true);
			});

		

		forkJoin([
			...removeObservables,
			...createReservedObservables,
			...createDeliveryObservables,
			...updateReservedObservables,
			...updateDeliveryObservables
		]).subscribe(() => {
			this.onContinueEmitter.emit();
			this.formConfirmingInProgress = false;
			this.dialog.close();
		}, () => { this.formConfirmingInProgress = false; });
	}

	mobileConfirm() {
		this.mobileFormConfirmingInProgress = true;
		this.requestDevicesService.confirmReceiptEquipmentByEngineer(this.requestId).subscribe(() => {
			this.onContinueEmitter.emit();
			this.mobileFormConfirmingInProgress = false;
		}, () => this.mobileFormConfirmingInProgress = false);
	}

	equipmentModelChange() {
		if (this.creatingEquipment.deviceTypeId && this.creatingEquipment.deviceSupplierId &&
			this.creatingEquipment.deviceModelId) {
			this.clearDeviceNomenclatures();
			this.loadNomenclatures = true;

			var getNomenclaturesQuery = new GetNomenclaturesQuery;
			getNomenclaturesQuery.deviceTypeId = this.creatingEquipment.deviceTypeId;
			getNomenclaturesQuery.deviceModelName = this.getDeviceModelName(this.creatingEquipment.deviceModelId);
			getNomenclaturesQuery.deviceSupplierName = this.getSupplierName(this.creatingEquipment.deviceSupplierId);

			this.for1CService.getNomenclatures(getNomenclaturesQuery).subscribe(resp => {
				this.deviceNomenclatures = resp.data.map((item, index) => new KeyValueObject(index, item));
				if (resp.data && resp.data.length == 1)
					this.creatingEquipment.nomenclature = resp.data[0];
				this.loadNomenclatures = false;
			}, () => this.loadNomenclatures = false);
		}
		else
			this.clearDeviceNomenclatures();
	}

	clearDeviceModel() {
		this.creatingEquipment.deviceModelId = null;
		this.clearDeviceNomenclatures();
	}

	clearDeviceNomenclatures() {
		this.creatingEquipment.nomenclature = null;
		this.deviceNomenclatures = [];
	}

	clearCreatingSerialNumber() {
		if (!this.isMobileDevice) {
			this.creatingSerialNumber = null;
			this.ngZone.runOutsideAngular(() => {
				setTimeout(() => {
					this.creatingSerialNumberInput.nativeElement.focus();
				});
			});
		}
	}

	confirmReturn(equipment: DeliveryEquipment) {
		if (!equipment.reserveSuccess && equipment.deviceConditionId == DeliveryDeviceConditions.default) {
			this.deliveryEquipments = this.deliveryEquipments.filter(x => x.number !== equipment.number);
			if (equipment.id && equipment.id != 0)
				this.requestDeviceIdsToRemove.push(equipment.id);
		}
		else {
			this.deliveryEquipments = this.deliveryEquipments.filter(x => x.number !== equipment.number);
			equipment.tryingReturn = false;
			this.reservedEquipments.push(equipment);
		}
	}

	moveToDelivery(equipment: DeliveryEquipment) {
		this.reservedEquipments = this.reservedEquipments.filter(x => x.number !== equipment.number);
		this.deliveryEquipments.push(equipment);
	}

	changeConfirmationSerialNumber(equipment: DeliveryEquipment) {
		equipment.serialNumberIsConfirmed = equipment.serialNumber === equipment.confirmationSerialNumber;
	}

	get isMobileDevice() {
		return this.appService.isMobileDevice;
	}

	get selectedDeviceModels(): HierarchyLookupModel[] {
		return this.creatingEquipment && this.creatingEquipment.deviceSupplierId
			? this.deviceModels.filter(x => x.parentId == this.creatingEquipment.deviceSupplierId)
			: [];
	}

	get creatingEquipmentCompleted() {
		return this.creatingEquipment &&
			this.creatingEquipment.deviceTypeId &&
			this.creatingEquipment.deviceSupplierId &&
			this.creatingEquipment.deviceModelId &&
			this.creatingEquipment.nomenclature &&
			this.creatingEquipment.nomenclature.toLowerCase() !== '(не выбрана)';
	}

	getDeviceConnectionTypeName(device: NewRequestDevice) {
		var connectionType = this.deviceConnectionTypes.find(x => x.id == device.deviceConnectionTypeId);
		return connectionType ? connectionType.name : "Не заполнено";
	}

	private getDeviceModelName(deviceModelId: number): string {
		return this.deviceModels.find(x => x.id == deviceModelId).name;
	}

	private getSupplierName(supplierId: number): string {
		return this.deviceSuppliers.find(x => x.id == supplierId).name;
	}

	private showErrorToast(message: string) {
		this.notificationService.error({
			title: "Ошибка",
			message: message,
			notificationType: NotificationType.Toast
		});
	}

	private mapSnDeviceToEquipment(device: SerialNumbersDevice) {
		var newEquipment = new DeliveryEquipment;
		newEquipment.number = Guid.create();
		newEquipment.id = device.requestDeviceId;
		newEquipment.nomenclature = device.nomenclature;
		newEquipment.serialNumber = device.serialNumber;
		newEquipment.reserveSuccess = device.reservedBySn;
		newEquipment.deviceConditionId = DeliveryDeviceConditions.default;
		newEquipment.deviceTypeId = device.deviceTypeId;
		newEquipment.deviceTypeName = device.deviceTypeName;
		newEquipment.connectionTypeId = device.connectionTypeId;
		return newEquipment;
	}

	private mapEquipmentToNewRequestDevice(equipment: DeliveryEquipment) {
		var newDevice = new NewRequestDevice;
		newDevice.requestDeviceId = equipment.id;
		newDevice.serialNumber = equipment.serialNumber;
		newDevice.nomenclature = equipment.nomenclature;
		newDevice.reserveSuccess = equipment.reserveSuccess;
		newDevice.activityId = this.activityId;
		newDevice.requestId = this.requestId;
		newDevice.deviceTypeId = equipment.deviceTypeId;
		newDevice.deviceModelId = equipment.deviceModelId;
		newDevice.deviceSupplierId = equipment.deviceSupplierId;
		newDevice.warehouseAreaMoveFromId = equipment.warehouseAreaMoveFromId;
		newDevice.deviceConnectionTypeId = equipment.connectionTypeId;
		return newDevice;
	}

	private mapNewRequestDeviceToEquipment(device: NewRequestDevice) {
		var newEquipment = new DeliveryEquipment;
		newEquipment.number = Guid.create();
		newEquipment.id = device.requestDeviceId;
		newEquipment.serialNumber = device.serialNumber;
		newEquipment.nomenclature = device.nomenclature;
		newEquipment.reserveSuccess = device.reserveSuccess;
		newEquipment.deviceTypeId = device.deviceTypeId;
		newEquipment.deviceTypeName = device.deviceTypeName;
		newEquipment.deviceModelId = device.deviceModelId;
		newEquipment.deviceSupplierId = device.deviceSupplierId;
		newEquipment.connectionTypeId = device.deviceConnectionTypeId;
		newEquipment.deviceConditionId = DeliveryDeviceConditions.default;
		newEquipment.deviceKindId = device.deviceKindId;
		newEquipment.warehouseMoveFromName = device.warehouseMoveFromName;
		newEquipment.warehouseAreaMoveFromId = device.warehouseAreaMoveFromId;
		newEquipment.isComponent = (device.serialNumber == null || device.serialNumber == '') && device.deviceKindId == <number>DeviceKindEnum.component;
		return newEquipment;
	}

	cancel() {
		if (this.deliveryEquipments.length !== this.startDeliveryEquipmentsCount ||
			this.reservedEquipments.length !== this.startReservedEquipmentsCount ||
			this.creatingSerialNumber ||
			this.creatingEquipment.deviceTypeId ||
			this.creatingEquipment.deviceSupplierId ||
			this.creatingEquipment.deviceModelId ||
			this.creatingEquipment.nomenclature) {
			this.notificationService.confirmation({
				title: 'Подтверждение отмены',
				message: 'Внимание, при подтверждении все изменения будут отменены.',
				confirmButtonText: 'Да, подтвердить отмену',
				cancelButtonText: 'Нет, продолжить',
			}, () => {
				this.dialog.close();
			});
		}
		else {
			this.dialog.close();
		}
	}

	copyToClp(txt) {
		var m = document;
		txt = m.createTextNode(txt);
		var w = window;
		var b = m.body;
		b.appendChild(txt);
		var d = m.createRange();
		var g = w.getSelection;
		d.selectNodeContents(txt);
		g().removeAllRanges();
		g().addRange(d);
		m.execCommand('copy');
		g().removeAllRanges();
		txt.remove();

		this.notificationService.success({
			title: 'Успех',
			message: 'Серийный номер скопирован',
			notificationType: NotificationType.Toast
		});
	}

	creatingSerialNumberInputFocus(event: any): void {
		this.creatingSerialNumberInputIsActive = true;
	}

	creatingSerialNumberInputBlur(): void {
		this.creatingSerialNumberInputIsActive = false;
	}

	@HostListener('window:keydown.enter')
	onEnterKeyDown(): void {
		if (this.creatingSerialNumberInputIsActive && this.creatingSerialNumber) {
			this.addSerialEquipment();
		}
	}

	getWarehouseMoveFromAreaNames(): string{
		var names =  this.reservedEquipments.filter(x => x.warehouseMoveFromName).map(x => x.warehouseMoveFromName).join(',');
		if (names.length > 1){
			return names;
		} else {
			return null;
		}
	}

	hasDeviceFromDifferentWarehouse(){
		var reservedResults = [
			<number>ReserveResult.pendingIssueToEngineer,
			<number>ReserveResult.reserveRequest,
			<number>ReserveResult.booked,
			<number>ReserveResult.receivingEngineer
		];
		return this.allRequestDevices.find(x => x.warehouseMoveFromId 
			&& x.reserveSuccess
			&& x.warehouseMoveFromId != this.defaultScWarehouseId 
			&& reservedResults.includes(x.reserveResultId));
	}
}
