﻿import {Injectable} from "@angular/core";
import {DataService} from "../../core/services/data.service";
import { ContragentDetail } from "../models/contragent/contragent-detail";
import {HttpClient} from "@angular/common/http";
import {NotificationService} from "../../core/services/notification.service";
import {Observable} from "rxjs";
import {ActionDataResult} from "../models/core/ActionDataResult";
import {environment} from "../../../environments/environment";
import {take, tap} from "rxjs/operators";
import {NotificationType} from "../../core/services/notification-type";

@Injectable()
export class ContragentDetailsService extends DataService<ContragentDetail, ContragentDetail> {

	constructor(http: HttpClient, notificationService: NotificationService) {
		super(http, notificationService);
		this.controllerName = 'contragent-details';
	}

	public removeForContragent(contragentDetailId: string, contragentId: number): Observable<ActionDataResult<any>> {
		return this.http.delete<ActionDataResult<object>>(`${environment.apiUrl}v${this.controllerVersion}/${this.controllerName}/delete/${contragentId}/${contragentDetailId}`)
			.pipe(tap(resp => {

				if (resp && !resp.isSuccessful){
					this.notificationService.error({
						title: 'Ошибка',
						message: resp.errorDescription,
						notificationType: NotificationType.SweetAlert
					});
				}

			}, errorResponse => {

				if (errorResponse.status === 904)
					return;

				this.notificationService.error({
					title: 'Ошибка',
					message: errorResponse.error.errorDescription,
					notificationType: NotificationType.SweetAlert
				});

			}))
			.pipe(take(1));

	}

}
