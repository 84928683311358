import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from '../../../../app.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { RequestFilesService } from '../../../../shared/services/request-files.service';
import { RequestFile } from '../../../../shared/models/attachment/request-file';
import { ImagePopup } from '../../../../shared/components/image-popup/image-popup.component';
import { UploadFile } from '../../../../shared/models/attachment/upload-file';
import { KeyValueObject } from '../../../../shared/models/core/KeyValueObject';
import { LookupService } from '../../../../shared/services/lookup.service';
import { Request } from '../../../../shared/models/request/request';
import { SelfEmployedData } from '../../../../shared/models/User/selfEmployedData';
import { UserImage } from '../../../../shared/models/User/userImage';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'self-employed',
	templateUrl: './self-employed.component.html',
	styleUrls: [
		'../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss'
	],
	changeDetection: ChangeDetectionStrategy.Default
})
export class SelfEmployedComponent implements OnInit {
	@Input() selfEmployedData: SelfEmployedData;
	@Input() telegramName: string;
	@Input() userImage: UserImage;
	@Input() canEditForm: boolean;
	@Output() requestChange = new EventEmitter<Request>();
	@Output() showFileTabEvent = new EventEmitter();

	files: RequestFile[] = [];

	filesLoading: boolean = false;
	showAddFilePanel: boolean = false;
	showGrid: boolean = true;
	uploadInProgress: any = false;
	uploadFilesPercentages?: number;

	commentToAdd: string = "";
	filesToUpload: UploadFile[] = [];
	attachmentTypes: KeyValueObject[] = [];
	downloadFilesInProgress: number[] = [];

	imageExtensions: string[] = ['.png', '.jpg', '.jpeg'];

	constructor(
		private appService: AppService,
		private dialogService: DialogService,
		private lookupService: LookupService
	) { }

	ngOnInit(): void {
		if (this.selfEmployedData == null) {
			this.selfEmployedData = new SelfEmployedData();
			this.selfEmployedData.passportDate = new Date();
			this.selfEmployedData.deRegistrationDate = new Date();
			this.selfEmployedData.registrationDate = new Date();
		}
		this.selfEmployedData.deRegistrationDate = new Date(this.selfEmployedData.deRegistrationDate);
		this.selfEmployedData.registrationDate = new Date(this.selfEmployedData.registrationDate);
		this.selfEmployedData.passportDate = new Date(this.selfEmployedData.passportDate);
		this.lookupService.getData("attachment-types", null).subscribe(data => this.attachmentTypes = data);
	}

	openImagePopup(imageUrl: string) {
		const dialogRef = this.dialogService.open({ content: ImagePopup, width: this.appService.isMobileDevice ? '90%' : '90%', height: '90%', title: "passport" });

		const imagePopup = <ImagePopup>dialogRef.content.instance;

		imagePopup.src = imageUrl;
		imagePopup.includeAuth = true;
		imagePopup.showCloseButton = false;
	}
	getImageUrl(): string {
		if (this.userImage && this.userImage.userImageId) {
			return environment.apiUrl + `v2/user-image/${this.userImage.userImageId}/preview`
		}
		else {
			return null;
		}
	}

	showFileTab() {
		this.showFileTabEvent.emit();
	}
}
